import React, { useContext, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faSpinner } from '@fortawesome/free-solid-svg-icons';

import {
  Modal,
  Field,
  SearchSelect,
  Form,
  Label,
  Button,
} from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Content,
  Footer,
} from 'components/ListingModal/styles';

import { getUsersService } from 'services/user';
import { ModalProps } from 'types';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { TicketDetailContext } from '../_context/state';

const responsibleSchema = Yup.object().shape({
  responsible: Yup.string(),
});

const ChangeResponsibleModal: React.FC<ModalProps> = ({ show, setShow }) => {
  const { changeResponsible, ticket } = useContext(TicketDetailContext);

  const [loading, setLoading] = useState(false);

  const submitForm = async ({ responsible }) => {
    setLoading(true);
    await changeResponsible(ticket.id, responsible);
    setLoading(false);
    setShow(false);
    toast.success('Responsável alterado');
  };

  const handleLoadUsers = async searchValue => {
    try {
      const res = await getUsersService({
        staff: true,
        search: searchValue,
      });

      return res.data.results.map(s => ({
        value: s.id,
        label: s.email,
      }));
    } catch (e) {
      console.log(e);
      return [];
    }
  };

  return (
    <Modal show={show} setShow={setShow} noExit={loading}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faLock} />
        </Icon>
        <Text>
          <Title>Trocar responsável</Title>
        </Text>
      </Header>

      <Content>
        <Form id="form" schema={responsibleSchema} onSubmit={submitForm}>
          <div
            style={{
              width: '100%',
              marginBottom: '20px',
            }}
          >
            <Label>Responsável</Label>
            <SearchSelect
              placeholder="Escolha um usuário"
              loadOptions={handleLoadUsers}
              name="responsible"
            />
          </div>
        </Form>
      </Content>
      <Footer>
        <Button icon disabled={loading} type="submit" form="form">
          {loading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            'Fechar ticket'
          )}
        </Button>
      </Footer>
    </Modal>
  );
};

export default ChangeResponsibleModal;
