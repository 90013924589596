import React, { useMemo, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';

import { Separator } from 'components/Details';
import { Alert } from 'components/Alert';

import NodeappField from 'components/NodeappField';
import { Error } from '@cloudez/cloudez-design-system';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NodeAppType } from 'types';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import exclamation from 'assets/img/svg/exclamation-message.svg';
import { WebsiteDetailContext } from '../_context/state';

interface IApplicationProps {
  type: NodeAppType;
  onChange(e: Event): void;
  errors: any;
}

const Application: React.FC<IApplicationProps> = ({
  type,
  onChange,
  errors,
}) => {
  const { website } = useContext(WebsiteDetailContext);

  const { fieldsLeft, fieldsRight } = useMemo(() => {
    return {
      fieldsLeft: [
        'upload_maxsize',
        'timeout',
        'memory',
        'allow_origin',
        'static_dir',
        'profile',
        'custom_port',
      ],

      fieldsRight: [
        'metabase_port',
        'metabase_version',
        'moodle_data_dir',

        'python_module',
        'python_version',
        'php_max_input_vars',

        'ruby_version',
        'rails_env',
        'ruby_release',

        'nodejs_app',
        'dotnet_app',
        'environment',
        'app_index',

        'php_version',
        'debug',

        'odoo_dbfilter',
        'odoo_addon_path',
        'odoo_master_password',

        'wordpress_wpadmin_protect',
        'php_safe_mode',
        'auto_install',
        'nodejs_version',
        'wordpress_xmlrpc_allow',
        'create_database_automatically',
        'wordpress_wpcron',

        'django_media_url',
        'django_media_dir',
        'django_settings',

        'shellhub_version',
        'shellhub_username',
        'shellhub_password',
      ],
    };
  }, []);

  return (
    <Row>
      <Col xl>
        {website.values.map((v, i) => {
          if (fieldsLeft.includes(v.slug)) {
            const [field] = type.values.filter(_v => v.slug === _v.slug);
            return (
              field && (
                <>
                  <NodeappField
                    field={field}
                    value={v.value}
                    onChange={onChange}
                    error={!!errors?.values?.[`${field.slug}`]}
                  />
                  {field.slug === 'memory' ? (
                    <Alert info style={{ margin: '0 50px 10px 0' }}>
                      <img alt="exclamation" src={exclamation} />
                      <span>
                        A falta de memória para a aplicação pode acarretar em
                        <b> erro 500</b>, caso isso aconteça, experimente
                        aumentar a memória alocada para este site. Lembre de
                        respeitar o limite de memória disponível no cloud
                      </span>
                    </Alert>
                  ) : (
                    <></>
                  )}
                  {errors?.values?.[`${field.slug}`] && (
                    <Error
                      style={{
                        transform: 'translateY(-16px)',
                      }}
                    >
                      {errors?.values?.[`${field.slug}`][0]}
                    </Error>
                  )}
                </>
              )
            );
          }
          return null;
        })}
      </Col>
      <Separator />
      <Col xl>
        {website.values.map((v, i) => {
          if (fieldsRight.includes(v.slug)) {
            const [field] = type.values.filter(_v => v.slug === _v.slug);
            return (
              field && (
                <>
                  {field.slug === 'nodejs_version' ? (
                    <NodeappField
                      key={v.slug}
                      field={field}
                      value={v.value}
                      onChange={onChange}
                      error={!!errors?.values?.[`${field.slug}`]}
                      cloudDistribution={website.cloud.distribution}
                    />
                  ) : (
                    <NodeappField
                      key={v.slug}
                      field={field}
                      value={v.value}
                      onChange={onChange}
                      error={!!errors?.values?.[`${field.slug}`]}
                    />
                  )}
                  {errors?.values?.[`${field.slug}`] && (
                    <Error
                      style={{
                        transform: 'translateY(-16px)',
                      }}
                    >
                      {errors?.values?.[`${field.slug}`][0]}
                    </Error>
                  )}
                </>
              )
            );
          }
          return null;
        })}
      </Col>
    </Row>
  );
};

export default Application;
