import React, { useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck, faInfo } from '@fortawesome/free-solid-svg-icons';
import { ThemeContext } from 'styled-components';

import { Wrapper } from './styles';

export default props => {
  const theme = useContext(ThemeContext);

  const getColor = () => {
    switch (props.type) {
      case 'info':
        return theme.labelNew;
      case 'success':
        return theme.successNew;
      case 'error':
        return theme.errorNew;
      default:
        return null;
    }
  };

  const getIcon = () => {
    switch (props.type) {
      case 'info':
        return faInfo;
      case 'success':
        return faCheck;
      case 'error':
        return faTimes;
      default:
        return null;
    }
  };

  return (
    <Wrapper color={getColor()}>
      <FontAwesomeIcon onClick={props.closeToast} icon={getIcon()} />
    </Wrapper>
  );
};
