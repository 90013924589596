import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, TextArea } from '@cloudez/cloudez-design-system';
import { getMessage } from 'utils/customMessages';

import {
  faPaperclip,
  faSpinner,
  faPaperPlane,
  faFilePdf,
  faFile,
} from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import {
  getTicketsService,
  getMessagesService,
  createTicketMessageService,
  getTicketService,
} from 'services/ticket';
import { useLayout } from 'hooks/layout';
import { useAuth } from 'hooks/auth';
import toastError from 'utils/toastError';
import {
  Container,
  Header,
  Messages,
  TicketFooter,
  Icons,
  FilesDropdown,
  File,
  NoTicket,
} from './styles';
import Message from './Message';

interface ITicketProps {
  type?: any;
  id?: string | number;
  ticketId?: string | number;
}

const Ticket: React.FC<ITicketProps> = ({ type, id, ticketId }) => {
  const { support_email } = useLayout();
  const { user } = useAuth();

  const fileInput = useRef<HTMLInputElement>();

  const [ticket, setTicket] = useState(null);
  const [messages, setMessages] = useState(null);
  const [files, setFiles] = useState(null);
  const [message, setMessage] = useState('');
  const [hasFile, setHasFile] = useState(false);
  const [hasListener, setHasListener] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showFiles, setShowFiles] = useState(false);

  const getFiles = data => {
    const _files = data.filter(d => d.file).map(d => d.file);

    setFiles(_files);
  };

  const getMessages = async _id => {
    try {
      const { data } = await getMessagesService({
        ticket: _id,
      });

      getFiles(data);

      setMessages(data);
    } catch (e) {
      console.log(e);
    }
  };

  const getTicket = useCallback(async () => {
    try {
      if (ticketId) {
        const { data } = await getTicketService(ticketId);
        setTicket(data);
        getMessages(data.id);
      } else {
        const {
          data: { results },
        } = await getTicketsService({
          target: `${type},${id}`,
        });

        if (results.length > 0) {
          const [_ticket] = results;
          setTicket(_ticket);
          getMessages(_ticket.id);
        }
      }
    } catch (e) {
      console.log(e);
    }
  }, [type, id]);

  const addMessage = async _data => {
    try {
      const { data } = await createTicketMessageService(_data);

      getMessages(data.ticket);
    } catch (e) {
      toast.error(e?.response?.data[Object.keys(e?.response?.data)[0]][0]);
    }
  };

  useEffect(() => {
    getTicket();
  }, [type, id, getTicket]);

  const isDomo = msg => {
    return (
      msg.author === 'domo@configr.com' ||
      msg.author === support_email ||
      msg.author_external === support_email
    );
  };

  const checkForShortcut = shortcut => {
    const regex = new RegExp(/\/(.*?)\//gm);

    if (user.is_staff) {
      if (shortcut && regex.test(shortcut)) {
        if (getMessage(shortcut.split(regex)[1])) {
          setMessage(getMessage(shortcut.split(regex)[1]).value);
        }
      }
    }
  };

  const sendMessage = async e => {
    if (!loading) {
      try {
        let msg = message;
        let canSend = false;
        if (e.type === 'keydown') {
          if (e.ctrlKey && e.keyCode === 13) {
            setLoading(true);
            msg = e.target.value;
            canSend = true;
          } else {
            return;
          }
        }
        if (e.type === 'keydown' && !canSend) {
          return;
        }

        const data = new FormData();
        data.append('ticket', ticket.id);
        data.append('text', msg);
        data.append('author', user.id.toString());
        if (fileInput.current.files.length > 0)
          data.append('file', fileInput.current.files[0]);
        await addMessage(data);
        fileInput.current.value = '';

        setHasFile(false);
        setMessage('');
        setLoading(false);
      } catch (e) {
        toastError(e);
        setLoading(false);
      }
    }
  };

  return ticket ? (
    <Container>
      <Header>
        {ticket?.summary}{' '}
        <FontAwesomeIcon
          icon={faPaperclip}
          onClick={() => setShowFiles(!showFiles)}
        />
        {showFiles && (
          <FilesDropdown>
            {files?.map((f, i) => {
              const hasImage =
                f.includes('.png') ||
                f.includes('.jpg') ||
                f.includes('.jpe') ||
                f.includes('.jpeg');
              return (
                <File key={i}>
                  <span
                    onClick={() => {
                      window.open(f, '_blank');
                      setShowFiles(false);
                    }}
                  >
                    {hasImage ? (
                      <img src={f} alt={f} />
                    ) : (
                      <FontAwesomeIcon
                        icon={f.includes('.pdf') ? faFilePdf : faFile}
                      />
                    )}
                  </span>
                </File>
              );
            })}
          </FilesDropdown>
        )}
      </Header>
      <Messages
        ref={ref => {
          if (ref)
            ref.scrollTo({
              top: ref.scrollHeight,
              behavior: 'smooth',
            });
        }}
      >
        {messages?.map(m => (
          <Message
            ticket={ticket}
            m={m}
            isAuthor={m.author === user.email}
            key={m.id}
            domo={isDomo(m)}
            staff={m.is_ticket_staff && m.author !== ticket.owner_email}
            isStaff={user.is_staff}
          />
        ))}
      </Messages>
      <TicketFooter file={hasFile}>
        <input
          ref={fileInput}
          type="file"
          id="fileinput"
          style={{
            display: 'none',
          }}
          onChange={e => setHasFile(!!e.target.files.length)}
        />

        <Icons>
          <FontAwesomeIcon
            className="file"
            icon={faPaperclip}
            onClick={() => fileInput.current.click()}
          />
        </Icons>

        <TextArea
          ref={e => {
            if (e && !hasListener) {
              e.addEventListener('keydown', sendMessage);
              setHasListener(true);
            }
          }}
          disabled={ticket.status === 2}
          background
          placeholder="Digite sua mensagem aqui.."
          value={message}
          onChange={e => {
            setMessage(e.target.value);
            checkForShortcut(e.target.value);
            e.target.style.height = '1px';
            e.target.style.height = `${25 + e.target.scrollHeight}px`;
          }}
          id="ticketMessageInput"
        />

        <Button
          disabled={
            loading ||
            ticket.status === 2 ||
            ((!message || message === '') && !hasFile)
          }
          width="130px"
          onClick={async e => {
            setLoading(true);
            await sendMessage(e);
            setLoading(false);
          }}
          icon
        >
          <FontAwesomeIcon
            icon={loading ? faSpinner : faPaperPlane}
            spin={loading}
          />
        </Button>
      </TicketFooter>
    </Container>
  ) : (
    <NoTicket>
      <p>Nenhum ticket encontrado.</p>
    </NoTicket>
  );
};
export default Ticket;
