import styled from 'styled-components';

export const Label = styled.span`
  font-weight: bold;
  font-size: 14px;
  color: ${props => props.theme.interface5};
`;

export const Value = styled.span`
  font-size: 14px;
  color: ${props => props.theme.interface5};
`;

export const Field = styled.div``;
