import api from 'services/api';

export const getUsersService = async (params?) => {
  try {
    return await api.get(`/v3/user/`, { params });
  } catch (e) {
    throw e;
  }
};

export const getUserService = async id => {
  try {
    return await api.get(`/v3/user/${id}/`);
  } catch (e) {
    throw e;
  }
};

export const getOTPStatusService = async (params, headers) => {
  try {
    return await api.get(`otp/devices/`, { params, headers });
  } catch (e) {
    throw e;
  }
};

export const verifyDeviceService = async payload => {
  try {
    return await api.post(`otp/verify-device/`, payload);
  } catch (e) {
    throw e;
  }
};

export const getCodeEmailService = async (params?) => {
  try {
    return await api.post('otp/email-device/', params);
  } catch (e) {
    throw e;
  }
};

export const resendCodeService = async (params?) => {
  try {
    return await api.post('otp/email-device/ask-code/', params);
  } catch (e) {
    throw e;
  }
};

export const getCodeDeviceService = async (params?) => {
  try {
    return await api.post(`otp/totp-device/`, params);
  } catch (e) {
    throw e;
  }
};

export const deleteOTPEmailService = async () => {
  try {
    return await api.delete('otp/email-device/');
  } catch (e) {
    throw e;
  }
};

export const deleteOTPDeviceService = async () => {
  try {
    return await api.delete('otp/totp-device/');
  } catch (e) {
    throw e;
  }
};

export const deleteDeviceService = async id => {
  try {
    return await api.delete(`otp/devices/${id}`);
  } catch (e) {
    throw e;
  }
};

export const deleteAllDeviceService = async headers => {
  try {
    return await api.delete('otp/devices/delete-all/', { headers });
  } catch (e) {
    throw e;
  }
};

export const skipFreeTrialService = async id => {
  try {
    return await api.post(`/v3/user/${id}/skip_free_trial/`);
  } catch (e) {
    throw e;
  }
};

export const updateUserPasswordService = async password => {
  try {
    return await api.patch('/v3/user-password/', password);
  } catch (e) {
    throw e;
  }
};

export const deleteUserService = async id => {
  try {
    return await api.delete(`/v3/user/${id}/`);
  } catch (e) {
    throw e;
  }
};

export const getUserBillingService = async id => {
  try {
    return await api.get(`/v3/billing/${id}/`);
  } catch (e) {
    throw e;
  }
};

export const updateUserBillingService = async (id, data, version?) => {
  try {
    return await api.patch(`/${version || 'v3'}/billing/${id}/`, data);
  } catch (e) {
    throw e;
  }
};

export const createUserPhoneService = async data => {
  try {
    return await api.post('/v3/user-phone/', data);
  } catch (e) {
    throw e;
  }
};

export const updateUserPhoneService = async (id, data) => {
  try {
    return await api.patch(`/v3/user-phone/${id}/`, data);
  } catch (e) {
    throw e;
  }
};

export const createReferralByEmail = async data => {
  return await api.post('/v3/referral/', data);
};
