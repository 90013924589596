import { Dispatch } from 'react';
import { toast } from 'react-toastify';

import { getPlanTypesService } from 'services/common';
import { getCloudSizesService } from 'services/cloud';

import { IActionPayload } from 'types';
import toastError from 'utils/toastError';

export const setLoading = (
  dispatch: Dispatch<IActionPayload>,
  value: boolean,
): void => {
  dispatch({
    type: 'SET_LOADING',
    payload: value,
  });
};

export const updatePlan = (
  dispatch: Dispatch<IActionPayload>,
  plan: any,
): void => {
  const { fbq } = window as any;
  const { gtag } = window as any;

  if (fbq) {
    fbq('track', 'AddToCart');
  }

  if (gtag) {
    gtag('event', 'track', { event_category: 'AddToCart' });
  }

  dispatch({
    type: 'UPDATE_PLAN',
    payload: plan,
  });
};

export const setCouponDiscount = (
  dispatch: Dispatch<IActionPayload>,
  discount: string | number,
  discountType: string,
): void => {
  dispatch({
    type: 'SET_COUPON_DISCOUNT',
    payload: {
      discount,
      discountType,
    },
  });
};

export const getPlans = async (
  dispatch: Dispatch<IActionPayload>,
  size_category = 'default',
  stop = false,
): Promise<void> => {
  setLoading(dispatch, true);
  try {
    const { data } = await getPlanTypesService({
      page_size: 1000,
      category: 1,
      size_category,
    });

    dispatch({
      type: 'GET_PLANS',
      payload: data,
    });

    if (stop) {
      setLoading(dispatch, false);
    }
  } catch (e) {
    toastError(e);
  }
};

export const setCoupon = (
  dispatch: Dispatch<IActionPayload>,
  value: string,
): void => {
  dispatch({
    type: 'SET_COUPON',
    payload: value,
  });
};

export const getBasePlans = async (
  dispatch: Dispatch<IActionPayload>,
): Promise<void> => {
  try {
    const { data } = await getCloudSizesService();

    dispatch({
      type: 'GET_BASE_PLANS',
      payload: data,
    });

    setLoading(dispatch, false);
  } catch (e) {
    toastError(e);
  }
};

export const setRecurrency = (
  dispatch: Dispatch<IActionPayload>,
  recurrency: string,
): void => {
  dispatch({
    type: 'SET_RECURRENCY',
    payload: recurrency,
  });
};

export const setPlanType = (
  dispatch: Dispatch<IActionPayload>,
  planType: number,
): void => {
  dispatch({
    type: 'SET_PLAN_TYPE',
    payload: planType,
  });
};
