/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export const Arrows = styled(FontAwesomeIcon)`
  color: ${props => props.theme.brand_primary_color};
  position: absolute;
  top: 50%;
  display: block;
  font-size: 30px;

  ${props =>
    props.left &&
    css`
      left: -25px;
    `}

  ${props =>
    props.right &&
    css`
      right: -25px;
    `}

  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  border: none;
  outline: none;
  background: transparent;
`;

export const Wrapper = styled.div`
  margin-top: 25px;
  width: 100%;
  height: 100%;
`;

export const PlanCard = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 320px;
  width: 250px;
  margin: 10px auto 10px auto;
  background: ${props =>
    props.theme.darkMode ? props.theme.interface3 : props.theme.interface1};
  border-radius: 5px;
  border: 2px solid transparent;

  padding: 30px 0px;

  box-shadow: 0px 3px 15px #64728c4d;

  ul {
    li {
      font-weight: 600;
      font-size: 10px;
      color: ${props => props.theme.interface5};
    }

    li.value {
      font-size: 13px;

      margin-bottom: 10px;
    }
  }

  div.plan-title {
    display: flex;
    justify-content: space-between;

    color: ${props => props.theme.brand_primary_color};

    font-weight: 700;
    font-size: 14px;

    width: 100%;

    margin-bottom: 5px;
    word-break: break-word;

    & > span {
      font-size: 13px;
    }
  }

  button {
    margin: 0px auto 0px auto;
  }

  div.price {
    text-align: left;
    display: flex;
    flex-direction: column;

    margin-top: 10px;

    span {
      color: ${props => props.theme.brand_primary_color};
      font-size: 28px;
      font-weight: 700;
    }

    span:first-child {
      color: ${props => props.theme.interface5};

      font-weight: 300;
    }
  }

  div.separator {
    margin-top: auto;
    border-bottom: 1px solid ${props => props.theme.interface3};
  }

  span.tip {
    font-size: 12px;
    color: ${props => props.theme.interface5};
    line-height: 16px;
    word-break: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  div.top {
    /* height: 50%; */
    padding: 0px 10px;
  }

  div.bottom {
    justify-self: flex-end;
    display: flex;
    margin-top: 30px;
    flex-direction: column;
    justify-content: center;

    padding: 0px 10px;

    /* height: 40%; */
    text-align: center;
  }

  transition: all 0.2s;

  cursor: pointer;

  &:hover {
    border-color: ${props =>
      props.theme.darkMode
        ? props.theme.interface5
        : props.theme.brand_primary_color};
  }

  ${props =>
    props.private &&
    css`
      border-color: ${props => props.theme.favorite};
    `}

  ${props =>
    props.selected &&
    css`
      border-color: ${props =>
        props.theme.darkMode
          ? props.theme.interface5
          : props.theme.brand_primary_color};
    `}
`;

export const Price = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: space-evenly;

  span {
    span {
      font-size: 46px;
      font-weight: bolder;
    }

    p {
      margin-top: -18px;
      text-align: center;
    }
  }
`;

export const InfoCard = styled.div`
  width: 100%;
  height: 400px;
  padding: 12px 16px;

  color: ${props => props.theme.brand_primary_color};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border: 1px solid ${props => props.theme.brand_primary_color};
  border-radius: 10px;

  ul {
    list-style: none;
    height: 100%;
    margin-bottom: 10px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;

    border-bottom: 1px solid ${props => props.theme.interface4};
  }

  ul li:before {
    content: '✓';
    font-size: 30px;
    margin-right: 10px;
  }

  span {
    text-align: left;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
  }

  li {
    text-align: left;
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  p {
    font-size: 12px;
    text-align: left;
    letter-spacing: 0;
  }
`;
