import React, { useEffect, useState } from 'react';

import { Row } from 'react-bootstrap';

import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { Box, CustomInput, Flexbox } from 'components/NewComponents';
import ApplicationIcon from 'components/ApplicationIcon';
import { Alert } from 'components/Alert';

import {
  faCloudUploadAlt,
  faExclamationCircle,
  faExternalLinkAlt,
  faEye,
  faEyeSlash,
  faPlus,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { faCheckCircle } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import {
  Button,
  createPagination,
  Pagination,
} from '@cloudez/cloudez-design-system';

import { validateDomainService, validateImapService } from 'services/friday';
import { getEmailsService } from 'services/email';

import { useMigCreateEmail } from '../../contexts/MigEmailContext';
import { useMigDetail } from '../../contexts/MigDetailContext';

import DuplicatedEmailsModal from './DuplicatedEmailsModal';
import { ErrorModal } from '../../ErrorModal';
import ImportCSVModal from './ImportCSVModal';

import { Card, Form } from './styles';

const validateDomainField = yup.object().shape({
  domain: yup.string().required('Por favor, digite um domínio.'),
});

const formSchema = {
  username: yup.string().required('Email é obrigatório'),
  password: yup.string().required('Senha é obrigatória'),
};

const validateExternalEmail = yup.object().shape({
  domain: yup.string().required('Por favor, digite um domínio.'),
  imap_port: yup
    .number()
    .typeError('Esse campo é obrigatório')
    .required('Esse campo é obrigatório'),
  src_email_host: yup
    .string()
    .matches(/^\S*$/, 'O Endereço IMAP não deve conter espaços em branco')
    .required('Esse campo é obrigatório'),
  accounts: yup.array().of(yup.object().shape(formSchema)),
});

const OriginData = (): JSX.Element => {
  const {
    originData,
    setOriginData,
    isValidated,
    setIsValidated,
    setEmailsOrigin,
    setSelectedOriginItem,
    selectedOriginItem,
    emailsOrigin,
    migrationErrors,
    migErrorMessage,
  } = useMigCreateEmail();

  const { setStep } = useMigDetail();

  const queryParams = new URLSearchParams(window.location.search);
  const domain = queryParams.get('domain');

  const [showCSVModal, setShowCSVModal] = useState(false);
  const [isValidating, setIsValidating] = useState(false);
  const {
    register,
    setError,
    handleSubmit,
    resetField,
    getValues,
    watch,
    clearErrors,
    control,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      domain: '',
      src_email_host: '',
      imap_port: null,
      accounts: originData?.accounts || [
        { username: '', password: '', showPass: false },
      ],
    },
    resolver: yupResolver(
      isValidated &&
        emailsOrigin?.results?.length === 0 &&
        !selectedOriginItem?.id
        ? validateExternalEmail
        : validateDomainField,
    ),
  });

  const accounts = getValues('accounts');

  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'accounts',
  });

  const haveDiferentEmails = () => {
    for (let i = 0; i < accounts.length; i++) {
      for (let k = i + 1; k < accounts.length; k++) {
        if (accounts[i].username !== accounts[k].username) {
          return true;
        }
        return false;
      }
    }
  };

  const [loading, setLoading] = useState(false);

  const getEmails = async (page, domain) => {
    try {
      const response = await getEmailsService({
        page,
        domain: domain?.trim(),
        page_size: 2,
      });

      const pagination = createPagination(response.data, 2);

      setEmailsOrigin({
        ...pagination,
        results: response.data.results,
      });
    } catch (err) {
      console.log('err', err);
    }
  };

  const [showDuplicatedEmailsModal, setShowDuplicatedEmailsModal] =
    useState(false);

  const [dados, setDados] = useState({});

  const goAheadFunc = async (data: any, pass: any) => {
    setDados(data);

    try {
      if (emailsOrigin?.results?.length === 0) {
        try {
          if (pass !== true) {
            const response = await haveDiferentEmails();

            if (response === false) {
              setShowDuplicatedEmailsModal(true);

              return;
            }
          }
          setLoading(true);
          await validateImapService({
            src_email_host: data.src_email_host,
            imap_port: data.imap_port,
            accounts,
          });
          setOriginData(data);
          setStep(2);
        } catch (err) {
          const emailErrors = err?.response?.data?.messages?.map(item =>
            item
              .split('-')[1]
              .replace(/[{()}]/g, '')
              .trim(),
          );
          if (err?.response?.data && emailErrors) {
            migrationErrors(err?.response?.data, emailErrors);
          } else {
            migrationErrors(err?.response?.data);
          }
        }
      }
      if (Object.values(selectedOriginItem).length === 0) {
        setSelectedOriginItem('external');
      }
      if (selectedOriginItem === 'external') {
        setEmailsOrigin({ results: [] });
      }
      if (selectedOriginItem.id) {
        setOriginData({ domain: originData.domain });
        setStep(2);
      }
    } catch {
      setLoading(false);
    }

    setLoading(false);
  };

  const loadCSV = data => {
    const payload = data.map(a => ({
      username: a[0]?.toLowerCase(),
      password: a[1],
      showPass: false,
    }));

    if (accounts[0]?.username?.length === 0) {
      resetField('accounts');
      setValue('accounts', payload);
    } else {
      setValue('accounts', [...accounts, ...payload]);
    }
  };

  const goBackFunc = () => {
    setEmailsOrigin({ results: [] });
    setIsValidated(false);
  };

  const validateDomain = async domain => {
    if (!getValues('domain')) {
      setError('domain', {
        type: 'manual',
        message: 'Por favor, digite um domínio.',
      });

      return;
    }

    try {
      setIsValidating(true);
      const { data } = await validateDomainService(domain.trim());

      if (!data?.result) {
        setError('domain', {
          type: 'manual',
          message: data.message,
        });
        setIsValidating(false);
        return;
      }

      await getEmails(1, domain.trim());

      setIsValidating(false);
      setIsValidated(true);
      setOriginData({ ...originData, domain: domain.trim() });
      clearErrors('domain');
    } catch (err) {
      setIsValidating(false);
      if (err?.response?.data) {
        migrationErrors(err?.response?.data);
      }
    }
  };

  useEffect(() => {
    if (domain) {
      setValue('domain', domain);
      validateDomain(domain);
    }
  }, [domain]);

  useEffect(() => {
    if (Object.values(originData).length > 0) {
      setValue('domain', originData.domain);
      setValue('src_email_host', originData.src_email_host);
      setValue('imap_port', originData?.imap_port || 993);
    }
  }, [originData]);

  return (
    <>
      {migErrorMessage && <ErrorModal err={migErrorMessage} />}

      {showDuplicatedEmailsModal && (
        <DuplicatedEmailsModal
          action={goAheadFunc}
          dados={dados}
          show={showDuplicatedEmailsModal}
          setShow={setShowDuplicatedEmailsModal}
        />
      )}

      {showCSVModal && (
        <ImportCSVModal
          action={loadCSV}
          show={showCSVModal}
          setShow={setShowCSVModal}
        />
      )}
      <Form onSubmit={handleSubmit(goAheadFunc)}>
        <div className="title">
          <FontAwesomeIcon icon={faCloudUploadAlt} />
          <span>Dados da Origem</span>
        </div>
        {emailsOrigin?.results?.length === 0 && (
          <Flexbox w="100%">
            <CustomInput
              width="100%"
              name="domain"
              label="Digite o Domínio"
              register={register}
              disabled={(!!originData?.domain && isValidated) || isValidated}
              error={errors?.domain?.message}
            />

            <Button
              style={{
                marginTop: '23px',
                gap: '5px',
                padding: '12px',
                opacity: '1',
              }}
              width="120px"
              type="button"
              height="40px"
              onClick={() => validateDomain(watch('domain'))}
              icon
              disabled={isValidated || isValidating}
              outline={!isValidating && !isValidated}
              success={isValidated}
            >
              {isValidated && <FontAwesomeIcon icon={faCheckCircle} />}
              <span
                style={{
                  fontSize: '16px',
                  textTransform: 'none',
                }}
              >
                {isValidated && 'Validado'}
                {isValidating && <FontAwesomeIcon icon={faSpinner} spin />}
                {!isValidated && !isValidating && 'Validar'}
              </span>
            </Button>
          </Flexbox>
        )}
        {emailsOrigin?.results?.length > 0 && (
          <Flexbox flexDir="column" spacing="26px">
            <span className="subtitle">
              Selecione abaixo qual é a origem do seu webmail:
            </span>
            <Flexbox spacing="16px">
              {emailsOrigin?.results?.map((item: any) => (
                <Card
                  onClick={() => setSelectedOriginItem(item)}
                  selected={selectedOriginItem.id === Number(item.id)}
                >
                  <ApplicationIcon
                    width="40px"
                    height="40px"
                    icon={
                      item?.type.slug === 'selfhosted-mail'
                        ? 'existingEmail'
                        : item?.type.slug
                    }
                  />
                  <b>{item.domain}</b>
                  <span>{item.cloud_display}</span>
                </Card>
              ))}
              <Card
                onClick={() => setSelectedOriginItem('external')}
                selected={selectedOriginItem === 'external'}
              >
                <FontAwesomeIcon
                  className="externalLinkAlt"
                  icon={faExternalLinkAlt}
                />
                <b>Migração externa</b>
              </Card>
            </Flexbox>
          </Flexbox>
        )}
        {isValidated && emailsOrigin?.results?.length === 0 && (
          <>
            <Flexbox>
              <CustomInput
                block
                label="Endereço IMAP"
                name="src_email_host"
                register={register}
                error={errors?.src_email_host?.message}
              />

              <CustomInput
                width="30%"
                label="PORTA"
                type="number"
                name="imap_port"
                register={register}
                error={errors?.imap_port?.message}
              />
            </Flexbox>

            <Flexbox>
              <div className="userAccess">
                <span>Acessos dos Usuários</span>{' '}
                <a onClick={() => setShowCSVModal(true)}>Importar CSV</a>
              </div>
            </Flexbox>
            {fields.map((item: any, index) => {
              return (
                <Flexbox key={item.id}>
                  <CustomInput
                    width={fields.length > 1 ? '55%' : '63%'}
                    label="Email"
                    name={`accounts.${index}.username`}
                    register={register}
                    error={
                      errors?.accounts &&
                      errors?.accounts[index]?.username?.message
                    }
                  />
                  <CustomInput
                    width={fields.length === 1 ? '35%' : '26%'}
                    type={item.showPass ? 'text' : 'password'}
                    icon={{
                      svg: !item.showPass ? faEye : faEyeSlash,
                      isRight: true,
                      action: () =>
                        update(index, {
                          username: watch(`accounts.${index}.username`),
                          password: watch(`accounts.${index}.password`),
                          showPass: !item.showPass,
                        }),
                    }}
                    label="Senha"
                    name={`accounts.${index}.password`}
                    register={register}
                    error={
                      errors?.accounts &&
                      errors?.accounts[index]?.password?.message
                    }
                  />
                  <CustomInput
                    type="checkbox"
                    style={{ display: 'none' }}
                    name={`accounts.${index}.showPass`}
                    register={register}
                  />

                  {index !== 0 ? (
                    <Button
                      width="137px"
                      error
                      onClick={() => remove(index)}
                      style={{
                        fontSize: '14px',
                        fontWeight: '700',
                        marginTop: '20px',
                      }}
                    >
                      REMOVER
                    </Button>
                  ) : fields.length > 1 ? (
                    <div style={{ width: '137px' }} />
                  ) : null}
                </Flexbox>
              );
            })}

            <Row
              style={{
                marginRight: '0px',
                marginLeft: '0px',
                marginTop: '16px',
              }}
            >
              <div className="newUser">
                <div
                  onClick={() => {
                    append({ username: '', password: '', showPass: false });
                  }}
                >
                  <span>Adicionar outro usuário</span>
                  <FontAwesomeIcon icon={faPlus} />
                </div>
              </div>
            </Row>
          </>
        )}
        {emailsOrigin?.count > 2 && (
          <Pagination
            onChangePage={getEmails}
            style={{ marginTop: '0px' }}
            pageSize={2}
            count={emailsOrigin.count}
            previous={emailsOrigin.previous}
            next={emailsOrigin.next}
            current={emailsOrigin.current}
          />
        )}

        {isValidated &&
          Object.values(selectedOriginItem).length > 0 &&
          emailsOrigin?.count >= 1 && (
            <Alert style={{ width: '734px' }} info>
              <FontAwesomeIcon size="lg" icon={faExclamationCircle} />
              <span>
                <b>O que será feito?</b>{' '}
                {selectedOriginItem === 'external'
                  ? 'Cópia de todo o conteúdo deste webmail, incluindo as contas.'
                  : 'Iremos migrar seu email de um local externo, via IMAP, para um destino interno com a gente.'}
              </span>
            </Alert>
          )}
        {isValidated && (
          <Box alignItems="center" justify="space-between">
            <Button
              style={{ fontSize: '14px' }}
              onClick={goBackFunc}
              type="button"
              width="117px"
              text
            >
              Voltar
            </Button>
            <Button
              style={{
                fontSize: '16px',
                textTransform: 'none',
              }}
              outline
              icon
              disabled={loading}
              width="120px"
            >
              {loading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : emailsOrigin?.results?.length === 0 ? (
                'Validar'
              ) : (
                'Continuar'
              )}
            </Button>
          </Box>
        )}
      </Form>
    </>
  );
};

export default OriginData;
