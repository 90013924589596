import styled, { css } from 'styled-components';

export const Content = styled.div`
  width: 500px;
  display: flex;
  flex-direction: column;
  /* align-items: center; */
  padding-top: 20px;

  form {
    width: 100%;
    padding: 15px 0px;

    span {
      color: ${props => props.theme.error};
      font-size: 12px;
    }

    p {
      font-size: 12px;
      color: #979797;

      a {
        color: ${props => props.theme.label};
        margin-top: 15px;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  h1 {
    margin-bottom: 10px;
  }

  @media (max-width: 1000px) {
    width: 100%;
    margin: 0;
  }
`;

export const Wrapper = styled.div`
  height: 100%;

  display: flex;
  justify-content: center;

  h1 {
    font-weight: 900;
    font-size: 35px;
    color: ${props =>
      props.theme.darkMode ? props.theme.interface4 : '#333333'};
    text-align: center;
  }

  h4 {
    font-size: 16px;
    margin-bottom: 20px;
    font-weight: 400;

    text-align: center;

    color: #64728c;
    /* width: 350px; */
  }

  @media (max-width: 1000px) {
    height: auto;
    width: 100%;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: center;

  padding: 25px 0;
`;
