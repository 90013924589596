import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';

import { NoResultsDetail } from 'components/Common';
import { AddButton, AddCard } from 'components/AddCard';
import UserCard from './UserCard';
import UserAddModal from './UserModal';
import UserPlaceholder from './UserPlaceholder';

const Users = ({ users, cloud, setUsers, userLoading }) => {
  const [addUser, setAddUser] = useState(false);

  return (
    <Row>
      {addUser && (
        <UserAddModal
          show={addUser}
          setShow={() => {
            setAddUser(false);
          }}
          cloud={cloud}
          setUsers={setUsers}
          users={users}
        />
      )}
      <Col xl="3" lg="4" sm="6" xs="12">
        <AddCard
          onClick={() => {
            setAddUser(true);
          }}
          width="100%"
          height="50px"
          style={{
            marginBottom: '12px',
          }}
        >
          <AddButton row>
            <FontAwesomeIcon icon={faPlusSquare} />
            <span>criar</span>
          </AddButton>
        </AddCard>
      </Col>
      {userLoading ? (
        <UserPlaceholder />
      ) : users.length ? (
        users.map(
          user =>
            user.username !== 'root' && (
              <Col key={user.id} xl="3" lg="4" sm="6" xs="12">
                <UserCard user={user} setUsers={setUsers} users={users} />
              </Col>
            ),
        )
      ) : (
        <NoResultsDetail row width="230px" height="50px">
          <FontAwesomeIcon icon={faUserCircle} />
          Nenhum usuário.
        </NoResultsDetail>
      )}
    </Row>
  );
};

export default Users;
