import styled, { css } from 'styled-components';

interface ITabProps {
  active?: boolean;
  confirm?: boolean;
  disabled?: boolean;
}

export const Tabs = styled.ul`
  list-style: none;
  display: flex;

  padding-right: 17px;
`;

export const Tab = styled.li<ITabProps>`
  font-size: 14px;
  color: ${props => props.theme.interface5};
  padding: 10px 40px;
  width: 100%;
  cursor: pointer;
  text-align: center;
  border-bottom: 3px solid transparent;
  transition: border 100ms ease-in-out;

  ${props =>
    props.active &&
    css`
      border-bottom: 3px solid
        ${props.confirm ? props.theme.success : props.theme.brand_primary_color};
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      color: ${props.confirm
        ? props.theme.interface5
        : props.theme.brand_primary_color};
    `}

  ${props =>
    props.confirm &&
    css`
      svg {
        margin-right: 10px;
        color: ${props.active ? props.theme.success : props.theme.interface5};
      }
    `}

  ${props =>
    props.disabled &&
    css`
      opacity: 0.6;
    `}
`;

export const TabContent = styled.div`
  height: auto;
  width: 100%;
  background: ${props => props.theme.interface1};
  border-radius: 5px;
  padding: 18px 12px;
`;
