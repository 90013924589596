import React from 'react';
import {
  StepperContainer,
  StepContainer,
  StepMain,
  Connector,
  ConnectorContainer,
  Step,
  LabelContainer,
} from './styles';

interface Step {
  label: string;
}

interface StepperProps {
  steps: Step[];
  activeStep: number;
  width?: string;
}

const Stepper = ({ steps, activeStep, width }: StepperProps) => {
  return (
    <StepperContainer width={width}>
      {steps?.map((item, index) => (
        <StepContainer>
          <StepMain>
            <>
              {index !== 0 && (
                <ConnectorContainer>
                  <Connector />
                </ConnectorContainer>
              )}
              <Step active={activeStep === index + 1}>
                <span>{index + 1}</span>
              </Step>
              <LabelContainer>
                <span>{item.label}</span>
              </LabelContainer>
            </>
          </StepMain>
        </StepContainer>
      ))}
    </StepperContainer>
  );
};

export default Stepper;
