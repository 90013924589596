import React from 'react';

import Skeleton from 'react-loading-skeleton';
import { Card } from '../CardsSession/styles';

export default function DevicePlaceholder() {
  return (
    <Card>
      <div className="title">
        <Skeleton circle width={40} height={40} />
        <Skeleton width={200} height={16} />
      </div>
      <div className="info">
        <Skeleton width={300} height={15} />
      </div>
      <div className="info">
        <Skeleton width={300} height={15} />
      </div>
      <div className="info">
        <Skeleton width={300} height={15} />
      </div>
      <div className="info">
        <Skeleton width={130} height={15} />
      </div>
    </Card>
  );
}
