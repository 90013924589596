import React from 'react';
import { createBrowserHistory } from 'history';
import { toast } from 'react-toastify';

import UnapliedChangesToast from 'components/UnapliedChangesToast';

const history = createBrowserHistory({
  getUserConfirmation(message, callback) {
    toast.info(<UnapliedChangesToast message={message} callback={callback} />);
  },
});

export default history;
