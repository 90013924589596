import styled, { css } from 'styled-components';

export const DropdownList = styled.ul`
  width: 110px;
  height: auto;

  position: absolute;
  background: ${props => props.theme.interface1};
  box-shadow: 0px 2px 15px rgba(100, 114, 140, 0.25);
  border-radius: 5px;
  z-index: 999;

  @media (max-width: 768px) {
    display: none;
  }
`;

interface ItemListProps {
  selected?: boolean;
}

export const ItemList = styled.li<ItemListProps>`
  ${props =>
    props.selected &&
    css`
      background: ${props => props.theme.interface2};
      color: ${props => props.theme.brand_primary_color} !important;
    `}

  font-size: 14px !important;
  text-align: left;
  position: relative;
  color: ${props => props.theme.interface5};
  text-transform: none;
  padding: 10px 25px;
  cursor: pointer;

  svg {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 6px;
    font-size: 10px;
    color: ${props => props.theme.brand_primary_color};
  }

  &:hover {
    transition: background 0.1s ease-in;
    background: ${props => props.theme.interface2};
    color: ${props => props.theme.brand_primary_color};
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const DownloadNF = styled.button`
  color: #2253f5;
  font-size: 0.75rem;
  font-weight: bold;
  border: none;
  background: none;
  text-decoration: underline;
  z-index: 99999;
`;
