// import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { darken } from 'polished';
import styled from 'styled-components';

export const ValueText = styled.span`
  display: block;
  font-size: 12px;
  color: ${props => props.theme.interface5};
  margin-top: -10px;
`;

export const Label = styled.span`
  display: block;
  font-size: 12px;
  margin-bottom: 4px;
  color: ${props => darken(0.2, props.theme.interface4)};
  svg {
    font-size: 12px;
  }
  text-transform: uppercase;

  .customTooltip {
    display: flex;
    flex-direction: column;

    background: ${props => props.theme.interface1} !important;
    color: ${props => props.theme.interface5} !important;

    border: 1px solid ${props => props.theme.labelNew} !important;
    max-width: 326px !important;

    padding: 10px 11px 9px 11px !important;

    text-transform: none;

    border-radius: 5px !important;

    font-size: 12px;

    line-height: 14.4px;

    .title {
      font-weight: bold;
    }
  }
`;

export const Info = styled(FontAwesomeIcon).attrs(() => ({
  icon: faQuestionCircle,
}))`
  color: ${props => darken(0.2, props.theme.interface4)};
  margin-left: 5px;

  &:hover {
    color: ${props => props.theme.brand_primary_color};
  }
`;

export const Popover = styled.div`
  max-width: 260px;
  padding: 10px;

  margin-bottom: 10px;

  position: relative;

  background: ${props => props.theme.interface1};
  text-align: center;

  border-radius: 5px;
  border: 1px solid ${props => props.theme.brand_primary_color};

  font-size: 12px;
  color: ${props => props.theme.brand_primary_color};
`;
