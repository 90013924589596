/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useCallback, useState } from 'react';

import { Input, Field, Error } from '@cloudez/cloudez-design-system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Tab, Tabs } from 'components/Tabs';
import SearchInput from 'components/SearchInput';
import { getAvailableDomainsService } from 'services/domain';
import CurrencyFormat from 'react-currency-format';
import { Price } from 'components/DomainBuy/DomainAvaiable/styles';
import {
  ContentWrapper,
  Title,
  Subtitle,
  StageButton,
  DomainField,
} from '../styles';
import { AvaiableDomains, AvaiableDomain } from './styles';

const Domain = ({
  setNewDomain,
  newDomain,
  createDomain,
  loading,
  nextStage,
  errors,
}) => {
  const [tab, setTab] = useState(1);
  const [_loading, setLoading] = useState(false);

  const [domainToBuy, setDomainToBuy] = useState('');
  const [domains, setDomains] = useState([]);
  const [_errors, setErrors] = useState('');

  const searchDomains = useCallback(async q => {
    setLoading(true);

    const tlds = ['.com.br', '.com', '.net'];

    const tld = q.substring(q.indexOf('.'));

    const _domain = q.replace(tld, '');

    if (tlds.indexOf(tld) === -1) {
      tlds.push(tld);
    }

    const _domains = [];

    for (const tld of tlds) {
      try {
        const { data } = await getAvailableDomainsService({
          has_domain: false,
          domain: _domain + tld,
        });

        _domains.push(data);
      } catch (e) {
        setErrors(e?.response?.data);
        console.log(e);
      }
    }

    setDomains(_domains);

    setLoading(false);
  }, []);

  return (
    <ContentWrapper>
      <Title>Associar domínio</Title>
      <Subtitle>Aponte seu domínio para nós</Subtitle>

      <Tabs
        style={{
          margin: '30px auto 0',
          maxWidth: '600px',
        }}
      >
        <Tab
          active={tab === 1}
          onClick={() => {
            setTab(1);
            setNewDomain({
              domain: null,
              domain_do_hire: false,
            });
          }}
        >
          Sim, vou usar um meu
        </Tab>
        <Tab
          active={tab === 2}
          onClick={() => {
            setTab(2);
            setNewDomain({
              domain: null,
              domain_do_hire: false,
            });
          }}
        >
          Não, vou comprar um domínio
        </Tab>
      </Tabs>

      <DomainField>
        {tab === 1 && (
          <>
            <Field>
              <Input
                block
                value={newDomain.domain}
                placeholder="Digite seu domínio"
                name="domain"
                onChange={e =>
                  setNewDomain({
                    ...newDomain,
                    domain: e.target.value,
                  })
                }
              />
              {(errors?.domain || errors?.domain?.domain) && (
                <Error>{errors?.domain[0] || errors?.domain?.domain}</Error>
              )}
            </Field>
            <Subtitle>
              Colocando seu domínio aqui, seu website não ficará fora do ar.
            </Subtitle>
          </>
        )}

        {tab === 2 && (
          <>
            <Field>
              <SearchInput
                callback={searchDomains}
                // noResults={clouds?.clouds?.length === 0}
                block
                value={domainToBuy}
                placeholder="Digite seu domínio"
                name="domain"
                loading={_loading}
              />
              {(errors?.domain || errors?.domain?.domain) && (
                <Error>{errors?.domain[0] || errors?.domain?.domain}</Error>
              )}
            </Field>

            <AvaiableDomains>
              {domains.map(d => (
                <AvaiableDomain
                  onClick={() => {
                    setDomainToBuy(d.domain);
                    setNewDomain({
                      ...newDomain,
                      ...d,
                      domain_do_hire: tab === 2,
                    });
                  }}
                  key={d.domain}
                  selected={d.domain === domainToBuy}
                >
                  {d.domain}
                  {d.is_available ? (
                    <Price>
                      <CurrencyFormat
                        value={d.price.amount}
                        displayType="text"
                        fixedDecimalScale
                        decimalScale={2}
                        decimalSeparator=","
                        thousandSeparator="."
                        prefix={d.price.currency === 'BRL' ? 'R$ ' : '$'}
                      />
                    </Price>
                  ) : (
                    <p>Indisponível</p>
                  )}
                </AvaiableDomain>
              ))}
            </AvaiableDomains>
          </>
        )}
      </DomainField>

      <StageButton
        icon
        disabled={loading || !newDomain.domain}
        type="submit"
        onClick={createDomain}
      >
        {loading ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : (
          'Associar domínio'
        )}
      </StageButton>
      <StageButton
        outline
        onClick={() => nextStage()}
        icon
        disabled={loading}
        type="button"
      >
        Pular
      </StageButton>
    </ContentWrapper>
  );
};

export default Domain;
