import React, {
  useContext,
  useMemo,
  useState,
  useCallback,
  useRef,
} from 'react';

import { getValue } from 'utils/getValue';
import NodeappField from 'components/NodeappField';
import { Button } from '@cloudez/cloudez-design-system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLightbulb } from '@fortawesome/free-regular-svg-icons';
import { faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useSpring, animated } from 'react-spring';
import { useLayout } from 'hooks/layout';
import { useAuth } from 'hooks/auth';
import { NodeAppField } from 'types';
import { WebsiteListingContext } from '../../_context/state';
import { Title, CardItem } from './styles';

interface OptimizationsProps {
  website: any;
  animStyles: any;
  analysis: any;
}

const Optimizations: React.FC<OptimizationsProps> = ({
  website,
  animStyles,
  analysis,
}) => {
  const [hasChanged, setHasChanged] = useState(false);

  const { user } = useAuth();

  const { updateField, updateWebsite, updateLoading } = useContext(
    WebsiteListingContext,
  );

  const types = useMemo(() => {
    return {
      https: {
        name: 'Enable HTTPS',
        name_br: 'HTTPS',
        slug: 'https_enable',
        default: 'false',
        choices: [],
        widget: 'c',
      } as NodeAppField,
      cdn: {
        name: 'Use CDN',
        name_br: 'CDN',
        slug: 'use_cdn',
        default: 'false',
        choices: [],
        widget: 'c',
      } as NodeAppField,
      pagespeed: {
        name: 'Enable Pagespeed',
        name_br: 'Pagespeed',
        slug: 'pagespeed',
        default: 'false',
        choices: [],
        widget: 'c',
      } as NodeAppField,
    };
  }, []);

  const data = useMemo(() => {
    return {
      use_cdn: getValue(website, 'use_cdn'),
      pagespeed: getValue(website, 'pagespeed'),
      https_enable: getValue(website, 'https_enable'),
      can_cdn: website?.can_cdn,
    };
  }, [website]);

  const onChange = useCallback(
    e => {
      updateField(
        e.target.name,
        e.target.type === 'checkbox' ? e.target.checked : e.target.value,
        website,
      );
      setHasChanged(true);
    },
    [website, updateField],
  );

  const applyChange = useCallback(async () => {
    await updateWebsite(null, null, null, website);
    setHasChanged(false);
  }, [website, updateWebsite]);

  return (
    <div
      style={{
        padding: '0 15px',
      }}
    >
      <Title
        style={{
          marginBottom: 20,
        }}
      >
        Insights
      </Title>
      <ul>
        <animated.div style={animStyles}>
          {analysis?.is_waiting_analysis && (
            <CardItem alert>
              <FontAwesomeIcon icon={faLightbulb} />
              <div>
                Seu website já esta sendo analisado. Por favor, volte em alguns
                instantes
              </div>
            </CardItem>
          )}
          {!website.is_hosted && (
            <CardItem alert>
              <FontAwesomeIcon icon={faLightbulb} />
              <div>
                Para realizar a análise da sua aplicação e utilizar os recursos
                de otimizações, aponte a aplicação para seu cloud. <br /> <br />{' '}
                Para realizar a troca de DNS siga o passo a passo{' '}
                <a
                  href="https://help.cloudez.io/onde-encontrar-as-informacoes-de-dns/"
                  target="_blank"
                  rel="noreferrer"
                >
                  neste guia
                </a>
                . <br /> <br /> Ou se deseja apontar sua aplicação por IP para o
                cloud siga o passo a passo{' '}
                <a
                  href="https://help.cloudez.io/onde-encontrar-o-endereco-de-ip-do-seu-cloud/"
                  target="_blank"
                  rel="noreferrer"
                >
                  neste guia
                </a>
                .
              </div>
            </CardItem>
          )}
        </animated.div>
        {website.is_hosted && (
          <>
            {((user?.is_new_acquisition && website.cloud.is_turbo) ||
              !user?.is_new_acquisition) && (
              <>
                <CardItem success={data.pagespeed === 'true'}>
                  {data.pagespeed === 'true' ? (
                    <FontAwesomeIcon icon={faCheck} />
                  ) : (
                    <FontAwesomeIcon icon={faLightbulb} />
                  )}
                  Ative o PageSpeed e melhore o desempenho e otimize seu website
                  conforme recomendações do Google.
                </CardItem>
                {data.can_cdn && (
                  <CardItem success={data.use_cdn === 'true'}>
                    {data.use_cdn === 'true' ? (
                      <FontAwesomeIcon icon={faCheck} />
                    ) : (
                      <FontAwesomeIcon icon={faLightbulb} />
                    )}
                    Ative o CDN para garanta maior performance aos usuários de
                    seus websites, APIs, ou aplicações web e mobile no Brasil.
                  </CardItem>
                )}
              </>
            )}
            <CardItem success={data.https_enable === 'true'}>
              {data.https_enable === 'true' ? (
                <FontAwesomeIcon icon={faCheck} />
              ) : (
                <FontAwesomeIcon icon={faLightbulb} />
              )}
              Ative o HTTPS e insira uma camada de proteção na transmissão de
              dados entre os usuários e o servidor.
            </CardItem>
            {user?.is_new_acquisition && !website.cloud.is_turbo && (
              <CardItem>
                <FontAwesomeIcon icon={faLightbulb} />
                <div>
                  Realize o upgrade do seu cloud <b>BASIC</b> para o TURBO para
                  ter acesso aos recursos de otimizações <b>CDN</b> e PageSpeed.
                </div>
              </CardItem>
            )}
          </>
        )}
      </ul>

      <Title
        style={{
          margin: '0',
          marginTop: 20,
          marginBottom: 20,
        }}
      >
        Otimizações
      </Title>
      <div
        style={{
          display: 'flex',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <NodeappField
          field={types.https}
          value={data.https_enable}
          onChange={onChange}
          disabled={!website.is_hosted}
        />
        <NodeappField
          field={types.cdn}
          value={data.use_cdn}
          onChange={onChange}
          disabled={
            !website.is_hosted ||
            !data.can_cdn ||
            (user?.is_new_acquisition && !website?.cloud.is_turbo)
          }
        />
        <NodeappField
          field={types.pagespeed}
          value={data.pagespeed}
          onChange={onChange}
          disabled={
            !website.is_hosted ||
            (user?.is_new_acquisition && !website?.cloud.is_turbo)
          }
        />
      </div>
      {hasChanged && (
        <Button
          style={{
            fontSize: 11,
            width: 90,
            height: 30,
          }}
          icon
          success
          onClick={applyChange}
          disabled={updateLoading}
          className="ml-auto"
        >
          {updateLoading ? (
            <>
              <FontAwesomeIcon icon={faSpinner} spin />
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faCheck} />
              Aplicar
            </>
          )}
        </Button>
      )}
    </div>
  );
};

export default Optimizations;
