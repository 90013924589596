import api from 'services/api';

export const getDatabasesService = async (
  params,
  ctrl = new AbortController(),
) => {
  try {
    return await api.get('/v3/database/', { params, signal: ctrl.signal });
  } catch (e) {
    throw e;
  }
};

export const getDatabaseService = async (id, params?) => {
  try {
    return await api.get(`/v3/database/${id}/`, { params });
  } catch (e) {
    throw e;
  }
};

export const deleteDatabaseService = async id => {
  try {
    return await api.delete(`/v3/database/${id}/`);
  } catch (e) {
    throw e;
  }
};

export const updateDatabaseService = async (id, data) => {
  try {
    return await api.patch(`/v3/database/${id}/`, data);
  } catch (e) {
    throw e;
  }
};

export const createDatabaseService = async data => {
  try {
    return await api.post(`/v3/database/`, data);
  } catch (e) {
    throw e;
  }
};

export const getDatabaseUsersService = async params => {
  try {
    return await api.get(`/v3/database-user/`, { params });
  } catch (e) {
    throw e;
  }
};

export const createDatabaseUserService = async payload => {
  try {
    return await api.post(`/v3/database-user/`, payload);
  } catch (e) {
    throw e;
  }
};

export const updateDatabaseUserService = async (id, payload) => {
  try {
    return await api.patch(`/v3/database-user/${id}/`, payload);
  } catch (e) {
    throw e;
  }
};

export const deleteDatabaseUserService = async id => {
  try {
    return await api.delete(`/v3/database-user/${id}/`);
  } catch (e) {
    throw e;
  }
};

export const getDatabaseTypesService = async params => {
  try {
    return await api.get(`/v3/database-type/`, {
      params,
    });
  } catch (e) {
    throw e;
  }
};
