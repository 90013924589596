import React, { useState } from 'react';

import { faSadTear, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Modal, Button } from '@cloudez/cloudez-design-system';

import { CustomInput } from 'components/NewComponents';

import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from 'components/ListingModal/styles';

import { ModalProps } from 'types';
import { HttpsText } from './styles';

interface IRemoveModalProps extends ModalProps {
  action: () => Promise<void>;
}

const RemoveModal = ({
  action,
  show,
  setShow,
}: IRemoveModalProps): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [remove, setRemove] = useState('');

  return (
    <Modal show={show} setShow={setShow} noExit={loading}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faSadTear} size="2x" />
        </Icon>
        <Text>
          <Title>Remover sua conta</Title>
          <Subtitle>Deseja remover sua conta?</Subtitle>
        </Text>
      </Header>

      <Content>
        <HttpsText>
          Por favor, escreva <span className="error">REMOVER</span> para
          confirmar a exclusão deste email
        </HttpsText>
        <CustomInput
          name="removeAccountButton"
          block
          placeholder="REMOVER"
          value={remove}
          onChange={e => {
            setRemove(e.target.value);
          }}
        />
      </Content>
      <Footer>
        <Button
          disabled={remove !== 'REMOVER'}
          error
          icon
          onClick={() => {
            setLoading(true);
            action();
          }}
        >
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Remover'}
        </Button>
      </Footer>
    </Modal>
  );
};

export default RemoveModal;
