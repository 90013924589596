import SearchInput from 'components/SearchInput';
import React, { useCallback, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { getAvailableDomainsService } from 'services/domain';
import { Error, Button } from '@cloudez/cloudez-design-system';
import DomainPlaceholder from './DomainPlaceholder';
import DomainAvaiable from './DomainAvaiable';

const DomainBuy = ({ domain, setDomain, buyDomain, setBuyDomain }) => {
  const [loading, setLoading] = useState(false);
  const [domains, setDomains] = useState(null);

  const [errors, setErrors] = useState(null);

  const searchDomains = useCallback(async q => {
    setLoading(true);

    const tlds = ['.com.br', '.com', '.net'];

    const tld = q.substring(q.indexOf('.'));

    const _domain = q.replace(tld, '');

    if (tlds.indexOf(tld) === -1) {
      tlds.push(tld);
    }

    const _domains = [];

    for (const tld of tlds) {
      try {
        const { data } = await getAvailableDomainsService({
          has_domain: false,
          domain: _domain + tld,
        });

        _domains.push(data);
      } catch (e) {
        setErrors(e?.response?.data);
        console.log(e);
      }
    }

    setDomains(_domains);
    setLoading(false);
  }, []);

  return (
    <>
      <SearchInput
        style={{
          width: '100%',
        }}
        value={domain}
        setValue={setDomain}
        callback={searchDomains}
        error={errors}
      />
      <Button
        style={{
          marginLeft: 'auto',
          marginTop: 10,
        }}
        onClick={() => searchDomains(domain)}
      >
        Pesquisar
      </Button>
      <Error>{errors?.domain}</Error>
      <Row
        style={{
          height: 'auto',
          maxWidth: '900px',
          margin: '30px auto 0',
        }}
      >
        {loading ? (
          <DomainPlaceholder />
        ) : (
          domains?.map(d => (
            <Col key={d.id} xs="12">
              <DomainAvaiable
                domain={d}
                buyDomain={buyDomain}
                setBuyDomain={setBuyDomain}
              />
            </Col>
          ))
        )}
      </Row>
    </>
  );
};

export default DomainBuy;
