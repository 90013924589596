import { toast } from 'react-toastify';
import history from 'services/history';
import {
  getMigrationService,
  getSyncsService,
  updateMigrationService,
} from 'services/migration';
import nProgress from 'nprogress';

export const setLoading = (dispatch, value) => {
  dispatch({
    type: 'SET_LOADING',
    payload: value,
  });
};

export const getMigration = async (dispatch, id) => {
  nProgress.start();
  try {
    const { data } = await getMigrationService(id);

    dispatch({
      type: 'GET_MIGRATION',
      payload: data,
    });

    getSyncs(dispatch, id);

    nProgress.done();
  } catch (e) {
    nProgress.done();
    if (e?.response?.status === 404) {
      toast.error('Migração não encontrado');
      history.push('/404');
    }
  }
};

export const getSyncs = async (dispatch, id) => {
  try {
    const { data } = await getSyncsService(id, { migration: id });

    dispatch({
      type: 'GET_SYNCS',
      payload: data,
    });
  } catch (e) {
    nProgress.done();
  }
};

export const updateField = (state, dispatch, field, value) => {
  dispatch({
    type: 'UPDATE_FIELD',
    payload: {
      ...state.migration,
      [field]: value,
    },
  });
};

export const patchMigration = async (dispatch, field, value, id) => {
  setLoading(dispatch, true);
  try {
    dispatch({
      type: 'SET_ERRORS',
      payload: null,
    });

    const { data } = await updateMigrationService(id, {
      [field]: value,
    });

    dispatch({
      type: 'PATCH_MIGRATION',
      payload: data,
    });

    setLoading(dispatch, false);
    toast.success('Suas alterações estão sendo aplicadas.');
  } catch (error) {
    dispatch({
      type: 'SET_ERRORS',
      payload: error?.response?.data,
    });

    setLoading(dispatch, false);
    toast.error('Algo não está certo.');
  }
};
