import React, { useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faSpinner } from '@fortawesome/free-solid-svg-icons';
import * as Yup from 'yup';

import {
  Modal,
  Label,
  Form,
  Button,
  SearchSelect,
  Input,
  FormInput,
} from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from 'components/ListingModal/styles';

import { getTicketIssuesService } from 'services/ticket';
import { getUserService, getUsersService } from 'services/user';
import { ModalProps } from 'types';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';

const issueSchema = Yup.object().shape({
  issue: Yup.string().required('Este campo é obrigatório'),
  whohelped: Yup.string(),
  comments: Yup.string().default('').required('Este campo é obrigatório'),
});

interface IssueModalProps extends ModalProps {
  now: any;
  ticket: any;
}

const IssueModal: React.FC<IssueModalProps> = ({
  action,
  show,
  setShow,
  now,
  ticket,
}) => {
  const [loading, setLoading] = useState(false);
  const [issuesList, setIssuesList] = useState([]);
  const [oldIssue, setOldIssue] = useState(null);
  const [oldWhohelped, setOldWhohelped] = useState(null);

  const teams = {
    1: 'Sup',
    2: 'Fin',
    3: 'Com',
    4: 'Mig',
    5: 'Spam',
    6: 'Ins',
    7: 'WL',
    0: 'Oth',
  };

  const submitForm = data => {
    setLoading(true);
    action(data.issue, data.whohelped, data.comments, now);
    setLoading(false);
    setShow(false);
  };

  useEffect(() => {
    const getIssues = async () => {
      const { data } = await getTicketIssuesService();

      let issues;

      if (ticket.team === 6 || ticket.team === 5) {
        issues = data.filter(item => item.name.startsWith('Sup'));
      } else {
        issues = data.filter(item => item.name.startsWith(teams[ticket.team]));
      }

      const issuesFiltered = issues?.map(i => ({
        value: i.id,
        label: i.name,
      }));

      if (ticket.issue) {
        setOldIssue(
          issuesFiltered.filter(item => item.value === ticket.issue)[0],
        );
      }

      if (ticket.who_helped) {
        try {
          const { data } = await getUserService(ticket.who_helped);
          setOldWhohelped({ value: data.id, label: data.email });
        } catch (e) {
          console.log(e);
        }
      }

      setIssuesList(issuesFiltered);
    };

    getIssues();
  }, []);

  const handleLoadUsers = async searchValue => {
    try {
      const res = await getUsersService({
        staff: true,
        search: searchValue,
      });

      return res.data.results.map(s => ({
        value: s.id,
        label: s.email,
      }));
    } catch (e) {
      console.log(e);
      return [];
    }
  };

  return (
    <Modal show={show} setShow={setShow} noExit={loading}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faTrashAlt} />
        </Icon>
        <Text>
          <Title>Fechar ticket</Title>
          <Subtitle>Descrição do problema</Subtitle>
        </Text>
      </Header>

      <Content>
        <Form
          id="issueForm"
          schema={issueSchema}
          onSubmit={submitForm}
          initialData={{
            comments: ticket.comments,
          }}
        >
          <div
            style={{
              width: '100%',
              marginBottom: '20px',
            }}
          >
            <Label>QUAL FOI O PROBLEMA NESTE TICKET?</Label>
            <SearchSelect
              placeholder="Escolha um problema"
              loadOptions={issuesList}
              async={false}
              name="issue"
              autoFocus
              defaultValue={oldIssue}
            />
          </div>
          <div
            style={{
              width: '100%',
              marginBottom: '20px',
            }}
          >
            <Label>ALGUÉM TE AJUDOU NA SOLUÇÃO? </Label>
            <SearchSelect
              placeholder="Escolha um usuário"
              loadOptions={handleLoadUsers}
              name="whohelped"
              defaultValue={oldWhohelped}
            />
          </div>
          <div
            style={{
              width: '100%',
              marginBottom: '20px',
            }}
          >
            <Label>COMENTÁRIO</Label>
            <FormInput block placeholder="Comentário" name="comments" />
          </div>
        </Form>
      </Content>
      <Footer>
        <Button
          type="submit"
          form="issueForm"
          icon
          disabled={loading}
          // onClick={() => {
          //
          // }}
        >
          {loading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            'Fechar ticket'
          )}
        </Button>
      </Footer>
    </Modal>
  );
};

export default IssueModal;
