import React, { useState } from 'react';
import { Button } from '@cloudez/cloudez-design-system';

import history from 'services/history';
import { Card, Container, PlanType } from './styles';

const Suggestion = ({ setStage }) => {
  const [selectedCard, setSelectedCard] = useState<number>();

  const recommendations = [
    {
      id: 1,
      values: ['dedicated', 'turbo'],
      title: 'Alta performance para aumento de resultados na web',
      subtitle: 'Landing pages, sites altamente profissionais, SEO, blogs, etc',
      types: {
        dedicated: true,
        shared: false,
      },
    },
    {
      id: 2,
      values: ['dedicated', 'shared', 'basic'],
      title: 'Qualidade cloud para projetos de exigência intermediária',
      subtitle:
        'Sites corporativos de visitação intermediária, sites de muito conteúdo, etc',
      types: {
        dedicated: true,
        shared: true,
      },
    },
    {
      id: 3,
      title: 'Custo benefício para muitos sites de baixa exigência',
      values: ['shared', 'volume'],
      subtitle:
        'Sites institucionais de baixa visitação, cartões de visita, páginas estáticas, etc',
      types: {
        dedicated: false,
        shared: true,
      },
    },
    // {
    //   id: 4,
    //   title: 'Capacidade de receber grande número simultâneo de visitas',
    //   subtitle: 'Portais de notícias, sites de alta visitação, entre outros',
    //   types: {
    //     dedicated: true,
    //     shared: false,
    //   },
    // },
    // {
    //   id: 5,
    //   title: 'Disponibilidade de recursos para requisições simultâneas',
    //   subtitle:
    //     'Sistemas de alta complexidade com muito processamento (php, node, react, etc)',
    //   types: {
    //     dedicated: true,
    //     shared: false,
    //   },
    // },
    // {
    //   id: 6,
    //   title: 'Rodar sistema de alta exigência sem travamentos',
    //   subtitle:
    //     'Sistemas simples com grande número de usuários (php, node, react, etc)',
    //   types: {
    //     dedicated: true,
    //     shared: false,
    //   },
    // },
    {
      id: 7,
      values: ['email'],
      title: 'E-mail com alta entregabilidade',
      types: {
        email: 'Exclusivo para E-mails',
      },
    },
    // {
    //   id: 8,
    //   title: 'Ver todas as opções',
    // },
  ];

  const filteredRecommendation = recommendations.find(
    item => item.id === selectedCard,
  );

  // const [values, setValues] = useState({
  //   splice: 3,
  //   slice: -1,
  // });

  // recommendations.splice(values.splice, values.splice);

  return (
    <Container>
      {recommendations.map(item => (
        <Card
          selected={selectedCard === item.id}
          onClick={() => setSelectedCard(item.id)}
          key={item.id}
        >
          <span className="title">{item.title}</span>
          {item.subtitle && <span className="subtitle">{item.subtitle}</span>}
          {item.types && (
            <div className="types">
              {item.types.email && (
                <PlanType>
                  <span className="text">{item.types.email}</span>
                </PlanType>
              )}
            </div>
          )}
        </Card>
      ))}

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'center',
          marginTop: '65px',

          gap: '70px',
        }}
      >
        <Button
          text
          outline
          onClick={() => {
            history.push(`?recommendation=turbo`);
            setStage(2);
          }}
        >
          Ver todas as opções
        </Button>

        <Button
          disabled={!selectedCard}
          onClick={() => {
            history.push(
              `?recommendation=${filteredRecommendation.values.toString()}`,
            );
            setStage(2);
          }}
        >
          Gerar recomendação
        </Button>
      </div>
    </Container>
  );
};

export default Suggestion;
