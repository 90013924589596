import React, { createContext, useReducer } from 'react';
import { Email } from 'types/email';
import reducer from './reducer';
import {
  setLoading,
  getEmail,
  updateField,
  updateValue,
  patchEmail,
  deleteEmail,
  addMessage,
} from './actions';

export interface EmailDetailState {
  email: Email;
  updateLoading: boolean;
  errors: any;
  ticket: any;
  messages: any;
  files: any;

  setLoading(value: boolean): void;
  getEmail(id: string | number): Promise<void>;
  deleteEmail(id: string | number): Promise<void>;
  updateField(field: string, value: string): void;
  updateValue(e: Event): void;
  patchEmail(field: string, value: any, id: number | string): Promise<void>;
  addMessage(data: any): Promise<void>;
}

// initial state
const initialState: EmailDetailState = {
  email: null,
  updateLoading: false,
  errors: null,
  ticket: null,
  messages: null,
  files: null,

  setLoading: (value: boolean): void => {},
  getEmail: async (id: string | number) => {},
  deleteEmail: async (id: string | number) => {},
  updateField: (field: string, value: string) => {},
  updateValue: (e: Event) => {},
  patchEmail: async (field: string, value: string, id: number | string) => {},
  addMessage: async (data: any) => {},
};

// context
export const EmailDetailContext = createContext<EmailDetailState>(initialState);

// provider
export const EmailDetailProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <EmailDetailContext.Provider
      value={{
        // state
        email: state.email,
        updateLoading: state.updateLoading,
        errors: state.errors,
        ticket: state.ticket,
        messages: state.messages,
        files: state.files,

        // actions
        setLoading: value => setLoading(dispatch, value),
        getEmail: async id => await getEmail(dispatch, id),
        deleteEmail: async id => await deleteEmail(dispatch, id),
        updateField: (field, value) =>
          updateField(state, dispatch, field, value),
        updateValue: e => updateValue(state, dispatch, e),
        patchEmail: async (field, value, id) =>
          await patchEmail(dispatch, field, value, id),
        addMessage: async data => await addMessage(dispatch, data),
      }}
    >
      {children}
    </EmailDetailContext.Provider>
  );
};
