import { useLayout } from 'hooks/layout';
import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import andre from 'assets/img/andrecardoso.png';
import useDevice from 'hooks/device';
import { Column, Wrapper, Quote } from './styles';

interface IAuthLayoutProps {
  defaultLayout: any;
}

const AuthLayout: React.FC<IAuthLayoutProps> = ({
  children,
  defaultLayout,
}) => {
  const { logo_primary, slug } = useLayout();

  const { isTablet } = useDevice();

  const location = useLocation();

  const isOnboarding = useMemo(() => {
    return (
      (location.pathname === '/onboarding' ||
        location.pathname === '/' ||
        location.pathname === '/register' ||
        location.pathname === '/phone/validate') &&
      slug === 'configr' &&
      !isTablet
    );
  }, [location, slug, isTablet]);

  return (
    <Wrapper defaultLayout={defaultLayout}>
      <Column left>
        <div className="img">
          <img src={logo_primary} alt="Logo" />
        </div>

        {isOnboarding && (
          <Quote>
            <img src={andre} alt="andre" />
            <span>
              André Cardoso <br />
              CEO da AndreBian Soluções Web
            </span>
            <p>
              "Criamos muitos sites em WordPress e a aplicação WordPress da
              Configr é simplesmente sensacional. Basta clicar em "criar" e tudo
              já está rodando".
            </p>
          </Quote>
        )}
      </Column>
      <Column defaultLayout={defaultLayout} right>
        {children}
      </Column>
    </Wrapper>
  );
};

export default AuthLayout;
