import React, { useState, useContext, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';

import { AddButton, AddCard } from 'components/AddCard';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTerminal, faPlusSquare } from '@fortawesome/free-solid-svg-icons';

import { NoResultsDetail } from 'components/Common';

import SearchInput from 'components/SearchInput';
import {
  Pagination,
  createPagination,
  PaginatedResponse,
} from '@cloudez/cloudez-design-system';
import { getWebsiteCronsService } from 'services/website';
import { Cron } from 'types/website';
import CronDetail from './CronDetail';
import CronCard from './CronCard';
import { WebsiteDetailContext } from '../_context/state';

const Crons: React.FC = () => {
  const { website } = useContext(WebsiteDetailContext);

  const [modal, setModal] = useState(false);
  const [crons, setCrons] = useState<PaginatedResponse<Cron>>(
    {} as PaginatedResponse<Cron>,
  );
  const [search, setSearch] = useState('');

  const getCrons = async (page: number | string, search: string) => {
    const { data } = await getWebsiteCronsService({
      search,
      page,
      website: website.id,
      page_size: 7,
    });

    const pagination = createPagination(data, 7);

    setCrons({ data: data.results as Cron[], ...pagination });
  };

  useEffect(() => {
    getCrons(1, '');
  }, []);

  const searchValue = (searchValue: string) => {
    setSearch(searchValue);
    getCrons(1, searchValue);
  };

  return (
    <>
      <Row
        style={{
          width: '100%',
          marginBottom: '24px',
          marginLeft: '0px',
        }}
      >
        <SearchInput
          style={{
            width: '100%',
          }}
          value={search}
          callback={searchValue}
        />
      </Row>
      <Row
        style={{
          marginLeft: '0',
          width: '100%',
        }}
      >
        {modal && (
          <CronDetail show={modal} setShow={setModal} setCrons={setCrons} />
        )}

        <Col xl="3" lg="4" sm="6">
          <AddCard
            onClick={() => {
              setModal(true);
            }}
            width="100%"
            style={{
              marginBottom: '12px',
            }}
          >
            Novo cron
            <AddButton>
              <FontAwesomeIcon icon={faPlusSquare} />
              <span>criar</span>
            </AddButton>
          </AddCard>
        </Col>
        {crons.data ? (
          crons.data.map(c => (
            <Col xl="3" lg="4" sm="6" key={c.id}>
              <CronCard cron={c} setCrons={setCrons} />
            </Col>
          ))
        ) : (
          <NoResultsDetail>
            <FontAwesomeIcon icon={faTerminal} />
            Nenhum cron instalado.
          </NoResultsDetail>
        )}
      </Row>
      <Row
        style={{
          // marginLeft: '0',
          width: '100%',
        }}
      >
        <Pagination
          style={{
            margin: '0 auto',
          }}
          onChangePage={getCrons}
          count={crons.count}
          previous={crons.previous}
          next={crons.next}
          current={crons.current}
          search={search}
          pageSize={7}
        />
      </Row>
    </>
  );
};

export default Crons;
