import React from 'react';
import { AnimatedList } from 'react-animated-list';

import { Col, Row } from 'react-bootstrap';

import Placeholder from 'components/Placeholder';

import {
  Card,
  CardContent,
  Listing,
  ListingItem,
  ApplicationValue,
  CloudValue,
  ListingHeader,
} from 'components/Listing/styles';
import { Acesso, AcessosList, Value } from 'components/Acessos';

const WebsitePlaceholder = () => {
  const placeholders = [];

  for (let i = 1; i <= 4; i++) {
    placeholders.push(i);
  }

  return (
    <>
      <ListingHeader>
        <Row>
          <Col xs="10" md="6" xl="4">
            <p>Aplicação</p>
          </Col>

          <Col className="d-none d-lg-flex" xs="3">
            <p>Performance</p>
          </Col>

          <Col className="d-none d-xl-flex" xl="2">
            <p>Cloud</p>
          </Col>

          <Col className="d-none d-xl-flex">
            <p>Disco</p>
          </Col>

          <Col xl="2" className="d-none d-xl-flex" />
        </Row>
      </ListingHeader>
      <Listing>
        <AnimatedList initialAnimationDuration={800} animation="grow">
          {placeholders.map((p, i) => (
            <ListingItem key={p} fade={i > 0 && i}>
              <Card>
                <CardContent>
                  <Col xs="10" md="6" xl="4">
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: 15,
                      }}
                    >
                      <Placeholder circle width={30} height={30} />
                      <span style={{ marginLeft: 12 }}>
                        <Placeholder width={180} height={12} />
                      </span>
                    </div>
                  </Col>

                  <Col className="d-none d-lg-flex" xl="3">
                    <ApplicationValue
                      style={{
                        justifyContent: 'space-between',
                      }}
                    >
                      <Value>
                        <Placeholder width={80} height={12} />
                      </Value>
                      <Value>
                        <Placeholder width={80} height={12} />
                      </Value>
                      <Value />
                    </ApplicationValue>
                  </Col>

                  <Col className="d-none d-xl-flex" xs="2">
                    <ApplicationValue style={{ fontWeight: 'normal' }}>
                      <CloudValue>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <Placeholder width={50} height={8} />
                          <Placeholder width={20} height={6} />
                        </div>
                      </CloudValue>
                    </ApplicationValue>
                  </Col>

                  <Col className="d-none d-xl-flex">
                    <ApplicationValue>
                      <Placeholder width={30} height={12} />
                    </ApplicationValue>
                  </Col>

                  <Col xl="2" className="d-none d-xl-flex">
                    <AcessosList>
                      <Acesso active hover>
                        <Placeholder width={16} height={18} />
                      </Acesso>

                      <Acesso active hover>
                        <Placeholder width={16} height={18} />
                      </Acesso>

                      <Acesso active hover>
                        <Placeholder width={16} height={18} />
                      </Acesso>

                      <Acesso active hover>
                        <Placeholder width={16} height={18} />
                      </Acesso>

                      <Acesso active hover favorite>
                        <Placeholder width={16} height={18} />
                      </Acesso>
                    </AcessosList>
                  </Col>
                </CardContent>
              </Card>
            </ListingItem>
          ))}
        </AnimatedList>
      </Listing>
    </>
  );
};

export default WebsitePlaceholder;
