import React, { useContext, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faUnlink } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import unlink from 'assets/img/svg/unlink.svg';

import { Modal, Input, Button, TextArea } from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from 'components/ListingModal/styles';

import { useLayout } from 'hooks/layout';
import { HttpsText } from './styles';
import { CloudDetailContext } from '../_context/state';

interface IRemoveModalProps {
  show: boolean;
  setShow: any;
}

const RemoveModal: React.FC<IRemoveModalProps> = ({ show, setShow }) => {
  const [remove, setRemove] = useState('');
  const [motive, setMotive] = useState('');
  const [loading, setLoading] = useState(false);
  const { cloud, deleteCloud } = useContext(CloudDetailContext);
  const theme = useLayout();

  const [confirmRemove, setConfirmRemove] = useState(false);

  const action = async () => {
    await deleteCloud(cloud.id, motive);
  };

  return (
    <Modal width="455px" show={show} setShow={setShow} noExit={loading}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faTrashAlt} />
        </Icon>
        <Text>
          <Title>Excluir cloud</Title>
          <Subtitle>Deseja excluir este cloud?</Subtitle>
        </Text>
      </Header>

      <Content style={{ padding: '17px 19px' }}>
        {confirmRemove ? (
          <HttpsText style={{ margin: confirmRemove ? '10px 0' : '24px' }}>
            <span
              style={{
                textTransform: 'uppercase',
                color: theme.alertNew,
                fontWeight: 'bold',
              }}
            >
              Atenção:
            </span>{' '}
            Tem certeza que quer excluir o cloud e também remover todas as
            cobranças referentes a ele.
          </HttpsText>
        ) : (
          <>
            <HttpsText>
              <span
                style={{
                  textTransform: 'uppercase',
                  color: theme.alertNew,
                  fontWeight: 'bold',
                }}
              >
                AVISO:
              </span>{' '}
              Ao excluir o cloud também será removido todas as cobranças
              referentes a ele.
            </HttpsText>
            <HttpsText>
              Por favor, escreva{' '}
              <span
                style={{
                  textTransform: 'uppercase',
                  color: theme.error,
                  fontWeight: 'bold',
                }}
              >
                REMOVER
              </span>{' '}
              para confirmar a exclusão deste cloud
            </HttpsText>

            <Input
              block
              placeholder="REMOVER"
              value={remove}
              onChange={e => {
                setRemove(e.target.value);
              }}
            />

            <HttpsText>
              No campo abaixo, descreva o motivo da exclusão deste cloud.
            </HttpsText>

            <TextArea
              block
              style={{ resize: 'none' }}
              height="60px"
              placeholder="Escreva o motivo da exclusão"
              value={motive}
              onChange={e => {
                setMotive(e.target.value);
              }}
            />
          </>
        )}
      </Content>
      <Footer>
        <Button
          disabled={
            (remove !== 'REMOVER' && !confirmRemove) ||
            (!motive && !confirmRemove) ||
            loading
          }
          error
          icon
          onClick={() => {
            if (confirmRemove) {
              setLoading(true);
              action();
            } else {
              setConfirmRemove(true);
            }
          }}
        >
          {loading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : confirmRemove ? (
            'Confirmar remoção'
          ) : (
            'Remover'
          )}
        </Button>
      </Footer>
    </Modal>
  );
};

export default RemoveModal;
