import queryString from 'query-string';
import { Reducer } from 'react';
import { IActionPayload } from 'types';
import { createPagination } from '@cloudez/cloudez-design-system';

const reducer: Reducer<any, IActionPayload> = (state, action): any => {
  switch (action.type) {
    case 'GET_INVOICES':
      const invoices = action.payload.results;
      const pagination = createPagination(action.payload, 10);

      return {
        ...state,
        ...pagination,
        invoices,
      };

    case 'UPDATE_INVOICE': {
      return {
        ...state,
        invoices: state?.invoices?.map(invoice =>
          invoice.id === action.payload.id ? action.payload : invoice,
        ),
      };
    }

    case 'SET_LOADING': {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case 'SET_ERRORS': {
      return {
        ...state,
        errors: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
