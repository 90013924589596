import { getValue } from 'utils/getValue';
import { toast } from 'react-toastify';
import history from 'services/history';
import { createWebsiteService } from 'services/website';
import {
  updateDatabaseService,
  createDatabaseService,
} from 'services/database';
import api from 'services/api';

export const setLoading = (dispatch, value) => {
  dispatch({
    type: 'SET_LOADING',
    payload: value,
  });
};

export const setNotRecommended = (dispatch, value) => {
  dispatch({
    type: 'SET_NOT_RECOMMENDED',
    payload: value,
  });
};

export const updateWebsiteValue = (state, dispatch, e) => {
  const values = state.website.values.map(_value => {
    if (_value.slug === e.target.name) {
      _value.value =
        e.target.type === 'checkbox'
          ? e.target.checked.toString()
          : e.target.value;
      return _value;
    }

    return _value;
  });

  if (e.target.name === 'domain') {
    dispatch({
      type: 'UPDATE_VALUE',
      payload: {
        ...state.website,
        values,
        domain: e.target.value,
      },
    });
  } else {
    dispatch({
      type: 'UPDATE_VALUE',
      payload: {
        ...state.website,
        values,
      },
    });
  }
};

export const updateWebsiteField = (state, dispatch, field, value) => {
  dispatch({
    type: 'UPDATE_WEBSITE_FIELD',
    payload: {
      ...state.website,
      [field]: value,
    },
  });
};

export const updateWebsite = (dispatch, website) => {
  dispatch({
    type: 'UPDATE_WEBSITE',
    payload: website,
  });
};

export const updateDatabase = (dispatch, database) => {
  dispatch({
    type: 'UPDATE_DATABASE',
    payload: database,
  });
};

export const updateDatabaseValue = (state, dispatch, e) => {
  const values = state.database.values.map(_value => {
    if (_value.slug === e.target.name) {
      _value.value =
        e.target.type === 'checkbox'
          ? e.target.checked.toString()
          : e.target.value;
      return _value;
    }

    return _value;
  });

  dispatch({
    type: 'UPDATE_DATABASE_VALUE',
    payload: {
      ...state.database,
      values,
    },
  });
};

export const updateDatabaseField = (state, dispatch, field, value) => {
  dispatch({
    type: 'UPDATE_DATABASE_FIELD',
    payload: {
      ...state.database,
      [field]: value,
    },
  });
};

export const createWebsite = async (
  dispatch,
  website,
  db,
  noDb,
  buyDomain,
  isProvider,
) => {
  setLoading(dispatch, true);
  try {
    const create_database_automatically = getValue(
      website,
      'create_database_automatically',
    );

    const payload = {
      ...website,
      values: [
        ...website.values,
        {
          slug: 'create_database_automatically',
          value: db || noDb ? false : create_database_automatically || false,
        },
      ],
    };

    if (isProvider) {
      delete payload.cloud;
    }

    const { data } = isProvider
      ? await api.post(`/v3/website/share-website/`, payload)
      : await createWebsiteService(payload);

    if (db && !noDb) {
      if (db.patch) {
        await updateDatabaseService(db.id, {
          websites: [...db.websites, data.id],
        });
      } else {
        await createDatabaseService({
          ...db,
          websites: [data.id],
          cloud: data.cloud,
        });
      }
    }

    // yield put(createWebsiteSuccess(data));
    setLoading(dispatch, true);
    if (!buyDomain) {
      history.push({
        pathname: `/websites/${data.id}`,
        state: {
          is_new: true,
        },
      });
    } else {
      dispatch({
        type: 'CREATE_WEBSITE_DOMAIN',
        payload: data,
      });
    }
  } catch (e) {
    setLoading(dispatch, false);

    dispatch({
      type: 'CREATE_WEBSITE_ERROR',
      payload: e?.response?.data,
    });
    // yield put(createWebsiteFailure(e.response?.data));
  }
};
