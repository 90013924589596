import React, { useState, useMemo, useEffect, useContext } from 'react';
import Slider from 'react-slick';

import { Row, Col } from 'react-bootstrap';

import { Arrows, Button } from '@cloudez/cloudez-design-system';
import Placeholder from 'components/Placeholder';
import {
  faChevronRight,
  faChevronLeft,
  faUserSecret,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CurrencyFormat from 'react-currency-format';
import EmptyListing from 'components/EmptyListing';

import { CreateButtons } from 'components/Common';
import { getPlanTypesService } from 'services/common';
import { getValue } from 'utils/getValue';
import api from 'services/api';
import history from 'services/history';
import { WebsiteCreateContext } from '../_context/state';
import { Wrapper, PlanCard, Price, Private } from './styles';

const SharingWebsitePlans = ({ setStage, setIsSharing }) => {
  const { website } = useContext(WebsiteCreateContext);
  const [plans, setPlans] = useState(null);
  const [plan, setPlan] = useState(null);
  const [loading, setLoading] = useState(false);

  const submit = async () => {
    try {
      const payload = {
        type: plan.id,
        domain: getValue(website, 'domain'),
        website_type: website.type,
      };
      const { data } = await api.post('/v3/setup/shared/website/', payload);
      history.push(`/invoices/${data.invoice}`);
    } catch (e) {
      console.log(e);
    }
  };

  function NextArrow(props) {
    const { onClick } = props;
    return (
      <Arrows
        right
        onClick={onClick}
        icon={faChevronRight}
        disabled={props.className.includes('slick-disabled')}
      />
    );
  }

  function PrevArrow(props) {
    const { onClick } = props;

    return (
      <Arrows
        left
        onClick={onClick}
        icon={faChevronLeft}
        disabled={props.className.includes('slick-disabled')}
      />
    );
  }

  const settings = useMemo(
    () => ({
      // centerMode: true,
      infinite: false,
      focusOnSelect: true,
      speed: 300,
      slidesToShow: 3,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    }),
    [],
  );

  const placeholders = [];

  for (let i = 1; i <= 5; i++) {
    placeholders.push(i);
  }

  useEffect(() => {
    async function getWebsitePlans() {
      setLoading(true);
      const { data } = await getPlanTypesService({
        category: 9,
      });
      setPlans(data);
      setLoading(false);
    }

    getWebsitePlans();
  }, []);

  return (
    <>
      <Wrapper>
        <Row>
          <Col
            xs="12"
            style={{
              marginBottom: '25px',
            }}
          >
            <Slider {...settings}>
              {!loading ? (
                plans?.results?.length > 0 ? (
                  plans?.results?.map(p => {
                    return (
                      <div key={p.id}>
                        <PlanCard
                          private={p.is_private}
                          selected={p.id === plan?.id}
                          onClick={() => setPlan(p)}
                        >
                          {p.is_private && (
                            <Private>
                              <FontAwesomeIcon icon={faUserSecret} />
                            </Private>
                          )}
                          <Price>
                            <h3>{p.name}</h3>

                            <span>
                              <CurrencyFormat
                                style={{ fontSize: '30px' }}
                                value={p.price_total?.amount}
                                displayType="text"
                                fixedDecimalScale
                                decimalScale={2}
                                decimalSeparator=","
                                thousandSeparator="."
                                prefix={
                                  p.price_total?.currency === 'BRL'
                                    ? 'R$ '
                                    : '$'
                                }
                              />
                              <p>mensal</p>
                            </span>
                          </Price>
                        </PlanCard>
                      </div>
                    );
                  })
                ) : (
                  <EmptyListing>
                    <p>Nenhum plano encontrado.</p>
                  </EmptyListing>
                )
              ) : (
                placeholders.map((p, i) => (
                  <div key={i}>
                    <PlanCard>
                      <Placeholder width={28} height={12} />
                      <Placeholder width={80} height={60} />
                      <div>
                        <Placeholder width={100} height={12} />
                        <Placeholder width={100} height={12} />
                        <Placeholder width={100} height={12} />
                      </div>
                      <div>
                        <span>
                          <Placeholder width={40} height={12} />
                        </span>
                        <span>
                          <Placeholder width={40} height={12} />
                        </span>
                        <span>
                          <Placeholder width={40} height={12} />
                        </span>
                      </div>
                    </PlanCard>
                  </div>
                ))
              )}
            </Slider>
          </Col>
        </Row>
      </Wrapper>
      <CreateButtons>
        <Button
          outline
          onClick={() => {
            setStage(3);
            setIsSharing(true);
          }}
          style={{
            marginTop: '20px',
          }}
        >
          Voltar
        </Button>
        <Button
          style={{
            marginTop: '20px',
          }}
          icon
          onClick={submit}
          data-id="websites-finalbtn"
        >
          Finalizar
        </Button>
      </CreateButtons>
    </>
  );
};

export default SharingWebsitePlans;
