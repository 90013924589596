import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faShareAlt,
  faSpinner,
  faPlus,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

import { Button } from '@cloudez/cloudez-design-system';

import { toast } from 'react-toastify';
import { shareResourceService, unshareResourceService } from 'services/common';
import { CustomInput } from 'components/NewComponents';
import { Header, Icon, Text, Title, Subtitle, Footer } from '../styles';

import { EmailBox, SharedUser } from './styles';

const ShareModal = ({ data, setShow, action, loading, setLoading }) => {
  const [email, setEmail] = useState('');

  const [users, setUsers] = useState(data.shared_users);

  const share = async () => {
    setLoading(true);
    try {
      const type =
        'total_accounts' in data
          ? 'email'
          : 'websites' in data
          ? 'database'
          : 'nameservers' in data
          ? 'domain'
          : 'website';

      const response = await shareResourceService(type, data.id, email);

      await action('shared_users', response.data.share, data.id);
      setLoading(false);
      toast.success(
        `${
          'total_accounts' in data
            ? 'Email'
            : 'websites' in data
            ? 'Database'
            : 'nameservers' in data
            ? 'Domínio'
            : 'Website'
        } compartilhado com sucesso`,
      );
      setEmail('');
      setUsers([...users, email]);
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data[Object.keys(e?.response?.data)[0]][0]);
    }
  };

  const unshare = async user => {
    setLoading(true);
    try {
      const type =
        'total_accounts' in data
          ? 'email'
          : 'websites' in data
          ? 'database'
          : 'nameservers' in data
          ? 'domain'
          : 'website';

      const response = await unshareResourceService(type, data.id, user);

      await action('shared_users', response.data.share, data.id);
      setLoading(false);
      toast.success(
        `${
          'total_accounts' in data
            ? 'Email'
            : 'websites' in data
            ? 'Database'
            : 'nameservers' in data
            ? 'Domínio'
            : 'Website'
        } descompartilhado com sucesso`,
      );
      const _users = users.filter(u => u !== user);
      setUsers(_users);
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data[Object.keys(e?.response?.data)[0]][0]);
    }
  };

  return (
    <>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faShareAlt} />
        </Icon>
        <Text>
          <Title>
            Compartilhar{' '}
            {'total_accounts' in data
              ? 'Email'
              : 'websites' in data
              ? 'database'
              : 'nameservers' in data
              ? 'domínio'
              : 'Website'}
          </Title>
          <Subtitle>
            Gerencie o compartilhamento do seu{' '}
            {'total_accounts' in data
              ? 'email'
              : 'websites' in data
              ? 'database'
              : 'nameservers' in data
              ? 'domínio'
              : 'website'}
          </Subtitle>
        </Text>
      </Header>
      <div style={{ padding: '17px 28px', width: '100%' }}>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
          <span>
            Insira abaixo o(s) email(s) desejado(s) para compartilhar este{' '}
            {'total_accounts' in data
              ? 'email'
              : 'websites' in data
              ? 'database'
              : 'nameservers' in data
              ? 'domínio'
              : 'website'}
            .
          </span>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: '20x',
              width: '100%',
            }}
          >
            <CustomInput
              width="330px"
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder="Adicionar email"
              name="add_email"
            />
            <Button
              onClick={share}
              disabled={loading}
              outline
              width="40px"
              icon
            >
              <FontAwesomeIcon
                icon={loading ? faSpinner : faPlus}
                spin={loading}
              />
            </Button>
          </div>

          <EmailBox
            style={{
              maxHeight: users.length ? '122px' : '40px',
            }}
          >
            {users.length ? (
              users.map(user => (
                <div className="emails" key={user.id}>
                  <SharedUser key={user}>{user}</SharedUser>
                  <FontAwesomeIcon
                    onClick={() => (!loading ? unshare(user) : null)}
                    icon={faTimes}
                    style={{
                      cursor: 'pointer',
                      marginLeft: '10px',
                    }}
                  />
                </div>
              ))
            ) : (
              <SharedUser>
                Este{' '}
                {'total_accounts' in data
                  ? 'email'
                  : 'websites' in data
                  ? 'database'
                  : 'nameservers' in data
                  ? 'domínio'
                  : 'website'}{' '}
                ainda não foi compartilhado
              </SharedUser>
            )}
          </EmailBox>
        </div>
      </div>
      <Footer>
        <Button width="149px" onClick={() => setShow(false)}>
          Fechar
        </Button>
      </Footer>
    </>
  );
};

export default ShareModal;
