/* eslint-disable prettier/prettier */
import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: ${props => props.theme.brand_primary_color};
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 540px;
  text-align: center;

  img {
    width: 200px;
  }

  form {
    text-align: start;
    border-radius: 5px;
    background: ${props => props.theme.interface1};
    display: flex;
    flex-direction: column;
    margin: 60px auto 0px;
    max-width: 400px;
    padding: 40px;

    h3 {
      padding-bottom: 30px;
      margin-top: 16px;
      font-size: 20px;
      text-align: center;
      font-weight: normal;
      transform: translateY(-20px);
      color: ${props => props.theme.interface5};
    }

    button {
      margin-bottom: 20px;
      margin-top: 20px;
    }

    span {
      color: #d92947;
      font-size: 11px;
      margin-top: 6px;
      margin-bottom: 5px;
    }

    p {
      margin-top: 10px;
      font-size: 11px;
      color: ${props => props.theme.brand_primary_color};
      margin-bottom: 10px;

      a {
        color: ${props => props.theme.brand_primary_color};
        font-size: 13px;
        cursor: pointer;
        font-weight: bold;
      }
    }
  }
`;
