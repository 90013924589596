import React, { useState, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSadTear, faSpinner } from '@fortawesome/free-solid-svg-icons';

import { Modal, Button } from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from 'components/ListingModal/styles';
import SurveyRenderer from 'components/SurveyRenderer';

interface IChurnModalProps {
  action(e?: any): void;
  partnerCanChurn?: boolean;
  show: boolean;
  setShow: any;
  survey: any;
}

const ChurnModal: React.FC<IChurnModalProps> = ({
  action,
  partnerCanChurn,
  show,
  setShow,
  survey,
}) => {
  const [loading, setLoading] = useState(false);
  const [payload, setPayload] = useState({});
  const theme = useContext(ThemeContext);

  const submit = e => {
    e.preventDefault();
    setLoading(true);
    action(payload);
    // deleteUser();
  };

  return (
    <Modal show={show} setShow={setShow} noExit={loading}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faSadTear} size="2x" />
        </Icon>
        <Text>
          {partnerCanChurn ? (
            <>
              <Title>Excluir revenda</Title>
              <Subtitle>Tem certeza que deseja deletar sua revenda?</Subtitle>
            </>
          ) : (
            <>
              <Title>Deseja excluir sua conta?</Title>
              <Subtitle>
                Responda as perguntas abaixo para nos ajudar a melhorar.
              </Subtitle>
            </>
          )}
        </Text>
      </Header>

      <Content>
        <Row>
          <Col xs="12">
            {partnerCanChurn ? (
              <p
                style={{
                  color: theme.interface5,
                  fontSize: '15px',
                  textAlign: 'center',
                  margin: '10px auto',
                }}
              >
                Para deletar sua revenda, acesse o painel de Parceiros.
              </p>
            ) : (
              <form id="survey" onSubmit={submit}>
                <SurveyRenderer
                  survey={survey}
                  payload={payload}
                  setPayload={setPayload}
                />
              </form>
            )}
          </Col>
        </Row>
      </Content>
      <Footer>
        {partnerCanChurn ? (
          <Button
            outline
            onClick={() => window.open('https://partners.cloudez.io/company')}
          >
            Partners
          </Button>
        ) : (
          <Button disabled={loading} type="submit" form="survey" icon error>
            {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Remover'}
          </Button>
        )}
      </Footer>
    </Modal>
  );
};

export default ChurnModal;
