import React, { createContext, useReducer } from 'react';

import reducer from './reducer';
import {
  setLoading,
  setCode,
  getOtpStatus,
  deleteDevice,
  deleteAllDevices,
} from './actions';

export interface CompanySecurityState {
  code: { secret: string; url: string };
  otpStatus: { app: boolean; email: boolean };
  devices: any;
  loading: boolean;

  getOtpStatus(headers: any, page?: number): Promise<void>;
  deleteDevice(id: number | string): Promise<void>;
  deleteAllDevices: any;
  setLoading(value: boolean): void;
  setCode(value: boolean): void;
}

// initial state
const initialState = {
  code: { secret: '', url: '' },
  otpStatus: null,
  devices: [],
  loading: null,
};

// context
export const SecurityCompanyContext = createContext<CompanySecurityState>(
  initialState as CompanySecurityState,
);

// provider
export const SecurityCompanyProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(
    reducer,
    initialState as CompanySecurityState,
  );

  return (
    <SecurityCompanyContext.Provider
      value={{
        // state
        code: state.code,
        otpStatus: state.otpStatus,
        devices: state.devices,
        loading: state.loading,

        // actions
        getOtpStatus: async (headers, page) =>
          await getOtpStatus(dispatch, headers, page),
        deleteDevice: async id => await deleteDevice(dispatch, id),
        deleteAllDevices: async token =>
          await deleteAllDevices(dispatch, token),
        setLoading: value => setLoading(dispatch, value),
        setCode: value => setCode(dispatch, value),
      }}
    >
      {children}
    </SecurityCompanyContext.Provider>
  );
};
