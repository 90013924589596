import { toast } from 'react-toastify';
import history from 'services/history';
import {
  createExternalEmailMigrationService,
  createWebsiteMigrationService,
  vldValidateImapService,
} from 'services/migration';

export const setLoading = (dispatch, value) => {
  dispatch({
    type: 'SET_LOADING',
    payload: value,
  });
};

export const createMigration = async (dispatch, payload) => {
  setLoading(dispatch, true);

  try {
    const { migType } = payload;

    if (migType === 1) {
      try {
        await createWebsiteMigrationService(payload);

        toast.success('Migração criada com sucesso');
        history.push('/migrations');
        setLoading(dispatch, false);
      } catch (error) {
        setLoading(dispatch, false);

        dispatch({
          type: 'CREATE_MIGRATION_ERROR',
          payload: error?.response?.data,
        });
      }
    } else {
      const { type } = payload;

      if (type === 1) {
        try {
          await createExternalEmailMigrationService(payload);

          toast.success('Migração criada com sucesso');
          history.push('/migrations');
          setLoading(dispatch, false);
        } catch (error) {
          setLoading(dispatch, false);
          dispatch({
            type: 'CREATE_MIGRATION_ERROR',
            payload: error?.response?.data,
          });
        }
      } else {
        try {
          const { data } = await vldValidateImapService(payload);

          if (!data.accounts?.error) {
            const migration = {
              ...payload,
              src_accounts: payload.accounts,
              email: payload.zoneapp,
            };

            try {
              await createExternalEmailMigrationService(migration);

              toast.success('Migração criada com sucesso');
              history.push('/migrations');
              setLoading(dispatch, false);
            } catch (error) {
              setLoading(dispatch, false);
              dispatch({
                type: 'CREATE_MIGRATION_ERROR',
                payload: error?.response?.data,
              });
            }
          }
          setLoading(dispatch, false);
        } catch (error) {
          setLoading(dispatch, false);
          dispatch({
            type: 'CREATE_MIGRATION_ERROR',
            payload: error?.response?.data?.errors,
          });
        }
      }
    }
    setLoading(dispatch, false);
  } catch (error) {
    setLoading(dispatch, false);
    dispatch({
      type: 'CREATE_MIGRATION_ERROR',
      payload: error?.response?.data?.errors || error?.response?.data,
    });
  }
};

export const createMigrationErrorAction = (dispatch, data) => {
  dispatch({
    type: 'CREATE_MIGRATION_ERROR',
    payload: data,
  });
};
