import React from 'react';
import { Container } from './styles';

interface SquareCheckboxProps {
  label?: string;
  checked?: any;
  value?: any;
  onChange?: any;
  name: string;
  register?: any;
  required?: boolean;
  id?: any;
  disabled?: boolean;
  onClick?: any;
}

const SquareCheckbox: React.FC<SquareCheckboxProps> = ({
  label,
  name,
  checked,
  onChange,
  onClick,
  value,
  register,
  required,
  disabled,
  id,
}) => {
  return (
    <Container>
      <div onClick={onClick} className="custom-checkbox">
        {register ? (
          <input
            checked={checked}
            value={value}
            name={name}
            id={name}
            onChange={onChange}
            type="checkbox"
            {...register(name, { required })}
          />
        ) : (
          <input
            checked={checked}
            value={value}
            name={name}
            disabled={disabled}
            id={id || name}
            onChange={onChange}
            type="checkbox"
          />
        )}
        <label htmlFor={name}>{label}</label>
      </div>
    </Container>
  );
};

export default SquareCheckbox;
