import React from 'react';

import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useDevice from 'hooks/device';
import { Container, Content } from './styles';

import cloud from './images/cloud.png';
import turbo from './images/turbo.png';
import volume from './images/volume.png';
import email from './images/email.png';
import memory from './images/memory.png';
import processing from './images/processing.png';

import cloudMobile from './images/cloudMobile.png';
import turboMobile from './images/turboMobile.png';
import volumeMobile from './images/volumeMobile.png';
import emailMobile from './images/emailMobile.png';
import memoryMobile from './images/memoryMobile.png';
import processingMobile from './images/processingMobile.png';

interface PlanTypesProps {
  tab: number;
}

const PlanTypes = ({ tab }: PlanTypesProps) => {
  const { isMobile } = useDevice();

  const types = [
    {
      title: 'Padrão',
      stage: 1,
      resume:
        'Hospedagem padrão e mais para sites que prezam por uma boa performance.',
      description:
        'Plano ideal para sites simples com foco em presença on-line, mas que demandam por performance.',
      benefits: [
        'Mais segurança',
        'Mais estabilidade',
        'Suporte especializado',
        'Acesso de qualquer lugar do mundo',
        'Máxima performance',
        'Funcionalidades Premium (CDN, Pagespeed e outras)',
      ],
      logo: isMobile ? cloudMobile : cloud,
    },
    {
      title: 'Volume',
      stage: 5,
      resume:
        'Infraestrutura ideal para sites que não são fonte de receita e leads.',
      description:
        'O Plano Cloud Volume é otimizado para facilitar a operação de hospedagem da agência, em que é possível colocar um número maior de aplicações simples dentro de um mesmo ambiente em nuvem. Oportunidade de oferecer a clientes menores a tecnologia do Cloud Hosting e centralizar toda a sua operação de hospedagem em um só painel.',
      benefits: [
        'Exclusivo para parceiros a partir do selo Certified',
        'Foco em otimização de operação',
        'Redução de custos diante outras hospedagens',
        'Suporte especializado',
        'Acesso de qualquer lugar do mundo',
      ],
      logo: isMobile ? volumeMobile : volume,
    },
    {
      title: 'Email',
      stage: 4,
      resume: 'Aplicação exclusiva para e-mails.',
      description:
        'O Plano Cloud E-mail foi pensado para comportar um  alto volume de dados e permitir que a sua aplicação de e-mail não tenha problemas com armazenamento.',
      benefits: [
        'Cloud exclusivo e adaptado para e-mails',
        'Mais espaço de armazenamento',
        'Mais estabilidade',
        'Suporte especializado',
        'Acesso de qualquer lugar do mundo',
      ],
      logo: isMobile ? emailMobile : email,
    },
    {
      title: 'Memória',
      stage: 2,
      resume: 'Ideal para sites dinâmicos e com grande tráfego.',
      description:
        'O Plano Memória tem o objetivo de comportar aplicações que necessitam de mais capacidade de armazenamento, como conteúdos dinâmicos, como vídeos, cursos, gráficos e portais de notícia.',
      benefits: [
        'Mais velocidade de acesso',
        'Maior capacidade de disponibilização de dados',
        'Facilidade em realizar tarefas simultâneas',
        'Suporte especializado',
        'Acesso de qualquer lugar do mundo',
      ],
      logo: isMobile ? memoryMobile : memory,
    },
    {
      title: 'Processamento',
      stage: 3,
      resume: 'Ideal para sistemas computacionais.',
      description:
        'O Plano de Processamento permite que aplicações mais complexas, como softwares e sistemas on-line, tenham ambiente adequado para processar todas as informações necessárias.',
      benefits: [
        'Aplicações individualizadas',
        'Mais estabilidade',
        'Maior performance',
        'Suporte especializado',
        'Acesso de qualquer lugar do mundo',
      ],
      logo: isMobile ? processingMobile : processing,
    },
  ];

  return (
    <Container>
      {types.map(item => {
        return (
          item.stage === tab && (
            <Content>
              <img src={item.logo} alt="basic" />
              <div className="text">
                <p>{item.title}</p>
                <span style={{ fontWeight: 'bold' }}>{item.resume}</span>
                <span>{item.description}</span>

                <div className="benefits">
                  {item.benefits.map(benefit => (
                    <div className="benefit">
                      <FontAwesomeIcon icon={faCheck} />
                      <span>{benefit}</span>
                    </div>
                  ))}
                </div>
              </div>
            </Content>
          )
        );
      })}
    </Container>
  );
};

export default PlanTypes;
