/* eslint-disable prettier/prettier */
import React, { useState, useContext, useMemo, useEffect } from 'react';
import moment from 'moment/min/moment-with-locales';
import { Col, Row } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCommentAlt,
  faEnvelope,
  faWindowMaximize,
  faStar,
  faCog,
  faSpinner,
  faExchangeAlt,
} from '@fortawesome/free-solid-svg-icons';

import history from 'services/history';

import {
  Card,
  ListingItem,
  ApplicationValue,
  ApplicationName,
  Notification,
} from 'components/Listing/styles';
import ApplicationIcon from 'components/ApplicationIcon';
import { Acesso, AcessosList, AcessoHover, Value } from 'components/Acessos';
import { ThemeContext } from 'styled-components';
import { Link } from 'react-router-dom';
import { useNotifications } from 'hooks/notifications';
import { getSyncsService } from 'services/migration';
import { Migration } from 'types/migration';
import { MigrationListingContext } from '../_context/state';
import Progress from '../Progress';

const MigrationCard: React.FC<{
  migration: Migration
}> = ({ migration }) => {
  const { changePinned } = useContext(MigrationListingContext);
  const { patchNotification, notifications } = useNotifications();

  const [showAccess, setShowAccess] = useState(false);
  const [syncs, setSyncs] = useState<any>();
  const theme = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);

  const createdAt = moment(migration.created_at).locale('pt-BR').fromNow();
  const updatedAt = moment(migration.updated_at).locale('pt-BR').fromNow();

  const notification = notifications?.filter(
    n =>
      n.type === 'migration' &&
      n.resource_id === Number(migration.id) &&
      n.status === 0,
  );

  useEffect(() => {
    const getSyncs = async id => {
      try {
        const { data } = await getSyncsService(id, { migration: id });

        setSyncs(data);
      } catch (e) {
        console.log(e);
      }
    };

    getSyncs(migration.id);
  }, []);

  const isRemoveBackup = useMemo(() => {
    return syncs?.find(
      s => s.type === 'remove_backup' && (s.status === 1 || s.status === 3),
    );
  }, [syncs]);

  return (
    <ListingItem key={migration.id} disable={migration.status >= 4}>
      <Link
        to={
          migration.type === 4
            ? migration.ticket
              ? `/suporte/${migration.ticket}`
              : '/migrations'
            : `/migrations/${migration.id}`
        }
      >
        <Card>
          {notification?.length > 0 && (
            <Notification
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                patchNotification({
                  id: notification[0].id,
                  data: { status: 1 },
                });
              }}
            />
          )}

          <Row>
            <Col
              xs={showAccess ? '4' : '10'}
              sm={showAccess ? '4' : '10'}
              md="5"
              lg="5"
              xl="4"
            >
              <ApplicationValue>
                {migration.type === 4 ? (
                  <FontAwesomeIcon icon={faExchangeAlt} size="3x" />
                ) : (
                  <ApplicationIcon
                    width="40px"
                    height="40px"
                    icon={
                      migration.resource_type === 'selfhosted-mail'
                        ? 'cloudez-footer'
                        : `${migration.resource_type}`
                    }
                    disable={migration.status >= 4}
                  />
                )}
                {!showAccess && (
                  <ApplicationName>
                    {migration.type === 4
                      ? migration.domain
                      : migration.resource_slug}
                  </ApplicationName>
                )}
              </ApplicationValue>
            </Col>

            <Col xl="3" lg="5" md="5" className="d-none d-sm-none d-md-flex">
              <Progress
                status={isRemoveBackup ? 4 : migration.status}
                migration={migration}
              />
            </Col>

            <Col className="d-none d-xl-flex">
              <ApplicationValue>
                <p style={{ fontWeight: 'normal' }}>{createdAt}</p>
              </ApplicationValue>
            </Col>

            <Col className="d-none d-xl-flex">
              <ApplicationValue>
                <p style={{ fontWeight: 'normal' }}>{updatedAt}</p>
              </ApplicationValue>
            </Col>

            {!showAccess && (
              <Col className="d-sm-flex d-md-none">
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCog}
                    style={{
                      color: '#64728C',
                    }}
                    onClick={e => {
                      e.preventDefault();
                      setShowAccess(true);
                    }}
                  />
                </div>
              </Col>
            )}

            <Col
              xl="1"
              md="2"
              sm="8"
              xs="8"
              className={!showAccess && 'd-none d-md-flex'}
              onClick={e => {
                e.preventDefault();
                setShowAccess(false);
              }}
              style={{
                background: showAccess && theme.interface3,
                height: '100%',
                width: '100%',
                borderTopRightRadius: '5px',
                borderBottomRightRadius: '5px',
              }}
            >
              <AcessosList
                showAccess={showAccess}
                disable={migration.is_disabled}
              >
                <Acesso
                  fixed={!!migration.ticket}
                  active
                  hover
                  onClick={e => {
                    e.stopPropagation();
                    history.push(`/suporte/${migration.ticket}`);
                  }}
                >
                  <FontAwesomeIcon icon={faCommentAlt} />
                  <AcessoHover>
                    <Value>
                      <span>Ticket</span>
                    </Value>
                  </AcessoHover>
                </Acesso>
                {migration.type !== 4 && (
                  <Acesso
                    fixed
                    active
                    hover
                    onClick={() =>
                      history.push(
                        `/${migration.resource === 'website'
                          ? 'websites'
                          : 'emails'
                        }/${migration.resource_id}`,
                      )
                    }
                  >
                    {migration.resource === 'website' ? (
                      <>
                        <FontAwesomeIcon icon={faWindowMaximize} />
                        <AcessoHover>
                          <Value>
                            <span>Website</span>
                          </Value>
                        </AcessoHover>
                      </>
                    ) : (
                      <>
                        <FontAwesomeIcon icon={faEnvelope} />
                        <AcessoHover>
                          <Value>
                            <span>Email</span>
                          </Value>
                        </AcessoHover>
                      </>
                    )}
                  </Acesso>
                )}
                <Acesso active fixed={migration.is_pinned} hover favorite>
                  <FontAwesomeIcon
                    onClick={async () => {
                      setLoading(true);
                      await changePinned(migration.id);
                      setLoading(false);
                    }}
                    icon={loading ? faSpinner : faStar}
                    spin={loading}
                  />
                  <AcessoHover>
                    <Value>
                      <span>Favorite</span>
                    </Value>
                  </AcessoHover>
                </Acesso>
              </AcessosList>
            </Col>
          </Row>
        </Card>
      </Link>
    </ListingItem>
  );
};

export default MigrationCard;
