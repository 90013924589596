import React from 'react';

import { Col } from 'react-bootstrap';
import Placeholder from 'components/Placeholder';
import { Type } from '../styles';

const DatabaseTypePlaceholder = () => {
  return (
    <>
      <Col xs="12" sm="6">
        <Type>
          <Placeholder circle width={40} height={40} />
          <div>
            <Placeholder width={50} height={16} />
          </div>
          <div>
            <Placeholder width={80} height={12} />
          </div>
          <div />
        </Type>
      </Col>
      <Col xs="12" sm="6">
        <Type>
          <Placeholder circle width={40} height={40} />
          <div>
            <Placeholder width={50} height={16} />
          </div>
          <div>
            <Placeholder width={80} height={12} />
          </div>
          <div />
        </Type>
      </Col>
    </>
  );
};

export default DatabaseTypePlaceholder;
