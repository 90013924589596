import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  h1 {
    margin: 0;
    font-weight: 900;
    font-size: 12rem;
    color: ${props => props.theme.brand_primary_color};
  }

  p {
    letter-spacing: 4px;
    font-size: 1.25rem;
    margin-bottom: 0;
    font-weight: 700;
    line-height: 1.2;
  }
`;
