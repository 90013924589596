import { faPlus, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Field, Label } from 'components/Details';
import { Error, Input } from '@cloudez/cloudez-design-system';
import { NoUsers, Shared, SharedUser } from 'components/Shared';
import React, { useCallback, useState, useContext } from 'react';

import { Col, Row } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { shareResourceService, unshareResourceService } from 'services/common';
import { EmailDetailContext } from '../_context/state';

import * as Styles from './styles';

export default function Share() {
  const { email, patchEmail, updateLoading } = useContext(EmailDetailContext);

  const [shareEmail, setShareEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({} as any);

  const share = useCallback(async () => {
    setLoading(true);
    try {
      const response = await shareResourceService(
        'email',
        email.id,
        shareEmail,
      );

      await patchEmail('shared_users', response.data.share, email.id);

      email.shared_users.push(shareEmail as never);
      setShareEmail('');
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setErrors(e?.response?.data);
    }
  }, [email, shareEmail, patchEmail]);

  const unshare = useCallback(
    async user => {
      setLoading(true);
      try {
        const response = await unshareResourceService('email', email.id, user);

        await patchEmail('shared_users', response.data.share, email.id);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
      // eslint-disable-next-line
    },
    [email],
  );

  return (
    <Row>
      <Col xs="6">
        <Field>
          <Label>COMPARTILHAMENTO</Label>
          <Row
            style={{
              marginTop: `${5}px`,
              marginBottom: `${5}px`,
            }}
          >
            <Col xs="9" sm="10">
              <Input
                block
                value={shareEmail}
                onChange={e => setShareEmail(e.target.value)}
                error={!!errors.email}
              />
              {errors.email && <Error>{errors.email[0]}</Error>}
            </Col>
            <Col xs="3" sm="2">
              <Styles.OfflineButton
                disabled={updateLoading || loading || shareEmail === ''}
                onClick={share}
              >
                {updateLoading || loading ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  <FontAwesomeIcon icon={faPlus} />
                )}
              </Styles.OfflineButton>
            </Col>
          </Row>
          <Shared>
            {email.shared_users.length ? (
              email.shared_users.map(user => (
                <SharedUser key={user}>
                  {user} {'  '}
                  <FontAwesomeIcon
                    icon={faTimes}
                    onClick={() => unshare(user)}
                  />
                </SharedUser>
              ))
            ) : (
              <NoUsers>Este email ainda não foi compartilhado</NoUsers>
            )}
          </Shared>
        </Field>
      </Col>
    </Row>
  );
}
