import styled from 'styled-components';

export const Card = styled.div`
  width: 100%;
  height: 85px;
  padding: 10px 14px;
  margin-bottom: 20px;
  background: ${props => props.theme.interface2};

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  font-size: 12px;
  color: ${props => props.theme.interface5};
  p {
    margin-top: 4px;
  }
  border: 1px solid transparent;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    border: 1px solid ${props => props.theme.brand_primary_color};
  }
`;
