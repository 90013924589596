import styled from 'styled-components';

interface HoverTextProps {
  active?: boolean;
  standby?: boolean;
}

export const HoverText = styled.div<HoverTextProps>`
  p {
    color: ${props =>
      props.standby
        ? props.theme.alertNew
        : props.active
        ? props.theme.successNew
        : props.theme.errorNew};

    font-weight: bold;
    font-size: 16px;
    margin-bottom: 8px;
  }

  visibility: hidden;
  background-color: ${props => props.theme.interface1};
  padding: 16px;
  position: absolute;
  z-index: 3;
  color: ${props => props.theme.interface5} !important;
  display: block !important;

  font-weight: normal;
  font-size: 14px;
  border: 1px solid ${props => props.theme.brand_primary_color};

  width: 200px;

  box-shadow: 0px 2px 15px rgba(100, 114, 140, 0.25);
  border-radius: 5px;
`;

export const Hover = styled.div`
  position: relative;

  &:hover ${HoverText} {
    visibility: visible;
  }
`;
