import React, { useState, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import ApplicationIcon from 'components/ApplicationIcon';
import {
  FormInput,
  Field,
  Label,
  Error,
  Checkbox,
  Button,
} from '@cloudez/cloudez-design-system';
import migSite2 from 'assets/img/svg/migsite2.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSpinner,
  faMinusSquare,
  faPlusSquare,
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons';
import { ThemeContext } from 'styled-components';
import CSVModal from './CSVModal';
import { MigrationCreateContext } from '../../_context/state';
import { Origin as OriginStyle, Image, Card } from '../styles';

const Origin = ({
  domain,
  host,
  setPort,
  port,
  setDomain,
  setHost,
  vldLoading,
  checked,
  vldPayload,
  autoPoint,
  setAutoPoint,
  isExternal,
  setIsExternal,
  selectedZoneapp,
  setSelectedZoneapp,
}) => {
  const { loading, errors } = useContext(MigrationCreateContext);
  const [accounts, setAccounts] = useState([
    {
      username: '',
      password: '',
    },
  ]);
  const [csvModal, setCSVModal] = useState(false);

  const theme = useContext(ThemeContext);

  const loadCSV = data => {
    const payload = data.map(a => ({
      username: a[0],
      password: a[1],
    }));

    setAccounts([...payload]);
  };

  return (
    <>
      {csvModal && (
        <CSVModal
          show={csvModal}
          setShow={() => setCSVModal(false)}
          action={loadCSV}
        />
      )}
      <OriginStyle>
        <h3>Origem</h3>
        <Row
          style={{
            width: '100%',
          }}
        >
          <Col sm="12">
            <Field>
              <Label>Domínio</Label>
              <FormInput
                value={domain}
                onChange={e => setDomain(e.target.value)}
                block="true"
                name="domain"
                error={!!errors?.domain}
              />
              {!!errors?.domain && <Error>{errors?.domain}</Error>}
            </Field>
          </Col>
        </Row>

        {checked && (
          <>
            {vldPayload?.zoneapps && !isExternal && (
              <>
                <Row
                  style={{
                    width: '100%',
                  }}
                >
                  {vldPayload?.zoneapps?.map(({ zoneapp }) => (
                    <Col
                      key={zoneapp.id}
                      sm="6"
                      xs="12"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <Card
                        key={zoneapp.id}
                        selected={zoneapp.id === selectedZoneapp.id}
                        onClick={() =>
                          setSelectedZoneapp({
                            id: zoneapp.id,
                            type: 'zoneapp',
                          })
                        }
                      >
                        <ApplicationIcon
                          icon={
                            zoneapp.type === 'selfhosted-mail'
                              ? 'cloudez-footer'
                              : `${zoneapp.type}`
                          }
                          width="45px"
                          height="45px"
                        />

                        <div
                          style={{
                            width: '100%',
                            whiteSpace: 'nowrap',
                            textAlign: 'center',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                          }}
                        >
                          <span>{zoneapp.domain}</span>
                        </div>
                        <div
                          style={{
                            textAlign: 'center',
                            width: '100%',
                          }}
                        >
                          <p>{zoneapp.cloud_nickname || zoneapp.cloud_name}</p>
                        </div>
                      </Card>
                    </Col>
                  ))}
                  <Col
                    sm="6"
                    xs="12"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Card onClick={() => setIsExternal(true)}>
                      <FontAwesomeIcon
                        icon={faExternalLinkAlt}
                        style={{ fontSize: '45px' }}
                        color={theme.interface6}
                      />

                      <div
                        style={{
                          width: '100%',
                          whiteSpace: 'nowrap',
                          textAlign: 'center',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                      >
                        <span>Email externo</span>
                      </div>
                    </Card>
                  </Col>
                </Row>
                <FormInput
                  name="zoneapp_src"
                  style={{
                    display: 'none',
                  }}
                  value={selectedZoneapp.id}
                  onChange={() => {}}
                  error={!!errors?.zoneapp_src}
                />
                {!!errors?.zoneapp_src && (
                  <Error
                    style={{
                      marginBottom: '15px',
                    }}
                  >
                    {errors?.zoneapp_src}
                  </Error>
                )}

                <Row
                  style={{
                    width: '100%',
                  }}
                >
                  <Col xs="12">
                    <Field>
                      <Label>Apontar automaticamente o DNS</Label>
                      <p
                        style={{
                          fontSize: '10px',
                          marginTop: '-5px',
                        }}
                      >
                        *O apontamento somente será realizado se o website de
                        origem estiver hospedado e ativo no seu painel.
                      </p>
                      <Checkbox
                        value={autoPoint}
                        checked={autoPoint}
                        onChange={e => setAutoPoint(e.target.checked)}
                      />
                    </Field>
                  </Col>
                </Row>
              </>
            )}

            {(!vldPayload?.zoneapps || isExternal) && (
              <>
                <Row
                  style={{
                    width: '100%',
                  }}
                >
                  <Col
                    sm={
                      vldPayload?.type === 0 || !vldPayload.is_owner
                        ? '8'
                        : '12'
                    }
                  >
                    <Field>
                      <Label>Endereço IMAP</Label>
                      <FormInput
                        value={host}
                        onChange={e => setHost(e.target.value)}
                        block="true"
                        name="host"
                        error={!!errors?.host}
                      />
                      {!!errors?.host && <Error>{errors?.host}</Error>}
                    </Field>
                  </Col>
                  {(vldPayload?.type === 0 || !vldPayload.is_owner) && (
                    <Col sm="12" md="4">
                      <Field>
                        <Label>Porta imap</Label>
                        <FormInput
                          block="true"
                          name="port"
                          value={port}
                          onChange={e => setPort(e.target.value)}
                        />
                      </Field>
                    </Col>
                  )}
                </Row>

                {(vldPayload?.type === 0 || !vldPayload.is_owner) && (
                  <>
                    {accounts.map((i, index) => {
                      return (
                        <Row
                          style={{
                            width: '100%',
                          }}
                          key={index}
                        >
                          <Col sm="12" md="6" xl="5">
                            <Field>
                              <Label>Email</Label>
                              <FormInput
                                block="true"
                                name={`accounts[${index}].username`}
                                value={accounts[index]?.username}
                                error={errors?.accounts?.find(
                                  a => a.username === accounts[index]?.username,
                                )}
                                onChange={e => {
                                  const _accounts = accounts.map((a, _index) =>
                                    index === _index
                                      ? { ...a, username: e.target.value }
                                      : a,
                                  );
                                  setAccounts(_accounts);
                                }}
                              />
                              {errors?.accounts?.find(
                                a => a.username === accounts[index]?.username,
                              ) && (
                                <Error>
                                  {
                                    errors?.accounts?.find(
                                      a =>
                                        a.username ===
                                        accounts[index]?.username,
                                    )?.error
                                  }
                                </Error>
                              )}
                            </Field>
                          </Col>
                          <Col sm="12" md="6" xl="5">
                            <Field>
                              <Label>Senha</Label>
                              <FormInput
                                block="true"
                                name={`accounts[${index}].password`}
                                error={errors?.accounts?.find(
                                  a => a.username === accounts[index]?.username,
                                )}
                                value={accounts[index]?.password}
                                onChange={e => {
                                  const _accounts = accounts.map((a, _index) =>
                                    index === _index
                                      ? { ...a, password: e.target.value }
                                      : a,
                                  );
                                  setAccounts(_accounts);
                                }}
                              />
                            </Field>
                          </Col>
                          {accounts.length > 1 && (
                            <Col
                              xl="2"
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              <Button
                                block
                                size="sm"
                                outline
                                error
                                style={{
                                  marginTop: '8px',
                                }}
                                onClick={e => {
                                  e.preventDefault();
                                  setAccounts(ac =>
                                    ac.filter((_, i) => index !== i),
                                  );
                                }}
                              >
                                Excluir
                                <FontAwesomeIcon icon={faMinusSquare} />
                              </Button>
                            </Col>
                          )}
                        </Row>
                      );
                    })}
                    <Row
                      style={{
                        width: '100%',
                      }}
                    >
                      <Col
                        style={{
                          width: '100%',
                          display: 'flex',
                        }}
                      >
                        <Button
                          size="sm"
                          onClick={e => {
                            e.preventDefault();
                            setAccounts([
                              ...accounts,
                              {
                                username: '',
                                password: '',
                              },
                            ]);
                          }}
                          left
                          outline
                          style={{
                            marginBottom: '10px',
                            marginRight: '20px',
                          }}
                        >
                          <FontAwesomeIcon icon={faPlusSquare} />
                          Usuário
                        </Button>
                        <Button
                          type="button"
                          left
                          size="sm"
                          onClick={e => {
                            setCSVModal(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faPlusSquare} />
                          CSV
                        </Button>
                      </Col>
                    </Row>
                  </>
                )}
              </>
            )}
          </>
        )}

        {!checked && (
          <Row
            style={{
              width: '100%',
            }}
          >
            <Image>
              <img src={migSite2} alt="migsite" />
            </Image>
          </Row>
        )}

        <Button icon type="submit" disabled={loading || vldLoading}>
          {loading || vldLoading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : checked ? (
            'Criar'
          ) : (
            'Validar'
          )}
        </Button>
      </OriginStyle>
    </>
  );
};

export default Origin;
