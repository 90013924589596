import { Select } from '@cloudez/cloudez-design-system';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLayout } from 'hooks/layout';
import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { putCloudActionService } from 'services/cloud';
import { CardHeader, InfoCard, TitleText } from './styles';

export const Informations = ({ cloud }) => {
  const theme = useLayout();

  const [canAction, setCanAction] = useState(true);
  const [action, setAction] = useState('0');

  const cloudAction = async () => {
    try {
      const payload = {
        action,
      };

      await putCloudActionService(cloud.id, payload);

      toast.success('A ação está sendo aplicada.');
      setCanAction(false);
    } catch (e) {}
  };

  return (
    <Col xl="5">
      <InfoCard>
        <CardHeader>Informações</CardHeader>
        <div className="info">
          <div>
            <TitleText>IPv4:</TitleText>
            <p>{cloud.ipaddr}</p>
          </div>
          {cloud.ipaddrv6 && (
            <div>
              <TitleText>IPv6:</TitleText>
              <p>{cloud.ipaddrv6}</p>
            </div>
          )}
          <div>
            <TitleText>Hostname:</TitleText>{' '}
            <p>{`${cloud.name}.${cloud.domain}`}</p>
          </div>
          <div>
            <TitleText>Ações: </TitleText>
            <p>
              <Select
                style={{
                  fontSize: '12px',
                  marginLeft: '2px',
                  marginRight: '6px',
                }}
                height="30px"
                width="175px"
                value={action}
                onChange={e => setAction(e.target.value)}
              >
                <option disabled value="0">
                  Escolha uma ação:
                </option>
                <option value="restart">Reiniciar cloud</option>
                <option value="apply">Aplicar configurações</option>
                <option value="update">Atualizar pacotes</option>
              </Select>
              <FontAwesomeIcon
                onClick={canAction && cloudAction}
                icon={faCheck}
                style={{
                  cursor: 'pointer',
                }}
                size="1x"
                color={theme.brand_primary_color}
              />
            </p>
          </div>
        </div>
      </InfoCard>
    </Col>
  );
};
