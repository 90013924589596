import styled, { css } from 'styled-components';
import React, { memo } from 'react';
import { transparentize } from 'polished';
import { Button } from '@cloudez/cloudez-design-system';

export const Header = styled.div`
  width: 100%;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    margin-bottom: 40px;

    ${Button} {
      margin-top: 10px;
      width: 100%;
    }
  }
`;

export const Title = styled.h1`
  font-size: 28px;
  color: ${props =>
    props.theme.darkMode
      ? props.theme.interface3
      : props.theme.interface7} !important;

  svg {
    margin-right: 10px;
    color: ${props => props.theme.brand_primary_color};
  }
`;

export const TicketWrapper = styled.div`
  position: relative;
  width: 100%;
  min-height: 680px;

  background: ${props => props.theme.interface1};
  border-radius: 5px;
`;

export const TicketHeader = styled.div`
  width: 100%;
  min-height: 61px;
  height: auto;
  border-bottom: 2px solid
    ${props =>
      props.theme.darkMode ? props.theme.interface2 : props.theme.interface3};
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    height: auto;
  }
`;

export const UserInfo = styled.div`
  width: 60%;

  padding: 11px 24px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    width: 75%;
  }
`;

export const CustomMessages = styled.div`
  margin-top: 10px;
  height: 200px;
  overflow-y: auto;

  p {
    margin-bottom: 8px;
    color: ${props => props.theme.interface5};
    cursor: pointer;
  }
`;

export const Email = styled.span`
  display: block;
  font-size: 10px;
  font-weight: bold;
  color: ${props => props.theme.interface5};
`;

export const TicketTitle = styled.span`
  width: 430px;
  word-wrap: break-word;
  display: block;
  line-height: 1;
  font-size: 14px;
  color: ${props =>
    props.theme.darkMode ? props.theme.interface4 : props.theme.interface6};
  font-weight: bold;

  @media (max-width: 625px) {
    width: 170px;
  }
`;

export const TeamBadge = styled.div`
  justify-self: flex-end;
  width: 65px;
  height: 20px;
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.labelNew};
  font-weight: bold;
  text-transform: uppercase;
`;

export const Updates = styled.div`
  width: 35%;

  padding: 11px 24px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  color: ${props => props.theme.interface5};
  font-size: 9px;

  p {
    color: ${props => props.theme.interface4};
    text-transform: uppercase;

    & > span {
      color: ${props => props.theme.interface5};
      text-transform: none;
      font-size: 11px;

      & > span {
        font-size: 9px;
      }
    }
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const RedAlert = styled.div`
  padding: 4px 6px;
  width: 5%;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 768px) {
    width: 25%;
  }
`;

export const Files = styled.div`
  padding: 4px 20px;
  min-height: 50px;
  width: 100%;
  border-top: 2px solid
    ${props =>
      props.theme.darkMode ? props.theme.interface2 : props.theme.interface3};
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  span,
  svg {
    color: ${props => props.theme.interface5};
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
  }
`;

export const File = styled.div`
  cursor: pointer;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin: 10px 10px;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    img {
      border-radius: 5px;
      height: 100%;
      width: auto;
    }
  }

  svg {
    color: ${props => props.theme.brand_primary_color};
    font-size: 30px;
  }
`;

export const FilesDropdown = styled.div`
  height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 300px;
  position: absolute;
  left: calc(50% - 150px);
  background: ${props => props.theme.interface1};
  box-shadow: 0px 3px 15px #64728c4d;
  padding: 10px;
  z-index: 2;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  ${File} {
    background: ${props => props.theme.interface1};
    padding: 6px;

    width: 50%;
    height: 50%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    a {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        font-size: 50px !important;
      }
    }
  }
`;

export const FileName = styled.div`
  width: 100%;
  font-size: 12px;
  color: ${props => props.theme.interface4};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface MessageProps {
  noResponsible?: boolean;
}
export const Messages = styled.div<MessageProps>`
  width: 100%;
  position: relative;
  min-height: 600px;

  padding: 24px;
  display: flex;
  flex-direction: column;

  svg {
    display: block;
    align-self: center;
    margin-bottom: 20px;
    color: ${props => props.theme.interface4};

    cursor: pointer;
  }
`;

interface MessageProps {
  domo?: boolean;
  isStaff?: boolean;
  isAuthor?: boolean;
}

export const Message = memo(styled.div<MessageProps>`
  max-width: 100%;
  min-width: 60%;
  width: 60%;
  margin-bottom: 30px;
  background: ${props => props.theme.interface2};
  border-radius: 15px 15px 15px 0px;

  div {
    height: 100%;
    width: 100%;
    color: ${props => props.theme.interface6};

    a {
      font-style: ${props => (props.domo ? 'italic' : 'normal')};
    }

    font-size: 12px;
  }

  ${props =>
    props.isStaff &&
    css`
      align-self: flex-end;
      border-radius: 15px 15px 0px 15px;
    `};

  ${props =>
    props.domo &&
    css`
      background: ${props.theme.brand_primary_color};

      div {
        color: ${props => props.theme.interface2};
      }
    `};

  ${props =>
    props.isAuthor &&
    css`
      background: ${() => transparentize(0.8, props.theme.successNew)};
      align-self: flex-end;
      border-radius: 15px 15px 0px 15px;

      div {
        color: ${props => props.theme.interface6};
      }
    `};

  @media (max-width: 1300px) {
    transition: width 0.2s ease;
    width: 100%;
  }
`);

interface MessageTextProps {
  domo?: boolean;
}

export const MessageText = styled.div<MessageTextProps>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 19px 15px;

  ${File} {
    margin: 0 10px;
    height: auto;
    width: 100%;

    &:hover {
      text-decoration: underline;
    }

    span {
      width: auto;
      margin: 0px 10px 0 0;
      & > svg {
        color: ${props => props.theme.brand_primary_color} !important;
        margin: 0;
        width: auto !important;
      }
    }

    p {
      width: 100%;
    }
  }

  pre {
    ${props =>
      props.domo &&
      css`
        a {
          color: ${props => props.theme.brand_secondary_color} !important;
        }
      `};

    a {
      color: ${props => props.theme.label};
      text-decoration: none;
    }

    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;

    width: 100%;
    font-size: 14px;
    line-height: 1.8em;
    overflow: visible;
    background: none;
    padding: 0;
  }
`;

interface MessageHeaderProps {
  domo?: boolean;
}

export const MessageHeader = styled.div<MessageHeaderProps>`
  width: 100%;
  padding: 9px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.interface1};

  p {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 8px;
      height: ${props => (props.domo ? '42px' : '30px')};
      border-radius: 50%;
    }
  }

  span {
    text-decoration: none;
    color: ${props => props.theme.interface5};
    cursor: pointer;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 10px;

    svg {
      align-self: center;
      margin: 0;
      margin-left: 4px;
    }
  }
`;

interface HourProps {
  isOld?: boolean;
  isAuthor?: boolean;
  isStaff?: boolean;
  domo?: boolean;
  staff?: boolean;
}

export const Hour = styled.span<HourProps>`
  position: relative;
  top: 18px;
  font-size: 10px;
  color: ${props => props.theme.interface5};
  ${props =>
    props.isAuthor
      ? css`
          left: ${props.isOld ? 'calc(100% - 140px)' : 'calc(100% - 30px)'};
        `
      : css`
          left: 0;
        `};
`;

interface TicketFooterProps {
  disabled?: boolean;
  file?: boolean;
}

export const TicketFooter = styled.div<TicketFooterProps>`
  width: 100%;
  height: 100%;
  padding: 30px 24px;
  border-top: 1px solid
    ${props =>
      props.theme.darkMode ? props.theme.interface2 : props.theme.interface3};

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  textarea {
    width: 80%;
    max-width: 80%;
    min-height: 70px;
  }

  svg {
    cursor: pointer;
    color: ${props => props.theme.interface4};
    font-size: 16px;

    &.file {
      color: ${props =>
        props.file ? props.theme.brand_primary_color : props.theme.interface4};
    }
  }

  @media (max-width: 1030px) {
    ${Button} {
      margin-top: 10px;
      width: 100%;
    }
    textarea {
      margin-top: 9px;
      width: 100%;
      max-width: 100%;
    }
    svg {
      margin-right: 6px;
    }
  }
`;

export const Icons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 4%;

  @media (max-width: 768px) {
    width: 10%;
    margin-bottom: 10px;
  }
`;

export const Typing = styled.div`
  width: auto;
  padding: 8px;
  position: absolute;
  bottom: 0;

  & > p {
    display: inline;
    font-size: 14px;
    color: ${props => props.theme.interface5};
  }

  &::after {
    content: 'está digitando..';
    display: inline;
    font-size: 14px;
    color: ${props => props.theme.interface5};
  }
`;
