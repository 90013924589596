import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;

  span {
    text-transform: uppercase;
    color: ${props => props.theme.success};
    font-weight: bold;
    font-size: 18px;
    margin-top: 30px;
  }

  p {
    color: ${props => props.theme.interface5};
    margin-top: 20px;
    font-size: 18px;
  }

  p.subtitle {
    font-size: 11px;
    margin-bottom: 50px;
  }
`;
