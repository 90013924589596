import React from 'react';
import { Col, Row } from 'react-bootstrap';

import Placeholder from 'components/Placeholder';

import {
  Card,
  CardContent,
  ListingHeader,
  Listing,
  ListingItem,
  ApplicationValue,
  CloudValue,
} from 'components/Listing/styles';

import { Wrapper } from 'components/ApplicationIcon/styles';
import { Acesso, AcessosList } from 'components/Acessos';

const WebsitePlaceholder = () => {
  const placeholders = [];

  for (let i = 1; i <= 4; i++) {
    placeholders.push(i);
  }

  return (
    <>
      <ListingHeader>
        <Row>
          <Col xs sm md="4" lg="5" xl="5">
            <Wrapper>
              <p>Aplicação</p>
            </Wrapper>
          </Col>
          <Col className="d-none d-xl-flex">
            <p>Cloud</p>
          </Col>
          <Col className="d-none d-sm-none d-md-flex">
            <p>Disco</p>
          </Col>
          <Col xl="2" className="d-none d-xl-flex" />
        </Row>
      </ListingHeader>

      <Listing>
        {placeholders.map((p, i) => (
          <ListingItem key={p} fade={i > 0 && i}>
            <Card>
              <CardContent>
                <Col xs sm md="4" lg="5" xl="5">
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      marginLeft: 15,
                    }}
                  >
                    <Placeholder circle width={40} height={40} />
                    <span style={{ marginLeft: 12 }}>
                      <Placeholder width={200} height={12} />
                    </span>
                  </div>
                </Col>

                <Col className="d-none d-xl-flex">
                  <ApplicationValue style={{ fontWeight: 'normal' }}>
                    <CloudValue>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Placeholder width={50} height={8} />
                        <Placeholder width={20} height={6} />
                      </div>
                    </CloudValue>
                  </ApplicationValue>
                </Col>

                <Col className="d-none d-sm-none d-md-flex d-xl-flex">
                  <ApplicationValue>
                    <Placeholder width={30} height={12} />
                  </ApplicationValue>
                </Col>

                <Col xl="2" className="d-none d-xl-flex">
                  <AcessosList>
                    <Acesso active hover>
                      <Placeholder width={16} height={18} />
                    </Acesso>

                    <Acesso active hover>
                      <Placeholder width={16} height={18} />
                    </Acesso>

                    <Acesso active hover>
                      <Placeholder width={16} height={18} />
                    </Acesso>

                    <Acesso active hover>
                      <Placeholder width={16} height={18} />
                    </Acesso>

                    <Acesso active hover favorite>
                      <Placeholder width={16} height={18} />
                    </Acesso>
                  </AcessosList>
                </Col>
              </CardContent>
            </Card>
          </ListingItem>
        ))}
      </Listing>
    </>
  );
};

export default WebsitePlaceholder;
