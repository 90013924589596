import React, {
  useCallback,
  useState,
  useContext,
  Dispatch,
  SetStateAction,
} from 'react';
import { Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faTerminal } from '@fortawesome/free-solid-svg-icons';

import {
  Modal,
  Button,
  Input,
  Label,
  Field,
  Checkbox,
  Error,
  TextArea,
  PaginatedResponse,
} from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Content,
  Footer,
} from 'components/ListingModal/styles';

import { toast } from 'react-toastify';
import {
  deleteWebsiteCronService,
  updateWebsiteCronService,
  createWebsiteCronService,
} from 'services/website';
import { Cron } from 'types/website';
import { HttpsText } from 'pages/Account/RemoveModal/styles';
import { WebsiteDetailContext } from '../../_context/state';

interface ICronDetailProps {
  show: boolean;
  setShow: any;
  cron?: Cron;
  setCrons: Dispatch<SetStateAction<PaginatedResponse<Cron>>>;
}

const CronDetail: React.FC<ICronDetailProps> = ({
  show,
  setShow,
  cron,
  setCrons,
}) => {
  const [loading, setLoading] = useState(false);
  const [cronError, setCronError] = useState({} as any);
  const { website } = useContext(WebsiteDetailContext);

  const [deleteMode, setDeleteMode] = useState(false);

  const [_cron, _setCron] = useState<Cron>(
    cron ||
      ({
        website: website.id,
        name: '',
        command: '',
        minute: '',
        hour: '',
        day_month: '*',
        month: '*',
        day_week: '*',
        startup_only: false,
      } as Cron),
  );

  const createCron = useCallback(async () => {
    setCronError({});
    setLoading(true);
    try {
      const { data } = await createWebsiteCronService(_cron);
      setCrons(crons => ({
        ...crons,
        data: [...crons.data, data],
      }));
      setLoading(false);
      setShow(false);
      toast.success('Cron criado com sucesso');
    } catch (e) {
      setCronError(e?.response?.data);
      setLoading(false);
    }
  }, [_cron]);

  const updateCron = useCallback(async () => {
    setCronError({});
    setLoading(true);
    try {
      const { data } = await updateWebsiteCronService(_cron.id, _cron);
      setCrons(crons => ({
        ...crons,
        data: crons.data.map(c => (c.id === data.id ? data : c)),
      }));
      setLoading(false);
      toast.success('Cron atualizado com sucesso');
      setShow(false);
    } catch (e) {
      setCronError(e?.response?.data);
      setLoading(false);
    }
  }, [_cron]);

  const deleteCron = useCallback(async () => {
    setCronError({});
    setLoading(true);
    try {
      await deleteWebsiteCronService(_cron.id);

      setCrons(crons => ({
        ...crons,
        data: crons.data.filter(c => c.id !== _cron.id),
      }));
      toast.success('Cron removido com sucesso');
      setLoading(false);
      setShow(false);
    } catch (e) {
      console.log(e);
      setCronError(e?.response?.data);
      setLoading(false);
    }
  }, [_cron]);

  const handleChange = useCallback(
    e => {
      _setCron({
        ..._cron,
        [e.target.name]:
          e.target.type === 'checkbox' ? e.target.checked : e.target.value,
      });
    },
    [_cron],
  );

  return (
    <Modal show={show} setShow={setShow} noExit={loading}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faTerminal} />
        </Icon>
        <Text>
          <Title>{deleteMode ? 'Remover cron' : 'Criar cron'}</Title>
        </Text>
      </Header>

      <Content>
        {deleteMode ? (
          <HttpsText>Tem certeza que deseja remover este cron?</HttpsText>
        ) : (
          <>
            <Row>
              <Col>
                <Field>
                  <Label>Nome</Label>
                  <Input
                    block
                    value={_cron.name}
                    onChange={handleChange}
                    name="name"
                    error={!!cronError?.name}
                  />
                  {cronError?.name && <Error>{cronError?.name[0]}</Error>}
                </Field>
              </Col>
            </Row>
            <Row>
              <Col>
                <Field>
                  <Label>Comando</Label>
                  <TextArea
                    block
                    value={_cron.command}
                    onChange={e => {
                      handleChange(e);
                      e.target.style.height = `${e.target.scrollHeight}px`;
                    }}
                    name="command"
                    error={!!cronError?.command}
                    style={{
                      maxWidth: '100%',
                    }}
                  />
                  {cronError?.command && <Error>{cronError?.command[0]}</Error>}
                </Field>
              </Col>
            </Row>
            <Row>
              <Col>
                <Field>
                  <Label>Minuto</Label>
                  <Input
                    block
                    value={_cron.minute}
                    onChange={handleChange}
                    name="minute"
                    error={!!cronError?.minute}
                  />
                  {cronError?.minute && <Error>{cronError?.minute[0]}</Error>}
                </Field>
              </Col>
              <Col>
                <Field>
                  <Label>Hora</Label>
                  <Input
                    block
                    value={_cron.hour}
                    onChange={handleChange}
                    name="hour"
                    error={!!cronError?.hour}
                  />
                  {cronError?.hour && <Error>{cronError?.hour[0]}</Error>}
                </Field>
              </Col>
            </Row>
            <Row>
              <Col>
                <Field>
                  <Label>Dia do mês</Label>
                  <Input
                    block
                    value={_cron.day_month}
                    onChange={handleChange}
                    name="day_month"
                    error={!!cronError?.day_month}
                  />
                  {cronError?.day_month && (
                    <Error>{cronError?.day_month[0]}</Error>
                  )}
                </Field>
              </Col>
              <Col>
                <Field>
                  <Label>Mês</Label>
                  <Input
                    block
                    value={_cron.month}
                    onChange={handleChange}
                    name="month"
                    error={!!cronError?.month}
                  />
                  {cronError?.month && <Error>{cronError?.month[0]}</Error>}
                </Field>
              </Col>
            </Row>
            <Row>
              <Col>
                <Field>
                  <Label>Dia da semana</Label>
                  <Input
                    block
                    value={_cron.day_week}
                    onChange={handleChange}
                    name="day_week"
                    error={!!cronError?.day_week}
                  />
                  {cronError?.day_week && (
                    <Error>{cronError?.day_week[0]}</Error>
                  )}
                </Field>
              </Col>
              <Col>
                <Field>
                  <Label>Somente ao iniciar</Label>
                  <Checkbox
                    value={_cron.startup_only}
                    checked={_cron.startup_only}
                    onChange={handleChange}
                    name="startup_only"
                    error={!!cronError?.startup_only}
                  />
                  {cronError?.startup_only && (
                    <Error>{cronError?.startup_only[0]}</Error>
                  )}
                </Field>
              </Col>
            </Row>
          </>
        )}
      </Content>
      <Footer hasLink={!!cron && !deleteMode}>
        {deleteMode ? (
          <Button
            width="149px"
            error
            icon
            disabled={loading}
            onClick={deleteCron}
          >
            {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Confirmar'}
          </Button>
        ) : cron ? (
          <>
            <Button
              icon
              disabled={loading}
              error
              outline
              onClick={() => setDeleteMode(true)}
            >
              {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Deletar'}
            </Button>
            <Button icon disabled={loading} onClick={updateCron}>
              {loading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                'Atualizar'
              )}
            </Button>
          </>
        ) : (
          <Button icon disabled={loading} onClick={createCron}>
            {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Criar'}
          </Button>
        )}
      </Footer>
    </Modal>
  );
};

export default CronDetail;
