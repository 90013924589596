import styled, { css } from 'styled-components';
import { Button } from '@cloudez/cloudez-design-system';
import { transparentize } from 'polished';

export const ImageText = styled.p`
  color: ${props => props.theme.interface5};
  font-size: 14px;
  font-weight: bold;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const Image = styled.div`
  height: 320px;
  width: 320px;
  background: ${props => props.theme.brand_primary_color};
`;

export const StageButton = styled(Button)`
  margin: auto;
  margin-top: 20px;
`;

export const Subtitle = styled.p`
  text-align: center;
  line-height: 1.5;
  color: ${props => props.theme.interface5};

  @media (max-width: 1000px) {
    width: 100% !important;
    margin: 0 !important;
  }
`;

export const Title = styled.h1`
  color: ${props =>
    props.theme.darkMode ? props.theme.interface4 : '#333333'};
  font-weight: 900;
  font-size: 2em;
  text-align: center;

  margin-bottom: 10px;
`;

export const ContentWrapper = styled.div`
  width: 70%;
  margin: 40px auto;

  p.domain {
    color: ${props => props.theme.brand_primary_color};
    text-align: center;
    margin-top: 30px;
    font-size: 17px;
    font-weight: bold;
  }

  p.description {
    color: ${props => props.theme.interface5};
    text-align: center;
    margin-top: 30px;
    font-size: 15px;
    max-width: 600px;
    margin: 30px auto;

    a {
      text-decoration: none;
      color: ${props => props.theme.brand_primary_color};
    }
  }

  ul.list {
    color: ${props => props.theme.interface5};
    text-align: center;
    margin-top: 30px;
    font-size: 15px;
    max-width: 600px;
    margin: 30px auto;

    li {
      margin-top: 20px;
    }

    svg {
      cursor: pointer;
      color: ${props => props.theme.brand_primary_color};
    }
  }

  div.alert-container {
    border: 1px solid ${props => props.theme.alertNew};
    background: transparent;
    padding: 15px 30px;
    max-width: 600px;
    margin: 0 auto;
    font-size: 15px;
    color: ${props => props.theme.interface5};
    border-radius: 9px;

    .highlight {
      color: ${props => props.theme.alertNew};
    }
  }

  @media (max-width: 1000px) {
    height: auto;
    width: 100%;
    padding: 0px 20px 20px 20px;

    div.wrapper {
      flex-direction: column;
    }
  }
`;

export const Header = styled.div`
  margin: auto;
  padding-top: 20px;
  width: 600px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

// export const Content = styled.div`
//   max-width: 500px;
//   display: flex;
//   flex-direction: column;
//   padding: 100px 50px;

//   form {
//     width: 100%;
//     padding: 15px 0px;

//     span {
//       color: ${props => props.theme.error};
//       font-size: 12px;
//     }

//     p {
//       font-size: 12px;
//       color: #979797;

//       a {
//         color: ${props => props.theme.label};
//         margin-top: 15px;

//         &:hover {
//           opacity: 1;
//         }
//       }
//     }
//   }

//   @media (max-width: 1000px) {
//     width: 100%;
//     margin: 0;
//   }
// `;

export const FreeDays = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  width: 300px;
  padding: 6px;
  border: 1px solid ${props => props.theme.interface3};
  border-radius: 11px;

  background-color: ${props => props.theme.interface1};

  span {
    color: ${props => props.theme.interface5};
    font-weight: bold;
    font-size: 14px;
  }

  div {
    width: 28px;
    height: 28px;
  }
`;

export const Badge = styled.img`
  width: 28px;

  ${props =>
    !props.completed &&
    css`
      opacity: 0.2;
    `}
`;

export const BadgeFlare = styled.img`
  width: 288px;
  margin: auto;
`;

export const BadgeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  margin: 36px 0 36px 0;
`;

export const Description = styled.p`
  text-align: center;
  max-width: 360px;
  margin: 0 auto 36px auto;
  line-height: 22px;

  color: ${props => props.theme.interface5};
`;

export const DomainField = styled.div`
  background-color: ${props => props.theme.interface2};
  border-radius: 5px;
  max-width: 600px;
  margin: 0 auto;
  padding: 16px;
`;
