import styled from 'styled-components';

export const SpamBadge = styled.div`
  margin-left: 30px;
  width: 60px;
  height: 19px;
  background: ${props => props.theme.errorNew};
  border-radius: 15px;

  font-size: 10px;
  color: ${props => props.theme.interface1};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    transform: translateX(-2px);
    font-size: 10px;
    margin-right: 2px;
    color: ${props => props.theme.interface1};
  }
`;
