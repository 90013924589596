import api from 'services/api';
import mccoyApi from 'services/mccoy';
import fridayApi from 'services/friday';

export default (token: string): void => {
  if (token) {
    api.defaults.headers.common.Authorization = `jwt ${token}`;
    mccoyApi.defaults.headers.common.Authorization =
      process.env.REACT_APP_GATEWAY_TOKEN || `jwt ${token}`;
    fridayApi.defaults.headers.common.Authorization =
      window.location.hostname === 'staging-app.cloudez.io'
        ? `Token ${process.env.REACT_APP_FRIDAY_TOKEN}`
        : `jwt ${token}`;
  } else {
    delete api.defaults.headers.common.Authorization;
    delete mccoyApi.defaults.headers.common.Authorization;
    delete fridayApi.defaults.headers.common.Authorization;
  }
};
