import React, { useEffect, useContext, useState, useRef } from 'react';
import queryString from 'query-string';
import { Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
  faStar,
} from '@fortawesome/free-solid-svg-icons';
import {
  Arrows,
  Pagination,
  Button,
  AdvancedSearch,
} from '@cloudez/cloudez-design-system';

import history from 'services/history';

import { Title, Header } from 'components/Common';
import SearchBar from 'components/SearchBar';

import Slider from 'react-slick';
import {
  FilterHover,
  Filter,
  FilterWrapper,
} from 'components/SearchBar/styles';
import { Value } from 'components/Acessos';
import EmptyListing from 'components/EmptyListing';

import { useDocTitle } from 'hooks/docTitle';
import { useLayout } from 'hooks/layout';
import useDevice from 'hooks/device';
import InvoiceWarning from 'pages/Dashboard/InvoiceWarning';
import { CloudListingContext, CloudListingProvider } from './_context/state';
import CloudPlaceholder from './Placeholder';
import CloudCard from './CloudCard';

const Clouds = () => {
  const theme = useLayout();
  const { isMobile } = useDevice();

  useDocTitle(`Clouds — ${theme.name}`);

  const { loading, clouds, count, previous, next, current, getClouds } =
    useContext(CloudListingContext);

  const {
    page = 1,
    search: _search = '',
    filter = 'all',
  } = queryString.parse(history.location.search);
  const [filterValue, setFilterValue] = useState(filter);

  const [search, setSearch] = useState(
    queryString.parse(history.location.search),
  );

  const abortControllerRef = useRef<AbortController>(new AbortController());

  const getCloudsCallback = (page, search, filter) => {
    abortControllerRef.current.abort();
    abortControllerRef.current = new AbortController();

    getClouds(page, search, filter, abortControllerRef.current);

    const _query = {
      ...search,
      page,
      filter,
    };

    if (!search) delete _query.search;
    if (filter === 'all') delete _query.filter;
    if (page === 1 || page === '1') delete _query.page;

    const query = queryString.stringify(_query);
    history.replace(`/clouds?${query}`);
  };

  useEffect(() => {
    getCloudsCallback(page, search, filter);
    // eslint-disable-next-line
  }, []);

  const searchValue = searchValue => {
    getCloudsCallback(1, searchValue, filterValue);
  };

  const handleSetFilter = filter => {
    setFilterValue(filter);
    getCloudsCallback(1, search, filter);
  };

  function NextArrow(props) {
    const { onClick, currentSlide } = props;
    return (
      <Arrows
        right
        onClick={onClick}
        icon={faChevronRight}
        disabled={currentSlide === 11}
      />
    );
  }

  function PrevArrow(props) {
    const { onClick, currentSlide } = props;

    return (
      <Arrows
        left
        onClick={onClick}
        icon={faChevronLeft}
        disabled={currentSlide === 0}
      />
    );
  }

  const settings = {
    className: 'center',
    centerMode: true,
    infinite: false,
    centerPadding: '14px',
    slidesToShow: 1,
    speed: 300,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  const placeholders = [];

  for (let i = 1; i <= 6; i++) {
    placeholders.push(i);
  }

  return (
    <>
      <Header>
        <Title>Clouds</Title>
        <Button onClick={() => history.push('/clouds/create')}>
          <span>Novo cloud</span>
        </Button>
      </Header>

      <InvoiceWarning />

      <SearchBar noInput>
        <AdvancedSearch
          style={{
            width: '400px',
          }}
          callback={searchValue}
          value={search}
          setValue={setSearch}
          noResults={false}
          fields={[
            {
              label: 'Qualquer campo',
              value: 'search',
            },
            {
              label: 'Nome do cloud',
              value: 'name',
            },
            {
              label: 'Fqdn do cloud',
              value: 'fqdn',
            },
            {
              label: 'Apelido do cloud',
              value: 'nickname',
            },
            {
              label: 'Email',
              value: 'email',
            },
          ]}
        />
        <FilterWrapper>
          <Filter>
            <FontAwesomeIcon
              icon={faStar}
              color={
                filterValue === 'favorite'
                  ? theme.favoriteNew
                  : theme.darkMode
                  ? theme.interface5
                  : theme.interface4
              }
              onClick={() =>
                filterValue === 'favorite'
                  ? handleSetFilter('all')
                  : handleSetFilter('favorite')
              }
            />
            <FilterHover>
              <Value>
                <span>Favoritos</span>
              </Value>
            </FilterHover>
          </Filter>
        </FilterWrapper>
      </SearchBar>

      {isMobile ? (
        <>
          {loading ? (
            <div>
              <CloudPlaceholder />
            </div>
          ) : (
            <Slider {...settings}>
              {clouds?.map(cloud => (
                <div key={cloud.id}>
                  <CloudCard cloud={cloud} key={cloud.id} />
                </div>
              ))}
            </Slider>
          )}
        </>
      ) : (
        <Row>
          {loading
            ? placeholders.map(p => (
                <Col xl="4" sm="6" key={p}>
                  <CloudPlaceholder />
                </Col>
              ))
            : clouds?.map(cloud => (
                <Col xl="4" sm="6" key={cloud.id}>
                  <CloudCard cloud={cloud} key={cloud.id} />
                </Col>
              ))}
        </Row>
      )}

      {!loading && clouds?.length > 0 && (
        <Pagination
          onChangePage={getCloudsCallback}
          count={count}
          previous={previous}
          next={next}
          current={current}
          search={search}
          pageSize={9}
          filter={filterValue}
        />
      )}

      {!loading && clouds?.length === 0 && (
        <EmptyListing>
          <p>Nenhum cloud encontrado.</p>
          <Button onClick={() => history.push('/clouds/create')} size="lg">
            Criar novo cloud
          </Button>
        </EmptyListing>
      )}
    </>
  );
};

export default props => (
  <CloudListingProvider>
    <Clouds {...props} />
  </CloudListingProvider>
);
