import React, { useState } from 'react';
import { withTheme } from 'styled-components';

import { Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolder, faLink } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-regular-svg-icons';

import { Input, Label, Field, Button } from '@cloudez/cloudez-design-system';

import { Tabs, Tab } from 'components/Tabs';
import copy from 'copy-to-clipboard';
import UserModal from 'pages/Clouds/CloudDetailNew/Users/UserModal';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from '../styles';

const FTPModal = ({ website, theme, setShow, updateField }) => {
  const [tab, setTab] = useState(1);

  const [modal, setModal] = useState(false);
  const [user, setUser] = useState(website?.user);

  return (
    <>
      {modal && (
        <UserModal
          setShow={() => setModal(false)}
          show={modal}
          user={user}
          updateField={updateField}
          website={website}
        />
      )}
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faFolder} />
        </Icon>
        <Text>
          <Title>Acessos</Title>
          <Subtitle>Informações de acesso de Website</Subtitle>
        </Text>
      </Header>
      <Tabs>
        <Tab
          active={tab === 1}
          onClick={() => {
            setTab(1);
          }}
        >
          sFTP
        </Tab>
        <Tab
          active={tab === 2}
          onClick={() => {
            setTab(2);
          }}
        >
          SSH
        </Tab>
      </Tabs>
      {tab === 1 && (
        <Content>
          <Field>
            <Label>Endereço temporário</Label>
            <Input
              block
              value={website?.temporary_address}
              readOnly
              background
            />

            <FontAwesomeIcon
              icon={faCopy}
              onClick={() => copy(website?.temporary_address)}
            />
          </Field>
          <Field>
            <Label>Server</Label>
            <Input block value={website?.cloud?.fqdn} readOnly background />
            <FontAwesomeIcon
              icon={faCopy}
              onClick={() => copy(website?.cloud?.fqdn)}
            />
          </Field>
          <Row>
            <Col lg="6">
              <Field>
                <Label>Usuário</Label>
                <Input
                  value={website?.user?.username}
                  readOnly
                  background
                  block
                />
                <FontAwesomeIcon
                  icon={faCopy}
                  onClick={() => copy(website?.user?.username)}
                />
              </Field>
            </Col>
            <Col lg="6">
              <Field>
                <Label>Senha</Label>
                <Button width="100%" outline onClick={() => setModal(true)}>
                  Alterar senha
                </Button>
              </Field>
            </Col>
          </Row>
        </Content>
      )}
      {tab === 2 && (
        <Content>
          <Field>
            <Label>SSH</Label>
            <Input
              block
              value={`${website?.user?.username}@${website?.cloud?.fqdn}`}
              readOnly
              style={{ color: theme.label }}
              background
            />
            <FontAwesomeIcon icon={faLink} />
          </Field>
          <Row>
            <Col>
              <Field>
                <Label>Protocolo</Label>
                <Input value="sFTP" readOnly background block />
              </Field>
            </Col>
            <Col>
              <Field>
                <Label>Porta</Label>
                <Input value="22" readOnly background block />
                <FontAwesomeIcon icon={faCopy} />
              </Field>
            </Col>
          </Row>
          <Row>
            <Col>
              <Field>
                <Label>Usuário</Label>
                <Input
                  value={website?.user?.username}
                  readOnly
                  background
                  block
                />
                <FontAwesomeIcon icon={faCopy} />
              </Field>
            </Col>
            <Col>
              <Field>
                <Label>Senha</Label>
                <Button width="100%" outline onClick={() => setModal(true)}>
                  Alterar senha
                </Button>
              </Field>
            </Col>
          </Row>
        </Content>
      )}
      <Footer>
        <Button onClick={() => setShow(false)}>Ok</Button>
      </Footer>
    </>
  );
};

export default withTheme(FTPModal);
