import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px 40px 0px;
  color: ${props => props.theme.interface7};
`;

export const MigrationWrapper = styled.div`
  display: flex;
  flex-direction: row;

  gap: 20px;
`;

export const MigrationSelect = styled.div`
  width: 100%;

  padding: 50px;
  gap: 30px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: ${props => props.theme.interface1};
  box-shadow: 0px 2px 15px rgba(100, 114, 140, 0.25);
  border-radius: 5px;

  p {
    color: ${props => props.theme.interface5};
  }
`;

export const AssistedMigration = styled.div`
  display: flex;
  flex-direction: row;
  padding: 40px;

  box-shadow: 0px 2px 15px rgba(100, 114, 140, 0.25);
  border-radius: 5px;

  gap: 35px;

  background-color: ${props => props.theme.interface1};

  margin-top: 56px;

  .textContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .textContent {
      display: flex;
      flex-direction: column;

        .subtitle {
          color: ${props => props.theme.interface5};
          font-weight: 300;
          font-size: 14px;
        }
    }
      .alert {
      color: ${props => props.theme.brand_primary_color};
      font-size: 14px;
    }
  }

  p {
    color: ${props => props.theme.interface5};
  }

  b {
    color: ${props => props.theme.interface7}
    font-style: italic;
    font-weight: 700;
    font-size: 18px;
  }
`;

export const MigrationInfo = styled.div`
  padding-right: 50px;
  width: 50%;
  height: 100%;

  p {
    color: ${props => props.theme.interface5} !important;
  }

  p + p {
    margin-top: 6px;
  }

  div + div {
    margin-top: 30px;

    ul {
      list-style: none;
      flex-wrap: wrap;

      li {
        padding: 12px 13px;
        cursor: pointer;
        border: 1px solid transparent;

        ul {
          display: flex;
          list-style: initial;
          width: 100%;
          justify-content: space-around;
          padding: 12px 13px;

          li {
            padding: 0;
            color: ${props => props.theme.brand_primary_color} !important;
          }
        }

        &:nth-child(odd) {
          background-color: ${props => props.theme.interface2};
        }

        &:hover {
          color: ${props => props.theme.brand_primary_color};
          border: 1px solid ${props => props.theme.brand_primary_color};
        }
      }
    }
  }

  h1 {
    color: ${props => props.theme.brand_primary_color};
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  h3 {
    color: ${props => props.theme.interface5};
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 18px;
  }

  p {
    color: ${props => props.theme.interface4};
    text-align: left;
    line-height: 1.74;
  }

  @media (max-width: 1210px) {
    padding-right: 0px;
    width: 100%;
  }
`;
