import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { AnimatedList } from 'react-animated-list';

import Placeholder from 'components/Placeholder';

import {
  Card,
  CardContent,
  Listing,
  ListingItem,
  ApplicationValue,
  CloudValue,
  ListingHeader,
} from 'components/Listing/styles';

import { Acesso, AcessosList } from 'components/Acessos';

const EmailPlaceholder = () => {
  const placeholders = [];

  for (let i = 1; i <= 4; i++) {
    placeholders.push(i);
  }

  return (
    <>
      <ListingHeader>
        <Row>
          <Col xs="10" sm="10" md="7" lg="6" xl="5">
            <p>Provedor</p>
          </Col>

          <Col className="d-none d-xl-flex">
            <p>Cloud</p>
          </Col>

          <Col className="d-none d-md-flex">
            <p>Disco</p>
          </Col>

          <Col xl="2" className="d-none d-md-flex" />
        </Row>
      </ListingHeader>

      <Listing>
        <AnimatedList initialAnimationDuration={800} animation="grow">
          {placeholders.map((p, i) => (
            <ListingItem key={p} fade={i > 0 && i}>
              <Card>
                <CardContent>
                  <Col xs="10" sm="10" md="7" lg="6" xl="5">
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        marginLeft: 15,
                      }}
                    >
                      <Placeholder circle width={30} height={30} />
                      <span style={{ marginLeft: 12 }}>
                        <Placeholder width={180} height={12} />
                      </span>
                    </div>
                  </Col>

                  <Col className="d-none d-xl-flex">
                    <ApplicationValue style={{ fontWeight: 'normal' }}>
                      <CloudValue>
                        <div
                          style={{
                            display: 'flex',
                            flexDirection: 'column',
                          }}
                        >
                          <Placeholder width={50} height={8} />
                          <Placeholder width={20} height={6} />
                        </div>
                      </CloudValue>
                    </ApplicationValue>
                  </Col>

                  <Col className="d-none d-sm-none d-md-flex">
                    <ApplicationValue>
                      <Placeholder width={22} height={8} />
                    </ApplicationValue>
                  </Col>

                  <Col xl="2" className="d-none d-xl-flex">
                    <AcessosList>
                      <Acesso active hover>
                        <Placeholder width={16} height={18} />
                      </Acesso>

                      <Acesso active hover>
                        <Placeholder width={16} height={18} />
                      </Acesso>

                      <Acesso active hover>
                        <Placeholder width={16} height={18} />
                      </Acesso>

                      <Acesso active hover>
                        <Placeholder width={16} height={18} />
                      </Acesso>

                      <Acesso active hover>
                        <Placeholder width={16} height={18} />
                      </Acesso>
                    </AcessosList>
                  </Col>
                </CardContent>
              </Card>
            </ListingItem>
          ))}
        </AnimatedList>
      </Listing>
    </>
  );
};

export default EmailPlaceholder;
