import React, { useEffect, useState, useContext } from 'react';
import { withTheme } from 'styled-components';
import { Row, Col } from 'react-bootstrap';

import ApplicationIcon from 'components/ApplicationIcon';

import { Input } from '@cloudez/cloudez-design-system';

import { getEmailTypesService } from 'services/email';
import { useAuth } from 'hooks/auth';
import { Wrapper, Type } from './styles';
import { EmailCreateContext } from '../_context/state';
import Placeholder from './Placeholder';

const EmailTypes = () => {
  const { updateField, newEmail, updateNewEmail } =
    useContext(EmailCreateContext);

  const [types, setTypes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const { user } = useAuth();

  useEffect(() => {
    async function getEmailTypes() {
      setLoading(true);
      const { data } = await getEmailTypesService({ company: user.company });

      setTypes(data);
      setLoading(false);
    }

    if (!types.length) getEmailTypes();
    // eslint-disable-next-line
  }, [types]);

  return (
    <Wrapper>
      {loading ? (
        <Row
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Placeholder />
        </Row>
      ) : (
        <>
          <Row
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {types &&
              types.map(t => (
                <Col
                  key={t.id}
                  xs="6"
                  md="4"
                  lg="3"
                  xl="2"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Type
                    onClick={() =>
                      updateNewEmail({
                        ...newEmail,
                        type: t.id,
                        values: t.values.map(t =>
                          t.slug === 'webmail'
                            ? {
                                slug: t.slug,
                                value: true,
                              }
                            : {
                                slug: t.slug,
                                value: t.default,
                              },
                        ),
                      })
                    }
                    selected={newEmail.type === t.id}
                  >
                    <ApplicationIcon
                      width="40px"
                      height="40px"
                      icon={
                        t.slug === 'selfhosted-mail'
                          ? 'cloudez-footer'
                          : `${t.slug}`
                      }
                    />
                    <span>{t.name}</span>
                    <p>{t.description}</p>
                  </Type>
                </Col>
              ))}
          </Row>
          {newEmail.type > 2 && (
            <Row
              style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
              }}
            >
              <Input
                placeholder="Código de verificação."
                value={verificationCode}
                onChange={e => {
                  setVerificationCode(e.target.value);

                  updateField(
                    'values',
                    newEmail.values.map(v =>
                      v.slug === 'verification_code'
                        ? { ...v, value: e.target.value }
                        : v,
                    ),
                  );
                }}
              />
            </Row>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default withTheme(EmailTypes);
