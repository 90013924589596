import React, { useContext, useEffect, useState } from 'react';

import { Row, Col } from 'react-bootstrap';

import {
  Input,
  Label,
  Error,
  Field,
  Select,
} from '@cloudez/cloudez-design-system';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import generatePassword from 'utils/generatePassword';
import { TabContent } from 'components/Tabs';
import { getWebsiteTypeService } from 'services/website';
import { Wrapper, Title } from './styles';
import { WebsiteCreateContext } from '../../_context/state';

const Shellhub = ({ errors }) => {
  const { updateWebsiteValue } = useContext(WebsiteCreateContext);

  const [versions, setVersions] = useState(null);

  const [password, setPassword] = useState({
    slug: 'shellhub_password',
    value: '',
  });
  const [version, setVersion] = useState({
    slug: 'shellhub_version',
    value: '',
  });
  const [user, setUser] = useState({
    slug: 'shellhub_username',
    value: '',
  });

  useEffect(() => {
    const getType = async () => {
      try {
        const { data } = await getWebsiteTypeService(110);
        setVersions(
          data.values.find(v => v.slug === 'shellhub_version')?.choices,
        );
      } catch (e) {
        console.log(e);
      }
    };

    getType();
  }, []);

  return (
    <Wrapper>
      <Title
        style={{
          marginBottom: '10px',
        }}
      >
        Configurações do Shellhub
      </Title>

      <TabContent>
        <Row
          style={{
            marginLeft: 0,
            width: '100%',
            padding: '8px 30px',
          }}
        >
          <Col sm="4">
            <Field>
              <Label>Usuário</Label>
              <Input
                block="true"
                autoComplete="off"
                placeholder="Usuário"
                name="shellhub_username"
                value={user.value}
                onChange={e => {
                  setUser({
                    ...user,
                    value: e.target.value,
                  });
                  updateWebsiteValue(e);
                }}
                error={!!errors}
              />
              {errors && <Error>{errors[0]}</Error>}
            </Field>
          </Col>
          <Col sm="4">
            <Field>
              <Label>Senha</Label>
              <Input
                block="true"
                placeholder="Senha"
                name="shellhub_password"
                value={password.value}
                onChange={e => {
                  setPassword({
                    ...password,
                    value: e.target.value,
                  });
                  updateWebsiteValue(e);
                }}
                error={!!errors}
              />
              {errors && <Error>{errors[0]}</Error>}
              <FontAwesomeIcon
                icon={faKey}
                onClick={() => {
                  const pass = generatePassword();

                  setPassword({
                    ...password,
                    value: pass,
                  });
                  updateWebsiteValue({
                    target: {
                      name: 'shellhub_password',
                      value: pass,
                    },
                  });
                }}
              />
            </Field>
          </Col>
          <Col sm="4">
            <Field>
              <Label>Versão</Label>
              <Select
                block
                name="shellhub_version"
                value={version.value}
                onChange={e => {
                  setVersion({
                    ...version,
                    value: e.target.value,
                  });
                  updateWebsiteValue(e);
                }}
                error={!!errors}
              >
                <option disabled value="">
                  Escolha uma versão:
                </option>
                {versions?.map(v => (
                  <option key={v} value={v}>
                    {v}
                  </option>
                ))}
              </Select>
              {errors && <Error>{errors[0]}</Error>}
            </Field>
          </Col>
        </Row>
      </TabContent>
    </Wrapper>
  );
};

export default Shellhub;
