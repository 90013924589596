import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ContentWrapper, Title, StageButton, Subtitle } from '../styles';

import WebsiteTypes from './WebsiteTypes';

const Application = ({ newWebsite, setNewWebsite, loading, createApp }) => {
  return (
    <ContentWrapper style={{ paddingBottom: '40px' }}>
      <Title>Criar aplicação</Title>
      <Subtitle>
        Escolha o tipo da sua aplicação e confira o poder do Cloud
      </Subtitle>

      <WebsiteTypes newWebsite={newWebsite} setNewWebsite={setNewWebsite} />

      <StageButton
        onClick={createApp}
        icon
        disabled={loading || !newWebsite.type}
        style={{
          marginBottom: '30px',
        }}
      >
        {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Próximo Passo'}
      </StageButton>
    </ContentWrapper>
  );
};

export default Application;
