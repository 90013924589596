import React from 'react';
import { useLayout } from 'hooks/layout';

import { Button } from './styles';

const CollapseButton: React.FC = () => {
  const { sideMenuCollapsed, toggleMenu } = useLayout();

  return (
    <Button sideMenuCollapsed={sideMenuCollapsed} onClick={toggleMenu}>
      <div />
      <div />
      <div />
    </Button>
  );
};

export default CollapseButton;
