import styled from 'styled-components';

export const DropdownList = styled.div`
  height: auto;
  position: absolute;
  background: ${props => props.theme.interface1};
  border: 1px solid ${props => props.theme.interface3};
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  z-index: 999;
  right: -75px;

  bottom: 0;

  width: 500px;

  top: 191px;

  animation: fadein 0.2s linear;

  @keyframes fadein {
    from {
      width: 0px;
    }
    to {
      width: 500px;
    }
  }
  @media (max-width: 1200px) {
    width: 400px;

    @keyframes fadein {
      from {
        width: 0px;
      }
      to {
        width: 400px;
      }
    }
  }

  @media (max-width: 768px) {
    width: 320px;

    @keyframes fadein {
      from {
        width: 0px;
      }
      to {
        width: 320px;
      }
    }
  }

  .header {
    display: flex;
    align-items: center;

    padding: 23px 33px;

    border-bottom: 1px solid ${props => props.theme.interface3};

    font-weight: 700;
    font-size: 16px;
    line-height: 20px;

    gap: 12px;

    svg {
      font-size: 20px;
      cursor: pointer;

      transition: opacity 0.2s;

      &:hover {
        opacity: 0.9;
      }
    }

    color: ${props => props.theme.brand_primary_color};
  }

  .filters {
    padding: 19px 33px;

    font-size: 14px;
    line-height: 19px;

    ul {
      color: ${props => props.theme.interface5};

      .categories {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
      }

      .appTypes,
      .types,
      .status {
        margin-top: 14px;

        font-weight: 400;
      }

      .status {
        overflow-y: scroll;
        height: 110px;
        width: 200px;

        div {
          margin-bottom: 5px;
        }
      }

      .appTypes {
        overflow-y: scroll;
        height: 200px;
        width: 200px;
      }
    }

    ul > li {
      margin-bottom: 12px;
    }

    li {
      display: flex;
      flex-direction: row;

      .title {
        font-weight: 700;
        color: ${props => props.theme.interface7};
      }
    }
  }
`;
