import React from 'react';

import NotificationBar from 'components/NotificationBar';
import { useLayout } from 'hooks/layout';
import Sidebar from './Sidebar';
import Navbar from './Navbar';
import Footer from './Footer';
import { Container, Content } from './styles';

const DefaultLayout: React.FC = ({ children }) => {
  const { sideMenuCollapsed, notification } = useLayout();

  return (
    <Container
      sideMenuCollapsed={sideMenuCollapsed}
      hasNotification={notification?.type}
    >
      {notification?.type && <NotificationBar />}
      <Sidebar />
      <Navbar />

      <Content>{children}</Content>
      <Footer />
    </Container>
  );
};

export default DefaultLayout;
