import api from 'services/api';

export const getInvoicesService = async (
  params,
  ctrl = new AbortController(),
) => {
  try {
    return await api.get('/v3/invoice/', { params, signal: ctrl.signal });
  } catch (e) {
    throw e;
  }
};

export const getInvoiceService = async (id, params?) => {
  try {
    return await api.get(`/v3/invoice/${id}/`, { params });
  } catch (e) {
    throw e;
  }
};

export const updateInvoiceService = async (id, data) => {
  try {
    return await api.patch(`/v3/invoice/${id}/`, data);
  } catch (e) {
    throw e;
  }
};

export const createInvoiceService = async payload => {
  try {
    return await api.post(`/v3/invoice/`, payload);
  } catch (error) {
    throw error;
  }
};

export const chargeInvoiceService = async id => {
  try {
    return await api.post(`/v3/invoice/${id}/charge/`);
  } catch (e) {
    throw e;
  }
};

export const refreshBankslipService = async id => {
  try {
    return await api.post(`/v3/invoice/${id}/refresh-bankslip/`);
  } catch (e) {
    throw e;
  }
};

export const uploadProofOfPaymentService = async (
  id: number | string,
  payload: any,
  headers: any,
) => {
  try {
    return await api.post(
      `/v3/invoice/${id}/upload_proof_of_payment/`,
      payload,
      headers,
    );
  } catch (error) {
    throw error;
  }
};
