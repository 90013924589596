import React from 'react';
import { HoverableIcon } from '@cloudez/cloudez-design-system';
import { Elipse } from './styles';

interface INavbarElipseProps {
  icon: any;
  hover: any;
  avatar?: any;
  iconColor?: any;
}

const NavbarElipse: React.FC<INavbarElipseProps> = ({
  icon,
  hover,
  avatar,
  iconColor,
  children,
}) => {
  return (
    <Elipse>
      <HoverableIcon
        icon={icon}
        hover={hover}
        avatar={avatar}
        iconColor={iconColor}
      />
      {children}
    </Elipse>
  );
};

export default NavbarElipse;
