import React, { useContext, useEffect, useState } from 'react';

import { getCodeDeviceService, verifyDeviceService } from 'services/user';

import moment from 'moment';

import {
  Footer,
  Icon,
  Text,
  Title,
  Header,
} from 'components/ListingModal/styles';

import { ThemeContext } from 'styled-components';

import InputMask from 'react-input-mask';

import { toast } from 'react-toastify';

import * as yup from 'yup';

import {
  Modal,
  Button,
  Form,
  FormInput,
  Error,
} from '@cloudez/cloudez-design-system';

import copy from 'copy-to-clipboard';

import QRCode from 'react-qr-code';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faQrcode, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Content, InputWithIcon } from './styles';
import { SecurityCompanyContext } from '../_context/state';

interface AuthModalProps {
  show: boolean;
  setShow: (value: boolean) => void;
  setIsAppActive: (value: boolean) => void;
  otpStatus: { app: boolean; email: boolean };
}

const AuthModal: React.FC<AuthModalProps> = ({
  show,
  setShow,
  setIsAppActive,
  otpStatus,
}) => {
  const [codeError, setCodeError] = useState('');

  const { code, setCode } = useContext(SecurityCompanyContext);
  const [loading, setLoading] = useState(false);

  const theme = useContext(ThemeContext);

  const schema = yup.object().shape({
    code: yup.string().required('O código é obrigatório.'),
  });

  const activeTwoAuthFactor = async data => {
    schema
      .validate(data, { abortEarly: false })
      .then(async result => {
        setLoading(true);
        await verifyDeviceService({
          code: result.code.replace(/[^0-9]/g, ''),
          device_type: 'app',
        });

        toast.success('Autenticação de dois fatores ativada com sucesso.');
        setIsAppActive(true);
        setShow(false);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        if (error.response?.data?.reason === 'N_FAILED_ATTEMPTS') {
          setCodeError(
            `Você excedeu o número de tentativas, aguarde até ${moment(
              error.response?.data?.locked_until,
            ).format('LT')}.`,
          );
        } else if (
          error.message &&
          error.response?.data.message !== 'Code not valid'
        ) {
          setCodeError(error.message);
        } else {
          setCodeError('Código incorreto!');
        }
      });
  };

  useEffect(() => {
    if (otpStatus?.email) {
      const getCode = async () => {
        const response = await getCodeDeviceService();

        setCode(response.data);
      };

      getCode();
    }
  }, [otpStatus?.email, show]);

  return (
    <Modal width="497px" show={show} setShow={setShow}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faQrcode} />
        </Icon>
        <Text>
          <Title>Aplicativo de Autenticação</Title>
        </Text>
      </Header>

      <Form onSubmit={activeTwoAuthFactor}>
        <Content>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '20px',
            }}
          >
            <span className="attention">
              Scaneie o QR Code abaixo para adicionar sua conta do painel ao seu
              aplicativo de autenticação.
            </span>
            <QRCode size={120} value={code.url} />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '12px',
            }}
          >
            <span className="attention">
              Se o seu aplicativo de autenticação não possuir um scanner, você
              pode utilizar esse código secreto:
            </span>
            <InputWithIcon>
              <FormInput
                width="368px"
                disabled
                value={code.secret}
                name="codeAuth"
              />
              <FontAwesomeIcon
                onClick={() => copy(code.secret)}
                color={theme.interface5}
                icon={faCopy}
              />
            </InputWithIcon>
          </div>

          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '12px',
            }}
          >
            <span className="attention">
              Por favor, insira o código que foi gerado pelo seu aplicativo de
              autenticação:
            </span>
            <div>
              <InputMask
                width="368px"
                mask="999 999"
                maskChar={null}
                name="code"
                placeholder="Insira o código gerado"
                error={codeError !== ''}
              >
                {inputProps => <FormInput {...inputProps} />}
              </InputMask>
              {codeError && <Error>{codeError}</Error>}
            </div>
          </div>
        </Content>
        <Footer style={{ justifyContent: 'center' }}>
          <Button disabled={loading} type="submit">
            {loading ? (
              <FontAwesomeIcon
                style={{ marginLeft: '0' }}
                icon={faSpinner}
                spin
              />
            ) : (
              'Ativar'
            )}
          </Button>
        </Footer>
      </Form>
    </Modal>
  );
};

export default AuthModal;
