import styled from 'styled-components';

export const CardHeader = styled.h3`
  font-size: 16px;
  color: ${props => props.theme.interface5};
`;

export const Card = styled.div`
  height: auto;
  padding: 15px;
  background: ${props => props.theme.interface1};
  border-radius: 5px;
`;
