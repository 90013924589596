import styled, { css } from 'styled-components';

export const Title = styled.h1`
  color: ${props => props.theme.interface5};
  font-size: 18px;
  font-weight: bold;
`;

export const Wrapper = styled.div`
  margin-top: 16px;
  width: 100%;
`;

export const Existent = styled.a`
  color: ${props => props.theme.brand_primary_color};
  font-size: 10px;
  text-transform: uppercase;
  cursor: pointer;

  position: absolute;
  right: 20px;
  top: 0;
`;

export const HelpText = styled.p`
  align-self: center;
  font-size: 16px;
  color: ${props => props.theme.interface5};
  text-align: center;
`;

interface TypeProps {
  type?: any;
  selected?: boolean;
}

export const Type = styled.div<TypeProps>`
  cursor: pointer;
  width: 100%;
  height: 110px;

  display: flex;
  flex-direction: column;

  align-items: center;

  border-radius: 5px;

  margin-bottom: 30px;
  padding: 10px;

  background: ${props => props.theme.interface2};
  border: 2px solid transparent;

  ${props =>
    props.selected &&
    css`
      border: 2px solid
        ${props =>
          props.theme.darkMode
            ? props.theme.interface5
            : props.theme.brand_primary_color};
    `}

  &:hover {
    transition: border 0.2s ease-in;
    border: 2px solid
      ${props =>
        props.theme.darkMode
          ? props.theme.interface5
          : props.theme.brand_primary_color};
  }

  span {
    margin-top: 8px;
    font-size: 14px;
    color: ${props => props.theme.interface5};
  }

  p {
    margin-top: 4px;
    font-size: 10px;
    color: ${props => props.theme.interface5};
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
