import React, { useState, useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFolder,
  faShareAlt,
  faStar,
  faEnvelope,
  faCog,
  faSpinner,
  faBan,
  faShieldAlt,
} from '@fortawesome/free-solid-svg-icons';

import {
  Card,
  ListingItem,
  ApplicationValue,
  ApplicationName,
  CloudValue,
  ApplicationSubName,
} from 'components/Listing/styles';
import { AcessosList, Acesso, AcessoHover, Value } from 'components/Acessos';
import ApplicationIcon from 'components/ApplicationIcon';
import ListingModal from 'components/ListingModal';

import kbToMb from 'utils/kbToMb';
import formatCloudDisplay from 'utils/formatCloudDisplay';
import { ThemeContext } from 'styled-components';
import { Link } from 'react-router-dom';
import { Hover, HoverText } from 'components/Hover';
import { Hosted } from 'pages/Websites/WebsiteListing/WebsiteCard/styles';
import { Email } from 'types/email';
import { EmailListingContext } from '../_context/state';
import { SpamBadge } from './styles';
import MailGatewayModal from './MailGatewayModal';
import WebmailModal from './WebmailModal';

interface EmailCardProps {
  email: Email;
}

const EmailCard: React.FC<EmailCardProps> = ({ email }) => {
  const theme = useContext(ThemeContext);
  const { updateEmail } = useContext(EmailListingContext);

  const [showAccess, setShowAccess] = useState(false);
  const [modal, toggleModal] = useState(false);
  const [mgModal, toggleMgModal] = useState(false);
  const [data, setData] = useState(null);
  const [type, setType] = useState('ftp');
  const [webmailModal, setWebmailModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const activateWebmail = async () => {
    try {
      const payload = email.values.map(v =>
        v.slug === 'webmail' ? { ...v, value: 'true' } : v,
      );

      await updateEmail('values', payload, email.id);
    } catch (e) {}
  };

  const openModal = (e, type, data) => {
    e.stopPropagation();
    e.preventDefault();

    setData(data);
    setType(type);
    toggleModal(!modal);
  };

  let cloudDisplay;

  if (email.cloud_display)
    cloudDisplay = formatCloudDisplay(email.cloud_display);

  return (
    <ListingItem key={email.id} disable={email.is_disabled}>
      {modal && (
        <ListingModal
          show={modal}
          setShow={() => toggleModal(false)}
          type={type}
          data={data}
          action={updateEmail}
        />
      )}
      {webmailModal && (
        <WebmailModal
          show={webmailModal}
          setShow={() => setWebmailModal(false)}
          action={activateWebmail}
        />
      )}
      {mgModal && <MailGatewayModal show={mgModal} setShow={toggleMgModal} />}
      <Card>
        <Link to={`/emails/${email.id}`}>
          <Row>
            <Col
              xs={showAccess ? '4' : '10'}
              sm={showAccess ? '4' : '10'}
              md="5"
            >
              <ApplicationValue>
                <ApplicationIcon
                  width="40px"
                  height="40px"
                  icon={
                    email.type.slug === 'selfhosted-mail'
                      ? 'cloud_cloudez'
                      : `${email.type.slug}`
                  }
                  disable={email.is_disabled}
                />
                {email.is_hosted && email.type.slug === 'selfhosted-mail' && (
                  <Hover>
                    <Hosted active />
                    <HoverText
                      style={{
                        top: '16px',
                      }}
                    >
                      DNS apontado com sucesso
                    </HoverText>
                  </Hover>
                )}
                {!showAccess && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <ApplicationName>
                      {email.domain}
                      {email.has_spammer && (
                        <SpamBadge>
                          <FontAwesomeIcon icon={faBan} />
                          SPAM
                        </SpamBadge>
                      )}
                    </ApplicationName>
                    <ApplicationSubName>{email.type.name}</ApplicationSubName>
                  </div>
                )}
              </ApplicationValue>
            </Col>

            <Col className="d-none d-xl-flex">
              <ApplicationValue style={{ fontWeight: 'normal' }}>
                {cloudDisplay ? (
                  <>
                    <CloudValue>
                      <span>{cloudDisplay[0]}</span>
                      <span
                        style={{
                          fontSize: `${10}px`,
                        }}
                      >
                        {cloudDisplay[1]}
                      </span>
                    </CloudValue>
                  </>
                ) : (
                  <p>Email externo</p>
                )}
              </ApplicationValue>
            </Col>

            <Col className="d-none d-md-flex">
              <ApplicationValue style={{ fontWeight: 'normal' }}>
                <p>{email.disk_usage ? kbToMb(email.disk_usage) : '0 B'}</p>
              </ApplicationValue>
            </Col>

            {!showAccess && (
              <Col className="d-sm-flex d-md-none">
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCog}
                    style={{
                      color: '#64728C',
                    }}
                    onClick={e => {
                      e.preventDefault();
                      setShowAccess(true);
                    }}
                  />
                </div>
              </Col>
            )}

            <Col
              xl="2"
              lg="5"
              md="5"
              sm="8"
              xs="8"
              className={!showAccess && 'd-none d-md-flex'}
              onClick={e => {
                e.preventDefault();
                setShowAccess(false);
              }}
              style={{
                background: showAccess && theme.interface3,
                height: '100%',
                width: '100%',
                borderTopRightRadius: '5px',
                borderBottomRightRadius: '5px',
              }}
            >
              <AcessosList showAccess={showAccess} disable={email.is_disabled}>
                <Acesso active hover onClick={e => openModal(e, 'smtp', email)}>
                  <FontAwesomeIcon icon={faFolder} />
                  <AcessoHover>
                    <Value>
                      <span>Acessos</span>
                    </Value>
                  </AcessoHover>
                </Acesso>
                <Acesso
                  active
                  fixed={email?.webmail_url}
                  hover
                  onClick={() => !email?.webmail_url && setWebmailModal(true)}
                >
                  {email?.webmail_url ? (
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      onClick={() => window.open(email?.webmail_url)}
                    />
                  ) : (
                    <FontAwesomeIcon icon={faEnvelope} />
                  )}
                  <AcessoHover>
                    <Value>
                      <span>Webmail</span>
                    </Value>
                  </AcessoHover>
                </Acesso>
                <Acesso active fixed hover onClick={e => toggleMgModal(true)}>
                  <FontAwesomeIcon icon={faShieldAlt} />
                  <AcessoHover>
                    <Value>
                      <span>Mail Gateway</span>
                    </Value>
                  </AcessoHover>
                </Acesso>
                <Acesso
                  active
                  fixed={!!email.shared_users.length}
                  hover
                  onClick={e => openModal(e, 'share', email)}
                >
                  <FontAwesomeIcon icon={faShareAlt} />
                  <AcessoHover>
                    <Value>
                      <span>Share</span>
                    </Value>
                  </AcessoHover>
                </Acesso>
                <Acesso active fixed={email.is_pinned} hover favorite>
                  <FontAwesomeIcon
                    onClick={async e => {
                      setLoading(true);
                      e.preventDefault();

                      await updateEmail(
                        'is_pinned',
                        !email.is_pinned,
                        email.id,
                      );
                      setLoading(false);
                    }}
                    icon={loading ? faSpinner : faStar}
                    spin={loading}
                  />
                  <AcessoHover>
                    <Value>
                      <span>Favorite</span>
                    </Value>
                  </AcessoHover>
                </Acesso>
              </AcessosList>
            </Col>
          </Row>
        </Link>
      </Card>
    </ListingItem>
  );
};

export default EmailCard;
