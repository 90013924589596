import React, { useContext } from 'react';

import { Button } from '@cloudez/cloudez-design-system';
import parabens from 'assets/img/parabens.png';

import history from 'services/history';

import { ThemeContext } from 'styled-components';
import { Wrapper } from './styles';

const InvoiceSuccess: React.FC = () => {
  const theme = useContext(ThemeContext);

  return (
    <Wrapper>
      <div
        style={{
          background: theme.brand_primary_color,
          width: '525px',
          height: '380px',
        }}
      >
        <img src={parabens} alt="parabéns" />
      </div>
      <span>Parabéns</span>
      <p>Sua fatura foi paga.</p>
      <Button size="lg" onClick={() => history.push('/clouds')}>
        Dashboard
      </Button>
    </Wrapper>
  );
};

export default InvoiceSuccess;
