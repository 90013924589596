import React, { useEffect, useState } from 'react';
import SearchInput from 'components/SearchInput';
import {
  Pagination,
  Button,
  Loading,
  createPagination,
} from '@cloudez/cloudez-design-system';
import { getCloudsService } from 'services/cloud';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { Wrapper, FilterDropdownList, CloudItem, Selected } from './styles';

const FilterDropdown = ({ callback, show, setShow, type = 'default' }) => {
  const [clouds, setClouds] = useState(null);
  const [cloud, setCloud] = useState([]);
  const [search, setSearch] = useState(null);
  const [cloudLoading, setCloudLoading] = useState(false);

  useEffect(() => {
    if (show) {
      document.addEventListener('keydown', e => {
        if (e.keyCode === 27) {
          setShow(false);
        }
      });
    }

    return () => {
      document.removeEventListener('keydown', e => {
        if (e.keyCode === 27) {
          setShow(false);
        }
      });
    };
    // eslint-disable-next-line
  }, [show]);

  const getCloudsCallback = async (page, search) => {
    setCloudLoading(true);
    try {
      const { data } = await getCloudsService({
        page_size: 5,
        page,
        search,
        category:
          type === 'default'
            ? 'default,ram,cpu,volume'
            : 'default,ram,cpu,volume,email',
      });

      const pagination = createPagination(data, 5);

      setClouds({ clouds: data.results, ...pagination });
      setCloudLoading(false);
    } catch (e) {
      setCloudLoading(false);
    }
  };

  useEffect(() => {
    getCloudsCallback(1, '');
  }, []);

  const searchValue = searchValue => {
    getCloudsCallback(1, searchValue);
  };

  return (
    <Wrapper show={show}>
      <SearchInput
        style={{
          width: '100%',
          height: '34px',
          fontSize: '10px',
        }}
        value={search}
        callback={searchValue}
      />

      <FilterDropdownList>
        {cloudLoading ? (
          <Loading />
        ) : clouds?.clouds?.length ? (
          <>
            {clouds?.clouds?.map(c => (
              <CloudItem
                key={c.id}
                onClick={() => {
                  const filtered = cloud.filter(cl => cl !== c.id);

                  cloud.includes(c.id)
                    ? setCloud(filtered)
                    : setCloud([...cloud, c.id]);
                }}
              >
                <Selected selected={cloud.includes(c.id)} />
                {c.nickname || c.name}
              </CloudItem>
            ))}
            <Pagination
              style={{
                margin: '8px auto',
                width: '100%',
              }}
              onChangePage={getCloudsCallback}
              isFilter
              count={clouds.count}
              previous={clouds.previous}
              next={clouds.next}
              current={clouds.current}
              search={search}
              pageSize={5}
            />
          </>
        ) : null}
      </FilterDropdownList>

      <Button
        width="66%"
        onClick={() => {
          callback(cloud);
        }}
        style={{
          display: 'inline',
          marginRight: '12px',
          height: '30px',
        }}
      >
        Filtrar
      </Button>
      <Button
        width="26%"
        style={{
          display: 'inline',
          height: '30px',
        }}
        onClick={() => {
          setCloud([]);
        }}
        icon
        error
        outline
      >
        <FontAwesomeIcon icon={faTimes} />
      </Button>
    </Wrapper>
  );
};

export default FilterDropdown;
