import { createPagination } from '@cloudez/cloudez-design-system';
import { Reducer } from 'react';
import { IActionPayload } from 'types';
import { CompanySecurityState } from './state';

const reducer: Reducer<CompanySecurityState, IActionPayload> = (
  state,
  action,
): CompanySecurityState => {
  switch (action.type) {
    case 'GET_OTP_STATUS':
      const otpStatus = action.payload.otp_enabled_option;
      const { devices } = action.payload;

      const pagination = createPagination(devices, 6);

      return {
        ...state,
        otpStatus,
        devices: { ...devices, ...pagination },
      };

    case 'SET_CODE':
      return {
        ...state,
        code: action.payload,
      };

    // case 'SET_DEVICES':
    //   return {
    //     ...state,
    //     devices: {
    //       ...state.devices,
    //       results: state.devices.results.filter(
    //         item => item.id !== action.payload,
    //       ),
    //     },
    //   };

    case 'SET_LOADING': {
      return {
        ...state,
        loading: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
