import React, { useState, useContext, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';

import ApplicationIcon from 'components/ApplicationIcon';
import { Button } from '@cloudez/cloudez-design-system';

import RemoveModal from '../RemoveModal';
import { Card, Header, Name, Body } from './styles';
import { DatabaseDetailContext } from '../../_context/state';

const WebsiteCard = ({ database, website, setWebsites, websites }) => {
  const [modal, showModal] = useState(false);
  const { patchDatabase } = useContext(DatabaseDetailContext);

  const disconnectWebsite = useCallback(() => {
    const _websites = websites.filter(wb => wb.id !== website.id);
    setWebsites(_websites);
    patchDatabase(
      'websites',
      _websites.map(wb => wb.id),
      database.id,
    );
    showModal(false);
    toast.success('Website desconectado!');
    // eslint-disable-next-line
  }, [websites, database]);

  return (
    <Card>
      {modal && (
        <RemoveModal
          action={disconnectWebsite}
          show={modal}
          setShow={() => showModal(false)}
        />
      )}
      <Header>
        <ApplicationIcon
          width="30px"
          height="30px"
          icon={website?.type?.slug}
        />
        <Name>
          <span
            style={{
              width: '90%',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
            }}
          >
            {website.domain || website.name}
          </span>
          <span
            style={{
              fontSize: `${10}px`,
              fontStyle: 'italic',
            }}
          >
            {website.cloud.display}
          </span>
        </Name>
      </Header>
      <Body>
        <Button
          outline
          error
          size="sm"
          style={{ width: `${145}px` }}
          onClick={() => showModal(true)}
        >
          Desconectar
        </Button>
        <Link to={`/websites/${website.id}`}>
          <Button size="sm" style={{ width: `${145}px` }}>
            Detalhes
          </Button>
        </Link>
      </Body>
    </Card>
  );
};

export default WebsiteCard;
