import styled from 'styled-components';

export const AppName = styled.div`
  margin-top: 40px;
  width: 100%;
  /* display: flex;
  flex-direction: column;
  align-items: center; */
`;

export const Origin = styled.div`
  width: 40%;
  margin-right: 16px;

  @media (max-width: 1000px) {
    width: 100%;
    margin: 20px 0;
  }
`;

export const Destination = styled.div`
  width: 40%;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const Section = styled.p`
  font-weight: 900;
  color: ${props => props.theme.brand_primary_color};
  margin-bottom: 26px;
`;

export const FreeTrialCloud = styled.div`
  width: 160px;
  background-color: ${props => props.theme.interface2};

  padding: 20px;

  margin-top: 40px;

  text-align: center;

  img {
    width: 45px;
    margin-bottom: 10px;
  }

  p {
    font-size: 13px;
    color: ${props => props.theme.interface5};
  }

  span {
    color: ${props => props.theme.interface5};
    font-size: 10px;
  }
`;
