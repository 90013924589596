import React, { useState, useContext } from 'react';
import { withTheme } from 'styled-components';
import { Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faPowerOff,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';

import {
  Modal,
  Input,
  Button,
  VerticalTabs,
} from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from 'components/ListingModal/styles';

import { useLayout } from 'hooks/layout';
import { faWindowMaximize } from '@fortawesome/free-regular-svg-icons';
import {
  Sync,
  TabTitle,
  TabContent,
} from 'pages/Migrations/MigrationDetail/Syncs/styles';
import { HttpsText } from './styles';
import { WebsiteDetailContext } from '../_context/state';

interface IOfflineModalProps {
  show: boolean;
  setShow: any;
}

const InfoModal: React.FC<IOfflineModalProps> = ({ show, setShow }) => {
  const [desabilitar, setDesabilitar] = useState('');
  const [loading, setLoading] = useState(false);
  const theme = useLayout();

  const { website } = useContext(WebsiteDetailContext);

  return (
    <Modal
      show={show}
      setShow={setShow}
      noExit={loading}
      style={{
        maxWidth: '100%',
      }}
    >
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faWindowMaximize} />
        </Icon>
        <Text>
          <Title>Website - Performance</Title>
          <Subtitle>Tire suas dúvidas sobre nossa aba de performance</Subtitle>
        </Text>
      </Header>

      <Content
        style={{
          maxHeight: 324,
          overflow: 'auto',
        }}
      >
        <VerticalTabs
          items={[
            {
              name: props => (
                <TabTitle {...props} first>
                  Usar CDN
                  <FontAwesomeIcon
                    icon={props.isOpen ? faChevronUp : faChevronDown}
                  />
                </TabTitle>
              ),
              content: props => (
                <TabContent {...props}>
                  O CDN, também conhecido como “redes de distribuição”, oferece
                  vários pontos de presença (PoP) fora do cloud de origem. Isso
                  permite que os websites gerenciem melhor o tráfego ao lidar
                  com as solicitações dos usuários com mais rapidez e
                  performance, proporcionando uma melhor experiência em sua
                  aplicação.
                </TabContent>
              ),
            },
            {
              name: props => (
                <TabTitle {...props} first>
                  SmartCache
                  <FontAwesomeIcon
                    icon={props.isOpen ? faChevronUp : faChevronDown}
                  />
                </TabTitle>
              ),
              content: props => (
                <TabContent {...props}>
                  Permite cachear páginas dinâmicas do website, aumentando a
                  performance e reduzindo o consumo de recursos da
                  infraestrutura do cloud e do banco de dados.
                </TabContent>
              ),
            },
            {
              name: props => (
                <TabTitle {...props} first>
                  Otimização de Imagem
                  <FontAwesomeIcon
                    icon={props.isOpen ? faChevronUp : faChevronDown}
                  />
                </TabTitle>
              ),
              content: props => (
                <TabContent {...props}>
                  <p>
                    As imagens otimizadas serão armazenadas em versão diferente
                    das imagens originais, então ao ativar a otimização de
                    imagens, as imagens serão otimizadas imediatamente.
                  </p>
                  <p>
                    O nível identifica quão menor a imagem deverá ficar após a
                    otimização. Com o nível configurado, imagens JPEG e GIF
                    podem ter sua qualidade alterada.
                  </p>
                  <br />
                  <p>Os níveis de otimizações são:</p>
                  <br />
                  <b>off</b> - Desativa a otimização das imagens.
                  <br />
                  <b>default</b> - Tenta otimizar as imagens sem alterar a
                  qualidade.
                  <br />
                  <b>low</b> - Imagens serão otimizadas com objetivo de
                  mantê-las próximas à qualidade original. A qualidade da imagem
                  pode ser diminuída levemente.
                  <br />
                  <b>medium</b> - Imagens serão otimizadas com objetivos de
                  obter uma imagem de tamanho menor, porém sem perder muita
                  qualidade. A qualidade de imagem pode ser diminuída.
                  <br />
                  <b>high</b> - Imagens serão otimizadas com objetivo de obter
                  uma imagem do menor tamanho possível. A qualidade de imagem
                  pode ser diminuída.
                </TabContent>
              ),
            },
            {
              name: props => (
                <TabTitle {...props} first>
                  Modo Desenvolvimento CDN
                  <FontAwesomeIcon
                    icon={props.isOpen ? faChevronUp : faChevronDown}
                  />
                </TabTitle>
              ),
              content: props => (
                <TabContent {...props}>
                  Quando esse modo é habilitado todos os caches da CDN são
                  desativados, ou seja, os caches de navegador continuarão
                  ativos. Isso pode ser útil para realizar eventuais testes na
                  infraestrutura do cloud de origem. Ativar esse modo irá
                  aumentar a banda utilizada e recursos do cloud, pois todas as
                  requisições serão redirecionadas diretamente para o cloud.
                </TabContent>
              ),
            },
            {
              name: props => (
                <TabTitle {...props} first>
                  WAF Level
                  <FontAwesomeIcon
                    icon={props.isOpen ? faChevronUp : faChevronDown}
                  />
                </TabTitle>
              ),
              content: props => (
                <TabContent {...props}>
                  WAF é a sigla para Web Application Firewall, um sistema que
                  fica entre o website e o resto da internet. Ele serve para
                  bloquear e proteger o cloud de ataques, como DDoS ou injeções
                  SQL.
                  <br />
                  <br />
                  Na opção WAF Level você possui 4 opções de nível de WAF:
                  <br />
                  <b>Off</b> - Desativado <br />
                  <b>Low</b> - Baixo
                  <br />
                  <b>Medium</b> - Médio
                  <br />
                  <b>High</b> - Alto
                </TabContent>
              ),
            },
            {
              name: props => (
                <TabTitle {...props} first>
                  Limpar Cache
                  <FontAwesomeIcon
                    icon={props.isOpen ? faChevronUp : faChevronDown}
                  />
                </TabTitle>
              ),
              content: props => (
                <TabContent {...props}>
                  Limpa os arquivos em cache de forma instantânea e força a
                  atualização nos servidores da GoCache. Quando é solicitada,
                  todas as requisições serão enviadas para o cloud de origem até
                  que os objetos sejam armazenados novamente na CDN.
                  <br />
                  <br />
                  OBS: Esta limpeza pode aumentar a carga em seu cloud de origem
                  e consequentemente provocar lentidão nos websites, enquanto os
                  objetos são repopulados no cache.
                  <br />
                  <br />
                  Tempo de execução: ~200ms
                </TabContent>
              ),
            },
            {
              name: props => (
                <TabTitle {...props} first>
                  Habilitar Módulo PageSpeed
                  <FontAwesomeIcon
                    icon={props.isOpen ? faChevronUp : faChevronDown}
                  />
                </TabTitle>
              ),
              content: props => (
                <TabContent {...props}>
                  Melhore o desempenho do websie e garanta mais resultados de
                  ranqueamento e SEO. <br />
                  <br />A utilização deste recurso avançado aumenta o consumo de
                  memória do seu cloud, mas melhora a performance de sua
                  aplicação.
                </TabContent>
              ),
            },
            {
              name: props => (
                <TabTitle {...props} first>
                  Nível de Cache de Arquivos Estáticos
                  <FontAwesomeIcon
                    icon={props.isOpen ? faChevronUp : faChevronDown}
                  />
                </TabTitle>
              ),
              content: props => (
                <TabContent {...props}>
                  O cache do navegador permite que seu website diga ao navegador
                  de um visitante para salvar arquivos estáticos no computador
                  local do visitante e carregar esses arquivos locais para
                  visitas subseqüentes, em vez de baixá-los do seu cloud em cada
                  carregamento de página.
                  <br />
                  <br />
                  Ative e ajuste o cache do navegador, o que lhe permite
                  acelerar o tempo de carregamento do seu site.
                  <br />
                  <br />
                  Você pode ajustar os períodos de tempo de arquivos estáticos,
                  de acordo com suas necessidades.
                  <br />
                  <br />
                  <b>Desligado</b> - Realizará requisições em seu cloud, mesmo
                  que o visitante já tenha acessado anteriormente;
                  <br />
                  <b>1 hora</b> - Os arquivos estáticos salvos no navegador do
                  visitante expirará em 1 hora;
                  <br />
                  <b>1 dia</b> - Os arquivos estáticos salvos no navegador do
                  visitante expirará em 1 dia;
                  <br />
                  <b>1 semana</b> - Os arquivos estáticos salvos no navegador do
                  visitante expirará em 1 semana;
                  <br />
                  <b>1 mês</b> - Os arquivos estáticos salvos no navegador do
                  visitante expirará em 1 mês;
                  <br />
                  <b>Até o fim dos tempos</b> - Os arquivos estáticos salvos no
                  navegador serão armazenados até o fim dos tempos, sendo
                  necessário o visitante “forçar” a limpeza deles.
                  <br />
                </TabContent>
              ),
            },
          ]}
        />
      </Content>
      <Footer>
        <Button
          onClick={() => {
            setShow();
          }}
        >
          Fechar
        </Button>
      </Footer>
    </Modal>
  );
};

export default InfoModal;
