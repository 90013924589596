import React from 'react';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Container } from './styles';

export function AlertMessage() {
  return (
    <Container>
      <FontAwesomeIcon icon={faExclamation} />
    </Container>
  );
}
