import React, { useRef, useEffect } from 'react';
import { transparentize } from 'polished';
import Chart from 'chart.js';
import { useLayout } from 'hooks/layout';

interface ScoreChartProps {
  score: any;
}

const ScoreChart: React.FC<ScoreChartProps> = ({ score }) => {
  const theme = useLayout();
  const chartRef = useRef(null);

  useEffect(() => {
    Chart.pluginService.register({
      beforeDraw(chart) {
        if (chart.config.options.elements.center) {
          const { ctx } = chart.chart;
          const centerConfig = chart.config.options.elements.center;
          const txt = centerConfig.text;
          ctx.font = 'bold 25px Montserrat';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillStyle = theme.interface6;
          const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
          const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
          ctx.fillText(txt, centerX, centerY);
        }
      },
      afterUpdate(chart) {
        const arc = chart.getDatasetMeta(0).data[0];
        arc.round = {
          x: (chart.chartArea.left + chart.chartArea.right) / 2,
          y: (chart.chartArea.top + chart.chartArea.bottom) / 2,
          radius: (chart.outerRadius + chart.innerRadius) / 2,
          thickness: (chart.outerRadius - chart.innerRadius) / 2,
          backgroundColor: arc._model.backgroundColor,
        };
      },
      afterDraw(chart) {
        const { ctx } = chart.chart;
        const arc = chart.getDatasetMeta(0).data[0];
        const startAngle = Math.PI / 2 - arc._view.startAngle;
        const endAngle = Math.PI / 2 - arc._view.endAngle;
        ctx.save();
        ctx.translate(arc.round.x, arc.round.y);
        ctx.fillStyle = arc.round.backgroundColor;
        ctx.beginPath();
        ctx.arc(
          arc.round.radius * Math.sin(startAngle),
          arc.round.radius * Math.cos(startAngle),
          arc.round.thickness,
          0,
          2 * Math.PI,
        );
        ctx.arc(
          arc.round.radius * Math.sin(endAngle),
          arc.round.radius * Math.cos(endAngle),
          arc.round.thickness,
          0,
          2 * Math.PI,
        );
        ctx.closePath();
        ctx.fill();
        ctx.restore();
      },
    });
  }, []);

  useEffect(() => {
    const chart = chartRef?.current?.getContext('2d');

    let color;

    if (score <= 50) {
      color = theme.errorNew;
    } else if (score > 50 && score <= 65) {
      color = theme.alertNew;
    } else if (score > 65 && score <= 80) {
      color = theme.favoriteNew;
    } else if (score > 80 && score <= 100) {
      color = theme.successNew;
    }

    let inverted;

    if (score <= 50) {
      inverted = transparentize(0.5, theme.errorNew);
    } else if (score > 50 && score <= 65) {
      inverted = transparentize(0.5, theme.alertNew);
    } else if (score > 65 && score <= 80) {
      inverted = transparentize(0.5, theme.favoriteNew);
    } else if (score > 80 && score <= 100) {
      inverted = transparentize(0.5, theme.successNew);
    }

    const _chart = new Chart(chart, {
      type: 'doughnut',
      data: {
        datasets: [
          {
            data: [score, 100 - score],
            backgroundColor: [color, inverted],
            borderWidth: [0, 0],
            hoverBackgroundColor: [color, inverted],
          },
        ],
      },
      options: {
        maintainAspectRatio: false,
        elements: {
          center: {
            text: score || '-',
          },
        },
        cutoutPercentage: 82,
        legend: {
          display: false,
        },
        hover: { mode: null },
        tooltips: {
          enabled: false,
        },
      },
    });

    return () => _chart.destroy();
  }, [chartRef, score]);

  return (
    <div>
      <canvas ref={chartRef} />
    </div>
  );
};

export default ScoreChart;
