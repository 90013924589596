import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;

  padding: 32px;

  .model {
    width: 325px;

    padding: 23px 0 33px 19px;

    margin-top: 26px;

    display: flex;
    flex-direction: column;

    background: ${props => props.theme.interface3};

    border-radius: 10px;
  }

  .downloadModel {
    color: ${props => props.theme.brand_primary_color};

    width: fit-content;

    cursor: pointer;

    span {
      font-weight: normal;
      font-size: 12px;
      margin-left: 5px;

      text-decoration-line: underline;
    }
  }

  span {
    font-weight: normal;
    font-size: 16px;
    line-height: 140%;

    color: ${props => props.theme.interface5};
  }
`;
