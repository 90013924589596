import React, { useContext, useState } from 'react';
import { Col, Row } from 'react-bootstrap';

import { Field, Label, Value } from 'components/Details';
import { Checkbox, Error, Input, Button } from '@cloudez/cloudez-design-system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { getValue } from 'utils/getValue';
import { toast } from 'react-toastify';
import api from 'services/api';
import { EmailDetailContext } from '../_context/state';
import { MailGateway } from './MailGateway';

interface ICommonProps {
  onChange: any;
  errors?: any;
}

const Common: React.FC<ICommonProps> = ({ onChange, errors }) => {
  const { email } = useContext(EmailDetailContext);

  const _logo = getValue(email, 'logo');
  const webmail = getValue(email, 'webmail');
  const aliases = getValue(email, 'aliases');

  const [logo, setLogo] = useState(_logo);

  const [newAlias, setNewAlias] = useState('');
  const [_aliases, setAliases] = useState(aliases.split(' '));

  const addAlias = () => {
    if (newAlias === email.domain) {
      toast.error('Você não pode adicionar o domínio como alias');
      setNewAlias('');
      return;
    }

    setAliases([..._aliases, newAlias]);
    onChange({
      target: {
        name: 'aliases',
        type: 'aliases',
        value: [..._aliases, newAlias].join(' '),
      },
    });
    setNewAlias('');
  };

  const changePrimaryColor = color => {
    onChange({
      target: {
        name: 'primary_color',
        type: 'primary_color',
        value: color,
      },
    });
  };

  const updateLogo = async () => {
    try {
      const payload = new FormData();
      payload.append('logo', logo);

      const { data } = await api.post(
        `/v3/email/${email.id}/update_logo/`,
        payload,
      );
      setLogo(data.logo);

      toast.success('Logo atualizada com sucesso.');
    } catch (e) {
      console.log(e);
    }
  };

  const deleteAlias = alias => {
    setAliases(_aliases.filter(a => a !== alias));

    onChange({
      target: {
        name: 'aliases',
        type: 'aliases',
        value: _aliases.filter(a => a !== alias).join(' '),
      },
    });
  };

  return (
    <Row>
      <Col xl="6">
        <Field>
          <Label>INSTALAR WEBMAIL</Label>
          <Row
            style={{
              marginTop: `${5}px`,
              marginBottom: `${5}px`,
            }}
          >
            <Col>
              <Checkbox
                name="webmail"
                checked={webmail === 'true'}
                onChange={onChange}
              />
            </Col>
          </Row>
        </Field>
        <Field>
          <Label>ALIAS DE DOMÍNIO</Label>
          <Row
            style={{
              marginTop: `${5}px`,
              marginBottom: `${5}px`,
            }}
          >
            <Col xs="9" sm="10">
              <Input
                block
                value={newAlias}
                onChange={e => setNewAlias(e.target.value)}
                error={!!errors?.values?.aliases}
              />
              {errors?.values?.aliases && (
                <Error>{errors?.values?.aliases[0]}</Error>
              )}
            </Col>
            <Col xs="3" sm="2">
              <Button
                style={{
                  width: '100%',
                }}
                icon
                onClick={addAlias}
              >
                <FontAwesomeIcon icon={faPlus} />
              </Button>
            </Col>
          </Row>
          {_aliases ? (
            _aliases.map(alias => {
              return (
                alias !== '' && (
                  <Value key={alias}>
                    {alias}{' '}
                    <FontAwesomeIcon
                      icon={faTimes}
                      onClick={() => deleteAlias(alias)}
                    />
                  </Value>
                )
              );
            })
          ) : (
            <Value>Este website ainda não possui alias</Value>
          )}
        </Field>
      </Col>
      <Col>
        <MailGateway cloudId={email.cloud} />
      </Col>
    </Row>
  );
};

export default Common;
