import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 25px;
  width: 100%;
  height: auto;
`;

interface ICloudCardProps {
  selected?: boolean;
}

export const CloudCard = styled.div<ICloudCardProps>`
  cursor: pointer;
  background: ${props => props.theme.interface1};
  width: 230px;
  height: 110px;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 15px 0px;
  border: 2px solid transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${props =>
    props.selected &&
    css`
      border: 2px solid
        ${props =>
          props.theme.darkMode
            ? props.theme.interface5
            : props.theme.brand_primary_color};
    `}

  &:hover {
    transition: all 0.2s ease-in;
    border: 2px solid
      ${props =>
        props.theme.darkMode
          ? props.theme.interface5
          : props.theme.brand_primary_color};
  }

  span {
    margin-top: 10px;
    font-size: 14px;
    color: ${props => props.theme.interface5};
  }

  p {
    font-size: 10px;
    color: ${props => props.theme.interface5};
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 10px;
`;

export const Logo = styled.div`
  padding: 0px 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Disk = styled.div`
  padding: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
`;

export const SelectedApplication = styled.div`
  display: flex;
  justify-content: center;

  position: relative;

  width: 700px;

  .badge {
    position: absolute;

    width: 150px;

    top: 0;
    left: calc(50% - 75px);
  }
`;

export const Application = styled.div`
  padding: 38px;

  width: 50%;

  border-radius: 6px 0 0 6px;

  background-color: rgba(232, 234, 239, 0.35);

  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  img {
    height: 52px;
  }

  .application-name {
    display: block;
    color: ${props => props.theme.interface5};
    font-size: 16px;
    font-weight: bold;
    margin-top: 15px;
  }

  .application-description {
    margin-top: 15px;
    font-size: 14px;
    color: ${props => props.theme.interface5};
  }
`;

export const Recommendation = styled.div`
  padding: 38px;
  width: 50%;

  background: ${props => props.theme.interface1};

  display: flex;
  flex-direction: column;
  justify-content: center;

  .title {
    font-size: 18px;
    font-weight: bold;
  }

  .description {
    font-size: 16px;
    margin: 15px 0;
  }
`;
