import React from 'react';

import {
  Field,
  Label,
  Radio,
  Select,
  TextArea,
} from '@cloudez/cloudez-design-system';

const SurveyRenderer: React.FC<{
  survey: any;
  payload: any;
  setPayload: any;
}> = ({ survey, payload, setPayload }) => {
  return survey?.items?.map(i => (
    <Field key={i.question.id}>
      <Label>{i.question.text}</Label>
      {(() => {
        switch (i.question.widget) {
          case 'select': {
            return (
              <Select
                name={i.question.text}
                required={i.is_required}
                block
                value={payload[i.question.id]}
                defaultValue=""
                onChange={e => {
                  setPayload({
                    ...payload,
                    [i.question.id]: e.target.value,
                  });
                }}
              >
                <option value="" disabled>
                  Selecione uma opção
                </option>
                {i.question.choices.map(c => (
                  <option key={c} value={c}>
                    {c}
                  </option>
                ))}
              </Select>
            );
          }
          case 'textarea': {
            return (
              <TextArea
                name={i.question.text}
                required={i.is_required}
                value={payload[i.question.id]}
                onChange={e => {
                  setPayload({
                    ...payload,
                    [i.question.id]: e.target.value,
                  });
                }}
                block
              />
            );
          }
          case 'radio': {
            return i.question.choices.map(c => (
              <>
                <Radio
                  value={c}
                  key={c}
                  onChange={e => {
                    setPayload({
                      ...payload,
                      [i.question.id]: e.target.value,
                    });
                  }}
                  checked={payload[i.question.id] === c}
                />
                <Label
                  style={{
                    marginRight: '10px',
                  }}
                >
                  {c}
                </Label>
              </>
            ));
          }
          default:
            return null;
        }
      })()}
    </Field>
  ));
};

export default SurveyRenderer;
