import React, {
  useState,
  useEffect,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react';
import { Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase, faSpinner } from '@fortawesome/free-solid-svg-icons';

import {
  Pagination,
  Modal,
  Button,
  createPagination,
  PaginatedResponse,
} from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Footer,
} from 'components/ListingModal/styles';

import { Tabs, Tab, TabContent } from 'components/Tabs';
import {
  createDatabaseService,
  getDatabasesService,
  updateDatabaseService,
} from 'services/database';
import { toast } from 'react-toastify';
import { getWebsitesService } from 'services/website';
import { ModalProps } from 'types';
import { Database } from 'types/database';
import Users from './Users';
import DatabaseTypes from './DatabaseTypes';
import DatabaseCard from './DatabaseCard';
import { WebsiteDetailContext } from '../../_context/state';

interface IConnectDatabaseModal extends ModalProps {
  setDatabases: Dispatch<SetStateAction<Database[]>>;
}

const ConnectDatabaseModal: React.FC<IConnectDatabaseModal> = ({
  show,
  setShow,
  setDatabases: _setDatabases,
}) => {
  const [databases, setDatabases] = useState<PaginatedResponse<Database>>(
    {} as PaginatedResponse<Database>,
  );
  const [selected, setSelected] = useState(null);
  const [tab, setTab] = useState(1);
  const [databaseNameError, setDatabaseNameError] = useState(null);
  const { website } = useContext(WebsiteDetailContext);

  const [newDatabase, setNewDatabase] = useState<Database>({
    cloud: website.cloud.id,
    values: [],
    websites: [website.id],
    type: null,
    users: [],
    admin: null,
  } as Database);
  const [loading, setLoading] = useState(false);

  const createDatabase = async () => {
    setLoading(true);
    try {
      const { data: db } = await createDatabaseService(newDatabase);
      _setDatabases(_databases => [..._databases, db]);
      toast.success('Database criado com sucesso');
      setLoading(false);
      setShow();
    } catch (e) {
      setLoading(false);
      if (e?.response?.data?.values) {
        setDatabaseNameError(e?.response?.data?.values?.database_name?.[0]);
      } else {
        toast.error(e?.response?.data[Object.keys(e?.response?.data)[0]][0]);
      }
    }
  };

  const getDatabases = async (page, search) => {
    try {
      const { data } = await getDatabasesService({
        page,
        search,
        cloud: website.cloud.id,
        page_size: 8,
      });

      const pagination = createPagination(data, 8);

      setDatabases({ data: data.results, ...pagination });
    } catch (e) {}
  };

  useEffect(() => {
    getDatabases(1, '');
    // eslint-disable-next-line
  }, []);

  const connectDatabase = async () => {
    setLoading(true);
    try {
      const {
        data: { results: dbs },
      } = await getWebsitesService({
        apps: selected.id,
        page_size: 5,
        filter: 'all',
        page: 1,
        database: selected.id,
      });

      const _dbs = dbs.map(d => d.id);

      const { data } = await updateDatabaseService(selected.id, {
        websites: [..._dbs, website.id],
      });

      _setDatabases(_databases => [..._databases, data]);
      toast.success('Database conectado com sucesso');

      setShow();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data[Object.keys(e?.response?.data)[0]][0]);
    }
  };

  return (
    <Modal show={show} setShow={setShow} noExit={loading}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faDatabase} />
        </Icon>
        <Text>
          <Title>Conectar database</Title>
        </Text>
      </Header>

      <Tabs>
        <Tab active={tab === 1} onClick={() => setTab(1)}>
          Criar
        </Tab>
        <Tab active={tab === 2} onClick={() => setTab(2)}>
          Escolher existente
        </Tab>
      </Tabs>

      <TabContent>
        {tab === 1 ? (
          <>
            <Row
              style={{
                marginLeft: 0,
                width: '100%',
              }}
            >
              <Col xl>
                <DatabaseTypes
                  databaseNameError={databaseNameError}
                  newDatabase={newDatabase}
                  setNewDatabase={setNewDatabase}
                />
              </Col>
            </Row>
            <Users newDatabase={newDatabase} setNewDatabase={setNewDatabase} />
          </>
        ) : (
          <>
            <Row
              style={{
                marginLeft: 0,
                width: '100%',
              }}
            >
              {databases?.data?.map(d => (
                <DatabaseCard
                  db={d}
                  key={d.id}
                  selected={selected?.id === d.id}
                  onClick={() => setSelected(d)}
                />
              ))}
            </Row>
            <Row
              style={{
                marginLeft: 0,
                width: '100%',
              }}
            >
              <Pagination
                style={{
                  width: '100%',
                  margin: '0 auto',
                }}
                onChangePage={getDatabases}
                count={databases?.count}
                previous={databases?.previous}
                next={databases?.next}
                current={databases?.current}
                pageSize={8}
              />
            </Row>
          </>
        )}
      </TabContent>
      <Footer hasLink>
        <Button
          onClick={tab === 1 ? createDatabase : connectDatabase}
          disabled={loading}
        >
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Conectar'}
        </Button>
      </Footer>
    </Modal>
  );
};

export default ConnectDatabaseModal;
