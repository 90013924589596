import React from 'react';
import { Button } from '@cloudez/cloudez-design-system';

export const IsCyberWeek = () => {
  return (
    <>
      <h1
        style={{
          fontWeight: 'bold',
          fontSize: '16px',
          lineHeight: '24px',
          textAlign: 'center',
          color: '#000',
          margin: '20px 0',
        }}
      >
        Aproveite a Cyber Week da Cloudez e ganhe créditos!
      </h1>

      <h3
        style={{
          fontWeight: 300,
          fontSize: '14px',
          lineHeight: '18px',
          textAlign: 'center',
          color: '#000',
          marginBottom: '20px',
        }}
      >
        Para cada domínio migrado para a Cloudez durante a Cyber Week, ganhe um
        desconto via crédito no seu painel para próximas faturas.
      </h3>

      <h1
        style={{
          fontWeight: 'bold',
          fontSize: '16px',
          lineHeight: '24px',
          textAlign: 'center',

          margin: '20px 0',
        }}
      >
        NÃO PERCA! Somente até dia 03/12.
      </h1>

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          marginBottom: 20,
        }}
      >
        <Button icon>
          <a
            rel="noreferrer"
            target="_blank"
            href="https://content.cloudez.io/cyberweek"
            style={{ textTransform: 'uppercase', color: '#fff' }}
          >
            {' '}
            Quero créditos
          </a>
        </Button>
      </div>

      <h3
        style={{
          fontWeight: 300,
          fontSize: '14px',
          lineHeight: '18px',
          textAlign: 'center',
          color: '#000',
          marginBottom: '20px',
        }}
      >
        Confira o{' '}
        <a
          rel="noreferrer"
          target="_blank"
          href="https://ac-landing-pages-user-uploads-production.s3.amazonaws.com/0000082666/8a0bbe13-3ca5-46e5-8220-765ae70ac982.pdf"
          style={{ textDecoration: 'underline', color: '#000' }}
        >
          regulamento
        </a>{' '}
        da promoção
      </h3>
    </>
  );
};

export default IsCyberWeek;
