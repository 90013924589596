import React, { useContext, useEffect, useState } from 'react';
import { ThemeContext, withTheme } from 'styled-components';
import { Button, Modal } from '@cloudez/cloudez-design-system';
import NodeappField from 'components/NodeappField';

import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPuzzlePiece } from '@fortawesome/free-solid-svg-icons';

import {
  Header,
  Icon,
  Text,
  Title,
  Content,
  Footer,
} from 'components/ListingModal/styles';
import {
  updateAddonService,
  getAddonTypeService,
  deleteAddonService,
} from 'services/cloud';

const EditAddonModal = ({ addon, show, setShow, setAddons, addons }) => {
  const [_addon, setAddon] = useState(addon);
  const [type, setType] = useState(null);
  const hasOptions = !!_addon?.values.length;

  const theme = useContext(ThemeContext);

  useEffect(() => {
    async function getType() {
      try {
        const { data } = await getAddonTypeService(_addon.type.id);
        setType(data);
      } catch (e) {}
    }

    if (_addon && show && hasOptions) {
      getType();
    }
    // eslint-disable-next-line
  }, [show]);

  const changeValue = e => {
    const values = _addon.values.map(_value => {
      if (_value.slug === e.target.name) {
        _value.value =
          e.target.type === 'checkbox'
            ? e.target.checked.toString()
            : e.target.value;
        return _value;
      }
      return _value;
    });

    setAddon({
      ..._addon,
      values,
    });
  };

  const createAddon = async () => {
    try {
      await updateAddonService(_addon.id, _addon);
      toast.success('Addon atualizado com sucesso.');
      setShow(false);
    } catch (e) {
      toast.error('Não foi possível adicionar o addon.');
    }
  };

  const deleteAddon = async () => {
    try {
      await deleteAddonService(_addon.id);

      const newAddons = addons.filter(a => _addon.id !== a.id);
      setAddons(newAddons);

      toast.success('Addon removido com sucesso.');
      setShow(false);
    } catch (e) {
      toast.error('Não foi possível remover o addon.');
    }
  };

  return (
    <Modal show={show} setShow={setShow}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faPuzzlePiece} />
        </Icon>
        <Text>
          <Title>Atualizar addon</Title>
        </Text>
      </Header>

      <Content>
        {hasOptions ? (
          type &&
          _addon?.values.map(v => {
            const value = type.values.filter(_v => v.slug === _v.slug)[0];

            return (
              <NodeappField
                key={v.slug}
                field={value}
                value={v.value || value.default}
                onChange={changeValue}
                nodeapp={false}
                block
              />
            );
          })
        ) : (
          <p
            style={{
              textAlign: 'center',
              color: theme.interface5,
              fontSize: '14px',
              margin: '20px 0',
            }}
          >
            Este addon não possui configurações adicionais
          </p>
        )}
      </Content>
      <Footer>
        <Button
          size={hasOptions && 'sm'}
          error
          style={{
            marginRight: hasOptions && '14px',
          }}
          outline
          icon
          onClick={deleteAddon}
        >
          Remover
        </Button>
        {hasOptions && (
          <Button size="sm" outline icon onClick={createAddon}>
            Atualizar
          </Button>
        )}
      </Footer>
    </Modal>
  );
};

export default withTheme(EditAddonModal);
