import React, { useContext, useEffect, useRef, useState } from 'react';

import { getCodeDeviceService, getCodeEmailService } from 'services/user';

import {
  Footer,
  Icon,
  Text,
  Title,
  Header,
} from 'components/ListingModal/styles';

import * as yup from 'yup';

import {
  Modal,
  Button,
  Form,
  FormInput,
  Error,
} from '@cloudez/cloudez-design-system';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { Content } from './styles';
import { SecurityCompanyContext } from '../_context/state';

interface ConfirmationModalProps {
  type: string;
  show: boolean;
  setShow: (value: boolean) => void;
  setShowAuth: (value: boolean) => void;
  setShowCodeEmail: (value: boolean) => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
  show,
  setShow,
  type,
  setShowAuth,
  setShowCodeEmail,
}) => {
  const [passwordError, setPasswordError] = useState('');
  const [loading, setLoading] = useState(false);

  const { setCode } = useContext(SecurityCompanyContext);

  const schema = yup.object().shape({
    password: yup.string().required('A senha é obrigatória.'),
  });

  const submitPassword = async data => {
    schema
      .validate(data, { abortEarly: false })
      .then(async result => {
        setLoading(true);
        const response =
          type === 'app'
            ? await getCodeDeviceService({
                password: result.password,
              })
            : await getCodeEmailService({
                password: result.password,
              });

        if (type === 'email') {
          setShowCodeEmail(true);
        } else {
          setCode(response.data);
          setShowAuth(true);
        }
        setLoading(false);
        setShow(false);
        setPasswordError('');
      })
      .catch(error => {
        setLoading(false);
        if (
          error.message &&
          error.response?.data.error !== 'Password required' &&
          error.response?.data.error !== 'Wrong password'
        ) {
          setPasswordError(error.message);
        } else {
          setPasswordError('Senha incorreta!');
        }
      });
  };

  return (
    <Modal width="497px" show={show} setShow={setShow}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faUserCircle} />
        </Icon>
        <Text>
          <Title>Insira sua senha para iniciar a configuração</Title>
        </Text>
      </Header>

      <Form id="confirmationForm" onSubmit={submitPassword}>
        <Content>
          <span>
            <b>Atenção!</b> Antes de iniciarmos a sua configuração, digite
            abaixo a sua senha do painel para confirmar que é você.
          </span>
          <div>
            <FormInput
              type="password"
              error={passwordError !== ''}
              name="password"
              placeholder="Digite aqui a sua senha"
            />
            {passwordError && <Error>{passwordError}</Error>}
          </div>
        </Content>
        <Footer hasLink>
          <Button type="button" onClick={() => setShow(false)} outline>
            Cancelar
          </Button>
          <Button disabled={loading} type="submit">
            {loading ? (
              <FontAwesomeIcon
                style={{ marginLeft: '0' }}
                icon={faSpinner}
                spin
              />
            ) : (
              'Verificar'
            )}
          </Button>
        </Footer>
      </Form>
    </Modal>
  );
};

export default ConfirmationModal;
