/* eslint-disable prettier/prettier */
import React, { useMemo, useCallback, useState, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';

import {
  Field,
  Label,
  Value,
  Separator,
  OfflineButton,
} from 'components/Details';
import { Input, Error } from '@cloudez/cloudez-design-system';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLock,
  faLockOpen,
  faPlus,
  faSpinner,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { ThemeContext } from 'styled-components';
import ListingModal from 'components/ListingModal';
import { shareResourceService, unshareResourceService } from 'services/common';

import { Shared, SharedUser, NoUsers } from 'components/Shared';
import UserModal from 'pages/Clouds/CloudDetailNew/Users/UserModal';
import { WebsiteDetailContext } from '../_context/state';

const Acessos: React.FC = () => {
  const { website, patchWebsite, updateLoading, updateField } =
    useContext(WebsiteDetailContext);

  const [modal, setModal] = useState(false);
  const theme = useContext(ThemeContext);

  const [loading, setLoading] = useState(false);

  const [shareEmail, setShareEmail] = useState('');
  const [httpsModal, setHttpsModal] = useState(false);
  const [errors, setErrors] = useState({} as any);

  const share = useCallback(async () => {
    setLoading(true);
    try {
      const response = await shareResourceService(
        'website',
        website.id,
        shareEmail,
      );

      await patchWebsite('shared_users', response.data.share, website.id);

      website.shared_users.push(shareEmail);
      setShareEmail('');
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setErrors(e?.response?.data);
    }
  }, [website, shareEmail, patchWebsite]);

  const unshare = useCallback(
    async user => {
      setLoading(true);
      try {
        const response = await unshareResourceService(
          'website',
          website.id,
          user,
        );

        await patchWebsite('shared_users', response.data.share, website.id);
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
      // eslint-disable-next-line
    },
    [website, website.user],
  );

  const { hasCertificate, isActive, canActivate } = useMemo(() => {
    const hasCertificate = !!website.certificate;
    const isActive = website.certificate && website.certificate.is_active;
    const canActivate = website.is_hosted;

    return {
      hasCertificate,
      isActive,
      canActivate,
    };
  }, [website]);

  const pendingActivate = useMemo(() => {
    let pendingActivate;

    if (
      website.certificate &&
      website.certificate.status === 2 &&
      website.certificate.is_active
    ) {
      pendingActivate = false;
    } else {
      pendingActivate = true;
    }

    return pendingActivate;
  }, [website]);

  return (
    <Row>
      {httpsModal && (
        <ListingModal
          show={website && httpsModal}
          setShow={() => setHttpsModal(false)}
          type={
            !hasCertificate && canActivate
              ? 'https-create'
              : hasCertificate && pendingActivate
              ? 'https-loading'
              : hasCertificate && !pendingActivate && isActive
              ? 'https-remove'
              : 'no-https'
          }
          data={website}
          action={(field, value, id) => patchWebsite(field, value, id)}
        />
      )}
      {modal && (
        <UserModal
          setShow={() => setModal(false)}
          show={modal}
          user={website.user}
          updateField={updateField}
        />
      )}
      <Col xl>
        <Field>
          <Label>ENDEREÇO TEMPORÁRIO</Label>
          <Value>
            <a
              href={`http://${website.temporary_address}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {website.temporary_address}
            </a>
          </Value>
        </Field>
        {website.domain && website.type.slug === 'wordpress' && (
          <Field>
            <Label>PAINEL ADMINISTRATIVO DO WORDPRESS</Label>
            <Value>
              <a
                href={`http://${website.domain}/wp-admin`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {`${website.domain}/wp-admin`}
              </a>
            </Value>
          </Field>
        )}
        <Field>
          <Label>ENDEREÇO SFTP</Label>
          <Value>
            <a
              href={`/clouds/${website.cloud.id}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {website.cloud.fqdn}
            </a>
          </Value>
        </Field>
        <Field>
          <Label>ACESSO SSH</Label>
          <Value>{`${website.user.username}@${website.cloud.fqdn}`}</Value>
        </Field>
        <Row>
          <Col>
            <Field>
              <Label>USUÁRIO SFTP/SSH</Label>
              <Value>{website.user.username}</Value>
            </Field>
          </Col>
          <Col>
            <Field>
              <Label>SENHA SFTP/SSH</Label>
              <Value
                link
                onClick={() => {
                  setModal(true);
                }}
              >
                Alterar senha
              </Value>
            </Field>
          </Col>
        </Row>
        <Field>
          <Label>SERVIDOR</Label>
          <Value>{website.cloud.fqdn}</Value>
        </Field>
        <Field>
          <Label>HTTPS</Label>
          <OfflineButton
            style={{
              position: 'relative',
            }}
            onClick={e => setHttpsModal(!modal)}
          >
            <FontAwesomeIcon
              icon={website.certificate ? faLock : faLockOpen}
              style={{
                color: theme.interface3,
              }}
            />
            {hasCertificate && pendingActivate && (
              <div
                style={{
                  position: 'absolute',
                  left: '16px',
                  top: '14px',
                }}
              >
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin
                  size="xs"
                  style={{
                    color: theme.brand_primary_color,
                    fontWeight: 'bold',
                  }}
                />
              </div>
            )}
          </OfflineButton>
        </Field>
      </Col>
      <Separator />
      <Col xl>
        <Field>
          <Label>COMPARTILHAMENTO</Label>
          <Row
            style={{
              marginTop: `${5}px`,
              marginBottom: `${5}px`,
            }}
          >
            <Col xs="9" sm="10">
              <Input
                block
                value={shareEmail}
                onChange={e => setShareEmail(e.target.value)}
                error={!!errors.email}
              />
              {errors.email && <Error>{errors.email[0]}</Error>}
            </Col>
            <Col xs="3" sm="2">
              <OfflineButton
                disabled={updateLoading || loading || shareEmail === ''}
                onClick={share}
              >
                {updateLoading || loading ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  <FontAwesomeIcon icon={faPlus} />
                )}
              </OfflineButton>
            </Col>
          </Row>
          <Shared>
            {website.shared_users.length ? (
              website.shared_users.map(user => (
                <SharedUser key={user}>
                  {user} {'  '}
                  <FontAwesomeIcon
                    icon={faTimes}
                    onClick={() => unshare(user)}
                  />
                </SharedUser>
              ))
            ) : (
              <NoUsers>Este website ainda não foi compartilhado</NoUsers>
            )}
          </Shared>
        </Field>
      </Col>
    </Row>
  );
};

export default Acessos;
