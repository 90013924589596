import React, { useState } from 'react';

import { toast } from 'react-toastify';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import {
  faEye,
  faEyeSlash,
  faKey,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button, Modal } from '@cloudez/cloudez-design-system';

import { CustomInput } from 'components/NewComponents';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from 'components/ListingModal/styles';

import { updateUserPasswordService } from 'services/user';
import generatePassword from 'utils/generatePassword';

interface IPasswordModalProps {
  setShow?(value: boolean): void;
  show?: boolean;
}

const PasswordModal = ({ show, setShow }: IPasswordModalProps): JSX.Element => {
  const [loading, setLoading] = useState(false);

  const changePasswordSchema = yup.object().shape({
    old_password: yup.string().required('Este campo é obrigatório'),
    new_password: yup.string().required('Este campo é obrigatório'),
    confirm_password: yup
      .string()
      .oneOf([yup.ref('new_password'), null], 'As senhas não são idênticas')
      .required('Este campo é obrigatório'),
  });

  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(changePasswordSchema),
  });

  const onSubmit = async data => {
    try {
      setLoading(true);

      delete data.confirm_password;

      await updateUserPasswordService(data);
      toast.success('Senha atualizada');
      setLoading(false);
      setShow(false);
    } catch (e) {
      setLoading(false);
      e.response?.data.old_password &&
        setError('old_password', {
          type: 'manual',
          message: 'Senha incorreta',
        });
    }
  };

  const [showPw, setShowPw] = useState(false);

  return (
    <Modal show={show} setShow={setShow} noExit={loading}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faKey} />
        </Icon>
        <Text>
          <Title>Alterar senha</Title>
          <Subtitle>Deseja alterar sua senha?</Subtitle>
        </Text>
      </Header>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Content>
          <CustomInput
            mb="20px"
            label="Senha antiga"
            block
            register={register}
            name="old_password"
            type="password"
            error={errors?.old_password?.message}
          />
          <CustomInput
            mb="20px"
            label="Nova senha"
            type={showPw ? 'text' : 'password'}
            block
            icon={[
              {
                svg: faKey,
                isRight: true,
                action: () => {
                  const pass = generatePassword();

                  setValue('new_password', pass);
                },
              },
              {
                svg: !showPw ? faEye : faEyeSlash,
                action: () => setShowPw(!showPw),
                isRight: true,
              },
            ]}
            name="new_password"
            register={register}
            error={errors?.new_password?.message}
          />
          <CustomInput
            label="Confirmar nova senha"
            block
            name="confirm_password"
            type="password"
            register={register}
            error={errors?.confirm_password?.message}
          />
        </Content>
        <Footer>
          <Button
            icon
            style={{
              marginLeft: 'auto',
            }}
            disabled={loading}
          >
            {loading ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              'Alterar senha'
            )}
          </Button>
        </Footer>
      </form>
    </Modal>
  );
};

export default PasswordModal;
