import React, { useState } from 'react';
import { withTheme } from 'styled-components';
import { Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReply } from '@fortawesome/free-solid-svg-icons';

import {
  Modal,
  Input,
  Field,
  Label,
  TextArea,
  Button,
} from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from 'components/ListingModal/styles';

const AutoReplyModal = ({
  show,
  setShow,
  account,
  autoReply,
  deleteAction,
  createAction,
  setAutoReply,
}) => {
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);

  return (
    <Modal show={show} setShow={setShow} noExit={loading}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faReply} />
        </Icon>
        <Text>
          <Title>Criar uma auto resposta</Title>
          <Subtitle>
            Resposta automática a ser enviada para emails que receber pelo
            período selecionado.
          </Subtitle>
        </Text>
      </Header>

      <Content>
        <Field>
          <Label>MENSANGEM DA AUTO RESPOSTA</Label>
          <TextArea
            block
            value={autoReply && autoReply[0]?.message}
            onChange={e =>
              setAutoReply({
                ...autoReply,
                message: e.target.value,
              })
            }
            disabled={autoReply && !!autoReply[0]}
            background={autoReply && !!autoReply[0]}
          />
        </Field>
        <Row>
          <Col>
            <Field>
              <Label>COMEÇA:</Label>
              <Input
                type="date"
                onChange={e =>
                  setAutoReply({
                    ...autoReply,
                    start: e.target.value,
                  })
                }
                block
                value={autoReply && autoReply[0]?.start}
                disabled={autoReply && !!autoReply[0]}
                background={autoReply && !!autoReply[0]}
              />
            </Field>
          </Col>
          <Col>
            <Field>
              <Label>TERMINA:</Label>
              <Input
                type="date"
                block
                value={autoReply && autoReply[0]?.end}
                disabled={autoReply && !!autoReply[0]}
                background={autoReply && !!autoReply[0]}
                onChange={e =>
                  setAutoReply({
                    ...autoReply,
                    end: e.target.value,
                  })
                }
              />
            </Field>
          </Col>
        </Row>
      </Content>
      <Footer>
        {autoReply && autoReply[0] ? (
          <Button
            error
            outline
            disabled={loading}
            onClick={() => {
              setLoading(true);
              deleteAction(autoReply[0].id);
              setShow();
              setLoading(false);
            }}
          >
            Deletar
          </Button>
        ) : (
          <Button
            disabled={loading}
            onClick={() => {
              setLoading(true);
              createAction(account);
              setShow();
              setLoading(false);
            }}
          >
            Criar
          </Button>
        )}
      </Footer>
    </Modal>
  );
};

export default AutoReplyModal;
