import api from 'services/api';

export const getDomainsService = async (
  params,
  ctrl = new AbortController(),
) => {
  try {
    return await api.get('/v3/domain/', { params, signal: ctrl.signal });
  } catch (e) {
    throw e;
  }
};

export const getDomainService = async (id, params?) => {
  try {
    return await api.get(`/v3/domain/${id}/`, { params });
  } catch (e) {
    throw e;
  }
};

export const updateDomainService = async (id, data) => {
  try {
    return await api.patch(`/v3/domain/${id}/`, data);
  } catch (e) {
    throw e;
  }
};

export const deleteDomainService = async id => {
  try {
    return await api.delete(`/v3/domain/${id}/`);
  } catch (e) {
    throw e;
  }
};

export const createDomainService = async data => {
  try {
    return await api.post(`/v3/domain/`, data);
  } catch (e) {
    throw e;
  }
};

export const refreshDomainService = async id => {
  try {
    return await api.post(`/v3/domain/${id}/refresh/`);
  } catch (e) {
    throw e;
  }
};

export const getAvailableDomainsService = async data => {
  try {
    return await api.post('/v3/domain-available/', data);
  } catch (e) {
    throw e;
  }
};

export const getDomainRecordsService = async params => {
  try {
    return await api.get(`/v3/domain-record/`, {
      params,
    });
  } catch (e) {
    throw e;
  }
};

export const createDomainRecordService = async payload => {
  try {
    return await api.post('/v3/domain-record/', payload);
  } catch (e) {
    throw e;
  }
};

export const updateDomainRecordService = async (id, data) => {
  try {
    return await api.patch(`/v3/domain-record/${id}/`, data);
  } catch (e) {
    throw e;
  }
};

export const deleteDomainRecordService = async id => {
  try {
    return await api.delete(`/v3/domain-record/${id}/`);
  } catch (e) {
    throw e;
  }
};
