import React, { createContext, useContext, useState } from 'react';

const MigCreateAssistedContext = createContext({} as any);

export function MigCreatedAssistedProvider({ children }) {
  const [cacheData, setCacheData] = useState({});

  return (
    <MigCreateAssistedContext.Provider
      value={{
        cacheData,
        setCacheData,
      }}
    >
      {children}
    </MigCreateAssistedContext.Provider>
  );
}

export const useMigCreateAssisted = () => useContext(MigCreateAssistedContext);
