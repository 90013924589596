import { Card } from 'components/Listing/styles';
import styled, { css } from 'styled-components';

export const Fields = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const Dropdown = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    font-size: 15px;
    color: ${props => props.theme.brand_primary_color};
  }
`;

interface NewCardProps {
  opened?: boolean;
}

export const NewCard = styled(Card)<NewCardProps>`
  transition: height 200ms ease;
  font-weight: bold;

  p {
    display: flex;
    align-items: center;
    height: 100%;
  }

  ${props =>
    props.opened &&
    css`
      height: 480px;
    `}

  button {
    svg {
      font-size: 14px;
      margin-right: 3px;
    }
  }
`;

export const Value = styled.div`
  display: flex;
  align-items: center;
`;
interface StatusPoprs {
  active?: boolean;
  standby?: boolean;
}

export const Status = styled.div<StatusPoprs>`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: ${props =>
    props.standby
      ? props.theme.alertNew
      : props.active
      ? props.theme.successNew
      : props.theme.errorNew};
  margin-right: 8px;
`;

export const Label = styled.p`
  font-weight: normal;
  font-size: 14px;
  color: ${props => props.theme.interface6};

  margin-right: 2px;

  svg {
    font-size: 18px;
    color: ${props => props.theme.interface4};
  }
`;

interface ScoreProps {
  score?: number | string;
}

export const Score = styled.span<ScoreProps>`
  margin-left: 3px;
  font-size: 14px;
  font-weight: bold;
  color: ${props => {
    const { score } = props;
    if (score <= 50) {
      return props.theme.errorNew;
    }
    if (score > 50 && score <= 65) {
      return props.theme.alertNew;
    }
    if (score > 65 && score <= 80) {
      return props.theme.favoriteNew;
    }
    if (score > 80 && score <= 100) {
      return props.theme.successNew;
    }
  }} !important;
`;
