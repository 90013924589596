import styled from 'styled-components';

export const Existent = styled.a`
  color: ${props => props.theme.brand_primary_color};
  font-size: 10px;
  text-transform: uppercase;
  cursor: pointer;

  position: absolute;
  right: 20px;
  top: 0;
`;

interface IUserCardProps {
  selected?: boolean;
}

export const UserCard = styled.div<IUserCardProps>`
  cursor: pointer;
  width: 100%;
  height: 50px;
  background: ${props => props.theme.interface2};
  padding: 13px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  border: 2px solid
    ${props =>
      props.selected
        ? props.theme.darkMode
          ? props.theme.interface5
          : props.theme.brand_primary_color
        : 'transparent'};
  border-radius: 5px;

  svg {
    color: ${props => props.theme.brand_primary_color};
    font-size: 24px;
  }

  &:hover {
    border: 2px solid ${props => props.theme.brand_primary_color};
  }
`;

export const Username = styled.span`
  margin-left: 8px;
  font-size: 12px;
  color: ${props => props.theme.interface5};
`;

export const Host = styled.div`
  font-size: 12px;
  font-weight: bold;
  color: ${props => props.theme.interface5};
  margin-right: 12px;

  span {
    display: block;
    font-weight: normal;
  }
`;

export const UserDetail = styled.div`
  padding: 0px 15px;
  width: 100%;
  height: 100%;

  @media (min-width: 992px) {
    border-left: 1px solid ${props => props.theme.interface4};
  }

  @media (max-width: 992px) {
    border-top: 1px solid ${props => props.theme.interface4};

    padding-top: 14px;
    margin-top: 8px;
  }
`;

export const AddUserButton = styled.div`
  height: 30px;
  width: 30px;
  background-color: ${props => props.theme.brand_secondary_color};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: width 0.3s ease-in-out;

  svg {
    font-size: 20px;
    margin-right: -100px;
    transition: all 0.05s ease-out;
  }

  &:hover {
    width: auto;
    padding: 0px 8px;
    transition: all 0.3s ease-out;

    svg {
      margin-right: 0;
      transition: all 0.3s ease-out;
      transform: rotateZ(-180deg);
    }

    span {
      transition: opacity 0.3s ease-in-out;
      transition-delay: 0.1s;
      opacity: 1;
    }
  }

  span {
    opacity: 0;
    margin-left: 8px;
    font-size: 12px;
    color: ${props => props.theme.brand_primary_color};
  }
`;
