import React, { useRef, useState, useEffect, useContext } from 'react';
import { Col, Row } from 'react-bootstrap';

import { darken, transparentize } from 'polished';

import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-regular-svg-icons';

import Chart from 'chart.js';

import blockedUsers from 'assets/img/svg/blocked_users.svg';
import overdueInvoice from 'assets/img/svg/overdue_invoice.svg';
import latePayment from 'assets/img/svg/late_payment.svg';
import pendingPayment from 'assets/img/svg/pending_payment.svg';
import paymentDone from 'assets/img/svg/payment_done.svg';
import cloudBlocked from 'assets/img/svg/cloud_blocked.svg';
import cloudOff from 'assets/img/svg/cloud_off.svg';
import cloudDeleted from 'assets/img/svg/cloud_deleted.svg';

import { Hover, HoverText } from 'components/Hover';
import { Button } from '@cloudez/cloudez-design-system';

import history from 'services/history';
import { withTheme } from 'styled-components';
import {
  faStar as fasStar,
  faBan,
  faMoneyBill,
  faSpinner,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import {
  Card,
  CardHeader,
  CardContent,
  Icons,
  CloudPerf,
  CloudActions,
  Badge,
  Pulsate,
} from './styles';

import { CloudListingContext } from '../_context/state';

const CloudCard = ({ cloud, theme }) => {
  const { updateCloud } = useContext(CloudListingContext);
  const memRef = useRef<HTMLCanvasElement>();
  const cpuRef = useRef<HTMLCanvasElement>();
  const diskRef = useRef<HTMLCanvasElement>();
  const [loading, setLoading] = useState(false);

  // eslint-disable-next-line
  const [cloudInfo, _] = useState(
    cloud.status_timeline[cloud.status_timeline.length - 1],
  );

  useEffect(() => {
    if (cloudInfo) {
      const memChartRef = memRef?.current.getContext('2d');
      const cpuChartRef = cpuRef?.current.getContext('2d');
      const diskChartRef = diskRef?.current.getContext('2d');

      const dates = cloud.status_timeline.map(s => {
        const d = moment(s.datetime);
        return d.format('HH:MM:ss');
      });

      const memData = cloud.status_timeline.map((s, i) => ({
        x: (100 / cloud.status_timeline.length) * i,
        y: Number(s.mem),
      }));

      const cpuData = cloud.status_timeline.map((s, i) => ({
        x: (100 / cloud.status_timeline.length) * i,
        y: Number(s.cpu),
      }));

      const gradientFillMem = memChartRef.createLinearGradient(0, 0, 0, 125);

      Chart.pluginService.register({
        beforeDraw(chart) {
          if (chart.config.options.elements.center) {
            const { ctx } = chart.chart;
            const centerConfig = chart.config.options.elements.center;
            const txt = centerConfig.text;
            ctx.font = '30px Lato';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillStyle = theme.interface4;
            const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
            const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;

            ctx.fillText(txt, centerX, centerY);
          }
        },
      });

      gradientFillMem.addColorStop(
        0,
        memData[memData.length - 1] && memData[memData.length - 1].y <= 50
          ? theme.success
          : memData[memData.length - 1] &&
            memData[memData.length - 1].y > 50 &&
            memData[memData.length - 1] &&
            memData[memData.length - 1].y <= 65
          ? theme.favorite
          : memData[memData.length - 1] &&
            memData[memData.length - 1].y > 65 &&
            memData[memData.length - 1] &&
            memData[memData.length - 1].y <= 80
          ? theme.alert
          : memData[memData.length - 1] && memData[memData.length - 1].y > 80
          ? theme.error
          : '#fff',
      );

      gradientFillMem.addColorStop(
        0.5,
        memData[memData.length - 1] && memData[memData.length - 1].y <= 50
          ? transparentize(0.3, theme.success)
          : memData[memData.length - 1] &&
            memData[memData.length - 1].y > 50 &&
            memData[memData.length - 1] &&
            memData[memData.length - 1].y <= 65
          ? transparentize(0.3, theme.favorite)
          : memData[memData.length - 1] &&
            memData[memData.length - 1].y > 65 &&
            memData[memData.length - 1] &&
            memData[memData.length - 1].y <= 80
          ? transparentize(0.3, theme.alert)
          : memData[memData.length - 1] && memData[memData.length - 1].y > 80
          ? transparentize(0.3, theme.error)
          : '#fff',
      );

      gradientFillMem.addColorStop(
        1,
        memData[memData.length - 1] && memData[memData.length - 1].y <= 50
          ? transparentize(0.9, theme.success)
          : memData[memData.length - 1] &&
            memData[memData.length - 1].y > 50 &&
            memData[memData.length - 1] &&
            memData[memData.length - 1].y <= 65
          ? transparentize(0.9, theme.favorite)
          : memData[memData.length - 1] &&
            memData[memData.length - 1].y > 65 &&
            memData[memData.length - 1] &&
            memData[memData.length - 1].y <= 80
          ? transparentize(0.9, theme.alert)
          : memData[memData.length - 1] && memData[memData.length - 1].y > 80
          ? transparentize(0.9, theme.error)
          : '#fff',
      );

      const gradientFillCpu = cpuChartRef.createLinearGradient(0, 0, 0, 125);

      gradientFillCpu.addColorStop(
        0,
        cpuData[cpuData.length - 1] && cpuData[cpuData.length - 1].y <= 50
          ? theme.success
          : cpuData[cpuData.length - 1] &&
            cpuData[cpuData.length - 1].y > 50 &&
            cpuData[cpuData.length - 1] &&
            cpuData[cpuData.length - 1].y <= 65
          ? theme.favorite
          : cpuData[cpuData.length - 1] &&
            cpuData[cpuData.length - 1].y > 65 &&
            cpuData[cpuData.length - 1] &&
            cpuData[cpuData.length - 1].y <= 80
          ? theme.alert
          : cpuData[cpuData.length - 1] && cpuData[cpuData.length - 1].y > 80
          ? theme.error
          : '#fff',
      );

      gradientFillCpu.addColorStop(
        0.5,
        cpuData[cpuData.length - 1] && cpuData[cpuData.length - 1].y <= 50
          ? transparentize(0.3, theme.success)
          : cpuData[cpuData.length - 1] &&
            cpuData[cpuData.length - 1].y > 50 &&
            cpuData[cpuData.length - 1] &&
            cpuData[cpuData.length - 1].y <= 65
          ? transparentize(0.3, theme.favorite)
          : cpuData[cpuData.length - 1] &&
            cpuData[cpuData.length - 1].y > 65 &&
            cpuData[cpuData.length - 1] &&
            cpuData[cpuData.length - 1].y <= 80
          ? transparentize(0.3, theme.alert)
          : cpuData[cpuData.length - 1] && cpuData[cpuData.length - 1].y > 80
          ? transparentize(0.3, theme.error)
          : '#fff',
      );

      gradientFillCpu.addColorStop(
        1,
        cpuData[cpuData.length - 1] && cpuData[cpuData.length - 1].y <= 50
          ? transparentize(0.9, theme.success)
          : cpuData[cpuData.length - 1] &&
            cpuData[cpuData.length - 1].y > 50 &&
            cpuData[cpuData.length - 1] &&
            cpuData[cpuData.length - 1].y <= 65
          ? transparentize(0.9, theme.favorite)
          : cpuData[cpuData.length - 1] &&
            cpuData[cpuData.length - 1].y > 65 &&
            cpuData[cpuData.length - 1] &&
            cpuData[cpuData.length - 1].y <= 80
          ? transparentize(0.9, theme.alert)
          : cpuData[cpuData.length - 1] && cpuData[cpuData.length - 1].y > 80
          ? transparentize(0.9, theme.error)
          : '#fff',
      );

      new Chart(cpuChartRef, {
        type: 'line',
        data: {
          labels: dates,
          datasets: [
            {
              label: 'CPU',
              data: cpuData,
              fill: 'origin',
              backgroundColor: gradientFillCpu,
              borderColor:
                cpuData[cpuData.length - 1] &&
                cpuData[cpuData.length - 1].y <= 50
                  ? darken(0.2, theme.success)
                  : cpuData[cpuData.length - 1] &&
                    cpuData[cpuData.length - 1].y > 50 &&
                    cpuData[cpuData.length - 1] &&
                    cpuData[cpuData.length - 1].y <= 65
                  ? darken(0.2, theme.favorite)
                  : cpuData[cpuData.length - 1] &&
                    cpuData[cpuData.length - 1].y > 65 &&
                    cpuData[cpuData.length - 1] &&
                    cpuData[cpuData.length - 1].y <= 80
                  ? darken(0.2, theme.alert)
                  : cpuData[cpuData.length - 1] &&
                    cpuData[cpuData.length - 1].y > 80 &&
                    cpuData[cpuData.length - 1] &&
                    cpuData[cpuData.length - 1].y <= 100
                  ? darken(0.2, theme.error)
                  : null,
              borderWidth: 2.5,
              lineTension: 0.3,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                display: false,
              },
            ],
            yAxes: [
              {
                display: false,
                ticks: {
                  min: 0,
                  max: 100,
                  stepSize: 10,
                },
              },
            ],
          },
          elements: {
            point: {
              radius: 0,
            },
          },
          legend: {
            display: false,
          },
        },
      });

      new Chart(memChartRef, {
        type: 'line',
        data: {
          labels: dates,
          datasets: [
            {
              label: 'Memória',
              data: memData,
              backgroundColor: gradientFillMem,
              borderColor:
                memData[memData.length - 1] &&
                memData[memData.length - 1].y <= 50
                  ? darken(0.2, theme.success)
                  : memData[memData.length - 1] &&
                    memData[memData.length - 1].y > 50 &&
                    memData[memData.length - 1] &&
                    memData[memData.length - 1].y <= 80
                  ? darken(0.2, theme.favorite)
                  : memData[memData.length - 1] &&
                    memData[memData.length - 1].y > 65 &&
                    memData[memData.length - 1] &&
                    memData[memData.length - 1].y <= 80
                  ? darken(0.2, theme.alert)
                  : memData[memData.length - 1] &&
                    memData[memData.length - 1].y > 80 &&
                    memData[memData.length - 1] &&
                    memData[memData.length - 1].y <= 100
                  ? darken(0.2, theme.error)
                  : null,
              borderWidth: 2,
              lineTension: 0.3,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                display: false,
              },
            ],
            yAxes: [
              {
                display: false,
                ticks: {
                  min: 0,
                  max: 100,
                  stepSize: 10,
                },
              },
            ],
          },
          elements: {
            point: {
              radius: 0,
            },
          },
          legend: {
            display: false,
          },
        },
      });

      new Chart(diskChartRef, {
        type: 'horizontalBar',
        data: {
          labels: ['Disco', 'Livre'],
          datasets: [
            {
              label: 'Disco',
              data: [cloudInfo && cloudInfo.disk > 100 ? 100 : cloudInfo.disk],
              backgroundColor: [
                cloudInfo && cloudInfo.disk <= 50
                  ? theme.success
                  : cloudInfo && cloudInfo.disk > 50 && cloudInfo.disk <= 65
                  ? theme.favorite
                  : cloudInfo && cloudInfo.disk > 65 && cloudInfo.disk <= 80
                  ? theme.alert
                  : cloudInfo && cloudInfo.disk > 80
                  ? theme.error
                  : null,
                theme.interface3,
              ],
              borderWidth: [0, 0],
            },
            {
              label: 'Livre',
              data: [
                cloudInfo && cloudInfo.disk > 100 ? 0 : 100 - cloudInfo.disk,
              ],
              backgroundColor: theme.interface2,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: true,
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                display: false, // hides the horizontal scale
                stacked: true, // stacks the bars on the x axis
              },
            ],
            yAxes: [
              {
                display: false, // hides the vertical scale
                stacked: true, // stacks the bars on the y axis
              },
            ],
          },
        },
      });
    }

    // eslint-disable-next-line
  }, [cloud, cloudInfo]);

  const favorite = async () => {
    setLoading(true);
    await updateCloud('is_pinned', !cloud.is_pinned, cloud.id);
    setLoading(false);
  };

  return (
    <Card
      blocked={cloud.hard_block || cloud.soft_block}
      spammer={cloud.has_spammer}
    >
      {(cloud.hard_block || cloud.soft_block || cloud.has_spammer) && (
        <Badge
          blocked={cloud.hard_block || cloud.soft_block}
          spammer={cloud.has_spammer}
        >
          <FontAwesomeIcon
            icon={
              cloud.hard_block || cloud.soft_block
                ? faMoneyBill
                : cloud.has_spammer
                ? faBan
                : null
            }
          />

          {cloud.hard_block || cloud.soft_block
            ? 'Bloqueado'
            : cloud.has_spammer
            ? 'Spam'
            : null}
        </Badge>
      )}
      <CardHeader>
        <span>{cloud.nickname || cloud.name}</span>
        <div style={{ display: 'flex' }}>
          {(() => {
            switch (cloud.status_listing) {
              case 'p':
                return (
                  <Hover>
                    <Icons style={{ margin: '0 10px' }}>
                      <img alt="pagamento em dia" src={paymentDone} />
                    </Icons>
                    <HoverText>Pagamento em dia</HoverText>
                  </Hover>
                );
              case 'id':
                return (
                  <Hover>
                    <Icons style={{ margin: '0 10px' }}>
                      <img alt="pagamento pendente" src={pendingPayment} />
                    </Icons>
                    <HoverText>Pagamento pendente</HoverText>
                  </Hover>
                );
              case 'ia':
                return (
                  <Hover>
                    <Icons style={{ margin: '0 10px' }}>
                      <img alt="pagamento atrasado" src={latePayment} />
                    </Icons>
                    <HoverText>Pagamento atrasado</HoverText>
                  </Hover>
                );
              case 'ub':
                return (
                  <Hover>
                    <Icons style={{ margin: '0 10px' }}>
                      <img alt="usuário bloqueado" src={blockedUsers} />
                    </Icons>
                    <HoverText>Usuário bloqueado</HoverText>
                  </Hover>
                );
              default:
                break;
            }
          })()}
          <Icons>
            <FontAwesomeIcon
              icon={loading ? faSpinner : cloud.is_pinned ? fasStar : faStar}
              spin={loading}
              color={loading ? theme.interface4 : theme.favorite}
              onClick={favorite}
            />
          </Icons>
        </div>
      </CardHeader>
      <CardContent>
        <CloudPerf>
          {cloudInfo ? (
            <>
              <Row
                style={{
                  width: '100%',
                  margin: '0px',
                }}
              >
                <Col xs="6">
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      margin: 'auto',
                      position: 'relative',
                    }}
                  >
                    <canvas width="100%" height="100%" ref={cpuRef} />
                    <span
                      style={{
                        width: '100%',
                        textAlign: 'center',
                        position: 'absolute',
                        top: 'calc(50% - 25px)',
                        color: theme.interface5,
                        fontSize: '20px',
                      }}
                    >
                      {cloudInfo.cpu}%
                    </span>
                  </div>
                </Col>
                <Col xs="6">
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      margin: 'auto',
                      position: 'relative',
                    }}
                  >
                    <canvas width="100%" height="100%" ref={memRef} />
                    <span
                      style={{
                        position: 'absolute',
                        width: '100%',
                        color: theme.interface5,
                        textAlign: 'center',
                        top: 'calc(50% - 25px)',
                        fontSize: '20px',
                      }}
                    >
                      {cloudInfo.mem}%
                    </span>
                  </div>
                </Col>
              </Row>
              <Row
                style={{
                  width: '100%',
                  margin: '10px 0px 0px 0px',
                }}
              >
                <Col xs="12">
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      margin: 'auto',
                      position: 'relative',
                    }}
                  >
                    <canvas width="100%" height="26px" ref={diskRef} />
                    <span
                      style={{
                        position: 'absolute',
                        fontWeight: 'bold',
                        left: 'calc(50% - 32px)',
                        top: '12px',
                        fontSize: '12px',
                        color: theme.interface6,
                      }}
                    >
                      {cloudInfo.disk}% usado
                    </span>
                    <span
                      style={{
                        position: 'absolute',
                        fontWeight: 'bold',
                        left: 'calc(50% - 9px)',
                        top: '40px',
                        fontSize: '12px',
                      }}
                    >
                      Disco
                    </span>
                  </div>
                </Col>
              </Row>
              {(Number(cloudInfo?.mem) > 80 ||
                Number(cloudInfo?.disk) > 80 ||
                Number(cloudInfo?.cpu) > 80) && (
                <Row
                  style={{
                    width: '100%',
                    margin: '10px 0px 0px 0px',
                  }}
                >
                  <Col xs="12">
                    <Pulsate
                      onClick={() => history.push(`/clouds/${cloud.id}`)}
                    >
                      <div className="icon">
                        <div />
                        <FontAwesomeIcon
                          style={{ width: '20px', height: '20px' }}
                          icon={faTriangleExclamation}
                        />
                      </div>

                      <span className="pulsate">Cloud sobrecarregado</span>
                    </Pulsate>
                  </Col>
                </Row>
              )}
            </>
          ) : (
            <p>O cloud está em sincronização</p>
          )}
        </CloudPerf>

        <CloudActions
          hasBadge={cloud.hard_block || cloud.soft_block || cloud.has_spammer}
        >
          <Button
            height="50px"
            width="80%"
            onClick={() => history.push(`/clouds/${cloud.id}`)}
          >
            Ver detalhes
          </Button>
        </CloudActions>
      </CardContent>
    </Card>
  );
};

export default withTheme(CloudCard);
