import styled from 'styled-components';

export const Container = styled.div`
  height: 50px;
  width: 350px;
  padding: 16px 10px;
  margin: 0 auto;

  background: ${props => props.theme.interface1};

  border-radius: 10px;
  border: 1px solid
    ${props =>
      props.theme.darkMode ? props.theme.interface7 : props.theme.interface3};

  display: flex;
  align-items: center;
  justify-content: space-evenly;

  span.text {
    font-weight: bold;
    font-size: 13px;
    color: ${props => props.theme.interface5};
  }

  span.time {
    font-weight: bold;
    font-size: 15px;
    color: ${props => props.theme.successNew};
  }

  @media (max-width: 768px) {
    margin: 10px 0;
    width: 100%;
  }
`;
