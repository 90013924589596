import CurrencyFormat from 'react-currency-format';
import styled from 'styled-components';

// export const Description = styled.div``;

// export const Links = styled.div``;

export const Card = styled.div`
  background: ${props => props.theme.interface1};

  border-radius: 5px;
`;

export const Stats = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Stat = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin: 40px 0px;

  img.avatar {
    width: 60px;
    border-radius: 50%;
  }

  span {
    color: ${props => props.theme.interface5};
    font-size: 14px;

    margin-top: 10px;
  }
`;

export const Value = styled.h1`
  font-size: 64px;
  color: ${props => props.theme.interface5};
`;

export const Credit = styled(CurrencyFormat)`
  font-size: 32px !important;
  font-weight: 700 !important;
  color: ${props => props.theme.successNew} !important;
`;

export const Subtitle = styled.span`
  font-size: 28px;
  color: ${props => (props.theme.darkMode ? props.theme.interface4 : '#333')};
  font-weight: 700;
`;

export const Description = styled.div`
  p.title {
    font-size: 18px;
    font-weight: 700;
    color: ${props => props.theme.brand_primary_color};
  }

  p.text {
    font-size: 16px;
    color: ${props => props.theme.interface5};
  }

  span.currency {
    font-size: 16px;
    font-weight: 700;
    color: ${props => props.theme.successNew};
  }
`;

export const LinkSend = styled(Card)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 30px 0%;

  div.field {
    display: flex;
    align-items: center;

    & > button {
      width: 98px;
    }
  }
`;

export const Content = styled.div`
  & > div {
    margin: 20px 0;
  }
`;
