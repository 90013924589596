import React, { createContext, useReducer } from 'react';
import { Database } from 'types/database';
import reducer from './reducer';
import {
  setLoading,
  getDatabase,
  updateField,
  updateValue,
  patchDatabase,
  deleteDatabase,
} from './actions';

export interface DatabaseDetailState {
  database: Database;
  updateLoading: boolean;
  errors: any;

  setLoading(value: boolean): void;
  getDatabase(id: string | number): Promise<void>;
  deleteDatabase(id: string | number): Promise<void>;
  patchDatabase(field: string, value: any, id: string | number): Promise<void>;
  updateField(field: string, value: any): void;
  updateValue(e: any): void;
}

// initial state
const initialState = {
  database: null,
  updateLoading: false,
  errors: null,
};

// context
export const DatabaseDetailContext = createContext<DatabaseDetailState>(
  initialState as DatabaseDetailState,
);

// provider
export const DatabaseDetailProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(
    reducer,
    initialState as DatabaseDetailState,
  );

  return (
    <DatabaseDetailContext.Provider
      value={{
        // state
        database: state.database,
        updateLoading: state.updateLoading,
        errors: state.errors,

        // actions
        setLoading: value => setLoading(dispatch, value),
        getDatabase: async id => await getDatabase(dispatch, id),
        deleteDatabase: async id => await deleteDatabase(dispatch, id),
        updateField: (field, value) =>
          updateField(state, dispatch, field, value),
        updateValue: e => updateValue(state, dispatch, e),
        patchDatabase: async (field, value, id) =>
          await patchDatabase(dispatch, field, value, id),
      }}
    >
      {children}
    </DatabaseDetailContext.Provider>
  );
};
