import React, { createContext, useReducer } from 'react';
import { PaginationType } from '@cloudez/cloudez-design-system';
import { Migration } from 'types/migration';
import reducer from './reducer';
import { setLoading, getMigrations, changePinned } from './actions';

export interface MigrationListingState extends PaginationType {
  migrations: Migration[];
  loading: boolean;
  errors: any;

  setLoading(value: boolean): void;
  getMigrations(
    page: string | number,
    search: string,
    filter: any,
    ctrl: AbortController,
  ): Promise<void>;
  changePinned(id: string | number): Promise<void>;
}

// initial state
const initialState = {
  migrations: null,
  count: null,
  previous: null,
  current: null,
  next: null,
  loading: true,
  errors: null,
};

// context
export const MigrationListingContext = createContext<MigrationListingState>(
  initialState as MigrationListingState,
);

// provider
export const MigrationListingProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(
    reducer,
    initialState as MigrationListingState,
  );

  return (
    <MigrationListingContext.Provider
      value={{
        // state
        migrations: state.migrations,
        count: state.count,
        previous: state.previous,
        current: state.current,
        next: state.next,
        loading: state.loading,
        errors: state.errors,

        // actions
        setLoading: value => setLoading(dispatch, value),
        getMigrations: async (page, search, filter, ctrl) =>
          await getMigrations(dispatch, page, search, filter, ctrl),
        changePinned: async id => await changePinned(dispatch, id),
      }}
    >
      {children}
    </MigrationListingContext.Provider>
  );
};
