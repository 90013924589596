import styled from 'styled-components';

interface IAddCardProps {
  width?: string;
  height?: string;
  row?: boolean;
}

export const AddCard = styled.div<IAddCardProps>`
  cursor: pointer;
  display: flex;
  width: ${props => props.width || '231px'};
  height: ${props => props.height || '142px'};
  justify-content: ${props => (props.row ? 'center' : 'space-evenly')};
  align-items: center;
  flex-direction: ${props => (props.row ? 'row' : 'column')};
  text-transform: uppercase;
  margin-bottom: 12px;

  color: ${props => props.theme.interface5};

  border: 2px dashed
    ${props =>
      props.theme.darkMode ? props.theme.interface6 : props.theme.interface4};
  border-radius: 5px;

  &:hover {
    border: 2px solid ${props => props.theme.brand_primary_color};
    color: ${props => props.theme.brand_primary_color};
  }
`;

interface IAddButtonProps {
  row?: boolean;
}

export const AddButton = styled.div<IAddButtonProps>`
  height: 25px;
  width: auto;
  background-color: ${props => props.theme.brand_secondary_color};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  color: ${props => props.theme.brand_primary_color};

  span {
    margin-left: 8px;
    font-size: 12px;
    text-transform: uppercase;
  }
`;
