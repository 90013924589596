import styled from 'styled-components';

interface ProgressLineProps {
  status: number;
  percentage: number | string;
}

export const ProgressLine = styled.div<ProgressLineProps>`
  position: absolute;
  top: 1px;
  left: 1px;
  height: 8px;
  width: ${props =>
    props.status === 0
      ? '0%'
      : props.status === 1
      ? `${props.percentage}%`
      : props.status === 2
      ? '50%'
      : props.status === 3
      ? '90%'
      : '99.7%'};
  border-radius: 10px;
  background: ${props => props.theme.successNew};
`;

export const ProgressBar = styled.div`
  height: 10px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid
    ${props =>
      props.theme.darkMode ? props.theme.interface2 : props.theme.interface4};
  background: ${props => props.theme.interface2};
`;

export const ProgressContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  margin-top: 8px;
`;

export const Label = styled.p`
  position: absolute;
  top: -14px;
  font-size: 11px;
  color: ${props => props.theme.successNew};
`;
