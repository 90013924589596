import React, { useState, useContext } from 'react';
import { withTheme } from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSpinner } from '@fortawesome/free-solid-svg-icons';

import {
  Modal,
  Input,
  Field,
  Label,
  Button,
} from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Content,
  Footer,
} from 'components/ListingModal/styles';
import { CloudDetailContext } from '../_context/state';

const ChangeNickname = ({ show, setShow }) => {
  const { cloud, updateLoading, patchCloud } = useContext(CloudDetailContext);
  const [nickname, setNickname] = useState(cloud.nickname);

  const updateCloudNickname = async () => {
    await patchCloud('nickname', nickname, cloud.id);

    if (!updateLoading) {
      setShow();
    }
  };

  return (
    <Modal show={show} setShow={setShow}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faEdit} />
        </Icon>
        <Text>
          <Title>Trocar apelido</Title>
        </Text>
      </Header>

      <Content>
        <Field>
          <Label>Apelido</Label>
          <Input
            block
            onChange={e => setNickname(e.target.value)}
            value={nickname}
            name="nickname"
          />
        </Field>
      </Content>
      <Footer>
        <Button disabled={updateLoading} onClick={updateCloudNickname} icon>
          {updateLoading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            'Alterar'
          )}
        </Button>
      </Footer>
    </Modal>
  );
};

export default withTheme(ChangeNickname);
