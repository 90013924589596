import React from 'react';
import exclamation from 'assets/img/svg/exclamation.svg';
import danger from 'assets/img/svg/danger.svg';
import { Button } from '@cloudez/cloudez-design-system';
import { useLayout } from 'hooks/layout';
import FreeTrialTime from 'pages/Onboard/FreeTrialTime';
import useDevice from 'hooks/device';
import { Wrapper } from './styles';

const NotificationBar = () => {
  const { notification, setNotification, sideMenuCollapsed } = useLayout();
  const { isMobile } = useDevice();

  return (
    <Wrapper
      sideMenuCollapsed={sideMenuCollapsed}
      type={notification?.type}
      button={notification?.button}
    >
      {(notification?.type === 'warning' ||
        notification?.type === 'danger') && (
        <img
          alt="exclamation"
          src={notification?.type === 'danger' ? danger : exclamation}
        />
      )}
      <span>{notification?.text}</span>
      {notification?.button?.onboarding && !isMobile && (
        <FreeTrialTime style={{ margin: 0 }} />
      )}
      <Button
        onClick={() => {
          if (notification?.button?.callback) {
            notification.button.callback();
          }
          if (notification?.button?.link) {
            window.open(notification?.button?.link, '_blank');
          }
          setNotification('', null, {});
        }}
      >
        {notification?.button?.text || 'OK'}
      </Button>
    </Wrapper>
  );
};

export default NotificationBar;
