import React, { useState, useEffect, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';

import {
  faPlus,
  faPlusSquare,
  faSpinner,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { NoResultsDetail } from 'components/Common';
import { AddButton, AddCard } from 'components/AddCard';

import {
  Field,
  Pagination,
  Input,
  Label,
  Error,
  Value,
  InputSufix,
  Button,
  createPagination,
  PaginatedResponse,
} from '@cloudez/cloudez-design-system';
import { toast } from 'react-toastify';
import {
  getEmailGroupsService,
  deleteEmailGroupService,
  createEmailGroupService,
  updateEmailGroupService,
} from 'services/email';
import { EmailGroup } from 'types/email';
import EmailAccountPlaceholder from '../Usuarios/EmailAccountPlaceholder';
import { EmailDetailContext } from '../_context/state';
import { UserCard, Username, UserDetail } from '../Usuarios/styles';

const Groups: React.FC = () => {
  const { email } = useContext(EmailDetailContext);

  const [loading, setLoading] = useState(true);
  const [createLoading, setCreateLoading] = useState(false);
  const [addGroup, setAddGroup] = useState(false);

  const [groupErrors, setGroupErrors] = useState({} as any);

  const [emailGroups, setEmailGroups] =
    useState<PaginatedResponse<EmailGroup>>(null);
  const [group, setGroup] = useState<EmailGroup>({
    email: email.id,
    name: '',
    origin: '',
    destiny: [],
  } as EmailGroup);

  const [newDestiny, setNewDestiny] = useState('');

  const getEmailGroupsCallback = async (
    page: number | string,
  ): Promise<void> => {
    try {
      const { data } = await getEmailGroupsService({
        page_size: 1000,
        email: email.id,
        page,
      });

      const pagination = createPagination(data, 9);

      setEmailGroups({
        data: data.results as EmailGroup[],
        ...pagination,
      });
      setGroup(data.results[0]);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEmailGroupsCallback(1);
    // eslint-disable-next-line
  }, []);

  const deleteGroup = async () => {
    await deleteEmailGroupService(group.id);

    const newGroups = emailGroups.data.filter(a => a.id !== group.id);

    setEmailGroups(prevState => ({
      ...prevState,
      data: newGroups,
    }));
    setGroup(newGroups[0]);

    toast.success('Grupo removido com sucesso.');
  };

  const createGroup = async () => {
    setCreateLoading(true);
    try {
      setGroupErrors([]);
      const { data } = await createEmailGroupService(group);
      setEmailGroups(prevState => ({
        ...prevState,
        data: [data, ...prevState.data],
      }));

      toast.success('Grupo adicionado.');
      setAddGroup(false);

      setGroup(data as EmailGroup);

      setCreateLoading(false);
    } catch (e) {
      setCreateLoading(false);
      setGroupErrors(e?.response?.data);
    }
  };

  const updateGroup = async () => {
    setCreateLoading(true);
    try {
      setGroupErrors([]);
      const { data } = await updateEmailGroupService(group.id, group);

      setEmailGroups(prevState => ({
        ...prevState,
        data: emailGroups.data.map(a => (a.id === data.id ? data : a)),
      }));

      toast.success('Grupo alterado com sucesso.');
      setCreateLoading(false);
    } catch (e) {
      setCreateLoading(false);
      setGroupErrors(e?.response?.data);
    }
  };

  const addDestiny = (group, cb) => {
    cb({
      ...group,
      destiny: [...group.destiny, newDestiny],
    });

    setNewDestiny('');
  };

  const deleteDestiny = (group, cb, destiny) => {
    const destinies = group.destiny.filter(d => d !== destiny);

    cb({
      ...group,
      destiny: destinies,
    });
  };

  return (
    <Row>
      <Col xl="5">
        <Row>
          <Col sm="6">
            {addGroup ? (
              <UserCard selected>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <FontAwesomeIcon icon={faUserCircle} />
                  <Username>{group.name}</Username>
                </div>
              </UserCard>
            ) : (
              <AddCard
                onClick={() => {
                  setAddGroup(!addGroup);
                  setGroup({
                    email: email.id,
                    name: '',
                    origin: '',
                    destiny: [],
                  } as EmailGroup);
                }}
                width="100%"
                height="50px"
              >
                <AddButton row>
                  <FontAwesomeIcon icon={faPlusSquare} />
                  <span>criar</span>
                </AddButton>
              </AddCard>
            )}
          </Col>

          {loading ? (
            <EmailAccountPlaceholder />
          ) : emailGroups?.data.length ? (
            emailGroups.data.map(_group => {
              return (
                <Col sm="6" key={_group.id}>
                  <UserCard
                    onClick={() => {
                      setGroup({
                        ..._group,
                      });
                      setAddGroup(false);
                    }}
                    selected={group && _group.id === group.id}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <FontAwesomeIcon icon={faUserCircle} />
                      <Username>{_group.name}</Username>
                    </div>
                  </UserCard>
                </Col>
              );
            })
          ) : (
            <Col sm="6">
              <NoResultsDetail row width="100%" height="50px">
                <FontAwesomeIcon icon={faUserCircle} />
                Nenhum grupo vinculado.
              </NoResultsDetail>
            </Col>
          )}
        </Row>

        <Row>
          <Pagination
            style={{
              margin: '0 auto',
            }}
            onChangePage={getEmailGroupsCallback}
            pageSize={9}
            count={emailGroups?.count}
            previous={emailGroups?.previous}
            next={emailGroups?.next}
            current={emailGroups?.current}
          />
        </Row>
      </Col>

      {group && (
        <Col xl="7">
          <UserDetail>
            <Row>
              <Col sm="6">
                <Field>
                  <Label>Nome do grupo</Label>
                  <Input
                    block
                    value={group.name}
                    onChange={e => {
                      setGroup({
                        ...group,
                        name: e.target.value,
                      });
                    }}
                    error={!!groupErrors.name}
                  />
                  {groupErrors.name && <Error>{groupErrors.name[0]}</Error>}
                </Field>
              </Col>
              <Col sm="6">
                <Field>
                  <Label>Origem</Label>
                  <Input
                    block
                    value={group.origin}
                    onChange={e => {
                      setGroup({
                        ...group,
                        origin: e.target.value,
                      });
                    }}
                    error={!!groupErrors.origin}
                  />
                  <InputSufix>{`@${email.domain}`}</InputSufix>

                  {groupErrors.origin && <Error>{groupErrors.origin[0]}</Error>}
                </Field>
              </Col>
              <Col sm="12">
                <Field>
                  <Label>Destino</Label>
                  <Row
                    style={{
                      marginTop: `${5}px`,
                      marginBottom: `${5}px`,
                    }}
                  >
                    <Col xs="9">
                      <Input
                        block
                        type="email"
                        value={newDestiny}
                        onChange={e => {
                          setNewDestiny(e.target.value);
                        }}
                        error={!!groupErrors.destiny}
                      />
                    </Col>
                    <Col xs="2">
                      <Button
                        width="100%"
                        icon
                        onClick={() => addDestiny(group, setGroup)}
                      >
                        <FontAwesomeIcon
                          style={{ position: 'initial' }}
                          icon={faPlus}
                        />
                      </Button>
                    </Col>
                  </Row>

                  {group.destiny.length ? (
                    group?.destiny.map((destiny, i) => {
                      return (
                        <>
                          <Value
                            key={destiny}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            {destiny}{' '}
                            <FontAwesomeIcon
                              onClick={() =>
                                deleteDestiny(group, setGroup, destiny)
                              }
                              style={{
                                position: 'initial',
                              }}
                              icon={faTimes}
                            />
                          </Value>

                          {groupErrors.destiny && (
                            <Error
                              key={i}
                              style={{
                                marginBottom: '4px',
                              }}
                            >
                              {groupErrors.destiny[i]}
                            </Error>
                          )}
                        </>
                      );
                    })
                  ) : (
                    <Value>Este grupo ainda não possui destino</Value>
                  )}
                </Field>
              </Col>
              <Col>
                {addGroup ? (
                  <Button
                    onClick={createGroup}
                    size="sm"
                    style={{
                      marginRight: '15px',
                    }}
                    disabled={createLoading}
                    icon
                  >
                    {createLoading ? (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                      'Adicionar'
                    )}
                  </Button>
                ) : (
                  <div
                    style={{
                      display: 'flex',
                    }}
                  >
                    <Button
                      size="sm"
                      style={{
                        marginRight: '15px',
                      }}
                      onClick={updateGroup}
                    >
                      {createLoading ? (
                        <FontAwesomeIcon icon={faSpinner} spin />
                      ) : (
                        'Salvar'
                      )}
                    </Button>
                    <Button onClick={deleteGroup} error outline size="sm">
                      Deletar
                    </Button>
                  </div>
                )}
              </Col>
            </Row>
          </UserDetail>
        </Col>
      )}
    </Row>
  );
};

export default Groups;
