import styled from 'styled-components';

export const Title = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;

  color: ${props => props.theme.brand_primary_color};

  span {
    font-weight: bold;
    font-size: 16px;
    line-height: 25px;
  }

  svg {
    width: 23px;
    height: 23px;
  }
`;
