import React, { Dispatch, SetStateAction } from 'react';

import {
  faExchangeAlt,
  faCommentAlt,
  faBell,
} from '@fortawesome/free-solid-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import moment from 'moment/min/moment-with-locales';
import { Link } from 'react-router-dom';
import { useNotifications } from 'hooks/notifications';
import { Wrapper, List, Notification } from './styles';

interface IDropdownProps {
  setDropdown: Dispatch<SetStateAction<boolean>>;
}

const Dropdown: React.FC<IDropdownProps> = ({ setDropdown }) => {
  const { visualizeAll, notifications } = useNotifications();

  return (
    <div
      style={{
        position: 'absolute',
        zIndex: 2,
      }}
    >
      <div
        style={{
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        }}
        onClick={() => setDropdown(false)}
      />
      <Wrapper>
        <List>
          {notifications?.length > 0 ? (
            notifications?.map(
              n =>
                n &&
                n.type !== 'chat' && (
                  <Notification key={n.id} status={n.status}>
                    <Link to={`/${n.type}s/${n.resource_id}`}>
                      {n.type === 'ticket' ? (
                        <FontAwesomeIcon icon={faCommentAlt} />
                      ) : (
                        <FontAwesomeIcon icon={faExchangeAlt} />
                      )}
                      <div>
                        {n.description}
                        <span>
                          {moment(n.updated_at).locale('pt-BR').fromNow()}
                        </span>
                      </div>
                    </Link>
                  </Notification>
                ),
            )
          ) : (
            <Notification status={1}>
              <span onClick={() => {}}>
                <FontAwesomeIcon icon={faBell} />
                Você não possui notificações
              </span>
            </Notification>
          )}
        </List>
        {notifications?.length > 0 && (
          <a
            href="#!"
            onClick={e => {
              e.preventDefault();
              visualizeAll();
            }}
          >
            marcar todas como visualizadas
          </a>
        )}
      </Wrapper>
    </div>
  );
};

export default Dropdown;
