import * as Sentry from '@sentry/react';

const isLocal = process.env.NODE_ENV === 'development';

export function logError(error: Error, errorInfo = null): void {
  if (isLocal) {
    console.log(error, errorInfo);
  }

  Sentry.withScope(scope => {
    errorInfo && scope.setExtras(errorInfo);
    Sentry.captureException(error);
  });
}
