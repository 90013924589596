/* eslint-disable prettier/prettier */
import React, { useEffect, useMemo, useState } from 'react';

import { useParams, useHistory } from 'react-router-dom';

import moment from 'moment';

import psl from 'psl';

import {
  changeStatusMigrationService,
  confirmMigrationService,
  getStatusMigrationService,
  ratingMigrationService,
} from 'services/friday';

import {
  faCloudUploadAlt,
  faExclamationCircle,
  faInfoCircle,
  faPaperPlane,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { faThumbsDown, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useLayout } from 'hooks/layout';

import { Header, Title } from 'components/Common';
import { Alert } from 'components/Alert';
import Stepper from 'components/Stepper';

import { Button } from '@cloudez/cloudez-design-system';

import { toast } from 'react-toastify';
import { useAuth } from 'hooks/auth';

import { autoPointDNSService } from 'services/website';

import api from 'services/api';
import { useDocTitle } from 'hooks/docTitle';
import {
  Container,
  Content,
  RatingButton,
  RatingButtons,
  StatusBox,
} from './styles';

import { useMigCreateAssisted } from '../MigrationCreateNew/contexts/MigAssistedContext';

import CancelMigrationModal from './CancelMigrationModal';
import RemoveBackupModal from './RemoveBackupModal';
import SyncsModal from './SyncsModal';
import SyncsTable from './Syncs';
import Success from './Success';
import SuccessModal from '../MigrationCreateNew/SuccessModal';

const MigrationDetailNew = (): JSX.Element => {
  const [migration, setMigration] = useState<any>({});
  const [pointed, setPointed] = useState<boolean>(false);

  const { user } = useAuth();

  const { cacheData } = useMigCreateAssisted();

  const { id } = useParams();
  const theme = useLayout();

  const history = useHistory();

  useDocTitle(
    migration
      ? `Migração ${migration?.domain} — Cloudez`
      : 'Migrações — Cloudez',
  );

  const queryParams = new URLSearchParams(window.location.search);

  const createNow = queryParams.get('success');

  const [showCancelMigrationModal, setShowCancelMigrationModal] =
    useState(false);

  const [showRemoveBackupModal, setShowRemoveBackupModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [confirmationTime, setConfirmationTime] = useState('');
  const [showSyncsModal, setShowSyncsModal] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [loadingRate, setLoadingRate] = useState(false);
  const [isCancelled, setIsCancelled] = useState(false);
  const [statusMig, setStatusMig] = useState<number>();
  const [appointed, setAppointed] = useState(null);
  const [isRated, setIsRated] = useState(null);
  const [rating, setRating] = useState(null);
  const [message, setMessage] = useState('');

  const title = {
    website: 'Migração de Website',
    email: 'Migração de Email',
  }[migration?.migration_target];

  const date = confirmationTime || migration?.validated_at;

  const validatedAtDate = moment.utc(date).format('DD/MM/YYYY');
  const validatedAtTime = moment.utc(date).format('HH:mm');

  const validatedAt =
    date && `Confirmado em ${validatedAtDate} às ${validatedAtTime}`;

  const isRemoveBackup = useMemo(() => {
    return migration?.syncs?.find(
      s => s.type === 'remove_backup' && (s.status === 2 || s.status === 4),
    );
  }, [migration?.syncs]);

  const showButtonRemoveBackup = useMemo(() => {
    return migration?.syncs?.find(
      s => s.type === 'remove_backup' && s.status !== 3,
    );
  }, [migration?.syncs]);

  const displayExternal =
    migration?.remote_host && migration.migration_type === 2;

  const status = {
    1: 'Em fila',
    2: 'Migrando',
    3: 'Migrando',
    4: 'Finalizado',
    5: 'Cancelado',
    6: 'Migrando',
    7: 'Validando',
  }[statusMig || migration.migration_status];

  const source = migration?.remote_host
    ? migration
    : migration?.src_nodeapp
    ? migration.src_nodeapp
    : migration.src_zoneapp;

  const destination = migration?.dest_nodeapp
    ? migration.dest_nodeapp
    : migration.dest_zoneapp;

  const destItemId =
    migration?.migration_target === 'email'
      ? migration?.dest_zoneapp?.id
      : migration?.dest_nodeapp?.id;
      
      const appointDNS = async ({ appoint }) => {
        setPointed(true);
        try {
          if (appoint === true) {
            await autoPointDNSService(
              destItemId,
              migration?.migration_target,
              migration?.migration_target === 'email'
                ? {
                    src_zoneapp_id: migration?.src_zoneapp?.id,
                    migration_user_uuid: user?.uuid,
                  }
                : {
                    src_nodeapp_id: migration?.src_nodeapp?.id,
                    migration_user_uuid: user?.uuid,
                  },
            );
          }

          await changeStatusMigrationService(id, {
            dns_pointed: appoint,
          });
          setAppointed(appoint);

          const { data } = await confirmMigrationService(id);

          setStatusMig(4);
          setConfirmationTime(data.validated_at);
      }catch (err) {
        console.log('err', err);
      }
  };

  const confirmMigration = async () => {
    setLoadingConfirm(true);
    const { data } = await confirmMigrationService(id);

    setStatusMig(4);

    setConfirmationTime(data.validated_at);
    setLoadingConfirm(false);
  };

  const sendRating = async () => {
    setLoadingRate(true);
    try {
      await ratingMigrationService(id, {
        rating,
        rating_message: message,
      });
      setIsRated(true);
      setLoadingRate(false);
      toast.success('Recebemos o seu feedback!');
    } catch (err) {
      setLoadingRate(false);
      console.log('err', err);
      toast.error('Não foi possível enviar o feedback, tente novamente.');
    }
  };

  useEffect(() => {
    if (createNow === 'true') {
      setShowSuccessModal(true);
    }
  }, [createNow]);

  useEffect(() => {
    if (id) {
      const getMigration = async () => {
        setLoadingStatus(true);
        const { data } = await getStatusMigrationService(id);

        setAppointed(data.dns_pointed);

        if (data.rating !== undefined) {
          setRating(data.rating);
          setIsRated(true);
        }

        setMigration(data);
        setLoadingStatus(false);
      };
      getMigration();
    }
  }, [id]);

  const [verifiedZone, setVerifiedZone] = useState<any>({});

  useEffect(() => {
    if (migration?.dest_nodeapp?.domain || migration?.dest_zoneapp?.domain) {
      const verifyZone = async () => {
        const domain =
          migration?.dest_nodeapp?.domain || migration?.dest_zoneapp?.domain;

        const rootDomain = psl.parse(domain).domain;

        const { data } = await api.get(`v3/domain/?domain=${rootDomain}`);

        setVerifiedZone(data.results.filter(item => item.domain === domain)[0]);
      };
      verifyZone();
    }
  }, [migration]);

  return loadingStatus ? (
    <div
      style={{
        minHeight: 'calc(100vh - 233px)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <FontAwesomeIcon icon={faSpinner} size="4x" spin />
    </div>
  ) : (
    <>
      {showSuccessModal && (
        <SuccessModal
          migration={migration}
          show={showSuccessModal}
          setShow={setShowSuccessModal}
        />
      )}
      {showCancelMigrationModal && (
        <CancelMigrationModal
          domain={migration.domain}
          show={showCancelMigrationModal}
          setShow={setShowCancelMigrationModal}
          setIsCancelled={setIsCancelled}
          setStatusMig={setStatusMig}
          id={id}
        />
      )}
      {showRemoveBackupModal && (
        <RemoveBackupModal
          domain={migration.dest_nodeapp.domain}
          show={showRemoveBackupModal}
          setShow={setShowRemoveBackupModal}
          sync={showButtonRemoveBackup?.id}
        />
      )}
      {showSyncsModal && (
        <SyncsModal
          show={showSyncsModal}
          setShow={setShowSyncsModal}
          isRemoveBackup={isRemoveBackup}
          migration={migration}
        />
      )}
      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
        {id && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Header style={{ justifyContent: 'center' }}>
              <Title>{title || 'Migração Assistida'}</Title>
            </Header>
            <Stepper
              width="60%"
              activeStep={4}
              steps={
                migration?.migration_target
                  ? [
                      { label: 'Dados da Origem' },
                      { label: 'Dados do Destino' },
                      { label: 'Confirmação' },
                      { label: 'Migração' },
                    ]
                  : [{ label: 'Dados Gerais' }, { label: 'Sucesso' }]
              }
            />
          </div>
        )}

        {cacheData || migration?.migration_type === 3 ? (
          <Success migration={migration} />
        ) : (
          <Container>
            {((migration.migration_status === 2 && !isCancelled) ||
              (migration.migration_status === 3 && !isCancelled) ||
              (migration.migration_status === 1 && !isCancelled) ||
              (migration.migration_status === 6 && !isCancelled)) && (
              <Content>
                <div className="title">
                  <FontAwesomeIcon icon={faCloudUploadAlt} />
                  <div>
                    <span>Migração:</span> {migration.domain}
                  </div>
                </div>
                <SyncsTable
                  isRemoveBackup={isRemoveBackup}
                  migration={migration}
                />
                <Button
                  onClick={() => setShowCancelMigrationModal(true)}
                  style={{
                    textTransform: 'none',
                    fontSize: '12px',
                    marginTop: '24px',
                  }}
                  type="button"
                  width="133px"
                  error
                  height="36px"
                  outline
                >
                  Cancelar Migração
                </Button>
              </Content>
            )}

            <Content>
              <div className="title">
                <FontAwesomeIcon icon={faInfoCircle} />
                <span>Detalhes da Migração</span>
              </div>
              <div className="status">
                <p>Status</p>
                <StatusBox status={statusMig || migration.migration_status}>
                  {status}
                </StatusBox>
              </div>
              <div className="originData">
                <p>Dados da Origem</p>
                <span>
                  <b>Domínio:</b>{' '}
                  <a
                    href={`https://${source?.domain}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {source?.domain}
                  </a>
                </span>
                {(migration.migration_type === 1 && !migration?.imap_host) ||
                migration?.remote_host ||
                displayExternal ? (
                  <>
                    <span>
                      <b>Servidor FTP:</b> {migration.remote_host}
                    </span>
                    <span>
                      <b>Porta:</b> {migration.remote_port}
                    </span>
                    <span>
                      <b>Caminho da aplicação:</b> {migration.ssh_path}
                    </span>
                  </>
                ) : migration?.imap_host ? (
                  <>
                    <span>
                      <b>Endereço IMAP:</b> {migration.imap_host}
                    </span>
                    <span>
                      <b>Porta:</b> {migration.imap_port}
                    </span>
                  </>
                ) : (
                  <>
                    <span>
                      <b>Servidor:</b> {source?.cloud_description}
                    </span>
                  </>
                )}
              </div>
              <div className="destData">
                <p>Dados de Destino</p>
                <span>
                  <b>Domínio:</b>{' '}
                  <a
                    href={`https://${destination?.domain}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {destination?.domain}
                  </a>
                </span>
                <span>
                  <b>
                    {' '}
                    {migration.migration_type === 1 ? 'Cloud:' : 'Servidor:'}
                  </b>{' '}
                  {destination?.cloud_description}
                </span>
              </div>
              {destination?.temporary_address && (
                <div className="tempMail">
                  <p>{`Endereço Temporário ${
                    migration?.migration_target === 'website'
                      ? 'da Aplicação'
                      : 'do Webmail'
                  }`}</p>
                  <div>
                    <a
                      href={`https://${destination?.temporary_address}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {destination?.temporary_address}
                    </a>
                    {(migration.migration_status === 7 ||
                      migration.migration_status === 4) && (
                      <StatusBox status={4} className="statusBox">
                        Disponível
                      </StatusBox>
                    )}
                  </div>
                </div>
              )}
              {migration?.tickets && (
                <div className="relatedTickets">
                  <p>Tickets Relacionados</p>
                  <div className="tickets">
                    {migration?.tickets?.map(item => (
                      <div>
                        <a
                          onClick={() => window.open(`/tickets/${item.id}`)}
                          className="ticketId"
                        >
                          #{item.id}
                        </a>
                        -
                        <span
                          style={{
                            color:
                              item.status === 2
                                ? theme.errorNew
                                : theme.successNew,
                          }}
                          className="ticketStatus"
                        >
                          {item.status === 2 ? 'FECHADO' : 'ABERTO'}
                        </span>
                      </div>
                    ))}
                  </div>
                </div>
              )}

              <Button
                onClick={() => window.open(`/suporte/create/?migration=${id}`)}
                style={{
                  textTransform: 'none',
                  marginTop: '24px',
                  fontSize: '12px',
                }}
                type="button"
                width="117px"
                height="30px"
                outline
              >
                Abrir Ticket
              </Button>
              {(migration.migration_status === 4 ||
                migration.migration_status === 7 ||
                statusMig === 4) && (
                <div
                  onClick={() => setShowSyncsModal(true)}
                  className="reviewProgress"
                >
                  Rever o Progresso
                </div>
              )}
              {(migration.migration_status === 4 || statusMig === 4) && (
                <>
                  {showButtonRemoveBackup && (
                    <>
                      <Alert style={{ marginTop: '24px' }} alert>
                        <FontAwesomeIcon size="lg" icon={faExclamationCircle} />
                        <span>
                          <b>Removendo backup de migração:</b> Os arquivos de
                          backup da sua migração serão removidos em 7 dias.
                        </span>
                      </Alert>
                      <Button
                        onClick={() => setShowRemoveBackupModal(true)}
                        style={{
                          textTransform: 'none',
                          marginTop: '24px',
                          fontSize: '12px',
                        }}
                        type="button"
                        width="133px"
                        height="35px"
                        error
                        outline
                      >
                        Remover backup
                      </Button>
                    </>
                  )}
                </>
              )}
            </Content>

            {(migration.migration_status === 7 ||
              migration.migration_status === 4) && (
              <Content type="success">
                <div>
                  <h1>Sua migração foi concluída com sucesso</h1>
                  <div className="webmail">
                    <span>{`Acesse seu ${
                      migration?.migration_target === 'website'
                        ? 'website'
                        : 'webmail'
                    } pelo link temporário:`}</span>
                    <a
                      href={`http://${destination?.temporary_address}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {destination?.temporary_address}
                    </a>
                  </div>
                  <div className="domainsContent">
                    <b>
                      {migration.migration_type === 1
                        ? 'Verifique se sua aplicação está funcionando corretamente, confirme sua migração e altere os DNS.'
                        : 'Caso confirme que tudo esteja funcionando corretamente, confirme sua migração.'}
                    </b>
                    {migration.migration_type === 1 && (
                      <div className="domains">
                        <span>ns1.cloudez.io</span>
                        <span>ns2.cloudez.io</span>
                        <span>ns3.cloudez.io</span>
                        <span>ns4.cloudez.io</span>
                        <span>ns5.cloudez.io</span>
                      </div>
                    )}
                  </div>
                  {((migration.migration_type === 1 &&
                    migration.migration_status === 7 &&
                    !date) ||
                    (migration.migration_type === 2 &&
                      migration.migration_status === 7 &&
                      !date &&
                      !verifiedZone?.is_active)) && (
                    <Button
                      onClick={confirmMigration}
                      style={{ marginTop: '24px' }}
                      icon
                      className="confirmButton"
                      type="button"
                    >
                      {loadingConfirm ? (
                        <FontAwesomeIcon icon={faSpinner} spin />
                      ) : (
                        'CONFIRMAR'
                      )}
                    </Button>
                  )}
                  {migration.migration_type === 2 && verifiedZone?.is_active && (
                    <>
                      <RatingButtons style={{ marginTop: '24px' }}>
                        <Button
                          onClick={() => appointDNS({ appoint: true })}
                          style={{
                            opacity: '1',
                            color: appointed ? theme.successNew : '#ffffff',
                            backgroundColor: appointed
                              ? '#ffffff'
                              : 'transparent',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            border: '2px solid #ffffff',
                          }}
                          disabled={
                            migration?.dns_pointed !== null ||
                            appointed !== null || pointed === true
                          }
                          success
                          height="35px"
                          type="button"
                        >
                          CONFIRMAR E APONTAR DNS
                        </Button>
                        <Button
                          onClick={() => appointDNS({ appoint: false })}
                          style={{
                            opacity: '1',
                            color:
                              appointed === false
                                ? theme.successNew
                                : '#ffffff',
                            backgroundColor:
                              appointed === false ? '#ffffff' : 'transparent',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            border: '2px solid #ffffff',
                          }}
                          success
                          disabled={
                            migration?.dns_pointed !== null ||
                            appointed !== null
                          }
                          height="35px"
                          type="button"
                        >
                          CONFIRMAR E NÃO APONTAR
                        </Button>
                      </RatingButtons>
                      {appointed === null && (
                        <>
                          <div className="infoAppointment">
                            <b>APONTAR DNS</b>
                            <span>
                              O apontamento mudará sua aplicação para o cloud de
                              destino automaticamente. Só estará disponível se o
                              website de origem estiver hospedado e ativo no seu
                              painel.
                            </span>
                          </div>
                          <div className="infoAppointment">
                            <b>NÃO APONTAR</b>
                            <span>
                              O apontamento para o cloud de destino deverá ser
                              feito manualmente.
                            </span>
                          </div>
                        </>
                      )}
                    </>
                  )}
                  {(migration.migration_status === 4 ||
                    confirmationTime.length > 0) && (
                    <div className="confirmedMigration">
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <span className="confirmedDate">{validatedAt}</span>
                        {appointed && (
                          <span className="confirmedDate">
                            {appointed
                              ? 'O apontamento ocorrerá em um tempo médio de 30 minutos, após esse período você poderá acessar seu website através do seu domínio.'
                              : 'O apontamento deverá ocorrer manualmente para o novo destino.'}
                          </span>
                        )}
                      </div>
                      <b>
                        Agora que já confirmou sua migração, nos ajude dando o
                        seu feedback sobre o processo!
                      </b>
                      <RatingButtons>
                        <RatingButton
                          active={rating}
                          onClick={() => setRating(true)}
                          success
                          disabled={migration?.rating !== undefined || isRated}
                          height="40px"
                          width="190px"
                          type="button"
                        >
                          <FontAwesomeIcon
                            style={{ marginLeft: '0' }}
                            icon={faThumbsUp}
                          />
                          Bom, estou satisfeito
                        </RatingButton>
                        <RatingButton
                          active={rating === false}
                          onClick={() => setRating(false)}
                          success
                          disabled={migration?.rating !== undefined || isRated}
                          type="button"
                        >
                          <FontAwesomeIcon
                            style={{ marginLeft: '0' }}
                            icon={faThumbsDown}
                          />
                          Ruim, estou insatisfeito
                        </RatingButton>
                      </RatingButtons>
                      {isRated === null && (
                        <div className="comment">
                          <span>Deixe um comentário</span>
                          <input
                            value={message}
                            onChange={e => setMessage(e.target.value)}
                            placeholder="Escreva sua mensagem aqui"
                          />
                          <Button
                            onClick={sendRating}
                            width="128px"
                            icon
                            height="40px"
                            style={{ gap: '10px' }}
                            type="button"
                          >
                            <span>Enviar</span>
                            {loadingRate ? (
                              <FontAwesomeIcon icon={faSpinner} spin />
                            ) : (
                              <FontAwesomeIcon icon={faPaperPlane} />
                            )}
                          </Button>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </Content>
            )}

            {(migration.migration_status === 5 || isCancelled) && (
              <Content type="canceled">
                <h1>Sua migração foi cancelada</h1>
                <span className="cancelledText">
                  Caso você deseje refaze-la ou iniciar uma nova migração,
                  clique no botão abaixo e comece um novo processo imediatamente
                </span>
                <Button
                  onClick={() => history.push('/migrations/create')}
                  style={{
                    color: '#ffffff',
                    border: '2px solid #ffffff',
                    marginTop: '64px',
                  }}
                  outline
                  width="164px"
                  height="30px"
                  type="button"
                >
                  Nova migração
                </Button>
              </Content>
            )}
          </Container>
        )}
        {/* {migration.migration_status === 2 ||
        migration.migration_status === 3 ? (
          <SupportBox>
            <img src={support} alt="Suport" />
          </SupportBox>
        ) : null} */}
      </div>
    </>
  );
};

export default props => <MigrationDetailNew {...props} />;
