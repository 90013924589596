import React, { useEffect, useContext, useState, useRef } from 'react';
import { AnimatedList } from 'react-animated-list';

import queryString from 'query-string';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStar,
  faCloud,
  faAngleUp,
  faAngleDown,
} from '@fortawesome/free-solid-svg-icons';

import history from 'services/history';

import { ListingHeader, Ordering } from 'components/Listing/styles';
import {
  Button,
  Pagination,
  AdvancedSearch,
} from '@cloudez/cloudez-design-system';
import SearchBar from 'components/SearchBar';

import { Title, Header } from 'components/Common';
import EmptyListing from 'components/EmptyListing';
import {
  Filter,
  FilterHover,
  FilterSeparator,
  FilterWrapper,
} from 'components/SearchBar/styles';
import { Value } from 'components/Acessos';
import { ThemeContext } from 'styled-components';
import FilterDropdown from 'components/CloudFilter';

import { useDocTitle } from 'hooks/docTitle';
import { useLayout } from 'hooks/layout';
import InvoiceWarning from 'pages/Dashboard/InvoiceWarning';
import { EmailListingProvider, EmailListingContext } from './_context/state';
import EmailCard from './EmailCard';
import EmailPlaceholder from './Placeholder';

const Emails = () => {
  const theme = useLayout();

  useDocTitle(`Emails — ${theme.name}`);

  const { loading, emails, count, previous, next, current, getEmails } =
    useContext(EmailListingContext);

  const {
    page = 1,
    search: _search = '',
    filter = 'all',
  } = queryString.parse(history.location.search);

  const [filterValue, setFilterValue] = useState(filter);
  const [cloudFilter, setCloudFilter] = useState(false);
  const [clouds, setClouds] = useState(null);
  const [orderValue, setOrderValue] = useState({ column: '', value: 0 }); // Inicialização do orderValue com valor zero e não ter chance de rolar undefined

  const [search, setSearch] = useState(
    queryString.parse(history.location.search),
  );

  const abortControllerRef = useRef<AbortController>(new AbortController());

  const getEmailsCallback = (page, search, filter, clouds, order) => {
    abortControllerRef.current.abort();
    abortControllerRef.current = new AbortController();
    getEmails(page, search, filter, clouds, order, abortControllerRef.current);

    const _query = {
      ...search,
      page,
      filter,
      clouds,
    };

    if (!search) delete _query.search;
    if (!clouds) delete _query.clouds;
    if (filter === 'all') delete _query.filter;
    if (page === 1 || page === '1') delete _query.page;

    const query = queryString.stringify(_query);
    history.replace(`/emails?${query}`);
  };

  useEffect(() => {
    getEmailsCallback(page, search, filter, clouds, orderValue);
    // eslint-disable-next-line
  }, []);

  const handleSetClouds = clouds => {
    const _clouds = clouds.join(',');
    setClouds(_clouds);
    setCloudFilter(false);
    getEmailsCallback(1, search, filter, _clouds, orderValue);
  };

  const handleSetFilter = filter => {
    setFilterValue(filter);
    getEmailsCallback(1, search, filter, clouds, orderValue);
  };

  const searchValue = searchValue => {
    getEmailsCallback(1, searchValue, filterValue, clouds, orderValue);
  };

  const order = column => {
    // Ajuste na lógica de ordenação
    const newOrderValue =
      orderValue.column === column
        ? {
            column,
            value: orderValue.value === 1 ? 2 : 1,
          }
        : {
            column,
            value: 1,
          };
    setOrderValue(newOrderValue);
    getEmailsCallback(1, searchValue, filterValue, clouds, newOrderValue);
  };

  return (
    <>
      <Header>
        <Title>Emails</Title>
        <Button onClick={() => history.push('/emails/create')}>
          <span>Novo email</span>
        </Button>
      </Header>
      <InvoiceWarning />

      <SearchBar noInput>
        <AdvancedSearch
          style={{
            width: '400px',
          }}
          callback={searchValue}
          value={search}
          noResults={false}
          setValue={setSearch}
          fields={[
            {
              label: 'Qualquer campo',
              value: 'search',
            },
            {
              label: 'Domínio',
              value: 'domain',
            },
            {
              label: 'Nome do cloud',
              value: 'cloud_name',
            },
            {
              label: 'Fqdn do cloud',
              value: 'cloud_fqdn',
            },
            {
              label: 'Email',
              value: 'email',
            },
          ]}
        />
        <FilterWrapper>
          <Filter>
            <FontAwesomeIcon
              icon={faCloud}
              color={
                cloudFilter || clouds
                  ? theme.brand_primary_color
                  : theme.darkMode
                  ? theme.interface5
                  : theme.interface4
              }
              onClick={() => {
                setCloudFilter(!cloudFilter);
              }}
            />
            <FilterHover>
              <Value>
                <span>Cloud</span>
              </Value>
            </FilterHover>
            <FilterDropdown
              type="email"
              setShow={setCloudFilter}
              show={cloudFilter}
              callback={handleSetClouds}
            />
          </Filter>
          <FilterSeparator />
          <Filter>
            <FontAwesomeIcon
              icon={faStar}
              color={
                filterValue === 'favorite'
                  ? theme.favoriteNew
                  : theme.darkMode
                  ? theme.interface5
                  : theme.interface4
              }
              onClick={() =>
                filterValue === 'favorite'
                  ? handleSetFilter('all')
                  : handleSetFilter('favorite')
              }
            />
            <FilterHover>
              <Value>
                <span>Favoritos</span>
              </Value>
            </FilterHover>
          </Filter>
        </FilterWrapper>
      </SearchBar>

      {loading ? (
        <EmailPlaceholder />
      ) : emails?.length ? (
        <>
          <ListingHeader>
            <Row>
              <Col xs="10" sm="10" md="5">
                <p
                  onClick={() => {
                    order('domain');
                  }}
                >
                  Provedor
                  <Ordering
                    status={
                      orderValue?.column === 'domain' ? orderValue?.value : 0
                    }
                  >
                    <FontAwesomeIcon
                      icon={orderValue.value === 2 ? faAngleUp : faAngleDown}
                      style={{ marginTop: '2px' }}
                    />
                  </Ordering>
                </p>{' '}
              </Col>

              <Col className="d-none d-xl-flex">
                <p
                  onClick={() => {
                    order('cloud');
                  }}
                >
                  Cloud
                  <Ordering
                    status={
                      orderValue?.column === 'cloud' ? orderValue?.value : 0
                    }
                  >
                    <FontAwesomeIcon
                      icon={orderValue.value === 2 ? faAngleUp : faAngleDown}
                      style={{ marginTop: '2px' }}
                    />
                  </Ordering>
                </p>{' '}
              </Col>

              <Col className="d-none d-md-flex">
                <p
                  onClick={() => {
                    order('disk');
                  }}
                >
                  Disco
                  <Ordering
                    status={
                      orderValue?.column === 'disk' ? orderValue?.value : 0
                    }
                  >
                    <FontAwesomeIcon
                      icon={orderValue.value === 2 ? faAngleUp : faAngleDown}
                      style={{ marginTop: '2px' }}
                    />
                  </Ordering>
                </p>{' '}
              </Col>

              <Col xl="2" className="d-none d-md-flex" />
            </Row>
          </ListingHeader>

          <AnimatedList initialAnimationDuration={800} animation="grow">
            {emails.map(email => (
              <EmailCard key={email.id} email={email} />
            ))}
          </AnimatedList>

          <Pagination
            onChangePage={getEmailsCallback}
            pageSize={10}
            count={count}
            previous={previous}
            next={next}
            current={current}
            search={search}
            filter={filterValue}
            clouds={clouds}
            order={orderValue}
          />
        </>
      ) : null}

      {!loading && emails?.length === 0 && (
        <EmptyListing>
          <p>Nenhum email encontrado.</p>
          <Button onClick={() => history.push('/emails/create')} size="lg">
            Criar novo email
          </Button>
        </EmptyListing>
      )}
    </>
  );
};

export default props => (
  <EmailListingProvider>
    <Emails {...props} />
  </EmailListingProvider>
);
