import React from 'react';
import { Col, Row } from 'react-bootstrap';

import Placeholder from 'components/Placeholder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumbs, Breadcrumb, Title, Header } from 'components/Common';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import {
  Card,
  CompanyBillingInfo,
  Separator,
  UserBillingInfo,
  OrderStatus,
  InvoiceItems,
  InvoiceItemsHeader,
  InvoiceItem,
} from '../styles';

const TicketDetailPlaceholder: React.FC = () => {
  return (
    <>
      <Header>
        <Title>
          <Breadcrumbs>
            <Breadcrumb>
              <Placeholder width={30} height={10} />
            </Breadcrumb>
            <FontAwesomeIcon icon={faChevronRight} />
            <Breadcrumb>
              <Placeholder width={60} height={10} />
            </Breadcrumb>
          </Breadcrumbs>
          <Placeholder width={150} height={34} />
        </Title>

        <Placeholder width={150} height={34} />
      </Header>

      <Card>
        <Row
          style={{
            height: '150px',
          }}
        >
          <Col
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <CompanyBillingInfo>
              <Placeholder width={150} height={28} />
              <div
                style={{
                  marginTop: '8px',
                }}
              >
                <Placeholder width={400} height={15} />
                <Placeholder width={180} height={15} />
                <Placeholder width={180} height={15} />
              </div>
            </CompanyBillingInfo>
          </Col>
          <Col
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Placeholder width={90} height={90} circle />
          </Col>
        </Row>

        <Separator />

        <Row
          style={{
            height: '100px',
          }}
        >
          <Col
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <UserBillingInfo>
              <Placeholder width={75} height={17} />

              <div
                style={{
                  marginTop: '8px',
                }}
              >
                <Placeholder width={75} height={15} />
                <Placeholder width={130} height={15} />
                <Placeholder width={100} height={15} />
              </div>
            </UserBillingInfo>
          </Col>

          <Col
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <OrderStatus>
              <Placeholder width={150} height={15} />

              <Placeholder width={150} height={15} />
            </OrderStatus>
          </Col>
        </Row>

        <Row className="mt-4">
          <Col className="d-flex justify-content-center flex-column">
            <Placeholder height="233px" width="100%" />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default TicketDetailPlaceholder;
