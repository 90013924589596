import styled, { css } from 'styled-components';

export const Buttons = styled.div`
  margin-top: 100px;

  display: flex;
  flex-direction: column;

  button {
    font-size: 14px;
    text-transform: none;
    margin-top: 30px;
  }
`;

export const AvaiableDomains = styled.ul`
  width: 100%;
`;

export const AvaiableDomain = styled.li`
  background: white;
  padding: 16px 12px;
  margin-bottom: 9px;

  font-size: 16px;
  color: ${props => props.theme.interface5};
  border-radius: 5px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  border: 2px solid transparent;
  cursor: pointer;

  &:hover {
    border: 2px solid
      ${props =>
        props.theme.darkMode
          ? props.theme.interface5
          : props.theme.brand_primary_color};
  }

  ${props =>
    props.selected &&
    css`
      border: 2px solid
        ${props.theme.darkMode
          ? props.theme.interface5
          : props.theme.brand_primary_color};
    `}

  p {
    font-size: 12px;
    color: ${props => props.theme.error};
    font-style: italic;
  }
`;
