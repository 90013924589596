import React, { useState, useContext, useEffect } from 'react';
import { withTheme } from 'styled-components';

import { Input, Field, Error } from '@cloudez/cloudez-design-system';

import { Tab, Tabs } from 'components/Tabs';

import DomainBuy from 'components/DomainBuy';
import { WebsiteCreateContext } from '../_context/state';
import { Wrapper, Card } from './styles';

const Domain = ({ errors, buy, setBuy }) => {
  const { website, updateWebsiteField } = useContext(WebsiteCreateContext);
  const [tab, setTab] = useState(1);
  const [domain, setDomain] = useState('');
  const [buyDomain, setBuyDomain] = useState(null);

  useEffect(() => {
    if (buyDomain) {
      const values = website.values.map(v =>
        v.slug === 'domain' ? { ...v, value: buyDomain.domain } : v,
      );
      updateWebsiteField('values', values);
      setBuy(buyDomain);
    }
    // eslint-disable-next-line
  }, [buyDomain]);

  return (
    <Wrapper data-id="websites-domain">
      <Tabs>
        <Tab
          active={tab === 1}
          onClick={() => {
            setTab(1);
          }}
        >
          Sim, vou usar um meu
        </Tab>
        <Tab
          active={tab === 2}
          onClick={() => {
            setTab(2);
          }}
        >
          Não, vou comprar um domínio
        </Tab>
      </Tabs>
      <Card>
        {tab === 1 && (
          <Field>
            <Input
              block
              placeholder="Digite seu domínio..."
              name="domain"
              onChange={e => {
                const values = website.values.map(v =>
                  v.slug === 'domain' ? { ...v, value: e.target.value } : v,
                );
                setBuy(false);
                updateWebsiteField('values', values);
              }}
              error={!!errors?.values?.domain}
            />
            {errors?.values?.domain && (
              <Error>{errors?.values?.domain[0]}</Error>
            )}
          </Field>
        )}

        {tab === 2 && (
          <DomainBuy
            domain={domain}
            setDomain={setDomain}
            setBuyDomain={setBuyDomain}
            buyDomain={buyDomain}
          />
        )}
      </Card>
    </Wrapper>
  );
};

export default Domain;
