import React, { useEffect, useRef, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';

import { SquareCheckbox } from 'components/NewComponents';
import StatusBox from 'components/StatusBox';

import { DropdownList } from './styles';

type Filters = {
  status?: string;
  target?: string;
  app_type?: string;
};

type AppType = {
  slug: string;
  name: string;
};

interface MigrationFiltersProps {
  show: boolean;
  setShow: (value: boolean) => void;
  filters: Filters;
  search: string;
  types: AppType[];
  setFilters: (value: Filters) => void;
  page: number | string | string[];
  orderValue: any;
  getMigrations: (
    page: number | string | string[],
    search: string,
    filters: Filters,
    order: any,
  ) => Promise<void>;
}

const MigrationFilters = ({
  show,
  setShow,
  types,
  getMigrations,
  page,
  orderValue,
  search,
  filters,
  setFilters,
}: MigrationFiltersProps): JSX.Element => {
  const catMenu = useRef(null);

  const closeOpenMenus = e => {
    if (catMenu?.current && show && !catMenu?.current.contains(e.target)) {
      setShow(false);
    }
  };

  const status = [
    { value: 1, label: 'Em fila' },
    { value: 2, label: 'Migrando' },
    { value: 4, label: 'Finalizado' },
    { value: 5, label: 'Cancelado' },
    { value: 6, label: 'Erro' },
    { value: 7, label: 'Validando' },
  ];

  document.addEventListener('mousedown', closeOpenMenus);

  const [selectedAppTypes, setSelectedAppTypes] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const [selectedTypes, setSelectedTypes] = useState([]);

  const teste = [
    {
      value: 'websites',
      label: 'Websites',
    },
    {
      value: 'emails',
      label: 'Emails',
    },
  ];

  const handleCheckAppTypes = event => {
    let updatedList = [...selectedAppTypes];
    if (event.target.checked) {
      updatedList = [...selectedAppTypes, event.target.name];
    } else {
      updatedList.splice(selectedAppTypes.indexOf(event.target.name), 1);
    }
    setFilters({ ...filters, app_type: updatedList.join() });
    setSelectedAppTypes(updatedList);
  };

  const handleCheckTypes = event => {
    let updatedList = [...selectedTypes];
    if (event.target.checked) {
      updatedList = [...selectedTypes, event.target.name];
    } else {
      updatedList.splice(selectedTypes.indexOf(event.target.name), 1);
    }
    setFilters({ ...filters, target: updatedList.join() });
    setSelectedTypes(updatedList);
  };

  const handleCheckStatus = value => {
    let valores = value;

    if (value === 2 || value === 3) {
      valores = '2,3';
    }
    let updatedList = [...selectedStatus];
    if (!selectedStatus?.includes(valores)) {
      updatedList = [...selectedStatus, valores];
    } else {
      updatedList.splice(selectedStatus.indexOf(valores), 1);
    }
    setFilters({ ...filters, status: updatedList.join() });
    setSelectedStatus(updatedList);
  };

  useEffect(() => {
    if (Object.values(filters).length > 0) {
      getMigrations(page, search, filters, orderValue);
    }
  }, [filters]);

  return (
    <div
      style={{
        display: show ? 'flex' : 'none',
        position: 'absolute',
        zIndex: 2,
      }}
    >
      <div style={{ position: 'fixed', inset: '0' }}>
        <DropdownList ref={catMenu}>
          <div className="header">
            <FontAwesomeIcon onClick={() => setShow(false)} icon={faFilter} />
            <span>Filtros</span>
          </div>
          <div className="filters">
            <ul>
              <li className="categories">
                <span className="title">Tipo</span>
                <ul className="types">
                  {teste.map(item => (
                    <li>
                      <SquareCheckbox
                        onChange={handleCheckTypes}
                        name={item.value}
                      />
                      {item.label}
                    </li>
                  ))}
                </ul>
              </li>
              <li className="categories">
                <span className="title">Aplicação</span>
                <ul className="appTypes">
                  {types?.map(item => (
                    <li>
                      <SquareCheckbox
                        name={item.slug}
                        onChange={handleCheckAppTypes}
                      />
                      {item.name}
                    </li>
                  ))}
                </ul>
              </li>
              <li className="categories">
                <span className="title">Status</span>
                <ul className="status">
                  {status?.map(item => (
                    <StatusBox
                      selected={selectedStatus.includes(
                        item.value === 2 || item.value === 3
                          ? '2,3'
                          : item.value,
                      )}
                      onClick={() => handleCheckStatus(item.value)}
                      style={{ cursor: 'pointer' }}
                      status={item.value}
                    >
                      {item.label}
                    </StatusBox>
                  ))}
                </ul>
              </li>
            </ul>
          </div>
        </DropdownList>
      </div>
    </div>
  );
};

export default MigrationFilters;
