import React, { createContext, useReducer } from 'react';
import reducer from './reducer';
import {
  setLoading,
  updateField,
  updateNewDomain,
  createDomain,
} from './actions';

export interface DomainCreateState {
  newDomain: {
    domain: string | null;
    domain_do_hire: boolean;
  };
  errors: any;
  setLoading(value: boolean): void;
  createDomain(domain: any): Promise<void>;
  updateField(field: string, value: any): void;
  updateNewDomain(domain: any): void;
}

// initial state
const initialState = {
  newDomain: {
    domain: null,
    domain_do_hire: false,
  },
  errors: null,
};

// context
export const DomainCreateContext = createContext<DomainCreateState>(
  initialState as DomainCreateState,
);

// provider
export const DomainCreateProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(
    reducer,
    initialState as DomainCreateState,
  );

  return (
    <DomainCreateContext.Provider
      value={{
        // state
        newDomain: state.newDomain,
        errors: state.errors,

        // actions
        setLoading: value => setLoading(dispatch, value),
        createDomain: async domain => await createDomain(dispatch, domain),
        updateField: (field, value) =>
          updateField(state, dispatch, field, value),
        updateNewDomain: domain => updateNewDomain(dispatch, domain),
      }}
    >
      {children}
    </DomainCreateContext.Provider>
  );
};
