import { toast } from 'react-toastify';
import history from 'services/history';
import {
  getCloudService,
  deleteCloudService,
  updateCloudService,
} from 'services/cloud';
import nProgress from 'nprogress';

export const setLoading = (dispatch, value) => {
  dispatch({
    type: 'SET_LOADING',
    payload: value,
  });
};

export const getCloud = async (dispatch, id) => {
  try {
    nProgress.start();
    const { data } = await getCloudService(id);

    dispatch({
      type: 'GET_CLOUD',
      payload: data,
    });

    nProgress.done();
  } catch (e) {
    if (e.response.status === 404) {
      toast.error('Cloud não encontrado');
      history.push('/404');
    }
  }
};

export const patchCloud = async (dispatch, field, value, id) => {
  setLoading(dispatch, true);
  try {
    const { data } = await updateCloudService(id, {
      [field]: value,
    });

    dispatch({
      type: 'PATCH_CLOUD',
      payload: data,
    });

    setLoading(dispatch, false);
    toast.success('Cloud atualizado com sucesso.');
  } catch (error) {
    dispatch({
      type: 'SET_ERRORS',
      payload: error?.response?.data,
    });

    setLoading(dispatch, false);
    toast.error('Algo não está certo.');
  }
};

export const deleteCloud = async (dispatch, id, motive) => {
  try {
    await deleteCloudService(id, motive);

    toast.success('Cloud removido com sucesso');
    history.push('/clouds');
  } catch (e) {
    if (e.response.status === 404) {
      toast.error('Cloud não encontrado');
      history.push('/404');
    }
  }
};
