import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  useMemo,
} from 'react';
import { Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';

import { Label, Button, Field } from '@cloudez/cloudez-design-system';

import {
  faPlusSquare,
  faKey,
  faSpinner,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons';
import { NoResultsDetail } from 'components/Common';
import { AddCard, AddButton } from 'components/AddCard';
import generatePassword from 'utils/generatePassword';
import {
  getDatabaseUsersService,
  updateDatabaseUserService,
  createDatabaseUserService,
  deleteDatabaseUserService,
} from 'services/database';
import { CustomInput, CustomSelect } from 'components/NewComponents';
import UserPlaceholder from './UserPlaceholder';
import { DatabaseDetailContext } from '../_context/state';
import { UserCard, Username, Host, UserDetail, Existent } from './styles';

const Usuarios: React.FC = () => {
  const { database } = useContext(DatabaseDetailContext);

  const [createLoading, setCreateLoading] = useState(false);
  const [databaseUsers, setDatabaseUsers] = useState(null);
  const [cloudUsers, setCloudUsers] = useState(null);
  const [errors, setErrors] = useState({} as any);
  const [selectedUser, setUser] = useState(null);
  const [addUser, setAddUser] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showPw, setShowPw] = useState(false);

  const [choose, setChoose] = useState(false);
  const [selectedAddUser, setSelectedAddUser] = useState<any>(0);

  const [newUser, setNewUser] = useState({
    databases: [database?.id],
    username: '',
    password: '',
    host: null,
  } as any);

  const addUserToDatabase = useCallback(async () => {
    setCreateLoading(true);
    try {
      const [user] = cloudUsers.filter(
        user => user?.id === Number(selectedAddUser),
      );

      setDatabaseUsers([...databaseUsers, user]);

      const databases = [...user.databases, database?.id];

      await updateDatabaseUserService(user?.id, { databases });

      toast.success('Usuário adicionado.');
      setAddUser(false);
      setCreateLoading(false);
      setUser(null);
    } catch (e) {
      setCreateLoading(false);
    }
  }, [cloudUsers, selectedAddUser, databaseUsers, database]);

  const accessOptionsCreate = [
    { value: null, title: 'Selecione' },
    { value: '127.0.0.1', title: 'Acesso local' },
    {
      value: newUser?.host !== '127.0.0.1' ? newUser?.host : '0.0.0.0',
      title: 'Acesso remoto',
    },
  ];

  const accessOptionsEdit = [
    { value: null, title: 'Selecione' },
    { value: '127.0.0.1', title: 'Acesso local' },
    {
      value:
        selectedUser?.host !== '127.0.0.1' ? selectedUser?.host : '0.0.0.0',
      title: 'Acesso remoto',
    },
  ];

  const userOptions = cloudUsers?.map(user => {
    const ids = databaseUsers?.map(u => u?.id);
    return (
      !ids.includes(user?.id) && {
        value: user?.id,
        title: user.username,
      }
    );
  });

  const createUser = useCallback(async () => {
    setCreateLoading(true);
    try {
      setErrors([]);
      const { data } = await createDatabaseUserService(newUser);
      setDatabaseUsers([data, ...databaseUsers]);
      setAddUser(false);
      setUser(data);
      toast.success('Usuário criado com sucesso.');
      setNewUser({
        id: 9999999,
        databases: [database?.id],
        username: '',
        password: '',
        host: '',
      });
      setCreateLoading(false);
    } catch (error) {
      setCreateLoading(false);
      setErrors(error?.response?.data);
    }
  }, [newUser, databaseUsers, database]);

  const editUser = useCallback(async () => {
    setCreateLoading(true);
    try {
      setErrors([]);
      const { data } = await updateDatabaseUserService(selectedUser?.id, {
        ...selectedUser,
      });

      const newUsers = databaseUsers.map(u => (u?.id === data?.id ? data : u));

      setDatabaseUsers(newUsers);

      setUser(data);
      toast.success('Usuário atualizado com sucesso.');
      setCreateLoading(false);
    } catch (error) {
      setCreateLoading(false);
      setErrors(error?.response?.data);
    }
  }, [selectedUser, databaseUsers]);

  const deleteUser = useCallback(async () => {
    await deleteDatabaseUserService(selectedUser?.id);
    const newUsers = databaseUsers.filter(u => u?.id !== selectedUser?.id);
    setDatabaseUsers(newUsers);
    setUser(newUsers[0]);
    toast.success('Usuário removido com sucesso.');
  }, [databaseUsers, selectedUser]);

  const onAddUserClick = useCallback(() => {
    setAddUser(!addUser);
    setNewUser({
      databases: [database?.id],
      username: '',
      password: '',
      host: null,
    });
    setUser(newUser);
  }, [addUser, newUser]);

  const onEditUserClick = useCallback(user => {
    setAddUser(false);
    setUser(user);
  }, []);

  const newSelected = useMemo(
    () => selectedUser && newUser?.id === selectedUser?.id,
    [selectedUser, newUser],
  );

  useEffect(() => {
    async function getDatabaseUsers() {
      const {
        data: { results },
      } = await getDatabaseUsersService({
        apps: database?.id,
        // cloud: database.cloud,
      });

      setDatabaseUsers(results);
      setUser(results[0]);

      setLoading(false);
    }

    if (!databaseUsers) getDatabaseUsers();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function getDatabaseCloudUsers() {
      const {
        data: { results },
      } = await getDatabaseUsersService({
        cloud: database.cloud,
        page_size: 100,
      });

      setCloudUsers(results);
    }

    if (databaseUsers && !cloudUsers) getDatabaseCloudUsers();
    // eslint-disable-next-line
  }, [databaseUsers]);

  return (
    <Row>
      <Col lg="6">
        <Row>
          <Col sm="6">
            {!addUser ? (
              <AddCard onClick={onAddUserClick} width="100%" height="50px">
                <AddButton row>
                  <FontAwesomeIcon icon={faPlusSquare} />
                  <span>criar</span>
                </AddButton>
              </AddCard>
            ) : (
              <UserCard selected={!!newSelected}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <FontAwesomeIcon icon={faUserCircle} />
                  <Username>{newUser.username}</Username>
                </div>

                <Host>
                  <span>
                    {newUser.host === '127.0.0.1'
                      ? 'Acesso local'
                      : newUser?.host?.length > 0
                      ? 'Acesso remoto'
                      : null}
                  </span>
                </Host>
              </UserCard>
            )}
          </Col>
          {loading ? (
            <UserPlaceholder />
          ) : databaseUsers?.length > 0 ? (
            databaseUsers.map(user => {
              return (
                <Col sm="6" key={user?.id}>
                  <UserCard
                    onClick={() => onEditUserClick(user)}
                    selected={selectedUser && user?.id === selectedUser?.id}
                  >
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <FontAwesomeIcon icon={faUserCircle} />
                      <Username>{user.username}</Username>
                    </div>
                    <Host>
                      <span>
                        {user.host === '127.0.0.1'
                          ? 'Acesso local'
                          : user?.host?.length > 0
                          ? 'Acesso remoto'
                          : null}
                      </span>
                    </Host>
                  </UserCard>
                </Col>
              );
            })
          ) : (
            <Col sm="6">
              <NoResultsDetail row width="100%" height="50px">
                <FontAwesomeIcon icon={faUserCircle} />
                Nenhum usuário vinculado.
              </NoResultsDetail>
            </Col>
          )}
        </Row>
      </Col>
      <Col lg="6">
        <UserDetail>
          {addUser ? (
            <>
              {!choose ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '20px',
                  }}
                >
                  <div>
                    <Existent onClick={() => setChoose(true)}>
                      Escolher existente
                    </Existent>
                  </div>
                  <CustomInput
                    name="username"
                    label="Usuário"
                    block
                    value={newUser.username}
                    onChange={e =>
                      setNewUser({ ...newUser, username: e.target.value })
                    }
                    error={errors?.username && errors?.username[0]}
                  />
                  <CustomInput
                    name="password"
                    label="Senha"
                    icon={[
                      {
                        svg: faKey,
                        isRight: true,
                        action: () => {
                          const pass = generatePassword();

                          setNewUser({ ...newUser, password: pass });
                        },
                      },
                      {
                        svg: !showPw ? faEye : faEyeSlash,
                        action: () => setShowPw(!showPw),
                        isRight: true,
                      },
                    ]}
                    block
                    value={newUser.password}
                    onChange={e =>
                      setNewUser({ ...newUser, password: e.target.value })
                    }
                    error={errors?.password && errors?.password[0]}
                    type={showPw ? 'text' : 'password'}
                  />

                  <CustomSelect
                    block
                    name="accessOptions"
                    label="Opção de acesso"
                    value={
                      newUser.host === null
                        ? accessOptionsCreate[0]
                        : newUser.host
                    }
                    error={errors?.host && errors?.host[0]}
                    onChange={e => {
                      setNewUser({
                        ...newUser,
                        host:
                          e.target.value === 'Selecione'
                            ? null
                            : e.target.value,
                      });
                    }}
                    options={accessOptionsCreate}
                  />
                  <div
                    style={{
                      display:
                        newUser.host === '0.0.0.0'
                          ? 'flex'
                          : newUser.host === '127.0.0.1' ||
                            newUser.host === null
                          ? 'none'
                          : 'flex',
                    }}
                  >
                    <div style={{ width: '100%' }}>
                      <CustomInput
                        name="ip"
                        label="informe o(S) IP(s) para acesso"
                        block
                        value={newUser.host}
                        onChange={e => {
                          setNewUser({ ...newUser, host: e.target.value });
                        }}
                        error={errors?.host && errors?.host[0]}
                      />
                      {newUser.host === '0.0.0.0' && (
                        <Label
                          style={{ marginBottom: '0px', marginTop: '5px' }}
                        >
                          (o IP 0.0.0.0 é permitido mas não recomendado)
                        </Label>
                      )}
                    </div>
                  </div>
                  <Button
                    width="123px"
                    style={{ fontSize: '12px' }}
                    onClick={createUser}
                    disabled={createLoading}
                    icon
                  >
                    {createLoading ? (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                      'Criar usuário'
                    )}
                  </Button>
                </div>
              ) : (
                <>
                  <Field
                    style={{
                      marginTop: '8px',
                    }}
                  >
                    <Label>Selecionar um usuário</Label>
                    <Existent onClick={() => setChoose(false)}>
                      Criar novo
                    </Existent>
                    <CustomSelect
                      name="addUser"
                      height="40px"
                      options={userOptions}
                      value={selectedAddUser}
                      onChange={e => {
                        setSelectedAddUser(e.target.value);
                        setNewUser(
                          cloudUsers.find(
                            u => Number(u?.id) === Number(e.target.value),
                          ),
                        );
                      }}
                    />
                  </Field>
                  <Button
                    disabled={selectedAddUser === 0 || createLoading}
                    icon
                    size="sm"
                    onClick={addUserToDatabase}
                  >
                    {createLoading ? (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                      'Adicionar'
                    )}
                  </Button>
                </>
              )}
            </>
          ) : (
            selectedUser && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '20px',
                }}
              >
                <CustomInput
                  name="password"
                  label="Usuário"
                  block
                  value={selectedUser.username}
                  readOnly
                  background
                />
                <CustomInput
                  name="password"
                  type={showPw ? 'text' : 'password'}
                  label="Senha"
                  icon={[
                    {
                      svg: faKey,
                      isRight: true,
                      action: () => {
                        const pass = generatePassword();

                        setUser({ ...selectedUser, password: pass });
                      },
                    },
                    {
                      svg: !showPw ? faEye : faEyeSlash,
                      action: () => setShowPw(!showPw),
                      isRight: true,
                    },
                  ]}
                  block
                  value={selectedUser.password}
                  onChange={e =>
                    setUser({ ...selectedUser, password: e.target.value })
                  }
                  error={errors?.password && errors?.password[0]}
                />
                <CustomSelect
                  block
                  name="accessOptions"
                  label="Opção de acesso"
                  value={
                    selectedUser.host === null
                      ? accessOptionsCreate[0]
                      : selectedUser.host
                  }
                  error={errors?.host && errors?.host[0]}
                  onChange={e => {
                    setUser({
                      ...selectedUser,
                      host:
                        e.target.value === 'Selecione' ? null : e.target.value,
                    });
                  }}
                  disableOption
                  options={accessOptionsEdit}
                />
                <div
                  style={{
                    display:
                      selectedUser.host === '0.0.0.0'
                        ? 'flex'
                        : selectedUser.host === '127.0.0.1' ||
                          selectedUser.host === null
                        ? 'none'
                        : 'flex',
                  }}
                >
                  <div style={{ width: '100%' }}>
                    <CustomInput
                      name="ip"
                      label="informe o(S) IP(s) para acesso"
                      block
                      value={selectedUser.host}
                      onChange={e => {
                        setUser({ ...selectedUser, host: e.target.value });
                      }}
                      error={errors?.host && errors?.host[0]}
                    />
                    {selectedUser.host === '0.0.0.0' && (
                      <Label style={{ marginBottom: '0px', marginTop: '5px' }}>
                        (o IP 0.0.0.0 é permitido mas não recomendado)
                      </Label>
                    )}
                  </div>
                </div>

                <div
                  style={{
                    display: 'flex',
                  }}
                >
                  <Button
                    width="78px"
                    style={{
                      marginRight: '15px',
                      fontSize: '12px',
                    }}
                    onClick={editUser}
                  >
                    {createLoading ? (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                      'Salvar'
                    )}
                  </Button>
                  <Button
                    style={{ fontSize: '12px' }}
                    error
                    outline
                    height="40px"
                    width="115px"
                    onClick={deleteUser}
                  >
                    Remover Usuário
                  </Button>
                </div>
              </div>
            )
          )}
        </UserDetail>
      </Col>
    </Row>
  );
};

export default Usuarios;
