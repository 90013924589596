/* eslint-disable prettier/prettier */
import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import Cards from 'react-credit-cards';

import { Row, Col } from 'react-bootstrap';

import useDevice from 'hooks/device';

import {
  Field,
  FormInput,
  Label,
  Form,
  Error,
  FormSelect,
  Input,
  Checkbox,
  Button,
} from '@cloudez/cloudez-design-system';
import { Scope } from '@rocketseat/unform';
import clearMask from 'utils/clearMask';

import history from 'services/history';
import {
  getCepService,
  getCreditcardService,
  deleteCreditcardService,
} from 'services/common';
import { getUserBillingService, updateUserBillingService } from 'services/user';
import { getInvoiceService, chargeInvoiceService } from 'services/invoice';
import { createCloudSetupService } from 'services/cloud';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSpinner,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';

import { toast } from 'react-toastify';
import { useAuth } from 'hooks/auth';
import { createCreditCardToken } from 'utils/iugu';
import { useLayout } from 'hooks/layout';
import { useMediaQuery } from '@react-hook/media-query';
import { Flexbox, SquareCheckbox } from 'components/NewComponents';
import { Alert } from 'components/Alert';
import { CloudCreateContext } from '../_context/state';
import { Wrapper, Title, Info, SaveCard } from './styles';
import DetailsCard from '../DetailsCard';

const paymentSchema = Yup.object().shape({
  number: Yup.string().required('Este campo é obrigatório'),
  name: Yup.string().required('Este campo é obrigatório'),
  expiration: Yup.string().required('Este campo é obrigatório'),
  verification_value: Yup.string().required('Este campo é obrigatório'),
});

const billingSchema = Yup.object().shape({
  name: Yup.string().required('Este campo é obrigatório'),
  email: Yup.string()
    .email('Por favor, insira um email válido.')
    .required('Este campo é obrigatório'),
  document_id: Yup.string().required('Este campo é obrigatório'),
  address_number: Yup.string().required('Este campo é obrigatório'),
  phone_number: Yup.string().required('Este campo é obrigatório'),
  country: Yup.string().required('Este campo é obrigatório'),
  address1: Yup.string().required('Este campo é obrigatório'),
  address2: Yup.string().required('Este campo é obrigatório'),
  state: Yup.string().required('Este campo é obrigatório'),
  city: Yup.string().required('Este campo é obrigatório'),
  inscricao_estadual: Yup.string().when('document_id', {
    is: val => val.length > 14,
    then: Yup.string().required('Este campo é obrigatório'),
    otherwise: Yup.string(),
  }),
});

const schema = Yup.object().shape({
  billing: billingSchema,
  payment: paymentSchema,
  // coupon: Yup.string().default(null),
  // recorrencia: Yup.string().default(1).required('Este campo é obrigatório'),
});

const schemaBilling = Yup.object().shape({
  billing: billingSchema,
  // recorrencia: Yup.string().default('1').required('Este campo é obrigatório'),
  // coupon: Yup.string().default(null),
});

interface IPaymentProps {
  setStage: Dispatch<SetStateAction<number>>;
  setLoading: Dispatch<SetStateAction<boolean>>;
  loading: boolean;
}

const Payment: React.FC<IPaymentProps> = ({
  loading,
  setLoading,
  setStage,
}) => {
  const { plan, recurrency, setRecurrency, coupon, planType } =
    useContext(CloudCreateContext);

  const [saveCard, setSaveCard] = useState(false);

  const theme = useLayout();

  const [errors, setErrors] = useState(null);
  const [creditCard, setCreditCard] = useState(null);
  const [billing, setBilling] = useState(null);
  const [billingErrors, setBillingErrors] = useState<any>({});
  const [cardErrors, setCardErrors] = useState<any>({});
  const [tab, setTab] = useState(1);
  const [cep, setCEP] = useState('');
  const [card, setCard] = useState({
    number: '',
    name: '',
    verification_value: '',
    expiration: '',
  });
  const [focus, setFocus] = useState('number');
  const [address, setAddress] = useState({
    state: '',
    address1: '',
    address2: '',
    city: '',
    zipcode: '',
  });
  const [changeCard, setChangeCard] = useState(false);
  // const [recurrency, setRecurrency] = useState('1');
  const [notHasIe, setNotHasIe] = useState(false);

  const [document, setDocument] = useState(0);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [creditCardLoading, setCreditCardLoading] = useState(true);

  const { user } = useAuth();

  const onMyFrameLoad = () => {
    setCreditCardLoading(false);
  };

  const getCEP = async _cep => {
    setBillingErrors((_data: any) => {
      delete _data.zipcode;

      return _data;
    });
    try {
      setLoading(true);
      const { data } = await getCepService(_cep);

      if (data.erro) {
        setBillingErrors(_data => ({
          ..._data,
          zipcode: ['CEP inválido'],
        }));
        return;
      }

      setAddress({
        state: data.uf,
        address1: data.logradouro,
        address2: data.bairro,
        city: data.localidade,
        zipcode: _cep,
      });

      setBillingErrors(_data => ({
        ..._data,
        zipcode: null,
      }));
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    async function getBilling() {
      try {
        const { data } = await getUserBillingService(user.id);

        setBilling({
          ...data,
          phone_number: data?.phone_number?.substring('+55'.length),
        });
        if (data.document_id.length >= 14) setDocument(1);
        if (data.inscricao_estadual === 'ISENTO') {
          setNotHasIe(true);
        }
        setCEP(data.zipcode);
        setAddress({
          state: data.state,
          address1: data.address1,
          address2: data.address2,
          city: data.city,
          zipcode: data.zipcode,
        });
      } catch (e) {
        console.log(e);
      }
    }

    async function getCreditCard() {
      try {
        const { data } = await getCreditcardService();
        setCreditCard(data[0]);
      } catch (e) {
        console.log(e);
      }
    }

    if (!billing) getBilling();
    if (!creditCard) getCreditCard();

    const eventMethod = window.addEventListener
      ? 'addEventListener'
      : 'attachEvent';
    const eventer = window[eventMethod];
    const messageEvent =
      eventMethod === 'attachEvent' ? 'onmessage' : 'message';
    eventer(
      messageEvent,
      e => {
        if (e.data === 'credit_created') {
          getCreditCard();
        }
      },
      false,
    );

    // eslint-disable-next-line
  }, []);

  const deleteCreditCard = async () => {
    setDeleteLoading(true);
    try {
      await deleteCreditcardService(creditCard.id);
      setCreditCard(null);
      setDeleteLoading(false);
    } catch (error) {
      setDeleteLoading(false);
      toast.error(
        error?.response?.data[Object.keys(error?.response?.data)[0]][0],
      );
    }
  };

  const handleSubmit = async d => {
    setLoading(true);
    setCardErrors({});
    setErrors([]);
    setBillingErrors([]);

    let cloudPayload = {
      plan_type: plan.id,
      lifespan_months: recurrency === 'monthly' ? 1 : 12,
      is_turbo: plan?.cloud_size?.category !== 3 && planType === 'turbo',
      coupon: '',
    };

    if (coupon) {
      cloudPayload = {
        ...cloudPayload,
        coupon,
      };
    }

    const number = clearMask(d.billing.phone_number);

    const billingPayload = {
      ...d.billing,
      document_id: clearMask(d.billing.document_id),
      phone_number: `+55${number}`,
      ...address,
      user: user.id,
    };

    try {
      setBillingErrors([]);
      await updateUserBillingService(user.id, billingPayload, 'v3');

      if (tab === 1) {
        if (!creditCard || changeCard) {
          try {
            await createCreditCardToken(d.payment);
          } catch (e) {
            setLoading(false);

            if (e.errors) {
              setCardErrors(e.errors);
              return;
            }
            setErrors(e);
            return;
          }
        }

        try {
          const { data: cloud_setup } = await createCloudSetupService(
            cloudPayload,
          );

          const { data: invoiceData } = await getInvoiceService(
            cloud_setup.invoice,
          );

          const { fbq } = window as any;

          if (fbq) {
            fbq('track', 'Purchase', {
              currency: invoiceData.total.currency,
              value: invoiceData.total.amount,
            });
          }

          await chargeInvoiceService(cloud_setup.invoice);

          setLoading(false);

          if (!saveCard) {
            const { data } = await getCreditcardService();
            deleteCreditcardService(data[0].id);
          }

          history.push(`/cloud/create/success`);
        } catch (e) {
          setErrors(e?.response?.data);
          setLoading(false);
        }
      } else {
        try {
          const { data: cloud_setup } = await createCloudSetupService(
            cloudPayload,
          );

          const { data: invoice } = await getInvoiceService(
            cloud_setup.invoice,
          );
          setLoading(false);

          history.push(`/invoices/${invoice.id}`);
        } catch (e) {
          setErrors(e?.response?.data);
          setLoading(false);
        }
      }
    } catch (e) {
      setLoading(false);
      setBillingErrors(e?.response?.data);
    }
  };

  const { isMobile } = useDevice();
  const widthThreshold = useMediaQuery('(max-width: 1100px)');

  const [hideContent, setHideContent] = useState(false);

  const onToggle = useCallback(
    state => {
      setHideContent(state);
    },
    [hideContent, setHideContent],
  );

  return billing ? (
    <>
      <Form
        schema={
          tab === 1 && (!creditCard || changeCard) ? schema : schemaBilling
        }
        onSubmit={handleSubmit}
        id="infoFormPayment"
        style={{ width: '100%' }}
      >
        <div
          className={`
              d-flex ${
                isMobile ? 'flex-column-reverse' : 'justify-content-between'
              }`}
          style={{
            marginTop: '25px',
            height: '100%',
          }}
        >
          {!hideContent && (
            <div style={{ width: isMobile ? '100%' : '70%' }}>
              <Title>Informações financeiras</Title>
              <Wrapper isFocused>
                <Row
                  style={{
                    margin: '0',
                  }}
                >
                  <Scope path="billing">
                    <Col>
                      <Row>
                        <Col sm="12" md="6">
                          <Field>
                            <Label required>Nome *</Label>
                            <FormInput
                              block="true"
                              name="name"
                              error={!!billingErrors?.name}
                              value={billing?.name}
                              onChange={e =>
                                setBilling({ ...billing, name: e.target.value })
                              }
                            />
                            {billingErrors?.name && (
                              <Error>{billingErrors?.name[0]}</Error>
                            )}
                          </Field>
                        </Col>
                        <Col sm="12" md="6">
                          <Field>
                            <Label required>Email financeiro *</Label>
                            <FormInput
                              block="true"
                              placeholder="cliente@company.com"
                              type="email"
                              name="email"
                              value={billing.email}
                              onChange={e =>
                                setBilling({
                                  ...billing,
                                  email: e.target.value,
                                })
                              }
                              error={!!billingErrors.email}
                            />
                            {billingErrors.email && (
                              <Error>{billingErrors.email[0]}</Error>
                            )}
                          </Field>
                        </Col>
                        <Col sm="6">
                          <Field>
                            <Label>
                              <span
                                style={{
                                  display: 'inline',
                                  cursor: 'pointer',
                                  color:
                                    document === 0
                                      ? theme.brand_primary_color
                                      : theme.interface4,
                                }}
                                onClick={() => setDocument(0)}
                              >
                                CPF
                              </span>
                              /
                              <span
                                style={{
                                  display: 'inline',
                                  cursor: 'pointer',
                                  color:
                                    document === 1
                                      ? theme.brand_primary_color
                                      : theme.interface4,
                                }}
                                onClick={() => setDocument(1)}
                              >
                                CNPJ
                              </span>
                            </Label>

                            <InputMask
                              mask={
                                document === 0
                                  ? '999.999.999-999'
                                  : '99.999.999/9999-99'
                              }
                              maskChar={null}
                              block="true"
                              name="document_id"
                              error={!!billingErrors.document_id}
                              value={billing.document_id}
                              onChange={e => {
                                if (e.target.value.length >= 15) {
                                  setDocument(1);
                                } else {
                                  setDocument(0);
                                }

                                setBilling({
                                  ...billing,
                                  document_id: clearMask(e.target.value),
                                });
                              }}
                            >
                              {inputProps => <FormInput {...inputProps} />}
                            </InputMask>
                            {billingErrors.document_id && (
                              <Error>{billingErrors.document_id[0]}</Error>
                            )}
                          </Field>
                        </Col>
                        <Col xl="6">
                          {document === 1 && (
                            <Field style={{ marginBottom: 0 }}>
                              <Label required>Inscrição estadual</Label>
                              <FormInput
                                block
                                value={billing?.inscricao_estadual}
                                name="inscricao_estadual"
                                onChange={e => {
                                  setBilling({
                                    ...billing,
                                    inscricao_estadual: e.target.value,
                                  });
                                }}
                                disabled={notHasIe}
                                error={billingErrors?.inscricao_estadual}
                              />
                              <Field
                                style={{
                                  marginTop: '2px',
                                  marginBottom: '0',
                                }}
                              >
                                <Label
                                  style={{
                                    fontSize: '11px',
                                  }}
                                >
                                  Não possuo inscrição estadual *
                                </Label>
                                <Checkbox
                                  checked={notHasIe}
                                  value={notHasIe}
                                  onChange={e => {
                                    setNotHasIe(e.target.checked);

                                    if (e.target.checked === true) {
                                      setBilling({
                                        ...billing,
                                        inscricao_estadual: 'ISENTO',
                                      });
                                    } else {
                                      setBilling({
                                        ...billing,
                                        inscricao_estadual: '',
                                      });
                                    }
                                  }}
                                />
                              </Field>
                              {billingErrors?.inscricao_estadual && (
                                <Error>
                                  {billingErrors?.inscricao_estadual[0]}
                                </Error>
                              )}
                            </Field>
                          )}
                        </Col>
                        <Col xl={6}>
                          <Field>
                            <Label required>CEP *</Label>
                            <InputMask
                              mask="99999-999"
                              value={cep}
                              maskChar={null}
                              autoFocus
                              onChange={e => {
                                const cep = e.target.value;
                                setCEP(cep);
                                setAddress({
                                  ...address,
                                  zipcode: e.target.value,
                                });
                                if (cep.length >= 9) {
                                  getCEP(cep.replace(/\.|-|\//g, ''));
                                }
                              }}
                              block="true"
                              error={!!billingErrors.zipcode}
                            >
                              {inputProps => {
                                return <Input {...inputProps} />;
                              }}
                            </InputMask>
                            {billingErrors.zipcode && (
                              <Error>{billingErrors?.zipcode[0]}</Error>
                            )}
                          </Field>
                        </Col>
                        <Col xl="3">
                          <Field>
                            <Label required>Número *</Label>
                            <FormInput
                              block="true"
                              name="address_number"
                              error={!!billingErrors.address_number}
                              value={billing.address_number}
                              onChange={e =>
                                setBilling({
                                  ...billing,
                                  address_number: e.target.value,
                                })
                              }
                            />
                            {billingErrors.address_number && (
                              <Error>{billingErrors.address_number[0]}</Error>
                            )}
                          </Field>
                        </Col>
                        <Col xl="3">
                          <Field>
                            <Label required>Telefone *</Label>
                            <InputMask
                              mask="(99) 99999-9999"
                              maskChar={null}
                              block="true"
                              name="phone_number"
                              value={billing?.phone_number}
                              error={!!billingErrors.phone_number}
                              onChange={e =>
                                setBilling({
                                  ...billing,
                                  phone_number: e.target.value,
                                })
                              }
                            >
                              {inputProps => <FormInput {...inputProps} />}
                            </InputMask>
                            {billingErrors.phone_number && (
                              <Error>{billingErrors.phone_number[0]}</Error>
                            )}
                          </Field>
                        </Col>
                      </Row>

                      <Row>
                        <Col xl="6">
                          <Field>
                            <Label required>País *</Label>
                            <FormInput
                              block="true"
                              name="country"
                              value={billing.country}
                              onChange={e =>
                                setBilling({
                                  ...billing,
                                  country: e.target.value,
                                })
                              }
                              error={!!billingErrors.country}
                            />
                            {billingErrors.country && (
                              <Error>{billingErrors.country[0]}</Error>
                            )}
                          </Field>
                        </Col>
                        <Col>
                          <Field>
                            <Label>Bairro</Label>
                            <FormInput
                              disabled={address?.zipcode ? undefined : true}
                              background={address?.zipcode ? undefined : true}
                              onChange={e =>
                                setAddress({
                                  ...address,
                                  address2: e.target.value,
                                })
                              }
                              block="true"
                              value={address.address2}
                              name="address2"
                            />
                          </Field>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Field>
                            <Label>Endereço</Label>
                            <FormInput
                              disabled={address?.zipcode ? undefined : true}
                              background={address?.zipcode ? undefined : true}
                              onChange={e =>
                                setAddress({
                                  ...address,
                                  address1: e.target.value,
                                })
                              }
                              block="true"
                              value={address.address1}
                              name="address1"
                            />
                          </Field>
                        </Col>
                        <Col xl="2">
                          <Field>
                            <Label>Estado</Label>
                            <FormSelect
                              options={[
                                { title: 'Acre', id: 'AC' },
                                { title: 'Alagoas', id: 'AL' },
                                { title: 'Amapá', id: 'AP' },
                                { title: 'Amazonas', id: 'AM' },
                                { title: 'Bahia', id: 'BA' },
                                { title: 'Ceará', id: 'CE' },
                                { title: 'Distrito Federal', id: 'DF' },
                                { title: 'Espírito Santo', id: 'ES' },
                                { title: 'Goias', id: 'GO' },
                                { title: 'Maranhão', id: 'MA' },
                                { title: 'Mato Grosso', id: 'MT' },
                                { title: 'Mato Grosso do Sul', id: 'MS' },
                                { title: 'Minas Gerais', id: 'MG' },
                                { title: 'Pará', id: 'PA' },
                                { title: 'Paraíba', id: 'PB' },
                                { title: 'Paraná', id: 'PR' },
                                { title: 'Pernambuco', id: 'PE' },
                                { title: 'Piauí', id: 'PI' },
                                { title: 'Rio de Janeiro', id: 'RJ' },
                                { title: 'Rio Grande do Norte', id: 'RN' },
                                { title: 'Rio Grande do Sul', id: 'RS' },
                                { title: 'Rondônia', id: 'RO' },
                                { title: 'Roraima', id: 'RR' },
                                { title: 'Santa Catarina', id: 'SC' },
                                { title: 'São Paulo', id: 'SP' },
                                { title: 'Sergipe', id: 'SE' },
                                { title: 'Tocantins', id: 'TO' },
                              ]}
                              disabled={!address?.zipcode}
                              onChange={e =>
                                setAddress({
                                  ...address,
                                  state: e.target.value,
                                })
                              }
                              block
                              value={address.state}
                              name="state"
                            />
                          </Field>
                        </Col>
                        <Col>
                          <Field>
                            <Label>Cidade</Label>
                            <FormInput
                              disabled={address?.zipcode ? undefined : true}
                              background={address?.zipcode ? undefined : true}
                              onChange={e =>
                                setAddress({
                                  ...address,
                                  city: e.target.value,
                                })
                              }
                              block="true"
                              value={address.city}
                              name="city"
                            />
                          </Field>
                        </Col>
                      </Row>
                    </Col>
                  </Scope>
                </Row>
              </Wrapper>
              {/* </Col> */}
              {/* <Col xs="12" lg="12"> */}
              <Title>
                Informações de pagamento
                {/* <p>
                    Só é possível realizar pagamentos por boleto caso não haja um cartão de crédito cadastrado
                  </p> */}
              </Title>
              <Wrapper
                isFocused
                tab
                selected={tab === 1}
                onClick={() => setTab(1)}
              >
                <div className="selection">
                  <div className="radio" />
                  <span>Cartão de crédito</span>
                </div>
                <div className="content">
                  {creditCard && !changeCard ? (
                    <>
                      <Field>
                        <Label>Cartão de crédito</Label>
                        <FormSelect
                          value={1}
                          height="40px"
                          name="creditCard"
                          options={[
                            {
                              id: '1',
                              title: `${creditCard.brand} ${creditCard.display_number}`,
                            },
                          ]}
                        />
                      </Field>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                          height: '25px',
                          fontSize: '11px',
                          marginBottom: '20px',
                        }}
                      >
                        <Button
                          form="b"
                          outline
                          style={{
                            width: '120px',
                            padding: '0 10px',
                            fontSize: '11px',
                            height: '100%',
                          }}
                          onClick={() => {
                            setChangeCard(!changeCard);
                            setRecurrency('monthly');
                          }}
                        >
                          Trocar cartão
                        </Button>
                        <Button
                          form="b"
                          error
                          style={{
                            width: '120px',
                            padding: '0px 10px',
                            fontSize: '11px',
                            height: '100%',
                          }}
                          disabled={deleteLoading}
                          onClick={() => {
                            setRecurrency('monthly');
                            deleteCreditCard();
                          }}
                          icon
                        >
                          {deleteLoading ? (
                            <FontAwesomeIcon icon={faSpinner} spin />
                          ) : (
                            'Excluir cartão'
                          )}
                        </Button>
                      </div>
                    </>
                  ) : user.payment_gateway !== 'PagBrasil' ? (
                    <Scope path="payment">
                      <Row>
                        <Col xl="5">
                          <Row>
                            <Col>
                              <Field>
                                <Label>Número do cartão</Label>
                                <InputMask
                                  mask="9999 9999 9999 9999"
                                  maskChar={null}
                                  block="true"
                                  name="number"
                                  onChange={e => {
                                    setCard({
                                      ...card,
                                      number: e.target.value,
                                    });
                                  }}
                                  onFocus={e => {
                                    setFocus(e.target?.name.split('.')[1]);
                                  }}
                                  error={!!cardErrors.number}
                                >
                                  {inputProps => <FormInput {...inputProps} />}
                                </InputMask>

                                {cardErrors.number && (
                                  <Error>Número inválido</Error>
                                )}
                              </Field>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Field>
                                <Label>Nome Impresso no cartão</Label>
                                <FormInput
                                  block="true"
                                  name="name"
                                  onChange={e => {
                                    setCard({
                                      ...card,
                                      name: e.target.value,
                                    });
                                  }}
                                  onFocus={e => {
                                    setFocus(e.target?.name.split('.')[1]);
                                  }}
                                  error={!!cardErrors.last_name}
                                />
                                {cardErrors.last_name && (
                                  <Error>Nome inválido</Error>
                                )}
                              </Field>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <Field>
                                <Label>Validade</Label>
                                <InputMask
                                  mask="99/9999"
                                  maskChar={null}
                                  block="true"
                                  name="expiration"
                                  onChange={e => {
                                    setCard({
                                      ...card,
                                      expiration: e.target.value,
                                    });
                                  }}
                                  onFocus={e => {
                                    setFocus(e.target?.name.split('.')[1]);
                                  }}
                                  error={!!cardErrors.expiration}
                                >
                                  {inputProps => <FormInput {...inputProps} />}
                                </InputMask>

                                {cardErrors.expiration && (
                                  <Error>Data de expiração inválida</Error>
                                )}
                              </Field>
                            </Col>
                            <Col>
                              <Field>
                                <Label>cvc</Label>
                                <FormInput
                                  maxLength="4"
                                  block="true"
                                  name="verification_value"
                                  onChange={e => {
                                    setCard({
                                      ...card,
                                      verification_value: e.target.value,
                                    });
                                  }}
                                  onFocus={e => {
                                    setFocus(e.target?.name.split('.')[1]);
                                  }}
                                  error={!!cardErrors.verification_value}
                                />
                                {cardErrors.verification_value && (
                                  <Error>CVC inválido</Error>
                                )}
                              </Field>
                            </Col>
                          </Row>
                        </Col>
                        <Col
                          xl="7"
                          style={{
                            marginBottom: '10px',
                          }}
                        >
                          <Cards
                            cvc={card.verification_value}
                            expiry={card.expiration}
                            focused={focus}
                            name={card?.name}
                            number={card.number}
                          />
                        </Col>
                        <Col
                          xl="7"
                          style={{
                            marginBottom: '10px',
                          }}
                        >
                          <Flexbox alignItems="baseline" spacing="0px">
                            <SquareCheckbox
                              value={saveCard}
                              checked={saveCard}
                              name="test"
                              onChange={() => setSaveCard(!saveCard)}
                            />
                            <SaveCard>
                              Salvar este cartão para pagamentos futuros
                            </SaveCard>
                          </Flexbox>
                        </Col>
                      </Row>
                      <Row>
                        <Alert info>
                          <FontAwesomeIcon
                            style={{
                              width: '20px',
                              height: '20px',
                              marginRight: '15px',
                            }}
                            icon={faExclamationCircle}
                          />
                          Seu plano será ativado assim que a compra for aprovada
                          pela administradora do seu cartão de crédito
                        </Alert>
                      </Row>
                    </Scope>
                  ) : (
                    <>
                      {creditCardLoading && (
                        <div
                          className="creditCardLoading"
                          style={{
                            height: widthThreshold ? '520px' : '300px',
                          }}
                        >
                          <FontAwesomeIcon icon={faSpinner} spin />
                        </div>
                      )}
                      <iframe
                        title="payment"
                        style={{
                          width: '100%',
                          display: creditCardLoading ? 'none' : 'flex',
                          height: widthThreshold ? '520px' : '300px',
                          overflow: 'none',
                        }}
                        frameBorder="0"
                        // onLoad={onMyFrameLoad}
                        src={`https://payment.cloudez.io/?uuid=${user.uuid}&brand_primary_color=${theme.brand_primary_color}&brand_secondary_color=${theme.brand_secondary_color}&domain=${window.location.origin}`}
                        // src={`http://localhost:3001/?uuid=${user.uuid}&brand_primary_color=${theme.brand_primary_color}&brand_secondary_color=${theme.brand_secondary_color}&domain=${window.location.origin}`}
                      />
                    </>
                  )}
                </div>
              </Wrapper>

              <Wrapper
                isFocused
                tab
                selected={tab === 2}
                onClick={() => setTab(2)}
              >
                <div className="selection">
                  <div className="radio" />
                  <span>Boleto</span>
                </div>
                <div className="content">
                  <p>Um boleto será criado e enviado para seu email.</p>
                  <p>
                    Pagamentos com boleto são <b>identificados em até 72h</b>{' '}
                    pelo nosso sistema
                  </p>
                </div>
              </Wrapper>
              {errors &&
                Object.keys(errors).map(e => (
                  <Error
                    key={e}
                    style={{
                      fontSize: '14px',
                    }}
                  >
                    {errors[e][0]?.length > 1 ? errors[e][0] : errors[e]}
                  </Error>
                ))}
            </div>
          )}

          <div style={{ width: '25%', position: 'sticky', top: '70px' }}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                position: 'sticky',
                top: '10px',
              }}
            >
              <DetailsCard setStage={setStage} onToggle={onToggle} />
              {!isMobile && (
                <Button
                  type="submit"
                  form="infoFormPayment"
                  disabled={loading}
                  style={{
                    marginTop: '20px',
                    width: '273px',
                  }}
                  icon
                >
                  {loading ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    'Finalizar pedido'
                  )}
                </Button>
              )}
            </div>
          </div>
        </div>
      </Form>
    </>
  ) : (
    <div style={{ textAlign: 'center', fontSize: '30px', margin: '60px 0px' }}>
      <FontAwesomeIcon icon={faSpinner} spin />
    </div>
  );
};

export default Payment;
