import { useLayout } from 'hooks/layout';
import React from 'react';

import history from 'services/history';
import { LogoImage, LogoWrapper } from './styles';

interface ILogoProps {
  auth?: boolean;
}

const Logo: React.FC<ILogoProps> = ({ auth = true }) => {
  const { sideMenuCollapsed, logo_reduced, logo_primary } = useLayout();

  return (
    <LogoWrapper
      sideMenuCollapsed={sideMenuCollapsed || !auth}
      onClick={() => history.push('/dashboard')}
    >
      <LogoImage src={sideMenuCollapsed ? logo_reduced : logo_primary} />
    </LogoWrapper>
  );
};

export default Logo;
