import React, { InputHTMLAttributes } from 'react';
import { Label } from '@cloudez/cloudez-design-system';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UseFormRegister } from 'react-hook-form';
import { Input } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  strongLabel?: boolean;
  name: string;
  register?: UseFormRegister<any>;
  required?: boolean;
  error?: any;
  block?: boolean;
  background?: boolean;
  icon?:
    | {
        svg: IconDefinition;
        action?: any;
        isRight?: boolean;
        color?: any;
      }
    | any;
  mb?: string;
}

export const CustomInput = ({
  label,
  name,
  register,
  required,
  error,
  icon,
  strongLabel,
  background,
  ...rest
}: InputProps): JSX.Element => {
  const choosedIcon = icon?.length > 0 && icon?.find(item => item.isRight);

  return (
    <Input icon={!!icon?.svg} error={error} background={background} {...rest}>
      {label && <Label strongLabel={strongLabel}>{label}</Label>}
      <div
        className={`withIcon ${
          icon?.isRight
            ? 'isRight'
            : choosedIcon?.isRight
            ? 'multipleIcons'
            : null
        }`}
      >
        {register ? (
          <input {...register(name, { required })} {...rest} />
        ) : (
          <input {...rest} />
        )}
        {(icon?.length > 0 || icon?.svg) && (
          <div
            className={`icon ${
              icon?.isRight
                ? 'isRight'
                : choosedIcon?.isRight
                ? 'multipleIcons'
                : null
            }`}
          >
            {icon?.length > 0 ? (
              <div
                style={{ display: 'flex', flexDirection: 'row', gap: '9px' }}
              >
                {icon.map(item => (
                  <FontAwesomeIcon
                    spin={item.svg.iconName === 'spinner'}
                    onClick={item?.action}
                    icon={item.svg}
                    style={{ color: item?.color }}
                  />
                ))}
              </div>
            ) : (
              <FontAwesomeIcon
                spin={icon.svg.iconName === 'spinner'}
                onClick={icon?.action}
                icon={icon.svg}
                style={{ color: icon?.color }}
              />
            )}
          </div>
        )}
      </div>

      {error && <span className="error">{error}</span>}
    </Input>
  );
};
