import React from 'react';
import IconComponent from '../icons/IconComponent';

import { Container } from './styles';

interface CustomFilterProps {
  type: 'closed' | 'open';
  spacing?: boolean;
  selected?: boolean;
  onClick?: () => void;
}

export function CustomFilter({
  type,
  spacing,
  selected,
  onClick,
}: CustomFilterProps): JSX.Element {
  const typeFilter = {
    closed: {
      type: selected ? 'closedSelectedIcon' : 'closedIcon',
      alt: 'Tickets fechados',
      text: 'Fechado',
    },
    open: {
      type: selected ? 'openSelectedIcon' : 'openIcon',
      alt: 'Tickets abertos',
      text: 'Aberto',
    },
  }[type];
  return (
    <Container onClick={onClick} selected={selected} spacing={spacing}>
      <IconComponent type={typeFilter.type} />
      <p>{typeFilter.text}</p>
    </Container>
  );
}
