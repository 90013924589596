import React, { createContext, useReducer } from 'react';
import { Ticket } from 'types/ticket';
import { User } from 'types/auth';
import reducer from './reducer';
import {
  setLoading,
  getTicket,
  updateTicket,
  getUser,
  getClouds,
  getTarget,
  getMessages,
  getFiles,
  addMessage,
  setRedAlert,
  addWSMessage,
  pickupTicket,
  changeResponsible,
} from './actions';

export interface TicketDetailState {
  ticket: Ticket;
  user: User;
  clouds: any;
  target: any;
  messages: any;
  files: any;
  errors: any;
  loading: false;

  getTicket(id: string | number): Promise<void>;
  getUser(owner: string | number): Promise<void>;
  getClouds(owner_email: string): Promise<void>;
  getMessages(id: string | number): Promise<void>;
  addMessage(data: any): Promise<void>;
  addWSMessage(message: any): Promise<void>;
  getFiles(files: any): Promise<void>;
  getTarget(target_type: string, target_id: number | string): Promise<void>;
  updateTicket(
    field: string,
    value: any,
    id: string | number,
    ticket?: any,
  ): Promise<void>;
  setLoading(value: boolean): void;
  setRedAlert(id: string | number): Promise<void>;
  pickupTicket(id: string | number): Promise<void>;
  changeResponsible(id: string | number, responsible: string): Promise<void>;
}

// initial state
const initialState = {
  ticket: null,
  user: null,
  clouds: null,
  target: null,
  messages: null,
  files: null,
  errors: null,
  loading: false,
};

// context
export const TicketDetailContext = createContext<TicketDetailState>(
  initialState as TicketDetailState,
);

// provider
export const TicketDetailProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(
    reducer,
    initialState as TicketDetailState,
  );

  return (
    <TicketDetailContext.Provider
      value={{
        // state
        ticket: state.ticket,
        user: state.user,
        clouds: state.clouds,
        target: state.target,
        messages: state.messages,
        files: state.files,
        loading: state.loading,
        errors: state.errors,

        // actions
        getTicket: async id => await getTicket(dispatch, id),
        getUser: async owner => await getUser(dispatch, owner),
        getClouds: async owner_email => await getClouds(dispatch, owner_email),
        getMessages: async id => await getMessages(dispatch, id),
        addMessage: async data => await addMessage(dispatch, data),
        addWSMessage: async message => addWSMessage(dispatch, message),
        getFiles: async files => await getFiles(dispatch, files),
        getTarget: async (target_type, target_id) =>
          await getTarget(dispatch, target_type, target_id),
        updateTicket: async (field, value, id, ticket) =>
          await updateTicket(dispatch, field, value, id, ticket),
        setLoading: value => setLoading(dispatch, value),
        setRedAlert: async id => await setRedAlert(dispatch, id),
        pickupTicket: async id => await pickupTicket(dispatch, id),
        changeResponsible: async (id, responsible) =>
          await changeResponsible(dispatch, id, responsible),
      }}
    >
      {children}
    </TicketDetailContext.Provider>
  );
};
