import styled from 'styled-components';

export const TitleText = styled.span`
  font-weight: 700;
  font-size: 14px;

  color: #a2a9bf;
`;

export const Pulsate = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;

  align-items: center;
  gap: 8px;

  span,
  svg {
    font-size: 16px;
    color: ${props => props.theme.alertNew};
    animation: pulse 1.3s infinite;
  }

  cursor: pointer;

  .icon {
    position: relative;
    div {
      position: absolute;
      right: 2px;
      top: 7px;
      width: 15px;
      height: 15px;
      background-color: ${props => props.theme.alertNew};
      filter: blur(10px);

      border-radius: 100%;
    }
  }

  @-webkit-keyframes pulse {
    0% {
      opacity: 0.3;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.3;
    }
  }

  @keyframes pulse {
    0% {
      opacity: 0.3;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.3;
    }
  }
`;

export const CloudInfo = styled.div`
  height: 100%;
  width: 100%;
  margin-top: -15px;
  line-height: 2.5;
  font-size: 12px;
  color: ${props => props.theme.interface5};

  p {
    span {
      color: ${props => props.theme.interface4};
    }
  }
`;
