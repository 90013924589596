import styled from 'styled-components';

interface RateButtonProps {
  rating: boolean;
}

export const RateButton = styled.div<RateButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 192px;
  height: 176px;
  border: ${props =>
    props.rating
      ? `2px solid ${props.theme.brand_primary_color}`
      : '1px solid #e8eaef'};
  border-radius: 5px;
  cursor: pointer;

  box-shadow: ${props =>
    props.rating && '0px 2px 15px rgba(100, 114, 140, 0.25)'};

  .red {
    color: ${props => props.theme.errorNew};
  }

  .green {
    color: ${props => props.theme.successNew};
  }

  svg {
    width: 81px;
    height: 70px;
  }
`;

export const ContentText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;

  font-size: 12px;
  color: ${props => props.theme.interface5};
  text-align: center;
  margin: 30px auto;
`;
