import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: auto;
`;

interface ITypeProps {
  selected?: boolean;
}
export const Type = styled.div<ITypeProps>`
  cursor: pointer;
  width: 100%;
  height: 110px;

  display: flex;
  flex-direction: column;

  align-items: center;

  border-radius: 5px;

  padding: 10px;

  background: ${props => props.theme.interface2};
  border: 1px solid transparent;

  ${props =>
    props.selected &&
    css`
      border: 1px solid
        ${props =>
          props.theme.darkMode
            ? props.theme.interface5
            : props.theme.brand_primary_color};
    `}

  &:hover {
    transition: border 0.2s ease-in;
    border: 1px solid
      ${props =>
        props.theme.darkMode
          ? props.theme.interface5
          : props.theme.brand_primary_color};
  }

  span {
    margin-top: 8px;
    font-size: 14px;
    color: ${props => props.theme.interface5};
  }

  p {
    margin-top: 4px;
    font-size: 10px;
    color: ${props => props.theme.interface5};
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
