import { toast } from 'react-toastify';
import { getPlanTypesService } from 'services/common';
import { getCloudSizesService } from 'services/cloud';

export const setLoading = (dispatch, value) => {
  dispatch({
    type: 'SET_LOADING',
    payload: value,
  });
};

export const updatePlan = (dispatch, plan) => {
  const { fbq } = window;
  const { gtag } = window;

  if (fbq) {
    fbq('track', 'AddToCart');
  }

  if (gtag) {
    gtag('event', 'track', { event_category: 'AddToCart' });
  }

  dispatch({
    type: 'UPDATE_PLAN',
    payload: plan,
  });
};

export const getPlans = async dispatch => {
  setLoading(dispatch, true);
  try {
    const { data } = await getPlanTypesService({
      page_size: 100,
      category: 1,
    });

    dispatch({
      type: 'GET_PLANS',
      payload: data,
    });
  } catch (e) {
    toast.error(e?.response?.data[Object.keys(e?.response?.data)[0]][0]);
  }
};

export const getBasePlans = async dispatch => {
  try {
    const { data } = await getCloudSizesService();

    dispatch({
      type: 'GET_BASE_PLANS',
      payload: data,
    });

    setLoading(dispatch, false);
  } catch (e) {
    toast.error(e?.response?.data[Object.keys(e?.response?.data)[0]][0]);
  }
};
