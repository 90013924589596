import { toast } from 'react-toastify';
import history from 'services/history';

import { createDomainService } from 'services/domain';

export const setLoading = (dispatch, value) => {
  dispatch({
    type: 'SET_LOADING',
    payload: value,
  });
};

export const updateField = (state, dispatch, field, value) => {
  dispatch({
    type: 'UPDATE_FIELD',
    payload: {
      ...state.newEmail,
      [field]: value,
    },
  });
};

export const updateNewDomain = (dispatch, domain) => {
  dispatch({
    type: 'UPDATE_NEW_DOMAIN',
    payload: domain,
  });
};

export const createDomain = async (dispatch, domain) => {
  try {
    const { data } = await createDomainService(domain);

    if (data.invoice) {
    } else {
      toast.success('Domínio criado com sucesso.');
      history.push(`/domains/${data.id}`);
    }
  } catch (e) {
    dispatch({
      type: 'SET_ERRORS',
      payload: e?.response?.data,
    });
  }
};
