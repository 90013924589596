import styled from 'styled-components';

export const CardHeader = styled.h3`
  font-size: 16px;
  color: ${props => props.theme.interface5};
`;

export const ResourceCard = styled.div`
  height: auto;
  padding: 15px;
  background: ${props => props.theme.interface1};
  border-radius: 5px;

  .type {
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      color: ${props => props.theme.interface5};
    }
  }

  .informations {
    padding: 30px 10px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: 768px) {
      padding: 10px 0px;
    }

    .information {
      svg {
        color: ${props => props.theme.interface5};
        font-size: 18px;
      }
      color: ${props =>
        props.theme.darkMode ? props.theme.interface4 : props.theme.interface6};

      display: flex;
      flex-direction: column;

      align-items: center;
      justify-content: center;

      .title {
        font-weight: 400;
        font-size: 12px;

        margin-top: 10px;

        color: ${props => props.theme.interface5};
      }

      .info {
        font-weight: bold;
        font-size: 16px;

        color: ${props => props.theme.interface6};
      }
    }
  }
`;

export const TitleText = styled.span`
  font-weight: 700;
  font-size: 14px;

  color: #a2a9bf;
`;

export const IsTurbo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0px 5px;
  border-radius: 5px;
  margin-left: 10px;

  user-select: none;

  text-transform: uppercase;

  svg {
    margin-right: 2px;
    height: 13px;
    path,
    ellipse,
    rect,
    circle {
      fill: ${props => props.theme.brand_primary_color};
    }
  }

  font-size: 12px;
  font-weight: 700;
  color: ${props => props.theme.brand_primary_color};
`;
