import React, { useEffect, useState } from 'react';
import { withTheme } from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import * as moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSpinner } from '@fortawesome/free-solid-svg-icons';

import {
  Modal,
  Input,
  Field,
  Label,
  Checkbox,
  Error,
  Button,
} from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Content,
  Footer,
} from 'components/ListingModal/styles';
import { createCloudChangeService, getCloudSizesService } from 'services/cloud';
import { getPlanTypesService } from 'services/common';
import { toast } from 'react-toastify';
import Plan from './Plan';
import { Infos } from './styles';

const ChangePlanModal = ({ theme, action, show, setShow, cloud }) => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [immediately, setImmediately] = useState(true);
  const [date, setDate] = useState(null);
  const [hour, setHour] = useState(null);
  const [minute, setMinute] = useState(null);
  const [loading, setLoading] = useState(false);
  const [plans, setPlans] = useState(null);
  const [basePlans, setBasePlans] = useState(null);
  const [errors, setErrors] = useState();

  useEffect(() => {
    async function getPlans() {
      try {
        const { data } = await getPlanTypesService({
          page_size: 100,
          category: 1,
        });

        setPlans(data.results);
      } catch (e) {
        console.log(e);
      }
    }

    getPlans();
  }, []);

  useEffect(() => {
    async function getBasePlans() {
      try {
        const { data } = await getCloudSizesService();
        setBasePlans(data);
      } catch (e) {
        console.log(e);
      }
    }

    getBasePlans();
  }, []);

  const upgradePlan = async (plan_type, immediately, date, hour, minute) => {
    setErrors({});
    try {
      let payload;

      if (immediately) {
        payload = {
          plan_type,
          node: cloud.id,
          immediately,
        };
      } else {
        payload = {
          plan_type,
          node: cloud.id,
          resize_date: date,
          resize_hour: `${hour}:${minute}:00`,
        };
      }

      await createCloudChangeService(payload);

      setShow(false);
      if (immediately) {
        toast.success('O upgrade será realizado assim que a fatura for paga.');
      } else {
        toast.success('O upgrade será realizado na data marcada.');
      }
    } catch (e) {
      setErrors(e?.response?.data);
      if (e?.response?.data?.non_field_errors) {
        toast.error(e?.response?.data?.non_field_errors[0]);
      }
    }
  };

  return (
    <Modal show={show} setShow={setShow} noExit={loading}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faEdit} />
        </Icon>
        <Text>
          <Title>Trocar plano</Title>
        </Text>
      </Header>

      <Content>
        {plans?.length > 0 ? (
          <>
            <Plan
              plans={plans}
              basePlans={basePlans}
              selectedPlan={selectedPlan}
              setSelectedPlan={setSelectedPlan}
            />
            {errors?.plan_type && (
              <Error
                style={{
                  margin: '10px 0',
                  color: '#d92947',
                }}
              >
                {errors?.plan_type[0]}
              </Error>
            )}

            <Field
              style={{
                marginTop: '10px',
              }}
            >
              <Label>
                Atualizar imediatamente após confirmação de pagamento.
              </Label>
              <Checkbox
                value={immediately}
                checked={immediately}
                onChange={e => setImmediately(e.target.checked)}
              />
            </Field>

            {!immediately && (
              <>
                <p
                  style={{
                    color: theme.interface5,
                    fontSize: '14px',
                    marginBottom: '10px',
                  }}
                >
                  ou
                </p>
                <Row
                  style={{
                    marginTop: '10px',
                  }}
                >
                  <Col sm="6">
                    <Field>
                      <Label>DATA</Label>
                      <Input
                        type="date"
                        block="true"
                        min={moment().format('YYYY/MM/DD')}
                        value={date}
                        onChange={e => setDate(e.target.value)}
                      />
                      {errors?.resize_date && (
                        <Error>{errors?.resize_date[0]}</Error>
                      )}
                    </Field>
                  </Col>
                  <Col sm="3">
                    <Field>
                      <Label>HORA</Label>
                      <Input
                        block="true"
                        value={hour}
                        onChange={e => setHour(e.target.value)}
                      />
                      {errors?.resize_hour && (
                        <Error>{errors?.resize_hour[0]}</Error>
                      )}
                    </Field>
                  </Col>
                  <Col sm="3">
                    <Field>
                      <Label>Minuto</Label>
                      <Input
                        block="true"
                        value={minute}
                        onChange={e => setMinute(e.target.value)}
                      />
                    </Field>
                  </Col>
                </Row>
              </>
            )}
            <Infos>
              <b>IMPORTANTE: </b>
              <p>Seu cloud será atualizado após confirmação de pagamento.</p>
              <p>
                Clouds de até 4G podem levar de 10 a 15 minutos para atualizar.
              </p>
              <p>
                Clouds maiores que 4G podem levar de 30 a 50 minutos para
                atualizar.
              </p>
              <p>
                Seu cloud e aplicações ficarão offline pelo período da
                atualização.
              </p>
            </Infos>
          </>
        ) : (
          <p
            style={{
              textAlign: 'center',
              fontSize: '20px',
              color: theme.interface5,
              fontWeight: 'bold',
              margin: '20px 0px',
            }}
          >
            Não há nenhum plano para upgrade
          </p>
        )}
      </Content>
      <Footer>
        <Button
          disabled={loading || plans?.length === 0}
          onClick={async () => {
            setLoading(true);
            await upgradePlan(selectedPlan, immediately, date, hour, minute);
            setLoading(false);
          }}
          icon
        >
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Alterar'}
        </Button>
      </Footer>
    </Modal>
  );
};

export default withTheme(ChangePlanModal);
