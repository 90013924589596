import React, { useState, useEffect, useCallback, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { Button } from '@cloudez/cloudez-design-system';

import { CreateWrapper, CreateHeader, CreateButtons } from 'components/Common';
import Domain from './Domain';
import Payment from './Payment';
import { DomainCreateProvider, DomainCreateContext } from './_context/state';

const DomainCreate = () => {
  const { newDomain, updateNewDomain, createDomain, errors } =
    useContext(DomainCreateContext);

  const [hire, setHire] = useState(false);
  const [buyDomain, setBuyDomain] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (buyDomain)
      updateNewDomain({
        domain: buyDomain.domain,
        domain_do_hire: true,
      });
    // eslint-disable-next-line
  }, [buyDomain]);

  const _createDomain = useCallback(async () => {
    setLoading(true);
    if (buyDomain) {
      setHire(true);
    } else {
      await createDomain(newDomain);
      setLoading(false);
    }
    // eslint-disable-next-line
  }, [newDomain, buyDomain]);

  return (
    <CreateWrapper>
      <CreateHeader>
        <h1>Você possui um domínio?</h1>
      </CreateHeader>

      {!hire ? (
        <>
          <Domain
            buyDomain={buyDomain}
            setBuyDomain={setBuyDomain}
            errors={errors}
          />
          <CreateButtons>
            <Button icon disabled={loading} onClick={_createDomain}>
              {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Criar'}
            </Button>
          </CreateButtons>
        </>
      ) : (
        <>
          <Payment buyDomain={buyDomain} />
        </>
      )}
    </CreateWrapper>
  );
};

export default props => (
  <DomainCreateProvider>
    <DomainCreate {...props} />
  </DomainCreateProvider>
);
