import React, { InputHTMLAttributes } from 'react';
import { UseFormRegister } from 'react-hook-form';

import { Label } from '@cloudez/cloudez-design-system';

import { TextArea } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  name: string;
  register?: UseFormRegister<any>;
  required?: boolean;
  error?: any;
  block?: boolean;
  background?: boolean;
  height?: string;
}

export const CustomTextArea = ({
  label,
  name,
  register,
  required,
  error,
  background,
  block,
  height,
  width,
  ...rest
}: InputProps): JSX.Element => {
  return (
    <TextArea
      width={width}
      height={height}
      block={block}
      background={background}
      error={error}
    >
      <Label>{label}</Label>
      {register ? (
        <textarea {...register(name, { required })} {...rest} />
      ) : (
        <textarea {...rest} />
      )}

      {error && <span className="error">{error}</span>}
    </TextArea>
  );
};
