import React, { useState } from 'react';

import {
  Footer,
  Icon,
  Text,
  Title,
  Header,
} from 'components/ListingModal/styles';

import { toast } from 'react-toastify';

import { deleteOTPDeviceService, deleteOTPEmailService } from 'services/user';

import { Modal, Button, Form } from '@cloudez/cloudez-design-system';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { Content } from './styles';

interface DeleteOtpModalProps {
  show: boolean;
  setShow: (value: boolean) => void;
  setIsAppActive: (value: boolean) => void;
  setIsEmailActive: (value: boolean) => void;
  type: string;
}

const DeleteOtpModal: React.FC<DeleteOtpModalProps> = ({
  show,
  setShow,
  setIsAppActive,
  setIsEmailActive,
  type,
}) => {
  const [loading, setLoading] = useState(false);

  const confirmDeleteOtp = async () => {
    try {
      setLoading(true);
      if (type === 'email') {
        await deleteOTPEmailService();
        setIsEmailActive(false);
      } else {
        await deleteOTPDeviceService();
        setIsAppActive(false);
      }
      setShow(false);
      setLoading(false);
      toast.success('Autenticação de dois fatores desativada com sucesso');
    } catch {
      setLoading(false);
      toast.error('Não foi possível desativar a autenticação de dois fatores!');
    }
  };

  return (
    <Modal width="497px" show={show} setShow={setShow}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </Icon>
        <Text>
          <Title>
            Deseja desativar a autenticação por{' '}
            {type === 'email' ? 'email' : 'aplicativo'}?
          </Title>
        </Text>
      </Header>

      <Form onSubmit={confirmDeleteOtp}>
        <Content>
          <span>
            <b>Atenção!</b> Ao desativar esta opção, o método de autenticação em
            duas etapas por {type === 'email' ? 'email' : 'aplicativo'} será
            removido da sua conta. Tem certeza de que deseja desativá-la?
          </span>
        </Content>
        <Footer hasLink>
          <Button onClick={() => setShow(false)} type="button" outline>
            Cancelar
          </Button>
          <Button disabled={loading} type="submit">
            {loading ? (
              <FontAwesomeIcon
                style={{ marginLeft: '0' }}
                icon={faSpinner}
                spin
              />
            ) : (
              'Desativar'
            )}
          </Button>
        </Footer>
      </Form>
    </Modal>
  );
};

export default DeleteOtpModal;
