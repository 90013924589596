import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 19px 21px;
  gap: 22px;

  font-size: 16px;
  line-height: 140%;
  /* or 22px */

  .attention {
    text-align: center;
  }
`;

export const InputWithIcon = styled.div`
  display: flex;
  align-items: center;

  svg {
    margin-left: 330px;
    position: absolute;
    cursor: pointer;
  }
`;
