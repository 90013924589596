import styled, { css } from 'styled-components';

export const Card = styled.div`
  width: 100%;
  height: 142px;
  padding: 10px 14px;
  margin-bottom: 20px;
  background: ${props => props.theme.interface2};
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid transparent;

  &:hover {
    border: 1px solid ${props => props.theme.brand_primary_color};
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 26px;

  display: flex;
  align-items: center;
`;

export const Name = styled.div`
  margin-left: 10px;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  font-size: 12px;
  color: ${props => props.theme.interface5};
`;

export const Body = styled.div`
  height: calc(100% - 26px);
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;
