import React from 'react';
import Placeholder from 'components/Placeholder';
import { Col } from 'react-bootstrap';

import { Card } from '../../styles';

const WebsitePlaceholder = () => {
  const placeholders = [];

  for (let i = 1; i <= 6; i++) {
    placeholders.push(i);
  }

  return placeholders.map((p, i) => (
    <Col key={i} lg="4" sm="6" xs="12">
      <Card>
        <Placeholder circle width={45} height={45} />

        <div>
          <span>
            <Placeholder width={100} height={14} />
          </span>
        </div>
        <div>
          <p>
            <Placeholder width={120} height={10} />
          </p>
        </div>
      </Card>
    </Col>
  ));
};

export default WebsitePlaceholder;
