import React, { createContext, useReducer } from 'react';
import { PaginationType } from '@cloudez/cloudez-design-system';
import { Cloud } from 'types/cloud';
import reducer from './reducer';
import { setLoading, getClouds, updateCloud } from './actions';

export interface CloudListingState extends PaginationType {
  clouds: Cloud[];
  loading: boolean;
  errors: any;
  setLoading(value: boolean): void;
  getClouds(
    page: string | number,
    search: string,
    filter: any,
    ctrl: AbortController,
  ): Promise<void>;
  updateCloud(field: string, value: any, id?: number | string): Promise<void>;
}

// initial state
const initialState = {
  clouds: null,
  count: null,
  previous: null,
  current: null,
  next: null,
  loading: true,
  errors: null,
};

// context
export const CloudListingContext = createContext<CloudListingState>(
  initialState as CloudListingState,
);

// provider
export const CloudListingProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(
    reducer,
    initialState as CloudListingState,
  );

  return (
    <CloudListingContext.Provider
      value={{
        // state
        clouds: state.clouds,
        count: state.count,
        previous: state.previous,
        current: state.current,
        next: state.next,
        loading: state.loading,
        errors: state.errors,

        // actions
        setLoading: value => setLoading(dispatch, value),
        getClouds: async (page, search, filter, ctrl) =>
          await getClouds(dispatch, page, search, filter, ctrl),
        updateCloud: async (field, value, id) =>
          await updateCloud(dispatch, field, value, id),
      }}
    >
      {children}
    </CloudListingContext.Provider>
  );
};
