import { Input } from '@cloudez/cloudez-design-system';

import styled, { css } from 'styled-components';

export const Container = styled.div`
  text-align: start;
  position: relative;

  ${Input} {
    padding-left: 30px;
  }

  svg {
    cursor: pointer;
    position: absolute;
    /* transform: translateY(-50%); */
    /* top: 50%; */
    top: 13px;
    left: 10px;
    color: ${props => props.theme.interface4};
  }

  span {
    color: ${props => props.theme.error};
    position: absolute;
    bottom: 5px;
    right: 2%;
    font-size: 9px;
  }
`;

interface ISearchWrapperProps {
  block?: boolean;
  noResults?: boolean;
}

export const SearchWrapper = styled.div<ISearchWrapperProps>`
  opacity: 1;
  position: relative;

  ${props =>
    props.block &&
    css`
      width: 100%;
    `};

  p {
    margin-left: 20px;
    display: ${props => (props.noResults ? 'block' : 'none')};
    font-size: 9px;
    font-style: italic;
    color: ${props => props.theme.error};
  }
`;
