import styled from 'styled-components';
import { Button } from '@cloudez/cloudez-design-system';

export const Info = styled.div`
  height: 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Header = styled.div`
  width: 100%;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    margin-bottom: 40px;

    ${Button} {
      margin-top: 10px;
      width: 100%;
    }
  }
`;

export const Title = styled.h1`
  font-size: 28px;
  color: ${props =>
    props.theme.darkMode
      ? props.theme.interface4
      : props.theme.interface7} !important;

  svg {
    margin-right: 10px;
    color: ${props => props.theme.brand_primary_color};
  }
`;

export const Offline = styled.div`
  width: 100px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  font-size: 12px;
`;

export const OfflineButton = styled.button`
  padding: 11px;
  border-radius: 5px;
  display: flex;
  border: 1px solid ${props => props.theme.brand_primary_color};
  align-items: center;
  justify-content: center;
  background: none;
  transition: all 0.2s ease-in;

  svg {
    color: ${props => props.theme.brand_primary_color};
  }

  &:hover {
    border: 1px solid transparent;
    background: ${props => props.theme.brand_secondary_color};
  }
`;

export const UndoButton = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background: ${props => props.theme.brand_secondary_color};
  margin-right: -20px;
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;

  svg {
    color: ${props => props.theme.brand_primary_color};
  }
`;

export const Iframe = styled.iframe`
  width: 100%;
  height: auto;
  border: none;
`;

export const Card = styled.div`
  height: auto;
  padding: 15px;
  background: ${props => props.theme.interface1};
  border-radius: 5px;
`;

export const CardHeader = styled.h3`
  font-size: 17px;
  color: ${props => props.theme.interface5};
`;

export const Informations = styled.div`
  padding: 30px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Information = styled.div`
  svg {
    color: #cfd3de;
    font-size: 18px;
  }

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
`;

export const Pagespeed = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const Performance = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const TicketCard = styled.div`
  height: 100%;
  padding: 15px;
  background: ${props => props.theme.interface1};
  border-radius: 5px;
`;
