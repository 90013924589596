import styled from 'styled-components';

export const HttpsText = styled.p`
  font-size: 14px;
  color: ${props => props.theme.interface5};
  line-height: 1.5;
  margin-bottom: 15px;
`;

export const Infos = styled.div`
  font-size: 11px;
  color: ${props => props.theme.interface5};
  margin-top: 10px;
  line-height: 1.5;
`;
