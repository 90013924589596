import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;

  padding: 24px;

  span {
    font-size: 16px;

    color: ${props => props.theme.interface5};
  }

  b.attention {
    color: ${props => props.theme.alertNew};
  }
`;
