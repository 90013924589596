import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 25px;
  width: 100%;
  height: 100%;
`;

export const PlanCard = styled.div`
  position: relative;
  height: 400px;
  margin: 0px 10px;
  background: ${props =>
    props.theme.darkMode ? props.theme.interface3 : props.theme.interface1};
  border-radius: 10px;
  border: 2px solid transparent;

  padding: 0px 20px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  color: ${props => props.theme.brand_primary_color};

  span {
    font-size: 14px;
    text-align: center;
    line-height: 1.8;
  }

  ul {
    font-weight: bold;
    font-size: 16px;
    text-align: center;

    li {
      padding: 10px;
    }
  }

  cursor: pointer;

  &:hover {
    border-color: ${props =>
      props.theme.darkMode
        ? props.theme.interface5
        : props.theme.brand_primary_color};
  }

  ${props =>
    props.private &&
    css`
      border-color: ${props => props.theme.favorite};
    `}

  ${props =>
    props.selected &&
    css`
      border-color: ${props =>
        props.theme.darkMode
          ? props.theme.interface5
          : props.theme.brand_primary_color};
    `}
`;

export const Price = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: space-evenly;

  span {
    span {
      font-size: 46px;
      font-weight: bolder;
    }

    p {
      margin-top: -18px;
      text-align: center;
    }
  }
`;

export const InfoCard = styled.div`
  width: 100%;
  height: 400px;
  padding: 12px 16px;

  color: ${props =>
    props.theme.darkMode
      ? props.theme.interface4
      : props.theme.brand_primary_color};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border: 1px solid
    ${props =>
      props.theme.darkMode
        ? props.theme.interface4
        : props.theme.brand_primary_color};
  border-radius: 10px;

  ul {
    list-style: none;
    height: 100%;
    margin-bottom: 10px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  ul li:before {
    content: '✓';
    font-size: 30px;
    margin-right: 10px;
  }

  span {
    text-align: left;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
  }

  li {
    text-align: left;
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  p {
    font-size: 12px;
    text-align: left;
    letter-spacing: 0;
  }
`;

export const Private = styled.div`
  position: absolute;
  top: 4px;
  right: 8px;
  font-size: 18px;
  color: ${props => props.theme.favorite};

  svg {
    opacity: 0.5;
  }
`;
