import React, {
  useEffect,
  useContext,
  useState,
  useCallback,
  useRef,
} from 'react';
import { AnimatedList } from 'react-animated-list';
import { Col, Row } from 'react-bootstrap';
import queryString from 'query-string';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStar,
  faCloud,
  faAngleUp,
  faAngleDown,
} from '@fortawesome/free-solid-svg-icons';

import history from 'services/history';

import {
  Button,
  Pagination,
  AdvancedSearch,
} from '@cloudez/cloudez-design-system';
import { Title, Header } from 'components/Common';
import SearchBar from 'components/SearchBar';
import { ListingHeader, Ordering } from 'components/Listing/styles';
import {
  FilterHover,
  Filter,
  FilterSeparator,
  FilterWrapper,
} from 'components/SearchBar/styles';
import { Value } from 'components/Acessos';
import EmptyListing from 'components/EmptyListing';

import FilterDropdown from 'components/CloudFilter';

import { useDocTitle } from 'hooks/docTitle';
import { useLayout } from 'hooks/layout';
import InvoiceWarning from 'pages/Dashboard/InvoiceWarning';
import api from 'services/mccoy';
import WebsitePlaceholder from './Placeholder';
import WebsiteCard from './WebsiteCard';
import {
  WebsiteListingContext,
  WebsiteListingProvider,
} from './_context/state';

const Websites = () => {
  const theme = useLayout();

  const { loading, websites, count, previous, next, current, getWebsites } =
    useContext(WebsiteListingContext);

  const [cloudFilter, setCloudFilter] = useState(false);
  const [clouds, setClouds] = useState(null);
  const [orderValue, setOrderValue] = useState({ column: '', value: 0 }); // Inicialização do orderValue com valor zero e não ter chance de rolar undefined

  const { page = 1, filter = 'all' } = queryString.parse(
    history.location.search,
  );

  const [search, setSearch] = useState(
    queryString.parse(history.location.search),
  );

  useDocTitle(`Websites — ${theme.name}`);

  useEffect(() => {
    getWebsitesCallback(page, search, filter, clouds, orderValue);
    // eslint-disable-next-line
  }, []);

  const [filterValue, setFilterValue] = useState(filter);

  const abortControllerRef = useRef<AbortController>(new AbortController());

  const getWebsitesCallback = useCallback(
    (page, search, filter, clouds, order) => {
      abortControllerRef.current.abort();
      abortControllerRef.current = new AbortController();

      getWebsites(
        page,
        search,
        filter,
        clouds,
        order,
        abortControllerRef.current,
      );

      const _query = {
        ...search,
        page,
        filter,
        clouds,
      };

      if (!clouds) delete _query.clouds;
      if (filter === 'all') delete _query.filter;
      if (page === 1 || page === '1') delete _query.page;
      const query = queryString.stringify(_query);
      history.replace(`/websites?${query}`);
      // eslint-disable-next-line
    },
    [api, abortControllerRef],
  );

  const handleSetClouds = useCallback(
    clouds => {
      const _clouds = clouds.join(',');
      setClouds(_clouds);
      setCloudFilter(false);
      getWebsitesCallback(1, search, filter, _clouds, orderValue);
    },
    // eslint-disable-next-line
    [search, filter, orderValue, getWebsitesCallback],
  );

  const handleSetFilter = useCallback(
    filter => {
      setFilterValue(filter);
      getWebsitesCallback(1, search, filter, clouds, orderValue);
    },
    // eslint-disable-next-line
    [search, clouds, orderValue, getWebsitesCallback],
  );

  const searchValue = useCallback(
    searchValue => {
      getWebsitesCallback(1, searchValue, filterValue, clouds, orderValue);
    },
    // eslint-disable-next-line
    [filterValue, clouds, orderValue, getWebsitesCallback],
  );

  const order = column => {
    // Ajuste na lógica de ordenação
    const newOrderValue =
      orderValue.column === column
        ? {
            column,
            value: orderValue.value === 1 ? 2 : 1,
          }
        : {
            column,
            value: 1,
          };
    setOrderValue(newOrderValue);
    getWebsitesCallback(1, search, filterValue, clouds, newOrderValue);
  };

  return (
    <>
      <Header>
        <Title>Websites</Title>
        <Button
          onClick={() => history.push('/websites/create')}
          block
          data-id="websites-createbtn"
        >
          <span>Novo website</span>
        </Button>
      </Header>
      <InvoiceWarning />

      <SearchBar noInput>
        <AdvancedSearch
          style={{
            width: '400px',
          }}
          callback={searchValue}
          value={search}
          noResults={false}
          setValue={setSearch}
          fields={[
            {
              label: 'Qualquer campo',
              value: 'search',
            },
            {
              label: 'Domínio',
              value: 'domain',
            },
            {
              label: 'Cloud',
              value: 'cloud_fqdn',
            },
            {
              label: 'Nome do cloud',
              value: 'cloud_name',
            },
            {
              label: 'Apelido do cloud',
              value: 'nickname',
            },
            {
              label: 'Email',
              value: 'email',
            },
            {
              label: 'Usuário',
              value: 'username',
            },
          ]}
        />
        <FilterWrapper>
          <Filter>
            <FontAwesomeIcon
              icon={faCloud}
              color={
                cloudFilter || clouds
                  ? theme.brand_primary_color
                  : theme.darkMode
                  ? theme.interface5
                  : theme.interface4
              }
              onClick={() => {
                setCloudFilter(!cloudFilter);
              }}
            />
            <FilterHover>
              <Value>
                <span>Cloud</span>
              </Value>
            </FilterHover>
            <FilterDropdown
              setShow={setCloudFilter}
              show={cloudFilter}
              callback={handleSetClouds}
            />
          </Filter>
          <FilterSeparator />
          <Filter>
            <FontAwesomeIcon
              icon={faStar}
              color={
                filterValue === 'favorite'
                  ? theme.favoriteNew
                  : theme.darkMode
                  ? theme.interface5
                  : theme.interface4
              }
              onClick={() =>
                filterValue === 'favorite'
                  ? handleSetFilter('all')
                  : handleSetFilter('favorite')
              }
            />
            <FilterHover>
              <Value>
                <span>Favoritos</span>
              </Value>
            </FilterHover>
          </Filter>
        </FilterWrapper>
      </SearchBar>

      {loading ? (
        <WebsitePlaceholder />
      ) : websites?.length ? (
        <>
          <ListingHeader>
            <Row>
              <Col xs="10" md="6" xl="4">
                <p onClick={() => order('domain')}>
                  Aplicação
                  <Ordering
                    status={
                      orderValue?.column === 'domain' ? orderValue?.value : 0
                    }
                  >
                    <FontAwesomeIcon
                      icon={orderValue.value === 2 ? faAngleUp : faAngleDown}
                      style={{ marginTop: '2px' }}
                    />
                  </Ordering>
                </p>
              </Col>

              <Col className="d-none d-lg-flex" xs="3">
                <p>Performance</p>
              </Col>

              <Col className="d-none d-xl-flex" xl="2">
                <p onClick={() => order('cloud')}>
                  Cloud
                  <Ordering
                    status={
                      orderValue?.column === 'cloud' ? orderValue?.value : 0
                    }
                  >
                    <FontAwesomeIcon
                      icon={orderValue.value === 2 ? faAngleUp : faAngleDown}
                      style={{ marginTop: '2px' }}
                    />
                  </Ordering>
                </p>
              </Col>

              <Col className="d-none d-xl-flex">
                <p onClick={() => order('disk')}>
                  Disco
                  <Ordering
                    status={
                      orderValue?.column === 'disk' ? orderValue?.value : 0
                    }
                  >
                    <FontAwesomeIcon
                      icon={orderValue.value === 2 ? faAngleUp : faAngleDown}
                      style={{ marginTop: '2px' }}
                    />
                  </Ordering>
                </p>{' '}
              </Col>

              <Col xl="2" className="d-none d-xl-flex" />
            </Row>
          </ListingHeader>

          <AnimatedList initialAnimationDuration={800} animation="grow">
            {websites.map(website => (
              <WebsiteCard key={website.id} website={website} />
            ))}
          </AnimatedList>

          <Pagination
            onChangePage={getWebsitesCallback}
            count={count}
            previous={previous}
            next={next}
            current={current}
            search={search}
            pageSize={10}
            filter={filterValue}
            clouds={clouds}
            order={orderValue}
          />
        </>
      ) : null}

      {!loading && websites?.length === 0 && (
        <EmptyListing>
          <p>Nenhum website encontrado.</p>
          <Button onClick={() => history.push('/websites/create')} size="lg">
            Criar novo website
          </Button>
        </EmptyListing>
      )}
    </>
  );
};

export default props => (
  <WebsiteListingProvider>
    <Websites {...props} />
  </WebsiteListingProvider>
);
