import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { darken } from 'polished';
import styled, { css } from 'styled-components';

interface WrapperProps {
  selected?: boolean;
  recommended?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  display: flex;

  align-items: center;

  position: relative;

  height: 215px;
  width: 275px;

  padding: 12px;
  border-radius: 10px;
  margin-bottom: 20px;

  background: ${props => props.theme.interface1};

  border: 2px solid transparent;
  cursor: pointer;

  ${props =>
    props.selected &&
    css`
      border: 2px solid
        ${props.recommended
          ? props.theme.successNew
          : props.theme.darkMode
          ? props.theme.interface5
          : props.theme.brand_primary_color};
    `}

  &:hover {
    transition: all 0.2s ease-in;
    border: 2px solid
      ${props =>
        props.recommended
          ? props.theme.successNew
          : props.theme.darkMode
          ? props.theme.interface5
          : props.theme.brand_primary_color};
  }
`;

interface SectionProps {
  values?: boolean;
}

export const Section = styled.div<SectionProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: ${props => (props.values ? '15px 15px 15px 5px' : '15px')};
`;

export const VerticalSeparator = styled.div`
  padding: 0px 5px;

  height: 160px;

  width: 10%;

  border-left: 1px solid ${props => props.theme.interface3};
`;

export const HorizontalSeparator = styled.div`
  padding: 0px 5px;

  width: 80px;

  border-top: 1px solid ${props => props.theme.interface3};
  margin-bottom: 10px;
`;

export const Icon = styled.div`
  padding-top: 15px;
`;

export const Title = styled.div`
  font-size: 14px;
  color: ${props => props.theme.interface5};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  text-align: center;

  width: 120px;
`;

export const Subtitle = styled.div`
  font-size: 10px;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  text-align: center;

  width: 120px;
`;

export const Recommended = styled.div`
  position: absolute;
  top: -2px;
  left: calc(50% - 75px);

  display: flex;
  justify-content: space-evenly;
  align-items: center;

  background: ${props => props.theme.successNew};
  padding: 3px;
  color: #fff;
  font-size: 13px;
  text-transform: uppercase;

  width: 150px;

  border-radius: 0px 0px 5px 5px;
`;

interface CloudValueProps {
  memory?: boolean;
  color: any;
}

export const CloudValue = styled.div<CloudValueProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  margin-bottom: ${props => (props.memory ? '10px' : '0')};

  div.value {
    border-radius: 8px;
    border: 1px solid ${props => props.color};
    font-size: 20px;
    color: ${props => props.color};
    padding: 8px 5px;
    position: relative;

    &:after {
      content: attr(data-title);
      background: ${props => props.theme.interface1};
      border: 1px solid ${props => props.theme.interface3};
      color: ${props => props.theme.interface5};
      border-radius: 5px;

      font-size: 12px;
      text-transform: uppercase;

      z-index: 1;

      white-space: nowrap;

      opacity: 0;
      visibility: hidden;

      padding: 2px 5px;

      position: absolute;
      top: -15px;
      left: 50%;

      transform: translate(-50%, -50%);
    }

    &:hover:after {
      opacity: 1;
      transition: all 0.1s ease 0.2s;
      visibility: visible;
    }
  }

  span {
    color: ${props => props.theme.interface5};
    font-size: 14px;
    margin-bottom: 5px;
  }
`;

export const Info = styled(FontAwesomeIcon).attrs(props => ({
  icon: faInfo,
}))`
  color: ${props => props.theme.interface4};
`;

export const InfoWrapper = styled.div`
  display: inline;

  position: absolute;
  top: 5px;
  left: 10px;

  padding: 5px;

  &:hover {
    svg {
      color: ${props => props.theme.brand_primary_color};
    }
  }
`;

export const InfoPopover = styled.div`
  width: 180px;

  padding: 10px;

  border: 1px solid ${props => props.theme.interface3};
  border-radius: 5px;

  position: relative;

  left: 15px;
  bottom: 14px;

  z-index: 2;

  background: ${props => props.theme.interface1};

  text-align: center;

  p {
    color: ${props => props.theme.interface5};
    margin-bottom: 5px;
    font-size: 14px;
  }

  h4 {
    margin-bottom: 10px;
  }

  span {
    font-size: 12px;
  }
`;

export const NewCloud = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  position: relative;

  padding: 12px;

  background-color: rgba(255, 255, 255, 0.5);

  height: 215px;
  width: 275px;

  position: relative;

  border: 2px dashed ${props => props.theme.successNew};

  &:hover {
    transition: all 0.2s ease-in;
    border: 2px solid ${props => props.theme.successNew};
  }

  border-radius: 10px;

  margin-bottom: 20px;

  cursor: pointer;

  & > svg {
    font-size: 32px;
    color: ${props => props.theme.successNew};
  }

  & > span {
    margin-bottom: 20px;
  }
`;
