import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import copy from 'copy-to-clipboard';
import { useAuth } from 'hooks/auth';
import React from 'react';
import { toast } from 'react-toastify';
import { refreshDomainService } from 'services/domain';
import { ContentWrapper, Title, Subtitle, StageButton } from '../styles';

export default function DomainActivation({ domain, nextStage, setShowBadge }) {
  const {
    user: { onboarding_data },
  } = useAuth();

  return (
    <ContentWrapper>
      <Title>Domínio atual</Title>
      <Subtitle>Sua aplicação está rodando nesse endereço</Subtitle>

      <p className="domain">{domain.domain || onboarding_data?.domain}</p>

      <p className="description">
        Aponte seu DNS para nós: copie os endereços abaixo e troque no seu
        provedor. Em caso de dúvida,{' '}
        <a
          href="https://help.cloudez.io/onde-encontrar-as-informacoes-de-dns/"
          target="_blank"
          rel="noreferrer"
        >
          clique aqui
        </a>
        . Após seguir os passos e apontar o DNS, basta aguardar a validação.
      </p>

      <ul className="list">
        <li>
          ns1.cloudez.io{' '}
          <FontAwesomeIcon
            icon={faCopy}
            onClick={() => {
              copy('ns1.cloudez.io');
              toast.success('DNS copiado com sucesso.');
            }}
            className="ml-2"
          />
        </li>
        <li>
          ns2.cloudez.io{' '}
          <FontAwesomeIcon
            icon={faCopy}
            onClick={() => {
              copy('ns2.cloudez.io');
              toast.success('DNS copiado com sucesso.');
            }}
            className="ml-2"
          />
        </li>
        <li>
          ns3.cloudez.io{' '}
          <FontAwesomeIcon
            icon={faCopy}
            onClick={() => {
              copy('ns3.cloudez.io');
              toast.success('DNS copiado com sucesso.');
            }}
            className="ml-2"
          />
        </li>
        <li>
          ns4.cloudez.io{' '}
          <FontAwesomeIcon
            icon={faCopy}
            onClick={() => {
              copy('ns4.cloudez.io');
              toast.success('DNS copiado com sucesso.');
            }}
            className="ml-2"
          />
        </li>
        <li>
          ns5.cloudez.io{' '}
          <FontAwesomeIcon
            icon={faCopy}
            onClick={() => {
              copy('ns5.cloudez.io');
              toast.success('DNS copiado com sucesso.');
            }}
            className="ml-2"
          />
        </li>
      </ul>

      <div className="alert-container">
        <b className="highlight">ATENÇÃO: </b>
        <span>
          O apontamento do DNS pode tirar seu site do ar caso seja feito antes
          de concluir a migração do seu website. Verifique o website através do
          <b> endereço temporário</b> ou aguarde o time de migração autorizar o
          apontamento no ticket.
        </span>
      </div>

      <StageButton
        onClick={() => {
          nextStage();
          setShowBadge(false);
        }}
      >
        PRÓXIMO
      </StageButton>
    </ContentWrapper>
  );
}
