import React from 'react';
import { Button, Input, Error, Label } from '@cloudez/cloudez-design-system';

import { Title, Subtitle } from '../../styles';
import { AppName, Section } from '../styles';

const Application = ({
  newMigration,
  setNewMigration,
  migrationErrors,
  _nextStage,
}) => {
  return (
    <>
      <AppName>
        <Label dark>Qual domínio de sua aplicação?</Label>
        <Input
          placeholder="Digite o domínio da aplicação"
          value={newMigration.domain}
          onChange={e =>
            setNewMigration({
              ...newMigration,
              domain: e.target.value,
            })
          }
          // style={{
          //   width: '60%',
          // }}
          error={!!migrationErrors?.domain}
        />
        {!!migrationErrors?.domain && <Error>{migrationErrors?.domain}</Error>}
      </AppName>
    </>
  );
};

export default Application;
