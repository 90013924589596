export default (bytes: number, kibibyte = false): string => {
  const threshold = 1024;

  if (kibibyte) {
    bytes *= 1024;
  }

  if (Math.abs(bytes) < threshold) {
    return `${bytes} B`;
  }

  const units = ['KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  let unit = -1;

  do {
    bytes /= threshold;
    ++unit;
  } while (Math.abs(bytes) >= threshold && unit < units.length - 1);

  return bytes ? `${bytes.toFixed(1)} ${units[unit]}` : null;
};
