import React, { useState } from 'react';
import { withTheme } from 'styled-components';
import { Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faUnlink } from '@fortawesome/free-solid-svg-icons';

import unlink from 'assets/img/svg/unlink.svg';

import { Modal, Input, Button } from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from 'components/ListingModal/styles';

import { ModalProps } from 'types';
import { useLayout } from 'hooks/layout';
import { HttpsText } from './styles';

type IRemoveModal = ModalProps;

const RemoveModal: React.FC<IRemoveModal> = ({ action, show, setShow }) => {
  const [desconect, setDesconect] = useState('');
  const [loading, setLoading] = useState(false);

  const theme = useLayout();

  return (
    <Modal show={show} setShow={setShow} noExit={loading}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faUnlink} />
        </Icon>
        <Text>
          <Title>Desconectar database</Title>
          <Subtitle>
            Deseja terminar o elo entre este website e o database?
          </Subtitle>
        </Text>
      </Header>

      <Content>
        <HttpsText>
          Por favor, escreva{' '}
          <span
            style={{
              textTransform: 'uppercase',
              color: theme.error,
            }}
          >
            desconectar
          </span>{' '}
          para confirmar
        </HttpsText>

        <Input
          block
          placeholder="DESCONECTAR"
          value={desconect}
          onChange={e => {
            setDesconect(e.target.value);
          }}
        />
      </Content>
      <Footer>
        <Button
          icon
          disabled={desconect !== 'DESCONECTAR' || loading}
          error
          onClick={() => {
            setLoading(true);
            action();
          }}
        >
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Remover'}
        </Button>
      </Footer>
    </Modal>
  );
};

export default RemoveModal;
