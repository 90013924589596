import styled, { css } from 'styled-components';

interface ICardProps {
  blocked?: boolean;
  spammer?: boolean;
}

export const Pulsate = styled.div`
  width: 100%;
  height: 100%;
  font-size: 16px;
  display: flex;
  justify-content: center;
  color: ${props => props.theme.alertNew};
  align-items: center;
  gap: 8px;

  animation: pulse 1.3s infinite;

  cursor: pointer;

  .icon {
    position: relative;
    div {
      position: absolute;
      right: 0px;
      top: 2px;
      width: 20px;
      height: 20px;
      background-color: ${props => props.theme.alertNew};
      filter: blur(9px);

      border-radius: 100%;
    }
  }

  @-webkit-keyframes pulse {
    0% {
      opacity: 0.3;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.3;
    }
  }

  @keyframes pulse {
    0% {
      opacity: 0.3;
    }
    50% {
      opacity: 1;
    }
    100% {
      opacity: 0.3;
    }
  }
`;

export const Badge = styled.div<ICardProps>`
  height: 18px;
  border-radius: 0px 0px 5px 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0;
  font-size: 9px;
  color: #fff;
  text-transform: uppercase;

  svg {
    margin-right: 4px;
  }

  ${props =>
    props.spammer &&
    css`
      width: 64px;
      background: ${props => props.theme.alert};
      left: calc(50% - 32px);
    `};

  ${props =>
    props.blocked &&
    css`
      width: 80px;
      background: ${props => props.theme.error};
      left: calc(50% - 40px);
    `}
`;

export const Card = styled.div<ICardProps>`
  height: 400px;
  border-radius: 5px;
  background: ${props => props.theme.interface1};
  margin: 20px 4px 0px 4px;
  border: 1px solid transparent;

  ${props =>
    props.spammer &&
    css`
      border: 1px solid ${props => props.theme.alert};
    `};

  ${props =>
    props.blocked &&
    css`
      border: 1px solid ${props => props.theme.error};

      ${CardContent} {
        opacity: 0.2;
      }

      ${CardHeader} {
        opacity: 0.2;
      }
    `}
`;

export const CardHeader = styled.div`
  padding: 0px 20px;
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
  justify-content: space-between;

  span {
    font-size: 18px;
    font-weight: bold;
    color: ${props => props.theme.interface5};
  }
`;

export const CardContent = styled.div`
  height: calc(100% - 40px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: space-between;
`;

export const CloudPerf = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;

  p {
    width: 100%;
    text-align: center;
    color: ${props => props.theme.interface4};
  }
`;

interface ICloudActionsProps {
  hasBadge?: boolean;
}

export const CloudActions = styled.div<ICloudActionsProps>`
  height: calc(100% - 200px);
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  color: ${props => props.theme.interface5};
  border-top: 1px solid ${props => props.theme.interface3};
  ${props =>
    props.hasBadge &&
    css`
      margin-bottom: 18px;
    `}
`;

export const Icons = styled.div`
  svg {
    cursor: pointer;
  }
`;
