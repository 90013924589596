import React from 'react';

import { Title, Header } from 'components/Common';
import Stepper from 'components/Stepper';

import { MigDetailProvider, useMigDetail } from '../contexts/MigDetailContext';
import Confirmation from './Confirmation';
import OriginData from './OriginData';
import DestData from './DestData';

import MigrationDetail from '../../MigrationDetailNew';

import { Container } from './styles';

import { MigCreateEmailProvider } from '../contexts/MigEmailContext';

const MigrationCreateEmailNew = () => {
  const { step } = useMigDetail();

  return (
    <>
      <Header style={{ justifyContent: 'center' }}>
        <Title>Migração de Email</Title>
      </Header>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <div style={{ width: '60%' }}>
          <Stepper
            activeStep={step}
            steps={[
              { label: 'Dados da Origem' },
              { label: 'Dados do Destino' },
              { label: 'Confirmação' },
              { label: 'Migração' },
            ]}
          />
        </div>
        <Container>
          {(() => {
            switch (step) {
              case 1:
                return <OriginData />;
              case 2:
                return <DestData />;
              case 3:
                return <Confirmation />;
              case 4:
                return <MigrationDetail />;
              default:
                return null;
            }
          })()}
        </Container>
      </div>
    </>
  );
};

export default props => (
  <MigDetailProvider>
    <MigCreateEmailProvider>
      <MigrationCreateEmailNew {...props} />
    </MigCreateEmailProvider>
  </MigDetailProvider>
);
