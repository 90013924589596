import { Reducer } from 'react';
import { IActionPayload } from 'types';
import { TicketDetailState } from './state';

const reducer: Reducer<TicketDetailState, IActionPayload> = (
  state,
  action,
): TicketDetailState => {
  switch (action.type) {
    case 'GET_TICKET':
    case 'UPDATE_TICKET':
      return {
        ...state,
        ticket: action.payload,
      };

    case 'PICKUP_TICKET':
      return {
        ...state,
        ticket: { ...state.ticket, responsible: action.payload },
      };

    case 'SET_RED_ALERT': {
      return {
        ...state,
        ticket: { ...state.ticket, is_red_alert: action.payload },
      };
    }

    case 'GET_USER':
      return {
        ...state,
        user: action.payload,
      };

    case 'GET_CLOUDS':
      return {
        ...state,
        clouds: action.payload,
      };

    case 'GET_TARGET':
      return {
        ...state,
        target: action.payload,
      };

    case 'GET_MESSAGES':
      return {
        ...state,
        messages: action.payload,
      };

    case 'ADD_WS_MESSAGE':
    case 'ADD_MESSAGE':
      return {
        ...state,
        messages: [...state.messages, action.payload],
      };

    case 'GET_FILES':
      return {
        ...state,
        files: action.payload,
      };

    case 'SET_LOADING': {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case 'SET_ERRORS': {
      return {
        ...state,
        errors: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
