import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 100%;
`;

export const Title = styled.div`
  display: flex;
  justify-content: center !important;
  align-items: center;
  padding: 10px 30px 30px 30px;

  .titleDefault {
    font-weight: bold;
    font-size: 16px;
    color: #545c68;
    line-height: 20px;

    margin-bottom: 0;
  }
`;

export const Disk = styled.div`
  padding: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
`;
