/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { createContext, useCallback, useState } from 'react';
import axios from 'axios';

export interface HelpContextState {
  helpsLoading: boolean;
  getHelpBase: (params: any) => void;
  helps: HelpItem[];
  setHelps: any;
  getCategories: () => void;
  categories: CategoryItem[];
}

interface CategoryItem {
  id: number;
  description: string;
  link: string;
  name: string;
}

interface HelpItem {
  id: number;
  link: string;
  title: { rendered: string };
}

export const HelpContext = createContext({} as HelpContextState);

export const HelpBaseProvider: React.FC = ({ children }) => {
  const [helps, setHelps] = useState([]);
  const [categories, setCategories] = useState([]);
  const [helpsLoading, setHelpsLoading] = useState(false);

  const getHelpBase = useCallback(async params => {
    try {
      setHelpsLoading(true);
      const response = await axios.get(
        'https://help.cloudez.io/wp-json/wp/v2/posts',
        {
          params,
        },
      );
      setHelps(response.data);
      setHelpsLoading(false);
    } catch (errors) {
      console.log('errors', errors);
      setHelpsLoading(false);
    }
  }, []);

  const getCategories = useCallback(async () => {
    try {
      const response = await axios.get(
        'https://help.cloudez.io/wp-json/wp/v2/categories',
      );
      setCategories(response.data);
    } catch (errors) {
      console.log('errors', errors);
    }
  }, []);

  return (
    <HelpContext.Provider
      value={{
        getHelpBase,
        helps,
        helpsLoading,
        setHelps,
        getCategories,
        categories,
      }}
    >
      {children}
    </HelpContext.Provider>
  );
};

export default {
  HelpBaseProvider,
};
