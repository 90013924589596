import React from 'react';
import { Col } from 'react-bootstrap';

import Placeholder from 'components/Placeholder';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { UserCard, Username, Host } from '../styles';

const UserPlaceholder: React.FC = () => {
  const placeholders = [];

  for (let i = 1; i <= 3; i++) {
    placeholders.push(i);
  }

  return (
    <>
      {placeholders.map((p, i) => (
        <Col sm="6" key={i}>
          <UserCard>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <FontAwesomeIcon icon={faUserCircle} />
              <Username>
                <Placeholder width={100} height={12} />
              </Username>
            </div>
            <Host>
              Host
              <span>
                <Placeholder width={40} height={12} />
              </span>
            </Host>
          </UserCard>
        </Col>
      ))}
    </>
  );
};

export default UserPlaceholder;
