import { toast } from 'react-toastify';
import history from 'services/history';
import {
  getWebsiteService,
  deleteWebsiteService,
  updateWebsiteService,
} from 'services/website';
import nProgress from 'nprogress';
import toastError from 'utils/toastError';

export const setLoading = (dispatch, value) => {
  dispatch({
    type: 'SET_LOADING',
    payload: value,
  });
};

export const getWebsite = async (dispatch, id) => {
  nProgress.start();
  try {
    const { data } = await getWebsiteService(id);

    dispatch({
      type: 'GET_WEBSITE',
      payload: data,
    });

    // dispatch({
    //   type: 'SET_ORIGINAL_WEBSITE',
    //   payload: data,
    // });

    nProgress.done();
  } catch (e) {
    nProgress.done();
    if (e.response.status === 404) {
      toast.error('Website não encontrado');
      history.push('/404');
    }
  }
};

export const getWebsiteV3 = async (dispatch, id) => {
  nProgress.start();
  try {
    const { data } = await getWebsiteService(id);

    dispatch({
      type: 'GET_WEBSITE',
      payload: data,
    });

    // dispatch({
    //   type: 'SET_ORIGINAL_WEBSITE',
    //   payload: data,
    // });

    nProgress.done();
  } catch (e) {
    nProgress.done();
    if (e.response.status === 404) {
      toast.error('Website não encontrado');
      history.push('/404');
    }
  }
};

export const deleteWebsite = async (dispatch, id, motive) => {
  setLoading(dispatch, true);
  try {
    const { data } = await deleteWebsiteService(id, motive);

    dispatch({
      type: 'DELETE_WEBSITE',
      payload: data,
    });
    setLoading(dispatch, false);
    toast.success('Website removido com sucesso.');
    history.push('/websites');
  } catch (e) {
    setLoading(dispatch, false);
    if (e.response.status === 404) {
      toast.error('Website não encontrado');
      history.push('/404');
    }
  }
};

export const patchWebsite = async (dispatch, field, value, id) => {
  setLoading(dispatch, true);
  try {
    dispatch({
      type: 'SET_ERRORS',
      payload: null,
    });

    const { data } = await updateWebsiteService(id, {
      [field]: value,
    });

    dispatch({
      type: 'PATCH_WEBSITE',
      payload: data,
    });

    dispatch({
      type: 'SET_ORIGINAL_WEBSITE',
      payload: data,
    });

    setLoading(dispatch, false);
    toast.success('Suas alterações estão sendo aplicadas.');
  } catch (error) {
    dispatch({
      type: 'SET_ERRORS',
      payload: error?.response?.data,
    });

    setLoading(dispatch, false);
    toastError(error);
  }
};

export const patchWebsiteV3 = async (dispatch, field, value, id) => {
  setLoading(dispatch, true);
  try {
    dispatch({
      type: 'SET_ERRORS',
      payload: null,
    });

    const { data } = await updateWebsiteService(id, {
      [field]: value,
    });

    dispatch({
      type: 'PATCH_WEBSITE',
      payload: data,
    });

    dispatch({
      type: 'SET_ORIGINAL_WEBSITE',
      payload: data,
    });

    setLoading(dispatch, false);
    toast.success('Suas alterações estão sendo aplicadas.');
  } catch (error) {
    dispatch({
      type: 'SET_ERRORS',
      payload: error?.response?.data,
    });

    setLoading(dispatch, false);
    toastError(error);
  }
};

export const updateValue = (state, dispatch, e) => {
  const values = state.website.values.map(_value => {
    if (_value.slug === e.target.name) {
      _value.value =
        e.target.type === 'checkbox'
          ? e.target.checked.toString()
          : e.target.value;
      return _value;
    }

    return _value;
  });

  if (e.target.name === 'domain') {
    dispatch({
      type: 'UPDATE_VALUE',
      payload: {
        ...state.website,
        values,
        domain: e.target.value,
      },
    });
  } else {
    dispatch({
      type: 'UPDATE_VALUE',
      payload: {
        ...state.website,
        values,
      },
    });
  }
};

export const updateField = (state, dispatch, field, value) => {
  dispatch({
    type: 'UPDATE_FIELD',
    payload: {
      ...state.website,
      [field]: value,
    },
  });
};
