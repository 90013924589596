import React from 'react';

import { Title, Header } from 'components/Common';
import Stepper from 'components/Stepper';

import MigrationDetailNew from '../../MigrationDetailNew';
import Overview from './Overview';

import { MigDetailProvider, useMigDetail } from '../contexts/MigDetailContext';
import { MigCreatedAssistedProvider } from '../contexts/MigAssistedContext';

const MigrationCreateAssistedNew = () => {
  const { step } = useMigDetail();

  return (
    <>
      <Header style={{ justifyContent: 'center' }}>
        <Title>Migração Assistida</Title>
      </Header>
      <Stepper
        activeStep={step}
        steps={[{ label: 'Dados Gerais' }, { label: 'Sucesso' }]}
      />
      {step === 1 ? <Overview /> : <MigrationDetailNew />}
    </>
  );
};

export default props => (
  <MigDetailProvider>
    <MigCreatedAssistedProvider>
      <MigrationCreateAssistedNew {...props} />
    </MigCreatedAssistedProvider>
  </MigDetailProvider>
);
