import React, { useState, useEffect } from 'react';
import queryString from 'query-string';

import { Row, Col } from 'react-bootstrap';
import { Field, Label, TextArea, Button } from '@cloudez/cloudez-design-system';

import history from 'services/history';

import {
  getTicketRatingService,
  updateTicketRatingService,
} from 'services/ticket';
import { useAuth } from 'hooks/auth';
import { Card } from './styles';

const TicketRating = ({ match }) => {
  const { user } = useAuth();
  const isAuthenticated = !!user;

  const [success, setSuccess] = useState(false);
  // eslint-disable-next-line
  const [_, setRating] = useState(null);
  const [ratingComment, setRatingComment] = useState('');

  const updateRating = async (ticketRating, skipSuccess = false) => {
    const { hash } = match.params;

    try {
      await updateTicketRatingService(isAuthenticated, hash, ticketRating);

      if (!skipSuccess) {
        setSuccess(true);
      }
    } catch (error) {}
  };

  useEffect(() => {
    const getTicketRating = async () => {
      try {
        const { hash } = match.params;
        let { r } = queryString.parse(history.location.search);
        setRating(r);

        const { data: ticketRating } = await getTicketRatingService(
          isAuthenticated,
          hash,
        );

        if (r === '1' || r === '2') {
          ticketRating.rating = Number(r);
          updateRating(ticketRating, true);
        } else {
          r = '0';
          setRating(r);
        }
      } catch (error) {}
    };

    getTicketRating();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <h2
        style={{
          fontSize: '30px',
          lineHeight: '36px',
        }}
      >
        Avalie nosso atendimento
      </h2>
      <p
        style={{
          height: '12px',
          lineHeight: '15px',
          fontSize: '12px',
        }}
      >
        Sua opinião é importante para melhorarmos sempre
      </p>

      <Card>
        <h1>Obrigado pela sua avaliação!</h1>
        {!success && (
          <Row>
            <Col>
              <Field>
                <Label>Deixe um comentário</Label>
                <TextArea
                  placeholder="O atendimento foi.."
                  height="80px"
                  block
                  value={ratingComment}
                  onChange={e => setRatingComment(e.target.value)}
                />
              </Field>
            </Col>
          </Row>
        )}
      </Card>

      <Button
        onClick={() => {
          updateRating({ rating_comment: ratingComment, rating: '1' });
        }}
      >
        Enviar
      </Button>
    </>
  );
};

export default TicketRating;
