import styled from 'styled-components';

export const HttpsText = styled.p`
  font-size: 16px;
  color: ${props => props.theme.interface5};
  line-height: 150%;
`;

export const HttpsButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
`;
