import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';

import Placeholder from 'components/Placeholder';
import { Breadcrumbs, Breadcrumb } from 'components/Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import {
  faCloud,
  faWindowMaximize,
  faEnvelope,
  faGlobe,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { ListingHeader, Listing } from 'components/Listing/styles';

import {
  Header,
  Title,
  Card,
  CardHeader,
  Informations,
  Information,
} from 'components/Details';
import { AddCard, AddButton } from 'components/AddCard';
import { ThemeContext } from 'styled-components';
import DomainRecordPlaceholder from '../DomainRecordCard/Placeholder';
import { OffBadge, StatusCard, Rtypes, CardContent } from '../styles';

const DomainDetailPlaceholder = () => {
  const theme = useContext(ThemeContext);

  return (
    <>
      <Header>
        <Row>
          <Col md="10" sm="9">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Placeholder circle width={64} height={64} />

              <Title>
                <Breadcrumbs>
                  <Breadcrumb>
                    <Placeholder width={40} height={10} />
                  </Breadcrumb>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <Breadcrumb>
                    <Placeholder width={60} height={10} />
                  </Breadcrumb>
                </Breadcrumbs>
                <h3>
                  <Placeholder width={100} height={18} />
                </h3>
              </Title>
            </div>
          </Col>

          <Col
            className="d-none d-sm-flex d-md-flex d-xl-flex"
            sm="3"
            md="2"
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Placeholder width={165} height={40} />
          </Col>
        </Row>
      </Header>

      <Row
        style={{
          marginBottom: `${15}px`,
        }}
      >
        <Col xl="7">
          <Row>
            <Col xl="12">
              <Card>
                <CardHeader>
                  <Placeholder width={100} height={16} />
                </CardHeader>
                <Informations>
                  <Information>
                    <FontAwesomeIcon icon={faCloud} />
                    <span
                      style={{
                        fontSize: `${12}px`,
                        marginTop: `${10}px`,
                      }}
                    >
                      <Placeholder width={40} height={12} />
                    </span>
                    <span
                      style={{
                        fontSize: `${16}px`,
                        fontWeight: 'bold',
                      }}
                    >
                      <Placeholder width={20} height={14} />
                    </span>
                  </Information>
                  <Information>
                    <FontAwesomeIcon icon={faWindowMaximize} />
                    <span
                      style={{
                        fontSize: `${12}px`,
                        marginTop: `${10}px`,
                      }}
                    >
                      <Placeholder width={40} height={12} />
                    </span>
                    <span
                      style={{
                        fontSize: `${16}px`,
                        fontWeight: 'bold',
                      }}
                    >
                      <Placeholder width={20} height={14} />
                    </span>
                  </Information>
                  <Information>
                    <FontAwesomeIcon icon={faEnvelope} />
                    <span
                      style={{
                        fontSize: `${12}px`,
                        marginTop: `${10}px`,
                      }}
                    >
                      <Placeholder width={40} height={12} />
                    </span>
                    <span
                      style={{
                        fontSize: `${16}px`,
                        fontWeight: 'bold',
                      }}
                    >
                      <Placeholder width={20} height={14} />
                    </span>
                  </Information>
                  <Information>
                    <FontAwesomeIcon icon={faGlobe} />
                    <span
                      style={{
                        fontSize: `${12}px`,
                        marginTop: `${10}px`,
                      }}
                    >
                      <Placeholder width={40} height={12} />
                    </span>
                    <span
                      style={{
                        fontSize: `${16}px`,
                        fontWeight: 'bold',
                      }}
                    >
                      <Placeholder width={20} height={14} />
                    </span>
                  </Information>
                </Informations>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xl="5">
          <StatusCard>
            <CardHeader>
              <Placeholder width={151} height={14} />
              <OffBadge>
                <Placeholder width={14} height={14} />
              </OffBadge>
            </CardHeader>
            <CardContent>
              <Placeholder width={420} height={14} />
              <ul>
                <li>
                  <Placeholder width={100} height={16} />
                </li>
                <li>
                  <Placeholder width={100} height={16} />
                </li>
                <li>
                  <Placeholder width={100} height={16} />
                </li>
                <li>
                  <Placeholder width={100} height={16} />
                </li>
                <li>
                  <Placeholder width={100} height={16} />
                </li>
              </ul>
            </CardContent>
          </StatusCard>
        </Col>
      </Row>

      <Placeholder width="100%" height={65} />

      <Rtypes
        style={{
          marginTop: '12px',
        }}
      >
        <div>
          <span
            style={{
              marginRight: '8px',
            }}
          >
            <Placeholder width={70} height={30} />
          </span>

          <span
            style={{
              marginRight: '8px',
            }}
          >
            <Placeholder width={70} height={30} />
          </span>

          <span
            style={{
              marginRight: '8px',
            }}
          >
            <Placeholder width={70} height={30} />
          </span>

          <span
            style={{
              marginRight: '8px',
            }}
          >
            <Placeholder width={70} height={30} />
          </span>

          <span
            style={{
              marginRight: '8px',
            }}
          >
            <Placeholder width={70} height={30} />
          </span>

          <span
            style={{
              marginRight: '8px',
            }}
          >
            <Placeholder width={70} height={30} />
          </span>

          <span
            style={{
              marginRight: '8px',
            }}
          >
            <Placeholder width={70} height={30} />
          </span>

          <span
            style={{
              marginRight: '8px',
            }}
          >
            <Placeholder width={70} height={30} />
          </span>

          <span
            style={{
              marginRight: '8px',
            }}
          >
            <Placeholder width={70} height={30} />
          </span>

          <span
            style={{
              marginRight: '8px',
            }}
          >
            <Placeholder width={70} height={30} />
          </span>
        </div>
      </Rtypes>

      <Row
        style={{
          marginTop: '20px',
        }}
      >
        <Col>
          <ListingHeader>
            <Row>
              <Col sm="2">
                <p>Tipo</p>
              </Col>
              <Col sm="3">
                <p>Nome</p>
              </Col>
              <Col sm="6">
                <p>Conteúdo</p>
              </Col>

              <Col xl="1" />
            </Row>
          </ListingHeader>

          <Listing>
            <AddCard
              width="100%"
              height="45px"
              style={{
                marginBottom: '10px',
              }}
            >
              <AddButton>
                <FontAwesomeIcon
                  icon={faPlusSquare}
                  color={theme.brand_primary_color}
                />
                <span>criar registro</span>
              </AddButton>
            </AddCard>
            <DomainRecordPlaceholder />
          </Listing>
        </Col>
      </Row>
    </>
  );
};

export default DomainDetailPlaceholder;
