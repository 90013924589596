class WebSocketTicketService {
  static instance = null;

  callbacks = {};
  socketRef = null;

  static getInstance() {
    if (!WebSocketTicketService.instance) {
      WebSocketTicketService.instance = new WebSocketTicketService();
    }
    return WebSocketTicketService.instance;
  }

  constructor() {
    this.socketRef = null;
  }

  connectTicket(uuid, id) {
    const path = `${process.env.REACT_APP_API_WEBSOCKET_URL}ticket`;
    this.socketRef = new WebSocket(`${path}?uuid=${uuid}&id=${id}`);
  }

  send(data) {
    this.socketRef.send(data);
  }

  close() {
    this.socketRef.close();
  }
}

const WebSocketInstance = WebSocketTicketService.getInstance();

export default WebSocketInstance;
