import styled from 'styled-components';

export const MigrationWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;

  gap: 20px;
`;

export const MigrationForm = styled.div`
  height: 100%;
  background: ${props => props.theme.interface1};
  border-radius: 5px;
  padding: 32px;
  width: 100%;
  max-width: 466px;

  gap: 24px;
  box-shadow: 0px 2px 15px rgba(100, 114, 140, 0.25);

  .textButton {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 12px;
  }
`;

export const MigrationInfo = styled.div`
  height: 100%;
  max-width: 270px;

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px;

  background-color: ${props => props.theme.interface1};
  box-shadow: 0px 2px 15px rgba(100, 114, 140, 0.25);
  border-radius: 5px;

  .title {
    gap: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;

    color: ${props => props.theme.brand_primary_color};

    span {
      font-weight: bold;
      font-size: 16px;
      line-height: 25px;
    }

    svg {
      width: 23px;
      height: 23px;
    }
  }

  .content {
    font-weight: bold;
    line-height: 100%;

    display: flex;
    flex-direction: column;
    gap: 8px;

    margin-top: 16px;

    .title {
      font-size: 14px;
      color: ${props => props.theme.interface5};
    }

    .text {
      font-size: 16px;
      font-weight: normal;
      color: ${props => props.theme.interface5};
    }

    .price {
      font-size: 28px;
      color: ${props => props.theme.successNew};
    }
  }
`;

export const Field = styled.div`
  width: 100%;
`;

export const Label = styled.label`
  font-weight: bold;
  font-size: 14px;
  line-height: 24px;

  color: ${props => props.theme.interface5};
`;
