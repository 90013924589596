import React, { useEffect, useRef } from 'react';

export const Countdown = ({ children, setTimer, timeDef, count, reset }) => {
  const Ref = useRef(null);

  const getTimeRemaining = e => {
    const total = Date.parse(e) - Date.parse(new Date() as any);
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = e => {
    const { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        `${minutes > 9 ? minutes : `0${minutes}`}:${
          seconds > 9 ? seconds : `0${seconds}`
        }`,
      );
    }
  };

  const clearTimer = e => {
    setTimer(timeDef);
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    const deadline = new Date();

    deadline.setSeconds(deadline.getSeconds() + count);
    return deadline;
  };

  useEffect(() => {
    if (reset) {
      clearTimer(getDeadTime());
    }
  }, [reset]);

  useEffect(() => {
    clearTimer(getDeadTime());
  }, []);
  return <div>{children}</div>;
};
