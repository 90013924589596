import styled from 'styled-components';

export const Title = styled.h1`
  color: ${props => props.theme.interface5};
  font-size: 18px;
  font-weight: bold;
`;

export const Wrapper = styled.div`
  margin-top: 16px;
  width: 100%;
`;

export const Existent = styled.a`
  color: ${props => props.theme.brand_primary_color};
  font-size: 10px;
  text-transform: uppercase;
  cursor: pointer;

  position: absolute;
  right: 20px;
  top: 0;
`;

export const HelpText = styled.p`
  align-self: center;
  font-size: 16px;
  color: ${props => props.theme.interface5};
  text-align: center;
`;
