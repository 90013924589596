import styled from 'styled-components';

export const StyledEmpty = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;

  font-weight: 700;

  .text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;

    span {
      color: ${props => props.theme.interface5};
    }

    u {
      color: ${props => props.theme.brand_primary_color};
      cursor: pointer;
      transition: opacity 0.2s;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`;
