import styled from 'styled-components';

export const Terms = styled.div`
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: ${props => props.theme.interface5};
  padding: 0 20px;

  button {
    width: 100%;
    height: 50px;
    margin-top: 33px;
  }

  margin-bottom: 20px;
`;
