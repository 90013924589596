import React, { useCallback, useMemo, useRef, useState } from 'react';

import { Title, Header } from 'components/Common';
import { Col, Row } from 'react-bootstrap';

import { useAuth } from 'hooks/auth';
import { useDocTitle } from 'hooks/docTitle';
import { useLayout } from 'hooks/layout';
import { Field, Input, Label, Button } from '@cloudez/cloudez-design-system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faLink,
  faPaperPlane,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import indique from 'assets/img/indique.png';
import whats from 'assets/img/whats.png';
import facebook from 'assets/img/facebook.png';
import twitter from 'assets/img/twitter.png';
import { createReferralByEmail } from 'services/user';
import { toast } from 'react-toastify';
import copy from 'copy-to-clipboard';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
} from 'react-share';
import InvoiceWarning from 'pages/Dashboard/InvoiceWarning';
import * as Styles from './styles';

const Referral: React.FC = () => {
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const emailRef = useRef(null);

  const { user } = useAuth();

  const { name } = useLayout();

  useDocTitle(`Indique e Ganhe — ${name}`);

  const sendReferral = useCallback(async () => {
    setSuccess(false);
    setLoading(true);
    try {
      const email = emailRef?.current?.value;
      await createReferralByEmail({ email });
      toast.success('Indicação enviada com sucesso');
      setSuccess(true);
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setSuccess(false);
      toast.error(e?.response?.data[Object.keys(e?.response?.data)[0]][0]);
    }
  }, [emailRef]);

  const title = useMemo(
    () =>
      `Descobri que a ${name} é a melhor solução para hospedar meus sites. Se você contratar com esse link ganha R$50 de desconto`,
    [name],
  );

  return (
    <>
      <Header>
        <Title>Indique e Ganhe</Title>
      </Header>
      <InvoiceWarning />

      <Row>
        <Col sm="2">
          <Styles.Stats>
            <Styles.Stat>
              <img className="avatar" src={user.avatar} alt="avatar" />
              <span>{user.full_name}</span>
            </Styles.Stat>

            <Styles.Stat>
              <Styles.Value>{user.referral_count?.monthly}</Styles.Value>
              <span>Indicações do mês</span>
            </Styles.Stat>

            <Styles.Stat>
              <Styles.Value>{user.referral_count?.total}</Styles.Value>
              <span>Total de indicações</span>
            </Styles.Stat>
          </Styles.Stats>
        </Col>

        <Col sm="5">
          <Styles.Subtitle>Como Funciona</Styles.Subtitle>

          <img
            style={{
              margin: '30px 0',
            }}
            src={indique}
            alt="indique"
          />

          <Styles.Description>
            <p className="title">
              Espalhe para todo mundo que o seu site está hospedado na {name} e
              ganhe créditos por isso.
            </p>
            <br />
            <p className="text">
              Utilize o link de indicação disponível nesta página.
            </p>
            <br />
            <p className="text">
              A cada novo cliente que contratar a {name} a partir de seu link
              você receberá <span className="currency">R$100</span> em créditos
              e ele receberá outros <span className="currency">R$50</span> para
              usar a partir de sua segunda fatura.
            </p>
            <br />
            <p className="text">
              Espalhe esse link em seus sites, redes sociais e blogs para
              aumentar as chances de ter a sua hospedagem grátis!
            </p>
          </Styles.Description>
        </Col>

        <Col>
          <Styles.LinkSend>
            <Styles.Content>
              <div>
                <Label>LINK DE INDICAÇÃO</Label>
                <div className="field">
                  <Input disabled value={user?.referral_url} />
                  <Button onClick={() => copy(user?.referral_url)}>
                    Copiar
                    <FontAwesomeIcon icon={faLink} />
                  </Button>
                </div>
              </div>

              <div style={{ width: '100%' }}>
                <Label>COMPARTILHAR</Label>
                <WhatsappShareButton url={user?.referral_url} title={title}>
                  <img
                    style={{
                      cursor: 'pointer',
                    }}
                    src={whats}
                    alt="whats"
                  />
                </WhatsappShareButton>
                <FacebookShareButton url={user?.referral_url} quote={title}>
                  <img
                    style={{
                      marginLeft: 10,
                      cursor: 'pointer',
                    }}
                    src={facebook}
                    alt="facebook"
                  />
                </FacebookShareButton>
                <TwitterShareButton url={user?.referral_url} title={title}>
                  <img
                    style={{
                      marginLeft: 10,
                      cursor: 'pointer',
                    }}
                    src={twitter}
                    alt="twitter"
                  />
                </TwitterShareButton>
              </div>

              <div>
                <Label>INDICAÇÃO POR EMAIL</Label>
                <div className="field">
                  <Input
                    type="email"
                    ref={emailRef}
                    placeholder="indique@exemplo.com"
                  />
                  <Button onClick={sendReferral}>
                    {loading ? (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                      <>
                        Enviar
                        <FontAwesomeIcon
                          icon={success ? faCheck : faPaperPlane}
                        />
                      </>
                    )}
                  </Button>
                </div>
              </div>
            </Styles.Content>
          </Styles.LinkSend>
        </Col>
      </Row>
    </>
  );
};

export default Referral;
