import React, { useState } from 'react';

import { Button } from '@cloudez/cloudez-design-system';

import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, Username, Spam } from './styles';

import UserModal from '../UserModal';

const UserCard = ({ user, users, setUsers }) => {
  const [modal, setModal] = useState(false);

  return (
    <Card spam={user.is_spammer}>
      {user.is_spammer && <Spam>spammer</Spam>}
      {modal && (
        <UserModal
          setShow={() => setModal(false)}
          show={modal}
          user={user}
          users={users}
          setUsers={setUsers}
        />
      )}
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <FontAwesomeIcon icon={faUserCircle} />
        <Username>{user.username}</Username>
      </div>
      <Button
        size="mn"
        outline
        style={{
          width: '110px',
        }}
        onClick={() => setModal(true)}
      >
        Alterar
      </Button>
    </Card>
  );
};

export default UserCard;
