import history from 'services/history';
import { getCloudsService, updateCloudService } from 'services/cloud';

export const setLoading = (dispatch, value) => {
  dispatch({
    type: 'SET_LOADING',
    payload: value,
  });
};

export const getClouds = async (dispatch, page, search, filter, ctrl) => {
  setLoading(dispatch, true);
  try {
    const { data } = await getCloudsService(
      {
        ...search,
        page,
        filter,
        page_size: 9,
      },
      ctrl,
    );

    dispatch({
      type: 'GET_CLOUDS',
      payload: data,
    });
    setLoading(dispatch, false);
  } catch (e) {
    if (e.message !== 'canceled') setLoading(dispatch, false);

    if (e?.response?.status === 404) {
      history.push('/404');
    }
  }
};

export const updateCloud = async (dispatch, field, value, id) => {
  try {
    const { data } = await updateCloudService(id, {
      [field]: value,
    });

    dispatch({
      type: 'UPDATE_CLOUD',
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: 'UPDATE_CLOUD_ERROR',
      payload: e?.response?.data,
    });
  }
};
