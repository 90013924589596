import { toast } from 'react-toastify';
import history from 'services/history';
import {
  getDatabaseService,
  deleteDatabaseService,
  updateDatabaseService,
} from 'services/database';
import nProgress from 'nprogress';

export const setLoading = (dispatch, value) => {
  dispatch({
    type: 'SET_LOADING',
    payload: value,
  });
};

export const getDatabase = async (dispatch, id) => {
  nProgress.start();
  try {
    const { data } = await getDatabaseService(id);

    dispatch({
      type: 'GET_DATABASE',
      payload: data,
    });
    nProgress.done();
  } catch (e) {
    nProgress.done();
    if (e?.response?.status === 404) {
      toast.error('Database não encontrado');
      history.push('/404');
    }
  }
};

export const deleteDatabase = async (dispatch, id) => {
  try {
    const { data } = await deleteDatabaseService(id);

    dispatch({
      type: 'DELETE_DATABASE',
      payload: data,
    });
    toast.success('Database removido com sucesso');
    history.push('/databases');
  } catch (e) {
    if (e?.response?.status === 404) {
      toast.error('Database não encontrado');
      history.push('/404');
    }
  }
};

export const patchDatabase = async (dispatch, field, value, id) => {
  setLoading(dispatch, true);
  try {
    const { data } = await updateDatabaseService(id, {
      [field]: value,
    });

    dispatch({
      type: 'PATCH_DATABASE',
      payload: data,
    });

    setLoading(dispatch, false);
    toast.success('Suas alterações estão sendo aplicadas.');
  } catch (error) {
    dispatch({
      type: 'SET_ERRORS',
      payload: error?.response?.data,
    });

    setLoading(dispatch, false);
    toast.error('Algo não está certo.');
  }
};

export const updateValue = (state, dispatch, e) => {
  const values = state.database.values.map(_value => {
    if (_value.slug === e.target.name) {
      _value.value =
        e.target.type === 'checkbox'
          ? e.target.checked.toString()
          : e.target.value;
      return _value;
    }

    return _value;
  });

  if (e.target.name === 'domain') {
    dispatch({
      type: 'UPDATE_VALUE',
      payload: {
        ...state.database,
        values,
        domain: e.target.value,
      },
    });
  } else {
    dispatch({
      type: 'UPDATE_VALUE',
      payload: {
        ...state.database,
        values,
      },
    });
  }
};

export const updateField = (state, dispatch, field, value) => {
  dispatch({
    type: 'UPDATE_FIELD',
    payload: {
      ...state.database,
      [field]: value,
    },
  });
};
