export default (): string => {
  const qtfChar = Math.floor(Math.random() * (14 - 8 + 1)) + 8;
  const re =
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[$!@#$%^&*()-+<>])[A-Za-z\d$!@#$%^&*()-+<>]{8,}/;
  const chars =
    '!@#$%^&*()-+<>abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOP1234567890';
  let pass;
  do {
    pass = '';
    for (let x = 0; x < qtfChar; x++) {
      const i = Math.floor(Math.random() * chars.length);
      pass += chars.charAt(i);
    }
  } while (!re.test(pass));
  return pass;
};
