import React from 'react';

import { Router } from 'react-router-dom';

import { library } from '@fortawesome/fontawesome-svg-core';

import { fas } from '@fortawesome/free-solid-svg-icons';

import history from 'services/history';
import Routes from 'routes';

import GlobalStyle from 'styles/global';
import nProgress from 'nprogress';

import AppProvider from 'context';

library.add(fas);
nProgress.configure({ showSpinner: true });

const App: React.FC = () => {
  return (
    <Router history={history}>
      <AppProvider>
        <Routes />
      </AppProvider>

      <GlobalStyle />
    </Router>
  );
};

export default App;
