import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faKey,
  faSpinner,
  faWindowMaximize,
} from '@fortawesome/free-solid-svg-icons';

import {
  Field,
  Label,
  Input,
  Error,
  Button,
  Modal,
} from '@cloudez/cloudez-design-system';

import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from 'components/ListingModal/styles';
import { updateUserPasswordService } from 'services/user';
import { toast } from 'react-toastify';
import { ModalProps } from 'types';
import { useLayout } from 'hooks/layout';

const SuccessModal = ({ show, setShow, website }) => {
  const [loading, setLoading] = useState(false);

  const theme = useLayout();
  const history = useHistory();

  return (
    <Modal show={show} setShow={setShow} noExit={loading}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faWindowMaximize} />
        </Icon>
        <Text>
          <Title>Website criado com sucesso</Title>
          <Subtitle>Aviso importante para os próximos passos</Subtitle>
        </Text>
      </Header>

      <Content>
        <div
          style={{
            fontSize: '16px',
            color: theme.interface6,
          }}
        >
          <p
            style={{
              color: theme.successNew,
              fontWeight: 700,
            }}
          >
            Parabéns, o seu website com domínio{' '}
            <span
              style={{
                color: theme.interface7,
              }}
            >
              {website.domain}
            </span>{' '}
            foi criado com sucesso.
          </p>
          <br />
          {!website.active_zone ? (
            <>
              <p>Para apontar seu domínio para o cloud, segue abaixo os DNS:</p>
              <br />
              <ul
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  fontWeight: 'bold',
                  color: theme.interface7,
                }}
              >
                <li style={{ marginRight: 18 }}>ns1.cloudez.io</li>
                <li style={{ marginRight: 18 }}>ns2.cloudez.io</li>
                <li style={{ marginRight: 18 }}>ns3.cloudez.io</li>
                <li style={{ marginRight: 18 }}>ns4.cloudez.io</li>
                <li style={{ marginRight: 18 }}>ns5.cloudez.io</li>
              </ul>
              <br />
              <p>
                Realize as alterações do seu DNS onde você comprou seu domínio e
                onde a sua gestão de domínio ocorre.
              </p>
              <br />
              <p>
                <b>Atenção:</b>
                <br /> Após alterar o DNS aguarde o período de comunicação dele
                com seu cloud (propagação) que poderá levar até 72 horas.
              </p>
              <br />
            </>
          ) : (
            <>
              <p>
                Identificamos que esse domínio já está conosco, então não é
                necessário realizar o apontamento do DNS.
              </p>
              <br />
            </>
          )}
          <p>
            Caso tenha seu website criado em outro local e deseja iniciar sua
            migração, clique em migração.
          </p>
        </div>
      </Content>

      <Footer hasLink>
        <Button icon outline disabled={loading} onClick={setShow}>
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Fechar'}
        </Button>
        <Button
          icon
          style={{
            marginLeft: 'auto',
          }}
          disabled={loading}
          onClick={() => {
            history.push('/migrations/create/website');
          }}
        >
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Migração'}
        </Button>
      </Footer>
    </Modal>
  );
};

export default SuccessModal;
