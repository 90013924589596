import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import api from 'services/api';
import { ThemeProvider } from 'styled-components';
import dark from 'styles/themes/dark';
import light from 'styles/themes/light';
import CloseButton from 'components/ToastCloseButton';
import ReactGA from 'react-ga';

export const LayoutContext = createContext<any>({} as any);

export const LayoutProvider: React.FC = ({ children }) => {
  const history = useHistory();
  const [data, setData] = useState(() => {
    const layout = localStorage.getItem('@cloudez-app-v2/layout');

    if (layout) {
      return JSON.parse(layout);
    }

    return {
      notification: {
        type: null,
        text: '',
        button: {},
      },
      sideMenuCollapsed: false,
      darkMode: false,
      loading: true,
      brand_primary_color: '#fff',
      ...light,
    };
  });

  const setNotification = useCallback((text, type, button = {}) => {
    setData(data => ({
      ...data,
      notification: {
        type,
        text,
        button,
      },
    }));
  }, []);

  const toggleMenu = useCallback(() => {
    setData(data => ({
      ...data,
      sideMenuCollapsed: !data.sideMenuCollapsed,
    }));
  }, []);

  const toggleDarkMode = useCallback(() => {
    const theme = data.darkMode ? light : dark;

    const layout = {
      ...data,
      ...theme,
      darkMode: !data.darkMode,
    };

    localStorage.setItem('@cloudez-app-v2/layout', JSON.stringify(layout));
    setData(layout);
  }, [data]);

  const handleTags = useCallback((data, domain) => {
    document.title = data.name;

    // favicon link
    const link: HTMLLinkElement =
      document.querySelector("link[rel*='icon']") ||
      document.createElement('link');

    let apiUrl;

    if (window.location.hostname === 'demo.cloudez.io') {
      apiUrl = 'https://dev-api.cloudez.io/';
    } else {
      apiUrl = process.env.REACT_APP_API_URL;
    }

    link.type = 'image/x-icon';
    link.rel = 'shortcut icon';
    link.href = data.favicon || data.logo_reduced;

    document.getElementsByTagName('head')[0].appendChild(link);

    // manifest link
    const linkManifest: HTMLLinkElement =
      document.querySelector("link[rel*='manifest']") ||
      document.createElement('link');

    linkManifest.rel = 'manifest';

    linkManifest.href = `${apiUrl}v2/data/manifest/${domain}/`;

    document.getElementsByTagName('head')[0].appendChild(linkManifest);

    // meta colors from chrome
    const metaChrome: HTMLMetaElement =
      document.querySelector('meta[name="theme-color"]') ||
      document.createElement('meta');

    metaChrome.name = 'theme-color';
    metaChrome.content = data.brand_primary_color || '#4A90E1';

    document.getElementsByTagName('head')[0].appendChild(metaChrome);

    // meta colors from IE
    const metaWindows: HTMLMetaElement =
      document.querySelector('meta[name="msapplication-navbutton-color"]') ||
      document.createElement('meta');

    metaWindows.name = 'msapplication-navbutton-color';
    metaWindows.content = data.brand_primary_color || '#4A90E1';

    document.getElementsByTagName('head')[0].appendChild(metaWindows);

    // meta colors from safari
    const metaSafari: HTMLMetaElement =
      document.querySelector(
        'meta[name="apple-mobile-web-app-status-bar-style"]',
      ) || document.createElement('meta');

    metaSafari.name = 'apple-mobile-web-app-status-bar-style';
    metaSafari.content = data.brand_primary_color || '#4A90E1';

    document.getElementsByTagName('head')[0].appendChild(metaSafari);
  }, []);

  const loadCloudezChat = useCallback((company, user = null) => {
    if (user) {
      const _dataChat = document.querySelectorAll('[data-chat="true"]');

      if (_dataChat.length >= 0) {
        _dataChat.forEach(el => {
          el.remove();
        });
      }

      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = 'chat-cloudez';
      script.async = true;
      script.src = process.env.REACT_APP_CHAT_URL;
      script.setAttribute('data-chat', 'true');
      script.setAttribute('company', company.code);
      const domain = company?.app_domains?.find(
        d => !d.includes('cloudez.app'),
      );
      script.setAttribute('company_domain', domain);
      script.setAttribute('primary_color', company.brand_primary_color);
      if (user) {
        script.setAttribute('username', user.full_name);
        script.setAttribute('email', user.email);
        script.setAttribute('uuid', user.uuid);
        script.setAttribute('is_partner', 'false');
        script.setAttribute('is_external', 'false');
      } else {
        script.setAttribute('is_external', 'true');
      }

      document.querySelector('body').appendChild(script);
    }
  }, []);

  const loadChat = useCallback((chatType, chatKey) => {
    const { Tawk_API } = window as any;

    if (chatType === 'tawkto' && chatKey && !Tawk_API) {
      const script = document.createElement('script');
      script.type = 'text/javascript';

      script.text = `
      var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();

      (function(){
        var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
        s1.async=true;
        s1.src='https://embed.tawk.to/${chatKey}/default';
        s1.charset='UTF-8';
        s1.setAttribute('crossorigin','*');
        s0.parentNode.insertBefore(s1,s0);
      })();
    `;

      document.querySelector('body').appendChild(script);
    }
  }, []);

  const loadAnalytics = useCallback(code => {
    if (code) {
      ReactGA.initialize(code);

      history.listen(location => {
        ReactGA.set({ page: location.pathname }); // Update the user's current page
        ReactGA.pageview(location.pathname); // Record a pageview for the given page
      });
    }
  }, []);

  const loadGTM = useCallback(code => {
    const { gtag } = window as any;

    if (code && !gtag) {
      const script1 = document.createElement('script');
      script1.type = 'text/javascript';

      script1.text = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','${code}');
    `;

      const noscript = document.createElement('noscript');
      const iframe: HTMLIFrameElement = document.createElement('iframe');
      iframe.src = `https://www.googletagmanager.com/ns.html?id=${code}`;
      iframe.height = '0';
      iframe.width = '0';
      iframe.setAttribute('style', 'display:none;visibility:hidden');

      noscript.appendChild(iframe);

      document
        .querySelector('body')
        .insertBefore(noscript, document.querySelector('body').firstChild);
      document.querySelector('head').appendChild(script1);
    }
  }, []);

  const loadFacebook = useCallback(code => {
    const { fbq } = window as any;

    if (code && !fbq) {
      const script = document.createElement('script');
      script.text = `
      !function(f,b,e,v,n,t,s) {if(f.fbq)return;n=f.fbq=function(){n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)}; if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0'; n.queue=[];t=b.createElement(e);t.async=!0; t.src=v;s=b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s)}(window, document,'script', 'https://connect.facebook.net/en_US/fbevents.js'); fbq('init', '${code}'); fbq('track', 'PageView');
      `;

      const noscript = document.createElement('noscript');
      const img = document.createElement('img');
      img.height = 1;
      img.width = 1;
      img.setAttribute('style', 'display:none');
      img.src = `https://www.facebook.com/tr?id=${code}&ev=PageView&noscript=1`;

      noscript.appendChild(img);

      document.querySelector('head').appendChild(script);
      document.querySelector('head').appendChild(noscript);
    }
  }, []);

  const updateTawkTo = useCallback(user => {
    setTimeout(() => {
      const { Tawk_API } = window as any;

      if (Tawk_API !== undefined && Tawk_API !== null) {
        if (typeof Tawk_API.setAttributes === 'function') {
          Tawk_API.setAttributes({
            name: user.full_name,
            whitelabel: data.slug,
            'system-id': user.id,
            redAlert: user.is_redalert,
            email: user.email,
            hash: user.chat_hash,
          });
        }

        if (typeof Tawk_API.showWidget === 'function') {
          Tawk_API.showWidget();
        }
      }
    }, 1500);
  }, []);

  const loadCustomScript = useCallback(scriptText => {
    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.text = scriptText;

    document.querySelector('body').appendChild(script);
  }, []);

  const loadCookieConsent = useCallback(theme => {
    const { cookieconsent } = window as any;
    cookieconsent.initialise({
      palette: {
        popup: {
          background: theme.brand_secondary_color,
        },
        button: {
          background: theme.brand_primary_color,
          text: theme.brand_secondary_color,
        },
      },
      theme: 'classic',
      content: {
        link: 'Ler mais',
        href: 'https://cloudez.io/br/politica-de-cookies/',
        message: `<span style="color: ${theme.brand_primary_color};">Este website utiliza cookies para melhorar sua experiência como usuário.</span>`,
        dismiss: 'Estou ciente',
      },
    });
  }, []);

  const getTheme = useCallback(async () => {
    try {
      const domain =
        process.env.NODE_ENV === 'development'
          ? 'cloud.configr.com'
          : window.location.hostname;

      const { data: company } = await api.get(`/v3/company/theme/${domain}/`);

      if (process.env.NODE_ENV !== 'development') {
        // load tawkto chat
        // if (
        //   company.slug === 'roberto4-hexah-digital' ||
        //   company.slug === 'grupodpg' ||
        //   company.slug === 'onosam-onocomp-informatica'
        // ) {
        //   const { chat_public_key, chat_type } = company;
        //   loadChat(chat_type, chat_public_key);
        // }

        // handle company's custom script
        if (company.custom_script) {
          loadCustomScript(company.custom_script);
        }
        // load gtm and analytics
        if (company.analytics_code) {
          loadAnalytics(company.analytics_code);
        }
        if (company.gtm_code) {
          loadGTM(company.gtm_code);
        }
        if (company.fb_code) {
          loadFacebook(company.fb_code);
        }

        handleTags(company, domain);
        // loadCookieConsent(company);
      }

      // if (company.has_partner_chat) loadCloudezChat(company);

      const layout = {
        ...data,
        ...company,
        loading: false,
      };

      setData(layout);

      localStorage.setItem('@cloudez-app-v2/layout', JSON.stringify(layout));

      // add notification if temp domain
      if (domain.endsWith('.cloudez.app')) {
        setNotification(
          `Você está acessando o seu painel de gerenciamento através de uma URL temporária. Algumas funcionalidades não estão disponíveis neste caminho.
              Em caso de dúvida, fale com seu Consultor Operacional`,
          'warning',
        );
      }
    } catch (e) {
      console.log(e);
    }
  }, [data]);

  useEffect(() => {
    getTheme();
  }, []);

  useEffect(() => {
    const handleSidemenu = () => {
      if (document.body.clientWidth <= 768 && !data.sideMenuCollapsed) {
        setData(data => {
          return {
            ...data,
            sideMenuCollapsed: true,
          };
        });
      }
    };

    window.addEventListener('resize', handleSidemenu);
    window.addEventListener('load', handleSidemenu);
  }, [data, toggleMenu]);

  return (
    <LayoutContext.Provider
      value={{
        ...data,
        toggleMenu,
        setNotification,
        toggleDarkMode,
        updateTawkTo,
        // loadCloudezChat,
        getTheme,
      }}
    >
      <ThemeProvider theme={data}>
        <ToastContainer closeButton={<CloseButton />} />
        {children}
      </ThemeProvider>
    </LayoutContext.Provider>
  );
};

export default {
  LayoutProvider,
};
