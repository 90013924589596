import axios from 'axios';

const api = axios.create({
  baseURL: 'https://api2.cloudez.io/v3/pagespeed',
});

export const analyzeWebsiteService = async domain => {
  try {
    return await api.post('/v0/run/enqueue_analysis/', { domain });
  } catch (e) {
    throw e;
  }
};

export const getWebsiteService = async params => {
  try {
    return await api.get('/v0/website/', { params });
  } catch (e) {
    throw e;
  }
};

export default api;
