import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import ApplicationIcon from 'components/ApplicationIcon';

import {
  faPlusSquare,
  faMinusSquare,
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getWebsiteTypesService } from 'services/website';
import { useAuth } from 'hooks/auth';
import { Wrapper, SeeMoreButton, Type } from './styles';
import Placeholder from './Placeholder';

const WebsiteTypes = ({ setWebsiteType, websiteType }) => {
  const [qty, setQty] = useState(3);
  const [types, setTypes] = useState([]);
  const [toggleShowMore, setToggleShowMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();

  useEffect(() => {
    async function getWebsiteTypes() {
      setLoading(true);
      const { data } = await getWebsiteTypesService({
        company: user.company,
      });

      setTypes(data.results);
      setLoading(false);
    }

    getWebsiteTypes();
    // eslint-disable-next-line
  }, []);

  return (
    <Wrapper>
      <>
        <Row
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {loading ? (
            <Placeholder />
          ) : (
            types?.map((t, i) => {
              if (i <= qty) {
                return (
                  <Col
                    key={t.id}
                    sm="3"
                    xs="6"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Type
                      type={t}
                      onClick={() => {
                        setWebsiteType(t.slug);
                      }}
                      selected={websiteType === t.slug}
                    >
                      <ApplicationIcon
                        width="40px"
                        height="40px"
                        icon={`${t.slug}`}
                      />
                      <span>{t.name}</span>
                      <p>{t.description}</p>
                    </Type>
                  </Col>
                );
              }
              return null;
            })
          )}
        </Row>
        <Row
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginBottom: '25px',
          }}
        >
          <SeeMoreButton
            onClick={() => {
              toggleShowMore ? setQty(types.length) : setQty(11);
              setToggleShowMore(!toggleShowMore);
            }}
          >
            <FontAwesomeIcon
              icon={toggleShowMore ? faPlusSquare : faMinusSquare}
            />
            <span>Mais aplicações</span>
          </SeeMoreButton>
        </Row>
      </>
    </Wrapper>
  );
};

export default WebsiteTypes;
