import styled from 'styled-components';

export const Shared = styled.ul`
  margin-top: 10px;
  list-style: none;
  max-height: 240px;
  overflow: auto;
`;
export const SharedUser = styled.li`
  font-size: 13px;
  color: ${props => props.theme.interface5};
  padding: 3px 2px;
`;

export const NoUsers = styled.li`
  font-size: 13px;
  color: ${props => props.theme.interface4};
  padding: 3px 2px;
`;
