import React, { useState, useMemo, useCallback } from 'react';
import { toast } from 'react-toastify';
import { Button } from '@cloudez/cloudez-design-system';
import { Tab, Tabs, TabContent } from 'components/Tabs';
import {
  faDesktop,
  faInfo,
  faMobileAlt,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { analyzeWebsiteService } from 'services/mccoy';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ScoreChart from './ScoreChart';

import { Title } from './styles';

const Charts = ({
  analysis,
  website,
  setAnalysis,
  tab,
  setTab,
  startAnimation,
  setOpened,
}) => {
  // const [tab, setTab] = useState(1);
  const [loading, setLoading] = useState(false);

  const pagespeedURL = useMemo(() => {
    const _tab = tab === 1 ? 'desktop' : 'mobile';
    return `https://developers.google.com/speed/pagespeed/insights/?hl=pt-BR&url=${website.domain}&tab=${_tab}`;
  }, [tab, website]);

  const runAnalyze = useCallback(
    async e => {
      e.stopPropagation();

      if (!website.is_hosted) {
        startAnimation();
        return;
      }

      try {
        setLoading(true);

        await analyzeWebsiteService(website.domain);

        setAnalysis(analysis => ({
          ...analysis,
          is_waiting_analysis: true,
        }));

        toast.success(
          'Seu website está sendo analisado. Por favor, volte em alguns instantes',
        );
        setLoading(false);
        setOpened(false);
      } catch (e) {
        setOpened(false);
        toast.error('Não foi possível analisar o website');
        setLoading(false);
      }
    },
    [website],
  );

  return (
    <div
      style={{
        padding: '0 15px',
        maxHeight: '100%',
      }}
    >
      <Title>Performance</Title>
      <Tabs
        style={{
          marginTop: '20px',
        }}
      >
        <Tab
          style={{
            width: 'unset',
            padding: '10px',
            fontWeight: 'normal',
          }}
          onClick={e => {
            e.stopPropagation();
            setTab(1);
          }}
          active={tab === 1}
        >
          <FontAwesomeIcon icon={faDesktop} className="mr-1" />
          Desktop
        </Tab>
        <Tab
          style={{
            width: 'unset',
            padding: '10px',
            fontWeight: 'normal',
          }}
          onClick={e => {
            e.stopPropagation();
            setTab(2);
          }}
          active={tab === 2}
        >
          <FontAwesomeIcon icon={faMobileAlt} className="mr-1" />
          Mobile
        </Tab>
      </Tabs>

      <TabContent>
        {(() => {
          switch (tab) {
            case 1:
              return <ScoreChart score={analysis?.desktop?.score} />;

            case 2:
              return <ScoreChart score={analysis?.mobile?.score} />;

            default:
              null;
          }
        })()}
      </TabContent>
      <p
        style={{
          marginTop: 0,
          textAlign: 'center',
          display: 'block',
          fontWeight: 'normal',
          fontSize: 12,
        }}
      >
        Nota do site
      </p>
      <Button
        style={{
          width: 90,
          height: 30,
          fontSize: 11,
          margin: '20px auto',
        }}
        icon
        disabled={loading || analysis?.is_waiting_analysis}
        onClick={runAnalyze}
      >
        {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Analisar'}
      </Button>
      <a
        style={{
          display: 'flex',
          alignItems: 'center',
          fontStyle: 'italic',
          fontSize: '12px',
          justifyContent: 'center',
          textDecorationLine: 'underline',
        }}
        href={pagespeedURL}
        target="_blank"
        rel="noreferrer"
      >
        <FontAwesomeIcon
          icon={faInfo}
          style={{
            marginRight: '8px',
          }}
        />
        Veja o relatório completo
      </a>
    </div>
  );
};

export default Charts;
