import styled from 'styled-components';

export interface StepperContainerProps {
  width?: string;
}

export const StepperContainer = styled.div<StepperContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  margin-bottom: 60px;

  width: ${props => (props.width ? props.width : '100%')};

  @media (max-width: 900px) {
    width: 100%;
  }
`;

export const StepContainer = styled.div`
  flex: 1;
  position: relative;
  padding-left: 8px;
  padding-right: 8px;
`;

export const StepMain = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const LabelContainer = styled.div`
  width: 100%;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.4;

  @media (max-width: 768px) {
    display: none;
  }

  span {
    font-weight: 300;
    font-size: 14px;

    color: ${props =>
      !props.theme.darkMode ? props.theme.interface7 : props.theme.interface4}

    display: block;
    margin-top: 5px;
    text-align: center;
    font-weight: 500;
  }
`;

interface StepProps {
  active?: boolean;
}

export const Step = styled.div<StepProps>`
  display: flex;

  text-align: center;
  align-items: center;

  border-radius: 50%;

  width: 22px;
  height: 22px;

  font-size: 14px;

  padding: 5px;

  color: ${props => (props.active ? '#ffffff' : props.theme.successNew)};
  background-color: ${props =>
    props.active ? props.theme.successNew : 'transparent'};

  border: 2px solid ${props => props.theme.successNew};
`;

export const Connector = styled.span`
  display: block;
  border-color: ${props => props.theme.successNew};
  border-top-style: solid;
  border-top-width: 2px;
`;

export const ConnectorContainer = styled.div`
  top: calc((2em - 10px) / 2);
  left: calc((-50% + 1.1em) - 8px);
  right: calc((50% + 1.1em) - 8px);
  position: absolute;
`;
