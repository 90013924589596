import React, { useState, useEffect, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faUserCircle } from '@fortawesome/free-regular-svg-icons';
import {
  faSpinner,
  faTimes,
  faPlus,
  faPlusSquare,
  faKey,
} from '@fortawesome/free-solid-svg-icons';

import generatePassword from 'utils/generatePassword';

import {
  Input,
  Label,
  Field,
  Checkbox,
  Select,
  Error,
  InputSufix,
  Value,
  Button,
  Pagination,
  createPagination,
  PaginatedResponse,
} from '@cloudez/cloudez-design-system';
import { Hover, HoverText } from 'components/Hover';

import { NoResultsDetail } from 'components/Common';
import { AddButton, AddCard } from 'components/AddCard';

import {
  getEmailAccountsService,
  deleteEmailAccountService,
  createEmailAccountService,
  updateEmailAccountService,
  createEmailAccountAutoReplyService,
  deleteEmailAccountAutoReplyService,
  getEmailAccountAutoReplysService,
} from 'services/email';
import kbToMb from 'utils/kbToMb';
import SearchInput from 'components/SearchInput';
import { EmailAccount, EmailAccountAutoReply } from 'types/email';
import toastError from 'utils/toastError';
import { EmailDetailContext } from '../_context/state';
import EmailAccountPlaceholder from './EmailAccountPlaceholder';
import AutoReplyModal from './AutoReplyModal';
import { UserCard, Username, UserDetail, SpamBadge } from './styles';
import OfflineModal from './OfflineModal';
import DeleteAccountEmailModal from './DeleteAccountEmailModal';

const Usuarios: React.FC = () => {
  const { email } = useContext(EmailDetailContext);

  const [loading, setLoading] = useState(true);
  const [offlineModal, setOfflineModal] = useState(false);

  const [openDeleteAccountEmailModal, setOpenDeleteAccountEmailModal] =
    useState(false);

  const [autoReplyModal, setAutoReplyModal] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [createLoading, setCreateLoading] = useState(false);
  const [showPw, setShowPw] = useState(false);

  const [autoReply, setAutoReply] = useState<EmailAccountAutoReply>(
    {} as EmailAccountAutoReply,
  );
  const [emailAccounts, setEmailAccounts] =
    useState<PaginatedResponse<EmailAccount>>(null);
  const [account, setAccount] = useState<EmailAccount>({
    email: email.id,
    username: '',
    password: '',
    quota: 0,
    copy: [],
    spam_score_delivery: null,
    spam_score_deny: null,
  } as EmailAccount);
  const [newCopy, setNewCopy] = useState('');
  const [search, setSearch] = useState(null);

  const [accountErrors, setAccountErrors] = useState({} as any);

  const getEmailAccountsCallback = async (page, search) => {
    setLoading(true);
    try {
      const { data } = await getEmailAccountsService({
        page_size: 9,
        email: email.id,
        page,
        search,
      });

      const pagination = createPagination(data, 9);

      setEmailAccounts({
        ...pagination,
        data: data.results as EmailAccount[],
      });
      setAccount(data.results[0]);
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEmailAccountsCallback(1, '');
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function getAccountAutoReply() {
      try {
        const { data } = await getEmailAccountAutoReplysService({
          email_account: account.id,
        });

        setAutoReply(data as EmailAccountAutoReply);
      } catch (e) {
        setLoading(false);
      }
    }

    if (account?.id) getAccountAutoReply();
    // eslint-disable-next-line
  }, [account?.id]);

  const deleteAccount = async () => {
    setCreateLoading(true);
    try {
      await deleteEmailAccountService(account.id);

      const newAccounts = emailAccounts.data.filter(a => a.id !== account.id);

      setEmailAccounts({ ...emailAccounts, data: newAccounts });
      setAccount(newAccounts[0] as EmailAccount);

      toast.success('Usuário removido com sucesso.');
      setCreateLoading(false);
    } catch (e) {
      setCreateLoading(false);
    }
  };

  const createAccount = async () => {
    setCreateLoading(true);
    try {
      setAccountErrors([]);
      const { data } = await createEmailAccountService(account);
      setEmailAccounts({
        ...emailAccounts,
        data: [data, ...emailAccounts.data],
      });

      toast.success('Usuário adicionado.');
      setAddUser(false);
      setAccount(data as EmailAccount);

      setCreateLoading(false);
    } catch (e) {
      const errorMessage =
        e?.response?.data?.non_field_errors?.[0] || 'Erro ao criar usuário.';

      toast.error(errorMessage);
      setCreateLoading(false);
      setAccountErrors(e?.response?.data);
    }
  };

  const updateAccount = async () => {
    setCreateLoading(true);
    setAccountErrors([]);

    try {
      const { data } = await updateEmailAccountService(account.id, account);

      setEmailAccounts({
        ...emailAccounts,
        data: emailAccounts?.data?.map(a => (a.id === data.id ? data : a)),
      });

      setCreateLoading(false);
      toast.success('Usuário alterado com sucesso.');
    } catch (e) {
      const errorMessage =
        e?.response?.data?.non_field_errors?.[0] ||
        'Erro ao alterar o usuário.';
      toast.error(errorMessage);
      setCreateLoading(false);
      setAccountErrors(e?.response?.data);
    }
  };

  const disableAccount = async value => {
    setCreateLoading(true);
    setAccountErrors([]);
    try {
      const payload = {
        ...account,
        is_disabled: value,
      };

      delete payload?.password;

      const { data } = await updateEmailAccountService(account.id, payload);

      setEmailAccounts({
        ...emailAccounts,
        data: emailAccounts.data.map(a =>
          a.id === account.id
            ? {
                ...account,
                ...data,
              }
            : a,
        ),
      });

      setAccount({
        ...account,
        is_disabled: value,
      });

      setCreateLoading(false);
      toast.success(
        `Usuário ${!value ? 'habilitado' : 'desabilitado'} com sucesso.`,
      );
    } catch (e) {
      setCreateLoading(false);
      setAccountErrors(e?.response?.data);
    }
  };

  const createAutoReply = async id => {
    try {
      const payload = {
        ...autoReply,
        email_account: id,
      };

      const { data } = await createEmailAccountAutoReplyService(payload);

      setAutoReply(data as EmailAccountAutoReply);

      toast.success(`Resposta automática cadastrada com sucesso.`);
    } catch (e) {}
  };

  const deleteAutoReply = async id => {
    try {
      await deleteEmailAccountAutoReplyService(id);

      setAutoReply({} as EmailAccountAutoReply);

      toast.error(`Resposta automática removida.`);
    } catch (e) {}
  };

  const addCopy = (user, cb) => {
    cb({
      ...user,
      copy: [...user.copy, newCopy],
    });

    setNewCopy('');
  };

  const deleteCopy = (user, cb, copy) => {
    const copies = user.copy.filter(c => c !== copy);

    cb({
      ...user,
      copy: copies,
    });
  };

  const searchValue = searchValue => {
    getEmailAccountsCallback(1, searchValue);
  };

  return (
    <>
      {offlineModal && (
        <OfflineModal
          account={account}
          show={offlineModal}
          setShow={() => setOfflineModal(false)}
          action={disableAccount}
        />
      )}
      {autoReplyModal && (
        <AutoReplyModal
          deleteAction={deleteAutoReply}
          account={account.id}
          autoReply={autoReply}
          setAutoReply={setAutoReply}
          show={autoReplyModal}
          setShow={() => setAutoReplyModal(false)}
          createAction={createAutoReply}
        />
      )}
      {openDeleteAccountEmailModal && (
        <DeleteAccountEmailModal
          show={openDeleteAccountEmailModal}
          setShow={setOpenDeleteAccountEmailModal}
          action={deleteAccount}
        />
      )}
      <Row>
        <Col xl="5">
          <Row
            style={{
              margin: 0,
              marginBottom: '10px',
              width: '100%',
            }}
          >
            <SearchInput
              style={{
                width: '100%',
              }}
              callback={searchValue}
              value={search}
            />
          </Row>

          <Row>
            <Col sm="6">
              {addUser ? (
                <UserCard selected={addUser}>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                    }}
                  >
                    <FontAwesomeIcon icon={faUserCircle} />
                    <Username>{account?.username}</Username>
                  </div>
                </UserCard>
              ) : (
                <AddCard
                  onClick={() => {
                    setAddUser(true);
                    setAccount({
                      email: email.id,
                      username: '',
                      password: '',
                      quota: 0,
                      copy: [],
                      spam_score_delivery: null,
                      spam_score_deny: null,
                    } as EmailAccount);
                  }}
                  width="100%"
                  height="50px"
                >
                  <AddButton row>
                    <FontAwesomeIcon icon={faPlusSquare} />
                    <span>criar</span>
                  </AddButton>
                </AddCard>
              )}
            </Col>

            {loading ? (
              <EmailAccountPlaceholder />
            ) : emailAccounts.data?.length ? (
              emailAccounts.data.map(user => {
                return (
                  <Col sm="6" key={user.id}>
                    <UserCard
                      isSpammer={user.is_spammer}
                      disabled={user.is_disabled}
                      onClick={() => {
                        setAccount({
                          ...user,
                        });
                        setAddUser(false);
                      }}
                      selected={account && user.id === account.id}
                    >
                      {user.is_spammer && <SpamBadge>SPAM</SpamBadge>}
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <FontAwesomeIcon icon={faUserCircle} />
                        <Username>{user?.username}</Username>
                      </div>
                    </UserCard>
                  </Col>
                );
              })
            ) : (
              <Col sm="6">
                <NoResultsDetail row width="100%" height="50px">
                  <FontAwesomeIcon icon={faUserCircle} />
                  Nenhum usuário vinculado.
                </NoResultsDetail>
              </Col>
            )}
          </Row>

          <Row>
            <Pagination
              style={{
                margin: '0 auto',
              }}
              onChangePage={getEmailAccountsCallback}
              pageSize={9}
              search={search}
              count={emailAccounts?.count}
              previous={emailAccounts?.previous}
              next={emailAccounts?.next}
              current={emailAccounts?.current}
            />
          </Row>
        </Col>

        {account && (
          <Col xl="7">
            <UserDetail>
              <Row>
                <Col sm="6">
                  <Field>
                    <Label>Nome de usuário</Label>
                    <Input
                      block
                      disabled={!addUser}
                      background={!addUser}
                      value={account?.username}
                      onChange={e => {
                        setAccount({
                          ...account,
                          username: e.target.value,
                        });
                      }}
                      error={!!accountErrors?.username}
                    />
                    <InputSufix>{`@${email.domain}`}</InputSufix>
                    {accountErrors?.username && (
                      <Error>{accountErrors?.username[0]}</Error>
                    )}
                  </Field>
                  <Field
                    style={{
                      height: '64px',
                    }}
                  >
                    <Label>Senha</Label>
                    <Input
                      block
                      value={account?.password}
                      onChange={e => {
                        setAccount({
                          ...account,
                          password: e.target.value,
                        });
                      }}
                      type={showPw ? 'text' : 'password'}
                      autoComplete="new-password"
                      error={!!accountErrors?.password}
                    />
                    {accountErrors?.password && (
                      <Error>{accountErrors?.password[0]}</Error>
                    )}
                    <FontAwesomeIcon
                      className="eye"
                      icon={faEye}
                      onClick={() => setShowPw(!showPw)}
                    />
                    <FontAwesomeIcon
                      className="key"
                      icon={faKey}
                      onClick={() => {
                        const pass = generatePassword();

                        setAccount({
                          ...account,
                          password: pass,
                        });
                      }}
                    />
                  </Field>
                  <Field>
                    <Label>Cópia</Label>
                    <Row
                      style={{
                        marginTop: `${5}px`,
                        marginBottom: `${5}px`,
                      }}
                    >
                      <Col xs="9">
                        <Input
                          block
                          type="email"
                          value={newCopy}
                          onChange={e => setNewCopy(e.target.value)}
                          error={!!accountErrors.copy}
                        />
                      </Col>
                      <Col xs="2">
                        <Button
                          width="100%"
                          icon
                          onClick={() => addCopy(account, setAccount)}
                        >
                          <FontAwesomeIcon
                            style={{ position: 'initial' }}
                            icon={faPlus}
                          />
                        </Button>
                      </Col>
                    </Row>

                    {account?.copy?.length ? (
                      account?.copy.map(copy => {
                        return (
                          <Value
                            key={copy}
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                            }}
                          >
                            {copy}{' '}
                            <FontAwesomeIcon
                              onClick={() =>
                                deleteCopy(account, setAccount, copy)
                              }
                              style={{
                                position: 'initial',
                              }}
                              icon={faTimes}
                            />
                          </Value>
                        );
                      })
                    ) : (
                      <Value>Este email ainda não possui copias</Value>
                    )}
                    {accountErrors.copy && (
                      <Error>{accountErrors.copy[0]}</Error>
                    )}
                  </Field>

                  {addUser ? (
                    <Button
                      size="sm"
                      onClick={createAccount}
                      disabled={createLoading}
                      icon
                    >
                      {createLoading ? (
                        <FontAwesomeIcon icon={faSpinner} spin />
                      ) : (
                        'Adicionar'
                      )}
                    </Button>
                  ) : (
                    <div
                      style={{
                        display: 'flex',
                      }}
                    >
                      <Button
                        size="sm"
                        style={{
                          marginRight: '15px',
                        }}
                        onClick={updateAccount}
                        disabled={createLoading}
                        icon
                      >
                        {createLoading ? (
                          <FontAwesomeIcon icon={faSpinner} spin />
                        ) : (
                          'Salvar'
                        )}
                      </Button>
                      <Button
                        error
                        outline
                        size="sm"
                        onClick={() => setOpenDeleteAccountEmailModal(true)}
                      >
                        Deletar
                      </Button>
                    </div>
                  )}
                </Col>
                <Col sm="6">
                  <Row
                    style={{
                      height: 81,
                    }}
                  >
                    <Col>
                      <Field>
                        <Label
                          style={{
                            marginBottom: '8px',
                          }}
                        >
                          Cota ilimitada
                        </Label>
                        <Checkbox
                          value={account.quota === 0}
                          checked={account.quota === 0}
                          onChange={e => {
                            setAccount({
                              ...account,
                              quota: e.target.value === 'true' ? '0' : 0,
                            });
                          }}
                          error={!!accountErrors.quota}
                        />
                        {accountErrors.quota && (
                          <Error>{accountErrors.quota[0]}</Error>
                        )}
                      </Field>
                    </Col>
                    {account.quota !== 0 && (
                      <Col>
                        <Field>
                          <Label>Cota</Label>
                          <Input
                            block
                            value={account.quota}
                            onChange={e =>
                              setAccount({
                                ...account,
                                quota: e.target.value,
                              })
                            }
                            error={!!accountErrors.quota}
                          />
                          <InputSufix>MB</InputSufix>

                          {accountErrors.quota && (
                            <Error>{accountErrors.quota[0]}</Error>
                          )}
                        </Field>
                      </Col>
                    )}
                  </Row>

                  {!addUser && (
                    <>
                      <Field>
                        <Label
                          style={{
                            display: 'flex',
                          }}
                        >
                          Valor de spam
                          <Hover
                            style={{
                              marginLeft: '4px',
                            }}
                          >
                            ?{' '}
                            <HoverText>
                              <b>Filtro de spams</b>
                              :
                              <br /> <br />
                              <b>Bloqueio restritivo</b> - pode marcar como spam
                              algumas mensagens legítimas
                              <br />
                              <br />
                              <b>Bloqueio intermediário</b> - configuração
                              padrão
                              <br />
                              <br />
                              <b>Bloqueio permissivo</b> - pode liberar
                              mensagens de spam como legítimas
                            </HoverText>
                          </Hover>
                        </Label>
                        <Select
                          height="40px"
                          value={
                            !account.spam_score_delivery &&
                            !account.spam_score_deny
                              ? '0'
                              : account.spam_score_delivery === 150 &&
                                account.spam_score_deny === 200
                              ? '3'
                              : account.spam_score_delivery === 40 &&
                                account.spam_score_deny === 70
                              ? '2'
                              : account.spam_score_delivery === 15 &&
                                account.spam_score_deny === 40
                              ? '1'
                              : '0'
                          }
                          onChange={e => {
                            let delivery;
                            let deny;

                            if (e.target.value === '0') {
                              delivery = null;
                              deny = null;
                            } else if (e.target.value === '1') {
                              delivery = 15;
                              deny = 40;
                            } else if (e.target.value === '2') {
                              delivery = 40;
                              deny = 70;
                            } else if (e.target.value === '3') {
                              delivery = 150;
                              deny = 200;
                            }

                            setAccount({
                              ...account,
                              spam_score_delivery: delivery,
                              spam_score_deny: deny,
                            });
                          }}
                        >
                          <option value="0">Desligado</option>
                          <option value="1">Bloqueio restritivo</option>
                          <option value="2">Bloqueio intermediário</option>
                          <option value="3">Bloqueio permissivo</option>
                        </Select>
                      </Field>

                      <Row>
                        <Col>
                          <Field>
                            <Label
                              style={{
                                marginBottom: '8px',
                              }}
                            >
                              USO DE DISCO
                            </Label>
                            <Value>
                              {account.disk_usage
                                ? kbToMb(account.disk_usage)
                                : '0 B'}
                            </Value>
                          </Field>
                        </Col>
                      </Row>

                      <div
                        style={{
                          display: 'flex',
                        }}
                      >
                        <Button
                          outline={!account.is_disabled}
                          alert
                          size="sm"
                          style={{
                            marginRight: '15px',
                          }}
                          onClick={() => setOfflineModal(true)}
                        >
                          {account.is_disabled ? 'Habilitar' : 'Desabilitar'}
                        </Button>
                        <Button
                          outline
                          size="sm"
                          onClick={() => setAutoReplyModal(true)}
                        >
                          Autoreply
                        </Button>
                      </div>
                    </>
                  )}
                </Col>
              </Row>
            </UserDetail>
          </Col>
        )}
      </Row>
    </>
  );
};

export default Usuarios;
