import React from 'react';

import { faCloudUploadAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Modal } from '@cloudez/cloudez-design-system';
import SyncsTable from '../Syncs';
import { Title } from './styles';

type Syncs = {
  type: string;
  status: number;
  email: string;
};

type Migration = {
  migration_status: number;
  migration_target: string;
  dest_nodeapp: { domain: string };
  syncs: Syncs[];
};
interface SyncsModalProps {
  show: boolean;
  setShow: (value: boolean) => void;
  isRemoveBackup: boolean;
  migration: Migration;
}

const SyncsModal = ({
  show,
  setShow,
  isRemoveBackup,
  migration,
}: SyncsModalProps): JSX.Element => {
  return (
    <Modal
      style={{ padding: '19px 32px 73px 32px' }}
      width="562px"
      show={show}
      setShow={setShow}
    >
      <Title className="title">
        <FontAwesomeIcon icon={faCloudUploadAlt} />
        <div>
          <span>Migração:</span> {migration.dest_nodeapp.domain}
        </div>
      </Title>
      <div>
        <SyncsTable isRemoveBackup={isRemoveBackup} migration={migration} />
      </div>
    </Modal>
  );
};

export default SyncsModal;
