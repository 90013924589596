import React, { useState, useEffect, useContext } from 'react';
import copy from 'copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfo, faFlag, faTimes } from '@fortawesome/free-solid-svg-icons';

import { Row, Col } from 'react-bootstrap';
import {
  Field,
  Label,
  Select,
  TextArea,
  Button,
} from '@cloudez/cloudez-design-system';
import { Value } from 'components/Details';

import { ThemeContext } from 'styled-components';
import { User } from 'types/auth';
import {
  Wrapper,
  Info,
  CHS,
  Points,
  CHSColor,
  Point,
  RedAlert,
  Content,
  Target,
  TicketHeader,
} from './styles';
import { TicketDetailContext } from '../_context/state';

interface StaffInfoProps {
  ticket: any;
  user: User;
  clouds: any;
  target: any;
}

const StaffInfo: React.FC<StaffInfoProps> = ({
  ticket,
  user,
  clouds,
  target,
}) => {
  const theme = useContext(ThemeContext);
  const { setRedAlert, updateTicket } = useContext(TicketDetailContext);
  const [showInfo, setShowInfo] = useState(false);
  const [team, setTeam] = useState(ticket?.team ? ticket?.team : '');
  const [userComments, setUserComments] = useState(
    ticket?.user_comments ? ticket?.user_comments : '',
  );
  const [comments, setComments] = useState(
    ticket?.comments ? ticket?.comments : '',
  );
  const [userType, setUserType] = useState(
    ticket?.user_type ? ticket?.user_type : '',
  );

  const userTypes = [
    { id: 1, title: 'C1' },
    { id: 2, title: 'C2' },
    { id: 3, title: 'C3' },
    { id: 4, title: 'C4' },
    { id: 5, title: 'C5' },
    { id: 6, title: 'C6' },
  ];

  const teams = [
    { id: 1, title: 'Suporte' },
    { id: 2, title: 'Financeiro' },
    { id: 3, title: 'Comercial' },
    { id: 4, title: 'Migração' },
    { id: 5, title: 'Spam' },
    { id: 6, title: 'Alertas' },
    { id: 7, title: 'Painel' },
    { id: 0, title: 'Outros' },
  ];

  useEffect(() => {
    if (showInfo) {
      document.addEventListener('keydown', e => {
        if (e.keyCode === 27) {
          setShowInfo(false);
        }
      });
    }
  }, [showInfo]);

  return (
    <Wrapper
      showInfo={showInfo}
      onClick={() => !showInfo && setShowInfo(!showInfo)}
    >
      {showInfo && clouds ? (
        <>
          <TicketHeader>
            <Info>
              <FontAwesomeIcon
                icon={faTimes}
                onClick={() => setShowInfo(false)}
              />
              <h2>Informações do cliente</h2>
            </Info>
            <Target>
              {ticket?.target_type !== '' && ticket?.target_id && (
                <>
                  <a
                    rel="noopener noreferrer"
                    target="_blank"
                    href={`/${ticket?.target_type}s/${ticket?.target_id}`}
                  >
                    {ticket?.target_type}
                  </a>
                  {ticket?.target_type !== 'domain' && (
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={`/clouds/${
                        ticket?.target_type === 'cloud'
                          ? target?.id
                          : ticket?.target_type === 'website'
                          ? target?.cloud?.id
                          : target?.cloud
                      }`}
                    >
                      cloud
                    </a>
                  )}
                </>
              )}
            </Target>
            <CHS>
              CHS
              <Points>
                <Point>
                  FINANCEIRO{' '}
                  <span>
                    <CHSColor point={ticket?.owner_chs?.finance} finance />{' '}
                    {ticket?.owner_chs?.finance}
                  </span>
                </Point>
                <Point>
                  SUPORTE{' '}
                  <span>
                    <CHSColor point={ticket?.owner_chs?.support} />
                    {ticket?.owner_chs?.support}
                  </span>
                </Point>
                <Point>
                  PRODUTO{' '}
                  <span>
                    <CHSColor point={ticket?.owner_chs?.product} />
                    {ticket?.owner_chs?.product}
                  </span>
                </Point>
              </Points>
            </CHS>
            <RedAlert
              active={ticket?.is_red_alert}
              onClick={async () => {
                await setRedAlert(ticket?.id);
              }}
            >
              <FontAwesomeIcon icon={faFlag} />
            </RedAlert>
          </TicketHeader>

          <Content>
            <Row>
              <Col>
                <Field>
                  <Label>Responsável</Label>
                  <Value
                    style={{
                      cursor: 'pointer',
                      marginTop: '-5px',
                    }}
                  >
                    {ticket?.responsible_email}
                  </Value>
                </Field>
                <Field>
                  <Label>Email</Label>
                  <Value
                    style={{
                      cursor: 'pointer',
                      marginTop: '-5px',
                    }}
                    onClick={() => copy(user?.email)}
                  >
                    {user?.email || ticket?.owner_email}
                  </Value>
                </Field>
                <Field>
                  <Label>Status</Label>
                  <Value
                    style={{
                      marginTop: '-5px',
                    }}
                  >
                    {ticket?.owner_status
                      ? `Bloqueado em: ${ticket?.owner_status}`
                      : 'Em dia'}
                  </Value>
                </Field>
                <Field>
                  <Label>Teste iniciado em:</Label>
                  <Value
                    style={{
                      marginTop: '-5px',
                    }}
                  >
                    {ticket?.owner_trial_asked
                      ? ticket?.owner_trial_asked
                      : 'Não pediu teste'}
                  </Value>
                </Field>
                <Field>
                  <Label>Company</Label>
                  <Value
                    style={{
                      marginTop: '-5px',
                    }}
                  >
                    {ticket?.owner_company.company}
                  </Value>
                </Field>
                <Field>
                  <Label>Selo</Label>
                  <Value
                    style={{
                      marginTop: '-5px',
                    }}
                  >
                    {ticket?.owner_company.cloud_provider}
                  </Value>
                </Field>
                <Field>
                  <Label>Whitelabel</Label>
                  <Value
                    style={{
                      marginTop: '-5px',
                    }}
                  >
                    {ticket?.owner_company.app_domain}
                  </Value>
                </Field>
                <Field>
                  <Label>Dono</Label>
                  <Value
                    style={{
                      marginTop: '-5px',
                    }}
                  >
                    {ticket?.owner_company.company_owner}
                  </Value>
                </Field>
                <Field>
                  <Label>Feedback do cliente</Label>
                  <Value
                    style={{
                      marginTop: '-5px',
                    }}
                  >
                    {ticket?.rating === 0
                      ? 'Sem feedback'
                      : ticket?.rating === 1
                      ? 'Curtiu'
                      : ticket?.rating === 2
                      ? 'Não curtiu'
                      : ''}
                  </Value>
                </Field>

                {ticket?.resource && ticket?.resource.type === 'website' && (
                  <>
                    <Field>
                      <Label>Migração</Label>
                      <Value
                        style={{
                          marginTop: '-5px',
                        }}
                      >
                        {ticket?.resource.domain}
                      </Value>
                    </Field>
                    <Field>
                      <Label>Temporário</Label>
                      <Value
                        style={{
                          marginTop: '-5px',
                        }}
                      >
                        {ticket?.resource.temporary_address}
                      </Value>
                    </Field>
                  </>
                )}
                {ticket?.resource && ticket?.resource.type === 'email' && (
                  <>
                    <Field>
                      <Label>Migração</Label>
                      <Value
                        style={{
                          marginTop: '-5px',
                        }}
                      >
                        {ticket?.resource.domain}
                      </Value>
                    </Field>
                    <Field>
                      <Label>Webmail</Label>
                      <Value
                        style={{
                          marginTop: '-5px',
                        }}
                      >
                        {ticket?.resource.webmail}
                      </Value>
                    </Field>
                  </>
                )}
                <Field>
                  <Label>Tipo de cliente</Label>
                  <div>
                    <Select
                      value={userType}
                      placeholder="Escolha um tipo"
                      width="195px"
                      onChange={e => {
                        setUserType(e.target.value);
                      }}
                      height="40px"
                    >
                      <option value="">Escolha um tipo</option>
                      {userTypes.map(u => (
                        <option value={u.id} key={u.id}>
                          {u.title}
                        </option>
                      ))}
                    </Select>
                    <Button
                      width="100px"
                      onClick={async () => {
                        await updateTicket('user_type', userType, ticket?.id);
                      }}
                    >
                      Alterar
                    </Button>
                  </div>
                </Field>
                <Field>
                  <Label>Alterar setor</Label>
                  <div>
                    <Select
                      value={team}
                      placeholder="Escolha um setor"
                      width="195px"
                      height="40px"
                      onChange={e => {
                        setTeam(e.target.value);
                      }}
                    >
                      <option value="">Escolha um time</option>
                      {teams.map(u => (
                        <option value={u.id} key={u.id}>
                          {u.title}
                        </option>
                      ))}
                    </Select>
                    <Button
                      width="100px"
                      onClick={async () => {
                        await updateTicket('team', team, ticket?.id);
                      }}
                    >
                      Alterar
                    </Button>
                  </div>
                </Field>
              </Col>
              <Col>
                <Field>
                  <Label>
                    Clouds - displayname / fqdn / ip / turbo or default
                  </Label>
                  <div
                    style={{
                      padding: '5px 10px',
                      border: `2px solid ${
                        theme.darkMode ? theme.interface2 : theme.interface3
                      }`,
                      height: '100px',
                      overflow: 'auto',
                      borderRadius: '5px',
                      fontSize: '14px',
                    }}
                  >
                    <ul>
                      {clouds.map(c => (
                        <li
                          key={c}
                          style={{
                            marginBottom: '8px',
                          }}
                        >
                          <span
                            style={{
                              cursor: 'pointer',
                              color: theme.interface5,
                              fontSize: '12px',
                              display: 'inline',
                            }}
                            onClick={e => copy(c.name)}
                          >{`${c.nickname || c.name} - ${
                            c.cloud_size.name
                          }`}</span>{' '}
                          /{' '}
                          <span
                            style={{
                              cursor: 'pointer',
                              color: theme.interface5,
                              fontSize: '12px',
                              display: 'inline',
                            }}
                            onClick={e => copy(`${c.name}.${c.domain}`)}
                          >{`${c.name}.${c.domain}`}</span>{' '}
                          /{' '}
                          <span
                            style={{
                              cursor: 'pointer',
                              color: theme.interface5,
                              fontSize: '12px',
                              display: 'inline',
                            }}
                            onClick={e => copy(c.ipaddr)}
                          >{`${c.ipaddr}`}</span>{' '}
                          /{' '}
                          <span
                            style={{
                              cursor: 'pointer',
                              color: theme.interface5,
                              fontSize: '12px',
                              display: 'inline',
                            }}
                          >
                            {c?.is_turbo?.toString()}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                </Field>

                <Field>
                  <Label>Recursos compartilhados</Label>
                  <div
                    style={{
                      padding: '5px 10px',
                      border: `2px solid ${
                        theme.darkMode ? theme.interface2 : theme.interface3
                      }`,
                      height: '100px',
                      overflow: 'auto',
                      borderRadius: '5px',
                      fontSize: '14px',
                      display: 'block',
                    }}
                  >
                    <Value>
                      <b>Websites:</b>
                      {ticket?.owner_shared_resources.websites.map(w => (
                        <p key={w}>{w}</p>
                      ))}
                    </Value>
                    <Value>
                      <b>Emails:</b>
                      {ticket?.owner_shared_resources.emails.map(e => (
                        <p key={e}>{e}</p>
                      ))}
                    </Value>
                    <Value>
                      <b>Databases:</b>
                      {ticket?.owner_shared_resources.databases.map(d => (
                        <p key={d}>{d}</p>
                      ))}
                    </Value>
                    <Value>
                      <b>Domínios:</b>
                      {ticket?.owner_shared_resources.domains.map(d => (
                        <p key={d}>{d}</p>
                      ))}
                    </Value>
                  </div>
                </Field>
                <Field>
                  <Label>Comentários deste ticket</Label>
                  <TextArea
                    block
                    height="60px"
                    value={comments}
                    onChange={e => {
                      setComments(e.target.value);
                    }}
                  />
                  <Button
                    style={{
                      marginTop: '4px',
                    }}
                    size="sm"
                    onClick={async () => {
                      await updateTicket('comments', comments, ticket?.id);
                    }}
                  >
                    Atualizar
                  </Button>
                </Field>
                <Field>
                  <Label>Comentários do usuário neste ticket</Label>
                  <TextArea
                    block
                    height="60px"
                    value={userComments}
                    onChange={e => {
                      setUserComments(e.target.value);
                    }}
                  />
                  <Button
                    style={{
                      marginTop: '4px',
                    }}
                    size="sm"
                    onClick={async () => {
                      await updateTicket(
                        'user_comments',
                        userComments,
                        ticket?.id,
                      );
                    }}
                  >
                    Atualizar
                  </Button>
                </Field>
              </Col>
            </Row>
          </Content>
        </>
      ) : (
        <FontAwesomeIcon icon={faInfo} />
      )}
    </Wrapper>
  );
};

export default StaffInfo;
