import React, { useEffect, useContext, useState, useMemo } from 'react';

import { Row, Col } from 'react-bootstrap';

import SearchBar from 'components/SearchBar';
import {
  Pagination,
  Loading,
  Button,
  createPagination,
} from '@cloudez/cloudez-design-system';
import { getCloudsService } from 'services/cloud';
import CloudCard from 'components/CloudCard';
import EmptyListing from 'components/EmptyListing';
import { useHistory } from 'react-router-dom';
import { NewCloud, Recommended } from 'components/CloudCard/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import { getWebsiteTypeService } from 'services/website';
import { faCrown } from '@fortawesome/free-solid-svg-icons';
import {
  Application,
  Recommendation,
  SelectedApplication,
  Wrapper,
} from './styles';
import { WebsiteCreateContext } from '../_context/state';

const reqSvgs = require.context('assets/img/svg', true, /\.svg$/);

const Cloud: React.FC = () => {
  const { website, updateWebsiteField, setNotRecommended } =
    useContext(WebsiteCreateContext);

  const [cloud, setCloud] = useState(null);
  const [search, setSearch] = useState('');
  const [cloudLoading, setCloudLoading] = useState(false);
  const [type, setType] = useState(null);
  const history = useHistory();

  const icon = useMemo(() => {
    if (type) {
      try {
        return reqSvgs(`./${type.slug}.svg`).default;
      } catch (e) {
        return reqSvgs('./custom.svg').default;
      }
    }

    return null;
  }, [type]);

  const getCloudsCallback = async (page, search) => {
    setCloudLoading(true);
    try {
      const { data } = await getCloudsService({
        page_size: 3,
        page,
        search,
        recommended: true,
        noemail: true,
      });

      const pagination = createPagination(data, 3);
      setCloud({ clouds: data.results, ...pagination });
      setCloudLoading(false);
    } catch (e) {
      setCloudLoading(false);
    }
  };

  useEffect(() => {
    async function getWebsiteType() {
      const { data } = await getWebsiteTypeService(website.type);

      setType(data);
    }

    getWebsiteType();
  }, []);

  useEffect(() => {
    getCloudsCallback(1, ''); // eslint-disable-next-line
  }, []);

  const searchValue = searchValue => {
    getCloudsCallback(1, searchValue);
    setSearch(searchValue);
  };

  const recommendedClouds = cloud?.clouds
    ?.filter(cloud => {
      if (type?.min_ram <= cloud.cloud_size.memory[0] && cloud.websites === 0) {
        return true;
      }

      return cloud.has_disk_space && cloud.free_mem >= type?.min_ram * 1048576;
    })
    .map(cloud => cloud.id);

  const [isNewRecommended, setIsNewRecommended] = useState(null);

  useEffect(() => {
    if (isNewRecommended === null && recommendedClouds) {
      setIsNewRecommended(recommendedClouds.length === 0);
    }
  }, [recommendedClouds]);

  return (
    <Wrapper>
      <Row
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
        className="mb-5"
      >
        <SelectedApplication>
          <Application>
            {type && <img src={icon} alt="App Icon" />}

            <span className="application-name">
              Sua nova aplicação é um {type?.name}.
            </span>

            <p className="application-description">{type?.full_description}</p>
          </Application>

          <Recommendation>
            <span className="title">Recomendação</span>
            <p className="description">
              Para melhores resultados em performance dessa aplicação, sugerimos
              a seguinte configuração:
            </p>
            <span className="memory">
              <b>Memória:</b> Mínimo {type?.min_ram}GB de RAM
            </span>
          </Recommendation>
        </SelectedApplication>
      </Row>
      <Row>
        <SearchBar
          callback={searchValue}
          value={search}
          noResults={cloud?.clouds?.length === 0 && !!search}
        />
      </Row>
      {cloudLoading ? (
        <Loading />
      ) : (
        <>
          <Row
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
            data-id="websites-clouds"
          >
            <Col
              lg="3"
              xs="12"
              md="4"
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {cloud?.clouds.length > 0 && (
                <NewCloud onClick={() => history.push('/clouds/create')}>
                  {isNewRecommended && (
                    <Recommended>
                      <FontAwesomeIcon icon={faCrown} />
                      <span>Recomendado</span>
                    </Recommended>
                  )}
                  <span>ADQUIRIR NOVO CLOUD</span>
                  <FontAwesomeIcon icon={faPlusSquare} />
                </NewCloud>
              )}
            </Col>
            {cloud?.clouds?.map(
              cloud =>
                recommendedClouds.includes(cloud.id) && (
                  <Col
                    key={cloud.id}
                    lg="3"
                    xs="12"
                    md="4"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <CloudCard
                      cloud={cloud}
                      selected={cloud.id === website.cloud}
                      onClick={() => {
                        if (!recommendedClouds.includes(cloud.id)) {
                          const hasMem =
                            cloud.free_mem >= type?.min_ram * 1048576;

                          const message = `${
                            !cloud.has_disk_space
                              ? 'está com pouco espaço em disco '
                              : ''
                          }${!cloud.has_disk_space && !hasMem ? ' e ' : ''}${
                            !hasMem
                              ? 'não cumpre com a recomendação da aplicação selecionada'
                              : ''
                          }`;

                          setNotRecommended({
                            open: true,
                            message: `O Cloud "${
                              cloud.nickname || cloud.name
                            }" ${message}.`,
                          });
                        } else {
                          setNotRecommended({
                            open: false,
                            message: '',
                          });
                        }

                        updateWebsiteField('cloud', cloud.id);
                      }}
                      recommended={recommendedClouds.includes(cloud.id)}
                    />
                  </Col>
                ),
            )}
            {cloud?.clouds?.map(
              cloud =>
                !recommendedClouds.includes(cloud.id) && (
                  <Col
                    key={cloud.id}
                    lg="3"
                    xs="12"
                    md="4"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <CloudCard
                      cloud={cloud}
                      selected={cloud.id === website.cloud}
                      onClick={() => {
                        if (!recommendedClouds.includes(cloud.id)) {
                          const hasMem =
                            cloud.free_mem >= type?.min_ram * 1048576;

                          const message = `${
                            !cloud.has_disk_space
                              ? 'está com pouco espaço em disco '
                              : ''
                          }${!cloud.has_disk_space && !hasMem ? ' e ' : ''}${
                            !hasMem
                              ? 'não cumpre com a recomendação da aplicação selecionada'
                              : ''
                          }`;

                          setNotRecommended({
                            open: true,
                            message: `O Cloud "${
                              cloud.nickname || cloud.name
                            }" ${message}.`,
                          });
                        } else {
                          setNotRecommended({
                            open: false,
                            message: '',
                          });
                        }

                        updateWebsiteField('cloud', cloud.id);
                      }}
                      recommended={recommendedClouds.includes(cloud.id)}
                    />
                  </Col>
                ),
            )}
          </Row>
          {cloud?.clouds?.length === 0 && (
            <EmptyListing>
              <p>Nenhum cloud encontrado.</p>
              <Button onClick={() => history.push('/clouds/create')} size="lg">
                Criar novo cloud
              </Button>
            </EmptyListing>
          )}
          <Row>
            <Pagination
              onChangePage={getCloudsCallback}
              pageSize={3}
              search={search}
              count={cloud?.count}
              previous={cloud?.previous}
              next={cloud?.next}
              current={cloud?.current}
            />
          </Row>
        </>
      )}
    </Wrapper>
  );
};

export default Cloud;
