import styled from 'styled-components';
import { Button } from '@cloudez/cloudez-design-system';

export const Prompt = styled.h3`
  color: ${props => props.theme.interface5};
  padding: 8px 15px 20px 0;
`;

export const LoginLink = styled.span`
  cursor: pointer;
  text-decoration: underline;
  font-weight: bold;
  color: ${props => props.theme.interface5};
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: center;
  ${Button} {
    margin-left: 20px;
  }
`;

interface WrapperProps {
  isFocused?: boolean;
  disabled?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  background: ${props => props.theme.interface1};
  margin-bottom: 10px;
  margin-top: 30px;
  width: 100%;
  height: auto;
  padding: 20px 0px 10px 0px;
  position: relative;

  .saveCard {
    font-family: 'Lato';
    font-weight: 400;
    font-size: 14px;

    color: ${props => props.theme.interface5};
  }

  h3 {
    color: ${props => props.theme.interface5};

    padding-left: 15px;
    padding-right: 15px;

    p {
      font-size: 12px;
      font-weight: normal;
    }
  }

  & > p {
    padding: 8px 15px 20px 15px;
  }

  opacity: ${props => (!props.disabled ? '1' : '0.4')};

  ${props =>
    props.disabled &&
    `&:after {
      content: '';
      position: absolute;

      width: 100%;
      height: 100%;

      top: 0;
      left: 0;
      cursor: not-allowed;
  }`}
`;

export const Total = styled.div`
  margin-right: 10px;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  & > span {
    color: ${props => props.theme.successNew};
    font-size: 28px;
    font-weight: 700;
  }

  & > p {
    color: ${props => props.theme.interface5};
    font-size: 16px;
    margin-bottom: 5px;
    margin-right: 7px;
  }
`;

export const LoadingIframeBox = styled.div`
  overflow: none;

  width: 100%;
  height: 520px;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 40px;
    color: ${props => props.theme.brand_primary_color};
  }
`;
