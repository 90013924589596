import React from 'react';
import Placeholder from 'components/Placeholder';
import { Col } from 'react-bootstrap';
import { Card } from './styles';

const Price = () => <Placeholder height={14} width={45} />;

const DomainName = () => <Placeholder height={14} width={70} />;

const DomainPlaceholder = () => {
  return (
    <>
      <Col xl="12">
        <Card>
          <DomainName />
          <Price />
        </Card>
      </Col>
      <Col xl="12">
        <Card>
          <DomainName />
          <Price />
        </Card>
      </Col>
      <Col xl="12">
        <Card>
          <DomainName />
          <Price />
        </Card>
      </Col>
    </>
  );
};

export default DomainPlaceholder;
