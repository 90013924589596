import styled from 'styled-components';

export const Title = styled.p`
  font-weight: bold;
  font-size: 16px;
  color: ${props => props.theme.interface5};
`;

export const Grid = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(2, 1fr);

  div {
    border: 1px solid ${props => props.theme.interface3};
    border-radius: 3px;
    padding: 10px;

    text-align: center;

    font-weight: normal;
    font-size: 12px;
    color: ${props => props.theme.interface5};

    span {
      display: block;

      font-weight: bold;
      font-size: 24px;
      text-align: center;
    }
  }
`;

export const ListItem = styled.li`
  padding: 10px 0;

  width: 100%;

  font-weight: normal;
  font-size: 14px;
  color: #64728c;

  display: flex;
  align-items: center;

  svg {
    font-size: 18px;
    color: ${props => props.theme.interface4};
  }

  div {
    height: 16px;
    width: 1.6px;
    background-color: ${props => props.theme.interface3};

    margin: 0 26px 0 8px;
  }
`;

interface ValueProps {
  score?: number | string;
  loadingTime?: number | string;
}

export const Value = styled.span<ValueProps>`
  color: ${props => {
    const { score, loadingTime } = props;

    if (loadingTime) {
      if (score <= 1.8) {
        return props.theme.successNew;
      }
      if (score <= 3) {
        return props.theme.alertNew;
      }
      return props.theme.errorNew;
    }

    if (score <= 50) {
      return props.theme.errorNew;
    }
    if (score > 50 && score <= 65) {
      return props.theme.alertNew;
    }
    if (score > 65 && score <= 80) {
      return props.theme.favoriteNew;
    }
    if (score > 80 && score <= 100) {
      return props.theme.successNew;
    }
  }} !important;
`;
