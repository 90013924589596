import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import useDevice from 'hooks/device';
import {
  Dot,
  Line,
  ProgressBar,
  ProgressContainer,
  StatusLabel,
} from './styles';

interface ProgressProps {
  status?: number | string;
  n?: number;
  labels?: any[];
}

const Progress: React.FC<ProgressProps> = ({ status, n, labels = [] }) => {
  const array = Array.from({ length: n }, (_, i) => i + 1);

  const { isMobile } = useDevice();
  return (
    <ProgressContainer>
      <ProgressBar>
        <Dot done={status >= 1}>
          {status === 1 ? (
            1
          ) : status > 1 ? (
            <FontAwesomeIcon icon={faCheck} />
          ) : (
            ''
          )}
          {labels && status !== 1 && !isMobile && (
            <StatusLabel>{labels[0]}</StatusLabel>
          )}
        </Dot>
        {array.map((_, n) => {
          const _status = n + 1;
          return (
            _status !== 1 && (
              <Fragment key={n}>
                <Line />
                <Dot done={status >= _status}>
                  {status > _status ? (
                    <FontAwesomeIcon icon={faCheck} />
                  ) : (
                    _status
                  )}
                  {labels && status !== n + 1 && !isMobile && (
                    <StatusLabel>{labels[n]}</StatusLabel>
                  )}
                </Dot>
              </Fragment>
            )
          );
        })}
      </ProgressBar>
    </ProgressContainer>
  );
};

export default Progress;
