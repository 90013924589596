import React, {
  useContext,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faSpinner } from '@fortawesome/free-solid-svg-icons';

import {
  Modal,
  Pagination,
  Button,
  createPagination,
} from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from 'components/ListingModal/styles';

import ApplicationIcon from 'components/ApplicationIcon';
import { getWebsitesService } from 'services/website';
import SearchInput from 'components/SearchInput';
import { ModalProps } from 'types';
import { DatabaseDetailContext } from '../../_context/state';
import { Card } from './styles';

import WebsitePlaceholder from './WebsitePlaceholder';

interface IConnectModal extends ModalProps {
  setWebsites: any;
  websites: any;
}

const ConnectModal: React.FC<IConnectModal> = ({
  show,
  setShow,
  setWebsites: _setWebsites,
  websites: _websites,
}) => {
  const { database, patchDatabase, updateLoading } = useContext(
    DatabaseDetailContext,
  );

  const [selected, setSelected] = useState({} as any);
  const [websites, setWebsites] = useState(null);
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);

  const getWebsitesCallback = async (page, search) => {
    async function getWebsites() {
      try {
        setLoading(true);
        const { data } = await getWebsitesService({
          cloud: database.cloud,
          page_size: 6,
          page,
          search,
        });

        const pagination = createPagination(data, 6);

        setWebsites({ websites: data.results, ...pagination });
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
    }

    getWebsites();
  };

  useEffect(() => {
    getWebsitesCallback(1, '');
    // eslint-disable-next-line
  }, []);

  const searchValue = useCallback(searchValue => {
    getWebsitesCallback(1, searchValue);
    // eslint-disable-next-line
  }, []);

  const connectWebsite = useCallback(async () => {
    try {
      await patchDatabase(
        'websites',
        [...database.websites, selected.id],
        database.id,
      );

      _setWebsites([..._websites, selected]);

      setShow(false);
    } catch (e) {}
    // eslint-disable-next-line
  }, [database, selected, _websites]);

  const noResults = useMemo(() => websites?.websites?.length === 0, [websites]);

  return (
    <Modal show={show} setShow={setShow} noExit={updateLoading}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faLock} />
        </Icon>
        <Text>
          <Title>Conectar website</Title>
          <Subtitle>Deseja conectar um website a este database?</Subtitle>
        </Text>
      </Header>

      <Content>
        <Row
          style={{
            margin: 0,
            width: '100%',
            marginBottom: '10px',
          }}
        >
          <Col xs>
            <SearchInput
              style={{
                width: '100%',
              }}
              value={search}
              callback={searchValue}
            />
          </Col>
        </Row>
        <Row
          style={{
            margin: 0,
            width: '100%',
          }}
        >
          {loading ? (
            <WebsitePlaceholder />
          ) : (
            websites?.websites?.map(w => (
              <Col
                key={w.id}
                sm="6"
                xs="12"
                style={{
                  width: '100%',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <Card
                  key={w.id}
                  selected={w.id === selected.id}
                  onClick={() => {
                    setSelected(w);
                  }}
                >
                  <ApplicationIcon
                    icon={w.type.slug}
                    width="45px"
                    height="45px"
                  />
                  <div
                    style={{
                      textAlign: 'center',
                      width: '100%',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                    }}
                  >
                    <span>{w.domain || w.name}</span>
                  </div>
                  <div
                    style={{
                      textAlign: 'center',
                      width: '100%',
                    }}
                  >
                    <p>{w.cloud.display}</p>
                  </div>
                </Card>
              </Col>
            ))
          )}
        </Row>

        <Row
          style={{
            width: '100%',
            margin: 0,
          }}
        >
          <Col xs>
            <Pagination
              style={{
                width: '100%',
                margin: '0 auto',
              }}
              onChangePage={getWebsitesCallback}
              pageSize={6}
              search={search}
              count={websites?.count}
              previous={websites?.previous}
              next={websites?.next}
              current={websites?.current}
            />
          </Col>
        </Row>
      </Content>

      <Footer>
        <Button onClick={connectWebsite} disabled={updateLoading} icon>
          {updateLoading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            'Conectar'
          )}
        </Button>
      </Footer>
    </Modal>
  );
};

export default ConnectModal;
