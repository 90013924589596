import styled from 'styled-components';

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  background: ${props => props.color};

  svg {
    color: #fff !important;
  }
`;
