import styled from 'styled-components';

export const Title = styled.p`
  font-weight: bold;
  font-size: 16px;
  color: ${props => props.theme.interface5};
`;

interface InfoProps {
  sub: boolean;
}

export const Info = styled.ul<InfoProps>`
  width: 60%;
  font-size: ${props => (props.sub ? '13px' : '17px')};
  color: ${props => props.theme.interface5};
  display: flex;

  justify-content: space-between;
  margin-bottom: 20px;

  & > li {
    span {
      font-size: 11px;
      font-weight: normal;
    }

    p {
      font-size: 15px;
      font-weight: bold;
      height: unset;
    }
  }
`;
