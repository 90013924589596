import styled, { css } from 'styled-components';

interface ICardProps {
  mobileOpen?: boolean;
}

export const Card = styled.div<ICardProps>`
  border-radius: 5px;

  background: ${props => props.theme.interface1};

  box-shadow: 0px 2px 15px rgba(100, 114, 140, 0.25);

  width: 274px;

  div.section {
    padding: 13px 15px;

    h3 {
      font-size: 14px;
      color: ${props =>
        props.theme.darkMode ? props.theme.interface5 : props.theme.interface7};
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 20px;
    }

    div.name-mem {
      color: ${props => props.theme.brand_primary_color};
      font-size: 14px;
      font-weight: bold;

      display: flex;
      justify-content: space-between;
      margin-bottom: 18px;
    }

    p.tip {
      color: ${props => props.theme.interface5};
      font-size: 12px;
    }

    div.stats {
      padding: 0px 10px;
      margin-top: 15px;
      display: flex;
      justify-content: space-between;

      h4 {
        color: ${props => props.theme.interface5};
        font-size: 10px;
      }

      span {
        font-size: 14px;
        color: ${props => props.theme.interface5};
      }
    }

    div.recurrency {
      display: flex;
      justify-content: space-between;

      span {
        font-size: 16px;
        color: ${props => props.theme.interface5};
        text-transform: uppercase;
      }

      a {
        color: ${props => props.theme.interface5};
        text-decoration: underline;
        cursor: pointer;
      }
    }

    div.type {
      display: flex;
      justify-content: space-between;
      align-items: center;

      span:nth-child(1) {
        font-size: 16px;
        color: ${props => props.theme.interface5};
        text-transform: uppercase;
      }

      span:nth-child(2) {
        font-weight: 700;
        font-size: 18px;
        color: ${props => props.theme.successNew};
      }
    }

    div.total {
      text-align: right;
      color: ${props => props.theme.successNew};
      font-weight: 700;
      font-size: 24px;

      @media (max-width: 768px) {
        text-align: left;
      }
    }

    div.nodiscount {
      text-decoration: line-through;
    }

    div.discount,
    div.nodiscount {
      text-align: right;
      font-size: 14px;
      font-weight: 700;
      color: ${props => props.theme.interface5};
    }
  }

  div.section + div.section {
    border-top: 1px solid
      ${props =>
        props.theme.darkMode ? props.theme.interface6 : props.theme.interface3};
  }

  div.mobile-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    padding: 20px 15px;
    color: ${props => props.theme.interface5};
    font-weight: 400;
    font-size: 16px;
    border-bottom: 1px solid
      ${props =>
        props.theme.darkMode ? props.theme.interface6 : props.theme.interface3};

    div {
      span.total-label {
        color: ${props => props.theme.interface5};
        font-weight: 400;
        font-size: 16px;
      }
      span.total-value {
        color: ${props => props.theme.brand_primary_color};
        font-weight: 700;
      }

      svg {
        margin-left: 12px;
        color: ${props => props.theme.brand_primary_color};
        font-size: 18px;
      }
    }
  }

  div.swipe {
    padding: 20px;
    height: 100%;
    border-top: ${props => props.theme.interface3};

    display: flex;
    justify-content: center;
    align-items: center;

    margin-top: auto;

    div.line {
      width: 50px;
      border-radius: 50px;
      height: 4px;
      background: ${props => props.theme.interface5};
    }
  }

  @media (max-width: 768px) {
    margin-left: -30px;

    width: 100vw;

    overflow: auto;

    position: ${props => (props.mobileOpen ? 'fixed' : 'sticky')};
    ${props =>
      props.mobileOpen &&
      css`
        top: 0;
        bottom: 0;
        overflow-y: scroll;
        overflow-x: hidden;
      `}

    height: ${props => (props.mobileOpen ? '100%' : 'auto')};

    ${props =>
      !props.mobileOpen &&
      css`
        margin-bottom: 30px;
      `}
  }
`;

export const Title = styled.h1`
  color: ${props => (props.theme.darkMode ? props.theme.interface5 : '#333')};
  font-size: 28px;

  margin-bottom: 25px;
`;
