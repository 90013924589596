import React, { useMemo, useCallback, useContext, useRef } from 'react';
import { Row, Col } from 'react-bootstrap';
import { Button, Checkbox } from '@cloudez/cloudez-design-system';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ThemeContext } from 'styled-components';

import { Link } from 'react-router-dom';

import { toast } from 'react-toastify';

import { Field, Label, Separator } from 'components/Details';
import NodeappField from 'components/NodeappField';

import { cleanCDNCacheService } from 'services/website';
import { getValue } from 'utils/getValue';
import { useAuth } from 'hooks/auth';
import { NodeAppType } from 'types';
import { WebsiteDetailContext } from '../_context/state';
import { CDNInfo } from './CDN';

interface ICDNProps {
  type: NodeAppType;
  onChange(e: Event): void;
  firstActive?: boolean;
}

const Performance: React.FC<ICDNProps> = ({ type, onChange, firstActive }) => {
  const { website } = useContext(WebsiteDetailContext);
  const theme = useContext(ThemeContext);
  const { user } = useAuth();

  const { fieldsLeft, fieldsRight } = useMemo(() => {
    const fieldsLeft = [
      'cdn_develop_mode',
      'cdn_smartcache',
      'cache_ttl',
      'cdn_waf',
      'smartcache_ttl',
      'image_optimization',
    ];

    const fieldsRight = ['cache_expire', 'pagespeed'];

    return { fieldsLeft, fieldsRight };
  }, []);

  const { use_cdn, cdn_smartcache } = useMemo(() => {
    const use_cdn = getValue(website, 'use_cdn');
    const cdn_smartcache = getValue(website, 'cdn_smartcache');

    return {
      use_cdn,
      cdn_smartcache,
    };
  }, [website]);

  const clearCache = useCallback(async () => {
    try {
      await cleanCDNCacheService(website.id);
      toast.success('Cache limpo com sucesso!');
    } catch (error) {
      toast.error('Não foi possível limpar o cache.');
    }
  }, [website]);

  return (
    <Row>
      <Col xl>
        {use_cdn && (
          <Field>
            <Label
              style={{
                marginBottom: '4px',
              }}
            >
              USAR CDN
            </Label>
            <div
              style={
                use_cdn === 'true' && firstActive
                  ? {
                      display: 'initial',
                      padding: '5px 15px',
                      borderRadius: '20px',
                      background: '#2253F526',
                    }
                  : {}
              }
            >
              <Checkbox
                disabled={!website.can_cdn}
                name="use_cdn"
                checked={use_cdn === 'true'}
                onChange={onChange}
                style={{
                  marginRight: '10px',
                }}
              />
              {use_cdn === 'true' && firstActive ? (
                <>
                  <FontAwesomeIcon
                    icon={faSpinner}
                    style={{ margin: '0 10px' }}
                    spin
                  />
                  <span>Aplicando... (10 a 15 minutos)</span>
                </>
              ) : (
                ''
              )}
            </div>
            <CDNInfo />
            {!website.can_cdn && (
              <p
                style={{
                  color: theme.interface4,
                  fontSize: '11px',
                  fontStyle: 'italic',
                  maxWidth: 305,
                }}
              >
                Para ter acesso ao recurso da CDN é necessário que seu Cloud
                seja do tipo <b>Padrão</b>. Não é possível ativar a CDN em um
                Cloud do tipo <b>E-mail</b> ou <b>Volume</b>.
              </p>
            )}
          </Field>
        )}
        {use_cdn === 'true' && (
          <>
            {website.values.map((v, i) => {
              if (fieldsLeft.includes(v.slug)) {
                if (v.slug === 'smartcache_ttl' && cdn_smartcache === 'false')
                  return null;
                const [field] = type.values.filter(_v => v.slug === _v.slug);
                return (
                  field && (
                    <NodeappField
                      key={v.slug}
                      field={field}
                      value={v.value}
                      onChange={onChange}
                    />
                  )
                );
              }
              return null;
            })}
            <Button outline onClick={clearCache}>
              Limpar cache
            </Button>
          </>
        )}
      </Col>
      <Separator />
      <Col xl>
        {website.values.map((v, i) => {
          if (fieldsRight.includes(v.slug)) {
            const [field] = type.values.filter(_v => v.slug === _v.slug);
            return (
              field && (
                <>
                  <NodeappField
                    key={v.slug}
                    field={field}
                    value={v.value}
                    onChange={onChange}
                    disabled={v.slug === 'pagespeed' && !website.can_pagespeed}
                  />
                  {v.slug === 'pagespeed' && !website.can_pagespeed && (
                    <p
                      style={{
                        color: theme.interface4,
                        fontSize: '13px',
                        marginBottom: '12px',
                      }}
                    >
                      * Não é possível ativar o PageSpeed em um Cloud do tipo{' '}
                      <b>E-mail</b> ou <b>Volume</b>.
                    </p>
                  )}
                </>
              )
            );
          }
          return null;
        })}
      </Col>
    </Row>
  );
};

export default Performance;
