import React, { useState, useContext, useMemo } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfo,
  faDollarSign,
  faCog,
  faCalendar,
  faFilePdf,
} from '@fortawesome/free-solid-svg-icons';
import { Col } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';

import {
  Card,
  CardContent,
  ListingItem,
  ApplicationValue,
  ApplicationName,
} from 'components/Listing/styles';
import { Acesso, AcessosList, AcessoHover, Value } from 'components/Acessos';

import { ThemeContext } from 'styled-components';
import { Link } from 'react-router-dom';
import api from 'services/api';
import { useAuth } from 'hooks/auth';
import InformationModal from '../InformationModal';
import DateModal from '../DateModal';
import { DownloadNF } from '../styles';

const InvoiceCard: React.FC<{
  invoice: any;
}> = ({ invoice: i }) => {
  const [infoModal, setInfoModal] = useState(false);
  const [invoice, setInvoice] = useState(null);
  const [showAccess, setShowAccess] = useState(false);
  const [dateModal, setDateModal] = useState(false);
  const theme = useContext(ThemeContext);

  const startChargingAt = moment(i?.start_charging_at).format('YYYY-MM-DD');

  const maxDate = moment(i?.start_charging_at)
    .add(10, 'days')
    .format('YYYY-MM-DD');

  const today = moment();
  const expiredAt = moment(i?.expired_at);
  const isExpired = expiredAt.isBefore(today, 'days');

  const { user } = useAuth();

  const canChangeDate =
    isExpired &&
    i.has_gateway &&
    !i.is_paid &&
    moment().isBetween(startChargingAt, maxDate, null, '[]');

  const getInvoicePDF = async () => {
    // setLoadingChurn(true);
    try {
      const { data } = await api.get(`/v3/invoice/${i.id}/pdf/`, {
        responseType: 'blob',
      });

      const a = document.createElement('a');
      a.href = URL.createObjectURL(data);
      a.download = `invoice-${i.id}.pdf`;
      a.click();
      // setLoadingChurn(false);
    } catch (e) {
      // setLoadingChurn(false);
      console.log(e);
    }
  };

  const hasNF = useMemo(() => !!i.nf, [i.nf]);

  return (
    <ListingItem key={i.id}>
      {infoModal && (
        <InformationModal
          show={infoModal}
          setShow={() => setInfoModal(false)}
          invoice={invoice}
        />
      )}
      {canChangeDate && dateModal && (
        <DateModal
          show={dateModal}
          setShow={() => setDateModal(false)}
          invoice={invoice}
        />
      )}
      <Link to={`/invoices/${i.id}/`}>
        <Card>
          <CardContent style={{ position: 'relative' }}>
            <Col xs="2" lg="1">
              {/* {!showAccess && (
                <ApplicationValue style={{ fontWeight: 'normal' }}>
                  <p>{i.id}</p>
                </ApplicationValue>
              )} */}
              <ApplicationValue style={{ fontWeight: 'normal' }}>
                <p>{i.id}</p>
              </ApplicationValue>
            </Col>

            <Col
              xs={!showAccess ? '8' : null}
              sm={!showAccess ? '6' : null}
              md={!showAccess ? '4' : null}
              lg="6"
              xl="5"
            >
              {/* {!showAccess && (
                <ApplicationValue
                  style={{
                    margin: '0',
                  }}
                >
                  <ApplicationName
                    style={{
                      margin: '0',
                    }}
                  >
                    {i.user_email}
                  </ApplicationName>
                </ApplicationValue>
              )} */}
              <ApplicationValue
                style={{
                  margin: '0',
                }}
              >
                <ApplicationName
                  style={{
                    margin: '0',
                  }}
                >
                  {i.user_email}
                </ApplicationName>
              </ApplicationValue>
            </Col>

            <Col className="d-none d-sm-none d-md-flex">
              <ApplicationValue style={{ fontWeight: 'normal' }}>
                <p>
                  <CurrencyFormat
                    value={i.total.amount}
                    displayType="text"
                    fixedDecimalScale
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    prefix={i.total.currency === 'BRL' ? 'R$ ' : '$'}
                  />
                </p>
              </ApplicationValue>
            </Col>

            <Col className="d-none d-sm-none d-md-flex">
              <ApplicationValue style={{ fontWeight: 'normal' }}>
                {moment(i.expired_at).format('DD/MM/YYYY')}
              </ApplicationValue>
            </Col>

            <Col className="d-none d-sm-none d-md-flex">
              <ApplicationValue style={{ fontWeight: 'normal' }}>
                {user.payment_gateway === 'PagBrasil' ? (
                  <DownloadNF
                    onClick={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      getInvoicePDF();
                    }}
                  >
                    Baixar PDF
                  </DownloadNF>
                ) : i.nf !== '' ? (
                  <DownloadNF
                    onClick={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      window.open(i.nf);
                    }}
                  >
                    Baixar NF-e
                  </DownloadNF>
                ) : i.is_paid ? (
                  <p>Processando NF-e</p>
                ) : (
                  <p>Fatura não paga</p>
                )}
              </ApplicationValue>
            </Col>

            {!showAccess && (
              <Col className="d-xs-flex d-sm-none">
                <div>
                  <FontAwesomeIcon
                    icon={faCog}
                    style={{
                      color: '#64728C',
                    }}
                    onClick={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      setShowAccess(true);
                    }}
                  />
                </div>
              </Col>
            )}

            <Col
              xl="1"
              md="2"
              sm="3"
              xs="4"
              className={!showAccess && 'd-none d-md-flex'}
              onClick={e => {
                e.preventDefault();
                setShowAccess(false);
              }}
              style={{
                background: showAccess && theme.interface3,
                height: '100%',
                width: '100%',
                borderTopRightRadius: '5px',
                borderBottomRightRadius: '5px',
              }}
            >
              <AcessosList showAccess={showAccess}>
                {canChangeDate && (
                  <Acesso
                    active
                    hover
                    onClick={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      setInvoice(i);
                      setDateModal(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faCalendar} />
                    <AcessoHover>
                      <Value>
                        <span>Trocar data</span>
                      </Value>
                    </AcessoHover>
                  </Acesso>
                )}
                <Acesso
                  active
                  hover
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                    setInfoModal(true);
                    setInvoice(i);
                  }}
                >
                  <FontAwesomeIcon icon={faInfo} />
                  <AcessoHover>
                    <Value>
                      <span>Informações</span>
                    </Value>
                  </AcessoHover>
                </Acesso>
                <Acesso
                  active
                  hover
                  paid
                  fixed={i.is_paid}
                  onClick={e => {
                    e.stopPropagation();
                    e.preventDefault();
                  }}
                >
                  <FontAwesomeIcon icon={faDollarSign} />
                  <AcessoHover>
                    <Value>
                      <span>Status</span>
                    </Value>
                  </AcessoHover>
                </Acesso>
              </AcessosList>
            </Col>
          </CardContent>
        </Card>
      </Link>
    </ListingItem>
  );
};

export default InvoiceCard;
