import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js';
import { withTheme } from 'styled-components';

import { Card, CardBody, CardHeader } from '../styles';

const DashboardChart = ({ theme, data, name }) => {
  const chartRef = useRef<HTMLCanvasElement>();

  useEffect(() => {
    if (chartRef.current) {
      const dataChart = chartRef.current.getContext('2d');

      Chart.pluginService.register({
        beforeDraw(chart) {
          if (chart.config.options.elements.value) {
            const { ctx } = chart.chart;
            const centerConfig = chart.config.options.elements.value;
            const txt = centerConfig.text;
            ctx.font = '16px Lato';
            ctx.textAlign = 'center';
            ctx.fillStyle = theme.interface5;
            const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
            const centerY =
              (chart.chartArea.top + chart.chartArea.bottom) / 2 - 10;
            ctx.fillText(txt, centerX, centerY);
          }
          if (chart.config.options.elements.name) {
            const { ctx } = chart.chart;
            const centerConfig = chart.config.options.elements.name;
            const txt = centerConfig.text;
            ctx.font = '16px Lato';
            ctx.textAlign = 'center';
            ctx.fillStyle = theme.interface5;
            const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
            const centerY =
              (chart.chartArea.top + chart.chartArea.bottom) / 2 + 10;
            ctx.fillText(txt, centerX, centerY);
          }
        },
      });

      const _data = createNew(data);
      const labels = _data.map(w => w.website_type || w.email_type);
      const values = _data.map(w => w.total);
      const total =
        values.length &&
        values.reduce((t, v) => {
          return t ? t + v : t + v;
        });

      new Chart(dataChart, {
        type: 'doughnut',
        data: {
          labels,
          datasets: [
            {
              label: name,
              data: values,
              borderColor: theme.interface1,
              backgroundColor: [
                '#55D8FE',
                '#FF8373',
                '#FFDA83',
                '#A3A0FB',
                '#b881b1',
                '#92c2a9',
                '#96a1f2',
                '#f29883',
                '#f59b3b',
                '#249bd3',
              ],
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          elements: {
            value: {
              text: `${total}`,
            },
            name: {
              text: `${name}`,
            },
          },
          cutoutPercentage: 70,
          legend: {
            position: 'right',
            align: 'center',
            labels: {
              usePointStyle: true,
              fontColor: theme.interface5,
              padding: 12,
            },
          },
        },
      });
    }
    // eslint-disable-next-line
  }, [data, theme, chartRef]);

  const sortArray = array => {
    array.sort((a, b) => b.total - a.total);
  };

  const createNew = list => {
    sortArray(list);
    const newList = [];

    if (list.length > 4) {
      const main = list.slice(0, 3);

      const other = list
        .slice(3, list.length)
        .map(i => i.total)
        .reduce((y, _list) => {
          return y + _list;
        });

      for (const i of main) {
        newList.push(i);
      }

      newList.push({ website_type: 'other', total: other });
      return newList;
    }
    return list;
  };

  return (
    <Card>
      <CardHeader>{name}</CardHeader>
      <CardBody>
        <canvas ref={chartRef} style={{ height: '270px' }} />
      </CardBody>
    </Card>
  );
};

export default withTheme(DashboardChart);
