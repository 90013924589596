import styled from 'styled-components';

export const NotRecommendedContent = styled.div`
  text-align: center;
  padding: 20px 40px 0px 40px;
  position: relative;

  p {
    margin-bottom: 30px;
  }

  svg {
    font-size: 50px;
    color: ${props => props.theme.alertNew};

    margin-bottom: 10px;
  }

  .not-recommended {
    color: ${props => props.theme.interface5};
    font-size: 18px;
    font-weight: 800;
  }

  .dynamic-text {
    color: ${props => props.theme.interface5};
  }

  .recommendation,
  .question {
    color: ${props => props.theme.interface5};
  }

  .question {
    margin-bottom: 0;
    font-weight: bold;
  }

  .close {
    position: absolute;

    top: -5px;
    right: -15px;

    font-size: 18px;
    color: ${props => props.theme.interface5};

    cursor: pointer;
  }
`;

export const Buttons = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;
`;
