import styled from 'styled-components';

interface IButtonProps {
  sideMenuCollapsed?: boolean;
}

export const Button = styled.button<IButtonProps>`
  border: none;
  background: none;
  margin-left: 15px;

  transition: all 0.2s ease-in-out;

  div {
    background: ${props =>
      props.theme.darkMode
        ? props.theme.interface4
        : props.theme.brand_primary_color};
    width: 20px;
    margin: 4px 0;
    height: 3px;
  }

  @media (max-width: 768px) {
    transform: ${({ sideMenuCollapsed }) =>
      !sideMenuCollapsed && 'translateX(210px)'};
  }
`;
