import styled, { css } from 'styled-components';

export const Card = styled.div`
  width: 100%;
  height: 142px;
  padding: 10px 14px;
  margin-bottom: 20px;
  background: ${props => props.theme.interface2};
  border-radius: 5px;
  border: 2px solid transparent;

  &:hover {
    cursor: pointer;
    border: 2px solid ${props => props.theme.brand_primary_color};
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 26px;

  display: flex;
  align-items: center;
`;

export const Name = styled.div`
  margin-left: 12px;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;

  font-size: 12px;
  color: ${props => props.theme.interface5};
`;

export const CommandHeader = styled.span`
  font-size: 12px;
  color: ${props => props.theme.interface5};
  font-weight: bold;
  display: block;
`;

export const Command = styled.span`
  font-size: 12px;
  color: ${props => props.theme.interface5};
  font-style: italic;

  display: block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Body = styled.div`
  height: calc(100% - 26px);
  width: 100%;
  margin-top: 20px;
`;
