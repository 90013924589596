import { Reducer } from 'react';
import { IActionPayload } from 'types';
import { createPagination } from '@cloudez/cloudez-design-system';
import { CloudListingState } from './state';

const reducer: Reducer<CloudListingState, IActionPayload> = (
  state,
  action,
): CloudListingState => {
  switch (action.type) {
    case 'GET_CLOUDS':
      const clouds = action.payload.results;

      const pagination = createPagination(action.payload, 10);

      return {
        ...state,
        ...pagination,
        clouds,
      };

    case 'UPDATE_CLOUD': {
      return {
        ...state,
        clouds: state?.clouds?.map(cloud =>
          cloud.id === action.payload.id ? action.payload : cloud,
        ),
      };
    }

    case 'SET_LOADING': {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case 'SET_ERRORS': {
      return {
        ...state,
        errors: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
