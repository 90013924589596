import React from 'react';

import { Button } from '@cloudez/cloudez-design-system';

import migEmail from 'assets/img/svg/mig_email.svg';
import migSite from 'assets/img/svg/mig_site.svg';
import migAssist from 'assets/img/svg/mig_assist.svg';

import history from 'services/history';
import { Breadcrumbs, Breadcrumb, Header, Title } from 'components/Common';

import { Link } from 'react-router-dom';
import {
  faChevronRight,
  faExchangeAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { MigrationCreateProvider } from './_context/state';
import { MigrationWrapper, MigrationSelect, AssistedMigration } from './styles';

const MigrationCreate = () => {
  return (
    <>
      <Header>
        <Title>
          <Breadcrumbs>
            <Breadcrumb>
              <Link to="/dashboard">Home</Link>
            </Breadcrumb>
            <FontAwesomeIcon icon={faChevronRight} />
            <Breadcrumb>
              <Link to="/migrations">Migração</Link>
            </Breadcrumb>
          </Breadcrumbs>
          <FontAwesomeIcon
            icon={faExchangeAlt}
            style={{ marginRight: '10px' }}
          />
          Nova Migração
        </Title>
      </Header>

      <MigrationWrapper>
        <MigrationSelect>
          <img src={migSite} alt="migsite" />
          <p>Migre com facilidade seus sites e aplicações.</p>
          <Button onClick={() => history.push('/migrations/create/website')}>
            MIGRAÇÃO DE SITE
          </Button>
        </MigrationSelect>
        <MigrationSelect>
          <img src={migEmail} alt="migemail" />
          <p>Migrar contas de e-mail para nosso provedor.</p>
          <Button onClick={() => history.push('/migrations/create/email')}>
            MIGRAÇÃO DE EMAIL
          </Button>
        </MigrationSelect>
      </MigrationWrapper>
      <AssistedMigration>
        <div>
          <img src={migAssist} alt="migassist" />
        </div>
        <p>
          Não tem tempo para cuidar da migração automática? Nosso time de
          especialistas cuida de tudo para você. Com a migração assistida você
          fica tranquilo enquanto trazemos suas aplicações e e-mails para seu
          Cloud. Uma comodidade adicional para sua empresa.
        </p>
        <Button
          onClick={() => history.push('/migrations/create/assisted')}
          outline
        >
          MIGRAÇÃO ASSISTIDA
        </Button>
      </AssistedMigration>
    </>
  );
};

export default props => (
  <MigrationCreateProvider>
    <MigrationCreate {...props} />
  </MigrationCreateProvider>
);
