import React, { useEffect } from 'react';

import { useLayout } from 'hooks/layout';

const Terms: React.FC = () => {
  const { slug } = useLayout();

  useEffect(() => {
    if (slug === 'configr')
      window.location.href = 'https://configr.com/termos-de-uso/';
    else window.location.href = 'https://cloudez.io/br/termos-legais/';
  }, [slug]);

  return <></>;
};

export default Terms;
