import React, { useState } from 'react';

import { toast } from 'react-toastify';

import { faCloudUploadAlt, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Modal, Button, Input } from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Footer,
} from 'components/ListingModal/styles';

import { removeBackupService } from 'services/friday';
import { Content } from './styles';

interface RemoveBackupModalProps {
  show: boolean;
  setShow: (value: boolean) => void;
  domain: string;
  sync: number;
}

const RemoveBackupModal = ({
  show,
  setShow,
  sync,
  domain,
}: RemoveBackupModalProps): JSX.Element => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState('');

  const onSubmit = async () => {
    setLoading(true);
    try {
      await removeBackupService(sync);
      setLoading(false);
      setShow(false);
      toast.success('O backup será removido em 7 dias.');
    } catch (err) {
      toast.success('Não foi possível remover o backup, tente novamente.');
      setLoading(false);
      setShow(false);
    }
  };

  return (
    <Modal width="562px" show={show} setShow={setShow}>
      <Header style={{ borderBottom: '0px' }}>
        <Icon>
          <FontAwesomeIcon
            style={{ fontSize: '20px' }}
            icon={faCloudUploadAlt}
          />
        </Icon>
        <Text>
          <Title>Excluir Backup</Title>
          <Subtitle>Deseja excluir o backup de {domain}?</Subtitle>
        </Text>
      </Header>

      <Content>
        <span>
          Ao remover o backup dessa migração, caso seja necessário repetir,
          iremos inciar a migração do ponto de partida.
        </span>
        <div className="input">
          <span>
            Por favor, escreva <b style={{ color: '#fb264b' }}>REMOVER</b> para
            confirmar
          </span>

          <Input value={value} onChange={e => setValue(e.target.value)} />
        </div>
      </Content>

      <Footer style={{ borderTop: '0px' }}>
        <Button
          disabled={loading || value !== 'REMOVER'}
          icon={loading ? faSpinner : undefined}
          onClick={onSubmit}
          error
        >
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'REMOVER'}
        </Button>
      </Footer>
    </Modal>
  );
};

export default RemoveBackupModal;
