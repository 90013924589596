import styled, { css } from 'styled-components';

interface IDomainIconProps {
  disable?: boolean;
}

export const DomainIcon = styled.span<IDomainIconProps>`
  svg {
    color: ${props => props.theme.brand_primary_color};
  }

  ${props =>
    props.disable &&
    css`
      svg {
        color: ${props => props.theme.interface4};
      }
    `}
`;
