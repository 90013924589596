import React, { useState } from 'react';

import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { Modal, Button, Label } from '@cloudez/cloudez-design-system';

import {
  CustomInput,
  CustomTextArea,
  SquareCheckbox,
} from 'components/NewComponents';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from 'components/ListingModal/styles';

import { useLayout } from 'hooks/layout';

import { ModalProps } from 'types';
import { HttpsText } from './styles';

const RemoveModal: React.FC<ModalProps> = ({
  action,
  show,
  setShow,
  typeName,
  params,
}) => {
  const theme = useLayout();

  const [loading, setLoading] = useState(false);
  const [remove, setRemove] = useState('');
  const [motive, setMotive] = useState('');
  const [accept, setAccept] = useState(false);

  const [removeDatabaseAndUser, setRemoveDatabaseAndUser] = useState(false);

  return (
    <Modal width="455px" show={show} setShow={setShow} noExit={loading}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faTrashAlt} />
        </Icon>
        <Text>
          <Title>Excluir {typeName}</Title>
          <Subtitle>{`Deseja excluir este ${typeName}?`}</Subtitle>
        </Text>
      </Header>

      <Content style={{ padding: '17px 17px 28px 17px' }}>
        <HttpsText>
          Por favor, escreva{' '}
          <span
            style={{
              textTransform: 'uppercase',
              color: theme.error,
              fontWeight: 'bold',
            }}
          >
            REMOVER
          </span>{' '}
          para confirmar a exclusão deste {typeName}.
        </HttpsText>
        <CustomInput
          name="remove"
          block
          placeholder="REMOVER"
          value={remove}
          onChange={e => {
            setRemove(e.target.value);
          }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '20px',
          }}
        >
          <SquareCheckbox
            name="AcceptRemove"
            checked={accept}
            value={accept}
            onChange={() => setAccept(!accept)}
          />
          <HttpsText
            style={{ fontFamily: 'Nunito', marginBottom: '0px' }}
            onClick={() => setAccept(!accept)}
          >
            Estou ciente que esta ação é{' '}
            <span
              style={{
                textTransform: 'uppercase',
                fontWeight: 'bold',
                color: theme.error,
              }}
            >
              IRREVERSÍVEL
            </span>
            .
          </HttpsText>
        </div>
        {params?.withMotive && (
          <>
            <HttpsText style={{ marginTop: '24px' }}>
              No campo abaixo, descreva o motivo da exclusão deste {typeName}.
            </HttpsText>
            <CustomTextArea
              name="motive"
              height="68px"
              style={{ resize: 'none' }}
              block
              placeholder="Escreva o motivo da exclusão"
              value={motive}
              onChange={e => {
                setMotive(e.target.value);
              }}
            />
          </>
        )}
        {params?.allowShowCheckbox && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              marginTop: '20px',
            }}
          >
            <SquareCheckbox
              value={removeDatabaseAndUser}
              onChange={() => setRemoveDatabaseAndUser(!removeDatabaseAndUser)}
              name="removeDbAndUser"
            />
            <HttpsText style={{ marginBottom: '0px' }}>
              {params?.text}
            </HttpsText>
          </div>
        )}
      </Content>
      <Footer>
        <Button
          width="149px"
          disabled={remove !== 'REMOVER' || accept !== true || loading}
          error
          icon
          onClick={() => {
            setLoading(true);
            action({ motive, removeDatabaseAndUser });
          }}
        >
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Remover'}
        </Button>
      </Footer>
    </Modal>
  );
};

export default RemoveModal;
