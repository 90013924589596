import React from 'react';

import { Container } from './styles';

export const CDNInfo = () => {
  return (
    <>
      <Container>
        <p>CDN</p>
        <span className="tag">
          <b style={{ fontSize: '16px' }}>100 GB</b>&nbsp;por cloud
        </span>
        <span style={{ fontSize: '16px' }}>
          A cota de CDN do usuário pode ser usada em todos os seus clouds
        </span>
        <b>Caso haja necessidade cada GB além da cota custará R$ 0,80.</b>
      </Container>
      <div style={{ padding: '15px' }}>
        <b style={{ color: '#29D975' }}>Conheça as vantagens da CDN</b>
        <p style={{ fontSize: '13px', padding: '10px 0', color: '#64728C' }}>
          <b>1</b> - Navegação mais rápida e melhora de performance da aplicação
        </p>
        <p style={{ fontSize: '13px', padding: '10px 0', color: '#64728C' }}>
          <b>2</b> - Alta tolerância a um fluxo intenso de acessos e maior
          estabilidade para seu site
        </p>
        <p style={{ fontSize: '13px', padding: '10px 0', color: '#64728C' }}>
          <b>3</b> - Mais segurança contra cyber ataques (DDoS)
        </p>
        <p style={{ fontSize: '13px', padding: '10px 0', color: '#64728C' }}>
          <b>4</b> - Redução de consumo de banda de transferência
        </p>
        <p style={{ fontSize: '13px', padding: '10px 0', color: '#64728C' }}>
          <b>5</b> - Redução de sobrecargas no servidor
        </p>
      </div>
    </>
  );
};
