import React, { useEffect, useState, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { Row, Col } from 'react-bootstrap';

import ApplicationIcon from 'components/ApplicationIcon';

import { Input, Checkbox, Button } from '@cloudez/cloudez-design-system';
import {
  faPlusSquare,
  faMinusSquare,
} from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { getWebsiteTypesService } from 'services/website';
import { useAuth } from 'hooks/auth';
import history from 'services/history';
import {
  createTicketMessageService,
  createTicketService,
} from 'services/ticket';
import toastError from 'utils/toastError';
import moment from 'moment';
import { createInvoiceService } from 'services/invoice';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { WebsiteCreateContext } from '../_context/state';
import { Wrapper, SeeMoreButton, Type, CustomApp } from './styles';
import Placeholder from './Placeholder';

import cmss from './images/cmss.png';
import shellhub from './images/shellhub.png';
import wikijs from './images/wikijs.png';
import zabbix from './images/zabbix.png';

const WebsiteTypes = ({ advancedOptions, setAdvancedOptions }) => {
  const [qty, setQty] = useState(11);
  const [types, setTypes] = useState([]);
  const [toggleShowMore, setToggleShowMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const { user } = useAuth();
  const { website, updateWebsite, updateWebsiteField } =
    useContext(WebsiteCreateContext);

  const theme = useContext(ThemeContext);

  const [loadingNew, setLoadingNew] = useState(false);

  const openTicket = async () => {
    const text =
      'Olá, gostaria do solicitar uma aplicação customizada por meio de uma automação pocket';

    const payload = {
      summary: 'Solicitação de serviço de app customizada',
      target_type: 'website',
      target_id: null,
      owner_email: user.email,
      team: 1,
    };

    try {
      setLoadingNew(true);
      const { data } = await createTicketService(payload);

      const messagePayload = new FormData();
      messagePayload.append('ticket', data.id.toString());
      messagePayload.append('text', text);
      messagePayload.append('author', user.id.toString());

      await createTicketMessageService(messagePayload);

      setLoadingNew(false);

      history.push(`/suporte/${data.id}`);
    } catch (e) {
      toastError(e);
      setLoadingNew(false);
    }
  };

  const createInvoiceAutomation = async () => {
    try {
      setLoadingNew(true);
      const { data } = await createInvoiceService({
        items: [
          {
            price: {
              amount: 1000.0,
              currency: 'BRL',
            },
            description: 'Cloudez Automation',
          },
        ],
        expired_at: moment().add(1, 'days').format('YYYY-MM-DD'),
        user_email: user.email,
        user: user.id,
      });
      setLoadingNew(false);
      history.push(`/invoices/${data.id}/`);
    } catch (e) {
      setLoadingNew(false);
      toastError(e);
    }
  };

  useEffect(() => {
    async function getWebsiteTypes() {
      setLoading(true);
      const { data } = await getWebsiteTypesService({
        page_size: 500,
        company: user.company,
      });

      setTypes([...data.results /* 'custom' */]);
      setLoading(false);
    }

    if (!types.length) getWebsiteTypes();
    // eslint-disable-next-line
  }, [types]);

  return (
    <Wrapper>
      <Row
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginBottom: '30px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            width: '400px',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
          data-id="websites-appname"
        >
          <span
            style={{
              color: theme.interface5,
              fontSize: '14px',
            }}
          >
            Dê um nome pra sua aplicação
          </span>
          <Input
            placeholder="Dê um nome pra sua aplicação..."
            block
            style={{
              marginTop: '8px',
            }}
            onChange={e => {
              updateWebsiteField('name', e.target.value);
            }}
          />
        </div>
      </Row>

      {loading ? (
        <Row
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Placeholder />
        </Row>
      ) : (
        <>
          <Row
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
            data-id="websites-apps"
          >
            {types &&
              types.map((t, i) => {
                if (i <= qty) {
                  return (
                    <Col
                      key={t.id}
                      lg={t === 'custom' ? '4' : '2'}
                      md="3"
                      sm="4"
                      xs="6"
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      {t === 'custom' ? (
                        <CustomApp>
                          <div className="leftSide">
                            <span>Quer uma aplicação customizada?</span>
                            <Button
                              onClick={
                                user.is_company_owner
                                  ? createInvoiceAutomation
                                  : openTicket
                              }
                              outline
                              icon
                              disabled={loadingNew}
                              width="142px"
                              height="28px"
                            >
                              {loadingNew ? (
                                <FontAwesomeIcon spin icon={faSpinner} />
                              ) : (
                                'Fale conosco'
                              )}
                            </Button>
                          </div>
                          <div className="rightSide">
                            <img src={zabbix} alt="Zabbix" />
                            <img src={shellhub} alt="ShellHub" />
                            <img src={wikijs} alt="Wiki.js" />
                            <img src={cmss} alt="CMS's" />
                          </div>
                          <div />
                        </CustomApp>
                      ) : (
                        <Type
                          type={t}
                          onClick={() => {
                            return updateWebsite({
                              ...website,
                              type: t.id,
                              values: t.values.map(t => ({
                                slug: t.slug,
                                value: t.default,
                              })),
                            });
                          }}
                          selected={website.type === t.id}
                        >
                          {t.slug === 'onrise' ? (
                            <ApplicationIcon
                              width="90px"
                              height="40px"
                              icon={`${t.slug}`}
                            />
                          ) : (
                            <ApplicationIcon
                              width="40px"
                              height="40px"
                              icon={`${t.slug}`}
                            />
                          )}
                          <span>{t.name}</span>
                          <p>{t.description}</p>
                        </Type>
                      )}
                    </Col>
                  );
                }
                return null;
              })}
          </Row>
          <Row
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginBottom: '25px',
            }}
          >
            <SeeMoreButton
              onClick={() => {
                toggleShowMore ? setQty(types.length) : setQty(11);
                setToggleShowMore(!toggleShowMore);
              }}
            >
              <FontAwesomeIcon
                icon={toggleShowMore ? faPlusSquare : faMinusSquare}
              />
              <span>Mais aplicações</span>
            </SeeMoreButton>
          </Row>
          <Row
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Checkbox
              value={advancedOptions}
              checked={advancedOptions}
              onChange={() => setAdvancedOptions(!advancedOptions)}
            />
            <span
              style={{
                color: theme.interface5,
                fontSize: '14px',
                marginLeft: '8px',
              }}
            >
              Customizar as opções avançadas deste setup?
            </span>
          </Row>
        </>
      )}
    </Wrapper>
  );
};

export default WebsiteTypes;
