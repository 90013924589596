import React, { useCallback, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLink } from '@fortawesome/free-solid-svg-icons';

import { Modal, Input, Button } from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from 'components/ListingModal/styles';

import ApplicationIcon from 'components/ApplicationIcon';
import { useLayout } from 'hooks/layout';
import { ModalProps } from 'types';
import { HttpsText, Domain, Cloud } from './styles';

interface LinkModalProps extends ModalProps {
  record: any;
}

const LinkModal: React.FC<LinkModalProps> = ({
  action,
  show,
  setShow,
  record,
}) => {
  const [remove, setRemove] = useState('');

  const theme = useLayout();

  const [resource] = useState(record.nodeapps[0] || record.zoneapps[0]);

  const editHandle = useCallback(() => {
    action();
    setShow();
    // eslint-disable-next-line
  }, []);

  const onChangeHandle = useCallback(e => {
    setRemove(e.target.value);
  }, []);

  return (
    <Modal show={show} setShow={setShow}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faLink} />
        </Icon>
        <Text>
          <Title>Registro vinculado</Title>
          <Subtitle>Deseja remover este vínculo?</Subtitle>
        </Text>
      </Header>

      <Content>
        <div
          style={{
            width: '80%',
            margin: '0 auto',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <HttpsText>
            Este registro está vinculado ao{' '}
            {record.nodeapps.length ? 'website' : 'email'}:
          </HttpsText>
          <ApplicationIcon
            icon={
              resource.type.slug === 'selfhosted-mail'
                ? 'cloud_cloudez'
                : resource.type.slug
            }
            width="60px"
            height="60px"
          />
          <Domain>{resource.domain}</Domain>
          <Cloud>{resource.cloud_display}</Cloud>
          <HttpsText
            style={{
              textAlign: 'center',
            }}
          >
            Editando este registro, seu{' '}
            {record.nodeapps.length ? 'website' : 'email'} pode não funcionar
            corretamente.
          </HttpsText>
          <HttpsText
            style={{
              textAlign: 'center',
            }}
          >
            Por favor, escreva{' '}
            <span style={{ color: theme.errorNew }}>EDITAR</span> para confirmar
          </HttpsText>
          <Input
            width="80%"
            block
            placeholder="EDITAR"
            value={remove}
            onChange={onChangeHandle}
          />
        </div>
      </Content>
      <Footer>
        <Button icon disabled={remove !== 'EDITAR'} error onClick={editHandle}>
          Editar
        </Button>
      </Footer>
    </Modal>
  );
};

export default LinkModal;
