import React, { useState, useCallback } from 'react';

import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

import api from 'services/api';

import {
  Button,
  FormInput,
  Label,
  Field,
} from '@cloudez/cloudez-design-system';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faKey, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useDocTitle } from 'hooks/docTitle';
import { useLayout } from 'hooks/layout';
import toastError from 'utils/toastError';
import ReactLoading from 'react-loading';
import { Content, Wrapper } from '../styles';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um email válido.')
    .required('O email é obrigatório.'),
});

const ResetPassword: React.FC = () => {
  const theme = useLayout();
  document.title = `Esqueci minha senha — ${theme.name}`;

  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  const handleSubmit = useCallback(
    async data => {
      const payload = {
        ...data,
        company: theme.code,
      };

      try {
        setLoading(true);
        await api.post('/auth/password/reset/', payload);

        setLoading(false);
        setDone(true);
        toast.success(
          'Em alguns minutos você receberá um email com o link para o reset de senha.',
        );
      } catch (e) {
        setLoading(false);
        toastError(e);
      }
    },
    [theme],
  );

  return (
    <Wrapper>
      <Content>
        {!theme || theme.loading ? (
          <ReactLoading
            type="balls"
            // style={{
            //   margin: '0 auto',
            //   fill: '#353FF2',
            //   width: '100px',
            //   height: '100px',
            // }}
          />
        ) : (
          <>
            <img src={theme.logo_primary} alt="logo" />
            <Form onSubmit={handleSubmit} schema={schema}>
              <div style={{ textAlign: 'center', color: '#a2a9bf' }}>
                <h2>Redefinir senha</h2>
                <br />
              </div>
              <Field>
                <Label>E-mail:</Label>
                <FormInput
                  type="email"
                  block="true"
                  name="email"
                  placeholder="exemplo@email.com"
                />
              </Field>

              <Button
                disabled={loading || done}
                icon
                width="100%"
                height="50px"
                type="submit"
              >
                {loading ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : done ? (
                  <FontAwesomeIcon icon={faCheck} />
                ) : (
                  'Redefinir'
                )}
              </Button>

              <div
                style={{
                  display: 'flex',
                  height: '100%',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  margin: 'auto',
                  fontSize: '12px',
                }}
              >
                <p>
                  <Link to="/login">LOGAR NA MINHA CONTA</Link>
                </p>
              </div>
            </Form>
          </>
        )}
      </Content>
    </Wrapper>
  );
};

export default ResetPassword;
