import {
  Content,
  Footer,
  Header,
  Text,
  Title,
  Icon,
} from 'components/ListingModal/styles';
import { Modal, Button } from '@cloudez/cloudez-design-system';
import React, { useEffect, useMemo, useRef, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SquareCheckbox } from 'components/NewComponents';
import { getValue } from 'utils/getValue';
import { useAuth } from 'hooks/auth';
import { ConfirmModalText } from './styles';

interface IConfirmModalProps {
  text: any;
  onCancel: any;
  onConfirm: any;
  show: boolean;
  setShow: any;
  noExit: boolean;
  website: any;
  onChange: any;
}

const ConfirmModal: React.FC<IConfirmModalProps> = ({
  text,
  onCancel,
  onConfirm,
  onChange,
  website,
  ...rest
}) => {
  const { use_cdn } = useMemo(() => {
    const use_cdn = getValue(website, 'use_cdn');

    return {
      use_cdn,
    };
  }, [website]);

  const [event, setEvent] = useState(undefined);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (text.slug === 'pagespeed') {
      document.getElementById('use_cdn').click();
    }
  }, [text.slug]);

  return (
    <Modal {...rest}>
      <Header>
        {text.slug === 'pagespeed' ? (
          <img src={text.icon} alt="Pagespeed Icon" />
        ) : text.slug.includes('use_cdn') ? (
          <img src={text.icon} alt="GoCache Icon" />
        ) : (
          <Icon>
            <FontAwesomeIcon icon={text.icon} />
          </Icon>
        )}
        <Text>
          <Title>{text.title}</Title>
        </Text>
      </Header>

      <Content>
        <ConfirmModalText>
          {text.text}{' '}
          {text.slug === 'pagespeed' && (
            <>
              <br />
              <div
                style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}
              >
                <SquareCheckbox
                  checked={use_cdn === 'true' || checked}
                  onChange={e => {
                    setEvent(checked ? undefined : e);
                    setChecked(!checked);
                  }}
                  name="use_cdn"
                />{' '}
                <span>
                  Seguir recomendação (<i>Ativar CDN</i>)
                </span>
              </div>
            </>
          )}
        </ConfirmModalText>
      </Content>

      <Footer hasLink>
        <Button outline error width="150px" onClick={onCancel}>
          Cancelar
        </Button>
        <Button
          width="150px"
          onClick={() => {
            onConfirm();
            text.slug === 'pagespeed' && onChange(event);
          }}
        >
          Confirmar
        </Button>
      </Footer>
    </Modal>
  );
};

export default ConfirmModal;
