import React, { Reducer } from 'react';
import { IActionPayload } from 'types';
import { CloudCreateState } from './state';

const createReducer: Reducer<CloudCreateState, IActionPayload> = (
  state,
  action,
): CloudCreateState => {
  switch (action.type) {
    case 'UPDATE_PLAN':
      return {
        ...state,
        plan: action.payload,
      };

    case 'SET_LOADING': {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case 'GET_PLANS': {
      return {
        ...state,
        plans: action.payload,
      };
    }
    case 'GET_BASE_PLANS': {
      return {
        ...state,
        basePlans: action.payload,
      };
    }

    case 'SET_RECURRENCY': {
      return {
        ...state,
        recurrency: action.payload,
      };
    }

    case 'SET_PLAN_TYPE': {
      return {
        ...state,
        planType: action.payload,
      };
    }

    case 'SET_COUPON': {
      return {
        ...state,
        coupon: action.payload,
      };
    }

    case 'SET_COUPON_DISCOUNT': {
      return {
        ...state,
        ...action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default createReducer;
