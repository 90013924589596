import React, { useEffect, useState } from 'react';

import { toast } from 'react-toastify';

import { useHistory } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { useAuth } from 'hooks/auth';

import {
  createMigrationAssistedService,
  getStatusMigrationService,
} from 'services/friday';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfoCircle,
  faPaperPlane,
  faSpinner,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';

import { Button, Error } from '@cloudez/cloudez-design-system';

import { CustomInput, CustomTextArea } from 'components/NewComponents';
import SquareCheckbox from 'components/NewComponents/SquareCheckbox';
import { Alert } from 'components/Alert';

import { useMigDetail } from '../../contexts/MigDetailContext';
import {
  MigrationWrapper,
  MigrationForm,
  MigrationInfo,
  Label,
} from './styles';
import { useMigCreateAssisted } from '../../contexts/MigAssistedContext';

const schema = Yup.object().shape({
  domain: Yup.string().required('Por favor, insira um domínio.'),
  assisted_info: Yup.string().required('Descreva os detalhes da sua migração.'),
});

const Overview = (): JSX.Element => {
  const [loading, setLoading] = useState(false);

  const { setStep } = useMigDetail();

  const { setCacheData } = useMigCreateAssisted();

  const { user } = useAuth();

  const history = useHistory();

  const [check1, setCheck1] = useState('');
  const [check2, setCheck2] = useState('');

  const labelStyle = {
    fontWeight: 'bold',
    color: `${props => props.theme.interface5}`,
  };

  const {
    register,
    handleSubmit,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const checks = check1.concat(', ', check2);

  const onSubmit = async payload => {
    setLoading(true);

    try {
      if (checks.length <= 2) {
        setError('markCheckbox', {
          type: 'manual',
          message: 'Marque pelo menos 1 opção.',
        });
        setLoading(false);
        return;
      }

      clearErrors('markCheckbox');

      const migrationPayload = {
        ...payload,
        user_uuid: user.uuid,
        company_uuid: user.company,
        migration_type: 3,
        assisted_value: check1.length > 0 && check2.length > 0 ? 100 : 50,
        assisted_targets: check1 && check2 ? checks : checks.replace(',', ''),
      };

      const { data } = await createMigrationAssistedService(migrationPayload);

      history.push(`/migrations/${data.id}`);

      const response = await getStatusMigrationService(data.id);

      setStep(2);

      setCacheData(response.data);
      setLoading(false);
    } catch (e) {
      if (e?.response?.data?.message) {
        toast.error(e?.response?.data?.message);
      }
      setLoading(false);
    }
  };

  return (
    <>
      <MigrationWrapper>
        <MigrationForm>
          <form
            style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
            onSubmit={handleSubmit(onSubmit)}
          >
            <div>
              <Label style={labelStyle}>DIGITE O DOMÍNIO</Label>
              <CustomInput
                name="domain"
                placeholder="Ex.: Migração de site"
                block
                error={errors?.domain?.message}
                register={register}
              />
            </div>

            <Label style={labelStyle}>SELECIONE O QUE DESEJA MIGRAR</Label>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: '20px',
              }}
            >
              <SquareCheckbox
                name="websiteCheckbox"
                label="Website"
                value={check1}
                checked={check1 === 'Website'}
                onChange={e => {
                  if (e.target.checked) {
                    setCheck1('Website');
                  } else {
                    setCheck1('');
                  }
                }}
              />
              <SquareCheckbox
                name="emailCheckbox"
                label="Emails"
                value="email"
                checked={check2 === 'Email'}
                onChange={e => {
                  if (e.target.checked) {
                    setCheck2('Email');
                  } else {
                    setCheck2('');
                  }
                }}
              />
              <Error style={{ margin: '5px' }}>
                {errors?.markCheckbox?.message}
              </Error>
            </div>
            <div
              style={{ display: 'flex', flexDirection: 'column', gap: '15px' }}
            >
              <div>
                <Label style={labelStyle}>
                  DESCREVA OS DETALHES DA SUA MIGRAÇÃO
                </Label>
                <CustomTextArea
                  name="assisted_info"
                  style={{
                    minHeight: '94px',
                    resize: 'none',
                    padding: '7px',
                  }}
                  placeholder="Exemplo&#13;&#10;URL do host atual:&#13;&#10;Login:&#13;&#10;Senha:"
                  block
                  register={register}
                  error={errors?.assisted_info?.message}
                />
              </div>
              <Alert info>
                <FontAwesomeIcon size="lg" icon={faExclamationCircle} />
                <span>
                  <b>Atenção!</b> A migração assistida consiste em migrar
                  somente 01 domínio contendo sua aplicação e/ou email. Caso
                  possua outro domínio para ser migrado, é necessário fazer um
                  novo pedido.
                </span>
              </Alert>
            </div>

            <Button
              type="submit"
              style={{
                width: '115px',
              }}
              icon
              disabled={loading}
            >
              {loading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                <div className="textButton">
                  Enviar <FontAwesomeIcon icon={faPaperPlane} />
                </div>
              )}
            </Button>
          </form>
        </MigrationForm>

        <MigrationInfo>
          <div className="title">
            <FontAwesomeIcon icon={faInfoCircle} />
            <span>Detalhes da Migração</span>
          </div>
          <div className="content">
            <span className="title">Domínio</span>
            <span
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                width: '215px',
              }}
              className="text"
            >
              {watch('domain')}
            </span>
          </div>
          <div className="content">
            <span className="title">Tipo de Migração</span>
            <span className="text">
              {check1 && check2 ? checks : checks.replace(',', '')}
            </span>
          </div>
          <div className="content">
            <span className="title">Valor</span>
            <span className="price">
              {check1.length === 0 && check2.length === 0 ? (
                `R$0`
              ) : (
                <>{`R$${
                  check1.length > 0 && check2.length > 0 ? 100 : 50
                },00`}</>
              )}
            </span>
          </div>
        </MigrationInfo>
      </MigrationWrapper>
    </>
  );
};

export default Overview;
