import React from 'react';
import { Col, Row } from 'react-bootstrap';

import Placeholder from 'components/Placeholder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumbs, Breadcrumb } from 'components/Common';
import { faChevronRight, faDoorOpen } from '@fortawesome/free-solid-svg-icons';
import {
  Header,
  Title,
  Tabs,
  Tab,
  TabContent,
  Card,
  CardHeader,
  Field,
  Label,
  Value,
} from 'components/Details';

const EmailDetailPlaceholder = () => {
  return (
    <>
      <Header>
        <Row>
          <Col sm="8" md="9">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Placeholder circle width={64} height={64} />

              <Title>
                <Breadcrumbs>
                  <Breadcrumb>
                    <Placeholder width={40} height={10} />
                  </Breadcrumb>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <Breadcrumb>
                    <Placeholder width={60} height={10} />
                  </Breadcrumb>
                </Breadcrumbs>
                <h3>
                  <Placeholder width={100} height={18} />
                </h3>
                <Placeholder width={100} height={12} />
              </Title>
            </div>
          </Col>
          <Col
            sm="1"
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Placeholder width={40} height={40} />
          </Col>
          <Col
            className="d-none d-sm-flex d-md-flex d-xl-flex"
            sm="3"
            md="2"
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Placeholder width={130} height={40} />
          </Col>
        </Row>
      </Header>

      <Row
        style={{
          marginBottom: `${15}px`,
        }}
      >
        <Col xl="7">
          <Row style={{ marginBottom: `${30}px` }}>
            <Col xl="12">
              <Card>
                <CardHeader>
                  <Placeholder width={100} height={16} />
                </CardHeader>
                <Row
                  style={{
                    marginTop: '10px',
                  }}
                >
                  <Col xl="5">
                    <Field>
                      <Label>
                        <Placeholder width={100} height={10} />
                      </Label>
                      <Value>
                        <Placeholder width={160} height={12} />
                      </Value>
                    </Field>
                    <Field>
                      <Label>
                        <Placeholder width={100} height={10} />
                      </Label>
                      <Value>
                        <Placeholder width={160} height={12} />
                      </Value>
                    </Field>
                  </Col>
                  <Col xs="6" sm="5" md="4" lg="4">
                    <Field>
                      <Label>
                        <Placeholder width={100} height={10} />
                      </Label>
                      <Value>
                        <Placeholder width={160} height={12} />
                      </Value>
                    </Field>
                    <Field>
                      <Label>
                        <Placeholder width={100} height={10} />
                      </Label>
                      <Value>
                        <Placeholder width={160} height={12} />
                      </Value>
                    </Field>
                    <Field>
                      <Label>
                        <Placeholder width={100} height={10} />
                      </Label>
                      <Value>
                        <Placeholder width={160} height={12} />
                      </Value>
                    </Field>
                  </Col>
                  <Col xs="1">
                    <Field>
                      <Label>
                        <FontAwesomeIcon icon={faDoorOpen} />
                      </Label>
                      <Value>
                        <Placeholder width={30} height={10} />
                      </Value>
                    </Field>
                    <Field>
                      <Label>
                        <FontAwesomeIcon icon={faDoorOpen} />
                      </Label>
                      <Value>
                        <Placeholder width={30} height={10} />
                      </Value>
                    </Field>
                    <Field>
                      <Label>
                        <FontAwesomeIcon icon={faDoorOpen} />
                      </Label>
                      <Value>
                        <Placeholder width={30} height={10} />
                      </Value>
                    </Field>
                  </Col>
                  <Col xs="3" sm="2">
                    <Field>
                      <Label>
                        <FontAwesomeIcon icon={faDoorOpen} /> SSL
                      </Label>
                      <Value>
                        <Placeholder width={30} height={10} />
                      </Value>
                    </Field>
                    <Field>
                      <Label>
                        <FontAwesomeIcon icon={faDoorOpen} /> SSL
                      </Label>
                      <Value>
                        <Placeholder width={30} height={10} />
                      </Value>
                    </Field>
                    <Field>
                      <Label>
                        <FontAwesomeIcon icon={faDoorOpen} /> SSL
                      </Label>
                      <Value>
                        <Placeholder width={30} height={10} />
                      </Value>
                    </Field>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Tabs>
            <Tab>
              <Placeholder width={50} height={14} />
            </Tab>
            <Tab>
              <Placeholder width={50} height={14} />
            </Tab>
            <Tab active>
              <Placeholder width={50} height={14} />
            </Tab>
          </Tabs>

          <TabContent>
            <Row>
              <Col xl="6">
                <Field>
                  <Label>
                    <Placeholder width={140} height={10} />
                  </Label>
                  <Placeholder width={50} height={14} />
                </Field>
                <Field>
                  <Label>
                    <Placeholder width={140} height={10} />
                  </Label>
                  <Placeholder width={440} height={40} />
                </Field>
              </Col>
            </Row>
          </TabContent>
        </Col>
      </Row>
    </>
  );
};

export default EmailDetailPlaceholder;
