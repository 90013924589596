import React, { createContext, useReducer } from 'react';
import reducer from './reducer';
import {
  setLoading,
  updateField,
  updateNewDatabase,
  getDatabaseUsers,
  createDatabase,
} from './actions';

export interface DatabaseCreateState {
  newDatabase: {
    cloud: null;
    values: [];
    websites: [];
    type: null;
    users: [];
    admin: null;
  };
  databaseUsers: any;
  errors: any;
  loading: boolean;

  setLoading(value: boolean): void;
  createDatabase(database: any): Promise<void>;
  getDatabaseUsers(): Promise<void>;
  updateField(field: string, value: any): void;
  updateNewDatabase(database: any): void;
}

// initial state
const initialState = {
  newDatabase: {
    cloud: null,
    values: [],
    websites: [],
    type: null,
    users: [],
    admin: null,
  },
  databaseUsers: null,
  errors: null,
  loading: null,
};

// context
export const DatabaseCreateContext = createContext<DatabaseCreateState>(
  initialState as DatabaseCreateState,
);

// provider
export const DatabaseCreateProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(
    reducer,
    initialState as DatabaseCreateState,
  );

  return (
    <DatabaseCreateContext.Provider
      value={{
        // state
        newDatabase: state.newDatabase,
        databaseUsers: state.databaseUsers,
        errors: state.errors,
        loading: state.loading,

        // actions
        setLoading: value => setLoading(dispatch, value),
        createDatabase: async database =>
          await createDatabase(dispatch, database),
        updateField: (field, value) =>
          updateField(state, dispatch, field, value),
        updateNewDatabase: database => updateNewDatabase(dispatch, database),
        getDatabaseUsers: async () => getDatabaseUsers(state, dispatch),
      }}
    >
      {children}
    </DatabaseCreateContext.Provider>
  );
};
