import React, { useState, useEffect, useCallback, useContext } from 'react';
import { withTheme } from 'styled-components';

import {
  Label,
  Input,
  Select,
  Field,
  Error,
} from '@cloudez/cloudez-design-system';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import generatePassword from 'utils/generatePassword';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { Wrapper, Card, Existent } from './styles';

import { DatabaseCreateContext } from '../_context/state';

const Users = () => {
  const { updateField, getDatabaseUsers, databaseUsers, errors } = useContext(
    DatabaseCreateContext,
  );

  const [choose, setChoose] = useState(false);
  const [user, setUser] = useState({
    username: '',
    password: '',
    host: '127.0.0.1',
    id: null,
  }) as any;
  const [showPw, setShowPw] = useState(false);

  useEffect(() => {
    getDatabaseUsers();
    // eslint-disable-next-line
  }, []);

  const onUserChange = useCallback(
    e => {
      setUser({
        ...user,
        [e.target.name]: e.target.value,
      });

      updateField('users', [
        {
          ...user,
          [e.target.name]: e.target.value,
        },
      ]);
    },

    // eslint-disable-next-line
    [user],
  );

  const _generatePassword = useCallback(() => {
    const pass = generatePassword();

    setUser({
      ...user,
      password: pass,
    });

    updateField('users', [
      {
        ...user,
        password: pass,
      },
    ]);
    // eslint-disable-next-line
  }, [user]);

  const onSelectChange = useCallback(e => {
    setUser(e.target.value);
    updateField('users', [JSON.parse(e.target.value)]);
    // eslint-disable-next-line
  }, []);

  return (
    <Wrapper>
      <Card>
        {!choose ? (
          <>
            <Field
              style={{
                position: 'relative',
              }}
            >
              <Label>Usuário</Label>
              <Existent onClick={() => setChoose(true)}>
                Escolher existente
              </Existent>
              <Input
                maxLength="15"
                block
                placeholder="Usuário"
                name="username"
                value={user.username}
                onChange={onUserChange}
                error={errors?.users?.[0]?.username}
              />
              {errors?.users?.[0]?.username ? (
                <Error>{errors?.users?.[0]?.username[0]}</Error>
              ) : (
                errors?.users?.[0] && <Error>{errors?.users?.[0]}</Error>
              )}
            </Field>
            <Field>
              <Label>Senha</Label>
              <Input
                block
                value={user.password}
                onChange={onUserChange}
                placeholder="Senha"
                name="password"
                type={showPw ? 'text' : 'password'}
                error={errors?.users?.[0]?.password}
              />
              {errors?.users?.[0]?.password && (
                <Error>{errors?.users?.[0]?.password[0]}</Error>
              )}
              <FontAwesomeIcon
                className="eye"
                icon={faEye}
                onClick={() => setShowPw(!showPw)}
              />
              <FontAwesomeIcon
                className="key"
                icon={faKey}
                onClick={_generatePassword}
              />{' '}
            </Field>
          </>
        ) : (
          <Field
            style={{
              marginTop: '8px',
              position: 'relative',
            }}
          >
            <Label>Selecione um usuário do seu cloud</Label>
            <Existent onClick={() => setChoose(false)}>Criar novo</Existent>
            <Select height="40px" value={user} onChange={onSelectChange}>
              <option value="">Selecione um usuário</option>
              {databaseUsers?.map(u => (
                <option key={u.id} value={JSON.stringify(u)}>
                  {u.username}
                </option>
              ))}
            </Select>
          </Field>
        )}
      </Card>
    </Wrapper>
  );
};

export default withTheme(Users);
