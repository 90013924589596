import styled, { css } from 'styled-components';

interface InputProps {
  height?: string | number;
  width?: string | number;
  block?: boolean | string;
  background?: boolean;
  error?: string;
  required?: boolean;
  dark?: boolean;
  sizeIcon?: string;
  topIcon?: string;
  icon?: boolean;
  mb?: string;
}

export const Input = styled.div<InputProps>`
  display: flex;
  flex-direction: column;

  margin-bottom: ${props => props.mb};

  width: ${props =>
    props.width ? props.width : props.block ? '100%' : '255px'};

  @media (max-width: 768px) {
    width: 100%;
  }

  svg {
    width: 21px;
    color: ${props => props.theme.interface5};
  }

  .withIcon {
    width: 100%;
    display: block;

    position: relative;

    .icon {
      width: 21px;
      height: 100%;
      position: absolute;

      display: flex;
      align-items: center;

      left: 0;
      font-size: ${props => (props.sizeIcon ? props.sizeIcon : '18px')};
      top: ${props => (props.topIcon ? props.topIcon : '0')};
      padding-left: 10px;
      color: #cfd3de;
      cursor: pointer;
    }

    .isRight {
      right: 26px;
      left: auto;
    }

    .multipleIcons {
      right: 55px;
      left: auto;
    }
  }
  .isRight {
    input {
      padding: 12px 40px 12px 12px;
    }
  }

  ${props =>
    props.error &&
    css`
      svg {
        color: ${props => props.theme.errorNew};
      }
    `}

  input {
    width: 100%;
    height: ${props => props.height || '40px'};
    border: 2px solid
      ${props =>
        props.theme.darkMode ? props.theme.interface6 : props.theme.interface3};
    color: ${props =>
      props.theme.darkMode ? props.theme.interface6 : props.theme.interface5};
    padding: ${props =>
      props.icon ? '12px 12px 12px 38px' : '12px 12px 12px 12px'};
    font-size: 14px;
    border: 1px solid ${props => props.theme.interface3};
    border-radius: 5px;
    ${props =>
      props.theme.darkMode &&
      css`
        background-color: ${props => props.theme.interface1};
      `}
    &::placeholder {
      color: ${props =>
        props.theme.darkMode ? props.theme.interface7 : props.theme.interface4};
    }

    &:focus {
      transition: border 0.2s ease-in-out;
      border: 2px solid ${props => props.theme.brand_primary_color};
    }
    ${props =>
      props.background &&
      css`
        background-color: ${props => props.theme.interface2};
      `}
    ${props =>
      props.error &&
      css`
        border-color: ${props => props.theme.errorNew};
      `}

      position:relative;
  }

  .error {
    display: block;
    font-size: 10px;
    color: ${props => props.theme.error};
    position: relative;
    margin-top: 5px;
  }
`;
