import React, { useContext, useMemo } from 'react';
import { MigrationDetailContext } from '../_context/state';
import { ProgressLine, ProgressBar, ProgressContainer, Label } from './styles';

const Progress: React.FC<{ status: number }> = ({ status }) => {
  const { migration } = useContext(MigrationDetailContext);

  const nodeappPercentage = useMemo(() => {
    if (migration.nodeapp?.disk_usage && migration.app_size) {
      const perc = Math.floor(
        (migration.nodeapp.disk_usage * 100) / migration.app_size,
      );
      const syncPerc = perc > 0 ? Math.floor((perc * 50) / 100) : 15;

      return syncPerc;
    }

    return 15;
  }, [migration]);

  return (
    <ProgressContainer>
      <Label>
        {status === 0
          ? 'NA FILA 0%'
          : status === 1
          ? `SINCRONIZANDO ${nodeappPercentage}%`
          : status === 2
          ? 'CONFIGURANDO 50%'
          : status === 3
          ? 'VALIDANDO 90%'
          : status >= 4
          ? 'FINALIZADO 100%'
          : null}
      </Label>
      <ProgressBar />
      <ProgressLine status={status} percentage={nodeappPercentage} />
    </ProgressContainer>
  );
};

export default Progress;
