import React, { useCallback, useMemo, useState, useContext } from 'react';
import { Row, Col } from 'react-bootstrap';

import { Field, Label, Separator, OfflineButton } from 'components/Details';
import { Input, Error } from '@cloudez/cloudez-design-system';
import NodeappField from 'components/NodeappField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { ThemeContext } from 'styled-components';
import { Shared, SharedUser, NoUsers } from 'components/Shared';
import { NodeAppType } from 'types';
import { WebsiteDetailContext } from '../_context/state';

interface IDomainProps {
  type: NodeAppType;
  onChange(e: any): any;
  errors: any;
}

const Domain: React.FC<IDomainProps> = ({ type, onChange, errors }) => {
  const { website, updateLoading } = useContext(WebsiteDetailContext);

  const [newAlias, setNewAlias] = useState('');
  const theme = useContext(ThemeContext);

  const aliases = useMemo(() => {
    return website.values.filter(value => value.slug === 'aliases')[0];
    // eslint-disable-next-line
  }, [website]);
  const [_aliases, setAliases] = useState(aliases.value.split(' '));

  const fields = useMemo(() => {
    return ['domain_www', 'https_enable', 'https_force', 'domain_canonical'];
    // eslint-disable-next-line
  }, []);

  const canActivate = useMemo(() => {
    return website.is_hosted;
    // eslint-disable-next-line
  }, [website]);

  const addAlias = useCallback(() => {
    if (newAlias === website.domain) {
      toast.error('Você não pode adicionar o domínio como alias');
      setNewAlias('');
      return;
    }
    setAliases([..._aliases, newAlias]);

    onChange({
      target: {
        name: 'aliases',
        type: 'aliases',
        value: [..._aliases, newAlias].join(' '),
      },
    });

    setNewAlias('');
    // eslint-disable-next-line
  }, [website, newAlias, _aliases]);

  const deleteAlias = useCallback(
    alias => {
      setAliases(_aliases.filter(a => a !== alias));

      onChange({
        target: {
          name: 'aliases',
          type: 'aliases',
          value: _aliases.filter(a => a !== alias).join(' '),
        },
      });
      // eslint-disable-next-line
    },
    [_aliases],
  );

  return (
    website && (
      <Row>
        <Col xl>
          {website?.values.map((v, i) => {
            if (fields.includes(v.slug)) {
              const [field] = type.values.filter(_v => v.slug === _v.slug);
              return (
                field && (
                  <NodeappField
                    disabled={
                      (v.slug === 'https_enable' || v.slug === 'https_force') &&
                      !canActivate
                    }
                    key={v.slug}
                    field={field}
                    value={v.value}
                    onChange={onChange}
                  />
                )
              );
            }
            return null;
          })}
          {!canActivate && (
            <p
              style={{
                color: theme.interface4,
                fontSize: '13px',
                marginTop: '12px',
              }}
            >
              * Algumas funcionalidades só estarão disponíveis caso seu website
              estiver hospedado e ativo no seu painel.
            </p>
          )}
        </Col>
        <Separator />
        <Col xl>
          <Field>
            <Label>ALIAS DE DOMÍNIO</Label>
            <Row
              style={{
                marginTop: `${5}px`,
                marginBottom: `${5}px`,
              }}
            >
              <Col xs="9" sm="10">
                <Input
                  block
                  value={newAlias}
                  onChange={e => setNewAlias(e.target.value)}
                  error={!!errors?.values?.aliases}
                />
                {errors?.values?.aliases && (
                  <Error>{errors?.values?.aliases[0]}</Error>
                )}
              </Col>
              <Col xs="3" sm="2">
                <OfflineButton disabled={updateLoading} onClick={addAlias}>
                  {updateLoading ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    <FontAwesomeIcon icon={faPlus} />
                  )}
                </OfflineButton>
              </Col>
            </Row>
            <Shared>
              {_aliases ? (
                _aliases.map(alias => {
                  return (
                    alias !== '' && (
                      <SharedUser key={alias}>
                        {alias}{' '}
                        <FontAwesomeIcon
                          icon={faTimes}
                          onClick={() => deleteAlias(alias)}
                        />
                      </SharedUser>
                    )
                  );
                })
              ) : (
                <NoUsers>Este website ainda não possui alias</NoUsers>
              )}
            </Shared>
          </Field>
        </Col>
      </Row>
    )
  );
};

export default Domain;
