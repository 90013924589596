import React, { useEffect, useState, useContext } from 'react';

import { Link, useHistory } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';
import Swipe from 'react-easy-swipe';

import kbToMb from 'utils/kbToMb';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDoorOpen,
  faChevronLeft,
  faChevronRight,
  faPowerOff,
} from '@fortawesome/free-solid-svg-icons';

import usePersistedState from 'hooks/usePersistedState';
import { useDocTitle } from 'hooks/docTitle';

import { Button, Arrows } from '@cloudez/cloudez-design-system';
import {
  Tabs,
  Tab,
  TabContent,
  Field,
  Label,
  Value,
  Content,
  Header,
  Title,
  Card,
  CardHeader,
  OfflineButton,
} from 'components/Details';
import ApplyChangesButton from 'components/ApplyChangesButton';
import RemoveModal from 'components/ListingModal/RemoveModal';
import { Breadcrumbs, Breadcrumb } from 'components/Common';
import ApplicationIcon from 'components/ApplicationIcon';
import Ticket from 'components/Ticket';

import { useAuth } from 'hooks/auth';
import { getCloudService } from 'services/cloud';
import { EmailDetailProvider, EmailDetailContext } from './_context/state';

import OfflineModal from './OfflineModal';
import Placeholder from './Placeholder';
import Usuarios from './Usuarios';
import Common from './Common';
import Groups from './Groups';
import Share from './Share';

const EmailDetail = ({ match }) => {
  const {
    email,
    getEmail,
    patchEmail,
    deleteEmail,
    updateValue,
    updateLoading,
  } = useContext(EmailDetailContext);

  const history = useHistory();

  const handleDelete = () => {
    deleteEmail(email.id);
  };

  const [isEditing, setIsEditing] = useState(false);
  const [modal, showModal] = useState(false);
  const [offlineModal, setOfflineModal] = useState(false);
  const [tab, setTab] = usePersistedState('emailTab', 1);

  useEffect(() => {
    let unblock;
    if (isEditing) {
      unblock = history.block(
        'Você tem alterações não aplicadas. O que deseja fazer?',
      );
    }

    return () => {
      if (unblock) unblock();
    };
    // eslint-disable-next-line
  }, [isEditing]);

  const changeValue = e => {
    setIsEditing(true);
    updateValue(e);
  };

  useEffect(() => {
    const { id } = match.params;
    getEmail(id);
    // eslint-disable-next-line
  }, []);

  const applyChanges = async (domain = false) => {
    if (isEditing || domain) {
      await patchEmail('values', email.values, email.id);
      setIsEditing(false);
    }
  };

  const updateEmail = async (field, value) => {
    await patchEmail(field, value, email.id);
  };

  const { user } = useAuth();

  const [cloudOwnerEmail, setCloudOwnerEmail] = useState();
  const isCloudOwner = cloudOwnerEmail === user?.email;

  useEffect(() => {
    if (email?.cloud) {
      const getCloud = async () => {
        const { data } = await getCloudService(email?.cloud);
        setCloudOwnerEmail(data.admin.email);
      };
      getCloud();
    }
  }, [email?.cloud]);

  const cloudDisplay =
    user.is_company_owner && isCloudOwner
      ? email?.cloud_display
      : email?.cloud_display?.split('-')[0];

  useDocTitle(email ? `${email?.domain} — Email` : 'Email — Cloudez');

  return email ? (
    <>
      {offlineModal && (
        <OfflineModal
          show={offlineModal}
          setShow={() => setOfflineModal(false)}
          action={updateEmail}
        />
      )}
      {modal && (
        <RemoveModal
          typeName="email"
          action={handleDelete}
          show={modal}
          setShow={() => showModal(false)}
        />
      )}
      <Header>
        <Row>
          <Col sm="8" md="9">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ApplicationIcon
                width="64px"
                height="64px"
                icon={
                  email.type.slug === 'selfhosted-mail'
                    ? 'cloudez-footer'
                    : `${email.type.slug}`
                }
              />
              <Title>
                <Breadcrumbs>
                  <Breadcrumb>
                    <Link to="/dashboard">Home</Link>
                  </Breadcrumb>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <Breadcrumb>
                    <Link to="/emails">Emails</Link>
                  </Breadcrumb>
                </Breadcrumbs>
                <h3>{email.domain}</h3>
                <p>{cloudDisplay}</p>
              </Title>
            </div>
          </Col>
          <Col
            sm="1"
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <OfflineButton
              offline={email.is_disabled}
              onClick={() => setOfflineModal(true)}
            >
              {email.is_disabled ? (
                <FontAwesomeIcon icon={faPowerOff} fade />
              ) : (
                <FontAwesomeIcon icon={faPowerOff} />
              )}
              <span>{email.is_disabled ? ' Desabilitado' : ' Habilitado'}</span>
            </OfflineButton>
          </Col>
          <Col
            className="d-none d-sm-flex d-md-flex d-xl-flex"
            sm="3"
            md="2"
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button block outline error remove onClick={() => showModal(true)}>
              Excluir email
            </Button>
          </Col>
        </Row>
      </Header>

      <Row style={{ marginBottom: `${30}px` }}>
        <Col xl="7">
          <Row>
            <Col xl="12">
              <Card>
                <Row>
                  <Col xl="6">
                    <Field>
                      <Value>
                        <CardHeader style={{ fontWeight: '400' }}>
                          Informações
                        </CardHeader>
                      </Value>
                    </Field>
                    {email.cloud_fqdn && (
                      <Field>
                        <Label>Servidor</Label>
                        <Value>
                          <Link to={`/clouds/${email.cloud}`}>
                            {email.cloud_fqdn}
                          </Link>
                        </Value>
                      </Field>
                    )}
                    {email.webmail_url && (
                      <Field>
                        <Label>Webmail</Label>
                        <Value>
                          <a
                            href={email.webmail_url}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {email.webmail_url}
                          </a>
                        </Value>
                      </Field>
                    )}
                    {email.webmail_url && (
                      <Field>
                        <Label>Link Temporário</Label>
                        <Value>
                          <a
                            href={`http://webmail.${email.cloud_fqdn}`}
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {`http://webmail.${email.cloud_fqdn}`}
                          </a>
                        </Value>
                      </Field>
                    )}
                  </Col>
                  <Col xs="6" sm="5" md="4" lg="6">
                    <Field>
                      <Label>SMTP | Portas</Label>
                      <Value>{`smtp.${email.domain} | 587 ou 465`}</Value>
                    </Field>
                    <Field>
                      <Label>POP | Portas</Label>
                      <Value>{`pop.${email.domain} | 110 ou 995`}</Value>
                    </Field>
                    <Field>
                      <Label>IMAP | Portas</Label>
                      <Value>{`imap.${email.domain} | 143 ou 993`}</Value>
                    </Field>
                    <Field>
                      <Label>Uso total de disco</Label>
                      <Value>
                        {email.disk_usage ? kbToMb(email.disk_usage) : '0 B'}
                      </Value>
                    </Field>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xl="5">
          <Card>
            <CardHeader style={{ fontWeight: '400' }}>Tickets</CardHeader>
            <Row>
              <Col xs>
                <Ticket type="3" id={email.id} />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      {email.type.slug === 'selfhosted-mail' && (
        <Row>
          <Col>
            <Tabs>
              <Tab active={tab === 1} onClick={() => setTab(1)}>
                Contas
              </Tab>
              <Tab active={tab === 2} onClick={() => setTab(2)}>
                Grupos
              </Tab>
              <Tab active={tab === 3} onClick={() => setTab(3)}>
                Geral
              </Tab>
              <Tab active={tab === 4} onClick={() => setTab(4)}>
                Compartilhamento
              </Tab>
            </Tabs>

            <Arrows
              disabled={tab === 1}
              icon={faChevronLeft}
              left
              onClick={() => tab > 1 && setTab(tab - 1)}
              actions
            />
            <Swipe
              onSwipeRight={() => tab > 1 && setTab(tab - 1)}
              onSwipeLeft={() => tab < 3 && setTab(tab + 1)}
              tolerance={100}
            >
              <TabContent>
                <Content loading={updateLoading}>
                  {(() => {
                    switch (tab) {
                      case 1:
                        return <Usuarios />;
                      case 2:
                        return <Groups />;
                      case 3:
                        return <Common onChange={changeValue} />;
                      case 4:
                        return <Share />;
                      default:
                        return null;
                    }
                  })()}
                </Content>
                <ApplyChangesButton
                  show={isEditing}
                  onClick={applyChanges}
                  loading={updateLoading}
                />
              </TabContent>
            </Swipe>
            <Arrows
              disabled={tab === 3}
              icon={faChevronRight}
              right
              onClick={() => tab < 3 && setTab(tab + 1)}
              actions
            />
            <Row
              style={{
                marginTop: '20px',
              }}
            >
              <Col xs="12" className="d-flex d-sm-none">
                <Button
                  style={{
                    width: '100%',
                  }}
                  remove
                  outline
                  error
                  onClick={() => showModal(true)}
                >
                  Excluir email
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      )}
    </>
  ) : (
    <Placeholder />
  );
};

export default props => (
  <EmailDetailProvider>
    <EmailDetail {...props} />
  </EmailDetailProvider>
);
