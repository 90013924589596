import React, { useState } from 'react';
import { withTheme } from 'styled-components';
import { Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff, faSpinner } from '@fortawesome/free-solid-svg-icons';

import { Modal, Input, Button } from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from 'components/ListingModal/styles';

import { ModalProps } from 'types';
import { useLayout } from 'hooks/layout';
import { HttpsText } from './styles';

interface OfflineModalProps extends ModalProps {
  account: any;
}

const OfflineModal: React.FC<OfflineModalProps> = ({
  action,
  show,
  setShow,
  account,
}) => {
  const [desabilitar, setDesabilitar] = useState('');
  const [loading, setLoading] = useState(false);
  const theme = useLayout();

  return (
    <Modal show={show} setShow={setShow} noExit={loading}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faPowerOff} />
        </Icon>
        <Text>
          {account.is_disabled ? (
            <>
              <Title>Habilitar conta</Title>
              <Subtitle>Deseja habilitar esta conta?</Subtitle>
            </>
          ) : (
            <>
              <Title>Desabilitar conta</Title>
              <Subtitle>Deseja desabilitar esta conta?</Subtitle>
            </>
          )}
        </Text>
      </Header>
      <Content>
        <HttpsText style={{ marginBottom: '24px', fontFamily: 'Nunito' }}>
          Por favor, escreva{' '}
          <span
            style={{
              textTransform: 'uppercase',
              fontWeight: 'bold',
              color: account.is_disabled
                ? theme.brand_primary_color
                : theme.error,
            }}
          >
            {account.is_disabled ? 'HABILITAR' : 'DESABILITAR'}
          </span>
          , para confirmar a ação.
        </HttpsText>
        <Input
          block
          placeholder={account.is_disabled ? 'HABILITAR' : 'DESABILITAR'}
          value={desabilitar}
          onChange={e => {
            setDesabilitar(e.target.value);
          }}
        />
      </Content>
      <Footer>
        <Button
          width="149px"
          icon
          disabled={
            (account.is_disabled
              ? desabilitar !== 'HABILITAR'
              : desabilitar !== 'DESABILITAR') || loading
          }
          error={!account.is_disabled}
          onClick={() => {
            setLoading(true);
            action(!account.is_disabled);
            setShow();
          }}
        >
          {loading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : account.is_disabled ? (
            'Habilitar'
          ) : (
            'Desabilitar'
          )}
        </Button>
      </Footer>
    </Modal>
  );
};

export default OfflineModal;
