import React, { useState } from 'react';

import { changeStatusMigrationService } from 'services/friday';

import { faCloudUploadAlt, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Modal, Button, Input, TextArea } from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Footer,
} from 'components/ListingModal/styles';

import { Content } from './styles';

interface CancelMigrationModalProps {
  show: boolean;
  setShow: (value: boolean) => void;
  setIsCancelled: (value: boolean) => void;
  setStatusMig: (value: number) => void;
  id: number;
  domain: string;
}

const CancelMigrationModal = ({
  show,
  setShow,
  setIsCancelled,
  setStatusMig,
  id,
  domain,
}: CancelMigrationModalProps): JSX.Element => {
  const [loading, setLoading] = useState(false);

  const [value, setValue] = useState('');
  const [reason, setReason] = useState('');

  const onSubmit = async () => {
    setLoading(true);
    try {
      await changeStatusMigrationService(id, {
        status: 5,
        cancel_message: reason,
      });
      setStatusMig(5);
      setIsCancelled(true);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log('err', err);
    }
    setShow(false);
  };

  return (
    <Modal width="562px" show={show} setShow={setShow}>
      <Header style={{ borderBottom: '0px' }}>
        <Icon>
          <FontAwesomeIcon
            style={{ fontSize: '20px' }}
            icon={faCloudUploadAlt}
          />
        </Icon>
        <Text>
          <Title>Cancelar migração</Title>
          <Subtitle>Deseja cancelar a migração de {domain}?</Subtitle>
        </Text>
      </Header>

      <Content>
        <span>
          Ao cancelar sua migração, ela continuará visível na sua listagem porém
          as ações pararão de funcionar.
        </span>
        <div className="input">
          <span>
            Por favor, escreva <b>CANCELAR</b> para confirmar
          </span>

          <Input value={value} onChange={e => setValue(e.target.value)} />
        </div>
        <div className="input">
          <span>
            Se desejar, nos informe abaixo o motivo do cancelamento da migração:
          </span>

          <TextArea
            style={{ resize: 'none' }}
            width="100%"
            height="106px"
            placeholder="Descreva o motivo do cancelamento"
            value={reason}
            onChange={e => setReason(e.target.value)}
          />
        </div>
      </Content>

      <Footer style={{ borderTop: '0px' }}>
        <Button
          disabled={loading || value !== 'CANCELAR'}
          icon={loading ? faSpinner : undefined}
          onClick={onSubmit}
          error
        >
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'CANCELAR'}
        </Button>
      </Footer>
    </Modal>
  );
};

export default CancelMigrationModal;
