import React from 'react';
import { Col, Row } from 'react-bootstrap';

import Placeholder from 'components/Placeholder';

import { Title, Header } from 'components/Common';
import {
  Card,
  CardHeader,
  CardBody,
  Clouds,
  Individual,
  Statistics,
  Top,
  Value,
  HTTPS,
} from '../styles';

const DashboardPlaceholder: React.FC = () => {
  return (
    <>
      <Header>
        <Title>
          <Placeholder width={200} height={30} />
        </Title>
      </Header>

      <Row>
        <Col style={{ marginBottom: '14px' }} xl="4">
          <Card>
            <CardHeader>
              <Placeholder width={100} height={20} />
            </CardHeader>
            <CardBody>
              <Clouds>
                <Placeholder width={40} height={30} />
                <Individual
                  style={{
                    marginTop: '14px',
                  }}
                >
                  <span>
                    <Placeholder width={60} height={14} />
                  </span>
                  <span>
                    <Placeholder width={60} height={14} />
                  </span>
                  <span>
                    <Placeholder width={60} height={14} />
                  </span>
                  <span>
                    <Placeholder width={60} height={14} />
                  </span>
                </Individual>
              </Clouds>
            </CardBody>
          </Card>
        </Col>
        <Col style={{ marginBottom: '14px' }} xl="4">
          <Card>
            <CardHeader>
              <Placeholder width={100} height={20} />
            </CardHeader>
            <CardBody>
              <Statistics>
                <li>
                  <Placeholder width={100} height={14} />
                </li>
                <li>
                  <Placeholder width={100} height={14} />
                </li>
                <li>
                  <Placeholder width={100} height={14} />
                </li>
              </Statistics>
            </CardBody>
          </Card>
        </Col>
        <Col style={{ marginBottom: '14px' }} xl="4">
          <Card>
            <CardHeader>
              <Placeholder width={100} height={20} />
            </CardHeader>
            <CardBody>
              <Row
                style={{
                  height: '100%',
                }}
              >
                <Col
                  style={{
                    height: '100%',
                  }}
                  xs="6"
                >
                  <Placeholder width={100} height={14} />
                </Col>
                <Col
                  style={{
                    height: '100%',
                  }}
                  xs="6"
                >
                  <Placeholder width={100} height={14} />
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col style={{ marginBottom: '14px' }} xl="6">
          <Card>
            <CardHeader>
              <Placeholder width={100} height={20} />
            </CardHeader>
            <CardBody>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Placeholder width={280} height={280} circle />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col style={{ marginBottom: '14px' }} xl="6">
          <Card>
            <CardHeader>
              <Placeholder width={130} height={20} />
            </CardHeader>
            <CardBody>
              <Top>
                <li>
                  <Row>
                    <Col style={{ marginBottom: '14px' }} xs="2">
                      <Placeholder width={30} height={30} circle />
                    </Col>
                    <Col style={{ marginBottom: '14px' }} xs="7">
                      <Value>
                        <Placeholder width={100} height={14} />
                        <span>
                          <Placeholder width={100} height={11} />
                        </span>
                      </Value>
                    </Col>
                    <Col style={{ marginBottom: '14px' }} xs="3">
                      <span
                        style={{
                          marginTop: '9px',
                        }}
                      >
                        <Placeholder width={30} height={14} />
                      </span>
                    </Col>
                  </Row>
                </li>
                <li>
                  <Row>
                    <Col style={{ marginBottom: '14px' }} xs="2">
                      <Placeholder width={30} height={30} circle />
                    </Col>
                    <Col style={{ marginBottom: '14px' }} xs="7">
                      <Value>
                        <Placeholder width={100} height={14} />
                        <span>
                          <Placeholder width={100} height={11} />
                        </span>
                      </Value>
                    </Col>
                    <Col style={{ marginBottom: '14px' }} xs="3">
                      <span
                        style={{
                          marginTop: '9px',
                        }}
                      >
                        <Placeholder width={30} height={14} />
                      </span>
                    </Col>
                  </Row>
                </li>
                <li>
                  <Row>
                    <Col style={{ marginBottom: '14px' }} xs="2">
                      <Placeholder width={30} height={30} circle />
                    </Col>
                    <Col style={{ marginBottom: '14px' }} xs="7">
                      <Value>
                        <Placeholder width={100} height={14} />
                        <span>
                          <Placeholder width={100} height={11} />
                        </span>
                      </Value>
                    </Col>
                    <Col style={{ marginBottom: '14px' }} xs="3">
                      <span
                        style={{
                          marginTop: '9px',
                        }}
                      >
                        <Placeholder width={30} height={14} />
                      </span>
                    </Col>
                  </Row>
                </li>
                <li>
                  <Row>
                    <Col style={{ marginBottom: '14px' }} xs="2">
                      <Placeholder width={30} height={30} circle />
                    </Col>
                    <Col style={{ marginBottom: '14px' }} xs="7">
                      <Value>
                        <Placeholder width={100} height={14} />
                        <span>
                          <Placeholder width={100} height={11} />
                        </span>
                      </Value>
                    </Col>
                    <Col style={{ marginBottom: '14px' }} xs="3">
                      <span
                        style={{
                          marginTop: '9px',
                        }}
                      >
                        <Placeholder width={30} height={14} />
                      </span>
                    </Col>
                  </Row>
                </li>
                <li>
                  <Row>
                    <Col style={{ marginBottom: '14px' }} xs="2">
                      <Placeholder width={30} height={30} circle />
                    </Col>
                    <Col style={{ marginBottom: '14px' }} xs="7">
                      <Value>
                        <Placeholder width={100} height={14} />
                        <span>
                          <Placeholder width={100} height={11} />
                        </span>
                      </Value>
                    </Col>
                    <Col style={{ marginBottom: '14px' }} xs="3">
                      <span
                        style={{
                          marginTop: '9px',
                        }}
                      >
                        <Placeholder width={30} height={14} />
                      </span>
                    </Col>
                  </Row>
                </li>
              </Top>
              <Placeholder width={100} height={14} />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col style={{ marginBottom: '14px' }} xl="6">
          <Card>
            <CardHeader>
              <Placeholder width={100} height={20} />
            </CardHeader>
            <CardBody>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <Placeholder width={280} height={280} circle />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col style={{ marginBottom: '14px' }} xl="6">
          <Card>
            <CardHeader>
              <Placeholder width={130} height={20} />
            </CardHeader>
            <CardBody>
              <Top>
                <li>
                  <Row>
                    <Col style={{ marginBottom: '14px' }} xs="2">
                      <Placeholder width={30} height={30} circle />
                    </Col>
                    <Col style={{ marginBottom: '14px' }} xs="7">
                      <Value>
                        <Placeholder width={100} height={14} />
                        <span>
                          <Placeholder width={100} height={11} />
                        </span>
                      </Value>
                    </Col>
                    <Col style={{ marginBottom: '14px' }} xs="3">
                      <span
                        style={{
                          marginTop: '9px',
                        }}
                      >
                        <Placeholder width={30} height={14} />
                      </span>
                    </Col>
                  </Row>
                </li>
                <li>
                  <Row>
                    <Col style={{ marginBottom: '14px' }} xs="2">
                      <Placeholder width={30} height={30} circle />
                    </Col>
                    <Col style={{ marginBottom: '14px' }} xs="7">
                      <Value>
                        <Placeholder width={100} height={14} />
                        <span>
                          <Placeholder width={100} height={11} />
                        </span>
                      </Value>
                    </Col>
                    <Col style={{ marginBottom: '14px' }} xs="3">
                      <span
                        style={{
                          marginTop: '9px',
                        }}
                      >
                        <Placeholder width={30} height={14} />
                      </span>
                    </Col>
                  </Row>
                </li>
                <li>
                  <Row>
                    <Col style={{ marginBottom: '14px' }} xs="2">
                      <Placeholder width={30} height={30} circle />
                    </Col>
                    <Col style={{ marginBottom: '14px' }} xs="7">
                      <Value>
                        <Placeholder width={100} height={14} />
                        <span>
                          <Placeholder width={100} height={11} />
                        </span>
                      </Value>
                    </Col>
                    <Col style={{ marginBottom: '14px' }} xs="3">
                      <span
                        style={{
                          marginTop: '9px',
                        }}
                      >
                        <Placeholder width={30} height={14} />
                      </span>
                    </Col>
                  </Row>
                </li>
                <li>
                  <Row>
                    <Col style={{ marginBottom: '14px' }} xs="2">
                      <Placeholder width={30} height={30} circle />
                    </Col>
                    <Col style={{ marginBottom: '14px' }} xs="7">
                      <Value>
                        <Placeholder width={100} height={14} />
                        <span>
                          <Placeholder width={100} height={11} />
                        </span>
                      </Value>
                    </Col>
                    <Col style={{ marginBottom: '14px' }} xs="3">
                      <span
                        style={{
                          marginTop: '9px',
                        }}
                      >
                        <Placeholder width={30} height={14} />
                      </span>
                    </Col>
                  </Row>
                </li>
                <li>
                  <Row>
                    <Col style={{ marginBottom: '14px' }} xs="2">
                      <Placeholder width={30} height={30} circle />
                    </Col>
                    <Col style={{ marginBottom: '14px' }} xs="7">
                      <Value>
                        <Placeholder width={100} height={14} />
                        <span>
                          <Placeholder width={100} height={11} />
                        </span>
                      </Value>
                    </Col>
                    <Col style={{ marginBottom: '14px' }} xs="3">
                      <span
                        style={{
                          marginTop: '9px',
                        }}
                      >
                        <Placeholder width={30} height={14} />
                      </span>
                    </Col>
                  </Row>
                </li>
              </Top>
              <Placeholder width={100} height={14} />
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col style={{ marginBottom: '14px' }}>
          <Card>
            <CardHeader>Domínios</CardHeader>
            <CardBody>
              <Placeholder width={100} height={100} />
            </CardBody>
          </Card>
        </Col>

        <Col style={{ marginBottom: '14px' }} xl="6">
          <Card>
            <CardHeader>
              <Placeholder width={100} height={30} />
            </CardHeader>
            <CardBody>
              <Top>
                <li>
                  <Row>
                    <Col style={{ marginBottom: '14px' }} xs="2">
                      <Placeholder circle width={15} height={15} />
                    </Col>
                    <Col style={{ marginBottom: '14px' }} xs="7">
                      <Value>
                        <Placeholder width={100} height={14} />
                      </Value>
                    </Col>
                  </Row>
                </li>
                <li>
                  <Row>
                    <Col style={{ marginBottom: '14px' }} xs="2">
                      <Placeholder circle width={15} height={15} />
                    </Col>
                    <Col style={{ marginBottom: '14px' }} xs="7">
                      <Value>
                        <Placeholder width={100} height={14} />
                      </Value>
                    </Col>
                  </Row>
                </li>
                <li>
                  <Row>
                    <Col style={{ marginBottom: '14px' }} xs="2">
                      <Placeholder circle width={15} height={15} />
                    </Col>
                    <Col style={{ marginBottom: '14px' }} xs="7">
                      <Value>
                        <Placeholder width={100} height={14} />
                      </Value>
                    </Col>
                  </Row>
                </li>
                <li>
                  <Row>
                    <Col style={{ marginBottom: '14px' }} xs="2">
                      <Placeholder circle width={15} height={15} />
                    </Col>
                    <Col style={{ marginBottom: '14px' }} xs="7">
                      <Value>
                        <Placeholder width={100} height={14} />
                      </Value>
                    </Col>
                  </Row>
                </li>
                <li>
                  <Row>
                    <Col style={{ marginBottom: '14px' }} xs="2">
                      <Placeholder circle width={15} height={15} />
                    </Col>
                    <Col style={{ marginBottom: '14px' }} xs="7">
                      <Value>
                        <Placeholder width={100} height={14} />
                      </Value>
                    </Col>
                  </Row>
                </li>
              </Top>

              <Placeholder width={100} height={14} />
            </CardBody>
          </Card>
        </Col>

        <Col style={{ marginBottom: '14px' }}>
          <Card>
            <CardHeader>
              <Placeholder width={100} height={30} />
            </CardHeader>
            <CardBody>
              <HTTPS>
                <Placeholder width={100} height={60} />
                <div>
                  <Placeholder width={60} height={30} />

                  <span>Let's Encrypt</span>
                  <Placeholder width={60} height={30} />

                  <span>Paga</span>
                </div>
              </HTTPS>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default DashboardPlaceholder;
