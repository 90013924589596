import React, { useState } from 'react';

import { useDropzone } from 'react-dropzone';

import { toast } from 'react-toastify';

import { uploadProofOfPaymentService } from 'services/invoice';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDollarSign,
  faSpinner,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

import { Button, Error, Modal } from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Footer,
} from 'components/ListingModal/styles';
import { AttachmentBox } from 'pages/Tickets/TicketCreateNew/styles';
import { Content } from './styles';

interface ReactivateModalProps {
  show: boolean;
  setShow: (value: boolean) => void;
  id: number;
  proofSent: () => void;
}

const ReactivateModal = ({
  show,
  setShow,
  id,
  proofSent,
}: ReactivateModalProps): JSX.Element => {
  const { acceptedFiles, getInputProps, getRootProps } = useDropzone();

  const [loading, setLoading] = useState(false);

  const [showError, setShowError] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);

  const removeFile = file => () => {
    acceptedFiles.splice(acceptedFiles.indexOf(file), 1);
  };

  const submitProofOfPayment = async () => {
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const fd = new FormData();

    if (acceptedFiles.length === 0) {
      setShowError(true);
      return;
    }

    fd.append('proof_of_payment', acceptedFiles[0]);

    try {
      setLoading(true);

      await uploadProofOfPaymentService(id, fd, config);
      proofSent();
      setLoading(false);
      setShowSuccess(true);
    } catch (err) {
      setLoading(false);
      setShow(false);
      toast.error(err?.response?.data?.detail);
    }
  };

  return (
    <Modal show={show} setShow={setShow}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faDollarSign} style={{ color: 'black' }} />
        </Icon>
        <Text>
          <Title>Confirmar pagamento</Title>
          <Subtitle>Envie seu comprovante para reativar seus produtos</Subtitle>
        </Text>
      </Header>
      <Content>
        {showSuccess ? (
          <div className="successBox">
            <span className="success">Comprovante enviado com sucesso!</span>
            <span>
              Se tudo correr conforme o esperado, sua cloud será reativada em
              até 10 minutos.
            </span>
          </div>
        ) : (
          <>
            <span>
              Para reativar o cloud, por favor, envie o comprovante de pagamento
              no local indicado abaixo.
            </span>
            <span>
              A reativação ocorrerá em até 10 minutos, mas pode levar mais tempo
              dependendo das circunstâncias e permanecerá ativo por até 3 dias.
            </span>
            <div className="box">
              <AttachmentBox {...getRootProps({ className: 'dropzone' })}>
                {acceptedFiles.length > 0 ? (
                  <>
                    {acceptedFiles.map((file: any) => (
                      <div key={file?.name} className="fileContainer">
                        <div className="fileBox">
                          <span>{file?.name}</span>
                          <FontAwesomeIcon
                            icon={faTimes}
                            onClick={removeFile(file)}
                          />
                        </div>
                      </div>
                    ))}
                  </>
                ) : (
                  <span className="text">
                    Clique para adicionar ou arraste o arquivo aqui
                  </span>
                )}
                <input data-testid="file-uploader" {...getInputProps()} />
              </AttachmentBox>
              {showError && <Error>Esse campo é obrigatório.</Error>}
            </div>
          </>
        )}
      </Content>
      <Footer hasLink>
        <Button onClick={() => setShow(false)} text outline>
          Fechar
        </Button>
        {!showSuccess && (
          <Button onClick={submitProofOfPayment} disabled={loading} icon>
            {loading ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              'Enviar comprovante'
            )}
          </Button>
        )}
      </Footer>
    </Modal>
  );
};

export default ReactivateModal;
