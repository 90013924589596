import api from 'services/api';
import Axios from 'axios';

export const getMigrationsService = async (
  params,
  ctrl = new AbortController(),
) => {
  try {
    return await api.get('/v3/migration-list/', {
      params,
      signal: ctrl.signal,
    });
  } catch (e) {
    throw e;
  }
};

export const getMigrationService = async (id, params?) => {
  try {
    return await api.get(`/v2/migration/${id}/`, { params });
  } catch (e) {
    throw e;
  }
};

export const getSyncsService = async (id, params?) => {
  try {
    return await api.get(`/v2/sync-v2/`, { params });
  } catch (e) {
    throw e;
  }
};

export const deleteMigrationService = async id => {
  try {
    return await api.delete(`/v2/migration/${id}/`);
  } catch (e) {
    throw e;
  }
};

export const updateMigrationService = async (id, data) => {
  try {
    return await api.patch(`/v2/migration/${id}/status/`, data);
  } catch (e) {
    throw e;
  }
};

export const createWebsiteMigrationService = async migration => {
  try {
    return await api.post('/v2/migration-website-2/', migration);
  } catch (e) {
    throw e;
  }
};

export const createInternalEmailMigrationService = async migration => {
  try {
    return await api.post('/v2/migration-email-internal/', migration);
  } catch (e) {
    throw e;
  }
};

export const createExternalEmailMigrationService = async migration => {
  try {
    return await api.post('/v2/migration-email-2/', migration);
  } catch (e) {
    throw e;
  }
};

export const vldValidateImapService = async payload => {
  const headers = { Authorization: 'Bearer api_token_validator' };

  try {
    return await Axios.post(
      `${process.env.REACT_APP_VLD_URL}v2/imap`,
      payload,
      {
        headers,
      },
    );
  } catch (e) {
    throw e;
  }
};

export const vldValidateWebsiteService = async payload => {
  const headers = { Authorization: 'Bearer api_token_validator' };

  try {
    return await Axios.post(
      `${process.env.REACT_APP_VLD_URL}website`,
      payload,
      {
        headers,
      },
    );
  } catch (e) {
    throw e;
  }
};

export const migrationChangePinnedService = async id => {
  try {
    return await api.post(`/v2/migration/${id}/change-pinned/`);
  } catch (e) {
    throw e;
  }
};
