import styled, { css } from 'styled-components';
import { Field } from '@cloudez/cloudez-design-system';

export const WebsiteList = styled.ul`
  position: absolute;
  width: 100%;

  max-height: 122px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #e8eaef;
  }

  &::-webkit-scrollbar-thumb {
    background: #cfd3de;
  }

  z-index: 999;
`;
export const WebsiteItem = styled.li`
  position: relative;
  background: ${props => props.theme.interface1};
  width: 100%;
  display: flex;
  align-items: center;
  border: 2px solid
    ${props =>
      props.theme.darkMode ? props.theme.interface2 : props.theme.interface3};
  border-bottom: none;
  border-top: none;
  height: 40px;

  &:last-child {
    border-bottom: 2px solid
      ${props =>
        props.theme.darkMode ? props.theme.interface2 : props.theme.interface3};
  }
  & + li {
    border-top: 2px solid
      ${props =>
        props.theme.darkMode ? props.theme.interface2 : props.theme.interface3};
  }

  img {
    margin-right: 14px;
    margin-left: 14px;
  }

  & > div {
    display: flex;
    flex-direction: column;

    span {
      display: block;
      margin: 0;
      color: ${props => props.theme.interface6};
      font-size: 13px;
    }

    p {
      color: ${props => props.theme.interface5} !important;
      font-size: 9px;
      font-weight: 400;
    }
  }

  &:hover {
    cursor: pointer;
    background: ${props => props.theme.brand_primary_color};
    border-color: ${props => props.theme.brand_primary_color};

    span {
      color: ${props => props.theme.interface1};
    }

    p {
      color: ${props => props.theme.interface2} !important;
    }
  }
`;

interface CardProps {
  isEditing?: boolean;
}

export const Card = styled.div<CardProps>`
  width: 100%;
  min-height: 45px;
  margin-bottom: 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 14px 0px;
  padding-left: 10px;
  background: ${props => props.theme.interface1};
  border: 1px solid transparent;

  &:hover {
    border: 1px solid ${props => props.theme.brand_primary_color};

    p {
      color: ${props => props.theme.brand_primary_color};
    }

    svg {
      color: ${props => props.theme.brand_primary_color};
    }
  }

  ${props =>
    props.isEditing &&
    css`
      border: 1px solid ${props => props.theme.brand_primary_color};
    `}
`;

export const CardContent = styled.div`
  width: 100%;
  height: 100%;
  font-weight: bold;

  svg {
    font-size: 20px;
  }

  p {
    color: ${props => props.theme.interface5};
    display: flex;
    align-items: center;
    height: 100%;
  }

  @media (max-width: 768px) {
    ${Field} {
      margin-top: 6px;
    }

    p {
      margin: 2px 0;
    }
  }
`;

export const Buttons = styled.div`
  svg {
    color: ${props => props.theme.interface5};
    cursor: pointer;
    font-size: 18px;
    & + svg {
      margin-left: 14px;
    }
  }

  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
`;
