import styled from 'styled-components';

interface WrapperProps {
  sideMenuCollapsed: boolean;
  type: any;
  button?: {
    text?: string;
  };
}

export const Wrapper = styled.div<WrapperProps>`
  grid-area: notificationbar;
  z-index: 999;
  height: 60px;
  width: 100%;
  padding: 15px 30px 15px
    ${props => (props.sideMenuCollapsed ? '86px' : '210px')};
  margin: auto;
  background: ${props =>
    props.type === 'warning'
      ? '#EFA703'
      : props.type === 'danger'
      ? '#F5D9E4'
      : 'white'};
  position: fixed;
  top: 0;

  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    max-width: 70%;

    text-align: left;
    font-size: 14px;
    letter-spacing: 0px;
    color: ${props =>
      props.type === 'danger' ? '#5a5a5a' : props.theme.interface1};
    ${props => (props.type === 'danger' ? 'font-weight: bold;' : '')}
    opacity: 1;
  }

  button {
    background: ${props =>
      props?.button?.text === 'Acessar Faturas'
        ? 'blue'
        : props.theme.interface1};
    color: ${props =>
      props?.button?.text === 'Acessar Faturas'
        ? 'white'
        : props.theme.interface7};
    &:hover {
      background: ${props => props.theme.interface1};
      color: ${props => props.theme.interface7};
    }
  }

  img {
    margin-left: 18px;
  }

  @media (max-width: 768px) {
    img {
      display: none;
    }

    span {
      max-width: 50%;
    }

    button {
      max-width: 40%;
    }

    padding: 0;
  }
`;
