import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 210px;
  margin-top: 10px;
`;

interface StrengthLevelProps {
  strength: any;
  level: any;
}
export const StrengthLevel = styled.div<StrengthLevelProps>`
  height: 3px;
  width: 20%;
  margin-right: 5px;
  border-radius: 5px;

  background-color: ${props => {
    if (props.strength >= props.level) {
      switch (props.level) {
        case 0:
          return '#FB264B';
        case 1:
          return '#F8820B';
        case 2:
          return '#FFBF2D';
        case 3:
          return '#93D929';
        case 4:
          return '#29D975';
        default:
          return '';
      }
    }

    return props.theme.interface4;
  }};
`;
