import React, { useContext } from 'react';

import { Row } from 'react-bootstrap';

import { Wrapper } from './styles';
import User from './User';
import Database from './Database';
import { WebsiteCreateContext } from '../_context/state';
import Shellhub from './Shellhub';

const AdvancedOptions = ({ errors, setNoDb }) => {
  const { website, updateWebsiteField } = useContext(WebsiteCreateContext);

  return (
    <Wrapper>
      {website.type === 110 && (
        <Row>
          <Shellhub errors={errors} />
        </Row>
      )}
      {website.type !== 110 && (
        <>
          <Row>
            <User
              website={website}
              updateWebsiteField={updateWebsiteField}
              errors={errors}
            />
          </Row>
          <Row>
            <Database setNoDb={setNoDb} />
          </Row>
        </>
      )}
    </Wrapper>
  );
};

export default AdvancedOptions;
