import React, { createContext, useReducer } from 'react';
import { PaginationType } from '@cloudez/cloudez-design-system';
import reducer from './reducer';
import { setLoading, getMigrations, ratingMigration } from './actions';

export interface MigrationListingState extends PaginationType {
  migrations: any;
  loading: boolean;
  errors: any;
  setLoading(value: boolean): void;
  getMigrations(page: any, search: any, filter: any, order: any): Promise<void>;
  ratingMigration(
    field: string,
    value: any,
    id?: number | string,
  ): Promise<void>;
}

// initial state
const initialState = {
  migrations: null,
  count: null,
  previous: null,
  current: null,
  next: null,
  loading: true,
  errors: null,
};

// context
export const MigrationListingContext = createContext<MigrationListingState>(
  initialState as MigrationListingState,
);

// provider
export const MigrationListingProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    reducer,
    initialState as MigrationListingState,
  );

  return (
    <MigrationListingContext.Provider
      value={{
        // state
        migrations: state.migrations,
        count: state.count,
        previous: state.previous,
        current: state.current,
        next: state.next,
        loading: state.loading,
        errors: state.errors,

        // actions
        setLoading: value => setLoading(dispatch, value),
        getMigrations: async (page, search, filter, order) =>
          await getMigrations(dispatch, page, search, filter, order),
        ratingMigration: async (field, value, id) =>
          await ratingMigration(dispatch, field, value, id),
      }}
    >
      {children}
    </MigrationListingContext.Provider>
  );
};
