import React, { useContext, useMemo } from 'react';
import { ThemeContext } from 'styled-components';

import moment from 'moment';

import kbToMb from 'utils/kbToMb';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDesktop, faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import { Title, Grid, ListItem, Value } from './styles';

import { Score } from '../styles';

const Scores: React.FC<{
  analysis: any;
  tab: number | string;
}> = ({ analysis, tab }) => {
  const device = useMemo(() => {
    return tab === 1 ? analysis?.desktop : analysis?.mobile;
  }, [tab, analysis]);

  return (
    <div
      style={{
        padding: '0 15px',
      }}
    >
      <Title
        style={{
          marginBottom: 20,
        }}
      >
        Notas
      </Title>
      <Grid>
        <div>
          Nota do site:{' '}
          <Value score={device?.score}>{device?.score || '-'}</Value>
        </div>
        <div>
          Tamanho da página:{' '}
          <Value>{kbToMb(device?.pageSize, true) || '-'}</Value>
        </div>
        <div>
          Carregamento:{' '}
          <Value score={device?.loadingTime} loadingTime={device?.loadingTime}>
            {device?.loadingTime ? `${device?.loadingTime}s` : '-'}
          </Value>
        </div>
        <div>
          Requisições: <Value>{device?.numRequests || '-'}</Value>
        </div>
      </Grid>

      <Title
        style={{
          marginTop: '40px',
          marginBottom: '10px',
        }}
      >
        Histórico
      </Title>
      <ul
        style={{
          width: '100%',
        }}
      >
        {analysis?.history?.map(h => {
          const date = moment(h.date).format('DD/MM/YYYY');

          return (
            <ListItem key={h}>
              <span>{date}</span>
              <div />
              <span>
                <FontAwesomeIcon icon={faDesktop} />{' '}
                <Score score={h['desktop-score']}>{h['desktop-score']}</Score>
              </span>
              <span className="ml-3">
                <FontAwesomeIcon icon={faMobileAlt} />{' '}
                <Score score={h['mobile-score']}>{h['mobile-score']}</Score>
              </span>
            </ListItem>
          );
        })}
      </ul>
    </div>
  );
};

export default Scores;
