import React, { useState, useContext, useEffect } from 'react';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import CurrencyFormat from 'react-currency-format';
import Cards from 'react-credit-cards';

import { Row, Col } from 'react-bootstrap';

import {
  Field,
  FormInput,
  Label,
  Form,
  Error,
  FormSelect,
  Button,
} from '@cloudez/cloudez-design-system';
import { Tabs, Tab, TabContent } from 'components/Tabs';
import { ThemeContext } from 'styled-components';
import { createDomainService } from 'services/domain';
import { getCreditcardService, deleteCreditcardService } from 'services/common';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { getInvoiceService, chargeInvoiceService } from 'services/invoice';
import { createCreditCardToken } from 'utils/iugu';
import toastError from 'utils/toastError';
import { Wrapper } from './styles';
import { WebsiteCreateContext } from '../_context/state';

const paymentSchema = Yup.object().shape({
  number: Yup.string().required('Este campo é obrigatório'),
  name: Yup.string().required('Este campo é obrigatório'),
  expiration: Yup.string().required('Este campo é obrigatório'),
  verification_value: Yup.string().required('Este campo é obrigatório'),
});

interface PaymentProps {
  domain: any;
  createWebsite: any;
  setStage: any;
}

const Payment: React.FC<PaymentProps> = ({
  domain,
  createWebsite,
  setStage,
}) => {
  const theme = useContext(ThemeContext);
  const { setLoading } = useContext(WebsiteCreateContext);
  const [errors, setErrors] = useState(null);
  const [creditCard, setCreditCard] = useState(null);
  const [cardErrors, setCardErrors] = useState({} as any);
  const [tab, setTab] = useState(1);
  const [card, setCard] = useState({
    number: '',
    name: '',
    verification_value: '',
    expiration: '',
  });
  const [focus, setFocus] = useState('number');
  const [changeCard, setChangeCard] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    async function getCreditCard() {
      try {
        const { data } = await getCreditcardService();
        setCreditCard(data[0]);
      } catch (e) {
        console.log(e);
      }
    }

    if (!creditCard) getCreditCard();
    // eslint-disable-next-line
  }, []);

  const deleteCreditCard = async () => {
    setDeleteLoading(true);
    try {
      await deleteCreditcardService(creditCard.id);
      setCreditCard(null);
      setDeleteLoading(false);
    } catch (error) {
      setDeleteLoading(false);
    }
  };

  const handleSubmit = async d => {
    setLoading(true);
    setCardErrors({});

    try {
      if (tab === 1) {
        if (!creditCard || changeCard) {
          try {
            await createCreditCardToken(d);
          } catch (e) {
            setLoading(false);

            if (e.errors) {
              setCardErrors(e.errors);
              return;
            }
            setErrors(e);
            return;
          }
        }

        try {
          const { data: domainResponse } = await createDomainService(domain);
          if (domainResponse) await createWebsite(true);
          await getInvoiceService(domainResponse.invoice);

          await chargeInvoiceService(domainResponse.invoice);

          setLoading(false);

          setStage('success');
        } catch (e) {
          setErrors(e?.response?.data);
          setLoading(false);
        }
      } else {
        try {
          const { data: domainResponse } = await createDomainService(domain);
          if (domainResponse) await createWebsite(true);
          await getInvoiceService(domainResponse.invoice);

          setLoading(false);

          setStage('success');
        } catch (e) {
          setErrors(e?.response?.data);
          setLoading(false);
        }
      }
    } catch (e) {
      toastError(e);
      setLoading(false);
    }
  };

  return (
    <>
      <Form
        schema={tab === 1 && (!creditCard || changeCard) ? paymentSchema : null}
        onSubmit={handleSubmit}
        id="domainWebsiteForm"
      >
        <Row>
          <Col xs="12" md="5">
            <Wrapper isFocused>
              <h3>Serviços</h3>

              <Row>
                <Col>
                  <table>
                    <tbody>
                      <tr>
                        <td>
                          <p>Domain: </p>
                          <p>
                            <small>{domain.domain}</small>
                          </p>
                        </td>
                        <td>
                          <CurrencyFormat
                            style={{
                              color: theme.success,
                              fontWeight: 'bolder',
                            }}
                            value={domain.price?.amount}
                            displayType="text"
                            fixedDecimalScale
                            decimalScale={2}
                            decimalSeparator=","
                            thousandSeparator="."
                            prefix={
                              domain.price?.currency === 'BRL' ? 'R$ ' : '$'
                            }
                          />
                        </td>
                      </tr>

                      <tr>
                        <td />
                        <td>
                          <CurrencyFormat
                            style={{
                              color: theme.success,
                              fontWeight: 'bolder',
                            }}
                            value={domain.price?.amount}
                            displayType="text"
                            fixedDecimalScale
                            decimalScale={2}
                            decimalSeparator=","
                            thousandSeparator="."
                            prefix={
                              domain.price?.currency === 'BRL' ? 'R$ ' : '$'
                            }
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </Col>
              </Row>
            </Wrapper>
          </Col>
          <Col xs="12" md="7">
            <Wrapper isFocused>
              <h3>
                Informações de pagamento
                <p>
                  Só é possível realizar pagamentos por boleto caso não haja um
                  cartão de crédito cadastrado
                </p>
              </h3>

              <Tabs>
                <Tab active={tab === 1} onClick={() => setTab(1)}>
                  Cartão de crédito
                </Tab>
                <Tab
                  active={tab === 2}
                  disabled={creditCard}
                  onClick={() => !creditCard && setTab(2)}
                >
                  Boleto
                </Tab>
              </Tabs>
              <TabContent>
                {tab === 1 ? (
                  <>
                    {creditCard && !changeCard ? (
                      <>
                        <Field>
                          <Label>Cartão de crédito</Label>
                          <FormSelect
                            value={1}
                            height="40px"
                            name="creditCard"
                            options={[
                              {
                                id: '1',
                                title: `${creditCard.brand} ${creditCard.display_number}`,
                              },
                            ]}
                          />
                        </Field>
                      </>
                    ) : (
                      <>
                        <Row>
                          <Col
                            lg="7"
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                            }}
                          >
                            <Cards
                              cvc={card.verification_value}
                              expiry={card.expiration}
                              focused={focus}
                              name={card.name}
                              number={card.number}
                            />
                          </Col>
                          <Col lg="5">
                            <Row>
                              <Col>
                                <Field>
                                  <Label>Número do cartão</Label>
                                  <InputMask
                                    mask="9999 9999 9999 9999"
                                    maskChar={null}
                                    block="true"
                                    name="number"
                                    onChange={e => {
                                      setCard({
                                        ...card,
                                        number: e.target.value,
                                      });
                                    }}
                                    onFocus={e => {
                                      setFocus(e.target.name.split('.')[1]);
                                    }}
                                    error={!!cardErrors.number}
                                  >
                                    {inputProps => (
                                      <FormInput {...inputProps} />
                                    )}
                                  </InputMask>

                                  {cardErrors.number && (
                                    <Error>Número inválido</Error>
                                  )}
                                </Field>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Field>
                                  <Label>Nome</Label>
                                  <FormInput
                                    block="true"
                                    name="name"
                                    onChange={e => {
                                      setCard({
                                        ...card,
                                        name: e.target.value,
                                      });
                                    }}
                                    onFocus={e => {
                                      setFocus(e.target.name.split('.')[1]);
                                    }}
                                    error={!!cardErrors.last_name}
                                  />
                                  {cardErrors.last_name && (
                                    <Error>Nome inválido</Error>
                                  )}
                                </Field>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Field>
                                  <Label>Validade</Label>
                                  <InputMask
                                    mask="99/9999"
                                    maskChar={null}
                                    block="true"
                                    name="expiration"
                                    onChange={e => {
                                      setCard({
                                        ...card,
                                        expiration: e.target.value,
                                      });
                                    }}
                                    onFocus={e => {
                                      setFocus(e.target.name.split('.')[1]);
                                    }}
                                    error={!!cardErrors.expiration}
                                  >
                                    {inputProps => (
                                      <FormInput {...inputProps} />
                                    )}
                                  </InputMask>

                                  {cardErrors.expiration && (
                                    <Error>Data de expiração inválida</Error>
                                  )}
                                </Field>
                              </Col>
                              <Col>
                                <Field>
                                  <Label>cvc</Label>
                                  <FormInput
                                    maxLength="4"
                                    block="true"
                                    name="verification_value"
                                    onChange={e => {
                                      setCard({
                                        ...card,
                                        verification_value: e.target.value,
                                      });
                                    }}
                                    onFocus={e => {
                                      setFocus(e.target.name.split('.')[1]);
                                    }}
                                    error={!!cardErrors.verification_value}
                                  />
                                  {cardErrors.verification_value && (
                                    <Error>CVC inválido</Error>
                                  )}
                                </Field>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </>
                    )}
                    {creditCard && (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'space-between',
                        }}
                      >
                        <Button
                          form="b"
                          outline
                          style={{
                            width: '120px',
                            padding: '0 10px',
                            fontSize: '11px',
                            height: '100%',
                          }}
                          onClick={() => {
                            setChangeCard(!changeCard);
                          }}
                        >
                          Trocar cartão
                        </Button>
                        <Button
                          form="b"
                          error
                          style={{
                            width: '120px',
                            padding: '0px 10px',
                            fontSize: '11px',
                            height: '100%',
                          }}
                          disabled={deleteLoading}
                          onClick={() => {
                            deleteCreditCard();
                          }}
                          icon
                        >
                          {deleteLoading ? (
                            <FontAwesomeIcon icon={faSpinner} spin />
                          ) : (
                            'Excluir cartão'
                          )}
                        </Button>
                      </div>
                    )}
                  </>
                ) : (
                  <p
                    style={{
                      color: theme.interface5,
                      textAlign: 'center',
                      padding: '18px',
                      border: `1px solid ${theme.brand_primary_color}`,
                    }}
                  >
                    Um boleto será criado e enviado para seu email.
                  </p>
                )}
              </TabContent>
            </Wrapper>
            {errors &&
              Object.keys(errors).map((e, i) => (
                <Error
                  key={i}
                  style={{
                    fontSize: '14px',
                  }}
                >
                  {errors[e][0]}
                </Error>
              ))}
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default Payment;
