import React, { useContext } from 'react';
import { NotificationsContext } from 'context/notifications';

export function useNotifications() {
  const context = useContext(NotificationsContext);

  if (!context) {
    throw new Error(
      'useNotifications must be used within an NotificationsProvider',
    );
  }

  return context;
}
