import React from 'react';

import CurrencyFormat from 'react-currency-format';
import { Card, Price } from './styles';

const DomainAvaiable = ({ domain, setBuyDomain, buyDomain }) => {
  return (
    <Card
      onClick={() => domain.is_available && setBuyDomain(domain)}
      selected={buyDomain && buyDomain.domain === domain.domain}
    >
      <span className="domain">{domain.domain}</span>
      {domain.is_available ? (
        <Price>
          <CurrencyFormat
            value={domain.price.amount}
            displayType="text"
            fixedDecimalScale
            decimalScale={2}
            decimalSeparator=","
            thousandSeparator="."
            prefix={domain.price.currency === 'BRL' ? 'R$ ' : '$'}
          />
        </Price>
      ) : (
        <p>Indisponível</p>
      )}
    </Card>
  );
};

export default DomainAvaiable;
