/* eslint-disable prettier/prettier */
import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: ${props => props.theme.brand_primary_color};
  height: 100%;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  width: 100%;
  text-align: center;
  padding: 27px;

  img {
    width: 200px;
    margin-bottom: 40px;
  }

  form {
    border-radius: 5px;
    background: ${props => props.theme.interface1};
    display: flex;
    flex-direction: column;
    margin: 0px auto 0px auto;
    max-width: 434px;
    padding: 30px;

    gap: 18px;

    text-align: center;
    align-items: center;

    .sentCode {
      font-weight: normal;
      font-size: 16px;

      text-align: center;
      cursor: pointer;

      color: ${props => props.theme.interface5} !important;
    }
  }
`;

export const InputBox = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;

  input {
    width: 50px;
    height: 47px;

    text-align: center;

    background: ${props => props.theme.interface1};

    border: 1px solid ${props => props.theme.interface3};
    box-sizing: border-box;
    border-radius: 5px;

    color: ${props => props.theme.interface5};
    font-size: 14px;
  }
`;
