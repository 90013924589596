import { useAuth } from 'hooks/auth';
import React from 'react';
import history from 'services/history';

import { Card } from './styles';

const InvoiceWarning: React.FC = () => {
  const {
    user: { unpaid_invoices: invoices },
  } = useAuth();

  return (
    invoices.n_invoices > 0 && (
      <Card>
        <span>
          Você tem <b>{invoices.n_invoices} fatura(s) para pagar</b> com um
          total de <b>R${invoices.total_price_amount}</b>
        </span>
        <button
          type="button"
          onClick={() => {
            history.push('/invoices');
          }}
        >
          Ver faturas
        </button>
      </Card>
    )
  );
};

export default InvoiceWarning;
