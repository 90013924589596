import React, { useState } from 'react';
import { withTheme } from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faSyncAlt } from '@fortawesome/free-solid-svg-icons';

import { toast } from 'react-toastify';

import { Button } from '@cloudez/cloudez-design-system';

import { refreshDomainService } from 'services/domain';

import { Header, Icon, Text, Title, Subtitle, Footer } from '../styles';

const UpdateDomainModal = ({ domain, theme, setShow, loading, setLoading }) => {
  // const [loading, setLoading] = useState(false);

  const refreshDomain = async () => {
    try {
      setLoading(true);
      await refreshDomainService(domain.id);

      setLoading(false);
      setShow(false);
      toast.success('Domínio atualizado.');
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faSyncAlt} />
        </Icon>
        <Text>
          <Title>Confirmar ação</Title>
          <Subtitle>Por favor confirme sua ação</Subtitle>
        </Text>
      </Header>
      <Footer>
        <Button icon onClick={refreshDomain}>
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Confirmar'}
        </Button>
      </Footer>
    </>
  );
};

export default withTheme(UpdateDomainModal);
