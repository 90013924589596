import React, { createContext, useReducer } from 'react';
import { Website } from 'types/website';
import { PaginationType } from '@cloudez/cloudez-design-system';
import reducer from './reducer';
import { setLoading, getWebsites, updateWebsite, updateField } from './actions';

export interface WebsiteListingState extends PaginationType {
  websites: Website[];
  loading: boolean;
  errors: any;

  setLoading(value: boolean): void;
  getWebsites(
    page: number | string,
    search: string,
    filter: number | string,
    clouds: any[],
    order: any,
    controller: AbortController,
  ): Promise<void>;
  updateWebsite(field: string, value: any, id: number | string): Promise<void>;
  updateField(field: string, value: string, id: string): void;
}

// initial state
const initialState = {
  websites: null,
  count: null,
  previous: null,
  current: null,
  next: null,
  loading: true,
  errors: null,
};

// context
export const WebsiteListingContext = createContext<WebsiteListingState>(
  initialState as WebsiteListingState,
);

// provider
export const WebsiteListingProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(
    reducer,
    initialState as WebsiteListingState,
  );

  return (
    <WebsiteListingContext.Provider
      value={{
        // state
        websites: state.websites,
        count: state.count,
        previous: state.previous,
        current: state.current,
        next: state.next,
        loading: state.loading,
        errors: state.errors,

        // actions
        setLoading: value => setLoading(dispatch, value),
        getWebsites: async (page, search, filter, clouds, order, controller) =>
          await getWebsites(
            dispatch,
            page,
            search,
            filter,
            clouds,
            order,
            controller,
          ),
        updateWebsite: async (field, value, id) =>
          await updateWebsite(dispatch, field, value, id),
        updateField: (field, value, id) =>
          updateField(dispatch, field, value, id),
      }}
    >
      {children}
    </WebsiteListingContext.Provider>
  );
};
