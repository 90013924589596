import { transparentize } from 'polished';
import styled, { css } from 'styled-components';
import { Button } from '@cloudez/cloudez-design-system';

export const NoTicket = styled.div`
  width: 100%;
  min-height: 124px;
  display: flex;
  justify-content: center;
  align-items: center;

  p {
    color: ${props => props.theme.interface4};
    font-size: 17px;
  }
`;

export const File = styled.div`
  cursor: pointer;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  margin: 10px 10px;

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;

    img {
      border-radius: 5px;
      height: 100%;
      width: auto;
    }
  }

  svg {
    color: ${props => props.theme.brand_primary_color};
    font-size: 30px;
  }
`;

interface IHourProps {
  isAuthor: boolean;
  isStaff: boolean;
  staff: boolean;
  domo: boolean;
  isOld: boolean;
}

export const Hour = styled.span<IHourProps>`
  position: relative;
  top: 18px;
  font-size: 10px;
  color: ${props => props.theme.interface5};
  ${props =>
    props.isAuthor || (props.isStaff && (props.staff || props.domo))
      ? css`
          left: ${props.isOld ? 'calc(100% - 140px)' : 'calc(100% - 30px)'};
        `
      : css`
          left: 0;
        `};
`;

export const Container = styled.div`
  width: 100%;
  height: auto;

  border: 2px solid
    ${props =>
      props.theme.darkMode ? props.theme.interface2 : props.theme.interface3};
  border-radius: 5px;
  margin-top: 14px;
`;

export const FilesDropdown = styled.div`
  height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 200px;
  position: absolute;
  top: 40px;
  right: 10px;
  background: ${props => props.theme.interface1};
  padding: 10px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  ${File} {
    background: ${props => props.theme.interface1};
    width: 50%;
    height: 50%;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    a {
      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        font-size: 50px !important;
      }
    }
  }
`;

export const Header = styled.div`
  position: relative;
  border-bottom: 2px solid
    ${props =>
      props.theme.darkMode ? props.theme.interface2 : props.theme.interface3};

  padding: 14px 16px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  font-weight: bold;
  font-size: 15px;
  color: ${props => props.theme.interface5};

  svg {
    cursor: pointer;
  }
`;

export const Messages = styled.div`
  padding: 28px 15px;
  display: flex;
  flex-direction: column;
  min-height: 140px;
  max-height: 240px;
  height: auto;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #e8eaef;
  }

  &::-webkit-scrollbar-thumb {
    background: #cfd3de;
  }
`;

interface IMessageProps {
  isAuthor?: boolean;
  isStaff?: boolean;
  staff?: boolean;
  domo?: boolean;
  isOld?: boolean;
}

export const Message = styled.div<IMessageProps>`
  width: 100%;

  & + div {
    margin-top: 30px;
  }

  background: ${props =>
    props.isAuthor
      ? transparentize(0.8, props.theme.successNew)
      : props.domo
      ? props.theme.brand_primary_color
      : props.theme.interface2};

  align-self: ${props => props.isAuthor && 'flex-end'};

  ${props =>
    props.domo &&
    css`
      background: ${props => props.theme.brand_primary_color};
      color: ${props =>
        props.theme.darkMode ? '#F5F8FF' : props.theme.interface1} !important;
      align-self: ${props => props.isStaff && 'flex-end'};

      a {
        color: ${props => props.theme.brand_secondary_color} !important;
      }
    `};

  ${props =>
    props.isStaff &&
    props.staff &&
    !props.domo &&
    css`
      align-self: flex-end;
      background: ${props => transparentize(0.8, props.theme.successNew)};
    `};

  border-radius: ${props =>
    props.isAuthor ? '15px 15px 0px 15px' : '15px 15px 15px 0px'};

  div {
    height: 100%;
    width: 100%;

    color: ${props =>
      props.theme.darkMode ? '#F5F8FF' : props.theme.interface6};

    ${props =>
      props.domo &&
      css`
        color: ${props =>
          props.theme.darkMode ? '#F5F8FF' : props.theme.interface1} !important;

        a {
          color: ${props => props.theme.brand_secondary_color} !important;
        }
      `};

    font-size: 12px;
  }
`;

interface IMessageHeaderProps {
  domo: boolean;
}

export const MessageHeader = styled.div<IMessageHeaderProps>`
  width: 100%;
  padding: 9px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${props => props.theme.interface1};

  p {
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      margin-right: 8px;
      height: ${props => (props.domo ? '42px' : '30px')};
      border-radius: 50%;
    }
  }

  span {
    text-decoration: none;
    color: ${props => props.theme.interface5};
    cursor: pointer;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 10px;

    svg {
      align-self: center;
      margin: 0;
      margin-left: 4px;
    }
  }
`;

interface IMessageTextProps {
  domo: boolean;
}

export const MessageText = styled.div<IMessageTextProps>`
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 19px 15px;

  pre {
    ${props =>
      props.domo &&
      css`
        a {
          color: ${props => props.theme.brand_secondary_color} !important;
        }
      `};

    a {
      color: ${props => props.theme.label};
      text-decoration: none;
    }

    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;

    width: 100%;
    font-size: 14px;
    line-height: 1.8em;
    overflow: visible;
    background: none;
    padding: 0;
    color: inherit;
  }
`;

interface ITicketFooterProps {
  file: boolean;
}

export const TicketFooter = styled.div<ITicketFooterProps>`
  width: 100%;
  height: 100%;
  padding: 20px 14px;
  border-top: 2px solid
    ${props =>
      props.theme.darkMode ? props.theme.interface2 : props.theme.interface3};

  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  textarea {
    width: 80%;
    max-width: 80%;
    min-height: 50px;
  }

  svg {
    cursor: pointer;
    color: ${props => props.theme.interface4};
    font-size: 16px;

    &.file {
      color: ${props =>
        props.file ? props.theme.brand_primary_color : props.theme.interface4};
    }
  }

  ${Button} {
    width: 50px;
    height: 50px;
  }
`;

export const Icons = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 4%;

  @media (max-width: 768px) {
    width: 10%;
    margin-bottom: 10px;
  }
`;
