import React, { useState, Dispatch, SetStateAction } from 'react';

import ApplicationIcon from 'components/ApplicationIcon';

import { CloudAddon } from 'types/cloud';
import { Card } from './styles';

import EditAddonModal from '../EditAddonModal';

interface IAddonCardProps {
  addon: CloudAddon;
  addons: CloudAddon[];
  setAddons: Dispatch<SetStateAction<CloudAddon[]>>;
}

const AddonCard: React.FC<IAddonCardProps> = ({ addon, addons, setAddons }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      {show && (
        <EditAddonModal
          addon={addon}
          show={show}
          setShow={setShow}
          addons={addons}
          setAddons={setAddons}
        />
      )}

      <Card onClick={() => setShow(true)}>
        <ApplicationIcon width="40px" height="40px" icon={addon.type.slug} />
        <p>{addon.type.name}</p>
      </Card>
    </>
  );
};

export default AddonCard;
