import React from 'react';
import { AnimatedList } from 'react-animated-list';
import { Col, Row } from 'react-bootstrap';

import Placeholder from 'components/Placeholder';

import {
  Card,
  CardContent,
  ListingHeader,
  Listing,
  ListingItem,
  ApplicationValue,
} from 'components/Listing/styles';
import { Acesso, AcessosList } from 'components/Acessos';

const TicketPlaceholder = () => {
  const placeholders = [];

  for (let i = 1; i <= 4; i++) {
    placeholders.push(i);
  }

  return (
    <>
      <ListingHeader>
        <Row>
          <Col xs="10" sm="10" md="4" lg="5">
            <p>Ticket</p>
          </Col>
          <Col className="d-none d-md-flex">ÁREA</Col>
          <Col className="d-none d-md-flex">
            <p>CRIADO EM </p>
          </Col>
          <Col className="d-none d-md-flex">
            <p>ATUALIZADO EM</p>
          </Col>
          <Col className="d-none d-md-flex" />
        </Row>
      </ListingHeader>

      <Listing>
        <AnimatedList initialAnimationDuration={800} animation="grow">
          {placeholders.map((p, i) => (
            <ListingItem key={p} fade={i > 0 && i}>
              <Card>
                <CardContent>
                  <Col xs="10" sm="10" md="4" lg="5">
                    <ApplicationValue>
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Placeholder width={25} height={25} />
                        <span style={{ marginLeft: 12 }}>
                          <Placeholder width={20} height={12} />
                        </span>
                        <span style={{ marginLeft: 12 }}>
                          <Placeholder width={180} height={12} />
                        </span>
                      </div>
                    </ApplicationValue>
                  </Col>

                  <Col className="d-none d-md-flex">
                    <ApplicationValue>
                      <Placeholder width={50} height={8} />
                    </ApplicationValue>
                  </Col>

                  <Col className="d-none d-md-flex">
                    <ApplicationValue>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Placeholder width={40} height={8} />
                        <Placeholder width={50} height={6} />
                      </div>
                    </ApplicationValue>
                  </Col>

                  <Col className="d-none d-md-flex">
                    <ApplicationValue>
                      <div
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                        }}
                      >
                        <Placeholder width={40} height={8} />
                        <Placeholder width={50} height={6} />
                      </div>
                    </ApplicationValue>
                  </Col>

                  <Col xl="2" md="2" sm="4" className="d-none d-md-flex">
                    <AcessosList>
                      <Acesso active hover>
                        <Placeholder width={16} height={18} />
                      </Acesso>

                      <Acesso active hover favorite>
                        <Placeholder width={16} height={18} />
                      </Acesso>
                    </AcessosList>
                  </Col>
                </CardContent>
              </Card>
            </ListingItem>
          ))}
        </AnimatedList>
      </Listing>
    </>
  );
};

export default TicketPlaceholder;
