import React from 'react';
import { AnimatedList } from 'react-animated-list';

import { Col, Row } from 'react-bootstrap';

import Placeholder from 'components/Placeholder';

import {
  Card,
  CardContent,
  Listing,
  ListingItem,
  ListingHeader,
} from 'components/Listing/styles';

const WebsitePlaceholder = () => {
  const placeholders = [];

  for (let i = 1; i <= 3; i++) {
    placeholders.push(i);
  }

  return (
    <>
      <ListingHeader>
        <Row>
          <Col style={{ maxWidth: '3.333%' }} xs="10" md="6" xl="1">
            <p>APP</p>
          </Col>
          <Col xs="3">
            <p>DOMÍNIO</p>
          </Col>
        </Row>
      </ListingHeader>
      <Listing>
        <AnimatedList initialAnimationDuration={800} animation="grow">
          {placeholders.map((p, i) => (
            <ListingItem key={p} fade={i > 0 && i}>
              <Card style={{ border: '1px solid #E8EAEF', height: '70px' }}>
                <CardContent>
                  <Col xs="10" md="6" xl="4">
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Placeholder circle width={30} height={30} />
                      <span style={{ marginLeft: 12 }}>
                        <Placeholder width={180} height={12} />
                      </span>
                    </div>
                  </Col>
                </CardContent>
              </Card>
            </ListingItem>
          ))}
        </AnimatedList>
      </Listing>
    </>
  );
};

export default WebsitePlaceholder;
