import React, { useRef, useState, useEffect } from 'react';

import { Label, Field } from '@cloudez/cloudez-design-system';
import { SquareInput, Inputs } from './styles';

const VerificationCodeInput: React.FC<{
  onCodeChange: any;
  focus?: number;
  noSpace?: boolean;
}> = ({ onCodeChange, focus = 2, noSpace }) => {
  const [code, setCode] = useState({
    cod1: '',
    cod2: '',
    cod3: '',
    cod4: '',
    cod5: '',
    cod6: '',
  } as any);
  const [pasted, setPasted] = useState(false);

  const code1Ref = useRef(null);
  const code2Ref = useRef(null);
  const code3Ref = useRef(null);
  const code4Ref = useRef(null);
  const code5Ref = useRef(null);
  const code6Ref = useRef(null);

  const inputsContainerRef = useRef(null);

  const handlePaste = e => {
    e.preventDefault();
    const codeNumbers = e.clipboardData.getData('Text').split('');

    const newCode = {};

    for (let i = 0; i < 6; i++) {
      const num = codeNumbers[i] || '';
      newCode[`cod${i + 1}`] = num;
    }

    setPasted(true);
    setCode(newCode);
  };

  const handleKeyDown = (e, previous) => {
    if ((e.keyCode === 8 || e.keyCode === 46) && code[e.target.name] === '') {
      e.preventDefault();

      if (!previous) return;
      previous.current.focus();
    }
  };

  useEffect(() => {
    if (!inputsContainerRef.current && !pasted) return;
    for (const child of inputsContainerRef.current.children) {
      if (child.tagName !== 'INPUT') continue;

      if (child.value.length === 0) {
        child.focus();
        break;
      } else if (child === code6Ref.current) {
        child.focus();
        break;
      }
    }
    setPasted(false);
  }, [pasted, inputsContainerRef]);

  useEffect(() => {
    onCodeChange(code);
  }, [code, onCodeChange]);

  return (
    <Field style={{ marginBottom: noSpace ? '0px' : '20px' }}>
      {!noSpace && (
        <Label
          dark
          style={{
            fontSize: '14px',
          }}
        >
          Digite o código
        </Label>
      )}

      <Inputs onPaste={handlePaste} ref={inputsContainerRef}>
        <SquareInput
          type="number"
          ref={code1Ref}
          value={code.cod1}
          onChange={e => {
            setCode({ ...code, [e.target.name]: e.target.value });
            if (e.target.value.length >= 1) {
              code2Ref.current.focus();
            }
          }}
          name="cod1"
          disabled={focus !== 2 ? true : undefined}
          background={focus !== 2 ? true : undefined}
        />
        <SquareInput
          type="number"
          ref={code2Ref}
          value={code.cod2}
          onChange={e => {
            setCode({ ...code, [e.target.name]: e.target.value });
            if (e.target.value.length >= 1) {
              code3Ref.current.focus();
            } else {
              code1Ref.current.focus();
            }
          }}
          name="cod2"
          disabled={focus !== 2 ? true : undefined}
          background={focus !== 2 ? true : undefined}
          onKeyDown={e => handleKeyDown(e, code1Ref)}
        />
        <SquareInput
          type="number"
          ref={code3Ref}
          value={code.cod3}
          onChange={e => {
            setCode({ ...code, [e.target.name]: e.target.value });
            if (e.target.value.length >= 1) {
              code4Ref.current.focus();
            } else {
              code2Ref.current.focus();
            }
          }}
          name="cod3"
          disabled={focus !== 2 ? true : undefined}
          background={focus !== 2 ? true : undefined}
          onKeyDown={e => handleKeyDown(e, code2Ref)}
        />
        {!noSpace && (
          <>
            <div />
            <div />
          </>
        )}

        <SquareInput
          type="number"
          ref={code4Ref}
          value={code.cod4}
          onChange={e => {
            setCode({ ...code, [e.target.name]: e.target.value });
            if (e.target.value.length >= 1) {
              code5Ref.current.focus();
            } else {
              code3Ref.current.focus();
            }
          }}
          name="cod4"
          disabled={focus !== 2 ? true : undefined}
          background={focus !== 2 ? true : undefined}
          onKeyDown={e => handleKeyDown(e, code3Ref)}
        />
        <SquareInput
          type="number"
          ref={code5Ref}
          value={code.cod5}
          onChange={e => {
            setCode({ ...code, [e.target.name]: e.target.value });
            if (e.target.value.length >= 1) {
              code6Ref.current.focus();
            } else {
              code4Ref.current.focus();
            }
          }}
          name="cod5"
          disabled={focus !== 2 ? true : undefined}
          background={focus !== 2 ? true : undefined}
          onKeyDown={e => handleKeyDown(e, code4Ref)}
        />
        <SquareInput
          type="number"
          ref={code6Ref}
          value={code.cod6}
          onChange={e => {
            setCode({ ...code, [e.target.name]: e.target.value });
            if (e.target.value.length < 1) {
              code5Ref.current.focus();
            }
          }}
          name="cod6"
          disabled={focus !== 2 ? true : undefined}
          background={focus !== 2 ? true : undefined}
          onKeyDown={e => handleKeyDown(e, code5Ref)}
        />
      </Inputs>
    </Field>
  );
};

export default VerificationCodeInput;
