import React from 'react';
import { Col } from 'react-bootstrap';

import Placeholder from 'components/Placeholder';

import { Card } from '../styles';

const WebsitePlaceholder: React.FC = () => {
  const placeholders = [];

  for (let i = 1; i <= 6; i++) {
    placeholders.push(i);
  }

  return (
    <>
      {placeholders.map((p, i) => (
        <Col
          key={i}
          sm="6"
          xs="12"
          style={{
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Card>
            <Placeholder circle width={42} height={42} />
            <div>
              <span>
                <Placeholder width={110} height={14} />
              </span>
            </div>
            <div>
              <p>
                <Placeholder width={100} height={10} />
              </p>
            </div>
          </Card>
        </Col>
      ))}
    </>
  );
};

export default WebsitePlaceholder;
