import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';

import {
  Button,
  FormInput,
  Label,
  Field,
} from '@cloudez/cloudez-design-system';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import brasil from 'assets/img/brazil-circle.png';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import clearMask from 'utils/clearMask';
import { createUserPhoneService, updateUserPhoneService } from 'services/user';
import VerificationCodeInput from 'components/VerificationCodeInput';
import { useAuth } from 'hooks/auth';
import { useHistory } from 'react-router-dom';
import { useLayout } from 'hooks/layout';
import Countdown, { zeroPad } from 'react-countdown';
import Progress from 'components/Progress';
import { Content, Wrapper, Timer } from './styles';
import { Header } from '../SignUp/styles';
import flagBR from 'assets/img/svg/bra.svg';

const phoneSchema = Yup.object().shape({
  phone: Yup.string('Insira um número válido').required('Número obrigatório'),
});

const ValidatePhone = () => {
  const { getUser } = useAuth();

  const [canValidate, setCanValidate] = useState(false);
  const [number, setNumber] = useState({} as any);

  const [resendCode, setResendCode] = useState(false);

  const history = useHistory();
  const { has_free_trial } = useLayout();

  const [code, setCode] = useState({
    cod1: '',
    cod2: '',
    cod3: '',
    cod4: '',
    cod5: '',
    cod6: '',
  });
  const containerRef = useRef(null);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.onkeyup = e => {
        const target = e.srcElement || e.target;
        const maxLength = 1;
        const { length } = target.value;
        if (length >= maxLength) {
          let next = target;
          while ((next = next.nextElementSibling)) {
            if (next == null) break;
            if (next.tagName.toLowerCase() === 'input') {
              next.focus();
              break;
            }
          }
        } else if (length === 0) {
          let previous = target;
          while ((previous = previous.previousElementSibling)) {
            if (previous == null) break;
            if (previous.tagName.toLowerCase() === 'input') {
              previous.focus();
              break;
            }
          }
        }
      };
    }
  }, [containerRef]);

  const timerRenderer = ({ completed, minutes, seconds }) => {
    if (completed) {
      setResendCode(true);
      setCanValidate(false);
    }

    return (
      <p className="time-left">
        {minutes}:{zeroPad(seconds)}
      </p>
    );
  };

  const handlePhoneSubmit = async data => {
    setLoading(true);
    const number = clearMask(data.phone);

    try {
      const { data } = await createUserPhoneService({ number: `+55${number}` });
      setNumber(data);
      setCanValidate(true);
      if (resendCode) {
        setResendCode(false);
      }

      const { fbq } = window as any;
      const { gtag } = window as any;

      if (fbq) {
        fbq('track', 'CompleteRegistration');
      }

      if (gtag) {
        gtag('event', 'track', { event_category: 'CompleteRegistration' });
      }

      setLoading(false);
    } catch (e) {
      toast.error(e?.response?.data[Object.keys(e?.response?.data)[0]][0]);
      setLoading(false);
    }
  };

  const handleCodeChange = newCode => {
    setCode(newCode);
  };

  const concatCode = useMemo(
    () =>
      `${code.cod1}${code.cod2}${code.cod3}${code.cod4}${code.cod5}${code.cod6}`,
    [code],
  );

  const seconds = useMemo(() => {
    if (canValidate) {
      return Date.now() + 240000;
    }

    return 0;
  }, [canValidate]);

  const validateNumber = async () => {
    setLoading(true);

    try {
      const { data } = await updateUserPhoneService(number.id, {
        validation: concatCode,
        number: number.number,
      });

      if (data.is_verified) {
        await getUser();
        setLoading(false);
        if (has_free_trial) {
          history.push('/onboard');
        }
      }
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data[Object.keys(e?.response?.data)[0]][0]);
    }
  };

  return (
    <>
      <Header>
        <Progress status={2} n={3} />
      </Header>
      <Wrapper>
        {/* <div
          style={{
            margin: '0 auto',
          }}
        >
          <Progress status={2} n={3} />
        </div> */}
        <Content>
          <div>
            <h1>Validação por SMS</h1>
            <h4>
              Para a segurança do seu Cloud, valide sua conta através do código
              de validação que enviaremos para você via SMS.
            </h4>
          </div>
          <Form schema={phoneSchema} onSubmit={handlePhoneSubmit}>
            <Field
              style={{
                position: 'relative',
              }}
            >
              <Label
                dark
                style={{
                  fontSize: '14px',
                }}
              >
                Digite seu número de celular
              </Label>
              <img
                alt="brasil"
                src={flagBR}
                style={{
                  width: '22px',
                  height: '22px',
                  position: 'absolute',
                  left: '10px',
                  top: '50%',
                }}
              />
              <InputMask
                // eslint-disable-next-line
                mask="(99) 99999-9999"
                maskChar={null}
                block
                height="50px"
                name="phone"
                placeholder="(XX) XXXXX-XXXX"
                disabled={canValidate}
              >
                {inputProps => (
                  <FormInput
                    {...inputProps}
                    disabled={canValidate}
                    style={{
                      paddingLeft: '40px',
                    }}
                  />
                )}
              </InputMask>
            </Field>
            {canValidate && (
              <>
                <VerificationCodeInput onCodeChange={handleCodeChange} />
                <Timer>
                  <p className="label">O código expira em </p>
                  <Countdown date={seconds} renderer={timerRenderer} />
                </Timer>
              </>
            )}

            {canValidate ? (
              <Button
                icon
                width="100%"
                height="50px"
                disabled={loading || concatCode.length < 6}
                form="#!"
                style={{
                  fontSize: '16px',
                  textTransform: 'none',
                }}
                onClick={validateNumber}
              >
                {loading ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  'Validar código'
                )}
              </Button>
            ) : (
              <Button
                icon
                width="100%"
                height="50px"
                disabled={loading}
                type="submit"
                style={{
                  fontSize: '16px',
                  textTransform: 'none',
                }}
              >
                {loading ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : resendCode ? (
                  'Reenviar código'
                ) : (
                  'Cadastrar número'
                )}
              </Button>
            )}
          </Form>
        </Content>
      </Wrapper>
    </>
  );
};

export default ValidatePhone;
