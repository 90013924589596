import { Button } from '@cloudez/cloudez-design-system';
import { useAuth } from 'hooks/auth';
import React, { useState } from 'react';
import {
  createTicketMessageService,
  createTicketService,
} from 'services/ticket';

import { createInvoiceService } from 'services/invoice';

import moment from 'moment';
import history from 'services/history';
import toastError from 'utils/toastError';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Container } from './styles';

export const MailGateway = ({ cloudId }) => {
  const { user } = useAuth();

  const [loading, setLoading] = useState(false);

  const openTicket = async () => {
    const text =
      'Olá, gostaria de solicitar uma expansão de limite de disparos no meu mail gateway ';

    const payload = {
      summary: 'Contratação de expansão de mail gateway',
      target_type: 'cloud',
      target_id: cloudId,
      owner_email: user.email,
      team: 1,
    };

    try {
      setLoading(true);
      const { data } = await createTicketService(payload);

      const messagePayload = new FormData();
      messagePayload.append('ticket', data.id.toString());
      messagePayload.append('text', text);
      messagePayload.append('author', user.id.toString());

      await createTicketMessageService(messagePayload);

      setLoading(false);

      history.push(`/suporte/${data.id}`);
    } catch (e) {
      toastError(e);
      setLoading(false);
    }
  };

  const createInvoiceSMTP = async () => {
    try {
      setLoading(true);
      const { data } = await createInvoiceService({
        items: [
          {
            price: {
              amount: 80,
              currency: 'BRL',
            },
            description: 'Cloudez SMTP',
          },
        ],
        expired_at: moment().add(1, 'days').format('YYYY-MM-DD'),
        user_email: user.email,
        user: user.id,
      });
      setLoading(false);
      history.push(`/invoices/${data.id}/`);
    } catch (e) {
      setLoading(false);
      toastError(e);
    }
  };

  return (
    <Container>
      <p>MAIL GATEWAY</p>
      <div className="tags">
        <span className="tag">
          <b style={{ fontSize: '16px' }}>100 mil</b>&nbsp;disparos/mês por
          cloud
        </span>
        <span className="tag">
          <b style={{ fontSize: '16px' }}>300</b>&nbsp;disparos/hora por cloud
        </span>
      </div>

      <span style={{ fontSize: '16px' }}>Cota intransferível entre clouds</span>
      <b>R$ 80,00 para cada 100 mil além da cota</b>
      <Button
        onClick={user.is_company_owner ? createInvoiceSMTP : openTicket}
        outline
        icon
        disabled={loading}
        height="28px"
      >
        {loading ? (
          <FontAwesomeIcon spin icon={faSpinner} />
        ) : (
          ' Aumentar meu limite'
        )}
      </Button>
    </Container>
  );
};
