import React, { useState, useContext, useEffect } from 'react';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import Cards from 'react-credit-cards';

import { Row, Col } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';

import {
  Field,
  FormInput,
  Label,
  Form,
  Error,
  FormSelect,
  Input,
  Checkbox,
  Button,
} from '@cloudez/cloudez-design-system';
import { Scope } from '@rocketseat/unform';
import { Tabs, Tab, TabContent } from 'components/Tabs';
import clearMask from 'utils/clearMask';
import { ThemeContext } from 'styled-components';

import {
  getCepService,
  getCreditcardService,
  deleteCreditcardService,
} from 'services/common';
import { getUserBillingService, updateUserBillingService } from 'services/user';
import { getInvoiceService, chargeInvoiceService } from 'services/invoice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { toast } from 'react-toastify';
import { useAuth } from 'hooks/auth';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { createDomainService } from 'services/domain';
import { updateWebsiteService } from 'services/website';
import { createCreditCardToken } from 'utils/iugu';
import { Wrapper, Total } from './styles';

const paymentSchema = Yup.object().shape({
  number: Yup.string().required('Este campo é obrigatório'),
  name: Yup.string().required('Este campo é obrigatório'),
  expiration: Yup.string().required('Este campo é obrigatório'),
  verification_value: Yup.string().required('Este campo é obrigatório'),
});

const billingSchema = Yup.object().shape({
  name: Yup.string().required('Este campo é obrigatório'),
  email: Yup.string()
    .email('Por favor, insira um email válido.')
    .required('Este campo é obrigatório'),
  document_id: Yup.string().required('Este campo é obrigatório'),
  address_number: Yup.string().required('Este campo é obrigatório'),
  country: Yup.string().required('Este campo é obrigatório'),
  address1: Yup.string().required('Este campo é obrigatório'),
  address2: Yup.string().required('Este campo é obrigatório'),
  state: Yup.string().required('Este campo é obrigatório'),
  city: Yup.string().required('Este campo é obrigatório'),
  inscricao_estadual: Yup.string().when('document_id', {
    is: val => val.length > 14,
    then: Yup.string().required('Este campo é obrigatório'),
    otherwise: Yup.string(),
  }),
});

const schema = Yup.object().shape({
  billing: billingSchema,
  payment: paymentSchema,
});

const schemaBilling = Yup.object().shape({
  billing: billingSchema,
});

const Payment = ({ buyDomain, nodeapp, setPayDomain }) => {
  const theme = useContext(ThemeContext);
  const [loading, setLoading] = useState(false);
  const { getUser } = useAuth();

  const [errors, setErrors] = useState(null);
  const [creditCard, setCreditCard] = useState(null);
  const [billing, setBilling] = useState(null);
  const [billingErrors, setBillingErrors] = useState([]);
  const [cardErrors, setCardErrors] = useState({});
  const [tab, setTab] = useState(1);
  const [cep, setCEP] = useState('');
  const [card, setCard] = useState({
    number: '',
    name: '',
    verification_value: '',
    expiration: '',
  });
  const [focus, setFocus] = useState('number');
  const [address, setAddress] = useState({
    state: '',
    address1: '',
    address2: '',
    city: '',
    zipcode: '',
  });
  const [changeCard, setChangeCard] = useState(false);
  const [notHasIe, setNotHasIe] = useState(false);

  const [document, setDocument] = useState(0);
  const [deleteLoading, setDeleteLoading] = useState(false);

  const { user } = useAuth();

  const getCEP = async _cep => {
    setBillingErrors(_data => {
      delete _data.zipcode;

      return _data;
    });
    try {
      setLoading(true);
      const { data } = await getCepService(_cep);

      if (data.erro) {
        setBillingErrors(_data => ({
          ..._data,
          zipcode: ['CEP inválido'],
        }));
        return;
      }

      setAddress({
        state: data.uf,
        address1: data.logradouro,
        address2: data.bairro,
        city: data.localidade,
        zipcode: _cep,
      });
      setLoading(false);
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    async function getBilling() {
      try {
        const { data } = await getUserBillingService(user.id);
        setBilling(data);
        if (data.document_id.length >= 14) setDocument(1);
        if (data.inscricao_estadual === 'ISENTO') {
          setNotHasIe(true);
        }
        setCEP(data.zipcode);
        setAddress({
          state: data.state,
          address1: data.address1,
          address2: data.address2,
          city: data.city,
          zipcode: data.zipcode,
        });
      } catch (e) {
        console.log(e);
      }
    }

    async function getCreditCard() {
      try {
        const { data } = await getCreditcardService();
        setCreditCard(data[0]);
      } catch (e) {
        console.log(e);
      }
    }

    if (!billing) getBilling();
    if (!creditCard) getCreditCard();
    // eslint-disable-next-line
  }, []);

  const deleteCreditCard = async () => {
    setDeleteLoading(true);
    try {
      await deleteCreditcardService(creditCard.id);
      setCreditCard(null);
      setDeleteLoading(false);
    } catch (error) {
      setDeleteLoading(false);
      toast.error(
        error?.response?.data[Object.keys(error?.response?.data)[0]][0],
      );
    }
  };

  const handleSubmit = async d => {
    setLoading(true);

    const billingPayload = {
      ...d.billing,
      document_id: clearMask(d.billing.document_id),
      ...address,
      user: user.id,
    };

    try {
      setBillingErrors([]);
      await updateUserBillingService(user.id, billingPayload);

      const { data } = await createDomainService(buyDomain);

      await updateWebsiteService(nodeapp.id, {
        ...nodeapp,
        values: [
          ...nodeapp.values,
          {
            slug: 'domain',
            value: buyDomain.domain,
          },
        ],
      });

      const _buyDomain = {
        ...buyDomain,
        ...data,
      };

      if (tab === 1) {
        if (!creditCard || changeCard) {
          try {
            await createCreditCardToken(d.payment);
          } catch (e) {
            setLoading(false);

            if (e.errors) {
              setCardErrors(e.errors);
              return;
            }
            setErrors(e);
            return;
          }
        }

        try {
          await getInvoiceService(_buyDomain.invoice);

          await chargeInvoiceService(_buyDomain.invoice);

          setPayDomain(false);

          await getUser();

          setLoading(false);
        } catch (e) {
          setErrors(e?.response?.data);
          setLoading(false);
        }
      } else {
        try {
          await getInvoiceService(_buyDomain.invoice);
          setPayDomain(false);

          await getUser();

          setLoading(false);
        } catch (e) {
          setErrors(e?.response?.data);
          setLoading(false);
        }
      }
    } catch (e) {
      setLoading(false);
      setBillingErrors(e?.response?.data);
    }
  };

  return (
    billing && (
      <>
        <Form
          schema={
            tab === 1 && (!creditCard || changeCard) ? schema : schemaBilling
          }
          onSubmit={handleSubmit}
          id="infoFormPayment"
          style={{
            margin: '0 auto',
            maxWidth: '1100px',
          }}
        >
          <Row>
            <Col xs="12" lg="6">
              <Wrapper isFocused>
                <h3>Dados Cadastrais</h3>

                <Row
                  style={{
                    margin: '0',
                  }}
                >
                  <Scope path="billing">
                    <Col>
                      <Field>
                        <Label required>Nome *</Label>
                        <FormInput
                          block="true"
                          name="name"
                          error={!!billingErrors?.name}
                          value={billing?.name}
                          onChange={e =>
                            setBilling({ ...billing, name: e.target.value })
                          }
                        />
                        {billingErrors?.name && (
                          <Error>{billingErrors?.name[0]}</Error>
                        )}
                      </Field>
                      <Row>
                        <Col>
                          <Field>
                            <Label required>Email financeiro *</Label>
                            <FormInput
                              block="true"
                              placeholder="cliente@company.com"
                              type="email"
                              name="email"
                              value={billing.email}
                              onChange={e =>
                                setBilling({
                                  ...billing,
                                  email: e.target.value,
                                })
                              }
                              error={!!billingErrors.email}
                            />
                            {billingErrors.email && (
                              <Error>{billingErrors.email[0]}</Error>
                            )}
                          </Field>
                        </Col>
                        <Col>
                          <Field>
                            <Label>
                              <span
                                style={{
                                  display: 'inline',
                                  cursor: 'pointer',
                                  color:
                                    document === 0
                                      ? theme.brand_primary_color
                                      : theme.interface4,
                                }}
                                onClick={() => setDocument(0)}
                              >
                                CPF
                              </span>
                              /
                              <span
                                style={{
                                  display: 'inline',
                                  cursor: 'pointer',
                                  color:
                                    document === 1
                                      ? theme.brand_primary_color
                                      : theme.interface4,
                                }}
                                onClick={() => setDocument(1)}
                              >
                                CNPJ
                              </span>
                            </Label>

                            <InputMask
                              mask={
                                document === 0
                                  ? '999.999.999-999'
                                  : '99.999.999/9999-99'
                              }
                              maskChar={null}
                              block="true"
                              name="document_id"
                              error={!!billingErrors.document_id}
                              value={billing.document_id}
                              onChange={e => {
                                if (e.target.value.length >= 15) {
                                  setDocument(1);
                                } else {
                                  setDocument(0);
                                }

                                setBilling({
                                  ...billing,
                                  document_id: clearMask(e.target.value),
                                });
                              }}
                            >
                              {inputProps => <FormInput {...inputProps} />}
                            </InputMask>
                            {billingErrors.document_id && (
                              <Error>{billingErrors.document_id[0]}</Error>
                            )}
                          </Field>
                        </Col>
                      </Row>
                      <Row>
                        {document === 1 && (
                          <Col xl="5">
                            <Field>
                              <Label required>Inscrição estadual</Label>
                              <FormInput
                                block
                                value={billing?.inscricao_estadual}
                                name="inscricao_estadual"
                                onChange={e => {
                                  setBilling({
                                    ...billing,
                                    inscricao_estadual: e.target.value,
                                  });
                                }}
                                disabled={notHasIe}
                                error={billingErrors?.inscricao_estadual}
                              />
                              <Field
                                style={{
                                  marginTop: '8px',
                                  marginBottom: '0',
                                }}
                              >
                                <Label
                                  style={{
                                    fontSize: '11px',
                                  }}
                                >
                                  Não possuo inscrição estadual *
                                </Label>
                                <Checkbox
                                  checked={notHasIe}
                                  value={notHasIe}
                                  onChange={e => {
                                    setNotHasIe(e.target.checked);

                                    if (e.target.checked === true) {
                                      setBilling({
                                        ...billing,
                                        inscricao_estadual: 'ISENTO',
                                      });
                                    } else {
                                      setBilling({
                                        ...billing,
                                        inscricao_estadual: '',
                                      });
                                    }
                                  }}
                                />
                              </Field>
                              {billingErrors?.inscricao_estadual && (
                                <Error>
                                  {billingErrors?.inscricao_estadual[0]}
                                </Error>
                              )}
                            </Field>
                          </Col>
                        )}
                        <Col xl={document === 1 ? '4' : '9'}>
                          <Field>
                            <Label required>CEP *</Label>
                            <InputMask
                              mask="99999-999"
                              value={cep}
                              maskChar={null}
                              autoFocus
                              onChange={e => {
                                const cep = e.target.value;
                                setCEP(cep);
                                setAddress({
                                  ...address,
                                  zipcode: e.target.value,
                                });
                                if (cep.length >= 9) {
                                  getCEP(cep.replace(/\.|-|\//g, ''));
                                }
                              }}
                              block="true"
                              error={!!billingErrors.zipcode}
                            >
                              {inputProps => {
                                return <Input {...inputProps} />;
                              }}
                            </InputMask>
                            {billingErrors.zipcode && (
                              <Error>{billingErrors?.zipcode[0]}</Error>
                            )}
                          </Field>
                        </Col>
                        <Col xl="3">
                          <Field>
                            <Label required>Número *</Label>
                            <FormInput
                              block="true"
                              name="address_number"
                              error={!!billingErrors.address_number}
                              value={billing.address_number}
                              onChange={e =>
                                setBilling({
                                  ...billing,
                                  address_number: e.target.value,
                                })
                              }
                            />
                            {billingErrors.address_number && (
                              <Error>{billingErrors.address_number[0]}</Error>
                            )}
                          </Field>
                        </Col>
                      </Row>

                      <Row>
                        <Col xl="6">
                          <Field>
                            <Label required>País *</Label>
                            <FormInput
                              block="true"
                              name="country"
                              value={billing.country}
                              onChange={e =>
                                setBilling({
                                  ...billing,
                                  country: e.target.value,
                                })
                              }
                              error={!!billingErrors.country}
                            />
                            {billingErrors.country && (
                              <Error>{billingErrors.country[0]}</Error>
                            )}
                          </Field>
                        </Col>
                        <Col>
                          <Field>
                            <Label>Bairro</Label>
                            <FormInput
                              disabled={address?.zipcode ? undefined : true}
                              background={address?.zipcode ? undefined : true}
                              onChange={e =>
                                setAddress({
                                  ...address,
                                  address2: e.target.value,
                                })
                              }
                              block="true"
                              value={address.address2}
                              name="address2"
                            />
                          </Field>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Field>
                            <Label>Endereço</Label>
                            <FormInput
                              disabled={address?.zipcode ? undefined : true}
                              background={address?.zipcode ? undefined : true}
                              onChange={e =>
                                setAddress({
                                  ...address,
                                  address1: e.target.value,
                                })
                              }
                              block="true"
                              value={address.address1}
                              name="address1"
                            />
                          </Field>
                        </Col>
                        <Col xl="2">
                          <Field>
                            <Label>Estado</Label>
                            <FormSelect
                              options={[
                                { title: 'Acre', id: 'AC' },
                                { title: 'Alagoas', id: 'AL' },
                                { title: 'Amapá', id: 'AP' },
                                { title: 'Amazonas', id: 'AM' },
                                { title: 'Bahia', id: 'BA' },
                                { title: 'Ceará', id: 'CE' },
                                { title: 'Distrito Federal', id: 'DF' },
                                { title: 'Espírito Santo', id: 'ES' },
                                { title: 'Goias', id: 'GO' },
                                { title: 'Maranhão', id: 'MA' },
                                { title: 'Mato Grosso', id: 'MT' },
                                { title: 'Mato Grosso do Sul', id: 'MS' },
                                { title: 'Minas Gerais', id: 'MG' },
                                { title: 'Pará', id: 'PA' },
                                { title: 'Paraíba', id: 'PB' },
                                { title: 'Paraná', id: 'PR' },
                                { title: 'Pernambuco', id: 'PE' },
                                { title: 'Piauí', id: 'PI' },
                                { title: 'Rio de Janeiro', id: 'RJ' },
                                { title: 'Rio Grande do Norte', id: 'RN' },
                                { title: 'Rio Grande do Sul', id: 'RS' },
                                { title: 'Rondônia', id: 'RO' },
                                { title: 'Roraima', id: 'RR' },
                                { title: 'Santa Catarina', id: 'SC' },
                                { title: 'São Paulo', id: 'SP' },
                                { title: 'Sergipe', id: 'SE' },
                                { title: 'Tocantins', id: 'TO' },
                              ]}
                              disabled={address?.zipcode ? undefined : true}
                              background={address?.zipcode ? undefined : true}
                              onChange={e =>
                                setAddress({
                                  ...address,
                                  state: e.target.value,
                                })
                              }
                              block="true"
                              value={address.state}
                              name="state"
                            />
                          </Field>
                        </Col>
                        <Col>
                          <Field>
                            <Label>Cidade</Label>
                            <FormInput
                              disabled={address?.zipcode ? undefined : true}
                              background={address?.zipcode ? undefined : true}
                              onChange={e =>
                                setAddress({
                                  ...address,
                                  city: e.target.value,
                                })
                              }
                              block="true"
                              value={address.city}
                              name="city"
                            />
                          </Field>
                        </Col>
                      </Row>
                    </Col>
                  </Scope>
                </Row>
              </Wrapper>
            </Col>
            <Col xs="12" lg="6">
              <Wrapper isFocused>
                <h3>
                  Método de pagamento
                  <p>
                    Só é possível realizar pagamentos por boleto caso não haja
                    um cartão de crédito cadastrado
                  </p>
                </h3>
                <Tabs>
                  <Tab
                    active={tab === 1}
                    onClick={() => {
                      setTab(1);
                    }}
                  >
                    Cartão de crédito
                  </Tab>
                  <Tab
                    active={tab === 2}
                    disabled={creditCard}
                    onClick={() => {
                      if (!creditCard) {
                        setTab(2);
                      }
                    }}
                  >
                    Boleto
                  </Tab>
                </Tabs>
                <TabContent
                  style={{
                    background: theme.interface2,
                  }}
                >
                  {tab === 1 ? (
                    <>
                      {creditCard && !changeCard ? (
                        <>
                          <Field>
                            <Label>Cartão de crédito</Label>
                            <FormSelect
                              value={1}
                              height="40px"
                              name="creditCard"
                              options={[
                                {
                                  id: 1,
                                  title: `${creditCard.brand} ${creditCard.display_number}`,
                                },
                              ]}
                            />
                          </Field>
                          <div
                            style={{
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'space-between',
                              height: '25px',
                              fontSize: '11px',
                              marginBottom: '20px',
                            }}
                          >
                            <Button
                              form="b"
                              outline
                              style={{
                                width: '120px',
                                padding: '0 10px',
                                fontSize: '11px',
                                height: '100%',
                              }}
                              onClick={() => {
                                setChangeCard(!changeCard);
                              }}
                            >
                              Trocar cartão
                            </Button>
                            <Button
                              form="b"
                              error
                              style={{
                                width: '120px',
                                padding: '0px 10px',
                                fontSize: '11px',
                                height: '100%',
                              }}
                              disabled={deleteLoading}
                              onClick={() => {
                                deleteCreditCard();
                              }}
                              icon
                            >
                              {deleteLoading ? (
                                <FontAwesomeIcon icon={faSpinner} spin />
                              ) : (
                                'Excluir cartão'
                              )}
                            </Button>
                          </div>
                        </>
                      ) : (
                        <Scope path="payment">
                          <Row>
                            <Col
                              xl="7"
                              style={{
                                marginBottom: '10px',
                              }}
                            >
                              <Cards
                                cvc={card.verification_value}
                                expiry={card.expiration}
                                focused={focus}
                                name={card?.name}
                                number={card.number}
                              />
                            </Col>
                            <Col xl="5">
                              <Row>
                                <Col>
                                  <Field>
                                    <Label>Número do cartão</Label>
                                    <InputMask
                                      mask="9999 9999 9999 9999"
                                      maskChar={null}
                                      block="true"
                                      name="number"
                                      onChange={e => {
                                        setCard({
                                          ...card,
                                          number: e.target.value,
                                        });
                                      }}
                                      onFocus={e => {
                                        setFocus(e.target?.name.split('.')[1]);
                                      }}
                                      error={!!cardErrors.number}
                                    >
                                      {inputProps => (
                                        <FormInput {...inputProps} />
                                      )}
                                    </InputMask>

                                    {cardErrors.number && (
                                      <Error>Número inválido</Error>
                                    )}
                                  </Field>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Field>
                                    <Label>Nome</Label>
                                    <FormInput
                                      block="true"
                                      name="name"
                                      onChange={e => {
                                        setCard({
                                          ...card,
                                          name: e.target.value,
                                        });
                                      }}
                                      onFocus={e => {
                                        setFocus(e.target?.name.split('.')[1]);
                                      }}
                                      error={!!cardErrors.last_name}
                                    />
                                    {cardErrors.last_name && (
                                      <Error>Nome inválido</Error>
                                    )}
                                  </Field>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Field>
                                    <Label>Validade</Label>
                                    <InputMask
                                      mask="99/9999"
                                      maskChar={null}
                                      block="true"
                                      name="expiration"
                                      onChange={e => {
                                        setCard({
                                          ...card,
                                          expiration: e.target.value,
                                        });
                                      }}
                                      onFocus={e => {
                                        setFocus(e.target?.name.split('.')[1]);
                                      }}
                                      error={!!cardErrors.expiration}
                                    >
                                      {inputProps => (
                                        <FormInput {...inputProps} />
                                      )}
                                    </InputMask>

                                    {cardErrors.expiration && (
                                      <Error>Data de expiração inválida</Error>
                                    )}
                                  </Field>
                                </Col>
                                <Col>
                                  <Field>
                                    <Label>cvc</Label>
                                    <FormInput
                                      maxLength="4"
                                      block="true"
                                      name="verification_value"
                                      onChange={e => {
                                        setCard({
                                          ...card,
                                          verification_value: e.target.value,
                                        });
                                      }}
                                      onFocus={e => {
                                        setFocus(e.target?.name.split('.')[1]);
                                      }}
                                      error={!!cardErrors.verification_value}
                                    />
                                    {cardErrors.verification_value && (
                                      <Error>CVC inválido</Error>
                                    )}
                                  </Field>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </Scope>
                      )}
                    </>
                  ) : (
                    <>
                      <p
                        style={{
                          color: theme.interface5,
                          textAlign: 'center',
                          padding: '18px',
                          marginBottom: '12px',
                          border: `1px solid ${theme.brand_primary_color}`,
                        }}
                      >
                        Um boleto será criado e enviado para seu email.
                      </p>
                    </>
                  )}
                </TabContent>
                <div
                  style={{
                    display: 'flex',
                    width: '100%',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                  }}
                >
                  <div
                    style={{
                      marginLeft: '14px',
                    }}
                  >
                    <span
                      style={{
                        color: theme.interface4,
                        fontSize: '13px',
                        display: 'block',
                      }}
                    >
                      Domínio
                    </span>
                    <p
                      style={{
                        color: theme.interface5,
                        fontSize: '17px',
                        display: 'block',
                      }}
                    >
                      {buyDomain?.domain}
                    </p>
                  </div>
                  <Total>
                    <p>Total: </p>
                    <CurrencyFormat
                      value={buyDomain.price?.amount}
                      displayType="text"
                      fixedDecimalScale
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      prefix={buyDomain.price?.currency === 'BRL' ? 'R$ ' : '$'}
                    />
                  </Total>
                </div>{' '}
                {errors &&
                  Object.keys(errors).map(e => (
                    <Error
                      key={e}
                      style={{
                        fontSize: '14px',
                      }}
                    >
                      {errors[e][0]}
                    </Error>
                  ))}
              </Wrapper>
              <Button
                icon
                disabled={loading}
                type="submit"
                form="infoFormPayment"
                style={{
                  float: 'right',
                }}
              >
                {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Criar'}
              </Button>
            </Col>
          </Row>
        </Form>
      </>
    )
  );
};

export default Payment;
