export default {
  success: '#7AD696',
  alert: '#EFA869',
  error: '#E86969',
  label: '#4A90E1',
  favorite: '#E9CF66',
  successNew: '#29D975',
  alertNew: '#F8820B',
  errorNew: '#FB264B',
  labelNew: '#353FF2',
  favoriteNew: '#FFBF2D',
  interface1: '#FFFFFF',
  interface2: '#F5F8FF',
  interface3: '#E8EAEF',
  interface4: '#CFD3DE',
  interface5: '#64728C',
  interface6: '#545C68',
  interface7: '#333333',
};
