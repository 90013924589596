import React, { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';

import { Field, Label, Value } from 'components/Details';
import { MigrationDetailContext } from '../_context/state';

const Acessos = () => {
  const { migration } = useContext(MigrationDetailContext);

  const migInfo = migration?.cache_data?.extra_args;

  return (
    <Row>
      <Col xl>
        <Row>
          <Col>
            <Field>
              <Label>Servidor/Endereço de origem</Label>
              <Value>
                <a
                  href={`http://${migInfo?.src_host}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {migInfo?.src_host}
                </a>
              </Value>
            </Field>
          </Col>
          <Col>
            <Field>
              <Label>Domínio de origem</Label>
              <Value>
                <a
                  href={`http://${migInfo?.src_domain}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {migInfo?.src_domain}
                </a>
              </Value>
            </Field>
          </Col>
        </Row>
        {migration.resource !== 'email' && migration.type !== 1 && (
          <Row>
            <Col>
              <Field>
                <Label>Usuário</Label>
                <Value>{migInfo?.src_user}</Value>
              </Field>
            </Col>
            <Col>
              <Field>
                <Label>Senha</Label>
                <Value>{migInfo?.src_passwd}</Value>
              </Field>
            </Col>
          </Row>
        )}

        <Row>
          {migration.resource !== 'email' && migration.type !== 1 && (
            <Col>
              <Field>
                <Label>Caminho da aplicação de origem</Label>
                <Value>{migInfo?.src_path}</Value>
              </Field>
            </Col>
          )}
          {migInfo?.src_port && (
            <Col>
              <Field>
                <Label>Porta do host de origem</Label>
                <Value>{migInfo?.src_port}</Value>
              </Field>
            </Col>
          )}
        </Row>

        <Row>
          <Col>
            <Field>
              <Label>Servidor de destino</Label>
              <Value>{migInfo?.dest_host}</Value>
            </Field>
          </Col>
          <Col>
            <Field>
              <Label>Domínio de destino</Label>
              <Value>{migInfo?.dest_domain}</Value>
            </Field>
          </Col>
        </Row>
        {migration.resource !== 'email' && (
          <Field>
            <Label>Endereço temporário</Label>
            <Value>
              <a
                href={`http://${migInfo?.temp}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {migInfo?.temp}
              </a>
            </Value>
          </Field>
        )}
      </Col>
    </Row>
  );
};

export default Acessos;
