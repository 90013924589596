import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { Title, Header } from 'components/Common';
import { Button } from '@cloudez/cloudez-design-system';

import axios from 'axios';
import history from 'services/history';

import { useAuth } from 'hooks/auth';

import { refreshBankslipService } from 'services/invoice';
import { Iframe } from './styles';

const InvoiceBankslip = ({ match }) => {
  const [billing, setBilling] = useState(null);
  const [bankslip, setBankslip] = useState(null);

  const [externalUrl, setExternalUrl] = useState(null);

  const { user } = useAuth();

  useEffect(() => {
    const { hash } = match.params;

    async function getInvoice() {
      try {
        const apiUrl =
          window.location.hostname === 'demo.cloudez.io'
            ? 'https://dev-api.cloudez.io/'
            : process.env.REACT_APP_API_URL
            ? process.env.REACT_APP_API_URL
            : 'https://api.cloudez.io/';

        const { data } = await axios.get(`${apiUrl}v2/invoice-hash/${hash}/`);

        const { fbq } = window as any;
        const { gtag } = window as any;

        if (fbq) {
          fbq('track', 'Purchase', {
            currency: data.total.currency,
            value: data.total.amount,
          });
        }

        if (gtag) {
          gtag('event', 'track', { event_category: 'Purchase' });
        }

        const today = moment();
        const expiredAt = moment(data?.expired_at);
        const isExpired = expiredAt.isBefore(today, 'days');

        const startChargingAt = moment(data?.start_charging_at).format(
          'YYYY-MM-DD',
        );

        const maxDate = moment(data?.start_charging_at)
          .add(10, 'days')
          .format('YYYY-MM-DD');

        const canChangeDate =
          isExpired &&
          data?.has_gateway &&
          !data?.is_paid &&
          moment().isBetween(startChargingAt, maxDate, null, '[]');

        if (canChangeDate) {
          await refreshBankslipService(data.id);
        } else if (data.is_paid) {
          history.push(`/invoices/${data.id}`);
        }
      } catch (e) {
        history.push(`/404`);
      }
    }

    async function getBilling() {
      try {
        const apiUrl =
          window.location.hostname === 'demo.cloudez.io'
            ? 'https://dev-api.cloudez.io/'
            : process.env.REACT_APP_API_URL
            ? process.env.REACT_APP_API_URL
            : 'https://api.cloudez.io/';

        const { data } = await axios.get(`${apiUrl}v3/billing-hash/${hash}/`);

        setBilling(data);
      } catch (e) {
        history.push(`/invoices/external/${hash}`);
      }
    }

    async function getBankslip() {
      try {
        const apiUrl =
          window.location.hostname === 'demo.cloudez.io'
            ? 'https://dev-api.cloudez.io/'
            : process.env.REACT_APP_API_URL
            ? process.env.REACT_APP_API_URL
            : 'https://api.cloudez.io/';

        const { data } = await axios.get(`${apiUrl}v0/bank-slip/${hash}/`, {
          responseType: 'blob',
        });

        const response = await axios.get(`${apiUrl}v0/bank-slip/${hash}/`, {
          responseType: 'json',
        });

        setExternalUrl(response.data);

        setBankslip(URL.createObjectURL(data));
      } catch (e) {
        history.push(`/invoices/external/${hash}`);
      }
    }

    getInvoice();
    if (!billing) getBilling();
    if (!bankslip) getBankslip();
    // eslint-disable-next-line
  }, []);

  const apiUrl =
    window.location.hostname === 'demo.cloudez.io'
      ? 'https://dev-api.cloudez.io/'
      : process.env.REACT_APP_API_URL
      ? process.env.REACT_APP_API_URL
      : 'https://api.cloudez.io/';

  return billing && bankslip ? (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '20px 0px 40px 0px',
        }}
      >
        <Title>Boleto</Title>

        {user?.payment_gateway !== 'PagBrasil' ||
          (!externalUrl.url_boleto?.includes('pagbrasil') && (
            <Button>
              <a
                href={`${apiUrl}v0/bank-slip/${match.params.hash}/`}
                target="_blank"
                rel="noopener noreferrer"
              >
                Download
              </a>
            </Button>
          ))}
      </div>

      <Iframe
        src={
          user?.payment_gateway === 'PagBrasil' ||
          externalUrl.url_boleto?.includes('pagbrasil')
            ? externalUrl.url_boleto
            : `${bankslip}#statusbar=0`
        }
      />

      <Button
        style={{
          marginTop: '16px',
        }}
        onClick={() => history.push(`/invoices/external/${match.params.hash}`)}
      >
        Pagar com cartão
      </Button>
    </>
  ) : (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px 0px 40px 0px',
      }}
    >
      <Title>Carregando boleto...</Title>
    </div>
  );
};

export default InvoiceBankslip;
