import React, { useContext } from 'react';

import { Button } from '@cloudez/cloudez-design-system';
import parabens from 'assets/img/parabens.png';

import history from 'services/history';

import { ThemeContext } from 'styled-components';
import { Wrapper } from './styles';
import { WebsiteCreateContext } from '../_context/state';

const Success = () => {
  const theme = useContext(ThemeContext);
  const { website } = useContext(WebsiteCreateContext);

  return (
    <Wrapper>
      <div
        style={{
          background: theme.brand_primary_color,
          width: '525px',
          height: '380px',
        }}
      >
        <img src={parabens} alt="parabéns" />
      </div>
      <span>Parabéns</span>
      <p>O website {website.domain} foi criado .</p>
      <Button size="lg" onClick={() => history.push(`/websites/${website.id}`)}>
        Ir para website
      </Button>
    </Wrapper>
  );
};

export default Success;
