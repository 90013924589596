import React, { useEffect, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment/min/moment-with-locales';
import md5 from 'md5';

import {
  faPaperclip,
  faFilePdf,
  faFile,
} from '@fortawesome/free-solid-svg-icons';
import { useLayout } from 'hooks/layout';
import { useAuth } from 'hooks/auth';
import {
  Message as MessageStyle,
  MessageHeader,
  MessageText,
  File,
  Hour,
} from '../styles';

const Message = ({ isAuthor, domo, staff, isStaff, m, ticket }) => {
  const { support_email } = useLayout();
  const { user } = useAuth();

  moment.locale('pt-BR');

  const _createdAt = moment(m.created_at).endOf('day');
  const _yesterday = moment().endOf('day');

  const isOld = !moment(_yesterday).isSame(moment(_createdAt), 'd');

  const createdAt = moment(m.created_at).format(isOld ? 'LLL' : 'HH:mm');

  const messageRef = useRef(null);

  useEffect(() => {
    const url = new RegExp(
      // eslint-disable-next-line
      /(\b(https?|ftp):\/\/[A-Z0-9+&@#\/%?=~_|!:,.;-]*[-A-Z0-9+&@#\/%=~_|])/gim,
    );

    if (messageRef.current) {
      messageRef.current.innerHTML = messageRef.current.innerHTML.replace(
        url,
        '<a href="$1" target="_blank">$1</a>',
      );
    }
  }, [messageRef]);

  const isDomo = msg => {
    return (
      msg.author === 'domo@configr.com' ||
      msg.author === support_email ||
      msg.author_external === support_email
    );
  };

  const getDomoIcon = id => {
    const req = require.context('assets/img', true, /\.png$/);
    let icon;

    try {
      const random = (id % 4) + 1;
      const domo = 'domo-'.concat(random.toString());

      icon = req(`./${domo}.png`).default;
    } catch (e) {
      icon = null;
    }

    return icon;
  };

  const getDomoName = msg => {
    return `Domo #${Math.floor(Math.random() * msg.id)}`;
  };

  return (
    <MessageStyle
      isAuthor={isAuthor}
      domo={domo}
      staff={staff}
      isStaff={isStaff}
    >
      <MessageHeader domo={isDomo(m)}>
        <p>
          <img
            src={
              m.author === 'domo@configr.com'
                ? getDomoIcon(m.id)
                : `https://www.gravatar.com/avatar/${md5(
                    m.author || m.author_external,
                  )}?d=mp`
            }
            alt="avatar"
          />
          {m.author === 'domo@configr.com' ? getDomoName(m) : m.display_name}
        </p>
        {m.text && m.file && (
          <span onClick={() => window.open(m.file, '_blank')}>
            <span>Anexo</span>
            <FontAwesomeIcon icon={faPaperclip} />
          </span>
        )}
      </MessageHeader>
      <MessageText domo={isDomo(m)}>
        {!m.text && m.file && (
          <File
            onClick={() => {
              window.open(m.file, '_blank');
            }}
          >
            <span>
              {m.file.includes('.png') ||
              m.file.includes('.jpg') ||
              m.file.includes('.jpe') ||
              m.file.includes('.jpeg') ? (
                <img
                  src={m.file}
                  alt={m.file}
                  style={{
                    maxHeight: '120px',
                    width: '100%',
                  }}
                />
              ) : (
                <FontAwesomeIcon
                  icon={m.file.includes('.pdf') ? faFilePdf : faFile}
                />
              )}
            </span>
            <p>{m.file.split('uploads/')[1]}</p>
          </File>
        )}
        <pre ref={messageRef}>{m.text}</pre>
      </MessageText>
      <Hour
        isAuthor={m.author === user.email}
        domo={isDomo(m)}
        staff={m.is_ticket_staff && m.author !== ticket.owner_email}
        isStaff={user.is_staff}
        isOld={isOld}
      >
        {createdAt}
      </Hour>
    </MessageStyle>
  );
};

export default Message;
