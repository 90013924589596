import React, { useContext, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faUnlink } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import unlink from 'assets/img/svg/unlink.svg';

import { Modal, Input, Button, TextArea } from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from 'components/ListingModal/styles';

import { useLayout } from 'hooks/layout';
import { HttpsText } from './styles';
import { CloudDetailContext } from '../_context/state';

const RemoveModal = ({ show, setShow }) => {
  const [remove, setRemove] = useState('');
  const [motive, setMotive] = useState('');
  const [loading, setLoading] = useState(false);
  const { cloud, deleteCloud } = useContext(CloudDetailContext);
  const theme = useLayout();

  const action = async () => {
    await deleteCloud(cloud.id, motive);
  };

  return (
    <Modal show={show} setShow={setShow} noExit={loading}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faTrashAlt} />
        </Icon>
        <Text>
          <Title>Excluir cloud</Title>
          <Subtitle>Deseja remover este cloud?</Subtitle>
        </Text>
      </Header>

      <Content>
        <HttpsText>
          Por favor, escreva{' '}
          <span
            style={{
              textTransform: 'uppercase',
              color: theme.error,
              fontWeight: 'bold',
            }}
          >
            REMOVER
          </span>{' '}
          para confirmar a exclusão deste cloud
        </HttpsText>

        <Input
          block
          placeholder="REMOVER"
          value={remove}
          onChange={e => {
            setRemove(e.target.value);
          }}
        />

        <HttpsText>
          No campo abaixo, descreva o motivo da exclusão deste cloud.
        </HttpsText>

        <TextArea
          block
          height={200}
          value={motive}
          onChange={e => {
            setMotive(e.target.value);
          }}
        />
      </Content>
      <Footer>
        <Button
          disabled={remove !== 'REMOVER' || !motive || loading}
          error
          icon
          onClick={() => {
            setLoading(true);
            action();
          }}
        >
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Remover'}
        </Button>
      </Footer>
    </Modal>
  );
};

export default RemoveModal;
