import { Reducer } from 'react';
import { IActionPayload } from 'types';
import { createPagination } from '@cloudez/cloudez-design-system';
import { DomainListingState } from './state';

const reducer: Reducer<DomainListingState, IActionPayload> = (
  state,
  action,
): DomainListingState => {
  switch (action.type) {
    case 'GET_DOMAINS':
      const domains = action.payload.results;

      const { count, next, previous, current } = createPagination(
        action.payload,
        10,
      );

      return {
        ...state,
        domains,
        count,
        next,
        previous,
        current,
      };

    case 'UPDATE_DOMAIN': {
      return {
        ...state,
        domains: state?.domains?.map(domain =>
          domain.id === action.payload.id ? action.payload : domain,
        ),
      };
    }

    case 'SET_LOADING': {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case 'SET_ERRORS': {
      return {
        ...state,
        errors: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
