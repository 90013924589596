import { useAuth } from 'hooks/auth';
import { userCan } from 'hooks/userCan';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

interface CanProps {
  roles: any[];
  permissions?: any[];
}

const Can: React.FC<CanProps> = ({ children, roles, permissions }) => {
  const history = useHistory();
  const { isAuthenticated } = useAuth();

  const userCanSeePage = userCan({
    roles,
    permissions,
  });

  useEffect(() => {
    if (isAuthenticated && !userCanSeePage) history.push('/404');
  }, [userCanSeePage, isAuthenticated]);

  return (
    ((isAuthenticated && userCanSeePage) || !isAuthenticated) && <>{children}</>
  );
};

export default Can;
