import React, { createContext, useReducer } from 'react';
import { PaginationType } from '@cloudez/cloudez-design-system';
import { Database } from 'types/database';
import reducer from './reducer';
import {
  setLoading,
  getDatabases,
  updateDatabase,
  updateDatabaseField,
} from './actions';

export interface DatabaseListingState extends PaginationType {
  databases: Database[];
  loading: boolean;
  errors: any;

  setLoading(value: boolean): void;
  getDatabases(
    page: string | number,
    search: string,
    filter: any,
    clouds: any,
    order: any,
    ctrl: AbortController,
  ): Promise<void>;
  updateDatabase(
    field: string,
    value: any,
    id?: number | string,
  ): Promise<void>;
  updateDatabaseField(field: string, value: any, id?: number | string): void;
}

// initial state
const initialState = {
  databases: null,
  count: null,
  previous: null,
  current: null,
  next: null,
  loading: true,
  errors: null,
};

// context
export const DatabaseListingContext = createContext<DatabaseListingState>(
  initialState as DatabaseListingState,
);

// provider
export const DatabaseListingProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(
    reducer,
    initialState as DatabaseListingState,
  );

  return (
    <DatabaseListingContext.Provider
      value={{
        // state
        databases: state.databases,
        count: state.count,
        previous: state.previous,
        current: state.current,
        next: state.next,
        loading: state.loading,
        errors: state.errors,

        // actions
        setLoading: value => setLoading(dispatch, value),
        getDatabases: async (page, search, filter, clouds, order, ctrl) =>
          await getDatabases(
            dispatch,
            page,
            search,
            filter,
            clouds,
            order,
            ctrl,
          ),
        updateDatabase: async (field, value, id) =>
          await updateDatabase(dispatch, field, value, id),
        updateDatabaseField: (field, value, id) =>
          updateDatabaseField(dispatch, field, value, id),
      }}
    >
      {children}
    </DatabaseListingContext.Provider>
  );
};
