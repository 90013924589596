import api from 'services/api';

export const getCloudsService = async (
  params?,
  ctrl = new AbortController(),
) => {
  try {
    return await api.get('/v3/cloud/', { params, signal: ctrl.signal });
  } catch (e) {
    throw e;
  }
};

export const getCloudService = async (id, params?) => {
  try {
    return await api.get(`/v3/cloud/${id}/`, { params });
  } catch (e) {
    throw e;
  }
};

export const deleteCloudService = async (id, motive) => {
  try {
    return await api.delete(`/v3/cloud/${id}/`, {
      data: {
        motive,
      },
    });
  } catch (e) {
    throw e;
  }
};

export const updateCloudService = async (id, data) => {
  try {
    return await api.patch(`/v3/cloud/${id}/`, data);
  } catch (e) {
    throw e;
  }
};

export const getCloudUsersService = async params => {
  try {
    return await api.get(`/v3/cloud-user/`, { params });
  } catch (e) {
    throw e;
  }
};

export const createCloudUserService = async payload => {
  try {
    return await api.post('/v3/cloud-user/', payload);
  } catch (e) {
    throw e;
  }
};

export const getCloudUserService = async id => {
  try {
    return await api.get(`/v3/cloud-user/${id}/`);
  } catch (e) {
    throw e;
  }
};

export const updateCloudUserService = async (id, data) => {
  try {
    return await api.patch(`/v3/cloud-user/${id}/`, data);
  } catch (e) {
    throw e;
  }
};

export const deleteCloudUserService = async id => {
  try {
    return await api.delete(`/v3/cloud-user/${id}/`);
  } catch (e) {
    throw e;
  }
};

export const createCloudSetupService = async data => {
  try {
    return await api.post('/v3/cloud/setup/', data);
  } catch (e) {
    throw e;
  }
};

export const getCloudSizesService = async () => {
  try {
    return await api.get('/v3/cloud/size/');
  } catch (e) {
    throw e;
  }
};

export const getAddonsService = async params => {
  try {
    return await api.get(`/v3/addon/`, { params });
  } catch (e) {
    throw e;
  }
};

export const getAddonTypesService = async () => {
  try {
    return await api.get(`/v3/addon-type/`);
  } catch (e) {
    throw e;
  }
};

export const getAddonTypeService = async id => {
  try {
    return await api.get(`/v3/addon-type/${id}/`);
  } catch (e) {
    throw e;
  }
};

export const createAddonService = async payload => {
  try {
    return await api.post('/v3/addon/', payload);
  } catch (e) {
    throw e;
  }
};

export const updateAddonService = async (id, payload) => {
  try {
    return await api.patch(`/v3/addon/${id}/`, payload);
  } catch (e) {
    throw e;
  }
};

export const deleteAddonService = async id => {
  try {
    return await api.delete(`/v3/addon/${id}/`);
  } catch (e) {
    throw e;
  }
};

export const createCloudChangeService = async payload => {
  try {
    return await api.post(`/v3/cloud/change/`, payload);
  } catch (e) {
    throw e;
  }
};

export const putCloudActionService = async (id, payload) => {
  try {
    return await api.put(`/v3/cloud/action/${id}/`, payload);
  } catch (e) {
    throw e;
  }
};

export const validateCoupon = async coupon => {
  try {
    return await api.post(`/v3/cloud/setup/validate_coupon/`, { coupon });
  } catch (e) {
    throw e;
  }
};
