import React, { useMemo } from 'react';
import zxcvbn from 'zxcvbn';
import * as Styles from './styles';

function PasswordStrength({ password }) {
  const strength = useMemo(() => zxcvbn(password).score, [password]);

  return (
    <Styles.Wrapper>
      <Styles.StrengthLevel level={0} strength={strength} />
      <Styles.StrengthLevel level={1} strength={strength} />
      <Styles.StrengthLevel level={2} strength={strength} />
      <Styles.StrengthLevel level={3} strength={strength} />
      <Styles.StrengthLevel level={4} strength={strength} />
    </Styles.Wrapper>
  );
}

export default PasswordStrength;
