import styled from 'styled-components';
import { Button } from '@cloudez/cloudez-design-system';

export const InfoBox = styled.div`
  display: flex;
  flex-direction: column;

  background-color: ${props => props.theme.interface1};

  width: 60%;

  padding: 15px 0px 32px 15px;

  border-radius: 5px;

  @media (max-width: 1000px) {
    width: 100%;
  }

  border-radius: 5px;

  a {
    font-size: 11px;
    color: ${props => props.theme.brand_primary_color};
    text-decoration: underline;
  }

  p {
    font-weight: 700;
    font-size: 16px;

    margin-bottom: 16px;

    color: ${props => props.theme.interface5};
  }

  span {
    font-weight: 400;
    font-size: 11px;

    color: ${props => props.theme.interface5};
  }

  .content {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-auto-columns: 100px;
    gap: 16px;

    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }

    .info {
      display: flex;
      flex-direction: column;
      gap: 1px;
    }

    .title {
      font-size: 13px;
      text-transform: uppercase;
      color: #bac4dc;
    }
  }
`;

export const UndoButton = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background: ${props => props.theme.brand_secondary_color};
  margin-right: -20px;
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;

  svg {
    color: ${props => props.theme.brand_primary_color};
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 70px;
  margin-bottom: 40px;
  /*
  ${Button} {
    margin-left: 62px;
  } */

  @media (max-width: 768px) {
    margin-bottom: 60px;

    ${Button} {
      margin-top: 10px;
      width: 100%;
    }
  }
`;

export const Title = styled.div`
  margin-left: 30px;

  h3 {
    font-size: 18px;
    color: ${props => props.theme.interface6};
  }

  p {
    font-size: 12px;
    color: ${props => props.theme.interface5};
  }
`;

export const Card = styled.div`
  height: 152px;
  padding: 15px;
  background: ${props => props.theme.interface1};
  border-radius: 5px;
`;

export const CardHeader = styled.h3`
  font-size: 16px;
  color: ${props => props.theme.interface5};
`;

export const Informations = styled.div`
  padding: 30px;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Information = styled.div`
  svg {
    color: #cfd3de;
    font-size: 18px;
  }

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
`;

export const Pagespeed = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const Performance = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const TicketCard = styled.div`
  height: 100%;
  padding: 15px;
  background: ${props => props.theme.interface1};
  border-radius: 5px;
`;
