import styled, { css } from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px 40px 0px;

  @media (max-width: 768px) {
    button {
      width: 60px;

      span {
        display: none;
      }

      &::after {
        content: 'Novo';
      }
    }
  }
`;

interface ICrateWrapperProps {
  width?: string;
}

export const CreateWrapper = styled.div<ICrateWrapperProps>`
  width: ${props => (props.width ? props.width : '100%')};
  height: 100%;
  display: flex;
  margin: auto;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 1000px) {
    width: 100%;
  }
`;

export const CreateHeader = styled.div`
  width: 100%;
  display: flex;
  padding: 20px 0px 0px 0px;

  flex-direction: column;

  align-items: center;
  justify-content: space-between;

  color: ${props =>
    props.theme.darkMode ? props.theme.interface3 : props.theme.interface7};

  span {
    color: ${props => props.theme.interface5};
  }

  @media (max-width: 768px) {
    text-align: center;

    h1 {
      font-size: 25px;
    }
  }
`;

interface ICreateButtonsProps {
  reverseColumn?: boolean;
  align?: string;
}

export const CreateButtons = styled.div<ICreateButtonsProps>`
  width: 100%;
  display: flex;
  justify-content: space-between;

  button:only-child {
    margin-left: ${props => (props.align === 'left' ? '' : 'auto')};
  }

  @media (max-width: 762px) {
    flex-direction: ${props =>
      props.reverseColumn ? 'column-reverse' : 'column'};

    button {
      width: 100%;
      padding: 0;
    }
  }
`;

export const Title = styled.h1`
  color: ${props =>
    props.theme.darkMode
      ? props.theme.interface4
      : props.theme.interface7} !important;
  font-size: 28px;
  font-weight: bold;

  svg {
    color: ${props => props.theme.brand_primary_color} !important;
  }
`;

export const NoResults = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  font-size: 14px;
  color: ${props => props.theme.interface5};
  padding: 40px;
  border-top: 1px dashed ${props => props.theme.interface5};
  border-bottom: 1px dashed ${props => props.theme.interface5};
`;

interface INoResultsDetailProps {
  width?: string;
  height?: string;
  row?: boolean;
}
export const NoResultsDetail = styled.div<INoResultsDetailProps>`
  display: flex;
  width: ${props => props.width || '231px'};
  height: ${props => props.height || '142px'};
  align-items: center;
  justify-content: ${props => (props.row ? 'space-between' : 'center')};
  flex-direction: ${props => (props.row ? 'row' : 'column')};
  font-size: 14px;
  color: ${props => props.theme.interface5};
  padding: ${props => (props.row ? '13px 10px' : '20px 10px')};
  border: 1px dashed
    ${props =>
      props.theme.darkMode ? props.theme.interface6 : props.theme.interface5};
  border-radius: 5px;
  text-transform: uppercase;

  svg {
    color: ${props => props.theme.interface5};
    margin-bottom: ${props => (props.row ? '0px' : '10px')};
    margin-right: 14px;
    font-size: ${props => (props.row ? '24px' : '35px')};
  }

  font-size: 12px;
`;

export const Breadcrumbs = styled.div`
  line-height: 30px;
  /* font-weight: 400; */
  width: 100%;
  margin-bottom: -4px;
  display: flex;
  align-items: center;

  svg {
    margin: 0 8px !important;
    color: ${props => props.theme.interface5} !important;
    font-size: 10px;
  }
`;

interface IBreadcrumbProps {
  active?: boolean;
}

export const Breadcrumb = styled.span<IBreadcrumbProps>`
  font-size: 12px;

  a {
    color: ${props => props.theme.interface5};

    ${props =>
      props.active &&
      css`
        color: ${props => props.theme.brand_primary_color};
      `};

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const Flex = styled.div`
  display: flex;

  justify-content: space-evenly;
  align-items: center;
`;
