import React, { createContext, useReducer } from 'react';
import { PaginationType } from '@cloudez/cloudez-design-system';
import { Invoice } from 'types/invoice';
import reducer from './reducer';
import { setLoading, getInvoices, updateInvoice } from './actions';

interface InvoiceListingState extends PaginationType {
  invoices: Invoice[];
  loading: boolean;
  errors: any;
  setLoading(value: boolean): void;
  getInvoices(
    page: string | number,
    search: any,
    status: any,
    order: any,
    ctrl: AbortController,
  ): Promise<void>;
  updateInvoice(field: string, value: any, id: string | number): Promise<void>;
}

// initial state
const initialState = {
  invoices: null,
  count: null,
  previous: null,
  current: null,
  next: null,
  loading: true,
  errors: null,
};

// context
export const InvoiceListingContext = createContext<InvoiceListingState>(
  initialState as InvoiceListingState,
);

// provider
export const InvoiceListingProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    reducer,
    initialState as InvoiceListingState,
  );

  return (
    <InvoiceListingContext.Provider
      value={{
        // state
        invoices: state.invoices,
        count: state.count,
        previous: state.previous,
        current: state.current,
        next: state.next,
        loading: state.loading,
        errors: state.errors,

        // actions
        setLoading: value => setLoading(dispatch, value),
        getInvoices: async (page, search, status, order, ctrl) =>
          await getInvoices(dispatch, page, search, status, order, ctrl),
        updateInvoice: async (field, value, id) =>
          await updateInvoice(dispatch, field, value, id),
      }}
    >
      {children}
    </InvoiceListingContext.Provider>
  );
};
