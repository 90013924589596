import styled from 'styled-components';

export const Table = styled.table`
  width: 100%;

  margin-top: 16px;

  text-align: left;

  border-collapse: separate;
  border-spacing: 0px;
  border: solid ${props => props.theme.interface3} 1px;
  border-radius: 6px;

  td {
    color: ${props => props.theme.interface5};
  }

  td:last-child {
    width: 40px;
  }

  tr:first-child {
    th {
      border-top: 0px;
    }
  }

  .withBorder {
    border-bottom: 1px solid ${props => props.theme.interface3};
    border-top: 1px solid ${props => props.theme.interface3};
  }

  td,
  th {
    padding: 11px 24px 10px 15px;
  }

  th {
    background-color: ${props => props.theme.interface2};
    color: ${props => props.theme.brand_primary_color};
  }
`;
