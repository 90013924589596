import { useLayout } from 'hooks/layout';
import React from 'react';

const IconComponent = ({ type }) => {
  const theme = useLayout();

  const icon = {
    closedIcon: {
      d: 'M24.001 16.4769V2.96885C24.001 1.35269 22.6931 0 21 0H3C1.34766 0 0 1.31034 0 2.96885V16.4769C0 18.0931 1.34766 19.4457 3 19.4457H7.5V23.397C7.5 23.8557 8.02711 24.1239 8.39625 23.8534L14.2509 19.4457H21.0009C22.6533 19.4457 24.001 18.1354 24.001 16.4769ZM16.7487 13.759C17.1393 13.3669 17.1393 12.7318 16.7487 12.3398L13.4568 9.03555L16.7503 5.72976C17.1409 5.33771 17.1409 4.70258 16.7503 4.31053C16.3597 3.91848 15.7269 3.91848 15.3363 4.31053L12.0428 7.61632L8.75011 4.30896C8.35952 3.91691 7.72675 3.91691 7.33616 4.30896C6.94556 4.70102 6.94556 5.33614 7.33616 5.72819L10.6297 9.03241L7.33616 12.3382C6.94556 12.7302 6.94556 13.3654 7.33616 13.7574C7.53161 13.955 7.78722 14.0522 8.04313 14.0522C8.29905 14.0522 8.55497 13.955 8.75027 13.7574L12.0428 10.4548L15.3357 13.7582C15.5307 13.9542 15.7863 14.0522 16.0425 14.0522C16.2987 14.0522 16.555 13.955 16.7487 13.759Z',
      fill: '#A2A9BF',
    },
    closedSelectedIcon: {
      d: 'M24.001 16.4769V2.96885C24.001 1.35269 22.6931 0 21 0H3C1.34766 0 0 1.31034 0 2.96885V16.4769C0 18.0931 1.34766 19.4457 3 19.4457H7.5V23.397C7.5 23.8557 8.02711 24.1239 8.39625 23.8534L14.2509 19.4457H21.0009C22.6533 19.4457 24.001 18.1354 24.001 16.4769ZM16.7487 13.759C17.1393 13.3669 17.1393 12.7318 16.7487 12.3398L13.4568 9.03555L16.7503 5.72976C17.1409 5.33771 17.1409 4.70258 16.7503 4.31053C16.3597 3.91848 15.7269 3.91848 15.3363 4.31053L12.0428 7.61632L8.75011 4.30896C8.35952 3.91691 7.72675 3.91691 7.33616 4.30896C6.94556 4.70102 6.94556 5.33614 7.33616 5.72819L10.6297 9.03241L7.33616 12.3382C6.94556 12.7302 6.94556 13.3654 7.33616 13.7574C7.53161 13.955 7.78722 14.0522 8.04313 14.0522C8.29905 14.0522 8.55497 13.955 8.75027 13.7574L12.0428 10.4548L15.3357 13.7582C15.5307 13.9542 15.7863 14.0522 16.0425 14.0522C16.2987 14.0522 16.555 13.955 16.7487 13.759Z',
      fill: theme.brand_primary_color,
    },
    openIcon: {
      d: 'M3 0H21C22.6922 0 24 1.34766 24.0009 2.95781V16.4156C24.0009 18.0258 22.6533 19.3734 21.0009 19.3734H14.2509L8.39625 23.7647C8.01656 24.0389 7.5 23.7711 7.5 23.31V19.3734H3C1.34766 19.3734 0 18.068 0 16.4156V2.95781C0 1.34766 1.34766 0 3 0ZM21.0422 17.25C21.4523 17.25 21.7922 16.9102 21.7922 16.5V3C21.7922 2.58984 21.4523 2.25 21.0422 2.25H3.04219C2.63203 2.25 2.29219 2.58984 2.29219 3V16.5C2.29219 16.9102 2.63203 17.25 3.04219 17.25H9.79218V20.0625L13.5422 17.25H21.0422ZM17.442 5.8C17.442 5.28375 17.0095 4.86667 16.4741 4.86667H4.80994C4.27548 4.86667 3.84204 5.28375 3.84204 5.8C3.84204 6.31625 4.27548 6.73334 4.80994 6.73334H16.4741C17.0095 6.73334 17.442 6.31625 17.442 5.8ZM11.6643 8.60001C12.2051 8.60001 12.642 9.01709 12.642 9.53334C12.642 10.0496 12.2051 10.4667 11.6643 10.4667H4.81982C4.2799 10.4667 3.84204 10.0496 3.84204 9.53334C3.84204 9.01709 4.2799 8.60001 4.81982 8.60001H11.6643ZM17.442 13.2667C17.442 12.7504 17.0079 12.3333 16.4706 12.3333H4.81347C4.27706 12.3333 3.84204 12.7504 3.84204 13.2667C3.84204 13.7829 4.27706 14.2 4.81347 14.2H16.4706C17.0079 14.2 17.442 13.7829 17.442 13.2667Z',
      fill: '#A2A9BF',
    },
    openSelectedIcon: {
      d: 'M3 0H21C22.6922 0 24 1.34766 24.0009 2.95781V16.4156C24.0009 18.0258 22.6533 19.3734 21.0009 19.3734H14.2509L8.39625 23.7647C8.01656 24.0389 7.5 23.7711 7.5 23.31V19.3734H3C1.34766 19.3734 0 18.068 0 16.4156V2.95781C0 1.34766 1.34766 0 3 0ZM21.0422 17.25C21.4523 17.25 21.7922 16.9102 21.7922 16.5V3C21.7922 2.58984 21.4523 2.25 21.0422 2.25H3.04219C2.63203 2.25 2.29219 2.58984 2.29219 3V16.5C2.29219 16.9102 2.63203 17.25 3.04219 17.25H9.79218V20.0625L13.5422 17.25H21.0422ZM17.442 5.8C17.442 5.28375 17.0095 4.86667 16.4741 4.86667H4.80994C4.27548 4.86667 3.84204 5.28375 3.84204 5.8C3.84204 6.31625 4.27548 6.73334 4.80994 6.73334H16.4741C17.0095 6.73334 17.442 6.31625 17.442 5.8ZM11.6643 8.60001C12.2051 8.60001 12.642 9.01709 12.642 9.53334C12.642 10.0496 12.2051 10.4667 11.6643 10.4667H4.81982C4.2799 10.4667 3.84204 10.0496 3.84204 9.53334C3.84204 9.01709 4.2799 8.60001 4.81982 8.60001H11.6643ZM17.442 13.2667C17.442 12.7504 17.0079 12.3333 16.4706 12.3333H4.81347C4.27706 12.3333 3.84204 12.7504 3.84204 13.2667C3.84204 13.7829 4.27706 14.2 4.81347 14.2H16.4706C17.0079 14.2 17.442 13.7829 17.442 13.2667Z',
      fill: theme.brand_primary_color,
    },
    defaultIcon: {
      d: 'M3 0H21C22.6922 0 24 1.34766 24.0009 2.95781V16.4156C24.0009 18.0258 22.6533 19.3734 21.0009 19.3734H14.2509L8.39625 23.7647C8.01656 24.0389 7.5 23.7711 7.5 23.31V19.3734H3C1.34766 19.3734 0 18.068 0 16.4156V2.95781C0 1.34766 1.34766 0 3 0ZM21.0422 17.25C21.4523 17.25 21.7922 16.9102 21.7922 16.5V3C21.7922 2.58984 21.4523 2.25 21.0422 2.25H3.04219C2.63203 2.25 2.29219 2.58984 2.29219 3V16.5C2.29219 16.9102 2.63203 17.25 3.04219 17.25H9.79218V20.0625L13.5422 17.25H21.0422ZM17.4423 5.8C17.4423 5.28375 17.0098 4.86667 16.4744 4.86667H4.81019C4.27572 4.86667 3.84229 5.28375 3.84229 5.8C3.84229 6.31625 4.27572 6.73334 4.81019 6.73334H16.4744C17.0098 6.73334 17.4423 6.31625 17.4423 5.8ZM11.6645 8.60001C12.2053 8.60001 12.6423 9.01709 12.6423 9.53334C12.6423 10.0496 12.2053 10.4667 11.6645 10.4667H4.82006C4.28015 10.4667 3.84229 10.0496 3.84229 9.53334C3.84229 9.01709 4.28015 8.60001 4.82006 8.60001H11.6645ZM17.4423 13.2667C17.4423 12.7504 17.0082 12.3333 16.4709 12.3333H4.81371C4.2773 12.3333 3.84229 12.7504 3.84229 13.2667C3.84229 13.7829 4.2773 14.2 4.81371 14.2H16.4709C17.0082 14.2 17.4423 13.7829 17.4423 13.2667Z',
      fill: theme.interface6,
    },
  }[type];

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d={icon?.d}
        fill={icon?.fill}
      />
    </svg>
  );
};
export default IconComponent;
