import styled from 'styled-components';

export const HttpsText = styled.p`
  font-size: 14px;
  color: ${props => props.theme.interface5};
  line-height: 1.5;
  margin-bottom: 15px;
`;

export const Domain = styled.span`
  font-size: 17px;
  color: ${props => props.theme.interface5};
  line-height: 1.5;
`;

export const Cloud = styled.span`
  font-size: 11px;
  color: ${props => props.theme.interface5};
  margin-bottom: 10px;
`;
