import styled, { css } from 'styled-components';

interface TextAreaProps {
  height?: string | number;
  width?: string | number;
  block?: boolean | string;
  background?: boolean;
  error?: string;
}

export const TextArea = styled.div<TextAreaProps>`
  display: flex;
  flex-direction: column;

  width: ${props =>
    props.width ? props.width : props.block ? '100%' : '255px'};

  textarea {
    height: ${props => props.height || '40px'};
    width: ${props =>
      props.width ? props.width : props.block ? '100%' : '255px'};
    border: 1px solid
      ${props =>
        props.theme.darkMode ? props.theme.interface2 : props.theme.interface3};
    color: ${props =>
      props.theme.darkMode ? props.theme.interface4 : props.theme.interface5};
    font-size: 14px;
    padding: 12px;
    border-radius: 5px;

    ${props =>
      props.theme.darkMode &&
      css`
        background-color: ${props => props.theme.interface1};
      `}

    &::placeholder {
      color: ${props => props.theme.interface4};
    }

    &:focus {
      transition: border 0.2s ease-in-out;
      border: 1px solid ${props => props.theme.brand_primary_color};
    }

    ${props =>
      props.background &&
      css`
        background-color: ${props => props.theme.interface2};
      `}

    ${props =>
      props.error &&
      css`
        border-color: ${props => props.theme.error};
      `}
  }

  span {
    display: block;
    font-size: 10px;
    color: ${props => props.theme.error};
    position: relative;
    margin-top: 5px;
  }
`;
