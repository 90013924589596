import React from 'react';

import { Col } from 'react-bootstrap';
import ApplicationIcon from 'components/ApplicationIcon';

import { Card, Header, Name } from './styles';

const DatabaseCard = ({ db, selected, ...rest }) => {
  const name = db?.values.filter(v => v.slug === 'database_name')[0];

  return (
    <Col xl="6" {...rest}>
      <Card selected={selected}>
        <Header>
          <ApplicationIcon
            width="30px"
            height="30px"
            icon={`${db.type.slug}`}
            disable={db.is_disabled}
          />
          <Name>
            <span
              style={{
                width: '90%',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              }}
            >
              {name.value}
            </span>
            <span
              style={{
                fontSize: `${10}px`,
                fontStyle: 'italic',
              }}
            >
              {db.cloud_display}
            </span>
          </Name>
        </Header>
      </Card>
    </Col>
  );
};

export default DatabaseCard;
