import React from 'react';
import { Button } from '@cloudez/cloudez-design-system';

interface Props {
  closeToast?: any;
  callback: any;
  message: string;
}

const UnapliedChangesToast: React.FC<Props> = ({
  closeToast,
  callback,
  message,
}) => {
  return (
    <>
      <p
        style={{
          marginTop: '-4px',
          fontSize: '13px',
        }}
      >
        {message}
      </p>
      <div
        style={{
          marginTop: '6px',
          display: 'flex',
          justifyContent: 'space-evenly',
        }}
      >
        <Button
          success
          size="sm"
          onClick={e => {
            e.stopPropagation();
            callback(false);
            closeToast();
          }}
        >
          Continuar
        </Button>
        <Button
          outline
          error
          size="sm"
          onClick={e => {
            e.stopPropagation();
            callback(true);
            closeToast();
          }}
        >
          Descartar
        </Button>
      </div>
    </>
  );
};

export default UnapliedChangesToast;
