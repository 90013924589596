import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  align-items: center;

  gap: 18px;

  padding: 32px;

  span {
    font-weight: normal;
    font-size: 16px;
    line-height: 25px;

    color: #000000;
  }
`;
