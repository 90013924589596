import styled, { css } from 'styled-components';

interface IWrapperProps {
  isFocused?: boolean;
  tab?: number | string | boolean;
  selected?: boolean;
}

export const Wrapper = styled.div<IWrapperProps>`
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.interface1};
  margin-bottom: 10px;
  width: 100%;
  height: auto;

  padding: 25px;

  border-radius: 5px;

  p {
    font-size: 17px;
    color: ${props => props.theme.interface5};
  }

  border: 1px solid transparent;

  ${props =>
    !props.selected &&
    props.tab &&
    css`
      &:hover {
        transition: all 0.2s ease-in-out;
        cursor: pointer;
        border: 1px solid ${props => props.theme.brand_primary_color};
      }
    `}

  div.content {
    display: ${props => (props.selected ? 'block' : 'none')};
  }

  div.selection {
    display: flex;
    align-items: center;

    ${props =>
      props.selected &&
      css`
        margin-bottom: 30px;
      `}

    span {
      color: ${props => props.theme.interface5};
    }

    div.radio {
      width: 15px;
      height: 15px;

      border: 2px solid ${props => props.theme.interface4};
      border-radius: 50%;
      background: transparent;

      margin-right: 10px;

      ${props =>
        props.selected &&
        css`
          border: 2px solid ${props => props.theme.brand_primary_color};

          :after {
            content: '';
            background: ${props => props.theme.brand_primary_color};
            border-radius: 50%;
            width: 9px;
            height: 9px;
            position: relative;
            display: inline-block;
            top: -5.9px;
            left: 1px;
          }
        `}
    }
  }

  opacity: ${props => (props.isFocused ? '1' : '0.4')};

  .creditCardLoading {
    width: 100%;
    overflow: none;

    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      font-size: 40px;
      color: ${props => props.theme.brand_primary_color};
    }
  }

  @media (max-width: 768px) {
    width: 100% !important;
  }
`;

export const SaveCard = styled.span`
  font-family: 'Lato';
  font-weight: 400;
  font-size: 16px;

  color: ${props => props.theme.interface5};
`;

export const Total = styled.div`
  height: 100%;
  margin-right: 10px;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  & > span {
    color: ${props => props.theme.successNew};
    font-size: 28px;
    font-weight: 700;
  }

  & > p {
    color: ${props => props.theme.interface5};
    font-size: 16px;
    margin-bottom: 5px;
    margin-right: 7px;
  }
`;

export const Title = styled.h1`
  color: ${props => (props.theme.darkMode ? props.theme.interface5 : '#333')};
  font-size: 28px;

  margin-bottom: 25px;

  @media (max-width: 768px) {
    margin-top: 20px;
    font-size: 22px;
  }
`;

export const Info = styled.div`
  padding: 5px 10px;
  background: ${props => props.theme.interface2};

  border-radius: 999px;

  color: ${props => props.theme.interface5};
  font-size: 14px;
`;
