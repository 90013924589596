import React, { useEffect, useContext, useState, useRef } from 'react';
import { Col, Row } from 'react-bootstrap';
import queryString from 'query-string';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStar,
  faCloud,
  faAngleUp,
  faAngleDown,
} from '@fortawesome/free-solid-svg-icons';

import history from 'services/history';

import {
  Button,
  Pagination,
  AdvancedSearch,
} from '@cloudez/cloudez-design-system';
import { Title, Header } from 'components/Common';
import SearchBar from 'components/SearchBar';
import { ListingHeader, Listing, Ordering } from 'components/Listing/styles';

import EmptyListing from 'components/EmptyListing';

import { useDocTitle } from 'hooks/docTitle';
import { useLayout } from 'hooks/layout';
import InvoiceWarning from 'pages/Dashboard/InvoiceWarning';
import WebsiteCard from './WebsiteCard';
import WebsitePlaceholder from './Placeholder';
import {
  WebsiteListingContext,
  WebsiteListingProvider,
} from './_context/state';

const Performance = () => {
  const theme = useLayout();

  useDocTitle(`Performance — ${theme.name}`);

  const { loading, websites, count, previous, next, current, getWebsites } =
    useContext(WebsiteListingContext);

  const {
    page = 1,
    search: _search = '',
    filter = 'all',
  } = queryString.parse(history.location.search);

  const [filterValue, setFilterValue] = useState<any>(filter as any);
  const [cloudFilter, setCloudFilter] = useState(false);
  const [clouds, setClouds] = useState(null);
  const [orderValue, setOrderValue] = useState<any>();
  const [search, setSearch] = useState([]);

  const abortControllerRef = useRef<AbortController>(new AbortController());

  const getWebsitesCallback = (page, search, filter, clouds, order) => {
    abortControllerRef.current.abort();
    abortControllerRef.current = new AbortController();

    getWebsites(
      page,
      search,
      filter,
      clouds,
      order,
      abortControllerRef.current,
    );

    const _query = {
      page,
      search,
      filter,
      clouds,
    };

    if (!clouds) delete _query.clouds;
    if (!search) delete _query.search;
    if (filter === 'all') delete _query.filter;
    if (page === 1 || page === '1') delete _query.page;
    const query = queryString.stringify(_query);
    history.replace(`/performance?${query}`);
  };

  useEffect(() => {
    getWebsitesCallback(page, search, filter, clouds, orderValue);
    // eslint-disable-next-line
  }, []);

  const searchValue = searchValue => {
    getWebsitesCallback(1, searchValue, filterValue, clouds, orderValue);
  };

  const order = column => {
    const order = {
      value: orderValue?.value === 2 ? 1 : orderValue?.value === 1 ? 0 : 2,
      column,
    };
    setOrderValue(order);
    getWebsitesCallback(1, searchValue, filterValue, clouds, order);
  };

  return (
    <>
      <Header>
        <Title>Performance</Title>
        <Button
          disabled
          style={{
            cursor: 'unset',
            opacity: 0,
          }}
        />
      </Header>
      <InvoiceWarning />
      <SearchBar noInput>
        <AdvancedSearch
          style={{
            width: '400px',
          }}
          callback={searchValue}
          noResults={false}
          value={search}
          setValue={setSearch}
          fields={[
            {
              label: 'Qualquer campo',
              value: 'search',
            },
            {
              label: 'Domínio',
              value: 'domain',
            },
            {
              label: 'Cloud',
              value: 'cloud_fqdn',
            },
            {
              label: 'Nome do cloud',
              value: 'cloud_name',
            },
            {
              label: 'Apelido do cloud',
              value: 'nickname',
            },
            {
              label: 'Email',
              value: 'email',
            },
            {
              label: 'Usuário',
              value: 'username',
            },
          ]}
        />
      </SearchBar>

      {loading ? (
        <WebsitePlaceholder />
      ) : websites && websites.length ? (
        <>
          <ListingHeader>
            <Row>
              <Col xs="4" sm="2" md="3" lg="3">
                <p
                  onClick={() => {
                    order('domain');
                  }}
                >
                  Aplicação
                  <Ordering
                    status={
                      orderValue?.column === 'domain' ? orderValue?.value : 0
                    }
                  >
                    <FontAwesomeIcon icon={faAngleUp} />
                    <FontAwesomeIcon icon={faAngleDown} />
                  </Ordering>
                </p>{' '}
              </Col>

              <Col xs="6" sm="5" md="5" lg="4">
                <p>Otimizações</p>
              </Col>

              <Col sm="5" md="4" lg="4">
                <p>Última análise</p>
              </Col>

              <Col xl="1" />
            </Row>
          </ListingHeader>

          <Listing>
            {websites?.map(website => (
              <WebsiteCard key={website.id} website={website} />
            ))}
          </Listing>

          <Pagination
            onChangePage={getWebsitesCallback}
            count={count}
            previous={previous}
            next={next}
            current={current}
            search={search}
            pageSize={10}
            filter={filterValue}
            clouds={clouds}
            order={orderValue}
          />
        </>
      ) : null}
      {!loading && websites?.length === 0 && (
        <EmptyListing>
          <p>Nenhum website encontrado.</p>
          <Button onClick={() => history.push('/websites/create')} size="lg">
            Criar novo website
          </Button>
        </EmptyListing>
      )}
    </>
  );
};

export default props => (
  <WebsiteListingProvider>
    <Performance {...props} />
  </WebsiteListingProvider>
);
