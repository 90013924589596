import React from 'react';

import SearchInput from 'components/SearchInput';
import { Wrapper, FilterWrapper } from './styles';

interface ISearchBarProps {
  callback?: any;
  noResults?: boolean;
  value?: any;
  noInput?: boolean;
}

const SearchBar: React.FC<ISearchBarProps> = ({
  callback,
  noResults,
  value,
  children,
  noInput = false,
}) => {
  return (
    <Wrapper>
      {!noInput && (
        <SearchInput callback={callback} value={value} error={noResults} />
      )}

      {children}
    </Wrapper>
  );
};

export default SearchBar;
