import styled from 'styled-components';

export const HttpsText = styled.p`
  font-size: 14px;
  color: ${props => props.theme.interface5};
  line-height: 1.5;
  margin-bottom: 15px;
`;

export const Comparision = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;

  p {
    font-weight: 700;
    font-size: 24px;

    color: #a2a9bf;
  }
`;

export const Divisor = styled.div`
  border-left: 1px solid ${props => props.theme.interface3};
  margin-top: 33px;
`;

export const Infos = styled.div`
  font-size: 14px;
  color: ${props => props.theme.interface5};
  margin-top: 10px;

  p {
    margin-bottom: 5px;
  }

  p.alert {
    font-weight: bold;
    margin-bottom: 16px;
    color: ${props => props.theme.alertNew};
  }
`;

export const FinancialInfo = styled.div`
  border-left: 2px solid rgba(196, 196, 196, 0.5);

  width: 50%;
  height: 100%;

  /* margin:  0px -10px 0px; */

  padding-left: 18px;

  padding-top: 5px;
  padding-bottom: 5px;

  h5 {
    color: ${props => props.theme.brand_primary_color};
    font-size: 16px;
  }

  font-size: 14px;
  color: ${props => props.theme.interface5};
  font-weight: 700;

  div.infos,
  div.info {
    display: flex;
    align-items: flex-start;
  }

  div.infos {
    margin-top: 8px;
  }

  div.info {
    flex-direction: column;
  }

  div.info + div.info {
    margin-left: 10px;
  }

  span.label {
    font-weight: 400;
    font-size: 12px;
  }
`;

export const Help = styled.div`
  h5 {
    color: ${props => props.theme.interface5};
    font-weight: 700;
    font-size: 14px;
  }

  a {
    color: ${props => props.theme.brand_primary_color};
    font-size: 12px !important;
  }
`;
