import React from 'react';
import { Col } from 'react-bootstrap';

import Placeholder from 'components/Placeholder';

import { Card, Header, Name, Body } from '../WebsiteCard/styles';

const WebsitePlaceholder = () => {
  const placeholders = [];

  for (let i = 1; i <= 3; i++) {
    placeholders.push(i);
  }

  return (
    <>
      {placeholders.map((p, i) => (
        <Col xl="3" key={i}>
          <Card>
            <Header>
              <Placeholder circle width={30} height={30} />

              <Name>
                <span>
                  <Placeholder width={100} height={14} />
                </span>
                <span>
                  <Placeholder width={100} height={10} />
                </span>
              </Name>
            </Header>
            <Body>
              <Placeholder width={145} height={30} />
              <Placeholder width={145} height={30} />
            </Body>
          </Card>
        </Col>
      ))}
    </>
  );
};

export default WebsitePlaceholder;
