import React from 'react';

import CSVReader from 'react-csv-reader';
import { CSVLink } from 'react-csv';

import { faFileDownload, faPoll } from '@fortawesome/free-solid-svg-icons';
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Modal, Button } from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Footer,
} from 'components/ListingModal/styles';

import { Content } from './styles';

interface ImportCSVModalProps {
  show: boolean;
  setShow: (value: boolean) => void;
  action: any;
}

const ImportCSVModal = ({
  show,
  setShow,
  action,
}: ImportCSVModalProps): JSX.Element => {
  const csvData = [
    ['email1', 'senha1'],
    ['email2', 'senha2'],
    ['email3', 'senha3'],
    ['email3', 'senha3'],
    ['.'],
    ['.'],
    ['.'],
    ['emailN', 'senhaN'],
  ];

  const parseOptions = {
    header: false,
    dynamicTyping: true,
    skipEmptyLines: true,
  };

  const onButtonClick = async () => {
    const input = document.getElementById('ObiWan');
    await input.click();
  };

  return (
    <>
      <div style={{ display: 'none' }}>
        <CSVReader
          parserOptions={parseOptions}
          inputId="ObiWan"
          inputName="ObiWan"
          onFileLoaded={(data, fileInfo) => {
            action(data, fileInfo);
            setShow(false);
          }}
        />
      </div>
      <Modal width="562px" show={show} setShow={setShow}>
        <Header>
          <Icon>
            <FontAwesomeIcon icon={faPoll} />
          </Icon>
          <Text>
            <Title>Importar contas</Title>
            <Subtitle>
              Preechimento dos campos através de um arquivo .CSV
            </Subtitle>
          </Text>
        </Header>

        <Content>
          <span>
            Pra importar múltiplas contas ,você pode utilizar um arquivo CSV com
            o conteúdo seguindo o modelo abaixo:
          </span>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
            <div className="model">
              <span>email1,senha1</span>
              <span>email2,senha2</span>
              <span>email3,senha3</span>
              <span>email3,senha3</span>
              <span>.</span>
              <span>.</span>
              <span>.</span>
              <span>emailN,senhaN</span>
            </div>
            <div className="downloadModel">
              <FontAwesomeIcon icon={faFileDownload} />
              <CSVLink
                data={csvData}
                filename="modelo.csv"
                className="btn btn-primary"
                target="_blank"
              >
                <span>Download do Modelo</span>
              </CSVLink>
            </div>
          </div>
          <span style={{ width: '100%', marginTop: '21px' }}>
            <b>Observação:</b> O arquivo não pode ter cabeçalho
          </span>
        </Content>
        <Footer hasLink>
          <Button outline onClick={() => setShow(false)} text>
            FECHAR
          </Button>
          <Button onClick={onButtonClick} icon>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                fontWeight: 'bold',
                fontSize: '16px',
                lineHeight: '18px',
                textTransform: 'none',
                gap: '5px',
                alignItems: 'center',
              }}
            >
              Importar CSV
              <FontAwesomeIcon icon={faPlusSquare} />
            </div>
          </Button>
        </Footer>
      </Modal>
    </>
  );
};

export default ImportCSVModal;
