import styled, { css } from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px 30px 0px;
  color: ${props => props.theme.interface1};

  .title {
    color: ${props =>
      props.theme.darkMode
        ? props.theme.interface4
        : props.theme.interface7} !important;
  }

  .breadcrumbs {
    margin-bottom: 10px;
  }

  span {
    font-weight: 300;

    .new {
      font-weight: bold;
    }
  }

  svg {
    color: ${props =>
      props.theme.darkMode
        ? props.theme.interface4
        : props.theme.interface7} !important;
  }
`;

export const TicketWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 24px;

  @media (max-width: 1100px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const TicketFooter = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px 24px;

  border-top: 1px solid ${props => props.theme.interface4};

  display: flex;
  align-items: center;
  justify-content: space-between;

  textarea {
    width: 80%;
    max-width: 80%;
    min-height: 50px;
  }

  svg {
    cursor: pointer;
    color: ${props => props.theme.interface4};
    font-size: 16px;
  }
`;

export const TicketForm = styled.div`
  width: 66%;
  height: 100%;
  background: ${props => props.theme.interface1};
  border-radius: 5px;
  padding: 24px 15px;

  @media (max-width: 1100px) {
    width: 100%;
  }

  .label {
    margin-bottom: 24px;
    font-weight: bold;
    line-height: 24px;
    font-size: 16px;
    line-height: 24px;
    color: ${props => props.theme.interface6};
  }

  label {
    text-transform: none;

    font-weight: bold;
    color: ${props => props.theme.interface5};
    font-size: 14px;
  }

  ul {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-left: 20px;
    margin-bottom: 24px;

    list-style-type: disc;

    li {
      font-weight: normal;
      color: ${props => props.theme.interface5};
      font-size: 14px;
      line-height: 20px;
    }
  }

  .instruction {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 16px;
    line-height: 14px;
    color: ${props => props.theme.interface6};
  }

  .css-1okebmr-indicatorSeparator {
    display: none;
  }

  .css-tlfecz-indicatorContainer,
  .css-1gtu0rj-indicatorContainer {
    svg {
      color: ${props => props.theme.brand_primary_color};
    }
  }
`;

export const TicketHelp = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 18px;
  width: 365px;
  height: auto;
  background-color: ${props => props.theme.interface1};
  border-radius: 5px;

  .title {
    margin-bottom: 24px;
    color: ${props => props.theme.interface6};
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
  }

  .empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    height: 60%;

    span {
      color: ${props => props.theme.interface5};
      font-weight: 300;
      width: 100%;
      text-align: center;
    }
  }

  .helpList {
    padding: 0;

    ul {
      display: flex;
      flex-direction: column;
      gap: 20px;
      margin-left: 20px;

      list-style-type: disc;

      li {
        font-weight: bold;
        color: ${props => props.theme.brand_primary_color};
        text-decoration-line: underline;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }

  img {
    width: 200px;
    height: 200px;
  }

  input {
    width: 326px;
  }

  h1 {
    color: ${props => props.theme.brand_primary_color};
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  h3 {
    color: ${props => props.theme.interface5};
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 1.4;
  }

  .css-1okebmr-indicatorSeparator {
    display: none;
  }

  .css-tlfecz-indicatorContainer,
  .css-1gtu0rj-indicatorContainer {
    svg {
      color: ${props => props.theme.brand_primary_color};
    }
  }
`;

export const Label = styled.span`
  font-size: 12px;
  color: ${props => props.theme.interface4};
  margin-bottom: 8px;
`;

export const AttachmentBox = styled.div`
  margin-top: 5px;
  border: 1px dashed ${props => (props.theme.darkMode ? '#ffffff' : '#929292')};
  background: ${props => props.theme.interface2};
  padding: 12px;
  text-align: center;
  cursor: pointer;

  .fileContainer {
    display: flex;
    flex-direction: row;
    gap: 5px;

    .fileBox {
      display: flex;
      flex-direction: row;
      align-items: center;
      background-color: ${props =>
        props.theme.darkMode ? '#000000' : '#d6d6d6'};
      padding: 0 10px;
      border-radius: 5px;
      gap: 20px;

      span {
        color: ${props => (props.theme.darkMode ? '#929292' : '#000000')};
      }

      svg {
        font-size: 12px;
        color: #929292;
      }
    }
  }

  transition: filter 0.4s;

  &:hover {
    filter: brightness(0.95);
  }

  span {
    color: ${props => (props.theme.darkMode ? '#ffffff' : '#000000')};

    font-weight: normal;
    font-size: 14px;

    &:hover {
      filter: brightness(0.9);
    }
  }
`;

interface IMessageInputProps {
  file?: any;
  disable?: boolean;
}

export const MessageInput = styled.div<IMessageInputProps>`
  width: 100%;
  height: 100%;
  margin-bottom: 32px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  .textarea {
    width: 100%;

    .warning {
      font-weight: normal;
      font-size: 10px;
      line-height: 20px;
    }

    textarea {
      min-height: 100px;
      resize: none;
    }
  }

  svg {
    cursor: pointer;
    /* color: ${props => props.theme.interface4}; */
    font-size: 16px;
  }
`;

export const DomainIcon = styled.span<IMessageInputProps>`
  svg {
    color: ${props => props.theme.brand_primary_color};
  }

  ${props =>
    props.disable &&
    css`
      svg {
        color: ${props => props.theme.interface4};
      }
    `}
`;

interface FlexboxProps {
  flexDir?: 'column' | 'row';
  maxWidth?:
    | '100%'
    | '90%'
    | '80%'
    | '70%'
    | '50%'
    | '40%'
    | '30%'
    | '20%'
    | '10%';
  alignItems?: 'stretch' | 'center' | 'flex-start' | 'flex-end' | 'baseline';
  w?:
    | '100%'
    | '90%'
    | '80%'
    | '70%'
    | '60%'
    | '50%'
    | '40%'
    | '30%'
    | '20%'
    | '10%';
  justify?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  mt?: string;
  spacing?: string;
}

export const Flexbox = styled.div<FlexboxProps>`
  display: flex;
  flex-direction: ${props => (props.flexDir ? props.flexDir : 'row')};
  justify-content: ${props => (props.justify ? props.justify : 'flex-start')}
  align-items: ${props => (props.alignItems ? props.alignItems : 'stretch')}
  max-width: ${props => (props.maxWidth ? props.maxWidth : '100%')}
  width: ${props => (props.w ? props.w : 'auto')}
  margin-top: ${props => (props.mt ? props.mt : '0px')}
  gap: ${props => (props.spacing ? props.spacing : '20px')};


   @media (max-width: 768px) {
     max-width: 100%;
     flex-direction: column;

     button {
       margin-top: 0px !important;
     }
   }

 
`;
