import React from 'react';
import { Col, Row } from 'react-bootstrap';

import Placeholder from 'components/Placeholder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumbs, Breadcrumb } from 'components/Common';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { TextArea } from '@cloudez/cloudez-design-system';
import {
  Header,
  Title,
  TicketFooter,
  TicketWrapper,
  TicketHeader,
  UserInfo,
  Updates,
  TicketTitle,
  Messages,
} from '../styles';

const TicketDetailPlaceholder = () => {
  return (
    <>
      <Header>
        <Row>
          <Col md="8">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Title>
                <Breadcrumbs>
                  <Breadcrumb>
                    <Placeholder width={40} height={10} />
                  </Breadcrumb>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <Breadcrumb>
                    <Placeholder width={60} height={10} />
                  </Breadcrumb>
                </Breadcrumbs>

                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                >
                  <div
                    style={{
                      marginRight: '10px',
                    }}
                  >
                    <Placeholder width={30} height={30} />
                  </div>
                  <Placeholder width={140} height={30} />
                </div>
              </Title>
            </div>
          </Col>

          <Col
            md="4"
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              width: '100%',
            }}
          >
            <Placeholder width={200} height={40} />
          </Col>
        </Row>
      </Header>

      <TicketWrapper>
        <TicketHeader>
          <UserInfo>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <TicketTitle>
                <Placeholder width={200} height={14} />
              </TicketTitle>
            </div>
          </UserInfo>

          <Updates>
            <p>
              Área <br />
              <span>
                <Placeholder width={50} height={11} />
              </span>
              <br />
              <span
                style={{
                  opacity: 0,
                }}
              >
                asd
              </span>
            </p>
            <p>
              Criado em
              <span>
                <Placeholder width={40} height={11} />
                <span>
                  <Placeholder width={50} height={9} />
                </span>
              </span>
              <span
                style={{
                  opacity: 0,
                }}
              >
                asd
              </span>
            </p>
            <p>
              Atualizado em
              <span>
                <Placeholder width={40} height={11} />
                <span>
                  <Placeholder width={50} height={9} />
                </span>
              </span>
              <span
                style={{
                  opacity: 0,
                }}
              >
                asd
              </span>
            </p>
          </Updates>
        </TicketHeader>

        <Messages>
          <Placeholder width="100%" height={220} />
          <div
            style={{
              alignSelf: 'flex-end',
              marginTop: '30px',
              width: '100%',
            }}
          >
            <Placeholder height={200} />
          </div>
        </Messages>

        <TicketFooter>
          <Placeholder width={43} height={16} />
          <TextArea
            disabled
            background
            placeholder="Digite sua mensagem aqui.."
          />
          <Placeholder width={130} height={40} />
        </TicketFooter>
      </TicketWrapper>
    </>
  );
};

export default TicketDetailPlaceholder;
