/* eslint-disable react/state-in-constructor */
import { faTimesCircle } from '@fortawesome/free-regular-svg-icons';
import {
  faArrowLeft,
  faCheck,
  faCopy,
  faRedo,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@cloudez/cloudez-design-system';
import React, { Component, useCallback, useState } from 'react';
import { logError } from 'utils/logError';
import notifyGchat from 'utils/notifyGchat';
import { Container, ErrorBox, Icon, Title, Card } from './styles';

const FallbackComponent: React.FC<{ redirect: any }> = ({ redirect }) => {
  return (
    <Container>
      <Card>
        <Title>
          <Icon className="mr-2" icon={faTimesCircle} />
          Ocorreu um erro
        </Title>
        <h4
          style={{
            maxWidth: '500px',
            margin: '10px 0',
          }}
        >
          Nós já fomos notificados automaticamente e estamos trabalhando no
          erro. Por favor, recarregue a página e tente novamente. Se o erro
          persistir, entre em contato com nosso time de suporte.
        </h4>
        <Button
          success
          onClick={() => window.location.reload()}
          className="mb-3"
          block
          width="500px"
        >
          <FontAwesomeIcon icon={faRedo} className="mr-2" />
          Recarregar
        </Button>
        <Button onClick={redirect}>
          <FontAwesomeIcon icon={faArrowLeft} className="ml-0 mr-2" />
          Painel
        </Button>
      </Card>
    </Container>
  );
};

interface State {
  error: any;
  errorInfo: any;
  hasError: boolean;
}

class ErrorBoundary extends React.Component {
  state: State = { error: null, errorInfo: null, hasError: false };

  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null, hasError: false };

    this.redirect = this.redirect.bind(this);
  }

  componentDidCatch(error, errorInfo) {
    logError(error, errorInfo);
    notifyGchat(
      `Error: ${error.message} ${errorInfo.componentStack.toString()}`,
      'react-bug',
    );

    this.setState({
      hasError: !!error,
      error,
      errorInfo,
    });
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  redirect() {
    this.setState(state => {
      return { ...state, hasError: false, error: null, errorInfo: null };
    });
  }

  render() {
    if (this.state.errorInfo && this.state.hasError) {
      return <FallbackComponent redirect={this.redirect} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
