import styled, { css } from 'styled-components';

interface IcardProps {
  resource: any;
  gap?: string;
  selected?: boolean;
  cloud: any;
}

export const Card = styled.div<IcardProps>`
  ${props =>
    props.resource &&
    css`
      cursor: pointer;
    `}
  max-height: 70px;
  min-width: 512px;
  width: 100%;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #e8eaef;
  margin-bottom: 20px;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;

  .iconRemove {
    margin-left: 20px;
    cursor: pointer;
  }

  .infosContent {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;

    span {
      max-width: 100%;
      font-size: 12px;
    }

    .domain {
      width: 160px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  ${props =>
    props.selected &&
    props.resource &&
    css`
      border: 1px solid ${props => props.theme.brand_primary_color};
    `}

  &:hover {
    ${props =>
      props.resource &&
      css`
      transition: all 0.2s ease-in;
      border: 1px solid ${props => props.theme.brand_primary_color};};
    `}
  }

  span {
    font-size: 14px;
    color: ${props => props.theme.interface5};
  }

  p {
    font-weight: bold;
    font-size: 12px;
    line-height: 16px;
    color: ${props => props.theme.interface5};
  }
`;

export const Content = styled.div`
  display: flex;
`;

export const Logo = styled.div`
  padding: 0px 10px;
  width: 100%;
  /* width: 50%; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* border-right: 1px solid ${props => props.theme.interface4}; */
`;
