import React, { useState, useRef, useEffect, useMemo } from 'react';
import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import InputMask from 'react-input-mask';

import { Modal, Button } from '@cloudez/cloudez-design-system';

import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSpinner } from '@fortawesome/free-solid-svg-icons';

import {
  Header,
  Icon,
  Text,
  Title,
  Content,
  Footer,
} from 'components/ListingModal/styles';
import { useLayout } from 'hooks/layout';
import { useAuth } from 'hooks/auth';

const UpdateBilling: React.FC = () => {
  const theme = useLayout();
  const history = useHistory();
  const { skipUpdateBilling } = useAuth();

  return (
    <Modal show noExit>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faEdit} size="2x" />
        </Icon>
        <Text>
          <Title>Atualizar Cadastro</Title>
        </Text>
      </Header>

      <Content>
        <p
          style={{
            fontWeight: 'normal',
            fontSize: 16,
            color: theme.interface5,
          }}
        >
          Notamos que já tem um tempo que você está com a gente e precisamos
          confirmar alguns dos seus dados! <br />
          Clique em <b>Atualizar</b> para confirmar as informações do seu
          cadastro.
        </p>
      </Content>
      <Footer hasLink>
        <Button
          type="button"
          outline
          onClick={() => {
            skipUpdateBilling();
            history.push('/dashboard');
          }}
        >
          Cancelar
        </Button>
        <Button
          onClick={() => {
            skipUpdateBilling();
            history.push('/account?tab=2');
          }}
        >
          Atualizar
        </Button>
      </Footer>
    </Modal>
  );
};

export default UpdateBilling;
