/* eslint-disable prettier/prettier */
import React, { Dispatch, SetStateAction, useMemo } from 'react';

import history from 'services/history';

import {
  faBarcode,
  faLightbulb,
  faPowerOff,
} from '@fortawesome/free-solid-svg-icons';
import { faUser } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLayout } from 'hooks/layout';
import { useAuth } from 'hooks/auth';
import { Wrapper, Links } from './styles';

interface IDropdownProps {
  setDropdown: Dispatch<SetStateAction<boolean>>;
  dropdown: boolean;
}

const Dropdown: React.FC<IDropdownProps> = ({ setDropdown, dropdown }) => {
  const { signOut, user } = useAuth();

  const invoiceRoles = useMemo(
    () => ['financial_role', 'customer_success_role'],
    [],
  );

  const rolesToSkip = useMemo(
    () => ['company_owner_role', 'customer_role', 'god_role'],
    [],
  );

  return (
    <div
      style={{
        position: 'absolute',
        zIndex: 2,
      }}
    >
      <div
        style={{
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        }}
        onClick={() => setDropdown(false)}
      />
      <Wrapper>
        <Links>
          <li
            onClick={() => {
              history.push('/account');
              setDropdown(!dropdown);
            }}
          >
            <FontAwesomeIcon icon={faUser} />
            Sua conta
          </li>
          {(invoiceRoles.some(role => user?.roles?.includes(role)) ||
            user?.roles?.some(role => rolesToSkip.includes(role)) ||
            user?.roles?.some(role => role.includes('admin_'))) && (
            <li
              onClick={() => {
                history.push('/invoices');
                setDropdown(!dropdown);
              }}
            >
              <FontAwesomeIcon icon={faBarcode} />
              Faturas
            </li>
          )}
          <li onClick={() => signOut()}>
            <FontAwesomeIcon icon={faPowerOff} />
            Sair
          </li>
        </Links>
      </Wrapper>
    </div>
  );
};

export default Dropdown;
