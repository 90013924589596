import queryString from 'query-string';
import { Reducer } from 'react';
import { IActionPayload } from 'types';
import { createPagination } from '@cloudez/cloudez-design-system';

const reducer: Reducer<any, IActionPayload> = (state, action): any => {
  switch (action.type) {
    case 'GET_TICKETS':
      const tickets = action.payload.results;

      const pagination = createPagination(action.payload, 10);

      return {
        ...state,
        ...pagination,
        tickets,
      };

    case 'UPDATE_TICKET': {
      return {
        ...state,
        tickets: state?.tickets?.map(ticket =>
          ticket.id === action.payload.id ? action.payload : ticket,
        ),
      };
    }

    case 'SET_LOADING': {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case 'SET_ERRORS': {
      return {
        ...state,
        errors: action.payload,
      };
    }

    case 'SET_RED_ALERT': {
      return {
        ...state,
        tickets: state?.tickets?.map(ticket =>
          ticket.id === action.payload.id
            ? { ...ticket, is_red_alert: action.payload.value }
            : ticket,
        ),
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
