export default (state, action) => {
  switch (action.type) {
    case 'UPDATE_FIELD':
    case 'UPDATE_NEW_DOMAIN':
      return {
        ...state,
        newDomain: action.payload,
      };

    case 'SET_LOADING': {
      return {
        ...state,
        updateLoading: action.payload,
      };
    }

    case 'SET_ERRORS': {
      return {
        ...state,
        errors: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
