import React, { useState, useContext } from 'react';

import { Input, Error } from '@cloudez/cloudez-design-system';

import { Tab, Tabs } from 'components/Tabs';

import DomainBuy from 'components/DomainBuy';
import { Wrapper, Card } from './styles';
import { DomainCreateContext } from '../_context/state';

const Domain = ({ buyDomain, setBuyDomain, errors }) => {
  const { newDomain, updateField } = useContext(DomainCreateContext);

  const [tab, setTab] = useState(1);
  const [domain, setDomain] = useState('');

  return (
    <Wrapper>
      <Tabs>
        <Tab
          active={tab === 1}
          onClick={() => {
            setTab(1);
          }}
        >
          Sim, vou usar um meu
        </Tab>
        <Tab
          active={tab === 2}
          onClick={() => {
            setTab(2);
          }}
        >
          Não, vou comprar um domínio
        </Tab>
      </Tabs>
      <Card>
        {tab === 1 && (
          <>
            <Input
              block
              placeholder="Digite seu domínio..."
              name="domain"
              value={newDomain.domain}
              onChange={e => {
                const value = e.target.value.toLowerCase();
                updateField('domain', value);
              }}
              error={!!errors?.domain || !!errors?.domain?.domain}
            />
            {(errors?.domain || errors?.domain?.domain) && (
              <Error>{errors?.domain[0] || errors?.domain?.domain}</Error>
            )}
          </>
        )}

        {tab === 2 && (
          <DomainBuy
            domain={domain}
            setDomain={setDomain}
            setBuyDomain={setBuyDomain}
            buyDomain={buyDomain}
          />
        )}
      </Card>
    </Wrapper>
  );
};

export default Domain;
