import React, { useEffect, useState, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';

import { Row, Col } from 'react-bootstrap';
import Swipe from 'react-easy-swipe';

import { ThemeContext } from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faChevronLeft,
  faEdit,
  faSpinner,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';

import { Button, Arrows } from '@cloudez/cloudez-design-system';

import { Breadcrumbs, Breadcrumb } from 'components/Common';
import ApplicationIcon from 'components/ApplicationIcon';
import Ticket from 'components/Ticket';

import {
  Header,
  Title,
  Tabs,
  Tab,
  TabContent,
  Card,
  CardHeader,
} from 'components/Details';

import {
  getCloudsService,
  getAddonsService,
  getCloudUsersService,
} from 'services/cloud';
import { answerSurveyService, getSurveySlugService } from 'services/survey';
import history from 'services/history';

import usePersistedState from 'hooks/usePersistedState';
import { useDocTitle } from 'hooks/docTitle';
import { useAuth } from 'hooks/auth';

import { CloudAddon, CloudUser } from 'types/cloud';

import { Hover, HoverText } from 'components/Hover';
import { CloudDetailProvider, CloudDetailContext } from './_context/state';

import ChangePlanModal from './ChangePlanModal';
import ChangeNickname from './ChangeNickname';
import { Informations } from './Informations';
import RemoveModal from './RemoveModal';
import { Resources } from './Resources';
import Placeholder from './Placeholder';
import { Graphics } from './Graphics';
import ChurnModal from './ChurnModal';
import { Details } from './Details';
import Addons from './Addons';
import Users from './Users';
import { Websites } from './Websites';
import { Emails } from './Emails';
import { Pulsate } from './styles';
import { CloudListingContext } from '../CloudListing/_context/state';

const CloudDetail = ({ match }) => {
  const { user } = useAuth();
  const { cloud, getCloud, deleteCloud } = useContext(CloudDetailContext);
  const { clouds } = useContext(CloudListingContext);
  const theme = useContext(ThemeContext);

  const [addons, setAddons] = useState<CloudAddon[]>(null);
  const [users, setUsers] = useState<CloudUser[]>(null);
  const [tab, setTab] = usePersistedState('@cloudez-app-v2/cloudTab', 1);
  const [removeModal, setRemoveModal] = useState(false);
  const [changePlanModal, setChangePlanModal] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [churnModal, setChurnModal] = useState(false);
  const [survey, setSurvey] = useState(null);
  const [addonLoading, setAddonLoading] = useState(true);
  const [userLoading, setUserLoading] = useState(true);
  const [partnerCanChurn] = useState(
    user.is_company_owner && user.owned_companies.includes(theme.code),
  );
  const [configrCanChurn] = useState(
    user.company_slug === 'configr' && theme.slug === 'configr',
  );

  const cloudInfo = cloud?.status_timeline[cloud.status_timeline.length - 1];

  const [changeNicknameModal, setChangeNicknameModal] = useState(false);

  useEffect(() => {
    const { id } = match.params;
    getCloud(id);

    async function getSurvey() {
      let surveySlug;
      if (configrCanChurn) surveySlug = 'churn-reason';
      else if (partnerCanChurn) surveySlug = 'partners-churn';

      try {
        const { data } = await getSurveySlugService(surveySlug);
        setSurvey(data);
      } catch (e) {
        console.log(e);
      }
    }
    if (configrCanChurn || partnerCanChurn) getSurvey();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function getAddons() {
      setAddonLoading(true);
      try {
        const { data } = await getAddonsService({
          cloud: cloud.id,
          name: '',
        });

        setAddonLoading(false);
        setAddons(data);
      } catch (e) {
        setAddonLoading(false);
        console.log(e);
      }
    }

    async function getUsers() {
      try {
        setUserLoading(true);
        const { data } = await getCloudUsersService({
          cloud: cloud.id,
          name: '',
          username: '',
        });
        setUsers(data);
        setUserLoading(false);
      } catch (e) {
        setUserLoading(false);
        console.log(e);
      }
    }

    if (cloud && !users && !addons) {
      getAddons();
      getUsers();
    }
    // eslint-disable-next-line
  }, [cloud]);

  const _deleteCloud = async () => {
    await deleteCloud(cloud.id, '');
  };

  const deleteCloudHandle = async () => {
    try {
      setRemoveLoading(true);
      const { data } = await getCloudsService();
      if ((configrCanChurn || partnerCanChurn) && data.count <= 1) {
        setRemoveLoading(false);
        setChurnModal(true);
      } else {
        setRemoveLoading(false);
        setRemoveModal(true);
      }
    } catch (e) {
      setRemoveLoading(false);
    }
  };

  const answerSurvey = async payload => {
    const data = {
      slug: survey.slug,
      answers: [],
    };

    for (const item in payload) {
      data.answers = [
        ...data.answers,
        { question_id: item, answer: payload[item] },
      ];
    }

    try {
      await answerSurveyService(data);

      if (partnerCanChurn) {
        history.push('/suporte');
      } else if (configrCanChurn) {
        _deleteCloud();
      }
    } catch (e) {
      console.log(e);
    }
  };

  useDocTitle(
    cloud ? `${cloud?.nickname || cloud?.name} — Cloud` : 'Clouds — Cloudez',
  );

  const icon = useMemo(() => {
    if (cloud) {
      if (
        cloud.cloud_provider.slug === 'partner' ||
        cloud.cloud_provider.slug === 'partner-g2' ||
        cloud.cloud_provider.slug.includes('partner-g3') ||
        cloud.cloud_provider.slug.includes('partner-g4')
      ) {
        return 'cloudez-footer';
      }

      if (
        cloud.cloud_provider.slug === 'configr-g3' ||
        cloud.cloud_provider.slug === 'configr-g4' ||
        cloud.cloud_provider.slug === 'configr-g5' ||
        cloud.cloud_provider.slug.includes('dpg-custom')
      ) {
        return 'configr';
      }

      return cloud.cloud_provider.slug;
    }
  }, [cloud]);

  return cloud ? (
    <>
      {churnModal && (
        <ChurnModal
          survey={survey}
          action={answerSurvey}
          show={churnModal}
          setShow={() => setChurnModal(false)}
          partnerCanChurn={partnerCanChurn}
          // configrCanChurn={configrCanChurn}
        />
      )}
      {removeModal && (
        <RemoveModal show={removeModal} setShow={() => setRemoveModal(false)} />
      )}
      {changeNicknameModal && (
        <ChangeNickname
          show={changeNicknameModal}
          setShow={() => setChangeNicknameModal(false)}
        />
      )}
      {changePlanModal && (
        <ChangePlanModal
          show={changePlanModal}
          setShow={setChangePlanModal}
          cloud={cloud}
        />
      )}
      <Header>
        <Row>
          <Col md="10" sm="9">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ApplicationIcon width="64px" height="64px" icon={icon} />
              <Title>
                <Breadcrumbs>
                  <Breadcrumb>
                    <Link to="/dashboard">Home</Link>
                  </Breadcrumb>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <Breadcrumb>
                    <Link to="/clouds">Clouds</Link>
                  </Breadcrumb>
                </Breadcrumbs>
                <h3>
                  {cloud.nickname || cloud.name}
                  <FontAwesomeIcon
                    style={{
                      marginLeft: '10px',
                      cursor: 'pointer',
                      color: theme.brand_primary_color,
                    }}
                    icon={faEdit}
                    onClick={() => setChangeNicknameModal(true)}
                  />
                  <br />
                  {cloud.soft_block || cloud.hard_block
                    ? 'Cloud Bloqueada'
                    : ''}
                </h3>
                {cloud.nickname && <p>Cloud: {cloud.name}</p>}
              </Title>
            </div>
          </Col>
          <Col
            className="d-none d-sm-flex d-md-flex d-xl-flex"
            sm="3"
            md="2"
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              icon={removeLoading}
              disabled={removeLoading}
              block
              outline
              error
              remove
              onClick={deleteCloudHandle}
            >
              {removeLoading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                <>Excluir cloud</>
              )}
            </Button>
          </Col>
        </Row>
      </Header>

      <Row
        style={{
          marginBottom: `${15}px`,
        }}
      >
        <Col xl="7">
          <Row style={{ marginBottom: `${30}px` }}>
            <Resources changePlan={setChangePlanModal} cloud={cloud} />
            <Informations cloud={cloud} />
          </Row>
          <Graphics cloudInfo={cloudInfo} cloud={cloud} />
        </Col>
        <Col xl="5">
          <Card>
            <CardHeader>Tickets</CardHeader>
            <Row>
              <Col xs>
                <Ticket type="1" id={cloud.id} />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Tabs>
            <Tab active={tab === 1} onClick={() => setTab(1)}>
              Detalhes
            </Tab>
            <Tab active={tab === 2} onClick={() => setTab(2)}>
              {Number(cloudInfo?.mem) > 80 ||
              Number(cloudInfo?.disk) > 80 ||
              Number(cloudInfo?.cpu) > 80 ? (
                <>
                  <Pulsate>
                    Websites
                    <Hover>
                      <div className="icon">
                        <div />

                        <FontAwesomeIcon
                          style={{ width: '20px', height: '20px' }}
                          icon={faTriangleExclamation}
                        />
                      </div>

                      <HoverText
                        standby
                        style={{
                          textAlign: 'left',
                          width: '385px',
                          textTransform: 'none',
                        }}
                      >
                        <p>Cloud sobrecarregado</p>O excesso de apps no mesmo
                        cloud pode leva-lo à sobrecarga, confira quantos apps
                        estão no seu cloud e se necessário, ative um novo cloud
                        e use o botão de isolar para migrá-los.
                      </HoverText>
                    </Hover>
                  </Pulsate>
                </>
              ) : (
                'Websites'
              )}
            </Tab>
            <Tab active={tab === 3} onClick={() => setTab(3)}>
              {Number(cloudInfo?.mem) > 80 ? (
                <>
                  <Pulsate>
                    Emails
                    <Hover>
                      <div className="icon">
                        <div />

                        <FontAwesomeIcon
                          style={{ width: '20px', height: '20px' }}
                          icon={faTriangleExclamation}
                        />
                      </div>

                      <HoverText
                        standby
                        style={{
                          textAlign: 'left',
                          width: '385px',
                          textTransform: 'none',
                        }}
                      >
                        <p>Cloud sobrecarregado</p>O excesso de apps no mesmo
                        cloud pode leva-lo à sobrecarga, confira quantos apps
                        estão no seu cloud e se necessário, ative um novo cloud
                        e use o botão de isolar para migrá-los.
                      </HoverText>
                    </Hover>
                  </Pulsate>
                </>
              ) : (
                'Emails'
              )}
            </Tab>
            <Tab active={tab === 4} onClick={() => setTab(4)}>
              Addons
            </Tab>
            <Tab active={tab === 5} onClick={() => setTab(5)}>
              Usuários
            </Tab>
          </Tabs>

          <Arrows
            icon={faChevronLeft}
            left
            onClick={() => tab === 2 && setTab(tab - 1)}
            actions
            disabled={tab === 1}
          />
          <Swipe
            onSwipeRight={() => tab === 2 && setTab(tab - 1)}
            onSwipeLeft={() => tab === 1 && setTab(tab + 1)}
            tolerance={100}
          >
            <TabContent>
              {(() => {
                switch (tab) {
                  case 1:
                    return <Details cloud={cloud} />;
                  case 2:
                    return <Websites cloudInfo={cloudInfo} cloud={cloud} />;
                  case 3:
                    return <Emails cloudInfo={cloudInfo} cloud={cloud} />;
                  case 4:
                    return (
                      <Addons
                        addonLoading={addonLoading}
                        addons={addons}
                        setAddons={setAddons}
                        cloud={cloud.id}
                      />
                    );
                  case 5:
                    return (
                      <Users
                        users={users}
                        setUsers={setUsers}
                        cloud={cloud.id}
                        userLoading={userLoading}
                      />
                    );
                    return;
                  default:
                    return null;
                }
              })()}
            </TabContent>
          </Swipe>
          <Arrows
            icon={faChevronRight}
            right
            onClick={() => tab === 1 && setTab(tab + 1)}
            actions
            disabled={tab === 2}
          />
          <Row
            style={{
              marginTop: '20px',
            }}
          >
            <Col xs="12" className="d-flex d-sm-none">
              <Button
                icon={removeLoading}
                disabled={removeLoading}
                width="100%"
                outline
                error
                remove
                onClick={deleteCloudHandle}
              >
                {removeLoading ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  <>Excluir cloud</>
                )}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  ) : (
    <Placeholder />
  );
};

export default props => (
  <CloudDetailProvider>
    <CloudDetail {...props} />
  </CloudDetailProvider>
);
