import styled, { css } from 'styled-components';

export const Container = styled.nav`
  position: fixed;
  top: 0;
  bottom: 0;
  grid-area: sidebar;
  background: ${props => props.theme.brand_primary_color};
  z-index: 999999999999;
  width: 100%;

  &:hover {
    width: 210px;
    transition: width 0.2s ease;
  }

  transition: width 0.2s ease;

  ${props =>
    props.theme.sideMenuCollapsed
      ? css`
          width: 100px;

          &:hover {
            width: 210px;

            ${MenuItem} {
              span {
                opacity: 1;
              }

              .card {
                padding: 8px 26px !important;
              }
            }

            ${NewBadge} {
              opacity: 1;
            }
          }
        `
      : css`
          width: 210px;

          ${MenuItem} {
            span {
              opacity: 1;
            }
          }

          ${NewBadge} {
            opacity: 1;
          }
        `}

  @media (max-width: 768px) {
    transition: all 0.2s ease-in-out;
    transform: ${({ theme: { sideMenuCollapsed } }) =>
      sideMenuCollapsed && 'translateX(-210px)'};
  }
`;

interface IMenuItemProps {
  isActive?: boolean;
  referral?: boolean;
  apoio?: boolean;
  language?: boolean;
  first?: boolean;
  last?: boolean;
  disabled?: boolean;
  logout?: boolean;
}

export const MenuItem = styled.li<IMenuItemProps>`
  position: relative;
  width: 100%;
  height: 42px;
  line-height: 42px;

  ${({ referral }) =>
    referral &&
    css`
      margin-top: 40px;
      text-align: center;
      color: #fff;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      cursor: pointer;

      height: 90px;
      img {
        margin: auto;
      }
    `}

  ${({ apoio }) =>
    apoio &&
    css`
      margin-top: ${props =>
        !props.theme.sideMenuCollapsed ? '52px' : '32px'};
      text-align: center;
      font-size: 14px;
      display: flex;
      flex-direction: column;
      align-items: center;
      cursor: pointer;
      height: 90px;


      .card {
        display: flex;
        flex-direction: column;
        align-items: center;

        justify-content: center;


        max-width: 117px;

        width: auto;

        border: 1px solid  #ffffff
        border-radius: 5px;


        ${props =>
          props.theme.sideMenuCollapsed
            ? css`
                padding: 0px 12px;
              `
            : css`
                padding: 8px 26px;
              `}


        span {
          font-weight: bold;
          color: #ffffff;

          font-size: 12px;
          line-height: 14px;
        }

        svg {
          width: 30px;
          height: 37px;
          color: #ffffff;
        }

        transition: background-color 0.2s;

        &:hover {
          span, svg {
            color: ${props => props.theme.brand_primary_color};
          }

          background-color: #ffffff;
        }
      }

      img {
        margin: auto;
      }

      span {
        color: ${props => props.theme.brand_secondary_color};
        opacity: 0;
      }
    `}

  ${({ logout }) =>
    logout &&
    css`
      margin-top: auto;
      margin-left: 1px;
    `}

  ${({ language }) =>
    language &&
    css`
      margin-top: auto;
      margin-left: 1px;

      img {
        width: 18px;
      }
    `};

  a,
  p {
    cursor: pointer;
    color: ${props =>
      props.isActive ? '#FFFFFF' : props.theme.brand_secondary_color};
    opacity: ${props => (props.isActive ? 1 : 0.6)};
    text-decoration: none;
    font-size: 13px;
    display: flex;
    align-items: center;
    text-align: center;
    padding: 0 38px;

    svg {
      font-size: 16px;
      color: ${props =>
        props.isActive ? '#FFFFFF' : props.theme.brand_secondary_color};
      transition: opacity 0.2s ease-in;
      min-width: 20px !important;
      line-height: 42px;
      text-align: center;
    }

    span {
      opacity: 0;
      margin-left: 16px;
      transition: opacity 0.1s ease-in;
    }

    ${props =>
      !props.language &&
      css`
        &:hover {
          color: #ffffff;
          opacity: 1;

          svg {
            color: #ffffff;
          }
        }
      `}

    ${props =>
      !props.disabled &&
      css`
        &:hover {
          color: #ffffff;
          opacity: 1;

          svg {
            color: #ffffff;
          }
        }
      `}

    ${props =>
      props.disabled &&
      css`
        opacity: 0.2;
        cursor: default;
        pointer-events: none;
      `}

    ${props =>
      props.logout &&
      css`
        font-size: 11px;

        svg {
          font-size: 14px;
        }
      `}

    ${props =>
      props.language &&
      css`
        font-size: 11px;
        cursor: unset;
      `}
  }
`;

export const SubMenuItem = styled(MenuItem)`
  a {
    position: relative;
    font-size: 11px;

    svg {
      font-size: 14px;
    }
  }
`;

export const MenuList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  padding-top: 24px;

  display: flex;
  flex-direction: column;
  align-items: center;

  height: calc(100% - 98px);
`;

interface INotificationProps {
  first?: boolean;
}

export const Notification = styled.div<INotificationProps>`
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background-color: red;
  position: absolute;
  top: 8px;
  left: 52px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #fff;
  font-size: 8px;
  font-weight: bold;
  color: #fff;
`;

export const NewBadge = styled.div`
  width: 50px;
  /* height: 21px; */
  line-height: 2.4;
  text-align: center;

  background: #fcff7c;

  font-size: 9px;
  font-weight: 900;
  text-align: center;

  border-radius: 6px 0 0 6px;

  position: absolute;
  bottom: 50%;
  right: 0;
  transform: translateY(50%);

  text-transform: uppercase;

  color: ${props => props.theme.brand_primary_color};

  opacity: 0;
`;
