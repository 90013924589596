import styled, { css } from 'styled-components';

interface IUserCardProps {
  selected?: boolean;
  isSpammer?: boolean;
  disabled?: boolean;
}

export const UserCard = styled.div<IUserCardProps>`
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 50px;
  background: ${props => props.theme.interface2};
  padding: 13px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  border: 1px solid
    ${props =>
      props.selected
        ? props.theme.darkMode
          ? props.theme.interface5
          : props.theme.brand_primary_color
        : props.isSpammer
        ? props.theme.error
        : 'transparent'};
  border-radius: 5px;

  svg {
    color: ${props =>
      props.isSpammer ? props.theme.error : props.theme.brand_primary_color};
    font-size: 24px;
  }

  &:hover {
    border: 1px solid
      ${props =>
        props.isSpammer ? props.theme.error : props.theme.brand_primary_color};
  }

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      color: ${props => props.theme.interface5} !important;

      svg {
        color: ${props => props.theme.interface5} !important;
      }
    `}
`;

export const Username = styled.span`
  margin-left: 8px;
  font-size: 12px;
  color: ${props => props.theme.interface5};
`;

export const Host = styled.div`
  font-size: 10px;
  font-weight: bold;
  color: ${props => props.theme.interface5};
  margin-right: 12px;

  span {
    display: block;
    font-weight: normal;
    font-style: italic;
  }
`;

export const SpamBadge = styled.div`
  position: absolute;
  right: 10px;
  margin-left: 30px;
  width: 40px;
  height: 14px;
  background: ${props => props.theme.error};
  border-radius: 15px;

  font-size: 8px;
  color: ${props => props.theme.interface1};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    transform: translateX(-2px);
    font-size: 10px;
    margin-right: 2px;
    color: ${props => props.theme.interface1};
  }
`;

export const UserDetail = styled.div`
  padding: 0px 15px;
  width: 100%;
  height: 100%;

  @media (max-width: 1200px) {
    border-top: 1px solid ${props => props.theme.interface4};
    margin-top: 6px;
    padding-top: 14px;
  }

  @media (min-width: 1200px) {
    border-left: 1px solid ${props => props.theme.interface4};
  }
`;

export const AddUserButton = styled.div`
  height: 30px;
  width: 30px;
  background-color: ${props => props.theme.brand_secondary_color};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: width 0.3s ease-in-out;

  svg {
    font-size: 20px;
    margin-right: -100px;
    transition: all 0.05s ease-out;
  }

  &:hover {
    width: auto;
    padding: 0px 8px;
    transition: all 0.3s ease-out;

    svg {
      margin-right: 0;
      transition: all 0.3s ease-out;
      transform: rotateZ(-180deg);
    }

    span {
      transition: opacity 0.3s ease-in-out;
      transition-delay: 0.1s;
      opacity: 1;
    }
  }

  span {
    opacity: 0;
    margin-left: 8px;
    font-size: 12px;
    color: ${props => props.theme.brand_primary_color};
  }
`;
