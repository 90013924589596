import styled, { css } from 'styled-components';

interface ICardProps {
  selected?: boolean;
  add?: boolean;
}

export const Card = styled.div<ICardProps>`
  width: 100%;
  padding: 10px 14px;
  margin-bottom: 20px;
  background: ${props => props.theme.interface2};
  border: 1px solid transparent;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    border: 1px solid
      ${props =>
        props.theme.darkMode
          ? props.theme.interface5
          : props.theme.brand_primary_color};
  }

  ${props =>
    props.selected &&
    css`
      border: 1px solid
        ${props =>
          props.theme.darkMode
            ? props.theme.interface5
            : props.theme.brand_primary_color};
    `}

  ${props =>
    props.add &&
    css`
      svg {
        color: ${props => props.theme.interface4};
      }

      color: ${props => props.theme.interface5};

      &:hover {
        cursor: pointer;
        border: 1px solid
          ${props =>
            props.theme.darkMode
              ? props.theme.interface5
              : props.theme.brand_primary_color};
      }
    `}
`;

export const Header = styled.div`
  width: 100%;
  height: 48px;

  display: flex;
  align-items: center;
`;

export const Name = styled.div`
  margin-left: 10px;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  font-size: 12px;
  color: ${props => props.theme.interface5};
`;

export const Body = styled.div`
  height: calc(100% - 26px);
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;
