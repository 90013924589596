import styled, { css } from 'styled-components';

interface WrapperProps {
  show?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  position: absolute;
  display: ${props => (props.show ? 'block' : 'none')};
  top: 26px;
  width: 190px;
  left: calc(50% - 95px);
  max-height: 290px;
  padding: 8px;
  background: ${props => props.theme.interface1};
  border: 2px solid
    ${props => {
      return props.theme.darkMode
        ? props.theme.interface2
        : props.theme.interface3;
    }};
  ${props =>
    !props.theme.darkMode &&
    css`
      box-shadow: 0px 3px 15px #64728c4d;
    `}

  border-radius: 5px;
  z-index: 999;
`;

export const FilterDropdownList = styled.ul`
  width: 100%;
  height: auto;
  min-height: 145px;
  margin: 8px 0;
  border-radius: 2px;
`;

export const CloudItem = styled.li`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 6px 8px;
  font-size: 12px;
  border: 2px solid transparent;
  border-radius: 5px;
  transition: border 0.01s ease-in;
  color: ${props => props.theme.interface6};

  &:hover {
    border: 2px solid ${props => props.theme.brand_primary_color};
  }

  &:nth-child(odd) {
    background: ${props => props.theme.interface2};
  }
`;

interface SelectedProps {
  selected?: boolean;
}

export const Selected = styled.div<SelectedProps>`
  margin-right: 10px;
  min-width: 13px;
  height: 13px;
  border: 2px solid ${props => props.theme.brand_primary_color};
  position: relative;
  border-radius: 50%;

  ${props =>
    props.selected &&
    css`
      &::before {
        content: '';
        border-radius: 50%;
        position: absolute;
        top: 1px;
        left: 1px;
        width: 7px;
        height: 7px;
        background: ${props => props.theme.brand_primary_color};
      }
    `}
`;
