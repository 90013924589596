import { Reducer } from 'react';
import { IActionPayload } from 'types';
import { DomainDetailState } from './state';

const reducer: Reducer<DomainDetailState, IActionPayload> = (
  state,
  action,
): DomainDetailState => {
  switch (action.type) {
    case 'GET_DOMAIN':
      return {
        ...state,
        domain: action.payload,
      };

    case 'SET_LOADING': {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case 'SET_ERRORS': {
      return {
        ...state,
        errors: action.payload,
      };
    }

    case 'GET_WEBSITES': {
      return {
        ...state,
        websites: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
export default reducer;
