import React from 'react';
import {
  Button,
  Input,
  Label,
  Field,
  Error,
  Checkbox,
} from '@cloudez/cloudez-design-system';

import { Col, Row } from 'react-bootstrap';
import { Title, Subtitle } from '../../styles';

const FTP = ({
  newMigration,
  setNewMigration,
  migrationErrors,
  _nextStage,
}) => {
  return (
    <>
      {/* <Title>Dados de acesso</Title>
      <Subtitle>
        Criar uma cópia de seu projeto é a opção ideal para migrar projetos de
        um servidor para outro, sem pereder tempo com processos repetitivos
      </Subtitle> */}

      <div
        style={{
          marginTop: '30px',
        }}
      >
        <Field>
          <Label dark>Qual endereço FTP?</Label>
          <Input
            block
            value={newMigration.host}
            onChange={e =>
              setNewMigration({ ...newMigration, host: e.target.value })
            }
            error={!!migrationErrors?.host}
          />
          {!!migrationErrors?.host && <Error>{migrationErrors?.host}</Error>}
        </Field>
        <Field>
          <Label dark>Qual usuário de acesso?</Label>
          <Input
            block
            value={newMigration.user}
            onChange={e =>
              setNewMigration({ ...newMigration, user: e.target.value })
            }
            error={!!migrationErrors?.auth || !!migrationErrors?.username}
          />
          {(!!migrationErrors?.auth || !!migrationErrors?.username) && (
            <Error>
              {migrationErrors?.auth || !!migrationErrors?.username}
            </Error>
          )}
        </Field>
        <Field>
          <Label dark>Digite sua senha secreta</Label>
          <Input
            type="password"
            block
            value={newMigration.password}
            onChange={e =>
              setNewMigration({ ...newMigration, password: e.target.value })
            }
            error={!!migrationErrors?.auth || !!migrationErrors?.password}
          />
          {(!!migrationErrors?.auth || !!migrationErrors?.password) && (
            <Error>
              {migrationErrors?.auth || !!migrationErrors?.password}
            </Error>
          )}
        </Field>
        <Row>
          <Col xl="2">
            <Field>
              <Label dark>SFTP/SSH</Label>
              <Checkbox
                value={newMigration.sftp}
                onChange={e =>
                  setNewMigration({ ...newMigration, sftp: e.target.checked })
                }
              />
            </Field>
          </Col>
          {newMigration.sftp && (
            <>
              <Col xl="3">
                <Field>
                  <Label dark>PORTA</Label>
                  <Input
                    block="true"
                    type="number"
                    value={newMigration.port}
                    onChange={e =>
                      setNewMigration({ ...newMigration, port: e.target.value })
                    }
                    error={!!migrationErrors?.port}
                  />
                  {!!migrationErrors?.port && (
                    <Error>{migrationErrors?.port}</Error>
                  )}
                </Field>
              </Col>
              <Col xl="7">
                <Label dark>CAMINHO</Label>
                <Input
                  block="true"
                  value={newMigration.path}
                  onChange={e =>
                    setNewMigration({ ...newMigration, path: e.target.value })
                  }
                  error={!!migrationErrors?.path}
                />
                {!!migrationErrors?.path && (
                  <Error>{migrationErrors?.path}</Error>
                )}
              </Col>
            </>
          )}
        </Row>
      </div>
    </>
  );
};

export default FTP;
