import React, { useContext, useMemo } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VerticalTabs } from '@cloudez/cloudez-design-system';
import {
  faCheck,
  faPause,
  faSpinner,
  faStop,
} from '@fortawesome/free-solid-svg-icons';
import { ThemeContext } from 'styled-components';
import { TabTitle, TabContent, Sync } from './styles';
import { MigrationDetailContext } from '../_context/state';

const Syncs = () => {
  const theme = useContext(ThemeContext);
  const { migration, syncs } = useContext(MigrationDetailContext);

  const getIcon = status => {
    return migration.status === status
      ? faSpinner
      : migration.status < status
      ? faPause
      : faCheck;
  };

  const getSyncIcon = status => {
    switch (status) {
      case 1:
      case 3:
        return faSpinner;
      case 2:
        return faCheck;
      case 4:
      case 0:
        return faPause;
      case 5:
        return faStop;
      default:
        return faPause;
    }
  };

  const getSyncColor = status => {
    switch (status) {
      case 1:
        return theme.interface6;
      default:
        return theme.interface4;
    }
  };

  const getColor = status => {
    return migration.status === status
      ? theme.interface5
      : migration.status < status
      ? theme.interface4
      : theme.success;
  };

  const isRemoveBackup = useMemo(() => {
    return syncs?.find(
      s => s.type === 'remove_backup' && (s.status === 1 || s.status === 3),
    );
  }, [syncs]);

  return (
    <VerticalTabs
      items={
        migration.resource !== 'email'
          ? [
              {
                name: props => (
                  <TabTitle {...props} first>
                    Sincronização
                    <FontAwesomeIcon
                      icon={getIcon(isRemoveBackup ? 0 : 1)}
                      spin={migration.status === 1 && !isRemoveBackup}
                      color={getColor(isRemoveBackup ? 0 : 1)}
                    />
                  </TabTitle>
                ),
                content: props => (
                  <TabContent {...props}>
                    {syncs?.map(s => {
                      return s.type === 'rsync' || s.type === 'wget' ? (
                        <Sync key={s.id} color={getSyncColor(s.status)}>
                          <p>Baixando os arquivos</p>
                          <FontAwesomeIcon
                            icon={getSyncIcon(s.status)}
                            spin={s.status === 1 || s.status === 3}
                          />
                        </Sync>
                      ) : s.type === 'imapsync' ? (
                        <Sync key={s.id} color={getSyncColor(s.status)}>
                          <p>Baixando conta {s.extra_args.src_user}</p>
                          <FontAwesomeIcon
                            icon={getSyncIcon(s.status)}
                            spin={s.status === 1 || s.status === 3}
                          />
                        </Sync>
                      ) : s.type === 'emailrsync' ? (
                        <Sync key={s.id} color={getSyncColor(s.status)}>
                          <p>Baixando as contas</p>
                          <FontAwesomeIcon
                            icon={getSyncIcon(s.status)}
                            spin={s.status === 1 || s.status === 3}
                          />
                        </Sync>
                      ) : s.type === 'sslsync' || s.type === 'internal' ? (
                        <Sync key={s.id} color={getSyncColor(s.status)}>
                          <p>Importando chave SSH</p>
                          <FontAwesomeIcon
                            icon={getSyncIcon(s.status)}
                            spin={s.status === 1 || s.status === 3}
                          />
                        </Sync>
                      ) : s.type === 'autodump' ? (
                        <Sync key={s.id} color={getSyncColor(s.status)}>
                          <p>Baixando o banco de dados</p>
                          <FontAwesomeIcon
                            icon={getSyncIcon(s.status)}
                            spin={s.status === 1 || s.status === 3}
                          />
                        </Sync>
                      ) : s.type === 'finish' ? (
                        <Sync key={s.id} color={getSyncColor(s.status)}>
                          <p>Importando o banco de dados</p>
                          <FontAwesomeIcon
                            icon={getSyncIcon(s.status)}
                            spin={s.status === 1 || s.status === 3}
                          />
                        </Sync>
                      ) : null;
                    })}
                  </TabContent>
                ),
                defaultOpen: migration.status === 1,
              },
              {
                name: props => (
                  <TabTitle {...props}>
                    Configuração
                    <FontAwesomeIcon
                      icon={getIcon(isRemoveBackup ? 0 : 2)}
                      spin={migration.status === 2 && !isRemoveBackup}
                      color={getColor(isRemoveBackup ? 0 : 2)}
                    />
                  </TabTitle>
                ),
                content: props => (
                  <TabContent {...props}>
                    <Sync color={getSyncColor(2)}>
                      <p>Configurando os últimos detalhes</p>
                      <FontAwesomeIcon
                        icon={getIcon(2)}
                        spin={migration.status === 2}
                      />
                    </Sync>
                  </TabContent>
                ),
                defaultOpen: migration.status === 2,
              },
              {
                name: props => (
                  <TabTitle {...props} last>
                    Validação
                    <FontAwesomeIcon
                      icon={getIcon(isRemoveBackup ? 1 : 3)}
                      spin={isRemoveBackup || migration.status === 3}
                      color={getColor(3)}
                    />
                  </TabTitle>
                ),
                content: props => (
                  <TabContent {...props}>
                    <Sync
                      color={getSyncColor(migration.status === 3 ? 1 : null)}
                    >
                      <p>Aguardando validação</p>

                      <FontAwesomeIcon
                        icon={getSyncIcon(
                          migration.status > 3
                            ? 2
                            : migration.status < 3
                            ? null
                            : 1,
                        )}
                        spin={migration.status === 3}
                      />
                    </Sync>

                    {syncs?.map(s => {
                      return s.type === 'remove_backup' ? (
                        <Sync key={s.id} color={getSyncColor(s.status)}>
                          <p>Removendo arquivos de migração</p>
                          <FontAwesomeIcon
                            icon={getSyncIcon(s.status)}
                            spin={s.status === 1 || s.status === 3}
                          />
                        </Sync>
                      ) : null;
                    })}
                  </TabContent>
                ),
                defaultOpen: migration.status === 3,
              },
            ]
          : [
              {
                name: props => (
                  <TabTitle {...props} first>
                    Sincronização
                    <FontAwesomeIcon
                      icon={getIcon(isRemoveBackup ? 0 : 1)}
                      spin={migration.status === 1 && !isRemoveBackup}
                      color={getColor(isRemoveBackup ? 0 : 1)}
                    />
                  </TabTitle>
                ),
                content: props => (
                  <TabContent {...props}>
                    {syncs?.map(s => {
                      return s.type === 'rsync' || s.type === 'wget' ? (
                        <Sync key={s.id} color={getSyncColor(s.status)}>
                          <p>Baixando os arquivos</p>
                          <FontAwesomeIcon
                            icon={getSyncIcon(s.status)}
                            spin={s.status === 1 || s.status === 3}
                          />
                        </Sync>
                      ) : s.type === 'imapsync' ? (
                        <Sync key={s.id} color={getSyncColor(s.status)}>
                          <p>Baixando conta {s.extra_args.src_user}</p>
                          <FontAwesomeIcon
                            icon={getSyncIcon(s.status)}
                            spin={s.status === 1 || s.status === 3}
                          />
                        </Sync>
                      ) : s.type === 'emailrsync' ? (
                        <Sync key={s.id} color={getSyncColor(s.status)}>
                          <p>Baixando as contas</p>
                          <FontAwesomeIcon
                            icon={getSyncIcon(s.status)}
                            spin={s.status === 1 || s.status === 3}
                          />
                        </Sync>
                      ) : s.type === 'sslsync' || s.type === 'internal' ? (
                        <Sync key={s.id} color={getSyncColor(s.status)}>
                          <p>Importando chave SSH</p>
                          <FontAwesomeIcon
                            icon={getSyncIcon(s.status)}
                            spin={s.status === 1 || s.status === 3}
                          />
                        </Sync>
                      ) : s.type === 'autodump' ? (
                        <Sync key={s.id} color={getSyncColor(s.status)}>
                          <p>Baixando o banco de dados</p>
                          <FontAwesomeIcon
                            icon={getSyncIcon(s.status)}
                            spin={s.status === 1 || s.status === 3}
                          />
                        </Sync>
                      ) : s.type === 'finish' ? (
                        <Sync key={s.id} color={getSyncColor(s.status)}>
                          <p>Importando o banco de dados</p>
                          <FontAwesomeIcon
                            icon={getSyncIcon(s.status)}
                            spin={s.status === 1 || s.status === 3}
                          />
                        </Sync>
                      ) : null;
                    })}
                  </TabContent>
                ),
                defaultOpen: migration.status === 1,
              },

              {
                name: props => (
                  <TabTitle {...props} last>
                    Validação
                    <FontAwesomeIcon
                      icon={getIcon(isRemoveBackup ? 1 : 3)}
                      spin={isRemoveBackup || migration.status === 3}
                      color={getColor(3)}
                    />
                  </TabTitle>
                ),
                content: props => (
                  <TabContent {...props}>
                    <Sync
                      color={getSyncColor(migration.status === 3 ? 1 : null)}
                    >
                      <p>Aguardando validação</p>

                      <FontAwesomeIcon
                        icon={getSyncIcon(
                          migration.status > 3
                            ? 2
                            : migration.status < 3
                            ? null
                            : 1,
                        )}
                        spin={migration.status === 3}
                      />
                    </Sync>

                    {syncs?.map(s => {
                      return s.type === 'remove_backup' ? (
                        <Sync key={s.id} color={getSyncColor(s.status)}>
                          <p>Removendo arquivos de migração</p>
                          <FontAwesomeIcon
                            icon={getSyncIcon(s.status)}
                            spin={s.status === 1 || s.status === 3}
                          />
                        </Sync>
                      ) : null;
                    })}
                  </TabContent>
                ),
                defaultOpen: migration.status === 3,
              },
            ]
      }
    />
  );
};

export default Syncs;
