import styled from 'styled-components';

interface FiltersProps {
  staff?: boolean;
}

export const Filters = styled.div<FiltersProps>`
  max-width: 100%;
  padding: 10px;
  margin-top: -28px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & > div {
    width: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 8px;
  }

  button {
    width: 80px;
    margin-right: 8px;
    position: relative;
  }
`;

export const TeamBadge = styled.div`
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  color: ${props => props.theme.label};
  font-weight: bold;
`;
