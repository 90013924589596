import React, { useState, useCallback } from 'react';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';

import {
  Button,
  FormInput,
  Form,
  Field,
  Label,
  Error,
} from '@cloudez/cloudez-design-system';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faPaperPlane,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import api from 'services/api';
import { useLayout } from 'hooks/layout';
import { toast } from 'react-toastify';
import clearMask from 'utils/clearMask';
import { Wrapper } from './styles';

const schema = Yup.object().shape({
  document_id: Yup.string().required('O documento é obrigatório.'),
});

const ResendInvoices: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [done, setDone] = useState(null);
  const [document, setDocument] = useState(0);
  const { code } = useLayout();

  const handleSubmit = useCallback(async e => {
    setErrors(null);
    setLoading(true);
    try {
      const { document_id } = e;

      const { data } = await api.get(
        `/v3/external/invoice/${code}/${clearMask(document_id)}/`,
      );

      setLoading(false);
      setDone(data.email || true);
    } catch (e) {
      setLoading(false);
      setErrors(e?.response?.data);
    }
  }, []);

  return (
    <Wrapper>
      <h1
        style={{
          fontSize: '24px',
          fontWeight: 700,
          textAlign: 'center',
          marginBottom: '10px',
        }}
      >
        Reenviar faturas
      </h1>
      <p
        style={{
          fontWeight: 400,
          fontSize: '16px',
          textAlign: 'center',
          color: '#64728C',
          marginBottom: '30px',
        }}
      >
        Receba suas faturas em aberto diretamente no seu email!
      </p>
      <Form schema={schema} onSubmit={handleSubmit}>
        <Field>
          <Label>Digite o seu cpf ou cnpj </Label>

          <InputMask
            mask={document === 0 ? '999.999.999-999' : '99.999.999/9999-99'}
            maskChar={null}
            block="true"
            name="document_id"
            error={!!errors?.document_id}
            onChange={e => {
              if (e.target.value.length >= 15) {
                setDocument(1);
              } else {
                setDocument(0);
              }
            }}
          >
            {inputProps => <FormInput {...inputProps} />}
          </InputMask>
          {errors?.document_id && (
            <Error>
              {errors?.document_id[0].length > 1
                ? errors?.document_id[0]
                : errors?.document_id}
            </Error>
          )}
        </Field>
        {!errors?.document_id && (
          <p
            style={{
              fontWeight: 400,
              fontSize: '12px',
              color: '#64728C',
              marginBottom: '30px',
              marginTop: -18,
            }}
          >
            Digite somente números
          </p>
        )}
        <Button
          type="submit"
          size="lg"
          style={{
            width: '100%',
          }}
          disabled={loading || done}
          outline={done}
        >
          Enviar
          {loading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            <FontAwesomeIcon icon={faPaperPlane} />
          )}
        </Button>

        {done && (
          <p
            style={{
              fontWeight: 700,
              fontSize: '16px',
              color: '#29D975',
              textAlign: 'center',
              marginTop: 30,
            }}
          >
            Suas faturas foram enviadas para o email {done}
          </p>
        )}
      </Form>
    </Wrapper>
  );
};

export default ResendInvoices;
