import { toast } from 'react-toastify';
import history from 'services/history';
import { createEmailService } from 'services/email';

export const setLoading = (dispatch, value) => {
  dispatch({
    type: 'SET_LOADING',
    payload: value,
  });
};

export const updateField = (state, dispatch, field, value) => {
  dispatch({
    type: 'UPDATE_FIELD',
    payload: {
      ...state.newEmail,
      [field]: value,
    },
  });
};

export const updateNewEmail = (dispatch, email) => {
  dispatch({
    type: 'UPDATE_NEW_EMAIL',
    payload: email,
  });
};

export const createEmail = async (dispatch, email, buyDomain) => {
  setLoading(dispatch, true);
  try {
    const { data } = await createEmailService(email);

    if (!buyDomain) {
      toast.success('Email criado com sucesso');
      history.push(`/emails/${data.id}`);
    } else {
      dispatch({
        type: 'CREATE_EMAIL_DOMAIN',
        payload: data,
      });
    }
  } catch (e) {
    setLoading(dispatch, false);
    dispatch({
      type: 'SET_ERRORS',
      payload: e?.response?.data,
    });
  }
};
