import React, { useMemo, useState, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ApplicationIcon from 'components/ApplicationIcon';

import {
  faChevronDown,
  faChevronUp,
  faDesktop,
  faMobileAlt,
} from '@fortawesome/free-solid-svg-icons';
import {
  ListingItem,
  ApplicationValue,
  ApplicationName,
} from 'components/Listing/styles';
import { getValue } from 'utils/getValue';
import { getWebsiteService } from 'services/mccoy';
import { useLayout } from 'hooks/layout';
import { useSpring } from 'react-spring';
import { NewCard, Dropdown, Value, Label, Status, Score } from './styles';

import Charts from './Charts';
import Scores from './Scores';
import Optimizations from './Optimizations';

const WebsiteCard: React.FC<{ website: any }> = ({ website }) => {
  const [showAccess, setShowAccess] = useState(false);
  const [opened, setOpened] = useState(false);
  const [analysis, setAnalysis] = useState(null);
  const [tab, setTab] = useState(1);
  const theme = useLayout();

  useEffect(() => {
    async function getAnalyze() {
      try {
        const { data } = await getWebsiteService({ q: website.domain });

        if (data.results.length > 0) {
          setAnalysis(data.results[0].analysis);
        }
      } catch (e) {
        console.log(e);
      }
    }

    getAnalyze();
    // eslint-disable-next-line
  }, []);

  const data = useMemo(() => {
    return {
      use_cdn: getValue(website, 'use_cdn') === 'true',
      pagespeed: getValue(website, 'pagespeed') === 'true',
      https_enable:
        getValue(website, 'https_enable') === 'true' &&
        website?.certificate?.is_active,
      ...analysis,
    };
  }, [website, analysis]);

  const [styles, start] = useSpring(() => ({ from: { opacity: 1 } }));

  const startAnimation = () => {
    start({
      from: { opacity: 0 },
      config: {
        duration: 400,
      },
      // to: async next => {
      //   let count = 0;
      //   while (count < 3) {
      //     await next({ opacity: 1 });
      //     await next({ opacity: 0 });
      //     count++;
      //   }

      //   await next({ opacity: 1 });
      // },
    });
  };

  return (
    <ListingItem disable={website.is_disabled}>
      <NewCard opened={opened}>
        <Row
          style={{
            width: '100%',
            height: '70px',
            margin: 0,
            position: 'relative',
            borderBottom: `${opened ? '1px' : '0'} solid ${theme.interface3}`,
          }}
        >
          <Col
            xs={!showAccess ? '4' : null}
            sm={!showAccess ? '2' : null}
            md={!showAccess ? '3' : null}
            lg="3"
          >
            <ApplicationValue>
              <ApplicationIcon
                width="30px"
                height="30px"
                icon={`${website.type.slug}`}
                disable={website.is_disabled}
              />
              {!showAccess && (
                <ApplicationName>
                  {website.domain ? website.domain : website.name}
                </ApplicationName>
              )}
            </ApplicationValue>
          </Col>

          <Col xs="6" sm="5" md="5" lg="4">
            <ApplicationValue
              style={{
                justifyContent: 'space-between',
              }}
            >
              <Value>
                <Status active={data.https_enable} />
                <Label>HTTPS</Label>
              </Value>
              <Value>
                <Status active={data.use_cdn} />
                <Label>CDN</Label>
              </Value>
              <Value>
                <Status active={data.pagespeed} />
                <Label>PageSpeed</Label>
              </Value>
              <Value>
                <Label />
              </Value>
            </ApplicationValue>
          </Col>

          <Col sm="5" md="4" lg="4">
            <ApplicationValue
              style={{
                justifyContent: 'space-between',
              }}
            >
              <Value>
                <Label>
                  <FontAwesomeIcon icon={faDesktop} />
                </Label>
                <Score className="mr-3" score={data.desktop?.score}>
                  {data.desktop?.score || '-'}
                </Score>
                <Label>
                  <FontAwesomeIcon icon={faMobileAlt} />
                </Label>
                <Score score={data.mobile?.score}>
                  {data.mobile?.score || '-'}
                </Score>
              </Value>
              {/* <Value></Value> */}

              <Value>
                <Label />
              </Value>
              <Value>
                <Label />
              </Value>
            </ApplicationValue>
          </Col>

          <Col xl="1">
            <Dropdown
              onClick={e => {
                setOpened(!opened);
              }}
            >
              <FontAwesomeIcon icon={opened ? faChevronUp : faChevronDown} />
            </Dropdown>
          </Col>
        </Row>

        {opened && (
          <Row
            style={{
              width: '100%',
              margin: '0',
              marginTop: 16,
            }}
          >
            <Col xs="3">
              <Charts
                website={website}
                analysis={analysis}
                setAnalysis={setAnalysis}
                tab={tab}
                setTab={setTab}
                startAnimation={startAnimation}
                setOpened={setOpened}
              />
            </Col>
            <Col xs="4">
              <Scores tab={tab} analysis={analysis} />
            </Col>
            <Col xs="5">
              <Optimizations
                website={website}
                analysis={analysis}
                animStyles={styles}
              />
            </Col>
          </Row>
        )}
      </NewCard>
    </ListingItem>
  );
};

export default WebsiteCard;
