import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 58px;
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Content = styled.div`
  display: flex;

  @media screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    img {
      width: 100% !important;
    }
  }

  .benefits {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(265px, 1fr));
    gap: 10px;

    .benefit {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 5px;

      svg {
        color: ${props => props.theme.successNew};
      }
    }
  }

  .text {
    display: flex;
    flex-direction: column;
    gap: 16px;

    justify-content: center;

    padding: 42px 30px;

    p {
      font-weight: 700;
      font-size: 24px;

      color: ${props => props.theme.interface7};
    }

    span {
      font-weight: 400;
      font-size: 16px;

      color: ${props => props.theme.interface6};
    }
  }

  box-shadow: 0px 2px 15px rgba(100, 114, 140, 0.25);
  border-radius: 5px;
`;
