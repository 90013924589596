import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { AddCard, AddButton } from 'components/AddCard';

import Placeholder from 'components/Placeholder';
import { Breadcrumbs, Breadcrumb } from 'components/Common';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Header, Title, Tabs, Tab, TabContent } from 'components/Details';

import UserPlaceholder from '../Usuarios/UserPlaceholder';

const DatabaseDetailPlaceholder: React.FC = () => {
  return (
    <>
      <Header>
        <Row>
          <Col md="10" sm="9">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Placeholder circle width={64} height={64} />

              <Title>
                <Breadcrumbs>
                  <Breadcrumb>
                    <Placeholder width={40} height={10} />
                  </Breadcrumb>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <Breadcrumb>
                    <Placeholder width={60} height={10} />
                  </Breadcrumb>
                </Breadcrumbs>
                <h3>
                  <Placeholder width={100} height={18} />
                </h3>
                <Placeholder width={100} height={14} />
              </Title>
            </div>
          </Col>

          <Col
            className="d-none d-sm-flex d-md-flex d-xl-flex"
            sm="3"
            md="2"
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Placeholder width={165} height={40} />
          </Col>
        </Row>
      </Header>

      <Row>
        <Col>
          <Tabs>
            <Tab active>
              <Placeholder width={50} height={14} />
            </Tab>
            <Tab>
              <Placeholder width={50} height={14} />
            </Tab>
          </Tabs>
          <TabContent>
            <Row>
              <Col lg="6">
                <Row>
                  <Col sm="6">
                    <AddCard width="100%" height="50px">
                      <AddButton row>
                        <FontAwesomeIcon icon={faPlusSquare} />
                        <span>criar</span>
                      </AddButton>
                    </AddCard>
                  </Col>
                  <UserPlaceholder />
                </Row>
              </Col>
              <Col lg="6" />
            </Row>
          </TabContent>
        </Col>
      </Row>
    </>
  );
};

export default DatabaseDetailPlaceholder;
