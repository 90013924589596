import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSpinner } from '@fortawesome/free-solid-svg-icons';

import {
  Modal,
  Input,
  Field,
  Label,
  Error,
  Button,
} from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from 'components/ListingModal/styles';
import { useLayout } from 'hooks/layout';

interface IChangeModalProps {
  action: any;
  show: boolean;
  setShow: any;
  changeValue: any;
  loading: boolean;
  errors: any;
}

const ChangeModal: React.FC<IChangeModalProps> = ({
  action,
  show,
  setShow,
  changeValue,
  loading,
  errors = 1,
}) => {
  const theme = useLayout();

  return (
    <Modal show={show} setShow={setShow}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faEdit} />
        </Icon>
        <Text>
          <Title>Trocar domínio</Title>
          <Subtitle>Alterar o domínio principal da aplicação</Subtitle>
        </Text>
      </Header>

      <Content>
        <Field>
          <Label>Domínio</Label>
          <Input
            block
            onChange={changeValue}
            name="domain"
            error={!!errors?.values?.domain}
          />
          {errors?.values?.domain && <Error>{errors?.values?.domain[0]}</Error>}
        </Field>

        <span
          style={{
            fontSize: '10px',
            color: theme.interface5,
          }}
        >
          <span
            style={{
              fontWeight: 'bold',
            }}
          >
            ATENÇÃO:
          </span>{' '}
          Execute esta operação com cuidado, se o domínio de destino não estiver
          ativo no painel seu website pode ficar indisponível.
        </span>
      </Content>
      <Footer>
        <Button
          onClick={async () => {
            await action(true);
            setShow();
          }}
          icon
          disabled={loading}
        >
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Alterar'}
        </Button>
      </Footer>
    </Modal>
  );
};

export default ChangeModal;
