import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 25px;
  width: 100%;
  height: auto;
`;
interface TypeProps {
  type?: any;
  selected?: boolean;
}

export const Type = styled.div<TypeProps>`
  cursor: pointer;
  width: 100%;
  height: 110px;

  display: flex;
  flex-direction: column;

  align-items: center;

  border-radius: 5px;

  margin-bottom: 30px;
  padding: 10px;

  background: ${props => props.theme.interface1};
  border: 2px solid transparent;

  ${props =>
    props.selected &&
    css`
      border: 2px solid
        ${props =>
          props.theme.darkMode
            ? props.theme.interface5
            : props.theme.brand_primary_color};
    `}

  &:hover {
    transition: border 0.2s ease-in;
    border: 2px solid
      ${props =>
        props.theme.darkMode
          ? props.theme.interface5
          : props.theme.brand_primary_color};
  }

  span {
    margin-top: 8px;
    font-size: 14px;
    color: ${props => props.theme.interface5};
  }

  p {
    margin-top: 4px;
    font-size: 10px;
    color: ${props => props.theme.interface5};
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const CustomApp = styled(Type)`
  display: flex;
  flex-direction: row;

  cursor: auto !important;

  background-color: ${props => props.theme.interface1};
  border-radius: 5px;

  width: 100%;
  height: 110px;

  div.leftSide {
    display: flex;
    flex-direction: column;
    gap: 8px;

    span {
      font-weight: 700 !important;
      font-size: 14px !important;
      color: ${props => props.theme.interface7} !important;
    }
  }

  div.rightSide {
    display: grid;
    grid-template-columns: 1fr 1fr;

    gap: 24px;
  }

  display: flex;
  flex-direction: row;

  font-style: normal;
  font-weight: 700;
  font-size: 14px;

  color: ${props => props.theme.interface7};
`;

export const SeeMoreButton = styled.div`
  height: 30px;
  width: 30px;
  background-color: ${props => props.theme.brand_secondary_color};
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: width 0.3s ease-in-out;

  svg {
    color: ${props => props.theme.brand_primary_color};
    font-size: 16px;
    margin-right: -90px;
    transition: all 0.05s ease-out;
  }

  &:hover {
    width: auto;
    padding: 0px 8px;
    transition: all 0.3s ease-out;

    svg {
      margin-right: 0;
      transition: all 0.3s ease-out;
      transform: rotateZ(-180deg);
    }

    span {
      transition: opacity 0.3s ease-out;
      transition-delay: 0.1s;
      opacity: 1;
    }
  }

  span {
    opacity: 0;
    margin-left: 8px;
    font-size: 12px;
    color: ${props => props.theme.brand_primary_color};
  }
`;
