import styled from 'styled-components';

export const Container = styled.div`
  border: 1px solid ${props => props.theme.interface4};
  border-radius: 5px;

  width: fit-content;

  margin-top: 13px;

  gap: 8px;

  display: flex;
  flex-direction: column;

  padding: 12px 16px;

  font-weight: 400;
  font-size: 14px;

  color: ${props => props.theme.interface5};

  .tag {
    display: flex;
    background-color: #f5f8ff;

    align-items: center;

    width: fit-content;

    font-weight: 700;

    border: 1px solid ${props => props.theme.interface3};
    border-radius: 5px;

    padding: 10px 8px;

    b {
      color: ${props => props.theme.interface6};
    }
  }
`;
