import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  background: ${props => props.theme.interface1};

  width: 70%;

  @media (max-width: 900px) {
    width: 100%;
  }

  box-shadow: 0px 2px 15px rgba(100, 114, 140, 0.25);
  border-radius: 5px;

  padding: 32px;

  gap: 24px;

  .title {
    font-weight: bold;
    font-size: 16px;

    line-height: 25px;

    color: ${props => props.theme.brand_primary_color};

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 9px;
  }

  .subtitle {
    font-weight: bold;
    font-size: 16px;

    color: ${props => props.theme.interface5};
  }

  .userAccess {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    justify-content: space-between;

    font-size: 14px;
    line-height: 24px;
    color: ${props => props.theme.brand_primary_color};

    span {
      font-weight: bold;
    }

    a {
      text-decoration-line: underline;
      font-weight: 500;
    }
  }

  .newUser {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;

    gap: 9px;

    font-weight: bold;
    font-size: 14px;
    line-height: 24px;

    text-decoration-line: underline;

    color: ${props => props.theme.brand_primary_color};
  }
`;

interface CardProps {
  selected?: boolean;
}

export const Card = styled.div<CardProps>`
  display: grid;
  grid-template-rows: 50px 26px;

  cursor: pointer;

  transition: 0.2s;

  &:hover {
    transform: scale(1.02);
  }

  justify-items: center;
  align-items: center;
  text-align: center;

  min-height: 110px;

  padding: 16px;

  border: ${props =>
    props.selected
      ? `2px solid ${props.theme.brand_primary_color}`
      : `1px solid ${props.theme.interface3}`};

  border-radius: 5px;

  box-shadow: ${props =>
    props.selected ? '0px 2px 15px rgba(100, 114, 140, 0.25)' : null};

  color: ${props => props.theme.interface5};

  b {
    font-size: 12px;
    line-height: 100%;
  }
  span {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    font-size: 10px;
    font-weight: normal;
  }
`;

export const CardBox = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 16px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
