import React, { createContext, useReducer } from 'react';
import reducer from './reducer';
import {
  setLoading,
  createMigration,
  createMigrationErrorAction,
} from './actions';

// initial state
const initialState = {
  loading: false,
  errors: null,
};

// context
export const MigrationCreateContext = createContext(initialState);

// provider
export const MigrationCreateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <MigrationCreateContext.Provider
      value={{
        // state
        errors: state.errors,
        loading: state.loading,

        // actions
        createMigration: async payload =>
          await createMigration(dispatch, payload),
        createMigrationErrorAction: data =>
          createMigrationErrorAction(dispatch, data),
        setLoading: value => setLoading(dispatch, value),
      }}
    >
      {children}
    </MigrationCreateContext.Provider>
  );
};
