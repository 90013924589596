import React, { useCallback, useState, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import queryString from 'query-string';
import { Form } from '@rocketseat/unform';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import ReactLoading from 'react-loading';

import api from 'services/api';

import {
  Button,
  FormInput,
  Label,
  Field,
  Checkbox,
} from '@cloudez/cloudez-design-system';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye,
  faEyeSlash,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { useDocTitle } from 'hooks/docTitle';
import { useSpring, animated } from 'react-spring';
import { useLayout } from 'hooks/layout';
import Progress from 'components/Progress';
import { useAuth } from 'hooks/auth';
import { Link, useLocation } from 'react-router-dom';
import PasswordStrength from 'components/PasswordStrength';
import { Content, Header, Wrapper } from './styles';

const schema = Yup.object().shape({
  full_name: Yup.string('Insira um nome válido.').required(
    'O nome é obrigatório.',
  ),
  email: Yup.string()
    .email('Insira um email válido.')
    .required('O email é obrigatório.'),
  password1: Yup.string()
    .min(8, 'A senha deve conter no mínimo 8 caracteres.')
    .required('A senha é obrigatória.'),
  password2: Yup.string()
    .min(8, 'A senha deve conter no mínimo 8 caracteres.')
    .oneOf([Yup.ref('password1'), null], 'As senhas são diferentes.')
    .required('Senha de confirmação é obrigatória.'),
});

const SignUp: React.FC = () => {
  const theme = useLayout();

  const { finishLogin } = useAuth();
  const location = useLocation();
  const [params, setParams] = useState({} as any);

  useDocTitle(`Cadastro — ${theme.name}`);

  const [recaptcha, setRecaptcha] = useState(null);
  const [noRecaptcha, setNoRecaptcha] = useState(false);
  const [accepted, setAccepted] = useState(false);
  const [notAccepted, setNotAccepted] = useState(false);

  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');

  const [showPw, setShowPw] = useState(false);
  const [showPwRep, setShowPwRep] = useState(false);

  const [loading, setLoading] = useState(false);

  const [stage, setStage] = useState(1);

  const nextStage = useCallback(() => setStage(state => state + 1), []);

  useEffect(() => {
    const _params = queryString.parse(location.search);
    const newParams = {} as any;

    for (const key in _params) {
      if (key.startsWith('utm_')) {
        newParams[key] = _params[key];
      }
    }

    const utmReferrer = document.referrer;
    if (!newParams.utm_referrer && utmReferrer) {
      newParams.utm_referrer = utmReferrer;
    }

    if (_params.reseller === 'true') {
      newParams.reseller = 'true';
    }

    if (_params.rf) {
      newParams.rf = _params.rf;
    }

    setParams(newParams);
  }, []);

  const handleUserSubmit = async data => {
    if (!recaptcha) {
      setNoRecaptcha(true);
    }

    if (!accepted) {
      setNotAccepted(true);
    }

    if (!accepted || !recaptcha) {
      return;
    }

    const payload = {
      ...data,
      referral: params.rf,
      company: theme.code,
      recaptcha: process.env.NODE_ENV === 'development' ? 'SKIP' : recaptcha,
    };

    try {
      setLoading(true);

      if (theme.slug === 'configr') {
        setLoading(false);
        return;
      }

      const { data } = await api.post('/auth/signup/', payload, {
        params,
      });

      const { token } = data;

      finishLogin(token, data.user);

      setLoading(false);

      const { fbq } = window as any;
      const { gtag } = window as any;

      if (fbq) {
        fbq('track', 'Lead');
      }

      if (gtag) {
        gtag('event', 'track', { event_category: 'Lead' });
      }

      nextStage();
    } catch (e) {
      setNoRecaptcha(false);
      setLoading(false);
      toast.error(e?.response?.data[Object.keys(e?.response?.data)[0]][0]);
    }
  };

  const handleRecaptcha = value => {
    setRecaptcha(value);
  };

  const props1 = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 1000 },
  });

  const props2 = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 1000 },
  });

  return (
    <>
      {theme.loading ? (
        <animated.div style={props2}>
          <ReactLoading
            type="balls"
            // style={{
            //   margin: '0 auto',
            //   fill: '#353FF2',
            //   width: '100px',
            //   height: '100px',
            // }}
          />
        </animated.div>
      ) : (
        <>
          <Header>
            <Progress status={stage} n={3} />
          </Header>

          <animated.div
            style={props1}
            // immediate={theme.loading.toString()}
          >
            <Wrapper>
              <Content>
                <div>
                  <h1>Crie sua conta</h1>
                  <h4>Relaxa! Não pediremos nenhum dado do seu cartão.</h4>
                </div>
                <Form onSubmit={handleUserSubmit} schema={schema}>
                  <Field>
                    <Label
                      dark
                      style={{
                        fontSize: '14px',
                      }}
                    >
                      Qual seu nome?
                    </Label>
                    <FormInput
                      block
                      height="50px"
                      name="full_name"
                      placeholder="Nome"
                    />
                  </Field>
                  <Field>
                    <Label
                      dark
                      style={{
                        fontSize: '14px',
                      }}
                    >
                      Qual seu email?
                    </Label>
                    <FormInput
                      block
                      height="50px"
                      type="email"
                      name="email"
                      placeholder="Email"
                    />
                  </Field>
                  <Field>
                    <Label
                      dark
                      style={{
                        fontSize: '14px',
                      }}
                    >
                      Digite uma senha
                    </Label>
                    <FormInput
                      block
                      height="50px"
                      type={showPw ? 'text' : 'password'}
                      name="password1"
                      placeholder="Senha"
                      onChange={e => setPassword(e.target.value)}
                    />
                    <FontAwesomeIcon
                      className="eye"
                      icon={showPw ? faEyeSlash : faEye}
                      onClick={() => setShowPw(!showPw)}
                    />
                    <PasswordStrength password={password} />
                  </Field>
                  <Field>
                    <Label
                      dark
                      style={{
                        fontSize: '14px',
                      }}
                    >
                      Repita sua senha
                    </Label>
                    <FormInput
                      block
                      height="50px"
                      type={showPwRep ? 'text' : 'password'}
                      name="password2"
                      placeholder="Repita a senha"
                      onChange={e => setPassword2(e.target.value)}
                    />
                    <FontAwesomeIcon
                      className="eye"
                      icon={showPwRep ? faEyeSlash : faEye}
                      onClick={() => setShowPwRep(!showPwRep)}
                    />
                    <PasswordStrength password={password2} />
                  </Field>
                  <Field>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                      }}
                    >
                      <Checkbox
                        block
                        checked={accepted}
                        value={accepted}
                        onChange={e => {
                          setAccepted(e.target.checked);
                          setNotAccepted(!e.target.checked);
                        }}
                      />
                      <Label
                        dark
                        style={{
                          fontSize: '14px',
                          margin: '0',
                        }}
                        className="ml-2"
                      >
                        Eu aceito todos os{' '}
                        <a
                          target="_blank"
                          href={
                            theme.slug === 'configr'
                              ? 'https://configr.com/termos-de-uso/'
                              : 'https://cloudez.io/br/termos-legais/'
                          }
                          rel="noreferrer"
                        >
                          termos e condições
                        </a>
                      </Label>
                    </div>
                    {notAccepted && (
                      <span style={{ display: 'block' }}>
                        Você deve aceitar os termos e condições
                      </span>
                    )}{' '}
                  </Field>

                  <Field>
                    <ReCAPTCHA
                      sitekey="6LevzLsUAAAAAC7u1_NAdfbn_spDuO2gHE91nLHm"
                      onChange={handleRecaptcha}
                    />
                    {noRecaptcha && <span>Recaptcha é obrigatório</span>}{' '}
                  </Field>
                  <Button
                    icon
                    width="100%"
                    height="50px"
                    disabled={loading}
                    type="submit"
                  >
                    {loading ? (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                      'Cadastrar'
                    )}
                  </Button>

                  <Link
                    style={{
                      color: theme.brand_primary_color,
                      display: 'block',
                      fontSize: '13px',
                      marginTop: '10px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                    to="/"
                  >
                    JÁ POSSUO LOGIN
                  </Link>
                </Form>
              </Content>
            </Wrapper>
          </animated.div>
        </>
      )}
    </>
  );
};

export default SignUp;
