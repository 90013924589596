import styled, { css } from 'styled-components';

interface OfflineButtonProps {
  offline?: boolean;
}

export const OfflineButton = styled.button.attrs({
  icon: true,
})<OfflineButtonProps>`
  padding: 11px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  transition: all 0.2s ease-in;

  ${props =>
    props.offline
      ? css`
          background: ${props => props.theme.brand_primary_color};
          border: 1px solid transparent;

          svg {
            color: ${props => props.theme.brand_secondary_color};
          }

          &:hover {
            border: 1px solid ${props => props.theme.brand_primary_color};
            background: none;

            svg {
              color: ${props => props.theme.brand_primary_color};
            }
          }
        `
      : css`
          background: none;
          border: 1px solid ${props => props.theme.brand_primary_color};

          svg {
            color: ${props => props.theme.brand_primary_color};
          }

          &:hover {
            border: 1px solid transparent;
            background: ${props => props.theme.brand_primary_color};

            svg {
              color: ${props => props.theme.brand_secondary_color};
            }
          }
        `}
`;
