import styled, { css } from 'styled-components';

export const Buttons = styled.div`
  margin: 30px auto 0 auto;

  display: flex;
  flex-direction: column;

  width: 320px;

  button {
    font-size: 14px;
    text-transform: none;
    margin-top: 10px;
  }
`;

export const Badges = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-evenly;
  margin: 50px 0;
  position: relative;

  .line {
    position: absolute;
    top: 50%;
    height: 2px;
    background: ${props => props.theme.interface3};
    width: 69%;
    margin: 0 auto;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;

    div {
      margin-bottom: 12px;
    }

    .line {
      display: none;
    }
  }

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;

    span {
      font-size: 17px;
      color: ${props => props.theme.interface5};
    }

    img {
      width: 46px;
      margin-top: 6px;
    }

    img ~ span {
      font-size: 15px;
      margin-top: 6px;
    }
  }
`;

export const Text = styled.span`
  display: block;
  text-align: center;
  max-width: 500px;

  margin: 30px auto;

  font-size: 17px;
  color: ${props => props.theme.interface5};
  font-weight: bold;
`;

export const Subtitle = styled.p`
  line-height: 1.5;
  color: ${props => props.theme.interface5};

  text-align: center;

  max-width: 500px;
  margin: auto;
`;

export const Title = styled.h1`
  color: ${props =>
    props.theme.darkMode ? props.theme.interface4 : '#333333'};
  font-weight: 900;
  font-size: 2.1em;
  text-align: center;
  margin-bottom: 10px;
`;

export const ContentWrapper = styled.div`
  height: 100%;
  width: 60%;
  margin: auto;
  padding-top: 20px;

  @media (max-width: 1000px) {
    padding: 20px 0;
    height: auto;
    width: 100%;
    /* padding: 0px 20px 20px 20px; */
  }
`;

export const Infos = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 46px;

  @media (max-width: 1000px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin: 0 20px; */
    padding: 0 10px;
  }
`;

export const InfoBox = styled.div`
  display: flex;
  position: relative;

  max-width: 400px;
  height: 142px;
  ${props =>
    props.left &&
    css`
      margin-right: 20px;
    `};
  background-color: ${props => props.theme.interface2};
  border: 1px solid #e6e6e6;
  border-radius: 5px;

  div.full {
    padding: 18px 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    p {
      color: ${props =>
        props.theme.darkMode ? props.theme.interface5 : props.theme.interface7};
      font-weight: bold;
      font-size: 17px;
      margin-bottom: 5px;
    }

    p.description {
      font-weight: normal;
      font-size: 15px;
    }
  }

  div.column {
    width: 50%;
    padding: 18px 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    text-align: center;

    ${props =>
      props.left &&
      css`
        color: ${props.theme.brand_primary_color};
      `}

    ${props =>
      props.left &&
      css`
        p {
          font-weight: bold;
          font-size: 16px;

          &.description {
            margin-top: 5px;
            font-size: 12px;
            line-height: 20px;
          }
        }
      `}
  }

  div.separator {
    /* width: 1px; */
    height: 50px;

    border: 0.5px solid #d8c8ed;

    position: absolute;
    top: calc(50% - 25px);
    left: 50%;
  }

  div.item {
    display: flex;
    justify-content: space-between;
    color: ${props => props.theme.interface5};

    &:not(.last) {
      margin-bottom: 16px;
    }

    svg {
      width: 20%;
      color: #28d875;
    }
  }

  @media (max-width: 1000px) {
    ${props =>
      props.left &&
      css`
        margin-right: 0;
        margin-bottom: 10px;
      `};
  }
`;
