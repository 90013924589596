import { Reducer } from 'react';
import { IActionPayload } from 'types';
import { WebsiteDetailState } from './state';

const reducer: Reducer<WebsiteDetailState, IActionPayload> = (
  state,
  action,
): WebsiteDetailState => {
  switch (action.type) {
    case 'GET_WEBSITE':
    case 'UPDATE_VALUE':
    case 'UPDATE_FIELD':
    case 'PATCH_WEBSITE':
      return {
        ...state,
        website: action.payload,
      };

    case 'SET_LOADING':
      return {
        ...state,
        updateLoading: action.payload,
      };

    case 'SET_ERRORS': {
      return {
        ...state,
        errors: action.payload,
      };
    }

    // case 'SET_ORIGINAL_WEBSITE': {
    //   return {
    //     ...state,
    //     originalWebsite: JSON.parse(JSON.stringify(action.payload)),
    //   };
    // }

    default: {
      return state;
    }
  }
};

export default reducer;
