import {
  faCheckCircle,
  faEnvelope,
  faStar,
  faTimesCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useState,
  useEffect,
} from 'react';
import { ReactComponent as Cloud } from 'assets/img/svg/nuvem.svg';
import { ReactComponent as Rocket } from 'assets/img/svg/rocket.svg';
import { CloudCreateContext } from '../_context/state';

import * as Styles from './styles';
import { Infos } from './styles';
import DetailsCard from '../DetailsCard';

interface IDetailsProps {
  setStage: Dispatch<SetStateAction<number>>;
  stage: number;
}

const Details: React.FC<IDetailsProps> = ({ setStage, stage }) => {
  const { setPlanType, planType, plan } = useContext(CloudCreateContext);

  const topics = [
    'Banco de dados',
    'HTTPS Gratuito',
    'Definição de limite de memória',
    '100k Mail gateway gratuito*',
    'Acesso Root',
    'PageSpeed Optimizer',
    'Backup automático',
    'Prevenção de blocklists',
    '100GB CDN + Cache Premium*',
  ];

  const emailTopics = [
    'Webmail',
    'Edição de SPF, DKIM e DMARC',
    'Antispam',
    'Gerenciadores de contas e grupos',
    'Acessos SMTP, POP e IMAP',
    'Configurações avançadas de segurança',
  ];

  const [hideContent, setHideContent] = useState<boolean>(false);

  useEffect(() => {
    if (!plan.has_turbo && plan?.cloud_size?.category !== 3 && stage === 3) {
      setPlanType('basic');
    } else if (plan?.cloud_size?.category === 3 && stage === 3) {
      setPlanType('email');
    } else if (stage === 3) {
      setPlanType('turbo');
    } else {
      setPlanType(null);
    }
  }, [plan]);

  return (
    <Styles.Wrapper>
      {!hideContent && (
        <Styles.PlanTypes>
          <Styles.Title>Tipo de plano</Styles.Title>
          {plan?.cloud_size?.category === 3 && (
            <Styles.PlanTypeCard
              style={{ height: 'fit-content' }}
              data-testid="basicCard"
              selected={!plan.has_turbo || planType === 'basic'}
              onClick={() => {
                setPlanType('basic');
              }}
            >
              <div className="radio">
                <div className="selection" />
                <FontAwesomeIcon icon={faEnvelope} />
                <span>EMAIL</span>
              </div>

              <div className="content">
                {emailTopics.map(topic => (
                  <div className="box">
                    <span key={topic}>
                      <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />
                    </span>
                    <span>{topic}</span>
                  </div>
                ))}
              </div>
              <Infos>
                <p className="alert">IMPORTANTE: </p>
                <p>
                  Não é possível realizar downgrade em clouds de e-mail
                  posteriormente.
                </p>
              </Infos>
            </Styles.PlanTypeCard>
          )}
          {plan.has_turbo && (
            <Styles.PlanTypeCard
              data-testid="turboCard"
              turbo
              selected={planType === 'turbo'}
              onClick={() => {
                setPlanType('turbo');
              }}
            >
              {plan.has_basic && (
                <Styles.Best>
                  <FontAwesomeIcon icon={faStar} />
                  Mais Vantajoso
                </Styles.Best>
              )}

              <div className="radio">
                <div className="selection" />
                <Rocket />
                <span>TURBO</span>
              </div>
              <div className="content">
                {topics.map(topic => (
                  <div className="box">
                    <span key={topic} className="green">
                      <FontAwesomeIcon icon={faCheckCircle} className="mr-1" />
                    </span>
                    <span>{topic}</span>
                  </div>
                ))}
              </div>
            </Styles.PlanTypeCard>
          )}
          {plan.has_basic && (
            <Styles.PlanTypeCard
              data-testid="basicCard"
              selected={!plan.has_turbo || planType === 'basic'}
              onClick={() => setPlanType('basic')}
            >
              <div className="radio">
                <div className="selection" />
                <Cloud />
                <span>BASIC</span>
              </div>

              <div className="content">
                {topics.map((topic, index) => (
                  <div className="box">
                    <span
                      key={topic}
                      className={[5, 8].includes(index) ? 'red' : 'green'}
                    >
                      {[5, 8].includes(index) ? (
                        <FontAwesomeIcon
                          icon={faTimesCircle}
                          className="mr-1"
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="mr-1"
                        />
                      )}
                    </span>
                    <span>{topic}</span>
                  </div>
                ))}
              </div>
            </Styles.PlanTypeCard>
          )}
        </Styles.PlanTypes>
      )}
      <Styles.Details data-testid="detailsCard">
        <DetailsCard
          setStage={setStage}
          onToggle={() => setHideContent(state => !state)}
        />
      </Styles.Details>
    </Styles.Wrapper>
  );
};

export default Details;
