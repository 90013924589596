import React, { useState, useContext } from 'react';
import { withTheme } from 'styled-components';
import { Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPowerOff, faSpinner } from '@fortawesome/free-solid-svg-icons';

import { Modal, Input, Button } from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from 'components/ListingModal/styles';

import { useLayout } from 'hooks/layout';
import { HttpsText } from './styles';
import { WebsiteDetailContext } from '../_context/state';

interface IOfflineModalProps {
  action: any;
  show: boolean;

  setShow: any;
}

const OfflineModal: React.FC<IOfflineModalProps> = ({
  action,
  show,
  setShow,
}) => {
  const [desabilitar, setDesabilitar] = useState('');
  const [loading, setLoading] = useState(false);
  const theme = useLayout();

  const { website } = useContext(WebsiteDetailContext);

  return (
    <Modal width="455px" show={show} setShow={setShow} noExit={loading}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faPowerOff} />
        </Icon>
        <Text>
          {website.is_disabled ? (
            <>
              <Title>Habilitar Website</Title>
              <Subtitle>Deseja habilitar este website?</Subtitle>
            </>
          ) : (
            <>
              <Title>Desabilitar Website</Title>
              <Subtitle>Deseja desabilitar este website?</Subtitle>
            </>
          )}
        </Text>
      </Header>

      <Content style={{ padding: '17px 27px' }}>
        <HttpsText style={{ marginBottom: '24px' }}>
          Por favor, escreva{' '}
          <span
            style={{
              textTransform: 'uppercase',
              fontWeight: 'bold',
              color: website.is_disabled
                ? theme.brand_primary_color
                : theme.error,
            }}
          >
            {website.is_disabled ? 'HABILITAR' : 'DESABILITAR'}
          </span>
          , para confirmar a ação.
        </HttpsText>
        <Input
          block
          placeholder={website.is_disabled ? 'HABILITAR' : 'DESABILITAR'}
          value={desabilitar}
          onChange={e => {
            setDesabilitar(e.target.value);
          }}
        />
      </Content>
      <Footer>
        <Button
          width="149px"
          icon
          disabled={
            (website.is_disabled
              ? desabilitar !== 'HABILITAR'
              : desabilitar !== 'DESABILITAR') || loading
          }
          error={!website.is_disabled}
          onClick={async () => {
            setLoading(true);
            await action('is_disabled', !website.is_disabled);
            setShow();
          }}
        >
          {loading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : website.is_disabled ? (
            'Habilitar'
          ) : (
            'Desabilitar'
          )}
        </Button>
      </Footer>
    </Modal>
  );
};

export default OfflineModal;
