import React, { useState } from 'react';

import { Row, Col } from 'react-bootstrap';

import debugModal from 'assets/img/svg/debug-modal.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBug, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@cloudez/cloudez-design-system';

import { getValue } from 'utils/getValue';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from '../styles';

import { DebugText } from './styles';

const DebugModal = ({ website, action, setShow, loading, setLoading }) => {
  // const [loading, setLoading] = useState(false);

  const debug = getValue(website, 'debug');

  const handleAction = async () => {
    setLoading(true);
    const values = website.values.map(value =>
      value.slug === 'debug'
        ? (value = {
            ...value,
            value: value.value === 'true' ? 'false' : 'true',
          })
        : value,
    );
    await action('values', values, website.id);
    setLoading(false);
    setShow(false);
  };

  return (
    <>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faBug} />
        </Icon>
        <Text>
          <Title>Debug</Title>
          <Subtitle>
            Ative para visualizar os erros que podem estar ocorrendo.
          </Subtitle>
        </Text>
      </Header>
      <Content>
        <DebugText>
          <span>
            {' '}
            Ao ativar a depuração de erros, assim que acessar seu site, caso
            existam erros, os mesmos serão exibidos.
          </span>
          <span>Utilize apenas para resolução rápida de problemas.</span>
          <span>
            {' '}
            O ideal é sempre ativar a depuração <b>
              por um período específico
            </b>{' '}
            apenas, logo depois desativando-o quando não for mais necessário.
            Assim, você garante a devida depuração e{' '}
            <b>não compromete a performance do sistema.</b>
          </span>
        </DebugText>
      </Content>
      <Footer>
        {debug && (
          <Button width="149px" onClick={handleAction} icon>
            {loading ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : debug === 'true' ? (
              'Desativar'
            ) : (
              'Ativar'
            )}
          </Button>
        )}
      </Footer>
    </>
  );
};

export default DebugModal;
