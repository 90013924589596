import React from 'react';

import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Modal, Button } from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Footer,
} from 'components/ListingModal/styles';

import { Content } from './styles';

interface DuplicatedEmailsModalProps {
  show: boolean;
  setShow: (value: boolean) => void;
  action: any;
  dados: any;
}

const DuplicatedEmailsModal = ({
  show,
  setShow,
  action,
  dados,
}: DuplicatedEmailsModalProps): JSX.Element => {
  return (
    <Modal width="455px" show={show} setShow={setShow}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faExclamation} />
        </Icon>
        <Text>
          <Title>Email duplicado</Title>
        </Text>
      </Header>

      <Content>
        <span>
          <b className="attention">Atenção:</b> Há uma ou mais contas de e-mails
          duplicadas. Deseja seguir a migração mesmo assim ou analisar?
        </span>
      </Content>
      <Footer hasLink>
        <Button
          onClick={() => {
            action(dados, true);
            setShow(false);
          }}
        >
          Ignorar
        </Button>
        <Button onClick={() => setShow(false)} icon>
          Analisar
        </Button>
      </Footer>
    </Modal>
  );
};

export default DuplicatedEmailsModal;
