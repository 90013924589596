import api from 'services/api';

export const getEmailsService = async (
  params,
  ctrl = new AbortController(),
) => {
  try {
    return await api.get('/v3/email/', { params, signal: ctrl.signal });
  } catch (e) {
    throw e;
  }
};

export const getEmailService = async (id, params?) => {
  try {
    return await api.get(`/v3/email/${id}/`, { params });
  } catch (e) {
    throw e;
  }
};

export const createEmailBulkService = async data => {
  try {
    return await api.post(`v3/email/account/bulk_create/`, data);
  } catch (e) {
    throw e;
  }
};

export const deleteEmailService = async id => {
  try {
    return await api.delete(`/v3/email/${id}/`);
  } catch (e) {
    throw e;
  }
};

export const updateEmailService = async (id, data) => {
  try {
    return await api.patch(`/v3/email/${id}/`, data);
  } catch (e) {
    throw e;
  }
};

export const createEmailService = async data => {
  try {
    return await api.post(`/v3/email/`, data);
  } catch (e) {
    throw e;
  }
};

export const getEmailGroupsService = async params => {
  try {
    return await api.get('/v3/email-group/', { params });
  } catch (e) {
    throw e;
  }
};

export const deleteEmailGroupService = async id => {
  try {
    return await api.delete(`/v3/email-group/${id}/`);
  } catch (e) {
    throw e;
  }
};

export const updateEmailGroupService = async (id, data) => {
  try {
    return await api.patch(`/v3/email-group/${id}/`, data);
  } catch (e) {
    throw e;
  }
};

export const createEmailGroupService = async data => {
  try {
    return await api.post(`/v3/email-group/`, data);
  } catch (e) {
    throw e;
  }
};

export const getEmailAccountsService = async params => {
  try {
    return await api.get('/v3/email/account/', { params });
  } catch (e) {
    throw e;
  }
};

export const deleteEmailAccountService = async id => {
  try {
    return await api.delete(`/v3/email/account/${id}/`);
  } catch (e) {
    throw e;
  }
};

export const updateEmailAccountService = async (id, data) => {
  try {
    return await api.patch(`/v3/email/account/${id}/`, data);
  } catch (e) {
    throw e;
  }
};

export const createEmailAccountService = async data => {
  try {
    return await api.post(`/v3/email/account/`, data);
  } catch (e) {
    throw e;
  }
};

export const getEmailAccountAutoReplysService = async params => {
  try {
    return await api.get('/v3/email-account-autoreply/', { params });
  } catch (e) {
    throw e;
  }
};

export const deleteEmailAccountAutoReplyService = async id => {
  try {
    return await api.delete(`/v3/email-account-autoreply/${id}/`);
  } catch (e) {
    throw e;
  }
};

export const updateEmailAccountAutoReplyService = async (id, data) => {
  try {
    return await api.patch(`/v3/email-account-autoreply/${id}/`, data);
  } catch (e) {
    throw e;
  }
};

export const createEmailAccountAutoReplyService = async data => {
  try {
    return await api.post(`/v3/email-account-autoreply/`, data);
  } catch (e) {
    throw e;
  }
};

export const createEmailAccountPasswordService = async data => {
  try {
    return await api.post('/v3/email-account-password/', data);
  } catch (e) {
    throw e;
  }
};

export const getEmailTypesService = async params => {
  try {
    return await api.get('/v3/email-type/', { params });
  } catch (e) {
    throw e;
  }
};
