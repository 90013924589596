import { faCheck, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@cloudez/cloudez-design-system';
import { FreeDays } from 'pages/Onboard/styles';
import styled, { css } from 'styled-components';

export const Card = styled.div`
  height: 100%;
  width: 100%;
  background: ${props => props.theme.interface1};
  border-radius: 5px;
`;

export const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 30px;
  padding: 24px 12px;
  font-size: 18px;
  color: ${props => props.theme.interface5};
`;

export const CardBody = styled.div`
  width: 100%;
  height: auto;
  padding: 6px 14px;

  a {
    text-align: center;
  }
`;

export const Th = styled.th`
  padding: 8px;
  font-weight: normal;
  font-size: 12px;
  background: ${props => props.theme.interface2};
  color: ${props => props.theme.interface5};
`;

interface ITrProps {
  name?: string;
}

export const Tr = styled.tr<ITrProps>`
  ${props =>
    props.name === 'Total' &&
    css`
      background: #2253f5;

      ${TdTitle} {
        color: #7be8ff;
        text-transform: uppercase;
        font-weight: bold;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
      }

      ${TdFinal} {
        color: #7be8ff;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
      }

      ${TdContent} {
        color: #7be8ff;
      }
    `}
`;

export const TdTitle = styled.td`
  padding: 10px 12px;
  font-size: 14px;
  color: ${props => props.theme.interface5};
`;

export const TdContent = styled.td`
  padding: 10px 12px;
  font-size: 14px;
  color: ${props => props.theme.interface5};
  font-weight: bold;
  text-align: center;
`;

export const TdFinal = styled.td`
  padding: 10px 12px;
  font-size: 14px;
  color: ${props => props.theme.interface5};
  font-weight: bold;
  text-align: center;
`;

export const Clouds = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const Total = styled.p`
  font-size: 24px;
  font-weight: 900;
  margin-bottom: 8px;
  color: ${props =>
    props.theme.darkMode ? props.theme.interface4 : props.theme.interface6};
`;

export const Individual = styled.div`
  display: flex;
  width: 80%;
  justify-content: space-between;
  span {
    font-size: 12px;
    font-weight: 700;
  }
`;

export const Statistics = styled.ul`
  height: 100%;
  width: 100%;

  li {
    font-size: 11px;
    color: ${props => props.theme.interface5};
    margin-bottom: 10px;
  }
`;

export const Top = styled.ul`
  li {
    cursor: pointer;
  }
`;

export const Value = styled.div`
  margin: 0;
  /* font-weight: 200; */
  font-size: 12px;

  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${props => props.theme.interface5};
  }

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: ${props => props.theme.interface4};
  }
`;

export const Domains = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

  div {
    height: 1px;
    width: 100%;
    background: ${props =>
      props.theme.darkMode ? props.theme.interface7 : props.theme.interface4};
  }

  p {
    font-size: 20px;
    color: gray;

    span {
      font-size: 45px;
      color: ${props => props.theme.success};
      font-weight: 900;
    }
  }

  span {
    font-size: 20px;
    color: gray;

    span {
      font-size: 32px;
      color: ${props => props.theme.brand_primary_color};
      font-weight: 900;
    }
  }
`;

export const HTTPS = styled.div`
  width: 100%;
  height: 100%;

  p {
    text-align: center;

    font-size: 65px;
    color: ${props => props.theme.brand_primary_color};
    font-weight: 900;
    margin-bottom: 1rem;
  }

  div {
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    p {
      font-size: 18px;
      color: ${props => props.theme.brand_primary_color};
      font-weight: 400;
      display: block;
      text-align: center;

      span {
        display: block;
        color: ${props => props.theme.interface5};
        font-size: 12px;
      }
    }
  }
`;

export const P = styled.p`
  font-size: 11px;
  color: ${props => props.theme.interface5};
`;

export const TrialDashboard = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin-bottom: 30px;

  p {
    position: absolute;
    bottom: -20px;
    color: ${props => props.theme.interface5};
  }

  ${FreeDays} {
    margin-bottom: 10px;
  }

  @media (max-width: 768px) {
    flex-direction: column;

    p {
      bottom: -40px;
    }
  }
`;

export const TrialButtons = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;

  div {
    display: flex;
    align-items: center;

    button {
      margin-right: 5px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    margin-left: 0;

    div {
      width: 100%;

      button {
        margin-bottom: 16px;
        width: 100%;
      }
    }
  }
`;

interface IDoneProps {
  verifying?: boolean;
  done?: boolean;
}

export const Done = styled.div<IDoneProps>`
  display: flex;
  align-items: center;
  /* justify-content: space-around; */

  max-width: 160px;

  font-size: 13px;
  font-weight: 900;
  text-decoration: ${({ verifying }) => verifying && 'underline'};
  color: ${({ theme, verifying }) =>
    verifying ? theme.favoriteNew : theme.successNew};

  svg {
    margin-left: 5px;
  }

  ${({ verifying }) =>
    verifying &&
    css`
      svg {
        font-size: 18px;
      }
    `}
`;

interface ITrialButtonProps {
  done?: boolean;
  theme: any;
}

export const TrialButton = styled(Button).attrs<ITrialButtonProps>(props => ({
  alert: !props.done,
  success: props.done,
}))`
  width: 150px;
`;

export const Status = styled(FontAwesomeIcon).attrs(
  (props: ITrialButtonProps) => ({
    icon: props.done ? faCheck : faExclamation,
  }),
)`
  color: ${(props: ITrialButtonProps) =>
    props.done ? props.theme.successNew : props.theme.alertNew};
`;
