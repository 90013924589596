import React, { useContext, useMemo } from 'react';
import { AuthContext } from '../context/auth';

export function userCan({ roles, permissions }) {
  const { user, isAuthenticated } = useContext(AuthContext);

  const rolesToSkip = useMemo(
    () => ['company_owner_role', 'customer_role', 'god_role'],
    [],
  );

  if (!isAuthenticated) {
    return false;
  }

  if (
    user?.roles.some(
      role => rolesToSkip.includes(role) || role.includes('admin_'),
    )
  ) {
    return true;
  }

  if (roles?.length > 0) {
    const hasAllRoles = roles.some(role => user?.roles.includes(role));
    if (!hasAllRoles) {
      return false;
    }
  }

  if (permissions?.length > 0) {
    const hasAllPermissions = permissions.every(
      permission => user?.permissions[permission],
    );

    if (!hasAllPermissions) {
      return false;
    }
  }

  return true;
}
