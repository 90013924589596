import React, { useState } from 'react';
import { Button, Error, Modal } from '@cloudez/cloudez-design-system';
import NodeappField from 'components/NodeappField';

import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPuzzlePiece, faSpinner } from '@fortawesome/free-solid-svg-icons';

import {
  Header,
  Icon,
  Text,
  Title,
  Content,
  Footer,
} from 'components/ListingModal/styles';
import { createAddonService } from 'services/cloud';

const OptionsModal = ({
  addon,
  show,
  setShow,
  cloud,
  setAddAddon,
  setAddons,
  addons,
}) => {
  const [_addon, setAddon] = useState(addon);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);

  const hasOptions = !!_addon?.values.length;

  const changeValue = e => {
    const values = _addon.values.map(_value => {
      if (_value.slug === e.target.name) {
        _value.value =
          e.target.type === 'checkbox'
            ? e.target.checked.toString()
            : e.target.value;
        return _value;
      }
      return _value;
    });

    setAddon({
      ..._addon,
      values,
    });
  };

  const createAddon = async () => {
    setLoading(true);
    const payload = {
      cloud,
      values: _addon.values.map(v => ({
        slug: v.slug,
        value: v.value || v.default,
      })),
      type: _addon.id,
      is_pinned: false,
    };

    try {
      const { data } = await createAddonService(payload);
      toast.success('Addon adicionado com sucesso.');

      const newAddon = {
        ...data,
        type: _addon,
      };

      setAddons([...addons, newAddon]);

      setLoading(false);
      setAddAddon(false);
      setShow(false);
    } catch (e) {
      setLoading(false);
      setErrors(e?.response?.data[Object.keys(e?.response?.data)[0]]);
      toast.error(
        e?.response?.data?.[Object.keys(e?.response?.data)?.[0]]?.[0],
      );
    }
  };

  return (
    <Modal show={show} setShow={setShow} noExit={loading}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faPuzzlePiece} />
        </Icon>
        <Text>
          <Title>Atualizar addon</Title>
        </Text>
      </Header>

      <Content>
        {hasOptions &&
          _addon?.values.map(v => {
            return (
              <>
                <NodeappField
                  key={v.slug}
                  field={v}
                  value={v.value || v.default}
                  onChange={changeValue}
                  nodeapp={false}
                  block
                />
                {errors?.[`${v.slug}`] && (
                  <Error
                    style={{
                      transform: 'translateY(-16px)',
                    }}
                  >
                    {errors?.[`${v.slug}`][0]}
                  </Error>
                )}
              </>
            );
          })}
      </Content>
      <Footer>
        <Button disabled={loading} outline onClick={createAddon} icon>
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Salvar'}
        </Button>
      </Footer>
    </Modal>
  );
};

export default OptionsModal;
