import React, { createContext, useReducer } from 'react';
import reducer from './reducer';
import {
  setLoading,
  updateField,
  updateNewEmail,
  createEmail,
} from './actions';

export interface EmailCreateContext {
  newEmail: {
    cloud: any;
    domain: string;
    type: any;
    disk_usage: number | string;
    values: any[];
    id: string | number;
  };
  errors: any;
  loading: boolean;
  setLoading(value: boolean): void;
  createEmail(email: any, buyDomain: any): Promise<void>;
  updateField(field: string, value: any): void;
  updateNewEmail(email: any): void;
}

// initial state
const initialState = {
  newEmail: {
    cloud: null,
    domain: '',
    type: null,
    disk_usage: null,
    values: [],
  },
  errors: null,
  loading: false,
};

// context
export const EmailCreateContext = createContext<EmailCreateContext>(
  initialState as EmailCreateContext,
);

// provider
export const EmailCreateProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(
    reducer,
    initialState as EmailCreateContext,
  );

  return (
    <EmailCreateContext.Provider
      value={{
        // state
        newEmail: state.newEmail,
        errors: state.errors,
        loading: state.loading,

        // actions
        setLoading: value => setLoading(dispatch, value),
        createEmail: async (email, buyDomain) =>
          await createEmail(dispatch, email, buyDomain),
        updateField: (field, value) =>
          updateField(state, dispatch, field, value),
        updateNewEmail: database => updateNewEmail(dispatch, database),
      }}
    >
      {children}
    </EmailCreateContext.Provider>
  );
};
