/* eslint-disable prettier/prettier */
import React, { useState, useContext } from 'react';

import moment from 'moment';

import { ThemeContext } from 'styled-components';
import { Col, Row } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCommentAlt,
  faEnvelope,
  faWindowMaximize,
  faThumbsUp,
  faThumbsDown,
} from '@fortawesome/free-solid-svg-icons';

import history from 'services/history';

import { Acesso, AcessosList, AcessoHover, Value } from 'components/Acessos';
import ApplicationIcon from 'components/ApplicationIcon';
import StatusBox from 'components/StatusBox';

import {
  Card,
  ListingItem,
  ApplicationValue,
  ApplicationName,
  Notification,
} from 'components/Listing/styles';

import { useNotifications } from 'hooks/notifications';
import RatingModal from './RatingModal';

type Migration = {
  id: number;
  created_at: string;
  updated_at: string;
  status: number;
  rating: null | number | boolean;
  dest_app_type: string;
  dest_nodeapp_id?: number;
  dest_zoneapp_id?: number;
  domain: string;
  progress: string;
  stage: string;
  ticket_id?: number;
};

interface MigrationCardProps {
  migration: Migration;
}

const MigrationCard = ({ migration }: MigrationCardProps): JSX.Element => {
  const { patchNotification, notifications } = useNotifications();

  const [showRatingModal, setShowRatingModal] = useState(false);
  const theme = useContext(ThemeContext);

  const createdDate = moment
    .utc(migration.created_at)
    .format('YYYY-MM-DD HH:mm:ss');
  const updatedDate = moment
    .utc(migration.updated_at)
    .format('YYYY-MM-DD HH:mm:ss');

  const createdAt = moment(createdDate).fromNow();
  const updatedAt = moment(updatedDate).fromNow();

  const status = {
    1: 'Em fila',
    2: 'Migrando',
    3: 'Migrando',
    4: 'Finalizado',
    5: 'Cancelado',
    6: 'Migrando',
    7: 'Validando',
  }[migration.status];

  const [migrationItem, setMigrationItem] = useState({});

  const notification = notifications?.filter(
    n => n.resource_id === Number(migration.id) && n.status === 0,
  );

  return (
    <>
      {showRatingModal && (
        <RatingModal
          migrationItem={migrationItem}
          show={showRatingModal}
          setShow={setShowRatingModal}
        />
      )}
      <ListingItem key={migration.id}>
        <Card onClick={() => history.push(`/migrations/${migration.id}`)}>
          {notification?.length > 0 && (
            <Notification
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();
                patchNotification({
                  id: notification[0].id,
                  data: { status: 1 },
                });
              }}
            />
          )}

          <Row>
            <Col xs="8" sm="8" md="4" lg="4" xl="3">
              <ApplicationValue>
                <ApplicationIcon
                  width="40px"
                  height="40px"
                  icon={
                    migration.dest_app_type === 'email'
                      ? 'otherExistingEmail'
                      : `${migration.dest_app_type}`
                  }
                />
                <ApplicationName>{migration.domain}</ApplicationName>
              </ApplicationValue>
            </Col>
            <Col xl="1" lg="3" md="3" className="d-none d-sm-none d-md-flex">
              <ApplicationValue>
                <p style={{ fontWeight: 'normal' }}>{migration.progress}</p>
              </ApplicationValue>
            </Col>

            <Col xl="2" lg="4" md="4" className="d-none d-xl-flex">
              <ApplicationValue>
                <p style={{ fontWeight: 'normal' }}>
                  {migration.stage === 'Erro' ? 'Migrando' : migration.stage}
                </p>
              </ApplicationValue>
            </Col>

            <Col className="d-none d-xl-flex">
              <ApplicationValue>
                <p style={{ fontWeight: 'normal' }}>{createdAt}</p>
              </ApplicationValue>
            </Col>

            <Col className="d-none d-xl-flex">
              <ApplicationValue>
                <p style={{ fontWeight: 'normal' }}>{updatedAt}</p>
              </ApplicationValue>
            </Col>
            <Col className="d-none d-sm-flex d-md-flex">
              <ApplicationValue>
                <StatusBox status={migration.status}>
                  <p style={{ fontWeight: 'normal' }}>{status}</p>
                </StatusBox>
              </ApplicationValue>
            </Col>
            <Col className="d-none d-xl-flex">
              {/* {(migration.status === 4 || migration.status === 7) && (
              <ApplicationValue>
                <StatusBox
                  isHovering={
                    migration.rating !== false && migration.rating !== true
                  }
                  onClick={e => {
                    e.stopPropagation();
                    history.push(`/migrations/${migration.id}`);
                  }}
                  outlined
                  status={migration.rating}
                >
                  <FontAwesomeIcon
                    icon={
                      migration.rating
                        ? faThumbsUp
                        : migration.rating === false
                        ? faThumbsDown
                        : null
                    }
                  />
                  <p style={{ fontWeight: 'normal' }}>{rating}</p>
                </StatusBox>
              </ApplicationValue>
            )} */}
              <AcessosList>
                {migration.status === 4 && (
                  <Acesso
                    active
                    hover
                    onClick={e => {
                      e.stopPropagation();
                      migration.rating === null && setShowRatingModal(true);

                      setMigrationItem(migration);
                    }}
                  >
                    <FontAwesomeIcon
                      style={{
                        width: '16px',
                        height: '16px',
                        color:
                          migration.rating === false
                            ? theme.errorNew
                            : migration.rating
                            ? theme.successNew
                            : '',
                      }}
                      icon={
                        migration.rating === false ? faThumbsDown : faThumbsUp
                      }
                    />
                  </Acesso>
                )}

                {migration.ticket_id > 0 && (
                  <Acesso
                    active
                    hover
                    onClick={e => {
                      e.stopPropagation();
                      history.push(`/suporte/${migration.ticket_id}`);
                    }}
                  >
                    <FontAwesomeIcon icon={faCommentAlt} />
                    <AcessoHover>
                      <Value>
                        <span>Ticket</span>
                      </Value>
                    </AcessoHover>
                  </Acesso>
                )}

                <Acesso
                  fixed
                  active
                  hover
                  onClick={e => {
                    e.stopPropagation();
                    history.push(
                      `/${migration.dest_nodeapp_id ? 'websites' : 'emails'}/${
                        migration.dest_nodeapp_id || migration.dest_zoneapp_id
                      }`,
                    );
                  }}
                >
                  {migration.dest_app_type === 'email' ? (
                    <>
                      <FontAwesomeIcon icon={faEnvelope} />
                      <AcessoHover>
                        <Value>
                          <span>Email</span>
                        </Value>
                      </AcessoHover>
                    </>
                  ) : (
                    <>
                      <FontAwesomeIcon icon={faWindowMaximize} />
                      <AcessoHover>
                        <Value>
                          <span>Website</span>
                        </Value>
                      </AcessoHover>
                    </>
                  )}
                </Acesso>
              </AcessosList>
            </Col>

            {/* <Col
            xl="1"
            md="2"
            sm="8"
            xs="8"
            className={!showAccess && 'd-none d-md-flex'}
            onClick={e => {
              e.preventDefault();
              setShowAccess(false);
            }}
            style={{
              background: showAccess && theme.interface3,
              height: '100%',
              width: '100%',
              borderTopRightRadius: '5px',
              borderBottomRightRadius: '5px',
            }}
          >
            
          </Col> */}
          </Row>
        </Card>
      </ListingItem>
    </>
  );
};

export default MigrationCard;
