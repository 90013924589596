import React, { useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBan, faExclamation } from '@fortawesome/free-solid-svg-icons';

import ApplicationIcon from 'components/ApplicationIcon';
import {
  Card,
  ListingItem,
  ApplicationValue,
  ApplicationName,
} from 'components/Listing/styles';

import { SpamBadge } from 'pages/Emails/EmailListing/EmailCard/styles';
import { Button } from '@cloudez/cloudez-design-system';
import { useLayout } from 'hooks/layout';
import history from 'services/history';

const WebsiteCard = ({ website, cloudInfo }) => {
  const [showAccess] = useState(false);

  const theme = useLayout();

  return (
    <ListingItem disable={website.is_disabled}>
      <Link to={`/websites/${website.id}`}>
        <Card style={{ border: '1px solid #E8EAEF', height: '70px' }}>
          <Row>
            <Col xs={showAccess ? '4' : '10'} md="6" xl="10">
              <ApplicationValue style={{ marginLeft: '0px' }}>
                <ApplicationIcon
                  width="30px"
                  height="30px"
                  icon={`${website.type.slug}`}
                  disable={website.is_disabled}
                />
                {!showAccess && (
                  <ApplicationName>
                    {website.domain ? website.domain : website.name}
                    {website.has_spammer && (
                      <SpamBadge>
                        <FontAwesomeIcon icon={faBan} />
                        SPAM
                      </SpamBadge>
                    )}
                  </ApplicationName>
                )}
              </ApplicationValue>
            </Col>
            <Col xs={showAccess ? '4' : '10'} md="6" xl="2">
              <ApplicationValue style={{ marginLeft: '20px' }}>
                {(Number(cloudInfo?.mem) > 80 ||
                  Number(cloudInfo?.disk) > 80 ||
                  Number(cloudInfo?.cpu) > 80) && (
                  <FontAwesomeIcon
                    style={{
                      width: '20px',
                      height: '17px',
                      color: theme.alertNew,
                    }}
                    icon={faExclamation}
                  />
                )}
                <Button
                  onClick={e => {
                    e.preventDefault();
                    history.push('/migrations/create/website', {
                      domain: website.domain,
                    });
                    history.push(
                      `/migrations/create/website?domain=${website.domain}`,
                    );
                  }}
                  outline
                  style={{ width: '89px', height: '32px' }}
                >
                  Isolar
                </Button>
              </ApplicationValue>
            </Col>
          </Row>
        </Card>
      </Link>
    </ListingItem>
  );
};

export default WebsiteCard;
