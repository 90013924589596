import React, { useState, useEffect } from 'react';

import { Row, Col } from 'react-bootstrap';

import { Tabs, Tab, TabContent } from 'components/Tabs';
import {
  Input,
  Label,
  Select,
  Error,
  Field,
} from '@cloudez/cloudez-design-system';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import generatePassword from 'utils/generatePassword';
import { getCloudUsersService } from 'services/cloud';
import { Wrapper, Title, Existent, HelpText } from './styles';

const User = ({ website, updateWebsiteField, errors }) => {
  const [tab, setTab] = useState(1);
  const [choose, setChoose] = useState(false);
  const [user, setUser] = useState({
    id: null,
    username: '',
    is_default: false,
    has_sudo: false,
    has_ssh: true,
    is_pinned: false,
    password: '',
  } as any);
  const [users, setUsers] = useState(null);

  useEffect(() => {
    async function getUsers() {
      try {
        const { data } = await getCloudUsersService({
          has_ssh: true,
          is_default: false,
        });

        const filteredUsers = data.filter(u => u.cloud === website.cloud);
        setUsers(filteredUsers);
      } catch (e) {
        console.log(e);
      }
    }

    if (!users) getUsers();
    // eslint-disable-next-line
  }, []);

  return (
    <Wrapper>
      <Title>Gostaria de criar um usuário para esse website?</Title>

      <Tabs>
        <Tab active={tab === 1} onClick={() => setTab(1)}>
          Sim
        </Tab>
        <Tab active={tab === 2} onClick={() => setTab(2)}>
          Não
        </Tab>
      </Tabs>

      <TabContent>
        {tab === 1 ? (
          <Row
            style={{
              marginLeft: 0,
              width: '100%',
              padding: '8px 30px',
            }}
          >
            {!choose ? (
              <>
                <Col sm="6">
                  <Field>
                    <Label>Usuário</Label>
                    <Input
                      block="true"
                      autoComplete="off"
                      placeholder="usuário"
                      value={user.username}
                      onChange={e => {
                        setUser({ ...user, username: e.target.value });
                        updateWebsiteField('user', user);
                      }}
                      error={!!errors?.user?.username}
                    />
                    {errors?.user?.username && (
                      <Error>{errors?.user?.username[0]}</Error>
                    )}
                  </Field>
                </Col>
                <Col sm="6">
                  <Field>
                    <Label>Senha</Label>
                    <Existent onClick={() => setChoose(true)}>
                      Escolher existente
                    </Existent>
                    <Input
                      block="true"
                      placeholder="senha"
                      value={user.password}
                      onChange={e => {
                        setUser({ ...user, password: e.target.value });
                        updateWebsiteField('user', user);
                      }}
                      error={!!errors?.user?.password}
                    />
                    {errors?.user?.password && (
                      <Error>{errors?.user?.password[0]}</Error>
                    )}
                    <FontAwesomeIcon
                      icon={faKey}
                      onClick={() => {
                        const pass = generatePassword();

                        setUser(_user => {
                          updateWebsiteField('user', {
                            ..._user,
                            password: pass,
                          });
                          return { ..._user, password: pass };
                        });
                      }}
                    />
                  </Field>
                </Col>
              </>
            ) : (
              <Col>
                <Label>Selecionando um usuário</Label>
                <Existent onClick={() => setChoose(false)}>Criar novo</Existent>
                <Select
                  height="40px"
                  value={user.id}
                  onChange={e => {
                    setUser(Number(e.target.value));
                    updateWebsiteField(
                      'user',
                      users.filter(u => u.id === Number(e.target.value))[0],
                    );
                  }}
                >
                  <option value={null}>Selecione um usuário</option>
                  {users?.map(u => (
                    <option key={u.id} value={u.id}>
                      {u.username}
                    </option>
                  ))}
                </Select>
              </Col>
            )}
          </Row>
        ) : (
          <HelpText>
            Um usuário é obrigatório e será criado automaticamente.
          </HelpText>
        )}
      </TabContent>
    </Wrapper>
  );
};

export default User;
