import React, { createContext, useReducer } from 'react';
import { Website } from 'types/website';
import { PaginationType } from '@cloudez/cloudez-design-system';
import reducer from './reducer';
import { setLoading, getWebsites, updateWebsite, updateField } from './actions';

export interface WebsiteListingState extends PaginationType {
  websites: Website[];
  loading: boolean;
  updateLoading: any;
  errors: any;
  selectedWebsites: any;
  getWebsites(
    page: number | string,
    search: any,
    filter: any,
    clouds: any,
    order: any,
    ctrl: AbortController,
  ): void;
  setLoading(value: boolean): void;
  updateWebsite(
    field: string,
    value: any,
    id: number | string,
    website: any,
  ): Promise<void>;
  updateField(field: string, value: any, website: any): void;
}

// initial state
const initialState = {
  websites: null,
  selectedWebsites: [],
  count: null,
  previous: null,
  current: null,
  next: null,
  loading: true,
  updateLoading: false,
  errors: null,
};

// context
export const WebsiteListingContext = createContext<WebsiteListingState>(
  initialState as WebsiteListingState,
);

// provider
export const WebsiteListingProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(
    reducer,
    initialState as WebsiteListingState,
  );

  return (
    <WebsiteListingContext.Provider
      value={{
        // state
        websites: state.websites,
        selectedWebsites: state.selectedWebsites,

        count: state.count,
        previous: state.previous,
        current: state.current,
        next: state.next,

        loading: state.loading,
        updateLoading: state.updateLoading,
        errors: state.errors,

        // actions
        setLoading: value => setLoading(dispatch, value),
        getWebsites: async (page, search, filter, clouds, order, ctrl) =>
          await getWebsites(
            dispatch,
            page,
            search,
            filter,
            clouds,
            order,
            ctrl,
          ),
        updateWebsite: async (field, value, id, website) =>
          await updateWebsite(dispatch, field, value, id, website),
        updateField: (field, value, website) =>
          updateField(dispatch, field, value, website),
      }}
    >
      {children}
    </WebsiteListingContext.Provider>
  );
};
