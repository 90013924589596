import React, { useState } from 'react';
import { withTheme } from 'styled-components';
import { Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faSpinner } from '@fortawesome/free-solid-svg-icons';

import unlink from 'assets/img/svg/unlink.svg';

import { Modal, Input, Button, Label } from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from 'components/ListingModal/styles';

import { SquareCheckbox } from 'components/NewComponents';

import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { HttpsText } from './styles';

const RemoveModal = ({ action, theme, show, setShow }) => {
  const [remove, setRemove] = useState('');
  const [loading, setLoading] = useState(false);
  const [accept, setAccept] = useState(false);

  return (
    <Modal show={show} setShow={setShow} noExit={loading}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faTrashAlt} />
        </Icon>
        <Text>
          <Title>Excluir conta de email</Title>
          <Subtitle>Deseja remover esta conta de email?</Subtitle>
        </Text>
      </Header>
      <Content style={{ padding: '17px 27px' }}>
        <HttpsText style={{ marginBottom: '24px', fontFamily: 'Nunito' }}>
          Por favor, escreva{' '}
          <span
            style={{
              textTransform: 'uppercase',
              fontWeight: 'bold',
              color: theme.error,
            }}
          >
            REMOVER
          </span>
          , para confirmar a exclusão desta conta de email.
        </HttpsText>
        <Input
          block
          placeholder="REMOVER"
          value={remove}
          onChange={e => {
            setRemove(e.target.value);
          }}
        />
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '20px',
          }}
        >
          <SquareCheckbox
            name="AcceptRemove"
            checked={accept}
            value={accept}
            onChange={() => setAccept(!accept)}
          />
          <HttpsText
            style={{ fontFamily: 'Nunito' }}
            onClick={() => setAccept(!accept)}
          >
            Estou ciente que esta ação é{' '}
            <span
              style={{
                textTransform: 'uppercase',
                fontWeight: 'bold',
                color: theme.error,
              }}
            >
              IRREVERSÍVEL
            </span>
            .
          </HttpsText>
        </div>
      </Content>

      <Footer>
        <Button
          width="149px"
          disabled={remove !== 'REMOVER' || accept !== true || loading}
          error
          icon
          onClick={async () => {
            setLoading(true);
            await action();
            setShow();
            setLoading(false);
          }}
        >
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Remover'}
        </Button>
      </Footer>
    </Modal>
  );
};

export default withTheme(RemoveModal);
