import React, { useState, Dispatch, SetStateAction, useContext } from 'react';
import { toast } from 'react-toastify';

import ApplicationIcon from 'components/ApplicationIcon';
import { Button } from '@cloudez/cloudez-design-system';
import history from 'services/history';
import { updateDatabaseService } from 'services/database';
import { Database } from 'types/database';
import RemoveModal from '../RemoveModal';

import { Card, Header, Name, Body } from './styles';
import { WebsiteDetailContext } from '../../_context/state';

interface IDatabaseCardProps {
  db: Database;
  setDatabases: Dispatch<SetStateAction<Database[]>>;
}

const DatabaseCard: React.FC<IDatabaseCardProps> = ({ db, setDatabases }) => {
  const [modal, showModal] = useState(false);
  const { website } = useContext(WebsiteDetailContext);

  const disconnectDatabase = async () => {
    setDatabases(databases => databases.filter(_db => _db.id !== db.id));
    const dbs = db.websites.filter(wb => wb !== website.id);
    await updateDatabaseService(db.id, { websites: dbs });
    showModal(false);
    toast.success('Website desconectado!');
  };

  const name = db?.values.filter(v => v.slug === 'database_name')[0];

  return (
    <Card onClick={() => history.push(`/databases/${db.id}`)}>
      {modal && (
        <RemoveModal
          action={disconnectDatabase}
          show={modal}
          setShow={() => showModal(false)}
        />
      )}
      <Header>
        <ApplicationIcon
          width="30px"
          height="30px"
          icon={`${db.type.slug}`}
          disable={db.is_disabled}
        />
        <Name>
          <span>{name.value}</span>
          <span
            style={{
              fontSize: `${10}px`,
              fontStyle: 'italic',
            }}
          >
            {db.cloud_display}
          </span>
        </Name>
      </Header>
      <Body>
        <Button
          outline
          error
          size="sm"
          style={{ width: `${145}px` }}
          onClick={e => {
            e.stopPropagation();
            showModal(true);
          }}
        >
          Desconectar
        </Button>
        <Button
          disabled={!db?.admin_url}
          size="sm"
          style={{ width: `${145}px` }}
        >
          <a
            onClick={e => {
              e.stopPropagation();
            }}
            href={db?.admin_url?.url || '#!'}
            target="_blank"
            rel="noopener noreferrer"
          >
            ADMIN
          </a>
        </Button>
      </Body>
    </Card>
  );
};

export default DatabaseCard;
