import { toast } from 'react-toastify';
import history from 'services/history';
import { getDomainService, deleteDomainService } from 'services/domain';
import { getWebsitesService } from 'services/website';
import nProgress from 'nprogress';

export const setLoading = (dispatch, value) => {
  dispatch({
    type: 'SET_LOADING',
    payload: value,
  });
};

export const getDomain = async (dispatch, id) => {
  nProgress.start();
  try {
    const { data } = await getDomainService(id);

    dispatch({
      type: 'GET_DOMAIN',
      payload: data,
    });

    getWebsites(dispatch, data.domain);

    nProgress.done();
  } catch (e) {
    nProgress.done();
    if (e.response.status === 404) {
      toast.error('Domínio não encontrado');
      history.push('/404');
    }
  }
};

export const getWebsites = async (dispatch, domain) => {
  try {
    const { data } = await getWebsitesService({
      page_size: 100,
      search: domain,
    });

    dispatch({
      type: 'GET_WEBSITES',
      payload: data,
    });
  } catch (e) {
    if (e.response.status === 404) {
      toast.error('Domínio não encontrado');
      history.push('/404');
    }
  }
};

export const deleteDomain = async (dispatch, id) => {
  try {
    const { data } = await deleteDomainService(id);

    dispatch({
      type: 'DELETE_DOMAIN',
      payload: data,
    });
    toast.error('Domínio removido com sucesso.');
    history.push('/domains');
  } catch (e) {
    if (e.response.status === 404) {
      toast.error('Domínio não encontrado');
      history.push('/404');
    }
  }
};
