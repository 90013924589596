import React from 'react';
import { Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHdd,
  faMemory,
  faMicrochip,
  faNetworkWired,
  faEnvelope,
  faCloud,
  faFloppyDisk,
  faHardDrive,
} from '@fortawesome/free-solid-svg-icons';
import { Button } from '@cloudez/cloudez-design-system';
import { CardHeader, IsTurbo, ResourceCard, TitleText } from './styles';

export const Resources = ({ cloud, changePlan }) => {
  return (
    <Col xl="7">
      <ResourceCard>
        <CardHeader style={{ marginBottom: '14px' }}>Recursos</CardHeader>
        <TitleText>Tipo:</TitleText>
        <div className="type">
          <span>{cloud.cloud_size.name}</span>

          <IsTurbo>
            <>
              {cloud.cloud_size.category === 3 ? (
                <div style={{ margin: '0 5px' }}>
                  <FontAwesomeIcon icon={faEnvelope} />
                  <span style={{ marginLeft: '5px' }}>Email</span>
                </div>
              ) : cloud.cloud_size.category === 4 ? (
                <div style={{ margin: '0 5px' }}>
                  <FontAwesomeIcon icon={faHardDrive} />
                  <span style={{ marginLeft: '5px' }}>Volume</span>
                </div>
              ) : (
                <div style={{ margin: '0 5px' }}>
                  <FontAwesomeIcon icon={faCloud} />
                  <span style={{ marginLeft: '5px' }}>Padrão</span>
                </div>
              )}
            </>
            {cloud.is_default && (
              <div style={{ margin: '0 5px' }}>
                <FontAwesomeIcon icon={faCloud} />
                <span style={{ marginLeft: '5px' }}>Padrão</span>
              </div>
            )}
          </IsTurbo>
        </div>
        <div className="informations">
          <div className="information">
            <FontAwesomeIcon icon={faMicrochip} />
            <span className="title">CPU</span>
            <span className="info">
              {cloud.cloud_size.cores}
              vCPU
            </span>
          </div>
          <div className="information">
            <FontAwesomeIcon icon={faHdd} />
            <span className="title">Disco</span>
            <span className="info">{cloud.cloud_size.disk}</span>
          </div>
          <div className="information">
            <FontAwesomeIcon icon={faMemory} />
            <span className="title">Memória</span>
            <span className="info">{cloud.cloud_size.memory}</span>
          </div>
          <div className="information">
            <FontAwesomeIcon icon={faNetworkWired} />
            <span className="title">Banda</span>
            <span className="info">{cloud.cloud_size.transfer}</span>
          </div>
        </div>
        {cloud.is_paying && (
          <Button
            onClick={() => changePlan(true)}
            width="100%"
            outline
            height="32px"
            style={{
              margin: 'auto',
              marginTop: '20px',
            }}
          >
            Alterar Recursos
          </Button>
        )}
      </ResourceCard>
    </Col>
  );
};
