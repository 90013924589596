import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faSpinner } from '@fortawesome/free-solid-svg-icons';

import { Button, Modal } from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Footer,
} from 'components/ListingModal/styles';
import { Content } from './styles';

interface ExistentCreditCardModalProps {
  show: boolean;
  setShow: (value: boolean) => void;
  invoiceLink: string;
}

const ExistentCreditCardModal = ({
  show,
  setShow,
  invoiceLink,
}: ExistentCreditCardModalProps): JSX.Element => {
  const [loading, setLoading] = useState(false);

  return (
    <Modal width="455px" show={show} setShow={setShow}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faCreditCard} />
        </Icon>
        <Text>
          <Title>Aviso de cartão de crédito cadastrado</Title>
          <Subtitle>Gerando boleto com cartão cadastrado.</Subtitle>
        </Text>
      </Header>
      <Content>
        <span>
          Notamos que você está prestes a gerar um boleto e que já possui um
          cartão de crédito cadastrado com a gente.
        </span>
        <span>
          Ao gerar o boleto, o cartão de crédito vinculado em sua conta
          permanecerá ativo podendo ser utilizado de forma automática para o
          pagamento desta fatura.
        </span>
      </Content>
      <Footer>
        <Button
          width="149px"
          onClick={() => {
            window.open(invoiceLink);
            setShow(false);
          }}
          disabled={loading}
          icon
        >
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Gerar boleto'}
        </Button>
      </Footer>
    </Modal>
  );
};

export default ExistentCreditCardModal;
