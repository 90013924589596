import React, { createContext, useReducer } from 'react';
import reducer from './reducer';
import { setLoading, updatePlan, getPlans, getBasePlans } from './actions';

// initial state
const initialState = {
  plan: null,
  plans: null,
  basePlans: null,
  loading: false,
};

// context
export const CloudCreateContext = createContext(initialState);

// provider
export const CloudCreateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <CloudCreateContext.Provider
      value={{
        // state
        plan: state.plan,
        plans: state.plans,
        basePlans: state.basePlans,
        loading: state.loading,

        // actions
        setLoading: value => setLoading(dispatch, value),
        updatePlan: plan => updatePlan(dispatch, plan),
        getPlans: async () => await getPlans(dispatch),
        getBasePlans: async () => await getBasePlans(dispatch),
      }}
    >
      {children}
    </CloudCreateContext.Provider>
  );
};
