import styled, { css } from 'styled-components';

export const Title = styled.p`
  font-weight: bold;
  font-size: 16px;
  color: ${props => props.theme.interface5};
`;

interface CardItemProps {
  alert?: boolean;
  success?: boolean;
}

export const CardItem = styled.li<CardItemProps>`
  border: 1px solid
    ${props => {
      return props.theme.brand_primary_color;
    }};
  border-radius: 5px;
  padding: 9px 9px;

  display: flex;

  ${props =>
    props.alert &&
    css`
      border: 1px solid ${props.theme.alertNew};
    `};

  ${props =>
    props.success &&
    css`
      border: 1px solid ${props.theme.successNew};
    `}

  svg {
    margin-top: 6px;
    margin-right: 10px;
    font-size: 17px;

    ${props =>
      props.alert &&
      css`
        color: ${props.theme.alertNew};
      `};
    ${props =>
      props.success &&
      css`
        color: ${props.theme.successNew};
      `}
  }

  a {
    color: ${props => props.theme.brand_primary_color};
    text-decoration: underline;
    font-weight: 700;
  }

  font-weight: normal;
  font-size: 12px;

  color: ${props => props.theme.interface6};

  &:not(:first-child) {
    margin-top: 13px;
  }
`;
