import React, { useEffect, useState } from 'react';
import { withTheme } from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { Button } from '@cloudez/cloudez-design-system';

import ApplicationIcon from 'components/ApplicationIcon';

import { toast } from 'react-toastify';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { getAddonTypesService, createAddonService } from 'services/cloud';

import { Wrapper, AddonCard } from './styles';
import OptionsModal from './OptionsModal';
import AddonPlaceholder from '../Placeholder';

const AddAddon = ({
  cloud,
  setAddAddon,
  setAddons: _setAddons,
  addons: _addons,
}) => {
  const [selectedAddon, setSelectedAddon] = useState(null);
  const [addons, setAddons] = useState([]);
  const [loading, setLoading] = useState(false);
  const [addonsLoading, setAddonsLoading] = useState(true);
  const [show, setShow] = useState(false);

  useEffect(() => {
    async function getAddons() {
      try {
        const { data } = await getAddonTypesService();
        setAddons(data);
        setAddonsLoading(false);
      } catch (e) {
        setAddonsLoading(false);
        console.log(e);
      }
    }

    if (!addons.length) getAddons();
  }, [addons]);

  const createAddon = async () => {
    setLoading(true);
    const payload = {
      cloud,
      values: selectedAddon.values.map(v => ({
        slug: v.slug,
        value: v.value || v.default,
      })),
      type: selectedAddon.id,
      is_pinned: false,
    };

    try {
      const { data } = await createAddonService(payload);

      const newAddon = {
        ...data,
        type: selectedAddon,
      };

      _setAddons([..._addons, newAddon]);

      toast.success('Addon adicionado com sucesso.');
      setLoading(false);
      setAddAddon(false);
    } catch (e) {
      setLoading(false);
      toast.error(e?.response?.data[Object.keys(e?.response?.data)[0]][0]);
    }
  };

  const hasOptions = !!selectedAddon?.values.length;

  return (
    <>
      {show && hasOptions && selectedAddon && (
        <OptionsModal
          addon={selectedAddon}
          show={show}
          setShow={setShow}
          cloud={cloud}
          setAddAddon={setAddAddon}
          setAddons={_setAddons}
          addons={_addons}
        />
      )}

      <Wrapper>
        <Row
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          {addonsLoading ? (
            <AddonPlaceholder />
          ) : (
            addons?.map(t => (
              <Col
                key={t.id}
                xs="6"
                sm="4"
                md="4"
                lg="3"
                xl="2"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <AddonCard
                  onClick={() => {
                    setShow(true);
                    setSelectedAddon(t);
                  }}
                  selected={selectedAddon?.id === t.id}
                >
                  <ApplicationIcon
                    width="40px"
                    height="40px"
                    icon={`${t.slug}`}
                  />
                  <span>{t.name}</span>
                  <p>{t.description}</p>
                </AddonCard>
              </Col>
            ))
          )}
        </Row>
        {selectedAddon && !hasOptions && (
          <Button outline onClick={createAddon} icon>
            {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Salvar'}
          </Button>
        )}
      </Wrapper>
    </>
  );
};

export default withTheme(AddAddon);
