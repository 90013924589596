import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 40px;
  width: 100%;
  padding: 0 16px;

  img {
    border-radius: 50%;
  }

  button {
    margin-left: auto;
  }

  .inputBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
  }
`;

export const FooterButtons = styled.div`
  margin-top: 14px;
  display: flex;
  justify-content: space-between;
`;
