import api from 'services/api';

export const getWebsitesService = async (
  params,
  ctrl = new AbortController(),
) => {
  try {
    return await api.get('/v3/website/', { params, signal: ctrl.signal });
  } catch (e) {
    throw e;
  }
};

export const autoPointDNSService = async (
  id: number,
  type: string,
  payload: any,
): Promise<void> => {
  try {
    return await api.post(`v3/${type}/${id}/auto_point_dns/`, payload);
  } catch (e) {
    throw e;
  }
};

export const getWebsiteService = async (id, params?) => {
  try {
    return await api.get(`/v3/website/${id}/`, { params });
  } catch (e) {
    throw e;
  }
};

export const createWebsiteService = async data => {
  try {
    return await api.post(`/v3/website/`, data);
  } catch (e) {
    throw e;
  }
};

export const updateWebsiteService = async (id, data) => {
  try {
    return await api.patch(`/v3/website/${id}/`, data);
  } catch (e) {
    throw e;
  }
};

export const deleteWebsiteService = async (id, motive) => {
  try {
    return await api.delete(`/v3/website/${id}/`, {
      data: {
        motive,
      },
    });
  } catch (e) {
    throw e;
  }
};

export const deleteCertificateService = async id => {
  try {
    return await api.delete(`/v3/certificate/${id}/`);
  } catch (e) {
    throw e;
  }
};

export const requeueCertificateService = async id => {
  try {
    return await api.post(`/v3/certificate/${id}/requeue/`);
  } catch (e) {
    throw e;
  }
};

export const createCertificateService = async data => {
  try {
    return await api.post('/v3/certificate/', data);
  } catch (e) {
    throw e;
  }
};

export const getWebsiteCronsService = async params => {
  try {
    return await api.get('/v3/website-cron/', { params });
  } catch (e) {
    throw e;
  }
};

export const getWebsiteCronService = async (id, params?) => {
  try {
    return await api.get(`/v3/website-cron/${id}/`, { params });
  } catch (e) {
    throw e;
  }
};

export const createWebsiteCronService = async data => {
  try {
    return await api.post(`/v3/website-cron/`, data);
  } catch (e) {
    throw e;
  }
};

export const updateWebsiteCronService = async (id, data) => {
  try {
    return await api.patch(`/v3/website-cron/${id}/`, data);
  } catch (e) {
    throw e;
  }
};

export const deleteWebsiteCronService = async id => {
  try {
    return await api.delete(`/v3/website-cron/${id}/`);
  } catch (e) {
    throw e;
  }
};

export const getWebsiteTypesService = async params => {
  try {
    return await api.get(`/v3/website-type/`, { params });
  } catch (e) {
    throw e;
  }
};

export const getWebsiteTypeService = async (id, params?) => {
  try {
    return await api.get(`/v3/website-type/${id}/`, { params });
  } catch (e) {
    throw e;
  }
};

export const cleanCDNCacheService = async id => {
  try {
    return await api.post(`/v3/website/${id}/clean_cdn_cache/`);
  } catch (e) {
    throw e;
  }
};

export const duplicateCertificateService = async data => {
  try {
    return await api.post('/v3/certificate/replicate-cert/', data);
  } catch (e) {
    throw e;
  }
};
