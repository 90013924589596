import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 19px 21px;
  gap: 22px;

  font-size: 16px;
  line-height: 140%;

  color: ${props =>
    props.theme.darkMode ? props.theme.interface3 : props.theme.interface7};

  .finish {
    color: ${props => props.theme.errorNew};
  }
`;
