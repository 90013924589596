import React, { useState, useRef } from 'react';

import * as Yup from 'yup';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faSpinner, faKey } from '@fortawesome/free-solid-svg-icons';

import {
  Modal,
  Button,
  Checkbox,
  Label,
  Field,
  Error,
  FormInput,
  FormTextArea,
  Form,
} from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Content,
  Footer,
} from 'components/ListingModal/styles';

import { toast } from 'react-toastify';
import {
  createCloudUserService,
  deleteCloudUserService,
  updateCloudUserService,
} from 'services/cloud';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { CloudUser } from 'types/cloud';

interface IUserModalProps {
  show: boolean;
  setShow: any;
  cloud?: string | number;
  user?: CloudUser;
  setUsers?: any;
  updateField?: any;
  website?: any;
}

const UserModal: React.FC<IUserModalProps> = ({
  show,
  setShow,
  cloud,
  setUsers,
  user: _user,
  updateField,
  website,
}) => {
  const [user, setUser] = useState<CloudUser>(
    (_user as CloudUser) ||
      ({
        cloud,
        has_http: false,
        has_sudo: false,
        has_ssh: false,
      } as CloudUser),
  );
  const [errors, setErrors] = useState({} as any);
  const [loading, setLoading] = useState(false);
  const [showPw, setShowPw] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const isUpdating = !!_user;

  const schema = Yup.object().shape({
    username: Yup.string().required('Por favor, digite um nome de usuário.'),
    authorized_keys: Yup.string(),
    password: isUpdating
      ? Yup.string()
      : Yup.string()
          .required('Por favor, digite uma senha.')
          .min(8, 'Senha deve conter no mínimo 8 caracteres.'),
  });

  const createUser = async d => {
    setLoading(true);

    const payload = {
      ...d,
      ...user,
    };

    try {
      const { data } = await createCloudUserService(payload);
      setUsers(users => [...users, data]);
      toast.success('Usuário adicionado');
      setLoading(false);
      setShow();
    } catch (e) {
      setLoading(false);
      setErrors(e?.response?.data);
    }
  };

  const updateUser = async d => {
    const payload = {
      ...user,
      ...d,
    };

    if (!payload.password) {
      delete payload.password;
    }
    if (!payload.authorized_keys) {
      delete payload.authorized_keys;
    }

    setLoading(true);
    try {
      const { data } = await updateCloudUserService(user.id, payload);
      if (setUsers) {
        setUsers(users =>
          users.map(u =>
            u.id === user.id
              ? {
                  ...user,
                  ...data,
                }
              : u,
          ),
        );
      } else if (updateField && website) {
        updateField(
          'user',
          {
            ...user,
            ...data,
          },
          website.id,
        );
      } else if (updateField) {
        updateField('user', {
          ...user,
          ...data,
        });
      }
      toast.success('Usuário atualizado');
      setLoading(false);
      setShow();
    } catch (e) {
      setErrors(e?.response?.data);
      setLoading(false);
    }
  };

  const deleteUser = async () => {
    setDeleteLoading(true);
    try {
      await deleteCloudUserService(user.id);

      setUsers(users => users.filter(u => user.id !== u.id));

      toast.success('Usuário removido com sucesso.');
      setDeleteLoading(false);
      setShow();
    } catch (e) {
      setErrors(e?.response?.data);
      setDeleteLoading(false);
    }
  };

  return (
    <Modal show={show} setShow={setShow} noExit={loading}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faUser} />
        </Icon>
        <Text>
          <Title>{isUpdating ? 'Atualizar' : 'Criar'} cloud user</Title>
        </Text>
      </Header>

      <Content>
        <Form
          schema={schema}
          id="createUser"
          onSubmit={isUpdating ? updateUser : createUser}
          initialData={user}
        >
          <Field>
            <Label>Usuário</Label>
            <FormInput
              block
              name="username"
              disabled={isUpdating}
              error={!!errors?.username}
            />
            {errors?.username && <Error>{errors?.username[0]}</Error>}
          </Field>
          <Field>
            <Label>senha</Label>
            <FormInput
              id="pw"
              block
              name="password"
              error={!!errors?.password}
              type={showPw ? 'text' : 'password'}
            />
            <FontAwesomeIcon
              className="eye"
              icon={faEye}
              onClick={() => setShowPw(!showPw)}
            />
            {errors?.password && <Error>{errors?.password[0]}</Error>}
          </Field>
          <Field>
            <Label>chaves autorizadas</Label>
            <FormTextArea
              block
              name="authorized_keys"
              height="90px"
              error={!!errors?.authorized_keys}
            />
            {errors?.authorized_keys && (
              <Error>{errors?.authorized_keys[0]}</Error>
            )}
          </Field>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              width: '100%',
            }}
          >
            <Field
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Label>usar http</Label>
              <Checkbox
                checked={user.has_http}
                block
                value={user.has_http}
                onChange={e => {
                  setUser({ ...user, has_http: e.target.checked });
                }}
                error={!!errors?.has_http}
              />
              {errors?.has_http && <Error>{errors?.has_http[0]}</Error>}
            </Field>
            <Field
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Label>usar sudo</Label>
              <Checkbox
                checked={user.has_sudo}
                block
                value={user.has_sudo}
                onChange={e => {
                  setUser({ ...user, has_sudo: e.target.checked });
                }}
                error={!!errors?.has_sudo}
              />
              {errors?.has_sudo && <Error>{errors?.has_sudo[0]}</Error>}
            </Field>
            <Field
              style={{
                flexDirection: 'column',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                width: '100%',
              }}
            >
              <Label>usar ssh</Label>
              <Checkbox
                checked={user.has_ssh}
                block
                value={user.has_ssh}
                onChange={e => {
                  setUser({ ...user, has_ssh: e.target.checked });
                }}
                error={!!errors?.has_ssh}
              />
              {errors?.has_ssh && <Error>{errors?.has_ssh[0]}</Error>}
            </Field>
          </div>
          {errors?.non_field_errors && (
            <Error>{errors?.non_field_errors[0]}</Error>
          )}
        </Form>
      </Content>

      <Footer hasLink={isUpdating}>
        {isUpdating && (
          <Button
            size="sm"
            error
            style={{
              marginRight: '14px',
            }}
            outline
            icon
            disabled={deleteLoading}
            onClick={deleteUser}
          >
            {deleteLoading ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              'Remover'
            )}
          </Button>
        )}

        <Button disabled={loading} type="submit" form="createUser" icon>
          {loading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : !isUpdating ? (
            'Criar'
          ) : (
            'Atualizar'
          )}
        </Button>
      </Footer>
    </Modal>
  );
};

export default UserModal;
