import React from 'react';
import Placeholder from 'components/Placeholder';
import { Col } from 'react-bootstrap';

import { Type } from '../styles';

const AddonPlaceholder = () => {
  const placeholders = [];

  for (let i = 1; i <= 4; i++) {
    placeholders.push(i);
  }

  return (
    <>
      {placeholders.map((p, i) => (
        <Col
          xs="6"
          md="4"
          lg="3"
          xl="2"
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Type>
            <Placeholder width={40} height={40} />
            <span>
              <Placeholder width={70} height={12} />
            </span>
          </Type>
        </Col>
      ))}
    </>
  );
};
export default AddonPlaceholder;
