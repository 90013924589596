import api from 'services/api';
import { toast } from 'react-toastify';
import history from 'services/history';
import { getCloudsService } from 'services/cloud';
import {
  updateTicketService,
  getTicketService,
  createTicketMessageService,
  getMessagesService,
  pickupTicketService,
  changeResponsibleService,
} from 'services/ticket';
import { getUserService } from 'services/user';
import { getResourceService } from 'services/common';
import nProgress from 'nprogress';

export const setLoading = (dispatch, value) => {
  dispatch({
    type: 'SET_LOADING',
    payload: value,
  });
};

export const addWSMessage = (dispatch, message) => {
  dispatch({
    type: 'ADD_WS_MESSAGE',
    payload: message,
  });
};

export const getTicket = async (dispatch, id) => {
  nProgress.start();
  try {
    const { data } = await getTicketService(id);

    dispatch({
      type: 'GET_TICKET',
      payload: data,
    });
    nProgress.done();
  } catch (e) {
    nProgress.done();
    if (e.response.status === 404) {
      toast.error('Ticket não encontrado');
      history.push('/404');
    }
  }
};

export const getUser = async (dispatch, owner) => {
  try {
    const { data } = await getUserService(owner);

    dispatch({
      type: 'GET_USER',
      payload: data,
    });
  } catch (e) {}
};

export const getClouds = async (dispatch, owner_email) => {
  try {
    const { data } = await getCloudsService({
      search: owner_email,
      page_size: 1000,
    });

    dispatch({
      type: 'GET_CLOUDS',
      payload: data.results,
    });
  } catch (e) {}
};

export const getTarget = async (dispatch, target_type, target_id) => {
  try {
    const { data } = await getResourceService(target_type, target_id);

    dispatch({
      type: 'GET_TARGET',
      payload: data,
    });
  } catch (e) {}
};

export const getMessages = async (dispatch, id) => {
  try {
    const { data } = await getMessagesService({
      ticket: id,
    });

    getFiles(dispatch, data);

    dispatch({
      type: 'GET_MESSAGES',
      payload: data,
    });
  } catch (e) {}
};

export const addMessage = async (dispatch, _data) => {
  try {
    await createTicketMessageService(_data);
    // getMessages(dispatch, data.ticket);
  } catch (e) {
    toast.error(e?.response?.data[Object.keys(e?.response?.data)[0]][0]);
  }
};

export const getFiles = (dispatch, data) => {
  const _files = data.filter(d => d.file).map(d => d.file);

  dispatch({
    type: 'GET_FILES',
    payload: _files,
  });
};

export const updateTicket = async (dispatch, field, value, id, ticket) => {
  try {
    const { data } = await updateTicketService(
      id,
      ticket || {
        [field]: value,
      },
    );

    toast.success('Ticket atualizado com sucesso.');

    dispatch({
      type: 'UPDATE_TICKET',
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: 'UPDATE_TICKET_ERROR',
      payload: e?.response?.data,
    });
  }
};

export const pickupTicket = async (dispatch, ticket) => {
  try {
    const { data: message } = await pickupTicketService(ticket);

    dispatch({
      type: 'PICKUP_TICKET',
      payload: message,
    });
  } catch (e) {
    dispatch({
      type: 'PICKUP_TICKET_ERROR',
      payload: e?.response?.data,
    });
  }
};

export const changeResponsible = async (dispatch, ticket, responsible) => {
  try {
    await changeResponsibleService(ticket, responsible);
  } catch (e) {
    dispatch({
      type: 'PICKUP_TICKET_ERROR',
      payload: e?.response?.data,
    });
  }
};
export const setRedAlert = async (dispatch, id) => {
  try {
    const { data } = await api.patch(`v2/ticket/${id}/redalert/`);

    dispatch({
      type: 'SET_RED_ALERT',
      payload: data?.red_alert,
    });
  } catch (e) {
    dispatch({
      type: 'SET_RED_ALERT_ERROR',
      payload: e?.response?.data,
    });
  }
};
