import React, { useEffect } from 'react';
import { useNotifications } from './notifications';

export function useDocTitle(title) {
  const { notifications } = useNotifications();

  useEffect(() => {
    document.title = `${
      notifications?.filter(n => n.status === 0).length > 0
        ? `(${notifications?.filter(n => n.status === 0).length})`
        : ''
    } ${title}`;
  }, [notifications, title]);
}
