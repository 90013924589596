import React, { useState, useContext } from 'react';

import { withTheme } from 'styled-components';

import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import {
  faRectangleXmark,
  faThumbsUp,
  faThumbsDown,
} from '@fortawesome/free-regular-svg-icons';

import { Button, Modal } from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from 'components/ListingModal/styles';

import { useAuth } from 'hooks/auth';
import { updateTicketService } from 'services/ticket';

import { CustomInput } from 'components/NewComponents';
import { ContentText, RateButton } from './styles';

import { TicketListingContext } from '../_context/state';
import { set } from 'react-ga';

const RatingModal = ({ show, setShow, ticket }) => {
  const { updateTicket } = useContext(TicketListingContext);

  const [loading, setLoading] = useState(false);

  const [_ticket, setTicket] = useState(ticket);
  const [rating, setRating] = useState(ticket.rating);
  const [ratingComment, setRatingComment] = useState(ticket?.rating_comment);
  const { user } = useAuth();

  const closeTicket = async () => {
    setLoading(true);
    try {
      await updateTicket('rating', rating, _ticket.id);
      await updateTicket('status', 2, _ticket.id);
      const { data } = await updateTicketService(_ticket.id, {
        status: 2,
        rating,
        rating_comment: ratingComment,
      });
      setTicket(data);
      setLoading(false);
      setShow(false);
    } catch (e) {
      setLoading(false);

      toast.error('Não foi possível fechar o ticket.');
    }
  };

  const rateTicket = async () => {
    setLoading(true);
    try {
      if (
        user.email === ticket.owner_email ||
        user.roles.includes('team_leader_role')
      ) {
        await updateTicket('rating', rating, _ticket.id);
        const { data } = await updateTicketService(_ticket.id, {
          rating,
          rating_comment: ratingComment,
        });
        setTicket(data);
        setLoading(false);
        setShow(false);
      } else {
        setLoading(false);
        setShow(false);
        toast.error('Você não tem permissão para avaliar esse ticket.');
      }
    } catch (e) {
      setLoading(false);

      toast.error('Não foi possível avaliar o ticket.');
    }
  };

  return (
    <Modal show={show} setShow={setShow}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faRectangleXmark} />
        </Icon>
        <Text>
          <Title>Fechar Ticket</Title>
          <Subtitle>Feche o ticket e avalie nosso atendimento</Subtitle>
        </Text>
      </Header>
      <Content style={{ padding: '0px 17px 17px 17px' }}>
        <div>
          <ContentText>
            <RateButton
              rating={rating === 1}
              onClick={() => {
                setRating(1);
              }}
            >
              <FontAwesomeIcon className="green" icon={faThumbsUp} />
            </RateButton>
            <RateButton
              rating={rating === 2}
              onClick={() => {
                setRating(2);
              }}
            >
              <FontAwesomeIcon className="red" icon={faThumbsDown} />
            </RateButton>
          </ContentText>
        </div>
        <CustomInput
          value={ratingComment}
          onChange={e => setRatingComment(e.target.value)}
          placeholder="Escreva um comentário"
          name="comment"
          label="Deixe um comentário"
          width="100%"
        />
      </Content>
      <Footer>
        <Button
          icon
          onClick={
            ticket.rating === 1 || ticket.rating === 2
              ? rateTicket
              : closeTicket
          }
          disabled={loading || !rating}
        >
          {loading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : ticket.rating === 1 || ticket.rating === 2 ? (
            'Avaliar ticket'
          ) : (
            'Fechar ticket'
          )}
        </Button>
      </Footer>
    </Modal>
  );
};

export default withTheme(RatingModal as any);
