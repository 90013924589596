import React from 'react';
import { Col, Row } from 'react-bootstrap';

import Placeholder from 'components/Placeholder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumbs, Breadcrumb } from 'components/Common';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import {
  Header,
  Title,
  Tabs,
  Tab,
  Content,
  TabContent,
  Card,
  CardHeader,
  Informations,
  Information,
  Field,
  Label,
  Value,
  Separator,
} from 'components/Details';

const WebsiteDetailPlaceholder: React.FC = () => {
  return (
    <>
      <Header>
        <Row>
          <Col sm="8" md="9">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Placeholder circle width={64} height={64} />

              <Title>
                <Breadcrumbs>
                  <Breadcrumb>
                    <Placeholder width={40} height={10} />
                  </Breadcrumb>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <Breadcrumb>
                    <Placeholder width={60} height={10} />
                  </Breadcrumb>
                </Breadcrumbs>
                <h3>
                  <Placeholder width={100} height={18} />
                </h3>
                <Placeholder width={100} height={12} />
              </Title>
            </div>
          </Col>
          <Col
            sm="1"
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <Placeholder width={40} height={40} />
          </Col>
          <Col
            className="d-none d-sm-flex d-md-flex d-xl-flex"
            sm="3"
            md="2"
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Placeholder width={130} height={40} />
          </Col>
        </Row>
      </Header>

      <Row
        style={{
          marginBottom: `${15}px`,
        }}
      >
        <Col xl="7">
          <Row style={{ marginBottom: `${30}px` }}>
            <Col xl="12">
              <Card>
                <CardHeader>
                  <Placeholder width={100} height={16} />
                </CardHeader>
                <Informations>
                  <Information>
                    <Placeholder width={25} height={25} />

                    <span>
                      <Placeholder width={60} height={12} />
                    </span>
                    <span>
                      <Placeholder width={40} height={16} />
                    </span>
                  </Information>

                  <Information>
                    <Placeholder width={25} height={25} />

                    <span>
                      <Placeholder width={60} height={12} />
                    </span>
                    <span>
                      <Placeholder width={40} height={16} />
                    </span>
                  </Information>

                  <Information>
                    <Placeholder width={25} height={25} />

                    <span>
                      <Placeholder width={60} height={12} />
                    </span>
                    <span>
                      <Placeholder width={40} height={16} />
                    </span>
                  </Information>
                  <Information>
                    <Placeholder width={25} height={25} />

                    <span>
                      <Placeholder width={60} height={12} />
                    </span>
                    <span>
                      <Placeholder width={40} height={16} />
                    </span>
                  </Information>
                </Informations>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Tabs>
            <Tab active>
              <Placeholder width={50} height={14} />
            </Tab>
            <Tab>
              <Placeholder width={50} height={14} />
            </Tab>
            <Tab>
              <Placeholder width={50} height={14} />
            </Tab>
            <Tab>
              <Placeholder width={50} height={14} />
            </Tab>
            <Tab>
              <Placeholder width={50} height={14} />
            </Tab>
            <Tab>
              <Placeholder width={50} height={14} />
            </Tab>
            <Tab>
              <Placeholder width={50} height={14} />
            </Tab>
          </Tabs>

          <TabContent>
            <Content>
              <Row>
                <Col xl>
                  <Field>
                    <Label>
                      <Placeholder width={100} height={10} />
                    </Label>
                    <Value>
                      <Placeholder width={160} height={12} />
                    </Value>
                  </Field>
                  <Row>
                    <Col>
                      <Field>
                        <Label>
                          <Placeholder width={100} height={10} />
                        </Label>
                        <Value>
                          <Placeholder width={160} height={12} />
                        </Value>
                      </Field>
                    </Col>
                    <Col>
                      <Field>
                        <Label>
                          <Placeholder width={100} height={10} />
                        </Label>
                        <Value>
                          <Placeholder width={160} height={12} />
                        </Value>
                      </Field>
                    </Col>
                  </Row>
                  <Field>
                    <Label>
                      <Placeholder width={100} height={10} />
                    </Label>
                    <Value>
                      <Placeholder width={160} height={12} />
                    </Value>
                  </Field>
                  <Field>
                    <Label>
                      <Placeholder width={100} height={10} />
                    </Label>
                    <Value>
                      <Placeholder width={160} height={12} />
                    </Value>
                  </Field>
                  <Field>
                    <Label>
                      <Placeholder width={100} height={10} />
                    </Label>
                    <Value>
                      <Placeholder width={160} height={12} />
                    </Value>
                  </Field>
                  <Field>
                    <Label>
                      <Placeholder width={100} height={10} />
                    </Label>
                    <Value>
                      <Placeholder width={30} height={30} />
                    </Value>
                  </Field>
                </Col>
                <Separator />
                <Col xl>
                  <Field>
                    <Label>
                      <Placeholder width={100} height={10} />
                    </Label>
                    <Value
                      style={{
                        marginBottom: '5px',
                      }}
                    >
                      <Placeholder width={400} height={40} />
                    </Value>
                    <Value>
                      <Placeholder width={220} height={10} />
                    </Value>
                    <Value>
                      <Placeholder width={220} height={10} />
                    </Value>
                  </Field>
                </Col>
              </Row>
            </Content>
          </TabContent>
        </Col>
      </Row>
    </>
  );
};

export default WebsiteDetailPlaceholder;
