import styled from 'styled-components';

export const Wrapper = styled.div`
  background: ${props => props.theme.interface1};
  border-radius: 5px;
  margin: auto;
  width: 60%;

  padding: 60px;

  @media (max-width: 762px) {
    width: 100%;
    padding: 40px;
  }
`;
