import React, { useContext, useState } from 'react';

import { toast } from 'react-toastify';

import {
  Footer,
  Icon,
  Text,
  Title,
  Header,
} from 'components/ListingModal/styles';

import { Modal, Button, Form, FormInput } from '@cloudez/cloudez-design-system';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { Content } from './styles';
import { SecurityCompanyContext } from '../_context/state';

interface FinishActiveSectionModal {
  show: boolean;
  setShow: (value: boolean) => void;
  id: number | string;
  getStatus: any;
}

const FinishActiveSectionModal: React.FC<FinishActiveSectionModal> = ({
  show,
  id,
  setShow,
  getStatus,
}) => {
  const [finish, setFinish] = useState('');

  const [loading, setLoading] = useState(false);

  const { deleteDevice } = useContext(SecurityCompanyContext);

  const submitFinishSection = async () => {
    try {
      setLoading(true);
      await deleteDevice(id);
      setLoading(false);
      setShow(false);
      getStatus();
    } catch (err) {
      toast.error('Não foi possível desconectar o dispositivo atual!');
      setShow(false);
      setLoading(false);
    }
  };

  return (
    <Modal width="497px" show={show} setShow={setShow}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </Icon>
        <Text>
          <Title>Encerrar Dispositivo Atual</Title>
        </Text>
      </Header>

      <Form onSubmit={submitFinishSection}>
        <Content>
          <span>
            <b>Atenção!</b> Ao encerrar o dispositivo atual, ao realizar o login
            novamente deverá informar o código de 6 dígitos do seu aplicativo de
            segurança.
          </span>
          <span>
            Por favor, digite <b className="finish">ENCERRAR</b> no campo abaixo
            para encerrar o seu dispositivo atual
          </span>
          <div style={{ width: '100%' }}>
            <FormInput
              width="100%"
              onChange={e => setFinish(e.target.value)}
              name="finishSection"
              placeholder="ENCERRAR"
            />
          </div>
        </Content>
        <Footer hasLink>
          <Button type="button" onClick={() => setShow(false)} outline>
            Cancelar
          </Button>
          <Button disabled={finish !== 'ENCERRAR' || loading} type="submit">
            {loading ? (
              <FontAwesomeIcon
                style={{ marginLeft: '0' }}
                icon={faSpinner}
                spin
              />
            ) : (
              'Encerrar'
            )}
          </Button>
        </Footer>
      </Form>
    </Modal>
  );
};

export default FinishActiveSectionModal;
