import React, { useEffect, useContext, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
  faExchangeAlt,
} from '@fortawesome/free-solid-svg-icons';

import { Breadcrumbs, Breadcrumb } from 'components/Common';
import { ThemeContext } from 'styled-components';

import {
  Tabs,
  Tab,
  TabContent,
  Content,
  Card,
  CardHeader,
} from 'components/Details';
import { Arrows } from '@cloudez/cloudez-design-system';
import Swipe from 'react-easy-swipe';

import ApplicationIcon from 'components/ApplicationIcon';
import usePersistedState from 'hooks/usePersistedState';

import Ticket from 'components/Ticket';
import { useDocTitle } from 'hooks/docTitle';
import { useNotifications } from 'hooks/notifications';
import Progress from './Progress';
import Syncs from './Syncs';
import Acessos from './Acessos';
import Accounts from './Accounts';
import {
  MigrationDetailContext,
  MigrationDetailProvider,
} from './_context/state';
import { Header, Title } from './styles';

const MigrationDetail = ({ match }) => {
  const theme = useContext(ThemeContext);

  const { migration, getMigration, syncs } = useContext(MigrationDetailContext);
  const [tab, setTab] = usePersistedState('migrationTab', 1);
  const { patchNotification, notifications } = useNotifications();

  useDocTitle(
    migration
      ? `Migração ${
          migration?.domain || migration?.resource_slug
        } - Migrações — Cloudez`
      : 'Migrações — Cloudez',
  );

  useEffect(() => {
    const { id } = match.params;

    const notification = notifications?.filter(
      n => n.type === 'migration' && n.resource_id === Number(id),
    );

    if (notification?.length > 0) {
      if (notification[0].status === 0)
        patchNotification({
          id: notification[0].id,
          data: { status: 1 },
        });
    }
    // eslint-disable-next-line
  }, [notifications, match.params.id]);

  useEffect(() => {
    const { id } = match.params;

    getMigration(id);
    // eslint-disable-next-line
  }, [match.params.id]);

  const isRemoveBackup = useMemo(() => {
    return syncs?.find(
      s => s.type === 'remove_backup' && (s.status === 1 || s.status === 3),
    );
  }, [syncs]);

  return (
    migration && (
      <>
        <Header>
          <Row>
            <Col sm="12">
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <Title>
                  <Breadcrumbs>
                    <Breadcrumb>
                      <Link to="/dashboard">Home</Link>
                    </Breadcrumb>
                    <FontAwesomeIcon icon={faChevronRight} />
                    <Breadcrumb>
                      <Link to="/migrations">Migrações</Link>
                    </Breadcrumb>
                  </Breadcrumbs>
                  {migration.type !== 4 ? (
                    <ApplicationIcon
                      width="40px"
                      height="40px"
                      style={{
                        marginBottom: '-8px',
                        marginRight: '5px',
                      }}
                      icon={
                        migration.resource === 'email'
                          ? migration.resource_type === 'selfhosted-mail'
                            ? 'cloudez-footer'
                            : `${migration.resource_type}`
                          : `${migration.resource_type}`
                      }
                    />
                  ) : (
                    <FontAwesomeIcon
                      style={{
                        color: theme.brand_primary_color,
                      }}
                      icon={faExchangeAlt}
                    />
                  )}
                  Migração {migration?.domain || migration?.resource_slug}
                </Title>
              </div>
            </Col>
          </Row>
        </Header>
        <Row style={{ marginBottom: `${30}px` }}>
          <Col sm="12" md="6">
            <Row style={{ marginBottom: `${30}px` }}>
              <Col xs="12">
                <Card>
                  <CardHeader>
                    <Progress status={isRemoveBackup ? 4 : migration.status} />
                  </CardHeader>
                  {/* <Row
                    style={{
                      marginTop: '20px',
                    }}
                  >
                    <Col xs>
                      <Info>
                        <div>
                          <FontAwesomeIcon
                            icon={faInfoCircle}
                            style={{ color: theme.errorNew, fontSize: '16px' }}
                          />
                          <span
                            style={{
                              color: theme.interface4,
                              fontSize: '13px',
                              textTransform: 'uppercase',
                              fontWeight: 'bold',
                              margin: '0 6px',
                            }}
                          >
                            Aguardando ação de:
                          </span>
                          <span
                            style={{
                              color: theme.interface6,
                              fontSize: '13px',
                              fontWeight: 'bold',
                            }}
                          >
                            Nome cliente
                          </span>
                        </div>
                        <div>
                          <span
                            style={{
                              color: theme.interface4,
                              fontSize: '13px',
                              textTransform: 'uppercase',
                              fontWeight: 'bold',
                              margin: '0 6px',
                            }}
                          >
                            previsão:
                          </span>
                          <span
                            style={{
                              color: theme.interface6,
                              fontSize: '13px',
                              fontWeight: 'bold',
                            }}
                          >
                            08/08/2020
                          </span>
                        </div>
                      </Info>
                    </Col>
                  </Row> */}
                  <Row
                    style={{
                      marginTop: '20px',
                    }}
                  >
                    <Col xs>
                      <Syncs />
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            {migration?.cache_data?.extra_args && (
              <Row style={{ marginBottom: `${30}px` }}>
                <Col xs="12">
                  <Row>
                    <Col xs>
                      {migration.resource === 'email' &&
                      migration.type !== 1 ? (
                        <>
                          <Tabs>
                            <Tab active={tab === 1} onClick={() => setTab(1)}>
                              Dados de acesso
                            </Tab>
                            <Tab active={tab === 2} onClick={() => setTab(2)}>
                              Contas
                            </Tab>
                          </Tabs>

                          <Arrows
                            disabled={tab === 1}
                            icon={faChevronLeft}
                            left
                            onClick={() => tab > 1 && setTab(tab - 1)}
                            actions
                            style={{
                              left: '-10px',
                            }}
                          />
                          <Swipe
                            onSwipeRight={() => tab > 1 && setTab(tab - 1)}
                            onSwipeLeft={() => tab < 7 && setTab(tab + 1)}
                            tolerance={100}
                          >
                            <TabContent>
                              <Content>
                                {(() => {
                                  switch (tab) {
                                    case 1:
                                      return <Acessos />;
                                    case 2:
                                      return <Accounts />;
                                    default:
                                      return null;
                                  }
                                })()}
                              </Content>
                            </TabContent>
                          </Swipe>
                          <Arrows
                            disabled={tab === 2}
                            icon={faChevronRight}
                            right
                            onClick={() => tab < 2 && setTab(tab + 1)}
                            actions
                            style={{
                              right: '-10px',
                            }}
                          />
                        </>
                      ) : (
                        <Card>
                          <Acessos />
                        </Card>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>
            )}
          </Col>
          <Col sm="12" md="6">
            <Card>
              <CardHeader>Ticket</CardHeader>
              <Row>
                <Col xs>
                  <Ticket ticketId={migration.ticket} />
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </>
    )
  );
};

export default props => (
  <MigrationDetailProvider>
    <MigrationDetail {...props} />
  </MigrationDetailProvider>
);
