import styled from 'styled-components';
import { Button } from '@cloudez/cloudez-design-system';

export const UndoButton = styled.button`
  width: 30px;
  height: 30px;
  border-radius: 5px;
  background: ${props => props.theme.brand_secondary_color};
  margin-right: -20px;
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;

  svg {
    color: ${props => props.theme.brand_primary_color};
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 70px;

  margin-bottom: 40px;

  ${Button} {
    margin-left: 62px;
  }
`;

export const Title = styled.div`
  margin-left: 30px;

  h3 {
    font-size: 18px;
    a {
      text-decoration: none;
      color: ${props => props.theme.interface6};
    }
  }

  p {
    font-size: 12px;
    color: ${props => props.theme.interface5};
  }
`;

export const Card = styled.div`
  height: auto;
  padding: 15px 30px;
  background: ${props => props.theme.interface1};
  border-radius: 5px;
`;

export const CardHeader = styled.h3`
  font-size: 18px;
  color: ${props => props.theme.interface5};
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`;

export const CompanyBillingInfo = styled.div`
  h1 {
    font-size: 22px;
    color: ${props => props.theme.interface5};
    font-weight: normal;
  }

  p {
    margin-top: 2px;
    text-transform: capitalize;
    color: ${props => props.theme.interface5};
    font-size: 12px;
  }
`;

export const UserBillingInfo = styled.div`
  span {
    font-size: 14px;
    color: ${props => props.theme.interface5};
    font-weight: bold;
  }

  p {
    font-size: 12px;

    margin-top: 2px;
    text-transform: capitalize;
    color: ${props => props.theme.interface5};
  }
`;

export const OrderStatus = styled.div`
  span {
    display: block;
    font-size: 12px;
    color: ${props => props.theme.interface5};
    font-weight: bold;
    margin-top: 10px;

    p {
      display: inline;
      font-weight: normal;
      font-size: 12px;
      text-transform: capitalize;
      color: ${props => props.theme.interface5};
    }
  }
`;

interface OrderBadgeProps {
  expired?: boolean;
  paid?: boolean;
}

export const OrderBadge = styled.div<OrderBadgeProps>`
  display: inline;
  border-radius: 14px;
  opacity: 1;
  color: ${props => props.theme.interface1};
  padding: 4px 10px;
  margin-left: 5px;
  background: ${props =>
    props.paid
      ? props.theme.success
      : props.expired
      ? props.theme.error
      : props.theme.favorite};
`;

export const InvoiceItems = styled.ul`
  list-style: none;
  border-radius: 5px;
  margin-top: 30px;
  border: 1px solid
    ${props =>
      props.theme.darkMode ? props.theme.interface2 : props.theme.interface3};
  background: ${props => props.theme.interface1};
`;

export const InvoiceItem = styled.li`
  padding: 22px 17px;
`;

export const InvoiceItemsHeader = styled.div`
  background: ${props => props.theme.interface2};
  color: ${props => props.theme.interface5};
  font-size: 12px;
  padding: 12px 17px;
`;

export const Separator = styled.div`
  background: ${props =>
    props.theme.darkMode ? props.theme.interface2 : props.theme.interface3};

  height: 1px;
  width: 100%;
  margin: 20px 0;
`;

export const Informations = styled.div`
  height: 100%;
  width: 100%;
`;

export const Information = styled.div`
  svg {
    color: #cfd3de;
    font-size: 18px;
  }

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
`;

export const AddressCard = styled.div`
  height: 100%;
  padding: 15px;
  background: ${props => props.theme.interface1};
  border-radius: 5px;
`;

export const Label = styled.span`
  font-size: 12px;
  color: ${props => props.theme.interface4};
  text-transform: uppercase;
  display: block;
`;

interface ValueProps {
  mrr?: boolean;
}

export const Value = styled.span<ValueProps>`
  font-size: 14px;
  font-weight: ${props => props.mrr && 'bold'};
  color: ${props =>
    props.mrr ? props.theme.brand_primary_color : props.theme.interface5};
`;

export const Active = styled.div`
  width: 12px;
  height: 12px;
  border-radius: 50%;
`;

interface StatusProps {
  active?: boolean;
}

export const Status = styled.div<StatusProps>`
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-top: 4px;
  width: 100%;
  justify-content: space-between;

  color: ${props =>
    props.active ? props.theme.success : props.theme.interface5};

  ${Active} {
    background: ${props =>
      props.active ? props.theme.success : props.theme.interface5};
  }
`;

export const CardBody = styled.div`
  width: 100%;
  height: calc(100% - 50px);
  margin-top: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;
