import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { darken, transparentize } from 'polished';

interface WrapperProps {
  show?: boolean;
  loading?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  display: ${props => (props.show ? 'flex' : 'none')};
  position: absolute;
  z-index: 999;
  bottom: -25px;
  right: -25px;
  width: 70px;
  height: 70px;
  cursor: ${props => (props.loading ? 'default' : 'pointer')};
  background: ${props =>
    props.loading
      ? transparentize(0.2, props.theme.success)
      : darken(0.2, props.theme.success)};
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 3px 15px #64728c4d;
  animation: show 0.3s;

  svg {
    font-size: 30px;
    color: ${props => props.theme.interface1};
  }

  @keyframes show {
    from {
      bottom: -200px;
    }

    to {
      bottom: -25px;
    }
  }

  @media (max-width: 840px) {
    right: -25px;
    width: 65px;
    height: 65px;

    @keyframes show {
      from {
        bottom: -200px;
      }

      to {
        bottom: -25px;
      }
    }
  }
`;

export default ({ onClick, show, loading }) => {
  return (
    <Wrapper
      onClick={() => {
        if (!loading) onClick();
      }}
      show={show}
      loading={loading}
    >
      {loading ? (
        <FontAwesomeIcon icon={faSpinner} spin />
      ) : (
        <FontAwesomeIcon icon={faCheck} />
      )}
    </Wrapper>
  );
};
