import { Reducer } from 'react';
import { IActionPayload } from 'types';

const reducer: Reducer<any, IActionPayload> = (state, action): any => {
  switch (action.type) {
    case 'UPDATE_FIELD':
    case 'UPDATE_NEW_EMAIL':
      return {
        ...state,
        newEmail: action.payload,
      };

    case 'SET_LOADING': {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case 'SET_ERRORS': {
      return {
        ...state,
        errors: action.payload,
      };
    }

    case 'CREATE_EMAIL_DOMAIN': {
      return {
        ...state,
        newEmail: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
