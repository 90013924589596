import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBarcode } from '@fortawesome/free-solid-svg-icons';

import {
  Modal,
  Button,
  Input,
  Label,
  Field,
  SubText,
} from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Content,
  Footer,
} from 'components/ListingModal/styles';
import copy from 'copy-to-clipboard';
import { ModalProps } from 'types';

interface InformationProps extends ModalProps {
  invoice: any;
}

const InformationModal: React.FC<InformationProps> = ({
  show,
  setShow,
  invoice,
}) => {
  return (
    <Modal show={show} setShow={setShow}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faBarcode} />
        </Icon>
        <Text>
          <Title>Informações da fatura</Title>
        </Text>
      </Header>

      <Content>
        <Field
          onClick={e => {
            copy(
              `${window.location.origin}/invoices/bank-slip/${invoice.public_id}/`,
            );
          }}
        >
          <Label>Link do boleto</Label>
          <Input
            style={{
              cursor: 'pointer',
            }}
            background
            block
            disabled
            value={`${window.location.origin}/invoices/bank-slip/${invoice.public_id}/`}
          />
          <SubText>* Clique no link para copiá-lo</SubText>
        </Field>
      </Content>
      <Footer>
        <Button onClick={() => setShow()}>Ok</Button>
      </Footer>
    </Modal>
  );
};

export default InformationModal;
