import React, { useEffect, useContext, useState, useRef } from 'react';
import { AnimatedList } from 'react-animated-list';
import queryString from 'query-string';
import { Col, Row } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAngleDown,
  faAngleUp,
  faStar,
} from '@fortawesome/free-solid-svg-icons';

import { ListingHeader, Ordering } from 'components/Listing/styles';

import {
  Button,
  Pagination,
  AdvancedSearch,
} from '@cloudez/cloudez-design-system';

import SearchBar from 'components/SearchBar';
import { Title, Header } from 'components/Common';
import history from 'services/history';
import { Wrapper } from 'components/ApplicationIcon/styles';
import EmptyListing from 'components/EmptyListing';
import {
  FilterHover,
  Filter,
  FilterWrapper,
} from 'components/SearchBar/styles';
import { Value } from 'components/Acessos';

import { useDocTitle } from 'hooks/docTitle';
import { useLayout } from 'hooks/layout';
import InvoiceWarning from 'pages/Dashboard/InvoiceWarning';
import { Hover, HoverText } from 'components/Hover';
import { DomainListingProvider, DomainListingContext } from './_context/state';
import DomainCard from './DomainCard';
import DomainPlaceholder from './Placeholder';

const Domains = () => {
  const theme = useLayout();

  useDocTitle(`Domínios — ${theme.name}`);

  const { loading, domains, count, next, previous, current, getDomains } =
    useContext(DomainListingContext);

  const {
    page = 1,
    search: _search = '',
    filter = 'all',
  } = queryString.parse(history.location.search);
  const [filterValue, setFilterValue] = useState(filter);
  const [orderValue, setOrderValue] = useState({ column: '', value: 0 }); // Inicialização do orderValue com valor zero e não ter chance de rolar undefined

  const [search, setSearch] = useState(
    queryString.parse(history.location.search),
  );

  const abortControllerRef = useRef<AbortController>(new AbortController());

  const getDomainsCallback = (_page, _search, _filter, order) => {
    abortControllerRef.current.abort();
    abortControllerRef.current = new AbortController();
    getDomains(_page, _search, _filter, order, abortControllerRef.current);
    const _query = {
      ..._search,
      page: _page,
      filter: _filter,
    };

    if (!_search) delete _query.search;
    if (_filter === 'all') delete _query.filter;
    if (_page === 1 || _page === '1') delete _query.page;

    const query = queryString.stringify(_query);
    history.replace(`/domains?${query}`);
  };

  useEffect(() => {
    getDomainsCallback(page, search, filter, orderValue);
    // eslint-disable-next-line
  }, []);

  const searchValue = value => {
    getDomainsCallback(1, value, filter, orderValue);
  };

  const handleSetFilter = _filter => {
    setFilterValue(_filter);
    getDomainsCallback(1, search, _filter, orderValue);
  };

  const order = column => {
    const newOrderValue =
      orderValue.column === column // Verificação não é mais necessária
        ? {
            column,
            value: orderValue.value === 1 ? 2 : 1,
          }
        : {
            column,
            value: 1,
          };
    setOrderValue(newOrderValue);
    getDomainsCallback(1, search, filter, newOrderValue);
  };

  return (
    <>
      <Header>
        <Title>Domínios</Title>
        <Button onClick={() => history.push('/domains/create')}>
          <span>Novo domínio</span>
        </Button>
      </Header>
      <InvoiceWarning />

      <SearchBar noInput>
        <AdvancedSearch
          style={{
            width: '400px',
          }}
          callback={searchValue}
          noResults={false}
          value={search}
          setValue={setSearch}
          fields={[
            {
              label: 'Qualquer campo',
              value: 'search',
            },
            {
              label: 'Domínio',
              value: 'domain',
            },
            {
              label: 'Email',
              value: 'email',
            },
            {
              label: 'Usuário',
              value: 'username',
            },
          ]}
        />
        <FilterWrapper>
          <Filter>
            <FontAwesomeIcon
              icon={faStar}
              color={
                filterValue === 'favorite'
                  ? theme.favoriteNew
                  : theme.darkMode
                  ? theme.interface5
                  : theme.interface4
              }
              onClick={() =>
                filterValue === 'favorite'
                  ? handleSetFilter('all')
                  : handleSetFilter('favorite')
              }
            />
            <FilterHover>
              <Value>
                <span>Favoritos</span>
              </Value>
            </FilterHover>
          </Filter>
        </FilterWrapper>
      </SearchBar>

      {loading ? (
        <DomainPlaceholder />
      ) : domains?.length ? (
        <>
          <ListingHeader>
            <Row>
              <Col xs="10" sm="10" md="3">
                <Wrapper>
                  <p onClick={() => order('domain')}>
                    Domínio
                    <Ordering
                      status={
                        orderValue?.column === 'domain' ? orderValue?.value : 0
                      }
                    >
                      <FontAwesomeIcon
                        icon={orderValue.value === 2 ? faAngleUp : faAngleDown}
                      />
                    </Ordering>
                  </p>{' '}
                </Wrapper>
              </Col>
              <Col xs="10" sm="10" md="2">
                <Wrapper>
                  <Hover>
                    <p>Comprado em</p>
                    <HoverText style={{ textTransform: 'none' }}>
                      Info restrita para compras feitas na Cloudez
                    </HoverText>
                  </Hover>
                </Wrapper>
              </Col>
              <Col className="d-none d-sm-none d-md-flex">
                <p onClick={() => order('updated_at')}>
                  Atualizado em
                  <Ordering
                    status={
                      orderValue?.column === 'updated_at'
                        ? orderValue?.value
                        : 0
                    }
                  >
                    <FontAwesomeIcon
                      icon={orderValue.value === 1 ? faAngleUp : faAngleDown}
                    />
                  </Ordering>
                </p>{' '}
              </Col>
              <Col xl="2" className="d-none d-xl-flex" />
              <Col xl="2" className="d-none d-xl-flex" />
            </Row>
          </ListingHeader>

          <AnimatedList initialAnimationDuration={800} animation="grow">
            {domains.map(domain => (
              <DomainCard domain={domain} key={domain.id} />
            ))}
          </AnimatedList>

          <Pagination
            onChangePage={getDomainsCallback}
            count={count}
            pageSize={10}
            previous={previous}
            next={next}
            filter={filterValue}
            current={current}
            search={search}
            order={orderValue}
          />
        </>
      ) : null}

      {!loading && domains?.length === 0 && (
        <EmptyListing>
          <p>Nenhum domínio encontrado.</p>
          <Button onClick={() => history.push('/domains/create')} size="lg">
            Criar novo domínio
          </Button>
        </EmptyListing>
      )}
    </>
  );
};

export default props => (
  <DomainListingProvider>
    <Domains {...props} />
  </DomainListingProvider>
);
