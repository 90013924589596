import React, {
  useCallback,
  useMemo,
  useEffect,
  useState,
  useContext,
  useRef,
} from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Row, Col } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import Swipe from 'react-easy-swipe';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import {
  faUpload,
  faMemory,
  faFileContract,
  faDoorOpen,
  faChevronLeft,
  faChevronRight,
  faPowerOff,
  faSpinner,
  faCheck,
  faQuestionCircle,
  faBug,
} from '@fortawesome/free-solid-svg-icons';

import { Arrows, Button } from '@cloudez/cloudez-design-system';

import usePersistedState from 'hooks/usePersistedState';
import { useDocTitle } from 'hooks/docTitle';

import { getValue } from 'utils/getValue';
import toastError from 'utils/toastError';

import { getWebsitesService, getWebsiteTypeService } from 'services/website';
import api from 'services/api';

import RemoveModal from 'components/ListingModal/RemoveModal';
import { Breadcrumbs, Breadcrumb } from 'components/Common';
import ApplicationIcon from 'components/ApplicationIcon';
import Ticket from 'components/Ticket';

import {
  Header,
  Title,
  Tabs,
  Tab,
  TabContent,
  Content,
  Card,
  CardHeader,
  Informations,
  Information,
  OfflineButton,
  TabFooter,
} from 'components/Details';
import Pagespeed from 'assets/img/svg/google-pagespeed.svg';
import GoCache from 'assets/img/gocache.png';

import { deleteDatabaseService, getDatabaseService } from 'services/database';
import { deleteCloudUserService, getCloudService } from 'services/cloud';
import { useAuth } from 'hooks/auth';
import { WebsiteDetailContext, WebsiteDetailProvider } from './_context/state';

import ConfiguracoesGerais from './Common';
import ChangeDomain from './ChangeDomain';
import OfflineModal from './OfflineModal';
import SuccessModal from './SuccessModal';
import ConfirmModal from './ConfirmModal';
import Placeholder from './Placeholder';
import Application from './Application';
import Performance from './Performance';
import InfoModal from './InfoModal';
import Databases from './Databases';
import Acessos from './Access';
import Domain from './Domain';
import Crons from './Crons';

const WebsiteDetail = ({ match }) => {
  const history = useHistory();

  const {
    website,
    getWebsite,
    patchWebsite,
    updateValue,
    deleteWebsite,
    updateLoading,
    errors,
    setLoading,
  } = useContext(WebsiteDetailContext);

  const [successModal, setSuccessModal] = useState(
    history.location.state?.is_new,
  );

  const [type, setType] = useState(null);
  const [tab, setTab] = usePersistedState('@cloudez-app-v2/websiteTab', 1);
  const [modal, showModal] = useState(false);
  const [offlineModal, setOfflineModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmModalText, setConfirmModalText] = useState({});
  const [confirmValue, setConfirmValue] = useState({} as any);
  const [changeDomainModal, setChangeDomainModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [infoModal, setInfoModal] = useState(false);

  const { user } = useAuth();
  const [firstActive, setFirstActive] = useState(false);

  useDocTitle(website ? `${website?.domain} — Website` : 'Website — Cloudez');

  useEffect(() => {
    const { id } = match.params;
    getWebsite(id);

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    let unblock;
    if (isEditing) {
      unblock = history.block(
        'Você tem alterações não aplicadas. O que deseja fazer?',
      );
    }

    return () => {
      if (unblock) unblock();
    };
    // eslint-disable-next-line
  }, [isEditing]);

  useEffect(() => {
    async function getType() {
      const { data } = await getWebsiteTypeService(website.type.id);

      setType(data);
    }

    if (!type && website) getType();
    // eslint-disable-next-line
  }, [website]);

  const mustConfirm = [
    {
      slug: 'pagespeed',
      text: (
        <div>
          <p>
            <b>Atenção:</b> a utilização deste recurso avançado aumenta o
            consumo de memória do seu cloud, mas melhora a performance de sua
            aplicação.
          </p>
          <br />
          <p>
            As principais melhorias proporcionadas por meio da automação são:
          </p>
          <br />
          <p>
            <b>Otimização de imagens</b> <br /> as imagens, automaticamente,
            serão comprimidas e reduzidas para o tamanho adequado para a página;
          </p>
          <br />
          <p>
            <b>Otimização de assets em JavaScript e CSS</b> <br /> os códigos,
            comentários desnecessários e caracteres em excesso serão reduzidos e
            o código comprimido;
          </p>
          <br />
          <p>
            <b>Recomendação</b> <br /> Utilize o PageSpeed Optimizer junto com o
            CDN habilitado.
          </p>
        </div>
      ),
      title: 'Habilitar PageSpeed Optimizer ',
      icon: Pagespeed,
    },
    {
      slug: 'debug',
      text: (
        <div>
          <p>
            Ao ativar a depuração de erros, assim que acessar seu site, caso
            existam erros, os mesmos serão exibidos.{' '}
          </p>
          <br />
          <p>Utilize apenas para resolução rápida de problemas.</p>
          <br />
          <p>
            O ideal é sempre ativar a depuração{' '}
            <b>por um período específico apenas</b>, logo depois desativando-o
            quando não for mais necessário. Assim, você garante a devida
            depuração e <b>não compromete a performance do sistema</b>.
          </p>
          <br />
        </div>
      ),
      title: 'Habilitar Debug',
      icon: faBug,
    },
    {
      slug: 'use_cdn_on',
      text: (
        <div>
          <p>O seu domínio {website?.domain} não está com os nossos DNS.</p>
          <br />
          <p>
            Para ativar o CDN será necessário alterar as entradas de domínio no
            seu provedor de DNS.
          </p>
          <br />
          <p>Edite as seguintes entradas</p>
          <br />
          <table>
            <tr>
              <td>
                <b>Nome</b>
              </td>
            </tr>
            <tr>www</tr>
            <br />
            <tr>
              <td>
                <b>Tipo</b>
              </td>
            </tr>
            <tr>CNAME</tr>
            <br />
            <tr>
              <td>
                <b>Registro</b>
              </td>
            </tr>
            <tr>{website?.gocache_info?.record}</tr>
            <br />
          </table>
          {website?.type?.slug === 'wordpress' && (
            <>
              <br />
              <p>
                <b>Observação: </b>Caso você possua WordPress, ao ativar o CDN
                será instalado e configurado o plugin{' '}
                <a
                  href="https://wordpress.org/plugins/gocache-cdn/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GoCache
                </a>{' '}
                já configurado para o uso.
              </p>
            </>
          )}
        </div>
      ),
      title: 'Habilitar CDN',
      icon: GoCache,
    },
    {
      slug: 'use_cdn_off',
      text: (
        <div>
          <p>O seu domínio {website?.domain} não está com os nossos DNS.</p>
          <br />
          <p>
            Para desativar o CDN será necessário alterar as entradas do entradas
            de domínio no seu provedor de DNS
          </p>
          <br />
          <p>Edite as seguintes entradas</p>
          <br />
          <table>
            <tr>
              <td>
                <b>Nome</b>
              </td>
            </tr>
            <tr>www</tr>
            <br />
            <tr>
              <td>
                <b>Tipo</b>
              </td>
            </tr>
            <tr>CNAME</tr>
            <br />
            <tr>
              <td>
                <b>Registro</b>
              </td>
            </tr>
            <tr>{website?.gocache_info?.record}</tr>
            <br />
          </table>
        </div>
      ),
      title: 'Desabilitar CDN',
      icon: GoCache,
    },
    {
      slug: 'use_cdn',
      text: (
        <div>
          <b>Observação:</b> Ao ativar será instalado o plugin{' '}
          <u>
            <a
              href="https://wordpress.org/plugins/gocache-cdn/"
              target="_blank"
              rel="noopener noreferrer"
            >
              GoCache
            </a>
          </u>{' '}
          em seu WordPress já configurado para o uso.
        </div>
      ),
      title: 'Habilitar CDN',
      icon: GoCache,
    },
  ];

  const {
    php_version,
    python_version,
    nodejs_version,
    upload_maxsize,
    memory,
    icon,
    version,
    ruby_version,
  } = useMemo(() => {
    let php_version;
    let python_version;
    let nodejs_version;
    let upload_maxsize;
    let memory;
    let icon;
    let version;
    let ruby_version;

    if (website) {
      php_version = getValue(website, 'php_version');
      python_version = getValue(website, 'python_version');
      nodejs_version = getValue(website, 'nodejs_version');
      ruby_version = getValue(website, 'ruby_version');
      upload_maxsize = getValue(website, 'upload_maxsize');

      if (php_version) {
        memory = getValue(website, 'memory');
        icon = 'php';
        version = php_version;
      } else if (python_version) {
        icon = 'python';
        version = python_version;
      } else if (nodejs_version) {
        icon = 'nodejs';
        version = nodejs_version;
      } else if (ruby_version) {
        icon = 'rails';
        version = ruby_version;
      }
    }

    return {
      php_version,
      python_version,
      nodejs_version,
      upload_maxsize,
      memory,
      icon,
      version,
      ruby_version,
    };
  }, [website]);

  const changeValue = useCallback(
    e => {
      if (e.target.name !== 'domain') setIsEditing(true);
      updateValue(e);

      if (
        mustConfirm.filter(v => {
          if (e.target.name === 'use_cdn') {
            if (website?.gocache_info) {
              return e.target.checked
                ? v.slug === 'use_cdn_on'
                : v.slug === 'use_cdn_off';
            }

            if (website?.type?.slug === 'wordpress') {
              return v.slug === e.target.name && e.target.checked;
            }
          }

          return (
            e.target.name !== 'use_cdn' &&
            v.slug === e.target.name &&
            e.target.checked
          );
        })[0]
      ) {
        setConfirmModal(state => !state);
        setConfirmModalText({
          ...mustConfirm.filter(v => {
            if (e.target.name === 'use_cdn' && website?.gocache_info) {
              return e.target.checked
                ? v.slug === 'use_cdn_on'
                : v.slug === 'use_cdn_off';
            }
            return v.slug === e.target.name && e.target.checked;
          })[0],
        });
        setConfirmValue({
          target: {
            name: e.target.name,
            checked: e.target.checked,
            type: 'checkbox',
          },
        });
      }
    },
    [updateValue],
  );

  const updateWebsite = useCallback(
    async (field, value) => {
      await patchWebsite(field, value, website.id);
    },
    [website, patchWebsite],
  );

  const applyChanges = useCallback(
    async (domain = false) => {
      if (isEditing) {
        await patchWebsite('values', website.values, website.id);
        setIsEditing(false);
      } else if (domain) {
        setLoading(true);
        try {
          await api.patch(`/v3/website/${website.id}/update-domain/`, {
            domain: website.domain,
          });
          setLoading(false);
        } catch (e) {
          toastError(e);
          setLoading(false);
        }
      }
    },
    [isEditing, website, patchWebsite],
  );

  const [allowDeleteDB, setAllowDeleteDB] = useState(false);
  const [allowDeleteUser, setAllowDeleteUser] = useState(false);

  const allowShowCheckbox = !allowDeleteUser && !allowDeleteDB;

  const handleDeleteWebsite = async params => {
    await deleteWebsite(website.id, params?.motive);

    if (params?.removeDatabaseAndUser) {
      if (website?.databases[0]) {
        deleteDatabaseService(website.databases[0]);
      }

      if (website?.user?.id) {
        deleteCloudUserService(website.user.id);
      }
    }
  };

  useEffect(() => {
    if (website?.databases[0]) {
      const getDatabase = async () => {
        const { data } = await getDatabaseService(website?.databases[0]);

        setAllowDeleteDB(data?.websites?.length > 1);
      };

      const getUser = async () => {
        const { data } = await getWebsitesService({ user: website.user.id });

        setAllowDeleteUser(data?.results?.length > 1);
      };

      getUser();
      getDatabase();
    }
  }, [website?.databases[0]]);

  const rollback = useCallback(() => {
    updateValue({
      target: {
        ...confirmValue.target,
        checked: !confirmValue.target.checked,
      },
    } as Event);
  }, [confirmValue, updateValue]);

  const [cloudOwnerEmail, setCloudOwnerEmail] = useState();

  const isCloudOwner = cloudOwnerEmail === user?.email;

  useEffect(() => {
    if (website?.cloud?.id) {
      const getCloud = async () => {
        const { data } = await getCloudService(website?.cloud?.id);
        setCloudOwnerEmail(data.admin.email);
      };
      getCloud();
    }
  }, [website?.cloud?.id]);

  const cloudDisplay =
    user.is_company_owner && isCloudOwner
      ? website?.cloud?.display
      : website?.cloud?.display.split(' - ')[0];

  return website && type ? (
    <>
      {confirmModal && (
        <ConfirmModal
          text={confirmModalText}
          show={confirmModal}
          setShow={setConfirmModal}
          website={website}
          onChange={changeValue}
          noExit
          onConfirm={() => {
            setFirstActive(true);
            setConfirmModal(state => !state);
          }}
          onCancel={() => {
            setConfirmModal(state => !state);
            rollback();
          }}
        />
      )}
      {successModal && (
        <SuccessModal
          website={website}
          show={successModal}
          setShow={() => setSuccessModal(false)}
        />
      )}
      {infoModal && (
        <InfoModal show={infoModal} setShow={() => setInfoModal(false)} />
      )}
      {offlineModal && (
        <OfflineModal
          show={offlineModal}
          setShow={() => setOfflineModal(false)}
          action={updateWebsite}
        />
      )}
      {modal && (
        <RemoveModal
          action={handleDeleteWebsite}
          typeName="website"
          params={{
            withMotive: true,
            allowShowCheckbox,
            text: 'Deseja remover o banco de dados e o usuário da aplicação?',
          }}
          show={modal}
          setShow={() => showModal(false)}
        />
      )}
      {changeDomainModal && (
        <ChangeDomain
          action={applyChanges}
          loading={updateLoading}
          errors={errors}
          show={changeDomainModal}
          setShow={() => setChangeDomainModal(false)}
          changeValue={changeValue}
        />
      )}
      <Header>
        <Row>
          <Col sm="8" md="9">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ApplicationIcon
                width="64px"
                height="64px"
                icon={`${website.type.slug}`}
              />
              <Title>
                <Breadcrumbs>
                  <Breadcrumb>
                    <Link to="/dashboard">Home</Link>
                  </Breadcrumb>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <Breadcrumb>
                    <Link to="/websites">Websites</Link>
                  </Breadcrumb>
                </Breadcrumbs>
                <h3>
                  <a
                    href={`http://${
                      website.domain || website.temporary_address
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {website.domain || website.name}
                  </a>
                  <FontAwesomeIcon
                    icon={faEdit}
                    onClick={() => setChangeDomainModal(true)}
                  />
                </h3>
                <p>{cloudDisplay}</p>
              </Title>
            </div>
          </Col>
          <Col
            sm="1"
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <OfflineButton
              offline={website.is_disabled}
              onClick={() => setOfflineModal(true)}
            >
              {website.is_disabled ? (
                <FontAwesomeIcon icon={faPowerOff} fade />
              ) : (
                <FontAwesomeIcon icon={faPowerOff} />
              )}
              <span>
                {website.is_disabled ? ' Desabilitado' : ' Habilitado'}
              </span>
            </OfflineButton>
          </Col>
          <Col
            className="d-none d-sm-flex d-md-flex d-xl-flex"
            sm="3"
            md="2"
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button remove outline error onClick={() => showModal(true)}>
              Excluir aplicação
            </Button>
          </Col>
        </Row>
      </Header>

      <Row
        style={{
          marginBottom: `${15}px`,
        }}
      >
        <Col xl="7">
          <Row style={{ marginBottom: `${30}px` }}>
            <Col xl="12">
              <Card>
                <CardHeader>Informações</CardHeader>
                <Informations>
                  {(php_version ||
                    python_version ||
                    nodejs_version ||
                    ruby_version) && (
                    <Information>
                      <ApplicationIcon width="30px" height="30px" icon={icon} />
                      <span
                        style={{
                          fontSize: `${12}px`,
                        }}
                      >
                        Versão
                      </span>
                      <span
                        style={{
                          fontSize: `${16}px`,
                          fontWeight: 'bold',
                        }}
                      >
                        {version}
                      </span>
                    </Information>
                  )}
                  <Information>
                    <FontAwesomeIcon icon={faUpload} />
                    <span
                      style={{
                        fontSize: `${12}px`,
                        marginTop: `${10}px`,
                      }}
                    >
                      Upload
                    </span>
                    <span
                      style={{
                        fontSize: `${16}px`,
                        fontWeight: 'bold',
                      }}
                    >
                      {upload_maxsize}
                    </span>
                  </Information>
                  {memory && (
                    <Information>
                      <FontAwesomeIcon icon={faMemory} />
                      <span
                        style={{
                          fontSize: `${12}px`,
                          marginTop: `${10}px`,
                        }}
                      >
                        Memória do PHP
                      </span>
                      <span
                        style={{
                          fontSize: `${16}px`,
                          fontWeight: 'bold',
                        }}
                      >
                        {memory}
                      </span>
                    </Information>
                  )}
                  <Information>
                    <FontAwesomeIcon icon={faFileContract} />
                    <span
                      style={{
                        fontSize: `${12}px`,
                        marginTop: `${10}px`,
                      }}
                    >
                      Protocolo
                    </span>
                    <span
                      style={{
                        fontSize: `${16}px`,
                        fontWeight: 'bold',
                      }}
                    >
                      sFTP
                    </span>
                  </Information>
                  <Information>
                    <FontAwesomeIcon icon={faDoorOpen} />
                    <span
                      style={{
                        fontSize: `${12}px`,
                        marginTop: `${10}px`,
                      }}
                    >
                      Porta
                    </span>
                    <span
                      style={{
                        fontSize: `${16}px`,
                        fontWeight: 'bold',
                      }}
                    >
                      22122
                    </span>
                  </Information>
                </Informations>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xl="5">
          <Card>
            <CardHeader>Tickets</CardHeader>
            <Row>
              <Col xs>
                <Ticket type="2" id={website.id} />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Tabs>
            <Tab active={tab === 1} onClick={() => setTab(1)}>
              Acessos
            </Tab>
            <Tab active={tab === 2} onClick={() => setTab(2)}>
              Geral
            </Tab>
            <Tab active={tab === 3} onClick={() => setTab(3)}>
              Aplicação
            </Tab>
            <Tab active={tab === 4} onClick={() => setTab(4)}>
              Domínio
            </Tab>
            <Tab active={tab === 5} onClick={() => setTab(5)}>
              Performance
            </Tab>
            <Tab active={tab === 6} onClick={() => setTab(6)}>
              Databases
            </Tab>
            <Tab active={tab === 7} onClick={() => setTab(7)}>
              Crons
            </Tab>
          </Tabs>

          <Arrows
            disabled={tab === 1}
            icon={faChevronLeft}
            left
            onClick={() => tab > 1 && setTab(tab - 1)}
            actions
          />
          <Swipe
            onSwipeRight={() => tab > 1 && setTab(tab - 1)}
            onSwipeLeft={() => tab < 7 && setTab(tab + 1)}
            tolerance={100}
          >
            <TabContent noPadding>
              <Content
                loading={updateLoading}
                style={{
                  padding: '18px 12px',
                }}
              >
                {(() => {
                  switch (tab) {
                    case 1:
                      return <Acessos />;
                    case 2:
                      return (
                        <ConfiguracoesGerais
                          type={type}
                          onChange={changeValue}
                        />
                      );
                    case 3:
                      return (
                        <Application
                          type={type}
                          onChange={changeValue}
                          errors={errors}
                        />
                      );
                    case 4:
                      return (
                        <Domain
                          type={type}
                          errors={errors}
                          onChange={changeValue}
                        />
                      );
                    case 5:
                      return (
                        <Performance
                          type={type}
                          onChange={changeValue}
                          firstActive={firstActive}
                        />
                      );
                    case 6:
                      return <Databases />;
                    case 7:
                      return <Crons />;
                    default:
                      return null;
                  }
                })()}
              </Content>
              <TabFooter>
                <div>
                  {tab === 5 ? (
                    <div
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() => setInfoModal(true)}
                    >
                      <FontAwesomeIcon
                        icon={faQuestionCircle}
                        size="2x"
                        style={{ marginRight: 10 }}
                      />
                      Dúvidas sobre performance?
                    </div>
                  ) : null}
                  {tab === 2 ? (
                    <div
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        window.open(
                          'https://help.cloudez.io/gerenciador-de-arquivos-cloudez/',
                          '_blank',
                        )
                      }
                    >
                      <FontAwesomeIcon
                        icon={faQuestionCircle}
                        size="2x"
                        style={{ marginRight: 10 }}
                      />
                      Dúvidas sobre o gerenciador de arquivos?
                    </div>
                  ) : null}
                  {tab === 3 && website?.type?.slug === 'wordpress' ? (
                    <div
                      style={{
                        cursor: 'pointer',
                      }}
                      onClick={() =>
                        window.open(
                          'https://help.cloudez.io/plugins-wordpress/',
                          '_blank',
                        )
                      }
                    >
                      <FontAwesomeIcon
                        icon={faQuestionCircle}
                        size="2x"
                        style={{ marginRight: 10 }}
                      />
                      Dúvidas sobre plugins?
                    </div>
                  ) : null}
                </div>
                <Button success onClick={applyChanges} disabled={!isEditing}>
                  Confirmar
                  {updateLoading ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    <FontAwesomeIcon icon={faCheck} />
                  )}
                </Button>
              </TabFooter>
            </TabContent>
          </Swipe>
          <Arrows
            disabled={tab === 7}
            icon={faChevronRight}
            right
            onClick={() => tab < 7 && setTab(tab + 1)}
            actions
          />
          <Row
            style={{
              marginTop: '20px',
            }}
          >
            <Col xs="12" className="d-flex d-sm-none">
              <Button
                style={{
                  width: '100%',
                }}
                remove
                outline
                error
                onClick={() => showModal(true)}
              >
                Excluir aplicação
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  ) : (
    <Placeholder />
  );
};

// TODO: make this better
export default props => (
  <WebsiteDetailProvider>
    <WebsiteDetail {...props} />
  </WebsiteDetailProvider>
);
