import styled, { css } from 'styled-components';

export const Card = styled.div<any>`
  cursor: pointer;
  background: ${props => props.theme.interface2};
  width: 170px;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 15px 0px;
  border: 2px solid transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${props =>
    props.selected &&
    css`
      border: 2px solid
        ${props =>
          props.theme.darkMode
            ? props.theme.interface5
            : props.theme.brand_primary_color};
    `}

  &:hover {
    transition: all 0.2s ease-in;
    border: 2px solid
      ${props =>
        props.theme.darkMode
          ? props.theme.interface5
          : props.theme.brand_primary_color};
  }

  span {
    font-size: 14px;
    color: ${props => props.theme.interface6};
  }

  p {
    font-size: 10px;
    color: ${props => props.theme.interface5};
  }
`;
