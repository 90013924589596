import styled, { css } from 'styled-components';

interface IWrapperProps {
  isFocused?: boolean;
}

export const Wrapper = styled.div<IWrapperProps>`
  background: ${props => props.theme.interface1};
  margin-bottom: 10px;
  width: 100%;
  height: auto;

  h3 {
    color: ${props => props.theme.interface5};
    padding: 8px 15px 20px 15px;

    p {
      font-size: 12px;
      font-weight: normal;
    }
  }

  opacity: ${props => (props.isFocused ? '1' : '0.4')};
`;

export const Helper = styled.div`
  font-size: 12px;
  color: ${props => props.theme.interface5};

  display: flex;
  height: 100%;
`;
