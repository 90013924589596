import styled from 'styled-components';
import { Input } from '@cloudez/cloudez-design-system';

export const SquareInput = styled(Input)`
  width: 50px;
  height: 50px;

  font-size: 20px;
  font-weight: bold;
  text-align: center;
`;

export const Inputs = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }
`;
