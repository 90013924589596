import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 185px;
  height: auto;

  position: absolute;
  background: ${props => props.theme.interface1};
  border: 1px solid
    ${props =>
      props.theme.darkMode ? props.theme.interface1 : props.theme.interface3};
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  z-index: 999;
  top: 8px;
  left: 10px;

  @media (max-width: 768px) {
    width: 120px;
    left: -35px;
  }
`;

export const Links = styled.ul`
  color: ${props => props.theme.interface5};

  li {
    padding: 10px 20px;
    text-align: left;
    font-size: 12px;

    cursor: pointer;

    svg {
      font-size: 14px;
      margin-right: 10px;
    }

    &:hover {
      transition: color 0.1s ease-in;
      color: ${props => props.theme.brand_primary_color};
    }
  }
`;
