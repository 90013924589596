import React, { useState, useEffect, useContext } from 'react';

import { Row, Col } from 'react-bootstrap';

import { Tabs, Tab, TabContent } from 'components/Tabs';
import { Input, Label, Select, Field } from '@cloudez/cloudez-design-system';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import generatePassword from 'utils/generatePassword';
import ApplicationIcon from 'components/ApplicationIcon';
import {
  getDatabasesService,
  getDatabaseUsersService,
  getDatabaseTypesService,
} from 'services/database';
import { getValue } from 'utils/getValue';
import { useAuth } from 'hooks/auth';
import { Wrapper, Title, Existent, HelpText, Type } from './styles';
import { WebsiteCreateContext } from '../../_context/state';

const Database = ({ setNoDb }) => {
  const {
    website,
    database: _database,
    updateDatabase,
    updateDatabaseField,
    updateDatabaseValue,
  } = useContext(WebsiteCreateContext);

  const {
    user: { company },
  } = useAuth();

  const [tab, setTab] = useState(1);
  const [types, setTypes] = useState(null);
  const [type, setType] = useState(null);
  const [choose, setChoose] = useState(false);
  const [chooseDatabase, setChooseDatabase] = useState(false);
  const [database, setDatabase] = useState({
    id: null,
    cloud: null,
    values: [],
    websites: [],
    type: null,
    users: [],
    admin: null,
  } as any);
  const [users, setUsers] = useState(null);
  const [databases, setDatabases] = useState(null);
  const [databaseName, setDatabaseName] = useState('');

  useEffect(() => {
    async function getDatabaseTypes() {
      try {
        const { data } = await getDatabaseTypesService({
          company,
        });

        setTypes(data);
      } catch (e) {
        console.log(e);
      }
    }

    getDatabaseTypes();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function getDatabases() {
      try {
        const { data } = await getDatabasesService({
          page_size: 100,
          cloud: website.cloud,
          type: type.slug,
        });

        setDatabases(data.results);
      } catch (e) {
        console.log(e);
      }
    }

    if (type) {
      getDatabases();
    }
    // eslint-disable-next-line
  }, [type]);

  useEffect(() => {
    async function getUsers() {
      try {
        const { data } = await getDatabaseUsersService({
          page_size: 100,
          cloud: website.cloud,
          type: type.slug,
        });

        setUsers(data.results);
      } catch (e) {
        console.log(e);
      }
    }

    if (type) {
      getUsers();
    }
    // eslint-disable-next-line
  }, [type]);

  return (
    <Wrapper>
      <Title>Gostaria de criar um database para esse website?</Title>

      <Tabs>
        <Tab
          active={tab === 1}
          onClick={() => {
            setTab(1);
            setNoDb(false);
          }}
        >
          Sim
        </Tab>
        <Tab
          active={tab === 2}
          onClick={() => {
            setTab(2);
            setNoDb(true);
          }}
        >
          Não
        </Tab>
      </Tabs>

      <TabContent>
        {tab === 1 ? (
          <>
            <Row
              style={{
                marginLeft: 0,
                width: '100%',
                padding: '8px 30px',
              }}
            >
              {types &&
                types.map(t => (
                  <Col
                    key={t.id}
                    lg="2"
                    md="3"
                    sm="4"
                    xs="6"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Type
                      type={t}
                      onClick={() => {
                        setType(t);
                        setDatabase({
                          ...database,
                          type: t.id,
                          values: t.values.map(t => ({
                            slug: t.slug,
                            value: t.default,
                          })),
                        });
                        updateDatabase({
                          ..._database,
                          type: t.id,
                          values: t.values.map(t => ({
                            slug: t.slug,
                            value: t.default,
                          })),
                        });
                      }}
                      selected={database.type === t.id}
                    >
                      <ApplicationIcon
                        width="40px"
                        height="40px"
                        icon={
                          t.slug === 'selfhosted-mail'
                            ? 'cloudez-footer'
                            : `${t.slug}`
                        }
                      />
                      <span>{t.name}</span>
                      <p>{t.description}</p>
                    </Type>
                  </Col>
                ))}
            </Row>
            {type && (
              <>
                {!chooseDatabase ? (
                  <>
                    <Row
                      style={{
                        marginLeft: 0,
                        width: '100%',
                        padding: '8px 30px',
                      }}
                    >
                      <Col>
                        <Field>
                          <Label>Nome do database</Label>
                          {!!databases?.length && (
                            <Existent onClick={() => setChooseDatabase(true)}>
                              Escolher database existente
                            </Existent>
                          )}
                          <Input
                            block="true"
                            placeholder="Nome do database"
                            value={databaseName}
                            name="database_name"
                            onChange={e => {
                              setDatabaseName(e.target.value);
                              updateDatabaseValue(e);
                            }}
                            // error={!!errors?.user?.password}
                          />
                          {/* {errors?.user?.password && (
                      <Error>{errors?.user?.password[0]}</Error>
                    )} */}
                        </Field>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        marginLeft: 0,
                        width: '100%',
                        padding: '8px 30px',
                      }}
                    >
                      {!choose ? (
                        <>
                          <Col sm="6">
                            <Field>
                              <Label>Usuário</Label>
                              <Input
                                block="true"
                                autoComplete="off"
                                placeholder="usuário"
                                value={_database?.users?.[0]?.username}
                                onChange={e => {
                                  updateDatabaseField('users', [
                                    {
                                      ..._database?.users?.[0],
                                      username: e.target.value,
                                    },
                                  ]);
                                }}
                                // error={!!errors?.user?.username}
                              />
                            </Field>
                            {/* {errors?.user?.username && (
                          <Error>{errors?.user?.username[0]}</Error>
                        )} */}
                          </Col>
                          <Col sm="6">
                            <Field>
                              <Label>Senha</Label>
                              <Existent onClick={() => setChoose(true)}>
                                Escolher existente
                              </Existent>
                              <Input
                                block="true"
                                placeholder="senha"
                                value={_database?.users?.[0]?.password}
                                onChange={e => {
                                  updateDatabaseField('users', [
                                    {
                                      ..._database?.users?.[0],
                                      password: e.target.value,
                                    },
                                  ]);
                                }}
                                // error={!!errors?.user?.password}
                              />
                              {/* {errors?.user?.password && (
                            <Error>{errors?.user?.password[0]}</Error>
                          )} */}
                              <FontAwesomeIcon
                                icon={faKey}
                                onClick={() => {
                                  const pass = generatePassword();

                                  updateDatabaseField('users', [
                                    {
                                      ..._database?.users?.[0],
                                      password: pass,
                                    },
                                  ]);
                                }}
                              />
                            </Field>
                          </Col>
                        </>
                      ) : (
                        <Col>
                          <Label>Selecionando um usuário</Label>
                          <Existent onClick={() => setChoose(false)}>
                            Criar novo
                          </Existent>
                          <Select
                            height="40px"
                            value={_database?.users?.[0]?.id}
                            onChange={e => {
                              const [u] = users.filter(
                                u => u.id === Number(e.target.value),
                              );
                              updateDatabaseField('users', [u]);
                            }}
                          >
                            <option value={null}>Selecione um usuário</option>
                            {users?.map(u => (
                              <option key={u.id} value={u.id}>
                                {u.username}
                              </option>
                            ))}
                          </Select>
                        </Col>
                      )}
                    </Row>
                  </>
                ) : (
                  <Row
                    style={{
                      marginLeft: 0,
                      width: '100%',
                      padding: '8px 30px',
                    }}
                  >
                    <Col>
                      <Label>Selecionando um database</Label>
                      <Existent onClick={() => setChooseDatabase(false)}>
                        Criar novo database
                      </Existent>
                      <Select
                        height="40px"
                        value={database.id}
                        onChange={e => {
                          setDatabase(Number(e.target.value));
                          const d = databases.filter(
                            d => d.id === Number(e.target.value),
                          );
                          updateDatabase({
                            id: d[0].id,
                            websites: d[0].websites,
                            patch: true,
                          });
                        }}
                      >
                        <option value={null}>Selecione um database</option>
                        {databases?.map(u => {
                          const name = getValue(u, 'database_name');
                          return (
                            <option key={u.id} value={u.id}>
                              {name}
                            </option>
                          );
                        })}
                      </Select>
                    </Col>
                  </Row>
                )}
              </>
            )}
          </>
        ) : (
          <HelpText>Nenhum database será criado</HelpText>
        )}
      </TabContent>
    </Wrapper>
  );
};

export default Database;
