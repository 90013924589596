import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { Row, Col } from 'react-bootstrap';
import Cards from 'react-credit-cards';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCreditCard, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Scope } from '@rocketseat/unform';

import {
  Modal,
  Field,
  FormInput,
  Label,
  Error,
  Button,
} from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Content,
  Footer,
} from 'components/ListingModal/styles';

import { toast } from 'react-toastify';
import { deleteCreditcardService } from 'services/common';
import { createCreditCardToken } from 'utils/iugu';
import toastError from 'utils/toastError';
import { ModalProps } from 'types';
import { useAuth } from 'hooks/auth';
import { useLayout } from 'hooks/layout';

interface ICreditCardModal extends ModalProps {
  creditCard: any;
  setCreditCard: any;
}

const CreditCardModal: React.FC<ICreditCardModal> = ({
  show,
  setShow,
  creditCard,
  setCreditCard,
}) => {
  const [loading, setLoading] = useState(false);
  const [card, setCard] = useState({
    number: '',
    name: '',
    verification_value: '',
    expiration: '',
  });
  const [focus, setFocus] = useState('number');
  const [cardErrors, setCardErrors] = useState({} as any);
  const [changeCard, setChangeCard] = useState(!creditCard);
  const { user } = useAuth();
  const theme = useLayout();

  const updateCreditCard = async () => {
    setLoading(true);

    try {
      const data = await createCreditCardToken(card);

      setCreditCard(data);

      toast.success('Cartão atualizado com sucesso.');
      setShow(false);
      setLoading(false);
    } catch (e) {
      setLoading(false);

      if (e.errors) {
        setCardErrors(e.errors);
        return;
      }

      toastError(e);
    }
  };

  const deleteCreditCard = async () => {
    try {
      setLoading(true);
      await deleteCreditcardService(creditCard.id);
      setCreditCard(null);
      setChangeCard(true);
      toast.success('Cartão excluído com sucesso.');
      setShow(false);
      setLoading(false);
    } catch (error) {
      toast.error('Algo não está certo.');
      setLoading(false);
      setShow(false);
    }
  };

  return (
    <Modal show={show} setShow={setShow} noExit={loading}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faCreditCard} />
        </Icon>
        <Text>
          <Title>Atualizar cartão de crédito</Title>
        </Text>
      </Header>

      <Content
        style={{
          padding: '17px 20px',
        }}
      >
        {creditCard && !changeCard ? (
          <Field>
            <Label>Cartão de crédito</Label>
            <FormInput
              value={`${creditCard.brand} ${creditCard.display_number}`}
              disabled
              height="40px"
              name="creditCard"
              block="true"
            />
          </Field>
        ) : user.payment_gateway === 'PagBrasil' ? (
          <iframe
            title="payment"
            style={{
              width: '100%',
              height: '520px',
              overflow: 'none',
            }}
            frameBorder="0"
            src={`https://payment.cloudez.io/?uuid=${user.uuid}&brand_primary_color=${theme.brand_primary_color}&brand_secondary_color=${theme.brand_secondary_color}&domain=${window.location.origin}`}
          />
        ) : (
          <Scope path="payment">
            <Row>
              <Col>
                <Cards
                  cvc={card.verification_value}
                  expiry={card.expiration}
                  focused={focus}
                  name={card.name}
                  number={card.number}
                />

                <Row>
                  <Col style={{ marginTop: '10px' }}>
                    <Field>
                      <Label>Número do cartão</Label>
                      <InputMask
                        mask="9999 9999 9999 9999"
                        maskChar={null}
                        block="true"
                        name="number"
                        onChange={e => {
                          setCard({
                            ...card,
                            number: e.target.value,
                          });
                        }}
                        onFocus={e => {
                          setFocus(e.target.name.split('.')[1]);
                        }}
                        error={cardErrors.number && 'true'}
                      >
                        {inputProps => <FormInput {...inputProps} />}
                      </InputMask>

                      {cardErrors.number && <Error>Número inválido</Error>}
                    </Field>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field>
                      <Label>Nome</Label>
                      <FormInput
                        block="true"
                        name="name"
                        onChange={e => {
                          setCard({
                            ...card,
                            name: e.target.value,
                          });
                        }}
                        onFocus={e => {
                          setFocus(e.target.name.split('.')[1]);
                        }}
                        error={!!cardErrors.last_name}
                      />
                      {cardErrors.last_name && <Error>Nome inválido</Error>}
                    </Field>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Field>
                      <Label>Validade</Label>
                      <InputMask
                        mask="99/9999"
                        maskChar={null}
                        block="true"
                        name="expiration"
                        onChange={e => {
                          setCard({
                            ...card,
                            expiration: e.target.value,
                          });
                        }}
                        onFocus={e => {
                          setFocus(e.target.name.split('.')[1]);
                        }}
                        error={cardErrors.expiration && 'true'}
                      >
                        {inputProps => <FormInput {...inputProps} />}
                      </InputMask>

                      {cardErrors.expiration && (
                        <Error>Data de expiração inválida</Error>
                      )}
                    </Field>
                  </Col>
                  <Col>
                    <Field>
                      <Label>cvc</Label>
                      <FormInput
                        maxLength="4"
                        block="true"
                        name="verification_value"
                        onChange={e => {
                          setCard({
                            ...card,
                            verification_value: e.target.value,
                          });
                        }}
                        onFocus={e => {
                          setFocus(e.target.name.split('.')[1]);
                        }}
                        error={!!cardErrors.verification_value}
                      />
                      {cardErrors.verification_value && (
                        <Error>CVC inválido</Error>
                      )}
                    </Field>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Scope>
        )}
        {creditCard && (
          <Button
            outline
            size="mn"
            style={{
              width: 'auto',
              padding: '0 10px',
            }}
            onClick={() => {
              setChangeCard(!changeCard);
            }}
          >
            {!changeCard ? 'Trocar cartão' : 'Usar cartão salvo'}
          </Button>
        )}
      </Content>
      <Footer>
        {!changeCard ? (
          <Button
            disabled={loading}
            icon
            onClick={deleteCreditCard}
            error
            outline
          >
            {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Deletar'}
          </Button>
        ) : (
          <Button disabled={loading} icon onClick={updateCreditCard}>
            {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Atualizar'}
          </Button>
        )}
      </Footer>
    </Modal>
  );
};

export default CreditCardModal;
