import React, { useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';

import { NoResultsDetail } from 'components/Common';
import { AddButton, AddCard } from 'components/AddCard';
import AddonCard from './AddonCard';
import AddAddon from './AddAddon';
import AddonPlaceholder from './Placeholder';

const Addons = ({ addons, cloud, setAddons, addonLoading }) => {
  const [addAddon, setAddAddon] = useState(false);

  return !addAddon ? (
    <Row>
      <Col xs="6" sm="6" md="4" lg="3" xl="2">
        <AddCard width="100%" height="85px" onClick={() => setAddAddon(true)}>
          <AddButton row>
            <FontAwesomeIcon icon={faPlusSquare} />
            <span>adicionar</span>
          </AddButton>
        </AddCard>
      </Col>
      {addonLoading ? (
        <AddonPlaceholder />
      ) : addons?.length ? (
        addons.map(addon => (
          <Col key={addon.id} xs="6" sm="6" md="4" lg="3" xl="2">
            <AddonCard addon={addon} setAddons={setAddons} addons={addons} />
          </Col>
        ))
      ) : (
        <Col xs="6" sm="6" md="4" lg="3" xl="2">
          <NoResultsDetail row width="100%" height="85px">
            <FontAwesomeIcon icon={faUserCircle} />
            Nenhum addon.
          </NoResultsDetail>
        </Col>
      )}
    </Row>
  ) : (
    <AddAddon
      addons={addons}
      setAddons={setAddons}
      cloud={cloud}
      setAddAddon={setAddAddon}
    />
  );
};

export default Addons;
