import React, { useState } from 'react';

import { toast } from 'react-toastify';

import {
  Footer,
  Icon,
  Text,
  Title,
  Header,
} from 'components/ListingModal/styles';

import { useAuth } from 'hooks/auth';

import { Modal, Button } from '@cloudez/cloudez-design-system';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelopeOpenText,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';

import { getCodeEmailService } from 'services/user';

import { Content } from './styles';

interface AlertEmailProps {
  show: boolean;
  setShow: (value: boolean) => void;
  setShowCodeEmail: (value: boolean) => void;
}

const AlertEmailModal: React.FC<AlertEmailProps> = ({
  show,
  setShow,
  setShowCodeEmail,
}) => {
  const { user } = useAuth();

  const [loading, setLoading] = useState(false);

  const sendCodeToEmail = async () => {
    try {
      setLoading(true);
      await getCodeEmailService();
      setShow(false);
      setShowCodeEmail(true);
      setLoading(false);
    } catch {
      toast.error('Não foi possível enviar o código para o email!');
      setLoading(false);
    }
  };

  return (
    <Modal width="497px" show={show} setShow={setShow}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faEnvelopeOpenText} />
        </Icon>
        <Text>
          <Title>Receber o Código por Email</Title>
        </Text>
      </Header>

      <Content>
        <span>
          Clique no botão abaixo para receber o código de segurança no seu email{' '}
          <b>{user.email}</b>
        </span>
      </Content>
      <Footer hasLink>
        <Button disabled={loading} onClick={sendCodeToEmail}>
          {loading ? (
            <FontAwesomeIcon
              style={{ marginLeft: '0px' }}
              icon={faSpinner}
              spin
            />
          ) : (
            'Enviar código'
          )}
        </Button>
      </Footer>
    </Modal>
  );
};

export default AlertEmailModal;
