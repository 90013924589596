import React, { createContext, useReducer } from 'react';
import reducer from './reducer';
import {
  setLoading,
  updatePlan,
  getPlans,
  getBasePlans,
  setRecurrency,
  setPlanType,
  setCoupon,
  setCouponDiscount,
} from './actions';

export interface CloudCreateState {
  plan: any;
  plans: any;
  basePlans: any;
  loading: boolean;
  recurrency: string;
  planType: string;
  coupon: any;
  discount: number;
  discountType: string;

  setCouponDiscount(discount: number, discountType: string): void;
  setCoupon(value: string): void;
  setLoading(value: boolean): void;
  updatePlan(plan: any): void;
  getPlans(size_category: string, stop: boolean): void;
  getBasePlans(): void;
  setRecurrency(recurrency): void;
  setPlanType(planType: any): void;
}

// initial state
const initialState: CloudCreateState = {
  plan: null,
  plans: null,
  basePlans: null,
  loading: false,
  recurrency: 'monthly',
  planType: '',
  coupon: null,
  discount: null,
  discountType: null,
  setCouponDiscount: (discount: number, discountType: string): void => {
    console.log(discount, discountType);
  },
  setCoupon: (coupon: string): void => {
    console.log(coupon);
  },
  setLoading: (value: boolean): void => {
    console.log(value);
  },
  updatePlan: (plan: any) => {},
  getPlans: (size_category: string, stop: boolean) => {},
  getBasePlans: () => {},
  setRecurrency: (recurrency: string) => {},
  setPlanType: (planType: any) => {},
};

// context
export const CloudCreateContext = createContext<CloudCreateState>(initialState);

// provider
export const CloudCreateProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <CloudCreateContext.Provider
      value={{
        // state
        plan: state.plan,
        plans: state.plans,
        basePlans: state.basePlans,
        loading: state.loading,
        recurrency: state.recurrency,
        planType: state.planType,
        coupon: state.coupon,
        discount: state.discount,
        discountType: state.discountType,

        // actions
        setCouponDiscount: (
          discount: string | number,
          discountType: string,
        ): void => setCouponDiscount(dispatch, discount, discountType),
        setCoupon: (value: string) => setCoupon(dispatch, value),
        setLoading: (value: boolean) => setLoading(dispatch, value),
        updatePlan: (plan: any) => updatePlan(dispatch, plan),
        getPlans: async (size_category: string, stop: boolean) =>
          await getPlans(dispatch, size_category, stop),
        getBasePlans: async () => await getBasePlans(dispatch),
        setRecurrency: (recurrency: string) =>
          setRecurrency(dispatch, recurrency),
        setPlanType: (planType: any) => setPlanType(dispatch, planType),
      }}
    >
      {children}
    </CloudCreateContext.Provider>
  );
};
