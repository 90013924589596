import React from 'react';
import { Col } from 'react-bootstrap';

import Placeholder from 'components/Placeholder';

import { Card, Username } from '../UserCard/styles';

const UserPlaceholder: React.FC = () => {
  const placeholders = [];

  for (let i = 1; i <= 3; i++) {
    placeholders.push(i);
  }

  return (
    <>
      {placeholders.map((p, i) => (
        <Col key={i} xl="3" lg="4" sm="6" xs="12">
          <Card>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Placeholder circle width={24} height={24} />

              <Username>
                <Placeholder width={50} height={12} />
              </Username>
            </div>
            <Placeholder width={100} height={12} />
          </Card>
        </Col>
      ))}
    </>
  );
};

export default UserPlaceholder;
