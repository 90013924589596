import React from 'react';

import { Col, Row } from 'react-bootstrap';
import CurrencyFormat from 'react-currency-format';

import { FinancialInfo } from './styles';

export const Details = ({ cloud }) => {
  return (
    <Row xl="3">
      <Col>
        <FinancialInfo>
          Informações de contratação
          <div className="infos">
            <div className="info">
              <span className="label">Proprietário do cloud</span>
              <span className="value">{cloud.admin?.email}</span>
            </div>
          </div>
        </FinancialInfo>
      </Col>
      <Col>
        <FinancialInfo>
          Informações Financeiras
          <div className="infos">
            <div className="info">
              <span className="label">Vencimento</span>
              <span className="value">{cloud.plan?.valid_until || '-'}</span>
            </div>
            <div className="info">
              <span className="label">Recorrência</span>
              <span className="value">
                {cloud.plan?.lifespan_months !== 12 ? 'Mensal' : 'Anual'}
              </span>
            </div>
            <div className="info">
              <span className="label">Valor</span>
              <CurrencyFormat
                value={cloud.plan?.price.amount}
                className="value"
                displayType="text"
                fixedDecimalScale
                decimalScale={2}
                decimalSeparator=","
                thousandSeparator="."
                prefix={cloud.plan?.price?.currency === 'BRL' ? 'R$ ' : '$'}
              />
            </div>
          </div>
        </FinancialInfo>
      </Col>
    </Row>
  );
};
