import React, { useContext, useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import { NoResultsDetail } from 'components/Common';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faWindowMaximize,
  faPlusSquare,
} from '@fortawesome/free-solid-svg-icons';
import { AddCard, AddButton } from 'components/AddCard';
import { getWebsiteService } from 'services/website';
import toastError from 'utils/toastError';
import { DatabaseDetailContext } from '../_context/state';
import ConnectModal from './ConnectModal';
import WebsiteCard from './WebsiteCard';

import WebsitePlaceholder from './WebsitePlaceholder';

const Websites = () => {
  const [addWebsite, setAddWebsite] = useState(false);
  const { database } = useContext(DatabaseDetailContext);
  const [websites, setWebsites] = useState(null);

  useEffect(() => {
    async function getWebsite(id) {
      const { data } = await getWebsiteService(id);
      return data;
    }

    async function mapWebsites() {
      try {
        if (database?.websites) {
          const ws = [];

          for (const w of database.websites) {
            const data = await getWebsite(w);
            ws.push(data);
          }

          if (websites) {
            setWebsites([...websites, ...ws]);
          } else {
            setWebsites([...ws]);
          }
        }
      } catch (err) {
        if (err.response.data.detail) {
          setWebsites([]);
        } else {
          toastError(err);
        }
      }
    }

    if (database && !websites) mapWebsites();
    // eslint-disable-next-line
  }, [database]);

  return (
    <>
      {addWebsite && (
        <ConnectModal
          show={addWebsite}
          setShow={setAddWebsite}
          setWebsites={setWebsites}
          websites={websites}
        />
      )}

      <Row
        style={{
          width: '100%',
          margin: 0,
        }}
      >
        <Col xs="12" sm="6" lg="4" xl="3" onClick={() => setAddWebsite(true)}>
          <AddCard width="100%" height="142px">
            <AddButton>
              <FontAwesomeIcon icon={faPlusSquare} />
              <span>conectar</span>
            </AddButton>
          </AddCard>
        </Col>

        {!websites ? (
          <WebsitePlaceholder />
        ) : websites?.length ? (
          websites.map(wb => (
            <Col key={wb.id} xs="12" sm="6" lg="4" xl="3">
              <WebsiteCard
                database={database}
                website={wb}
                websites={websites}
                setWebsites={setWebsites}
              />
            </Col>
          ))
        ) : (
          <NoResultsDetail>
            <FontAwesomeIcon icon={faWindowMaximize} />
            Nenhum website vinculado.
          </NoResultsDetail>
        )}
      </Row>
    </>
  );
};

export default Websites;
