import React, { useState, useContext, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';

import { NoResultsDetail } from 'components/Common';
import { AddButton, AddCard } from 'components/AddCard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase, faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { getDatabaseService } from 'services/database';
import { Database } from 'types/database';
import DatabaseCard from './DatabaseCard';

import ConnectDatabaseModal from './ConnectDatabaseModal';
import { WebsiteDetailContext } from '../_context/state';

const Databases: React.FC = () => {
  const { website } = useContext(WebsiteDetailContext);

  const [modal, setModal] = useState(false);
  const [databases, setDatabases] = useState<Database[]>(null);

  useEffect(() => {
    const getDatabases = async (id): Promise<Database> => {
      const { data } = await getDatabaseService(id);
      return data;
    };

    async function mapDatabases() {
      if (website?.databases) {
        const dbs: Database[] = [];

        for (const database of website.databases) {
          const data = await getDatabases(database);
          dbs.push(data);
        }

        if (databases) {
          setDatabases(databases => [...databases, ...dbs]);
        } else {
          setDatabases([...dbs]);
        }
      }
    }

    if (!databases) mapDatabases();
    // eslint-disable-next-line
  }, [website]);

  return (
    <Row>
      {modal && (
        <ConnectDatabaseModal
          show={modal}
          setShow={() => setModal(false)}
          setDatabases={setDatabases}
        />
      )}

      <Col xl="3" lg="4" sm="6">
        <AddCard
          onClick={() => setModal(true)}
          width="100%"
          style={{
            marginBottom: '12px',
          }}
        >
          Novo database
          <AddButton>
            <FontAwesomeIcon icon={faPlusSquare} />
            <span>criar</span>
          </AddButton>
        </AddCard>
      </Col>
      {databases ? (
        databases.map(db => (
          <Col xl="3" lg="4" sm="6" key={db.id}>
            <DatabaseCard db={db} setDatabases={setDatabases} />
          </Col>
        ))
      ) : (
        <NoResultsDetail>
          <FontAwesomeIcon icon={faDatabase} />
          Nenhum banco de dados instalado.
        </NoResultsDetail>
      )}
    </Row>
  );
};

export default Databases;
