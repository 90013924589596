import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 230px;
  height: auto;
  max-height: 240px;

  position: absolute;
  background: ${props => props.theme.interface1};
  border: 1px solid ${props => props.theme.interface3};
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  z-index: 999;
  top: 8px;
  /* left: calc(50% - 115px); */

  & > a {
    font-size: 11px;
    color: ${props => props.theme.interface5};
    cursor: pointer;
    display: block;
    margin: 3px 4px;
    text-align: right;
  }

  @media (max-width: 768px) {
    width: 120px;
    left: -35px;
  }
`;

export const List = styled.ul`
  color: ${props => props.theme.interface5};
  overflow: auto;
  max-height: 220px;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #e8eaef;
  }

  &::-webkit-scrollbar-thumb {
    background: #cfd3de;
  }
`;

interface INotificationProps {
  status: number;
}

export const Notification = styled.li<INotificationProps>`
  & > a,
  & > span {
    color: ${props => props.theme.interface5};
    text-decoration: none;
    padding: 10px 8px;
    text-align: left;
    font-size: 13px;
    font-weight: bold;
    display: flex;
    align-items: center;
    width: 100%;
    cursor: pointer;

    svg {
      font-size: 18px;
      margin-right: 10px;
    }

    & > div {
      span {
        display: block;
        font-size: 11px;
        font-weight: normal;
      }
    }

    &:hover {
      transition: color 0.1s ease-in;
      color: ${props => props.theme.brand_primary_color};
    }

    ${props =>
      props.status !== 0 &&
      css`
        background: ${props => props.theme.interface3};
      `}
  }
`;
