/* eslint-disable prettier/prettier */
import { Tabs } from 'react-bootstrap';
import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 25px;
  width: 100%;
  height: 100%;

  & > p {
    color: ${props => props.theme.interface5};
    margin: 20px 0px;
    text-align: center;
  }
`;

interface IPlanCardProps {
  private?: boolean;
  selected?: boolean;
}

export const PlanCard = styled.div<IPlanCardProps>`
  position: relative;
  height: 346px;

  max-width: 245px;
  margin: 15px 20px;
  background: ${props =>
    props.theme.darkMode ? props.theme.interface3 : props.theme.interface1};
  border-radius: 5px;
  border: 2px solid transparent;

  padding: 30px 0px;

  box-shadow: 0px 3px 15px #64728c4d;

  ul {
    li {
      font-weight: 600;
      font-size: 10px;
      color: ${props => props.theme.interface5};
    }

    li.value {
      font-size: 13px;

      margin-bottom: 10px;
    }
  }

  div.plan-title {
    display: flex;
    justify-content: space-between;

    color: ${props => props.theme.brand_primary_color};

    font-weight: 700;
    font-size: 14px;

    width: 100%;

    margin-bottom: 5px;
    word-break: break-word;

    & > span {
      font-size: 13px;
    }
  }

  button {
    margin: 0px auto 0px auto;
  }

  div.prices {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    .recommended {
      background-color: rgba(34, 83, 245, 0.1);
      border-radius: 5px;

      display: flex;
      flex-direction: column;

      .price {
        padding: 0 8px 8px 8px;
        margin-top: 0;
      }

      .idealPlan {
        width: 100%;

        text-transform: uppercase;
        color: #ffffff !important;

        border-top-left-radius: 5px;
        border-top-right-radius: 5px;

        background-color: ${props => props.theme.successNew};

        font-weight: 700 !important;
        font-size: 10px !important;
        text-align: center;
      }
    }

    div.price {
      text-align: left;
      display: flex;
      flex-direction: column;

      margin-top: 10px;

      span {
        color: ${props => props.theme.brand_primary_color};
        font-size: 18px;
        font-weight: 700;
      }

      span:first-child {
        color: ${props => props.theme.interface5};
        font-size: 20px;
        font-weight: 300;
      }
    }
  }

  div.separator {
    border-bottom: 1px solid ${props => props.theme.interface3};
  }

  span.tip {
    font-size: 12px;
    color: ${props => props.theme.interface5};
    line-height: 16px;
    word-break: break-word;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  div.top {
    height: 55%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 0 10px 10px 10px;
  }

  div.bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 0px 10px;

    height: 35%;
    text-align: center;
  }

  transition: all 0.2s;

  /* cursor: pointer; */

  /* &:hover {
    border-color: ${props =>
    props.theme.darkMode
      ? props.theme.interface5
      : props.theme.brand_primary_color};
  } */

  ${props =>
    props.private &&
    css`
      border-color: ${props => props.theme.favorite};
    `}

  ${props =>
    props.selected &&
    css`
      border-color: ${props =>
        props.theme.darkMode
          ? props.theme.interface5
          : props.theme.brand_primary_color};
    `}
`;

export const InfoCard = styled.div`
  width: 100%;
  height: 400px;
  padding: 12px 16px;

  color: ${props =>
    props.theme.darkMode
      ? props.theme.interface4
      : props.theme.brand_primary_color};
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  border: 1px solid
    ${props =>
      props.theme.darkMode
        ? props.theme.interface4
        : props.theme.brand_primary_color};
  border-radius: 10px;

  ul {
    list-style: none;
    height: 100%;
    margin-bottom: 10px;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  ul li:before {
    content: '✓';
    font-size: 30px;
    margin-right: 10px;
  }

  span {
    text-align: left;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
  }

  li {
    text-align: left;
    font-size: 14px;
    display: flex;
    align-items: center;
  }

  p {
    font-size: 12px;
    text-align: left;
    letter-spacing: 0;
  }
`;

export const Private = styled.div`
  position: absolute;
  top: 4px;
  right: 8px;
  font-size: 18px;
  color: ${props => props.theme.favorite};

  svg {
    opacity: 0.5;
  }
`;

export const RecurrencySelect = styled.div`
  display: flex;
  justify-content: center;

  div {
    padding: 6px 0px;
    width: 128px;
    text-align: center;
    border-radius: 0 4px 4px 0;
    border: 1px solid ${props => props.theme.brand_primary_color};
    color: ${props => props.theme.brand_primary_color};
    user-select: none;
    cursor: pointer;
  }

  div.selected {
    background: ${props => props.theme.brand_primary_color};
    color: #fff;
  }

  & > div:first-child {
    border-radius: 4px 0 0 4px;
    border-right-width: 0px;
  }
`;
