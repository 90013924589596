import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  margin-top: 25px;

  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column-reverse;
    justify-content: center;
  }
`;

interface IPlanTypeCardProps {
  selected?: boolean;
  turbo?: boolean;
}
export const PlanTypeCard = styled.div<IPlanTypeCardProps>`
  position: relative;

  width: 100%;

  cursor: pointer;

  padding: 40px 25px;

  border-radius: 5px;
  border: 2px solid transparent;

  background: ${props => props.theme.interface1};

  :hover {
    border: 2px solid ${props => props.theme.brand_primary_color};
    box-shadow: 0px 2px 15px rgba(100, 114, 140, 0.25);

    transition: all 0.2s ease-in-out;
  }

  ${props =>
    props.selected &&
    css`
      border: 2px solid ${props => props.theme.brand_primary_color};
      box-shadow: 0px 2px 15px rgba(100, 114, 140, 0.25);
    `}

  div.content {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-columns: 100px;

    @media (max-width: 900px) {
      grid-template-columns: 1fr 1fr;
    }

    @media (max-width: 600px) {
      grid-template-columns: 1fr;
    }

    gap: 15px 10px;
    margin-top: 30px;

    .box {
      display: flex;
      flex-direction: row;
    }

    span.red {
      svg {
        color: ${props => props.theme.errorNew};
      }
    }

    svg {
      color: ${props => props.theme.brand_primary_color};

      font-size: 20px;
    }
  }

  div.radio {
    display: flex;
    align-items: center;

    div.selection {
      width: 15px;
      height: 15px;

      border: 2px solid ${props => props.theme.interface4};
      border-radius: 999px;
      background: transparent;

      margin-right: 10px;

      ${props =>
        props.selected &&
        css`
          border: 2px solid ${props => props.theme.brand_primary_color};

          :after {
            content: '';
            background: ${props => props.theme.brand_primary_color};
            border-radius: 50%;
            width: 9px;
            height: 9px;
            position: relative;
            display: inline-block;
            top: -5.9px;
            left: 1px;
          }
        `}
    }

    span {
      font-size: 15px;
      text-transform: uppercase;
      font-weight: 700;

      margin-left: 5px;
    }

    path,
    ellipse,
    rect,
    circle {
      fill: ${props => props.theme.brand_primary_color};
    }
  }

  @media (max-width: 768px) {
    height: 100%;
    width: 100%;
    div.content {
      flex-direction: column;
      span {
        width: auto;
      }
    }
  }
`;

export const Best = styled.div`
  width: 170px;

  background: ${props => props.theme.brand_primary_color};

  padding: 4px 0px;

  border-radius: 0px 0px 4px 4px;

  font-size: 11px;

  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: -1px;
  left: 50%;
  transform: translate(-50%, 0);

  color: #fff;

  svg {
    margin-right: 5px;
  }
`;

export const PlanTypes = styled.div`
  width: 70%;

  ${PlanTypeCard} + ${PlanTypeCard} {
    margin-top: 40px;
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const Details = styled.div`
  width: 25%;

  @media (max-width: 768px) {
    /* margin-left: -30px;
    margin-right: -30px; */

    width: 100%;
  }
`;

export const Title = styled.h1`
  color: ${props => (props.theme.darkMode ? props.theme.interface5 : '#333')};
  font-size: 28px;

  margin-bottom: 25px;
`;

export const Infos = styled.div`
  font-size: 14px;
  color: ${props => props.theme.interface5};
  margin-top: 20px;

  p {
    margin-bottom: 5px;
  }

  p.alert {
    font-weight: bold;
    margin-bottom: 8px;
    color: ${props => props.theme.alertNew};
  }
`;
