import styled from 'styled-components';

interface ContainerProps {
  spacing?: boolean;
  selected?: boolean;
}

export const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-right: ${props => props.spacing && '32px'};

  cursor: pointer;

  color: ${props =>
    props.selected ? props.theme.brand_primary_color : '#a2a9bf'};

  img {
    width: 24px;
    height: 23px;
  }

  p {
    margin-top: 2px;
    font-weight: 700;
    font-size: 13px;
  }
`;
