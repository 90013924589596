import styled from 'styled-components';

export const Container = styled.div`
  padding: 18px 20px;
  display: flex;
  flex-direction: column;
  gap: 16px;

  .gridCards {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16px;
  }

  .footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .finishAllSectionsButton {
      font-weight: bold;
      font-size: 12px;
      line-height: 12px;

      text-transform: uppercase;

      border: 0;
      background: transparent;
      border: 2px solid ${props => props.theme.errorNew};
      border-radius: 5px;
      color: ${props => props.theme.errorNew};
      padding: 9px;
    }
  }
`;

export const Title = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  font-weight: 600;
  gap: 8px;
  font-size: 16px;
  line-height: 140%;

  color: ${props =>
    props.theme.darkMode ? props.theme.interface3 : props.theme.interface7};

  .active {
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;

    background-color: ${props => props.theme.successNew};
    color: ${props => props.theme.interface1};
    padding: 1px 5px;
    border-radius: 23px;
  }
`;

export const Text = styled.span`
  font-weight: 300;
  font-size: 16px;
  line-height: 140%;

  color: ${props => props.theme.interface5};
`;

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;

  background: ${props => props.theme.interface1};
  border: 2px solid ${props => props.theme.interface3};
  border-radius: 5px;

  max-width: 337px;
`;

export const Separator = styled.div`
  border-top: 1px solid ${props => props.theme.interface3};
`;
