import React, { useContext } from 'react';
import { Row, Col } from 'react-bootstrap';
import ApplicationIcon from 'components/ApplicationIcon';
import {
  FormInput,
  Field,
  Label,
  Error,
  Checkbox,
  Input,
  Button,
} from '@cloudez/cloudez-design-system';
import migSite2 from 'assets/img/svg/migsite2.svg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExternalLinkAlt,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { ThemeContext } from 'styled-components';
import { MigrationCreateContext } from '../../_context/state';
import { Origin as OriginStyle, Image, Card } from '../styles';

const Origin = ({
  checked,
  vldPayload,
  setFtpUser,
  ftpUser,
  setPath,
  path,
  port,
  setPort,
  setSftp,
  sftp,
  domain,
  host,
  setHost,
  setDomain,
  autoPoint,
  setAutoPoint,
  vldLoading,
  selectedNodeapp,
  setSelectedNodeapp,
  isExternal,
  setIsExternal,
}) => {
  const { loading, errors } = useContext(MigrationCreateContext);
  const theme = useContext(ThemeContext);

  return (
    <OriginStyle>
      <h3>Origem</h3>
      <Row
        style={{
          width: '100%',
        }}
      >
        <Col sm="12">
          <Field>
            <Label>Domínio</Label>
            <FormInput
              value={domain}
              onChange={e => setDomain(e.target.value)}
              block="true"
              name="domain"
              error={!!errors?.domain}
            />
            {!!errors?.domain && <Error>{errors?.domain}</Error>}
          </Field>
        </Col>
      </Row>

      {checked && (
        <>
          {vldPayload?.websites && !isExternal && (
            <>
              <Row
                style={{
                  width: '100%',
                }}
              >
                {vldPayload?.websites?.map(({ website, is_owner }) => (
                  <Col
                    key={website.id}
                    sm="6"
                    xs="12"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Card
                      key={website.id}
                      selected={website.id === selectedNodeapp.id}
                      onClick={() =>
                        setSelectedNodeapp({
                          id: website.id,
                          is_owner,
                          type: 'nodeapp',
                        })
                      }
                    >
                      <ApplicationIcon
                        icon={website.type}
                        width="45px"
                        height="45px"
                      />

                      <div
                        style={{
                          width: '100%',
                          whiteSpace: 'nowrap',
                          textAlign: 'center',
                          textOverflow: 'ellipsis',
                          overflow: 'hidden',
                        }}
                      >
                        <span>{website.domain}</span>
                      </div>
                      <div
                        style={{
                          textAlign: 'center',
                          width: '100%',
                        }}
                      >
                        <p>{website.cloud_nickname || website.cloud_name}</p>
                      </div>
                    </Card>
                  </Col>
                ))}
                <Col
                  sm="6"
                  xs="12"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Card onClick={() => setIsExternal(true)}>
                    <FontAwesomeIcon
                      icon={faExternalLinkAlt}
                      style={{ fontSize: '45px' }}
                      color={theme.interface6}
                    />

                    <div
                      style={{
                        width: '100%',
                        whiteSpace: 'nowrap',
                        textAlign: 'center',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                      }}
                    >
                      <span>Website externo</span>
                    </div>
                  </Card>
                </Col>
              </Row>
              <FormInput
                name="nodeapp_src"
                style={{
                  display: 'none',
                }}
                value={selectedNodeapp.id}
                onChange={() => {}}
                error={!!errors?.nodeapp_src}
              />
              {!!errors?.nodeapp_src && (
                <Error
                  style={{
                    marginBottom: '15px',
                  }}
                >
                  {errors?.nodeapp_src}
                </Error>
              )}
              <Row
                style={{
                  width: '100%',
                }}
              >
                <Col xs="12">
                  <Field>
                    <Label>Apontar automaticamente o DNS</Label>
                    <p
                      style={{
                        fontSize: '10px',
                        marginTop: '-5px',
                      }}
                    >
                      *O apontamento somente será realizado se o website de
                      origem estiver hospedado e ativo no seu painel.
                    </p>
                    <Checkbox
                      value={autoPoint}
                      checked={autoPoint}
                      onChange={e => setAutoPoint(e.target.checked)}
                    />
                  </Field>
                </Col>
              </Row>
            </>
          )}

          {(!vldPayload?.websites || isExternal) && (
            <>
              <Row
                style={{
                  width: '100%',
                }}
              >
                <Col sm="12">
                  <Field>
                    <Label>Servidor FTP</Label>
                    <FormInput
                      value={host}
                      onChange={e => setHost(e.target.value)}
                      block="true"
                      name="host"
                      error={!!errors?.host || !!errors?.connection}
                    />
                    {(!!errors?.host || !!errors?.connection) && (
                      <Error>{errors?.host || errors?.connection}</Error>
                    )}
                  </Field>
                </Col>
              </Row>
              <Row
                style={{
                  width: '100%',
                }}
              >
                <Col sm="12">
                  <Field>
                    <Label>Usuário FTP</Label>
                    <FormInput
                      block="true"
                      name="user"
                      error={!!errors?.auth || !!errors?.username}
                      value={ftpUser.username}
                      onChange={e =>
                        setFtpUser({ ...ftpUser, username: e.target.value })
                      }
                    />
                    {(!!errors?.auth || !!errors?.username) && (
                      <Error>{errors?.auth || errors?.username}</Error>
                    )}
                  </Field>
                </Col>
              </Row>
              <Row
                style={{
                  width: '100%',
                }}
              >
                <Col sm="12">
                  <Field>
                    <Label>Senha FTP</Label>
                    <FormInput
                      type="password"
                      block="true"
                      name="password"
                      error={!!errors?.auth || !!errors?.password}
                      value={ftpUser.password}
                      onChange={e =>
                        setFtpUser({ ...ftpUser, password: e.target.value })
                      }
                    />
                    {(!!errors?.auth || !!errors?.password) && (
                      <Error>{errors?.auth || errors?.password}</Error>
                    )}
                  </Field>
                </Col>
              </Row>
              <Row
                style={{
                  width: '100%',
                }}
              >
                <Col xl="2">
                  <Field>
                    <Label>sFTP/SSH</Label>
                    <Checkbox
                      value={sftp}
                      onChange={e => setSftp(e.target.checked)}
                    />
                  </Field>
                </Col>
                {sftp && (
                  <>
                    <Col xl="3">
                      <Field>
                        <Label>PORTA</Label>
                        <Input
                          block="true"
                          type="number"
                          value={port}
                          onChange={e => setPort(e.target.value)}
                          error={!!errors?.port}
                        />
                        {!!errors?.port && <Error>{errors?.port}</Error>}
                      </Field>
                    </Col>
                    <Col xl="7">
                      <Field>
                        <Label>Caminho da aplicação</Label>
                        <Input
                          block="true"
                          value={path}
                          onChange={e => setPath(e.target.value)}
                          error={!!errors?.path}
                        />
                        {!!errors?.path && <Error>{errors?.path}</Error>}
                      </Field>
                    </Col>
                  </>
                )}
              </Row>
            </>
          )}
        </>
      )}

      {!checked && (
        <Row
          style={{
            width: '100%',
          }}
        >
          <Image>
            <img src={migSite2} alt="migsite" />
          </Image>
        </Row>
      )}

      <Button icon type="submit" disabled={loading || vldLoading}>
        {loading || vldLoading ? (
          <FontAwesomeIcon icon={faSpinner} spin />
        ) : checked ? (
          'Criar'
        ) : (
          'Validar'
        )}
      </Button>
    </OriginStyle>
  );
};

export default Origin;
