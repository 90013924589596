import React, { useContext } from 'react';

import { Button } from '@cloudez/cloudez-design-system';
import parabens from 'assets/img/parabens.png';

import history from 'services/history';

import { ThemeContext } from 'styled-components';
import { useAuth } from 'hooks/auth';
import { Wrapper } from './styles';

const CloudCreateSuccess: React.FC = () => {
  const theme = useContext(ThemeContext);
  const { user } = useAuth();

  return (
    <Wrapper>
      <div
        style={{
          background: theme.brand_primary_color,
          width: '525px',
          height: '380px',
        }}
      >
        <img src={parabens} alt="parabéns" />
      </div>
      <span>Parabéns</span>
      <p>Seu cloud foi criado!</p>
      {user.payment_gateway === 'PagBrasil' && (
        <p className="subtitle">
          Esta cobrança aparecerá na fatura de seu cartão de crédito como{' '}
          <b>PB* {user.company_name} , PORTO ALEGRE</b>
        </p>
      )}
      <Button size="lg" onClick={() => history.push('/clouds')}>
        Dashboard
      </Button>
    </Wrapper>
  );
};

export default CloudCreateSuccess;
