import React, { createContext, useCallback, useEffect, useState } from 'react';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import api from 'services/api';
import toastError from 'utils/toastError';
import { getUserService } from 'services/user';
import setApiToken from 'utils/setApiToken';
import md5 from 'md5';
import { toast } from 'react-toastify';
import { useLayout } from 'hooks/layout';
import TermsModal from 'components/TermsModal';
import { User } from 'types/auth';

export interface AuthState {
  user: User;
  loading: boolean;
  skipBilling: boolean;
  isAuthenticated: boolean;
  token2FA: string;

  loginData: { email: string; password: string };
  devices: any;

  signInError: string;

  signIn(
    email: string,
    password: string,
    params?: any,
    twoAuthData?: any,
  ): void;
  setLoginData: any;
  finishLogin(token: string, user: any, token_2fa?: any): void;
  signOut(): void;
  updateUser(field: string, value: any): void;
  skipUpdateBilling(): void;
  getUser(): void;
}

export const AuthContext = createContext<AuthState>({} as AuthState);

export const AuthProvider: React.FC = ({ children }) => {
  const layout = useLayout();

  const [loading, setLoading] = useState(null);
  const [user, setUser] = useState(null);
  const [skipBilling, setSkipBilling] = useState(false);

  const [loginData, setLoginData] = useState({} as any);
  const [signInError, setSignInError] = useState('');
  const [devices, setDevices] = useState([]);

  const isAuthenticated = !!user;
  const [token, setToken] = useState(() => {
    return localStorage.getItem('@cloudez-app-v3/token');
  });

  const handleAmpl = user => {
    if (user.is_staff) return;

    const body = document.getElementsByTagName('body')[0];

    const amplScript = document.createElement('script');
    amplScript.type = 'text/javascript';
    amplScript.setAttribute('name', 'amplScript');
    amplScript.text = `
      window.amplData = {
        email: '${user.email}',
        created_at: '${user.customer_at}',
        name: '${user.full_name}',
        phone: '${user.phone}',
        company: '${user.company_name}',
        survey: '${
          user.is_company_owner
            ? '625885f1bf18a2e07fbc88fb'
            : '6254338aa0604a1ee87742ee'
        }',
        callback: function(data){}
      }
    `;

    const amplRunScript = document.createElement('script');
    amplRunScript.type = 'text/javascript';
    amplRunScript.setAttribute('name', 'amplRunScript');
    amplRunScript.text = `
      window.AmplSurvey.run('run');
    `;

    body.appendChild(amplScript);
    body.appendChild(amplRunScript);
  };

  const handleCompass = useCallback(user => {
    const body = document.getElementsByTagName('body')[0];
    const compassScript = document.createElement('script');
    compassScript.type = 'text/javascript';
    compassScript.setAttribute('name', 'conpassScript');
    compassScript.text = `
      if (window.Conpass) {
        window.Conpass.init({
          name: '${user.full_name}',
          email: '${user.email}',
          custom_fields: {
            id: ${user.id},
            company: '${user.company_slug}',
            is_customer: ${user.is_customer},
            is_staff: ${user.is_staff},
            company_owner: ${user.is_company_owner},
            company_created_at: '${user.company_created_at}',
            is_whitelabel_app: true,
          }
        });
      }
    `;

    body.appendChild(compassScript);
  }, []);

  const handleWootric = useCallback(user => {
    if (
      process.env.NODE_ENV !== 'development' &&
      user.company_slug === 'configr'
    ) {
      const body = document.getElementsByTagName('body')[0];
      const scriptWootricBdy = document.createElement('script');
      scriptWootricBdy.type = 'text/javascript';
      scriptWootricBdy.setAttribute('name', 'wootricBdy');
      scriptWootricBdy.text = `
        window.wootricSettings = {
          email: '${user.email}',
          external_id: '${user.id}',
          created_at: ${user.customer_at},
          account_token: 'NPS-b115ee99'
        };
      `;

      body.appendChild(scriptWootricBdy);

      const { wootric, wootricLoaded } = window as any;

      if (typeof wootric === 'function' && !wootricLoaded) {
        const scriptWootricRun = document.createElement('script');

        scriptWootricRun.type = 'text/javascript';
        scriptWootricRun.setAttribute('name', 'wootricRun');
        scriptWootricRun.text = `
            window.wootricLoaded = true;
            window.wootric('run');
        `;

        body.appendChild(scriptWootricRun);
      }
    }
  }, []);

  const getAvatar = useCallback(email => {
    const hash = md5(email);
    return `https://www.gravatar.com/avatar/${hash}?d=mp`;
  }, []);

  const token2FA = localStorage.getItem('@cloudez-app-v3/2fa_token');

  // Sign in action
  const signIn = useCallback(
    async (email, password, params?, twoAuthData?) => {
      setLoading(true);
      setLoginData({ email, password, company: layout.code });
      try {
        const payload: any = twoAuthData
          ? {
              password,
              company: layout.code,
              email: '',
              username: '',
              code: twoAuthData.code,
              device_type: twoAuthData.device_type,
              trust_device: twoAuthData.trust_device,
            }
          : {
              password,
              company: layout.code,
              email: '',
              username: '',
            };

        const headers: any = {};
        if (token2FA) headers['X-token2fa'] = token2FA;

        if (email.includes('@')) {
          payload.email = email;
        } else {
          payload.username = email;
        }

        const {
          data: { token, user, token2fa },
        } = await api.post('/auth/login/', payload, {
          params,
          headers,
        });

        if (token2fa) {
          localStorage.setItem('@cloudez-app-v3/2fa_token', token2fa);
        }

        finishLogin(token, user);
        setSignInError('');
      } catch (e) {
        if (
          e.response?.data.message === 'User has 2FA enabled' ||
          e.response?.data.message === 'Verification code sent in email' ||
          e.response?.data.message === 'Verification code failed'
        ) {
          setSignInError(e.response?.data.message);
          setDevices(e.response?.data.devices);
        } else {
          toastError(e);
        }
        setLoading(false);
      }
    },
    [layout, token2FA],
  );

  const finishLogin = useCallback(
    async (token, user) => {
      setApiToken(token);

      const newUser = await getUser(user);
      handleWootric(newUser);
      handleCompass(newUser);
      if (newUser.company_slug === 'configr' || newUser.is_company_owner)
        handleAmpl(newUser);

      // if (
      //   process.env.NODE_ENV !== 'development' &&
      //   (layout.slug === 'roberto4-hexah-digital' ||
      //     layout.slug === 'grupodpg' ||
      //     layout.slug === 'onosam-onocomp-informatica')
      // ) {
      //   layout.updateTawkTo(newUser);
      // } else if (layout.has_partner_chat)
      //   setTimeout(() => {
      //     layout.loadCloudezChat(layout, newUser);
      //   }, 1000);

      localStorage.setItem('@cloudez-app-v3/token', token);
      setToken(token);

      layout.getTheme();

      setLoading(false);
    },
    [layout],
  );

  // Sign out action
  const signOut = useCallback(() => {
    const wootricBdy = document.getElementsByName('wootricBdy')[0];
    const wootricRun = document.getElementsByName('wootricRun')[0];
    const conpassScript = document.getElementsByName('conpassScript')[0];

    const amplScript = document.getElementsByName('amplScript')[0];
    const amplRunScript = document.getElementsByName('amplRunScript')[0];

    if (wootricBdy) wootricBdy.remove();
    if (wootricRun) wootricRun.remove();
    if (conpassScript) conpassScript.remove();
    if (amplScript) amplScript.remove();
    if (amplRunScript) amplRunScript.remove();

    localStorage.removeItem('@cloudez-app-v3/token');
    localStorage.removeItem('@cloudez-app-v3/user');

    // if (layout.has_partner_chat) layout.loadCloudezChat(layout);

    setUser(null);
    setSignInError('');
    setApiToken(null);
  }, [layout]);

  const getUser = useCallback(async (_user = null) => {
    const lsUser = JSON.parse(localStorage.getItem('@cloudez-app-v3/user'));
    const user = _user || lsUser;

    const { data } = await getUserService(user.id);
    const avatar = getAvatar(data.email);

    const fullUser = {
      ...user,
      ...data,
      avatar,
    };

    setUser(fullUser);
    localStorage.setItem('@cloudez-app-v3/user', JSON.stringify(fullUser));
    return fullUser;
  }, []);

  const refreshToken = useCallback(async oldToken => {
    try {
      const response = await api.post('/auth/login/refresh/', {
        token: oldToken,
      });

      const { token } = response.data;

      localStorage.setItem('@cloudez-app-v3/token', token);
      setToken(token);

      setApiToken(token);
    } catch (error) {
      console.log(error);
    }
  }, []);

  const updateUser = useCallback(
    async (field, value) => {
      try {
        await api.patch(`/v3/user/${user.id}/`, {
          [field]: value,
        });

        await getUser();

        toast.success('Usuário atualizado com sucesso');
      } catch (e) {
        toastError(e);
      }
    },
    [user],
  );

  const skipUpdateBilling = () => {
    setSkipBilling(true);
  };

  useEffect(() => {
    let id;

    if (token) {
      const decoded = jwtDecode(token);

      if (decoded?.exp) {
        const now = moment();
        const expiredAt = moment.unix(decoded.exp);

        const diff = moment.duration(expiredAt.diff(now)).asSeconds();

        if (diff > 10) {
          id = setTimeout(async () => {
            await refreshToken(token);
          }, (diff - 10) * 1000);
          return;
        }

        return signOut();
      }
    }

    return () => {
      clearTimeout(id);
    };
  }, [token, refreshToken, signOut]);

  useEffect(() => {
    let id2fa;

    const token2FA = localStorage.getItem('@cloudez-app-v3/2fa_token');

    if (token2FA) {
      const decoded = jwtDecode(token2FA);

      if (decoded?.exp) {
        const now = moment();
        const expiredAt = moment.unix(decoded.exp);
        const diff = moment.duration(expiredAt.diff(now)).asSeconds();

        if (diff > 10) {
          id2fa = setTimeout(() => {
            localStorage.removeItem('@cloudez-app-v3/2fa_token');
          }, 2147483647);
          return;
        }

        return signOut();
      }
    }

    return () => {
      clearTimeout(id2fa);
    };
  }, []);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('@cloudez-app-v3/user'));

    if (token && user) {
      setUser(oldUser => ({
        ...oldUser,
        ...user,
      }));

      finishLogin(token, user);
    }
  }, []);

  return (
    <AuthContext.Provider
      value={{
        signIn,
        user,
        signOut,
        finishLogin,
        getUser,
        isAuthenticated,
        loading,
        updateUser,
        skipUpdateBilling,
        skipBilling,

        signInError,
        setLoginData,
        loginData,
        devices,
        token2FA,
      }}
    >
      {!!user && <>{!user.accepted_terms && <TermsModal />}</>}
      {children}
    </AuthContext.Provider>
  );
};

export default {
  AuthProvider,
};
