import React, { useState, useContext, useCallback, useMemo } from 'react';

import { Col, Row } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDatabase,
  faShareAlt,
  faCog,
  faStar,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { ThemeContext } from 'styled-components';

import {
  Card,
  ListingItem,
  ApplicationValue,
  ApplicationName,
  CloudValue,
  ApplicationSubName,
} from 'components/Listing/styles';
import { Acesso, AcessosList, AcessoHover, Value } from 'components/Acessos';
import formatCloudDisplay from 'utils/formatCloudDisplay';
import ApplicationIcon from 'components/ApplicationIcon';
import ListingModal from 'components/ListingModal';
import { Link } from 'react-router-dom';
import kbToMb from 'utils/kbToMb';
import api from 'services/api';

import { getValue } from 'utils/getValue';
import { Database } from 'types/database';
import AdminModal from './AdminModal';
import { DatabaseListingContext } from '../_context/state';

interface IDatbaseCardProps {
  database: Database;
}

const DatabaseCard: React.FC<IDatbaseCardProps> = ({ database }) => {
  const { updateDatabase, updateDatabaseField } = useContext(
    DatabaseListingContext,
  );

  const [modal, toggleModal] = useState(false);
  const [adminModal, toggleAdminModal] = useState(false);
  const [showAccess, setShowAccess] = useState(false);
  const [data, setData] = useState(null);
  const [type, setType] = useState('ftp');
  const [loading, setLoading] = useState(false);
  const theme = useContext(ThemeContext);

  const openModal = useCallback(
    (e, type, data) => {
      e.preventDefault();
      e.stopPropagation();
      setData(data);
      setType(type);
      toggleModal(!modal);
    },
    [modal],
  );

  const activateAdmin = useCallback(async () => {
    try {
      await api.post('/v3/addon/', {
        cloud: database.cloud,
        is_pinned: false,
        type: database.type.slug === 'mysql' ? 12 : 13,
        values: [{ slug: 'password_protected', value: 'false' }],
      });

      let payload;

      if (database.type.slug === 'mysql') {
        payload = {
          name: 'PhpMyAdmin',
          slug: 'phpmyadmin',
          url: `http://${database.cloud_fqdn}/phpmyadmin`,
        };
      } else {
        payload = {
          name: 'PhpPgAdmin',
          slug: 'phppgadmin',
          url: `http://${database.cloud_fqdn}/phppgadmin`,
        };
      }

      updateDatabaseField('admin_url', payload, database.id);
    } catch (e) {
      console.log(e);
    }
  }, [database, updateDatabaseField]);

  const pinDatabase = useCallback(
    async e => {
      setLoading(true);
      e.preventDefault();

      await updateDatabase('is_pinned', !database.is_pinned, database.id);
      setLoading(false);
    },
    [database, updateDatabase],
  );

  const cloudDisplay = useMemo(
    () => database && formatCloudDisplay(database.cloud_display),
    [database],
  );

  const name = useMemo(() => getValue(database, 'database_name'), [database]);

  return (
    <ListingItem key={database.id} disable={database.is_disabled}>
      {modal && (
        <ListingModal
          show={modal}
          setShow={() => toggleModal(false)}
          type={type}
          data={data}
          action={updateDatabase}
        />
      )}
      {adminModal && (
        <AdminModal
          show={adminModal}
          setShow={() => toggleAdminModal(false)}
          type={database.type.slug}
          action={activateAdmin}
        />
      )}
      <Link to={`/databases/${database.id}`}>
        <Card>
          <Row>
            <Col
              xs={showAccess ? '4' : '10'}
              sm={showAccess ? '4' : '10'}
              md="5"
            >
              <ApplicationValue>
                <ApplicationIcon
                  width="40px"
                  height="40px"
                  icon={`${database.type.slug}`}
                  disable={database.is_disabled}
                />
                {!showAccess && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                    }}
                  >
                    <ApplicationName>{name}</ApplicationName>
                    <ApplicationSubName>
                      {database.type.name}
                    </ApplicationSubName>
                  </div>
                )}
              </ApplicationValue>
            </Col>

            <Col className="d-none d-xl-flex">
              <ApplicationValue style={{ fontWeight: 'normal' }}>
                <CloudValue>
                  <span>{cloudDisplay[0]}</span>
                  <span
                    style={{
                      fontSize: `${10}px`,
                    }}
                  >
                    {cloudDisplay[1]}
                  </span>
                </CloudValue>
              </ApplicationValue>
            </Col>

            <Col className="d-none d-sm-none d-md-flex d-xl-flex">
              <ApplicationValue>
                <p style={{ fontWeight: 'normal' }}>
                  {database.disk_usage
                    ? kbToMb(database.disk_usage * 1024)
                    : '0 B'}
                </p>
              </ApplicationValue>
            </Col>

            {!showAccess && (
              <Col className="d-sm-flex d-md-none">
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <FontAwesomeIcon
                    icon={faCog}
                    style={{
                      color: '#64728C',
                    }}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      setShowAccess(true);
                    }}
                  />
                </div>
              </Col>
            )}

            <Col
              xl="2"
              lg="5"
              md="5"
              sm="8"
              xs="8"
              className={!showAccess && 'd-none d-md-flex'}
              onClick={e => {
                e.preventDefault();
                setShowAccess(false);
              }}
              style={{
                background: showAccess && theme.interface3,
                height: '100%',
                width: '100%',
                borderTopRightRadius: '5px',
                borderBottomRightRadius: '5px',
              }}
            >
              <AcessosList
                showAccess={showAccess}
                disable={database.is_disabled}
              >
                <Acesso
                  active
                  fixed={!!database.admin_url}
                  hover
                  onClick={() => !database.admin_url && toggleAdminModal(true)}
                >
                  {database.admin_url ? (
                    <FontAwesomeIcon
                      icon={faDatabase}
                      onClick={() => window.open(database?.admin_url?.url)}
                    />
                  ) : (
                    <FontAwesomeIcon icon={faDatabase} />
                  )}
                  <AcessoHover>
                    <Value>
                      <span>Database</span>
                    </Value>
                  </AcessoHover>
                </Acesso>
                <Acesso
                  active
                  hover
                  fixed={!!database?.shared_users?.length}
                  onClick={e => openModal(e, 'share', database)}
                >
                  <FontAwesomeIcon icon={faShareAlt} />
                  <AcessoHover>
                    <Value>
                      <span>Share</span>
                    </Value>
                  </AcessoHover>
                </Acesso>
                <Acesso active fixed={database.is_pinned} hover favorite>
                  <FontAwesomeIcon
                    onClick={pinDatabase}
                    icon={loading ? faSpinner : faStar}
                    spin={loading}
                  />
                  <AcessoHover>
                    <Value>
                      <span>Favorite</span>
                    </Value>
                  </AcessoHover>
                </Acesso>
              </AcessosList>
            </Col>
          </Row>
        </Card>
      </Link>
    </ListingItem>
  );
};

export default DatabaseCard;
