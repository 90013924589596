import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronUp,
  faCircleCheck,
  faCircleXmark,
} from '@fortawesome/free-solid-svg-icons';

import { Modal, Button, VerticalTabs } from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from 'components/ListingModal/styles';

import { faWindowMaximize } from '@fortawesome/free-regular-svg-icons';

import wordpress from 'assets/img/svg/wordpress.svg';
import {
  TabTitle,
  TabContent,
} from 'pages/Migrations/MigrationDetail/Syncs/styles';
import { useLayout } from 'hooks/layout';

interface IOfflineModalProps {
  show: boolean;
  setShow: any;
}

interface SuggestProps {
  text?: boolean;
  type: string;
}

const Suggest = ({ type, text }: SuggestProps) => {
  const theme = useLayout();

  const style = {
    display: 'flex',
    FlexDirection: 'row',
    alignItems: 'center',
    gap: '8px',
  };

  const error = theme.errorNew;
  const success = theme.successNew;
  return type === 'error' ? (
    <div style={{ ...style, marginBottom: text ? '8px' : '0px' }}>
      <FontAwesomeIcon
        style={{ width: '16px', height: '16px', color: error }}
        icon={faCircleXmark}
      />
      {text && (
        <span style={{ color: error, fontWeight: 'bold' }}>
          Não recomendado
        </span>
      )}
    </div>
  ) : type === 'success' ? (
    <div style={{ ...style, marginBottom: text ? '8px' : '0px' }}>
      <FontAwesomeIcon
        style={{ width: '16px', height: '16px', color: success }}
        icon={faCircleCheck}
      />
      {text && (
        <span style={{ color: success, fontWeight: 'bold' }}>Recomendado</span>
      )}
    </div>
  ) : null;
};

const TitleContent = ({ children }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: '8px',
        alignItems: 'center',
      }}
    >
      {children}
    </div>
  );
};

interface TextContentProps {
  text: string;
  type: string;
}

const TextContent = ({ text, type }: TextContentProps) => (
  <div>
    <div>
      <Suggest text type={type} />
    </div>
    <span>{text}</span>
  </div>
);
const InfoModal: React.FC<IOfflineModalProps> = ({ show, setShow }) => {
  return (
    <Modal
      show={show}
      setShow={setShow}
      style={{
        maxWidth: '100%',
      }}
    >
      <Header>
        <Icon>
          <img
            style={{ width: '19px', height: '19px' }}
            src={wordpress}
            alt="Wordpress"
          />
        </Icon>
        <Text>
          <Title>Plugins WordPress</Title>
          <Subtitle>Veja nossas recomendações</Subtitle>
        </Text>
      </Header>

      <Content
        style={{
          maxHeight: 324,
          overflow: 'auto',
        }}
      >
        <VerticalTabs
          items={[
            {
              name: props => (
                <TabTitle {...props} first>
                  <TitleContent>
                    Wordfence
                    <Suggest type="success" />
                  </TitleContent>
                  <FontAwesomeIcon
                    icon={props.isOpen ? faChevronUp : faChevronDown}
                  />
                </TabTitle>
              ),
              content: props => (
                <TabContent {...props}>
                  <TextContent
                    type="success"
                    text="Um plugin de segurança compátivel com nossos sistemas e automações"
                  />
                </TabContent>
              ),
            },
            {
              name: props => (
                <TabTitle {...props} first>
                  <TitleContent>
                    Sucuri Security
                    <Suggest type="success" />
                  </TitleContent>
                  <FontAwesomeIcon
                    icon={props.isOpen ? faChevronUp : faChevronDown}
                  />
                </TabTitle>
              ),
              content: props => (
                <TabContent {...props}>
                  <TextContent
                    type="success"
                    text="Um plugin de segurança compátivel com nossos sistemas e automações"
                  />
                </TabContent>
              ),
            },
            {
              name: props => (
                <TabTitle {...props} first>
                  <TitleContent>
                    W3 - Total Cache
                    <Suggest type="success" />
                  </TitleContent>
                  <FontAwesomeIcon
                    icon={props.isOpen ? faChevronUp : faChevronDown}
                  />
                </TabTitle>
              ),
              content: props => (
                <TabContent {...props}>
                  <TextContent
                    type="success"
                    text="Um plugin de cache compátivel com nossos sistemas e automações"
                  />
                </TabContent>
              ),
            },
            {
              name: props => (
                <TabTitle {...props} first>
                  <TitleContent>
                    Autoptimize
                    <Suggest type="success" />
                  </TitleContent>
                  <FontAwesomeIcon
                    icon={props.isOpen ? faChevronUp : faChevronDown}
                  />
                </TabTitle>
              ),
              content: props => (
                <TabContent {...props}>
                  <TextContent
                    type="success"
                    text="Um plugin de otimização compátivel com nossos sistemas e automações"
                  />
                </TabContent>
              ),
            },
            {
              name: props => (
                <TabTitle {...props} first>
                  <TitleContent>
                    WebP Express
                    <Suggest type="success" />
                  </TitleContent>
                  <FontAwesomeIcon
                    icon={props.isOpen ? faChevronUp : faChevronDown}
                  />
                </TabTitle>
              ),
              content: props => (
                <TabContent {...props}>
                  <TextContent
                    type="success"
                    text="Um plugin de otimização compátivel com nossos sistemas e automações"
                  />
                </TabContent>
              ),
            },
            {
              name: props => (
                <TabTitle {...props} first>
                  <TitleContent>
                    EWWW Image Optimizer
                    <Suggest type="success" />
                  </TitleContent>
                  <FontAwesomeIcon
                    icon={props.isOpen ? faChevronUp : faChevronDown}
                  />
                </TabTitle>
              ),
              content: props => (
                <TabContent {...props}>
                  <TextContent
                    type="success"
                    text="Um plugin de otimização compátivel com nossos sistemas e automações"
                  />
                </TabContent>
              ),
            },
            {
              name: props => (
                <TabTitle {...props} first>
                  <TitleContent>
                    Short Pixel
                    <Suggest type="success" />
                  </TitleContent>
                  <FontAwesomeIcon
                    icon={props.isOpen ? faChevronUp : faChevronDown}
                  />
                </TabTitle>
              ),
              content: props => (
                <TabContent {...props}>
                  <TextContent
                    type="success"
                    text="Um plugin de compressão de imagem que reduz tamanho do arquivo e mantém boa qualidade visual"
                  />
                </TabContent>
              ),
            },
            {
              name: props => (
                <TabTitle {...props} first>
                  <TitleContent>
                    Really Simple SSL
                    <Suggest type="error" />
                  </TitleContent>
                  <FontAwesomeIcon
                    icon={props.isOpen ? faChevronUp : faChevronDown}
                  />
                </TabTitle>
              ),
              content: props => (
                <TabContent {...props}>
                  <TextContent
                    type="error"
                    text="Este plugin não é necessário pois você já possui uma função de forçar o https no seu painel"
                  />
                </TabContent>
              ),
            },
            {
              name: props => (
                <TabTitle {...props} first>
                  <TitleContent>
                    WP Rocket
                    <Suggest type="error" />
                  </TitleContent>
                  <FontAwesomeIcon
                    icon={props.isOpen ? faChevronUp : faChevronDown}
                  />
                </TabTitle>
              ),
              content: props => (
                <TabContent {...props}>
                  <TextContent
                    type="error"
                    text="Possui muitas incompatibilidades já registradas com as otimizações do nosso sistema"
                  />
                </TabContent>
              ),
            },
            {
              name: props => (
                <TabTitle {...props} first>
                  <TitleContent>
                    Redirection
                    <Suggest type="error" />
                  </TitleContent>
                  <FontAwesomeIcon
                    icon={props.isOpen ? faChevronUp : faChevronDown}
                  />
                </TabTitle>
              ),
              content: props => (
                <TabContent {...props}>
                  <TextContent
                    type="error"
                    text="Possui muitas incompatibilidades já registradas com as configurações dos clouds"
                  />
                </TabContent>
              ),
            },
            {
              name: props => (
                <TabTitle {...props} first>
                  <TitleContent>
                    Elementskit-lite
                    <Suggest type="error" />
                  </TitleContent>
                  <FontAwesomeIcon
                    icon={props.isOpen ? faChevronUp : faChevronDown}
                  />
                </TabTitle>
              ),
              content: props => (
                <TabContent {...props}>
                  <TextContent
                    type="error"
                    text="Esse plugin gera inúmeros problemas de desempenho na aplicação"
                  />
                </TabContent>
              ),
            },
            {
              name: props => (
                <TabTitle {...props} first>
                  <TitleContent>
                    Essential-addons-elementor
                    <Suggest type="error" />
                  </TitleContent>
                  <FontAwesomeIcon
                    icon={props.isOpen ? faChevronUp : faChevronDown}
                  />
                </TabTitle>
              ),
              content: props => (
                <TabContent {...props}>
                  <TextContent
                    type="error"
                    text="Esse plugin gera inúmeros problemas de desempenho na aplicação"
                  />
                </TabContent>
              ),
            },
            {
              name: props => (
                <TabTitle {...props} first>
                  <TitleContent>
                    Essential-addons-for-elementor-lite
                    <Suggest type="error" />
                  </TitleContent>
                  <FontAwesomeIcon
                    icon={props.isOpen ? faChevronUp : faChevronDown}
                  />
                </TabTitle>
              ),
              content: props => (
                <TabContent {...props}>
                  <TextContent
                    type="error"
                    text="Esse plugin gera inúmeros problemas de desempenho na aplicação"
                  />
                </TabContent>
              ),
            },
          ]}
        />
      </Content>
      <Footer>
        <Button
          onClick={() => {
            setShow();
          }}
        >
          Fechar
        </Button>
      </Footer>
    </Modal>
  );
};

export default InfoModal;
