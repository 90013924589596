import React, { useMemo, useContext, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

import { Link } from 'react-router-dom';
import NodeappField from 'components/NodeappField';
import { Separator, Field, Value } from 'components/Details';
import { ThemeContext } from 'styled-components';
import { useAuth } from 'hooks/auth';
import { NodeAppType } from 'types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Label } from '@cloudez/cloudez-design-system';
import { WebsiteDetailContext } from '../_context/state';
import InfoModal from './InfoModal';

interface IConfiguracoesGeraisProps {
  type: NodeAppType;
  onChange(e: Event): any;
}

const ConfiguracoesGerais: React.FC<IConfiguracoesGeraisProps> = ({
  type,
  onChange,
}) => {
  const { website } = useContext(WebsiteDetailContext);
  const { user } = useAuth();
  const theme = useContext(ThemeContext);

  const [showInfoModal, setShowInfoModal] = useState(false);

  const { fieldsLeft, fieldsRight } = useMemo(() => {
    return {
      fieldsLeft: ['app_root_path', 'password_protected', 'php_filemanager'],
      fieldsRight: ['http_x_xss_protection', 'http_x_frame_options'],
    };
  }, []);

  return (
    <>
      {showInfoModal && (
        <InfoModal show={showInfoModal} setShow={setShowInfoModal} />
      )}
      <Row>
        <Col xl>
          {website.values.map((v, i) => {
            if (fieldsLeft.includes(v.slug)) {
              const [field] = type.values.filter(_v => v.slug === _v.slug);

              if (field) {
                if (v.slug === 'php_filemanager' && v.value === 'true') {
                  return (
                    <div>
                      <NodeappField
                        key={v.slug}
                        field={field}
                        value={v.value}
                        onChange={onChange}
                      />
                      <Value style={{ marginBottom: '1.0rem' }}>
                        <Label
                          style={{
                            fontWeight: 'normal',
                            fontSize: '12px',
                            marginBottom: '0',
                          }}
                        >
                          Endereço do Gerenciador de arquivos
                        </Label>
                        <a
                          href={`http://${website.temporary_address}/cezfm`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {`${website.temporary_address}/cezfm`}
                        </a>
                      </Value>
                    </div>
                  );
                }

                return (
                  <NodeappField
                    key={v.slug}
                    field={field}
                    value={v.value}
                    onChange={onChange}
                  />
                );
              }
            }
            return null;
          })}
        </Col>

        <Separator />
        <Col xl>
          {website.values.map((v, i) => {
            if (fieldsRight.includes(v.slug)) {
              const [field] = type.values.filter(_v => v.slug === _v.slug);
              return (
                field && (
                  <NodeappField
                    key={v.slug}
                    field={field}
                    value={v.value}
                    onChange={onChange}
                  />
                )
              );
            }
            return null;
          })}
        </Col>
      </Row>
    </>
  );
};

export default ConfiguracoesGerais;
