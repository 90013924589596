import React, { useCallback, useEffect, useRef } from 'react';
import Slider from 'react-slick';

import Placeholder from 'components/Placeholder';
import CurrencyFormat from 'react-currency-format';
import { Wrapper, PlanCard } from './styles';

const Plan = ({
  selectedPlan,
  setSelectedPlan,
  plans,
  lifespanMonths,
  loading,
  getAnnual,
  tab,
}) => {
  const slider = useRef();
  const planList = [];
  useEffect(() => {
    const slickListDiv = document.getElementsByClassName('slick-list')[0];
    slickListDiv.addEventListener('wheel', event => {
      event.preventDefault();
      event.deltaY > 0
        ? slider.current.slickNext()
        : slider.current.slickPrev();
    });
  }, []);

  const settings = {
    className: 'mb-5',
    centerMode: plans.length > 1,
    centerPadding: '40px',
    speed: 300,
    arrows: false,
    slidesToShow: plans.length > 1 ? 2.2 : 1,
    slidesToScroll: 1,
    dots: true,
  };

  const placeholders = [];

  for (let i = 1; i <= 5; i++) {
    placeholders.push(i);
  }

  return (
    <Wrapper>
      <Slider {...settings} ref={slider}>
        {loading
          ? placeholders.map((p, i) => (
              <div key={i}>
                <PlanCard>
                  <Placeholder width={28} height={12} />
                  <Placeholder width={80} height={60} />
                  <div>
                    <Placeholder width={100} height={12} />
                    <Placeholder width={100} height={12} />
                    <Placeholder width={100} height={12} />
                  </div>
                  <div>
                    <span>
                      <Placeholder width={40} height={12} />
                    </span>
                    <span>
                      <Placeholder width={40} height={12} />
                    </span>
                    <span>
                      <Placeholder width={40} height={12} />
                    </span>
                  </div>
                </PlanCard>
              </div>
            ))
          : plans?.map((p, index) => {
              if (planList.includes(p)) {
                return;
              }
              planList.push(p);
              const description = (() => {
                if (p?.description && p?.description !== 'False') {
                  return p?.description;
                }

                if (p?.cloud_size?.info && p?.cloud_size?.info['tip_pt-br']) {
                  return p?.cloud_size?.info['tip_pt-br'];
                }

                return '';
              })();

              return (
                <div key={p?.id}>
                  <PlanCard
                    selected={p?.id === selectedPlan?.id}
                    onClick={() => setSelectedPlan(p)}
                  >
                    <div className="top">
                      <div className="plan-title">
                        {p?.name}
                        <span>{p?.cloud_size?.info?.memory} RAM</span>
                      </div>

                      <span className="tip">{description}</span>

                      <div className="price">
                        <span>{lifespanMonths === 1 ? 'Mensal' : 'Anual'}</span>
                        <CurrencyFormat
                          style={{ fontSize: '30px' }}
                          value={
                            lifespanMonths === 1
                              ? tab === 2
                                ? p?.turbo_price_total?.amount
                                : p?.price_total?.amount
                              : getAnnual(
                                  tab === 2
                                    ? p?.turbo_price_total?.amount
                                    : p?.price_total?.amount,
                                )
                          }
                          displayType="text"
                          fixedDecimalScale
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          prefix={
                            tab === 2
                              ? p?.turbo_price_total?.currency === 'BRL'
                                ? 'R$ '
                                : '$'
                              : p?.price_total?.currency === 'BRL'
                              ? 'R$ '
                              : '$'
                          }
                        />
                      </div>
                    </div>

                    <div className="separator" />
                    <div className="bottom">
                      <ul>
                        <li>DISCO</li>
                        <li className="value">{p?.cloud_size?.disk}</li>
                        <li>PROCESSADOR</li>
                        <li className="value">
                          {p?.cloud_size?.cores} CPU core
                        </li>
                      </ul>
                    </div>
                  </PlanCard>
                </div>
              );
            })}
      </Slider>
    </Wrapper>
  );
};

export default Plan;
