import React from 'react';
import { Button } from '@cloudez/cloudez-design-system';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons';
import badgeMig from 'assets/img/svg/badge_mig.svg';
import badgeHour from 'assets/img/svg/badge_hour.svg';
import badgeApp from 'assets/img/svg/badge_app.svg';
import badgeDomain from 'assets/img/svg/badge_domain.svg';
import { Header } from 'pages/Auth/SignUp/styles';
import Progress from 'components/Progress';
import {
  Buttons,
  ContentWrapper,
  Title,
  Subtitle,
  InfoBox,
  Text,
  Badges,
  Infos,
} from './styles';

const Item = ({ children, last }) => (
  <div className={`item ${last ? 'last' : ''}`}>
    <span>{children}</span> <FontAwesomeIcon icon={faCheck} />
  </div>
);

const FreeTrial = ({ startFreeTrial, skipFreeTrial, loading, ...rest }) => {
  return (
    <>
      <Header>
        <Progress status={3} n={3} />
      </Header>
      <ContentWrapper {...rest}>
        <Title>Teste grátis por até 7 dias</Title>
        <Subtitle>
          Ao ativar seu teste grátis: você ganha 24h para criar sua aplicação
        </Subtitle>

        <Infos>
          <InfoBox left>
            <div className="full">
              <p>Plano Trial (Cloud Turbo)</p>
              <p className="description">
                Hospede diversas aplicações com limite recomendado de
                aproximadamente <b>100.000 visualizações mensais.</b>
              </p>
            </div>
          </InfoBox>

          <InfoBox>
            <div className="column">
              <Item>GoCache CDN</Item>
              <Item>Anti-spam</Item>
              <Item last>Anti-blocklist</Item>
            </div>
            <div className="column">
              <Item>PageSpeed</Item>
              <Item>LiteSpeed</Item>
              <Item last>SSL/HTTPS</Item>
            </div>
          </InfoBox>
        </Infos>

        <Text>
          A cada passo que você configura de forma simples, mais dias você ganha
          para utilizar as funcionalidades da nossa plataforma.
        </Text>

        <Badges>
          <div className="line" />
          <div>
            <span>Iniciar o Teste Grátis</span>
            <img src={badgeHour} alt="icon" />
            <span style={{ opacity: 0 }}>a</span>
          </div>
          <div>
            <span>Criação de Aplicação</span>
            <img src={badgeApp} alt="icon" />
            <span>+2 Dias</span>
          </div>
          <div>
            <span>Criação de Domínio</span>
            <img src={badgeDomain} alt="icon" />
            <span>+2 Dias</span>
          </div>
          <div>
            <span>Criação de Cópia</span>
            <img src={badgeMig} alt="icon" />
            <span>+2 Dias</span>
          </div>
        </Badges>

        <Buttons>
          <Button
            onClick={startFreeTrial}
            width="100%"
            height="44px"
            icon
            disabled={loading}
          >
            {loading ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              'INICIAR O TESTE GRÁTIS'
            )}
          </Button>
          <Button
            onClick={skipFreeTrial}
            width="100%"
            height="44px"
            outline
            disabled={loading}
            style={{
              border: 0,
            }}
          >
            JA CONHEÇO O PAINEL E QUERO COMPRAR
          </Button>
        </Buttons>
      </ContentWrapper>
    </>
  );
};

export default FreeTrial;
