import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faSpinner } from '@fortawesome/free-solid-svg-icons';

import { Modal, Button } from '@cloudez/cloudez-design-system';

import { CustomInput } from 'components/NewComponents';
import {
  Header,
  Icon,
  Text,
  Title,
  Content,
  Footer,
} from 'components/ListingModal/styles';

import history from 'services/history';
import { useLayout } from 'hooks/layout';
import { HttpsText } from './styles';

const SuccessModal = ({ show, setShow, id }) => {
  const theme = useLayout();

  return (
    <Modal width="455px" show={show} setShow={setShow}>
      <Header>
        <Icon color={theme.successNew}>
          <FontAwesomeIcon icon={faCheck} />
        </Icon>
        <Text>
          <Title>Ticket aberto com sucesso</Title>
        </Text>
      </Header>

      <Content>
        <HttpsText>
          Obrigado! Recebemos sua mensagem. Agora avaliaremos a sua necessidade
          e retornaremos em breve para te ajudar.
        </HttpsText>
      </Content>
      <Footer>
        <Button
          width="149px"
          icon
          onClick={() => {
            history.push(`/suporte/${id}`);
          }}
        >
          OK
        </Button>
      </Footer>
    </Modal>
  );
};

export default SuccessModal;
