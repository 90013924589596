import React, { useMemo, useContext } from 'react';
import Slider from 'react-slick';

import { Row, Col } from 'react-bootstrap';

import { Arrows } from '@cloudez/cloudez-design-system';
import Placeholder from 'components/Placeholder';
import {
  faChevronRight,
  faChevronLeft,
  faUserSecret,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CurrencyFormat from 'react-currency-format';
import EmptyListing from 'components/EmptyListing';
import { CloudCreateContext } from '../_context/state';
import { Wrapper, PlanCard, Price, InfoCard, Private } from './styles';

const Plan = () => {
  const { plan, updatePlan, plans, basePlans, loading } =
    useContext(CloudCreateContext);

  function NextArrow(props) {
    const { onClick } = props;
    return (
      <Arrows
        right="true"
        onClick={onClick}
        icon={faChevronRight}
        disabled={props.className.includes('slick-disabled')}
      />
    );
  }

  function PrevArrow(props) {
    const { onClick } = props;

    return (
      <Arrows
        left="true"
        onClick={onClick}
        icon={faChevronLeft}
        disabled={props.className.includes('slick-disabled')}
      />
    );
  }

  const settings = useMemo(
    () => ({
      // centerMode: true,
      infinite: false,
      focusOnSelect: true,
      speed: 300,
      slidesToShow: 3,
      nextArrow: <NextArrow />,
      prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    }),
    [],
  );

  const placeholders = [];

  for (let i = 1; i <= 5; i++) {
    placeholders.push(i);
  }

  return (
    <Wrapper>
      <Row>
        <Col
          xl="9"
          style={{
            marginBottom: '25px',
          }}
        >
          <Slider {...settings}>
            {!loading ? (
              plans?.results?.length > 0 ? (
                plans?.results?.map(p => {
                  const [basePlan] = basePlans.filter(
                    b => p.cloud_size === b.id,
                  );

                  return (
                    <div key={p.id}>
                      <PlanCard
                        private={p.is_private}
                        selected={p.id === plan?.id}
                        onClick={() => updatePlan(p)}
                      >
                        {p.is_private && (
                          <Private>
                            <FontAwesomeIcon icon={faUserSecret} />
                          </Private>
                        )}
                        <Price>
                          <h3>{p.name}</h3>

                          <span>
                            <CurrencyFormat
                              style={{ fontSize: '30px' }}
                              value={p.price_total?.amount}
                              displayType="text"
                              fixedDecimalScale
                              decimalScale={2}
                              decimalSeparator=","
                              thousandSeparator="."
                              prefix={
                                p.price_total?.currency === 'BRL' ? 'R$ ' : '$'
                              }
                            />
                            <p>mensal</p>
                          </span>
                        </Price>
                        {basePlan && (
                          <>
                            <span>{basePlan?.info['tip_pt-br']}</span>
                            <ul>
                              <li>{basePlan?.info.memory} RAM</li>
                              <li>{basePlan?.info.cores} CPU core</li>
                              <li>{basePlan?.info.disk}</li>
                            </ul>
                          </>
                        )}
                      </PlanCard>
                    </div>
                  );
                })
              ) : (
                <EmptyListing>
                  <p>Nenhum plano encontrado.</p>
                </EmptyListing>
              )
            ) : (
              placeholders.map((p, i) => (
                <div key={i}>
                  <PlanCard>
                    <Placeholder width={28} height={12} />
                    <Placeholder width={80} height={60} />
                    <div>
                      <Placeholder width={100} height={12} />
                      <Placeholder width={100} height={12} />
                      <Placeholder width={100} height={12} />
                    </div>
                    <div>
                      <span>
                        <Placeholder width={40} height={12} />
                      </span>
                      <span>
                        <Placeholder width={40} height={12} />
                      </span>
                      <span>
                        <Placeholder width={40} height={12} />
                      </span>
                    </div>
                  </PlanCard>
                </div>
              ))
            )}
          </Slider>
        </Col>
        <Col
          xl="3"
          style={{
            marginBottom: '25px',
          }}
        >
          <InfoCard>
            <span>TODOS OS PACOTES INCLUEM</span>
            <ul>
              <li>CDN + Cache Premium</li>
              <li>HTTPS gratuito</li>
              <li>Migração automática</li>
              <li>Prevenção de blocklists</li>
              <li>Backup automático</li>
              <li>Mail gateway gratuito</li>
            </ul>
          </InfoCard>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Plan;
