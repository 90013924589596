import styled from 'styled-components';

interface HostedProps {
  active?: boolean;
}

export const Hosted = styled.div<HostedProps>`
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: ${props => props.theme.successNew};
  position: absolute;
  bottom: -14px;
  left: -4px;
`;
