/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState } from 'react';
import { withTheme } from 'styled-components';
import * as Yup from 'yup';
import { Form } from '@rocketseat/unform';
import queryString from 'query-string';

import { Link, useLocation, useHistory } from 'react-router-dom';
import {
  Button,
  FormInput,
  Label,
  Field,
} from '@cloudez/cloudez-design-system';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleUser,
  faEye,
  faEyeSlash,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { useSpring, animated } from 'react-spring';
import { useDocTitle } from 'hooks/docTitle';
import { useLayout } from 'hooks/layout';
import { useAuth } from 'hooks/auth';
import ReactLoading from 'react-loading';
import { CustomInput } from 'components/NewComponents';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';

import { Content, Wrapper } from '../styles';

const schema = Yup.object().shape({
  email: Yup.string().required('O email é obrigatório.'),
  password: Yup.string().required('A senha é obrigatória.'),
});

const SignIn = () => {
  const location = useLocation();
  const [params, setParams] = useState({});
  const theme = useLayout();
  const { signIn, signInError, loading } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const history = useHistory();

  useDocTitle(`Login — ${theme.name}`);

  useEffect(() => {
    const _params = queryString.parse(location.search);
    const newParams = {} as any;

    for (const key in _params) {
      if (key.startsWith('utm_')) {
        newParams[key] = _params[key];
      }
    }

    const utmReferrer = document.referrer;
    if (!newParams.utm_referrer && utmReferrer) {
      newParams.utm_referrer = utmReferrer;
    }

    if (_params.reseller === 'true') {
      newParams.reseller = 'true';
    }

    setParams(newParams);
  }, []);

  const onSubmit = data => {
    signIn(data.email, data.password, params);
  };

  useEffect(() => {
    if (
      signInError === 'User has 2FA enabled' ||
      signInError === 'Verification code sent in email'
    ) {
      history.push('login/2fa');
    }
  }, [signInError]);

  const props1 = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 1000 },
  });

  const props2 = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { duration: 1000 },
  });

  const [showPw, setShowPw] = useState(false);

  return (
    <Wrapper>
      <Content>
        {theme.loading ? (
          <animated.div style={props2}>
            <ReactLoading type="balls" />
          </animated.div>
        ) : (
          <animated.div style={props1}>
            <img src={theme.logo_primary} alt="logo" />
            <form onSubmit={handleSubmit(onSubmit)}>
              <div style={{ textAlign: 'center', color: '#a2a9bf' }}>
                <FontAwesomeIcon icon={faCircleUser} size="2x" />
                <h2>LOGIN</h2>
                <br />
              </div>
              <CustomInput
                mb="20px"
                autoFocus
                block
                name="email"
                label="E-mail:"
                register={register}
                error={errors?.email?.message}
                placeholder="E-mail ou nome de usuário"
                height="40px"
              />
              <CustomInput
                mb="20px"
                block
                name="password"
                register={register}
                error={errors?.password?.message}
                type={showPw ? 'text' : 'password'}
                icon={{
                  svg: !showPw ? faEye : faEyeSlash,
                  action: () => setShowPw(!showPw),
                  isRight: true,
                }}
                label="Senha:"
                placeholder="Senha"
                height="40px"
              />
              <Button
                disabled={loading}
                icon
                width="100%"
                height="50px"
                type="submit"
              >
                {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Entrar'}
              </Button>

              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  height: '100%',
                  alignItems: 'center',
                  fontSize: '12px',
                }}
              >
                {!theme.has_disabled_register && (
                  <p>
                    <Link to="/register">QUERO CRIAR MINHA CONTA</Link>
                  </p>
                )}
                <p>
                  <Link to="/password/reset">NÃO LEMBRO MINHA SENHA</Link>
                </p>
                <p>
                  <Link to="/invoices/resend">SOLICITAR 2º VIA DE FATURAS</Link>
                </p>
              </div>
            </form>
          </animated.div>
        )}
      </Content>
    </Wrapper>
  );
};

export default withTheme(SignIn);
