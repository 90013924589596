import styled, { css } from 'styled-components';

interface SelectProps {
  width?: string;
  height?: string;
  background?: boolean;
  block?: boolean | string;
}

export const Select = styled.div<SelectProps>`
  display: flex;
  flex-direction: column;

  width: ${props =>
    props.width ? props.width : props.block ? '100%' : '255px'};

  select {
    width: ${props => props.width || '100%'};
    height: ${props => props.height || '40px'};
    padding: 6px 10px;

    background: ${props => props.theme.interface1};
    border-radius: 5px;
    outline: 0;
    border: 1px solid
      ${props =>
        props.theme.darkMode ? props.theme.interface2 : props.theme.interface3};
    color: ${props =>
      props.theme.darkMode ? props.theme.interface4 : props.theme.interface5};

    ${props =>
      props.background &&
      css`
        background-color: ${props => props.theme.interface2};
      `}

    &::placeholder {
      color: ${props => props.theme.interface4};
    }

    &:disabled {
      opacity: 1;
    }

    &:focus {
      transition: border 0.2s ease-in-out;
      border: 2px solid ${props => props.theme.brand_primary_color};
    }

    &:-moz-focusring {
      color: transparent;
      text-shadow: 0 0 0 #000;
    }

    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }
`;
