import React, { useEffect, useState, useContext, useMemo } from 'react';

import { Row, Col } from 'react-bootstrap';
import { Arrows, Button } from '@cloudez/cloudez-design-system';
import Swipe from 'react-easy-swipe';

import ApplicationIcon from 'components/ApplicationIcon';

import { Header, Title, Tabs, Tab, TabContent } from 'components/Details';

import { Breadcrumbs, Breadcrumb } from 'components/Common';
import { Link } from 'react-router-dom';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getValue } from 'utils/getValue';
import { useDocTitle } from 'hooks/docTitle';
import RemoveModal from 'components/ListingModal/RemoveModal';
import {
  DatabaseDetailContext,
  DatabaseDetailProvider,
} from './_context/state';
import Placeholder from './Placeholder';
import Websites from './Websites';
import Usuarios from './Usuarios';
import { InfoBox } from './styles';

const DatabaseDetail = ({ match }) => {
  const { database, getDatabase, deleteDatabase } = useContext(
    DatabaseDetailContext,
  );

  const [tab, setTab] = useState(1);
  // eslint-disable-next-line
  const [modal, showModal] = useState(false);

  useEffect(() => {
    const { id } = match.params;
    getDatabase(id);

    // eslint-disable-next-line
  }, []);

  const _deleteDatabase = async () => {
    await deleteDatabase(database?.id);
  };

  const name = useMemo(
    () => database && getValue(database, 'database_name'),
    [database],
  );

  useDocTitle(name ? `${name} — Database` : 'Database — Cloudez');

  return database ? (
    <>
      {modal && (
        <RemoveModal
          typeName="database"
          action={_deleteDatabase}
          show={modal}
          setShow={() => showModal(false)}
        />
      )}

      <Header>
        <Row>
          <Col md="10" sm="9">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ApplicationIcon
                width="64px"
                height="64px"
                icon={database.type.slug}
              />
              <Title>
                <Breadcrumbs>
                  <Breadcrumb>
                    <Link to="/dashboard">Home</Link>
                  </Breadcrumb>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <Breadcrumb>
                    <Link to="/databases">Databases</Link>
                  </Breadcrumb>
                </Breadcrumbs>
                <h3>{name}</h3>
                <p>{database.cloud_display}</p>
              </Title>
            </div>
          </Col>
          <Col
            className="d-none d-sm-flex d-md-flex d-xl-flex"
            sm="3"
            md="2"
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button block outline error remove onClick={() => showModal(true)}>
              Excluir database
            </Button>
          </Col>
        </Row>
      </Header>
      <InfoBox>
        <p>Informações</p>
        <div className="content">
          <div className="info">
            <span className="title">IP</span>
            <span>{database?.node_ip}</span>
          </div>
          <div className="info">
            <span className="title">ESPAÇO UTILIZADO</span>
            <span>{database?.disk_usage || '.'}</span>
          </div>
          <div className="info">
            <span className="title">SERVIDOR</span>
            <span>{database?.cloud_fqdn}</span>
          </div>
          <div className="info">
            <span className="title">TIPO DO BANCO DE DADOS/PORTA</span>
            <span>
              {`${database?.type?.name} / ${database?.database_port}`}
            </span>
          </div>
          <div className="info">
            <span className="title">GERENCIADOR DO BANCO DE DADOS</span>
            <a target="_blank" href={database?.admin_url?.url} rel="noreferrer">
              {database?.admin_url?.url}
            </a>
          </div>
          <div className="info">
            <span className="title">TOTAL DE SITES VINCULADOS</span>
            <span>{database?.websites?.length}</span>
          </div>
        </div>
      </InfoBox>

      <Row>
        <Col>
          <Tabs>
            <Tab active={tab === 1} onClick={() => setTab(1)}>
              Usuários
            </Tab>
            <Tab active={tab === 2} onClick={() => setTab(2)}>
              Websites
            </Tab>
          </Tabs>
          <Arrows
            disabled={tab === 1}
            icon={faChevronLeft}
            left
            onClick={() => tab > 1 && setTab(tab - 1)}
            actions
          />
          <Swipe
            onSwipeRight={() => tab > 1 && setTab(tab - 1)}
            onSwipeLeft={() => tab < 2 && setTab(tab + 1)}
            tolerance={100}
          >
            <TabContent>
              {(() => {
                switch (tab) {
                  case 1:
                    return <Usuarios />;
                  case 2:
                    return <Websites />;

                  default:
                    return null;
                }
              })()}
            </TabContent>
          </Swipe>
          <Arrows
            disabled={tab === 2}
            icon={faChevronRight}
            right
            onClick={() => tab < 2 && setTab(tab + 1)}
            actions
          />
          <Row
            style={{
              marginTop: '20px',
            }}
          >
            <Col xs="12" className="d-flex d-sm-none">
              <Button
                style={{
                  width: '100%',
                }}
                remove
                outline
                error
                onClick={() => showModal(true)}
              >
                Excluir database
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  ) : (
    <Placeholder />
  );
};

export default props => (
  <DatabaseDetailProvider>
    <DatabaseDetail {...props} />
  </DatabaseDetailProvider>
);
