import React, { useEffect, useState, useContext } from 'react';
import { AnimatedList } from 'react-animated-list';

import queryString from 'query-string';
import { ThemeContext } from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusSquare } from '@fortawesome/free-regular-svg-icons';
import {
  faWindowMaximize,
  faGlobeAmericas,
  faChevronRight,
  faEnvelope,
  faCloud,
  faGlobe,
} from '@fortawesome/free-solid-svg-icons';

import { ListingHeader, Listing } from 'components/Listing/styles';
import { Pagination, Button } from '@cloudez/cloudez-design-system';

import {
  Header,
  Title,
  Card,
  CardHeader,
  Informations,
  Information,
} from 'components/Details';

import SearchBar from 'components/SearchBar';
import { NoResults, Breadcrumbs, Breadcrumb } from 'components/Common';
import { AddCard, AddButton } from 'components/AddCard';

import { Link } from 'react-router-dom';
import kbToMb from 'utils/kbToMb';

import { getDomainRecordsService } from 'services/domain';
import { useDocTitle } from 'hooks/docTitle';
import RemoveModal from 'components/ListingModal/RemoveModal';
import DomainRecordCard from './DomainRecordCard';
import DomainRecordPlaceholder from './DomainRecordCard/Placeholder';
import { DomainDetailProvider, DomainDetailContext } from './_context/state';
import { DomainIcon } from '../DomainListing/styles';
import { OffBadge, StatusCard, Rtypes, CardContent } from './styles';
import Placeholder from './Placeholder';

let newId = 2800;

const DomainDetail = ({ match }) => {
  const theme = useContext(ThemeContext);
  const { domain, getDomain, deleteDomain } = useContext(DomainDetailContext);

  const [modal, setModal] = useState(false);
  const [rtype, setRtype] = useState('');
  const [records, setRecords] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [loading, setLoading] = useState(false);

  const [newRecords, setNewRecords] = useState([]);
  const [search, setSearch] = useState('');
  const [request, setRequest] = useState({} as any);
  const [page, setPage] = useState(1);

  useEffect(() => {
    const { id } = match.params;

    getDomain(id);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function getRecords() {
      setLoading(true);

      const { data } = await getDomainRecordsService({
        domain: domain?.id,
        rtype: rtype || null,
        search: search || null,
        page_size: 35,
        page,
      });

      data.next = data.next ? queryString.parseUrl(data.next).query.page : null;

      if (data.previous) {
        if (queryString.parseUrl(data.previous).query.page) {
          data.previous = queryString.parseUrl(data.previous).query.page;
        } else {
          data.previous = 1;
        }

        if (data.next) {
          data.current = data.next - 1;
        } else {
          data.current = Math.ceil(data.count / 35);
        }
      } else {
        data.current = 1;
        data.previous = null;
      }

      setRecords(data.results);
      setRequest(data);
      setLoading(false);
    }

    if (domain) getRecords();
  }, [domain, rtype, search, page]);

  const addNewRecord = () => {
    setIsAdding(true);
    newId += 1;
    setNewRecords(state => [
      ...state,
      {
        id: newId,
        domain: match.params?.id,
        rtype: 1,
        name: '',
        host: '',
        text: '',
      },
    ]);
  };

  const _deleteDomain = async () => {
    await deleteDomain(domain?.id);
  };

  useDocTitle(domain ? `${domain?.domain} — Domínio` : 'Domínio — Cloudez');

  return domain ? (
    <>
      {modal && (
        <RemoveModal
          typeName="domínio"
          action={_deleteDomain}
          show={modal}
          setShow={() => setModal(false)}
        />
      )}
      <Header>
        <Row>
          <Col md="10" sm="9">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <DomainIcon disable={!domain.is_active}>
                <FontAwesomeIcon icon={faGlobeAmericas} size="4x" />
              </DomainIcon>
              <Title>
                <Breadcrumbs>
                  <Breadcrumb>
                    <Link to="/dashboard">Home</Link>
                  </Breadcrumb>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <Breadcrumb>
                    <Link to="/domains">Domínios</Link>
                  </Breadcrumb>
                </Breadcrumbs>
                <h3>{domain.domain}</h3>
              </Title>
            </div>
          </Col>
          <Col
            className="d-none d-sm-flex d-md-flex d-xl-flex"
            sm="3"
            md="2"
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button remove outline error onClick={() => setModal(true)}>
              Excluir domínio
            </Button>
          </Col>
        </Row>
      </Header>

      <Row
        style={{
          marginBottom: `${15}px`,
        }}
      >
        <Col xl="7">
          <Row>
            <Col xl="12">
              <Card>
                <CardHeader>Informações</CardHeader>
                <Informations>
                  <Information>
                    <FontAwesomeIcon icon={faCloud} />
                    <span
                      style={{
                        fontSize: `${12}px`,
                        marginTop: `${10}px`,
                      }}
                    >
                      Clouds ligados
                    </span>
                    <span
                      style={{
                        fontSize: `${16}px`,
                        fontWeight: 'bold',
                      }}
                    >
                      {domain.total_linked_clouds}
                    </span>
                  </Information>
                  <Information>
                    <FontAwesomeIcon icon={faWindowMaximize} />
                    <span
                      style={{
                        fontSize: `${12}px`,
                        marginTop: `${10}px`,
                      }}
                    >
                      Websites ligados
                    </span>
                    <span
                      style={{
                        fontSize: `${16}px`,
                        fontWeight: 'bold',
                      }}
                    >
                      {domain.total_linked_websites}
                    </span>
                  </Information>
                  <Information>
                    <FontAwesomeIcon icon={faEnvelope} />
                    <span
                      style={{
                        fontSize: `${12}px`,
                        marginTop: `${10}px`,
                      }}
                    >
                      Emails ligados
                    </span>
                    <span
                      style={{
                        fontSize: `${16}px`,
                        fontWeight: 'bold',
                      }}
                    >
                      {domain.total_linked_emails}
                    </span>
                  </Information>
                  <Information>
                    <FontAwesomeIcon icon={faGlobe} />
                    <span
                      style={{
                        fontSize: `${12}px`,
                        marginTop: `${10}px`,
                      }}
                    >
                      Uso de CDN
                    </span>
                    <span
                      style={{
                        fontSize: `${16}px`,
                        fontWeight: 'bold',
                      }}
                    >
                      {kbToMb(domain.cdn_usage.usage_total * 1024) || 0}
                    </span>
                  </Information>
                </Informations>
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xl="5">
          <StatusCard>
            <CardHeader>
              Status do apontamento
              <OffBadge isActive={domain.is_active}>
                {domain.is_active ? 'ON' : 'OFF'}
              </OffBadge>
            </CardHeader>
            <CardContent>
              <p>
                Para automatizar aponte seu DNS para os seguintes nameservers
              </p>
              <ul>
                <li>ns1.cloudez.io</li>
                <li>ns2.cloudez.io</li>
                <li>ns3.cloudez.io</li>
                <li>ns4.cloudez.io</li>
                <li>ns5.cloudez.io</li>
              </ul>
            </CardContent>
          </StatusCard>
        </Col>
      </Row>

      <SearchBar
        callback={q => {
          setSearch(q);
        }}
        noResults={search && records && !records.length}
        value={search}
      />

      <Rtypes>
        <div>
          <Button size="sm" outline={rtype !== ''} onClick={() => setRtype('')}>
            TODOS
          </Button>
          <Button
            size="sm"
            outline={rtype !== '1'}
            onClick={() => setRtype('1')}
          >
            A
          </Button>

          <Button
            size="sm"
            outline={rtype !== '5'}
            onClick={() => setRtype('5')}
          >
            CNAME
          </Button>
          <Button
            size="sm"
            outline={rtype !== '12'}
            onClick={() => setRtype('12')}
          >
            PTR
          </Button>
          <Button
            size="sm"
            outline={rtype !== '15'}
            onClick={() => setRtype('15')}
          >
            MX
          </Button>
          <Button
            size="sm"
            outline={rtype !== '16'}
            onClick={() => setRtype('16')}
          >
            TXT
          </Button>
          <Button
            size="sm"
            outline={rtype !== '28'}
            onClick={() => setRtype('28')}
          >
            AAAA
          </Button>
          <Button
            size="sm"
            outline={rtype !== '33'}
            onClick={() => setRtype('33')}
          >
            SRV
          </Button>
        </div>
      </Rtypes>

      <Row
        style={{
          marginTop: '20px',
        }}
      >
        <Col>
          <ListingHeader>
            <Row>
              <Col sm="2">
                <p>Tipo</p>
              </Col>
              <Col sm="3">
                <p>Nome</p>
              </Col>
              <Col sm="6">
                <p>Conteúdo</p>
              </Col>

              <Col xl="1" />
            </Row>
          </ListingHeader>

          <Listing>
            <AddCard
              width="100%"
              height="45px"
              onClick={addNewRecord}
              style={{
                marginBottom: '10px',
              }}
            >
              <AddButton>
                <FontAwesomeIcon
                  icon={faPlusSquare}
                  color={theme.brand_primary_color}
                />
                <span>criar registro</span>
              </AddButton>
            </AddCard>
            {isAdding && newRecords.length > 0 && (
              <>
                {newRecords.map((newRecord, index) => {
                  return (
                    <DomainRecordCard
                      records={records}
                      record={newRecord}
                      key={index}
                      isAdding={isAdding}
                      setIsAdding={setIsAdding}
                      newRecord={newRecord}
                      setRecords={setRecords}
                      setNewRecord={value => {
                        setNewRecords(state => {
                          const newState = [...state];
                          newState[index] = value;

                          return newState.filter(record => record);
                        });
                      }}
                    />
                  );
                })}
              </>
            )}
            {loading ? (
              <DomainRecordPlaceholder />
            ) : records ? (
              records.length ? (
                <>
                  <AnimatedList initialAnimationDuration={800} animation="grow">
                    {records.map(rcrd => {
                      return (
                        <DomainRecordCard
                          record={rcrd}
                          records={records}
                          key={rcrd?.id}
                          isAdding={isAdding}
                          setIsAdding={setIsAdding}
                          newRecord={{}}
                          setRecords={setRecords}
                          setNewRecord={setNewRecords}
                        />
                      );
                    })}
                  </AnimatedList>
                  <Pagination
                    onChangePage={newPage => setPage(newPage)}
                    count={request.count}
                    previous={request.previous}
                    next={request.next}
                    current={request.current}
                    search={search}
                    pageSize={35}
                  />
                </>
              ) : (
                <NoResults>Nenhum registro desse tipo</NoResults>
              )
            ) : null}
          </Listing>
          <Row
            style={{
              marginTop: '20px',
            }}
          >
            <Col xs="12" className="d-flex d-sm-none">
              <Button
                style={{
                  width: '100%',
                }}
                remove
                outline
                error
                onClick={() => setModal(true)}
              >
                Excluir domínio
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  ) : (
    <Placeholder />
  );
};

export default props => (
  <DomainDetailProvider>
    <DomainDetail {...props} />
  </DomainDetailProvider>
);
