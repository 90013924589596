import React, { createContext, useContext, useState } from 'react';

const MigDetailContext = createContext({} as any);

export function MigDetailProvider({ children }) {
  const [step, setStep] = useState(1);

  return (
    <MigDetailContext.Provider
      value={{
        step,
        setStep,
      }}
    >
      {children}
    </MigDetailContext.Provider>
  );
}

export const useMigDetail = () => useContext(MigDetailContext);
