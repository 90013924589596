import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  width: 70%;

  background: ${props => props.theme.interface1};

  box-shadow: 0px 2px 15px rgba(100, 114, 140, 0.25);
  border-radius: 5px;

  padding: 32px;

  gap: 24px;

  .title {
    font-weight: bold;
    font-size: 16px;

    line-height: 25px;

    color: ${props => props.theme.brand_primary_color};

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 9px;
  }

  .subtitle {
    font-weight: bold;
    font-size: 16px;

    color: ${props => props.theme.interface5};
  }

  .originData {
    display: flex;
    flex-direction: column;
    gap: 15px;

    display: flex;
    flex-direction: column;

    gap: 24px;

    p {
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: ${props => props.theme.brand_primary_color};
    }

    label,
    span {
      display: block;
      font-weight: bold;
      font-size: 16px;

      color: ${props => props.theme.interface5};
    }

    span {
      font-weight: normal;
    }
  }

  .users {
    display: flex;
    flex-direction: column;
    gap: 13px;

    p {
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: ${props => props.theme.brand_primary_color};
    }

    table {
      border-collapse: separate;
      border-spacing: 0 10px;
      width: 100%;
      text-align: left;

      color: ${props => props.theme.interface5};

      td {
        border: 1px solid #e8eaef;
        border-style: solid none;
        padding: 10px;
        background-color: transparent;

        svg {
          cursor: pointer;

          width: 1.25em;

          transition: 0.2s;

          transition: filter 0.4s;

          &:hover {
            filter: brightness(0.5);
          }
        }
      }
      td:first-child {
        border-left-style: solid;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;

        padding-left: 10px;
      }
      td:last-child {
        border-right-style: solid;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
      }
    }
  }

  .visualizeAll {
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: normal;
    font-size: 16px;

    text-decoration: underline;

    color: ${props => props.theme.brand_primary_color};

    span {
      cursor: pointer;
    }
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
