import styled from 'styled-components';

export const Content = styled.div`
  padding: 17px 32px;
  width: 100%;

  font-size: 14px;

  .text {
    font-size: 15px;
    font-weight: 300;
    color: ${props =>
      props.theme.darkMode ? props.theme.interface4 : props.theme.interface7};
  }

  .options {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: center;

    span {
      font-size: 14px;
      color: ${props =>
        props.theme.darkMode ? props.theme.interface4 : props.theme.interface7};
    }
  }

  .titlesClient {
    font-size: 14px;
    color: ${props =>
      props.theme.darkMode ? props.theme.interface4 : props.theme.interface7};
  }

  .textAreaBox {
    display: flex;
    flex-direction: column;
    gap: 5px;
    font-size: 12px;
    color: ${props => props.theme.errorNew};

    font-weight: normal;
  }

  .titles {
    font-size: 16px;
    font-weight: bold;

    color: ${props =>
      props.theme.darkMode ? props.theme.interface4 : props.theme.interface7};
  }

  .successText {
    font-size: 16px;
    color: ${props =>
      props.theme.darkMode ? props.theme.interface5 : props.theme.interface7};

    b {
      font-size: 16px;
      color: ${props =>
        props.theme.darkMode ? props.theme.interface5 : props.theme.interface7};
    }

    span {
      color: ${props => props.theme.brand_primary_color};
      font-size: 16px;
    }
  }

  .estrategical {
    margin-top: 16px;
    gap: 16px;
    display: flex;
    flex-direction: column;
    font-weight: 300;

    div {
      display: flex;
      flex-direction: column;
      gap: 16px;

      p {
        font-weight: bold;
        color: ${props =>
          props.theme.darkMode
            ? props.theme.interface4
            : props.theme.interface7};
      }
    }
  }

  .client {
    margin-top: 16px;
    gap: 16px;
    display: flex;
    flex-direction: column;
    font-weight: 300;

    .radioBox {
      display: flex;
      flex-direction: column;
      gap: 16px;

      div {
        display: flex;
        flex-direction: row;
        gap: 23px;

        .radio {
          display: flex;
          flex-direction: row;
          gap: 8px;
          align-items: center;
        }
      }
    }
  }

  p {
    color: ${props => props.theme.interface5};
  }

  svg {
    color: ${props => props.theme.brand_primary_color};
  }
`;
