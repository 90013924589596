import React, {
  useCallback,
  useMemo,
  useEffect,
  useState,
  useContext,
} from 'react';
import { withTheme, ThemeContext } from 'styled-components';
import { Row, Col } from 'react-bootstrap';

import ApplicationIcon from 'components/ApplicationIcon';

import { Loading, Input, Error } from '@cloudez/cloudez-design-system';

import { getDatabaseTypesService } from 'services/database';
import { getValue } from 'utils/getValue';
import { useAuth } from 'hooks/auth';
import { DatabaseCreateContext } from '../_context/state';
import { Wrapper, Type } from './styles';

const DatabaseTypes = () => {
  const { newDatabase, updateField, updateNewDatabase, errors } = useContext(
    DatabaseCreateContext,
  );

  const theme = useContext(ThemeContext);

  const { user } = useAuth();

  const [types, setTypes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getDatabaseTypes() {
      setLoading(true);
      const { data } = await getDatabaseTypesService({
        company: user.company,
      });

      setTypes(data);
      setLoading(false);
    }

    if (!types.length) getDatabaseTypes();
    // eslint-disable-next-line
  }, [types]);

  const databaseName = useMemo(() => {
    return getValue(newDatabase, 'database_name');
  }, [newDatabase]);

  const onNameChange = useCallback(
    e => {
      updateField(
        'values',
        newDatabase.values.map((v: any) =>
          v.slug === 'database_name' ? { ...v, value: e.target.value } : v,
        ),
      );
    },
    // eslint-disable-next-line
    [newDatabase],
  );

  const onTypeChange = useCallback(
    t => {
      updateNewDatabase({
        ...newDatabase,
        type: t.id,
        values: t.values.map(t => ({
          slug: t.slug,
          value: t.default,
        })),
      });
    },
    // eslint-disable-next-line
    [newDatabase],
  );

  return (
    <Wrapper>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Row
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {types?.map(t => (
              <Col
                key={t.id}
                lg="2"
                md="3"
                sm="4"
                xs="6"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Type
                  onClick={() => onTypeChange(t)}
                  selected={newDatabase.type === t.id}
                >
                  <ApplicationIcon
                    width="40px"
                    height="40px"
                    icon={
                      t.slug === 'selfhosted-mail'
                        ? 'cloudez-footer'
                        : `${t.slug}`
                    }
                  />
                  <span>{t.name}</span>
                  <p>{t.description}</p>
                </Type>
              </Col>
            ))}
          </Row>

          {newDatabase.type && (
            <Row
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBottom: '30px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '400px',
                  height: '100%',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                }}
              >
                <span
                  style={{
                    color: theme.interface5,
                    fontSize: '14px',
                  }}
                >
                  Dê um nome pra seu banco de dados
                </span>
                <Input
                  placeholder="Dê um nome pra seu banco de dados..."
                  block
                  value={databaseName}
                  onChange={onNameChange}
                  style={{
                    marginTop: '8px',
                  }}
                  error={errors?.values?.database_name}
                />
                {errors?.values?.database_name && (
                  <Error>{errors?.values?.database_name[0]}</Error>
                )}
              </div>
            </Row>
          )}
        </>
      )}
    </Wrapper>
  );
};

export default withTheme(DatabaseTypes);
