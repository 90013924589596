import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt } from '@fortawesome/free-solid-svg-icons';

import { Modal, Button } from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Content,
  Subtitle,
  Footer,
} from 'components/ListingModal/styles';

import { ModalProps } from 'types';

const MailGatewayModal: React.FC<ModalProps> = ({ show, setShow }) => {
  return (
    <Modal show={show} setShow={setShow}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faShieldAlt} />
        </Icon>
        <Text>
          <Title>Mail Gateway</Title>
          <Subtitle>Seus envios protegidos.</Subtitle>
        </Text>
      </Header>

      <Content
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '20px',
          justifyContent: 'space-between',
        }}
      >
        <span>
          Mail Gateway é um sistema de proteção utilizado no envio de todos os
          seus emails.
        </span>
        <span>
          Ele visa distribuir seus disparos entre diferentes endereços IP,
          melhorando a taxa de entrega dos seus emails.
        </span>
        <span>
          Com a utilização do Mail Gateway, você evita que seu cloud ou domínio
          caiam em listas de bloqueio nos órgãos Anti-SPAM do tipo “blocklist”.
        </span>
      </Content>

      <Footer>
        <Button width="149px" onClick={() => setShow(false)}>
          Fechar
        </Button>
      </Footer>
    </Modal>
  );
};

export default MailGatewayModal;
