/* eslint-disable prettier/prettier */
import React, { useState, useContext, useMemo } from 'react';
import { ThemeContext } from 'styled-components';

import { Link } from 'react-router-dom';
import { Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFlag,
  faSpinner,
  faStar,
  faThumbsUp,
  faThumbsDown,
  faCog,
  faCircle,
} from '@fortawesome/free-solid-svg-icons';
import { faRectangleXmark } from '@fortawesome/free-regular-svg-icons';

import { useNotifications } from 'hooks/notifications';
import { useAuth } from 'hooks/auth';

import cloudez from 'assets/img/svg/cloud_cloudez.svg';

import {
  Card,
  CardContent,
  ListingItem,
  ApplicationValue,
  ApplicationName,
  Notification,
} from 'components/Listing/styles';
import { AcessosList, Acesso, AcessoHover, Value } from 'components/Acessos';

import moment from 'moment';
import { AlertMessage } from './AlertMessage';
import { TicketListingContext } from '../_context/state';
import RatingModal from '../RatingModal';
import IconComponent from '../icons/IconComponent';

const TicketCard = ({ ticket }) => {
  const [ratingModal, setRatingModal] = useState(false);
  const [ratingTicket, setRatingTicket] = useState(null);
  const [loading, setLoading] = useState(null);
  const [loadingRedAlert, setLoadingRedAlert] = useState(null);
  const [showAccess, setShowAccess] = useState(false);
  const theme = useContext(ThemeContext);
  const { setRedAlert, updateTicket } = useContext(TicketListingContext);
  const { user } = useAuth();
  const { notifications } = useNotifications();

  moment.locale('pt-br');

  const createdAt = moment(ticket.created_at).locale('pt-BR').fromNow();
  const updatedAt = moment(ticket.updated_at).locale('pt-BR').fromNow();

  const hasNotification =
    notifications?.filter(
      n =>
        n.type === 'ticket' &&
        n.resource_id === Number(ticket.id) &&
        n.status === 0,
    ).length > 0;

  const ticketOwner = useMemo(() => {
    const owner = ticket.owner_email;
    if (ticket.is_cloudez || !user.is_staff) {
      if (owner.includes('configr.com')) {
        return owner.replace('configr.com', 'cloudez.io');
      }
      if (owner.includes('confi.gr')) {
        return owner.replace('confi.gr', 'cloudez.io');
      }
      return owner;
    }
    return owner;

    // eslint-disable-next-line
  }, [ticket]);

  const ticketLastMessageAuthor = useMemo(() => {
    const author = ticket.last_message_author;
    if (ticket.is_cloudez || !user.is_staff) {
      if (author?.includes('configr.com'))
        return author?.replace('configr.com', 'cloudez.io');
      if (author?.includes('confi.gr'))
        return author?.replace('confi.gr', 'cloudez.io');
      return author;
    }
    return author;
    // eslint-disable-next-line
  }, [ticket]);

  const newMessage =
    ticketLastMessageAuthor !== user.email && ticket.owner_email === user.email;

  const ticketArea = useMemo(() => {
    return ticket.team === 1
      ? 'Suporte'
      : ticket.team === 2
      ? 'Financeiro'
      : ticket.team === 3
      ? 'Comercial'
      : ticket.team === 4
      ? 'Migração'
      : ticket.team === 5
      ? 'Spam'
      : ticket.team === 6
      ? 'Alertas'
      : ticket.team === 7
      ? 'Painel'
      : ticket.team === 8 || ticket.team === 0
      ? 'Outros'
      : '';
  }, [ticket]);

  return (
    <>
      {ratingModal && (
        <RatingModal
          show={ratingModal}
          setShow={() => setRatingModal(false)}
          ticket={ratingTicket}
        />
      )}
      <ListingItem key={ticket.id} disable={ticket.status === 2} ticket>
        <Link to={`/suporte/${ticket.id}`}>
          <Card closed={ticket.status === 2}>
            <CardContent closed={ticket.status === 2}>
              <Col xs="10" sm="10" md="4" lg="1">
                {newMessage && ticket.status !== 2 && (
                  <Acesso style={{ padding: '0 8px' }} active hover>
                    <AlertMessage />
                    <AcessoHover
                      style={{
                        zIndex: '999',
                        top: '28px',
                        marginLeft: '-37px',
                      }}
                    >
                      <Value
                        style={{
                          textTransform: 'none',
                          fontSize: '12px',
                          textAlign: 'center',
                        }}
                      >
                        <span>Aguardando sua interação</span>
                      </Value>
                    </AcessoHover>
                  </Acesso>
                )}
                <ApplicationValue style={{ marginLeft: '30px' }}>
                  <IconComponent
                    type={ticket.status !== 2 ? 'defaultIcon' : 'closedIcon'}
                  />
                </ApplicationValue>
              </Col>
              <Col
                xs={!showAccess ? '10' : null}
                sm={!showAccess ? '10' : null}
                md={!showAccess ? '4' : null}
                lg="4"
              >
                <ApplicationValue closed={ticket.status === 2}>
                  <span
                    style={{
                      position: 'relative',
                    }}
                  >
                    {/* {hasNotification && (
                      <Notification
                        style={{
                          top: '-5px',
                        }}
                      />
                    )} */}
                  </span>
                  <ApplicationName>
                    <span
                      style={{
                        fontWeight: 'bold',
                        marginRight: '6px',
                      }}
                    >
                      # {ticket.id}
                    </span>
                    {ticket.summary}
                  </ApplicationName>
                </ApplicationValue>
              </Col>

              <Col className="d-none d-md-flex">
                <ApplicationValue
                  closed={ticket.status === 2}
                  style={{ fontWeight: 'normal' }}
                >
                  {ticketArea}
                </ApplicationValue>
              </Col>

              <Col className="d-none d-md-flex">
                <ApplicationValue closed={ticket.status === 2}>
                  <div>
                    <p style={{ fontWeight: 'normal' }}>{createdAt}</p>
                    <p
                      style={{
                        fontWeight: 'normal',
                        fontSize: '10px',
                        width: '90px',
                        display: 'block',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {ticketOwner}
                    </p>
                  </div>
                </ApplicationValue>
              </Col>

              <Col className="d-none d-md-flex">
                <ApplicationValue closed={ticket.status === 2}>
                  <div>
                    <p
                      className="bold"
                      style={{
                        fontWeight: 'normal',
                      }}
                    >
                      {ticket.status === 2 && ticket.closed_at
                        ? `${moment(ticket.closed_at).format('L')} - ${moment(
                            ticket.closed_at,
                          ).format('LT')}`
                        : ticket.status !== 2
                        ? updatedAt
                        : null}
                    </p>
                    {ticket.status !== 2 && (
                      <p
                        style={{
                          fontWeight: 'normal',
                          fontSize: '10px',
                          width: '100px',
                          display: 'block',
                          whiteSpace: 'nowrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                        }}
                      >
                        {ticketLastMessageAuthor}
                      </p>
                    )}
                  </div>
                </ApplicationValue>
              </Col>

              {!showAccess && (
                <Col className="d-sm-flex d-md-none">
                  <div>
                    <FontAwesomeIcon
                      icon={faCog}
                      style={{
                        color: '#64728C',
                      }}
                      onClick={e => {
                        e.preventDefault();
                        setShowAccess(true);
                      }}
                    />
                  </div>
                </Col>
              )}

              <Col
                xl="2"
                md="2"
                sm="4"
                className={!showAccess && 'd-none d-md-flex'}
                onClick={e => {
                  e.preventDefault();
                  setShowAccess(false);
                }}
                style={{
                  background: showAccess && theme.interface3,
                  height: '100%',
                  width: '100%',
                  borderTopRightRadius: '5px',
                  borderBottomRightRadius: '5px',
                }}
              >
                <AcessosList showAccess={showAccess}>
                  {user.is_staff && (
                    <>
                      {ticket?.is_cloudez && (
                        <Acesso active fixed={ticket.is_pinned} hover>
                          <img src={cloudez} width="20px" alt="cloudez" />
                          <AcessoHover>
                            <Value>
                              <span>Cloudez</span>
                            </Value>
                          </AcessoHover>
                        </Acesso>
                      )}
                      <Acesso
                        active
                        fixed={ticket.is_red_alert}
                        hover
                        remove
                        onClick={async e => {
                          e.stopPropagation();
                          e.preventDefault();
                          setLoadingRedAlert(true);
                          await setRedAlert(ticket.id);
                          setLoadingRedAlert(false);
                        }}
                      >
                        <FontAwesomeIcon
                          spin={loadingRedAlert}
                          icon={loadingRedAlert ? faSpinner : faFlag}
                        />
                        <AcessoHover>
                          <Value>
                            <span>Red alert</span>
                          </Value>
                        </AcessoHover>
                      </Acesso>
                      <Acesso
                        // active
                        hover
                        color={
                          ticket.priority === 1
                            ? 'green'
                            : ticket.priority === 2
                            ? 'blue'
                            : ticket.priority === 3
                            ? 'yellow'
                            : ticket.priority === 4
                            ? 'orange'
                            : ticket.priority === 5
                            ? 'red'
                            : null
                        }
                      >
                        <FontAwesomeIcon icon={faCircle} />
                        <AcessoHover>
                          <Value>
                            <span>Prioridade</span>
                          </Value>
                        </AcessoHover>
                      </Acesso>
                    </>
                  )}

                  <Acesso
                    active
                    fixed={ticket.rating === 1}
                    bold
                    like={ticket.rating}
                    hover
                    onClick={e => {
                      e.stopPropagation();
                      e.preventDefault();
                      setRatingModal(true);
                      setRatingTicket(ticket);
                    }}
                  >
                    {!ticket.rating && (
                      <AcessoHover>
                        <Value>
                          <span>
                            {ticket.status === 2
                              ? 'Avaliar Atendimento'
                              : 'Fechar Ticket'}
                          </span>
                        </Value>
                      </AcessoHover>
                    )}
                    <FontAwesomeIcon
                      icon={
                        !ticket.rating && ticket.status !== 2
                          ? faRectangleXmark
                          : ticket.rating === 2
                          ? faThumbsDown
                          : ticket.rating === 1
                          ? faThumbsUp
                          : faRectangleXmark
                      }
                    />
                  </Acesso>

                  <Acesso
                    active
                    fixed={ticket.is_pinned}
                    hover
                    favorite
                    onClick={async () => {
                      setLoading(true);
                      await updateTicket(
                        'is_pinned',
                        !ticket.is_pinned,
                        ticket.id,
                      );
                      setLoading(false);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={loading ? faSpinner : faStar}
                      spin={loading}
                    />
                    <AcessoHover>
                      <Value>
                        <span>Favorite</span>
                      </Value>
                    </AcessoHover>
                  </Acesso>
                </AcessosList>
              </Col>
            </CardContent>
          </Card>
        </Link>
      </ListingItem>
    </>
  );
};

export default TicketCard;
