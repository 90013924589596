import styled, { css } from 'styled-components';

const handleType = props => {
  switch (props.status) {
    case 7:
      return '#2253F5';
    case 5:
      return '#CFD3DE';
    case 4:
      return '#29D975';
    case 2:
    case 3:
    case 6:
      return '#FFBF2D';
    case 1:
      return '#333333';
    default:
      return '#fff';
  }
};
const handleRating = status => {
  switch (status) {
    case true:
      return '1px solid #29D975';
    case false:
      return '1px solid #FB264B';
    case null:
      return '1px solid #2253F5';
    default:
      return '1px solid #2253F5';
  }
};

const handleIconColor = status => {
  switch (status) {
    case true:
      return '#29D975';
    case false:
      return '#FB264B';
    case null:
      return '';
    default:
      return '';
  }
};

interface StatusBoxProps {
  status: number | boolean;
  outlined?: boolean;
  isHovering?: boolean;
  selected?: boolean;
}

const StatusBox = styled.div<StatusBoxProps>`
  width: 77px;
  height: 32px;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 5px;

  background-color: ${props =>
    props.outlined ? 'transparent' : handleType(props)};
  border: ${props => props.outlined && handleRating(props.status)};
  display: flex;

  font-weight: 400;
  font-size: 12px;

  transition: 0.2s;

  ${({ selected }) =>
    selected &&
    css`
      border: 2px solid ${props => props.theme.brand_primary_color} !important;
    `}

  ${({ isHovering }) =>
    isHovering &&
    css`
      &:hover {
        background-color: #2253f5;
        color: #ffffff;
        svg {
          color: #ffffff !important;
        }
      }
    `}

  color: ${props =>
    props.outlined ? props.theme.interface5 : props.theme.interface1};

  border-radius: 50px;

  svg {
    color: ${props => handleIconColor(props.status)} !important;
  }
`;

export default StatusBox;
