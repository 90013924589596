import styled from 'styled-components';

interface FlexboxProps {
  flexDir?: 'column' | 'row';
  maxWidth?: '100%' | '90%' | '80%' | '70%' | '50%' | '40%' | '30%';
  alignItems?: 'stretch' | 'center' | 'flex-start' | 'flex-end' | 'baseline';
  w?: '100%' | '90%' | '80%' | '70%' | '50%' | '40%' | '30%' | '20%' | '10%';
  justify?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  mt?: string;
  spacing?: string;
}

export const Flexbox = styled.div<FlexboxProps>`
  display: flex;
  flex-direction: ${props => (props.flexDir ? props.flexDir : 'row')};
  justify-content: ${props => (props.justify ? props.justify : 'flex-start')}
  align-items: ${props => (props.alignItems ? props.alignItems : 'stretch')}
  max-width: ${props => (props.maxWidth ? props.maxWidth : '100%')}
  width: ${props => (props.w ? props.w : 'auto')}
  margin-top: ${props => (props.mt ? props.mt : '0px')}
  gap: ${props => (props.spacing ? props.spacing : '20px')};


   @media (max-width: 768px) {
     max-width: 100%;
     flex-direction: column;
     align-items: flex-start;

     button {
       margin-top: 0px !important;
     }

     #radioCheckbox {
       margin-top: 0px !important;
     }
   }

   @media (max-width: 900px) {
     max-width: 100%;
   }
`;
