import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  align-items: left;

  gap: 24px;

  padding: 17px 28px;

  .emails {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  span {
    font-weight: normal;
    font-size: 16px;
    line-height: 150%;

    color: ${props => props.theme.interface5};
  }
`;
