import React from 'react';
import { Col, Row } from 'react-bootstrap';

import Placeholder from 'components/Placeholder';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breadcrumbs, Breadcrumb } from 'components/Common';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import {
  Header,
  Title,
  Tabs,
  Tab,
  TabContent,
  Card,
  Informations,
  Information,
  CardHeader,
} from 'components/Details';

import { CloudInfo } from '../styles';
import AddonPlaceholder from '../Addons/Placeholder';

const WebsiteDetailPlaceholder = () => {
  return (
    <>
      <Header>
        <Row>
          <Col md="10" sm="9">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Placeholder circle width={64} height={64} />

              <Title>
                <Breadcrumbs>
                  <Breadcrumb>
                    <Placeholder width={40} height={10} />
                  </Breadcrumb>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <Breadcrumb>
                    <Placeholder width={60} height={10} />
                  </Breadcrumb>
                </Breadcrumbs>
                <h3>
                  <Placeholder width={100} height={18} />
                </h3>
                <Placeholder width={100} height={12} />
              </Title>
            </div>
          </Col>

          <Col
            className="d-none d-sm-flex d-md-flex d-xl-flex"
            sm="3"
            md="2"
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Placeholder width={130} height={40} />
          </Col>
        </Row>
      </Header>

      <Row
        style={{
          marginBottom: `${15}px`,
        }}
      >
        <Col xl="8">
          <Row style={{ marginBottom: `${30}px` }}>
            <Col xl="12">
              <Card>
                <CardHeader>
                  <Placeholder width={100} height={16} />
                </CardHeader>
                <Row>
                  <Col xl="7">
                    <Informations>
                      <Information>
                        <Placeholder width={25} height={25} />
                        <span>
                          <Placeholder width={60} height={12} />
                        </span>
                        <span>
                          <Placeholder width={40} height={16} />
                        </span>
                      </Information>

                      <Information>
                        <Placeholder width={25} height={25} />

                        <span>
                          <Placeholder width={60} height={12} />
                        </span>
                        <span>
                          <Placeholder width={40} height={16} />
                        </span>
                      </Information>

                      <Information>
                        <Placeholder width={25} height={25} />

                        <span>
                          <Placeholder width={60} height={12} />
                        </span>
                        <span>
                          <Placeholder width={40} height={16} />
                        </span>
                      </Information>
                      <Information>
                        <Placeholder width={25} height={25} />

                        <span>
                          <Placeholder width={60} height={12} />
                        </span>
                        <span>
                          <Placeholder width={40} height={16} />
                        </span>
                      </Information>
                    </Informations>
                  </Col>
                  <Col>
                    <CloudInfo>
                      <p
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <span
                          style={{
                            marginRight: '2px',
                          }}
                        >
                          Tipo:
                        </span>
                        <Placeholder width={100} height={12} />
                      </p>
                      <p
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <span
                          style={{
                            marginRight: '2px',
                          }}
                        >
                          Sistema:
                        </span>
                        <Placeholder width={100} height={12} />
                      </p>
                      <p
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <span
                          style={{
                            marginRight: '2px',
                          }}
                        >
                          IPv4:
                        </span>
                        <Placeholder width={100} height={12} />
                      </p>
                      <p
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <span
                          style={{
                            marginRight: '2px',
                          }}
                        >
                          IPv6:
                        </span>
                        <Placeholder width={100} height={12} />
                      </p>
                      <p
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <span
                          style={{
                            marginRight: '2px',
                          }}
                        >
                          Hostname:
                        </span>
                        <Placeholder width={100} height={12} />
                      </p>

                      <p
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <span
                          style={{
                            marginRight: '2px',
                          }}
                        >
                          Ação:
                        </span>
                        <Placeholder width={100} height={12} />
                      </p>
                    </CloudInfo>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl="4">
              <Card>
                <CardHeader>
                  <Placeholder width={100} height={16} />
                </CardHeader>
                <div>
                  <Placeholder width={210} height={150} />
                </div>
              </Card>
            </Col>
            <Col xl="4">
              <Card>
                <CardHeader>
                  <Placeholder width={100} height={16} />
                </CardHeader>
                <div>
                  <Placeholder width={210} height={150} />
                </div>
              </Card>
            </Col>
            <Col xl="4">
              <Card>
                <CardHeader>
                  <Placeholder width={100} height={16} />
                </CardHeader>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Placeholder width={150} height={150} circle />
                </div>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col>
          <Tabs>
            <Tab active>
              <Placeholder width={50} height={14} />
            </Tab>
            <Tab>
              <Placeholder width={50} height={14} />
            </Tab>
          </Tabs>

          <TabContent>
            <Row>
              <AddonPlaceholder />
            </Row>
          </TabContent>
        </Col>
      </Row>
    </>
  );
};

export default WebsiteDetailPlaceholder;
