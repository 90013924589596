import React, { createContext, useReducer } from 'react';
import { Domain } from 'types/domain';
import reducer from './reducer';
import { setLoading, getDomain, deleteDomain, getWebsites } from './actions';

export interface DomainDetailState {
  domain: Domain;
  errors: any;
  websites: any;
  loading: boolean;
  getDomain(id: number | string): Promise<void>;
  getWebsites(domain: string | number): Promise<void>;
  deleteDomain(id: number | string): Promise<void>;
  setLoading(value: boolean): void;
}

// initial state
const initialState = {
  domain: null,
  errors: null,
  websites: null,
  loading: false,
};

// context
export const DomainDetailContext = createContext<DomainDetailState>(
  initialState as DomainDetailState,
);

// provider
export const DomainDetailProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    reducer,
    initialState as DomainDetailState,
  );

  return (
    <DomainDetailContext.Provider
      value={{
        // state
        domain: state.domain,
        loading: state.loading,
        errors: state.errors,
        websites: state.websites,

        // actions
        getDomain: async id => await getDomain(dispatch, id),
        getWebsites: async (domain: string | number) =>
          await getWebsites(dispatch, domain),
        deleteDomain: async id => await deleteDomain(dispatch, id),
        setLoading: value => setLoading(dispatch, value),
      }}
    >
      {children}
    </DomainDetailContext.Provider>
  );
};
