import React from 'react';

import { Flexbox } from 'components/NewComponents/Flexbox';
import { Title, Header } from 'components/Common';
import Stepper from 'components/Stepper';

import { MigDetailProvider, useMigDetail } from '../contexts/MigDetailContext';
import { MigCreateWebsiteProvider } from '../contexts/MigWebsiteContext';

import MigrationDetail from '../../MigrationDetailNew';
import Confirmation from './Confirmation';
import OriginData from './OriginData';
import DestData from './DestData';

const MigrationCreateWebsiteNew = () => {
  const { step } = useMigDetail();

  return (
    <>
      <Header style={{ justifyContent: 'center' }}>
        <Title>Migração de Website</Title>
      </Header>
      <Flexbox flexDir="column" justify="center" alignItems="center">
        <Stepper
          width="60%"
          activeStep={step}
          steps={[
            { label: 'Dados da Origem' },
            { label: 'Dados do Destino' },
            { label: 'Confirmação' },
            { label: 'Migração' },
          ]}
        />
        <Flexbox w="100%" justify="center">
          {(() => {
            switch (step) {
              case 1:
                return <OriginData />;
              case 2:
                return <DestData />;
              case 3:
                return <Confirmation />;
              case 4:
                return <MigrationDetail />;
              default:
                return null;
            }
          })()}
        </Flexbox>
      </Flexbox>
    </>
  );
};

export default props => (
  <MigDetailProvider>
    <MigCreateWebsiteProvider>
      <MigrationCreateWebsiteNew {...props} />
    </MigCreateWebsiteProvider>
  </MigDetailProvider>
);
