import React, { useState, Dispatch, SetStateAction } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTerminal } from '@fortawesome/free-solid-svg-icons';
import { Cron } from 'types/website';
import { PaginatedResponse } from '@cloudez/cloudez-design-system';
import { Card, Header, Name, Body, CommandHeader, Command } from './styles';
import CronDetail from '../CronDetail';

interface ICronCardProps {
  cron?: Cron;
  setCrons: Dispatch<SetStateAction<PaginatedResponse<Cron>>>;
}

const CronCard: React.FC<ICronCardProps> = ({ cron, setCrons }) => {
  const [modal, setModal] = useState(false);

  return (
    <>
      {modal && (
        <CronDetail
          show={modal}
          setShow={setModal}
          cron={cron}
          setCrons={setCrons}
        />
      )}
      <Card
        onClick={() => {
          setModal(true);
        }}
      >
        <Header>
          <FontAwesomeIcon
            icon={faTerminal}
            style={{
              width: `${22}px`,
              height: `${22}px`,
            }}
          />
          <Name>{cron.name}</Name>
        </Header>
        <Body>
          <CommandHeader>Comandos</CommandHeader>
          <Command>
            {cron.minute} {cron.hour} {cron.day_month} {cron.month}{' '}
            {cron.day_week}
          </Command>
          <Command>{cron.command}</Command>
        </Body>
      </Card>
    </>
  );
};

export default CronCard;
