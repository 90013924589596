import { useAuth } from 'hooks/auth';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useLayout } from 'hooks/layout';
import moment from 'moment/min/moment-with-locales';
import { skipFreeTrialService } from 'services/user';
import { Container } from './styles';

const FreeTrialTime = props => {
  const { user, getUser } = useAuth();

  const history = useHistory();
  const theme = useLayout();

  const [time, setTime] = useState('');

  useEffect(() => {
    moment.locale('pt-BR');
    const ends = moment(user?.onboarding_data?.free_trial_ends);

    const x = setInterval(() => {
      const diff = moment(ends, 'DD/MM/YYYY HH:mm:ss').diff(
        moment(moment(), 'DD/MM/YYYY HH:mm:ss'),
      );
      const d = moment.duration(diff);
      const s = Math.floor(d.asHours()) + moment.utc(diff).format(':mm:ss');

      if (diff <= 0) {
        clearInterval(x);
        setTime('EXPIRADO');
      } else setTime(s);
    }, 1000);

    return () => clearInterval(x);
  }, [user?.onboarding_data?.free_trial_ends]);

  const skipFreeTrial = async () => {
    try {
      await skipFreeTrialService(user.id);
      await getUser();
    } catch (e) {
      console.log(e);
    }
    theme.setNotification('', null, {});
  };

  return (
    <Container {...props}>
      <span className="text">Tempo de Free Trial</span>{' '}
      {user?.onboarding_data?.started_free_trial ? (
        <span className="time">
          {user?.onboarding_data?.trial_days}{' '}
          {user?.onboarding_data?.trial_days === 1 ? 'dia' : 'dias'} / {time}
        </span>
      ) : (
        <>
          <span
            className="time"
            onClick={() => history.push('/onboard')}
            style={{
              cursor: 'pointer',
              border: `1px solid ${theme.successNew}`,
              padding: '4px 6px',
              borderRadius: '5px',
            }}
          >
            INICIAR
          </span>
          <span
            className="time"
            onClick={skipFreeTrial}
            style={{
              cursor: 'pointer',
              border: `1px solid ${theme.successNew}`,
              padding: '4px 6px',
              borderRadius: '5px',
            }}
          >
            PULAR
          </span>
        </>
      )}
    </Container>
  );
};

export default FreeTrialTime;
