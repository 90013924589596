import styled, { css } from 'styled-components';

const reqSvgs = require.context('assets/img/svg', true, /\.svg$/);

export const Wrapper = styled.div``;

interface IconProps {
  icon: any;
  width: any;
  height: any;
  disable?: boolean;
}
export const Icon = styled.img.attrs<IconProps>(props => {
  let icon;

  try {
    icon = reqSvgs(`./${props.icon}.svg`).default;
  } catch (e) {
    icon = reqSvgs('./custom.svg').default;
  }

  return {
    src: icon,
  };
})<IconProps>`
  width: ${props => props.width};
  height: ${props => props.height};

  ${props =>
    props.disable &&
    css`
      filter: grayscale(1);
      opacity: 0.2;
    `};
`;
