import React, { useState, useEffect, useContext } from 'react';

import { Input } from '@cloudez/cloudez-design-system';

import { Tab, Tabs } from 'components/Tabs';
import DomainBuy from 'components/DomainBuy';
import { Wrapper, Card } from './styles';
import { EmailCreateContext } from '../_context/state';

interface DomainProps {
  setBuy: any;
}

const Domain: React.FC<DomainProps> = ({ setBuy }) => {
  const { updateField, newEmail } = useContext(EmailCreateContext);

  const [tab, setTab] = useState(1);
  const [domain, setDomain] = useState('');
  const [buyDomain, setBuyDomain] = useState(null);

  useEffect(() => {
    if (buyDomain) {
      updateField('domain', buyDomain.domain);

      setBuy(buyDomain);
    }
    // eslint-disable-next-line
  }, [buyDomain]);

  return (
    <Wrapper>
      <Tabs>
        <Tab
          active={tab === 1}
          onClick={() => {
            setTab(1);
          }}
        >
          Sim, vou usar um meu
        </Tab>
        <Tab
          active={tab === 2}
          onClick={() => {
            setTab(2);
          }}
        >
          Não, vou comprar um domínio
        </Tab>
      </Tabs>
      <Card>
        {tab === 1 && (
          <>
            <Input
              block
              placeholder="Digite seu domínio..."
              name="domain"
              value={newEmail.domain}
              onChange={e => {
                updateField('domain', e.target.value);
              }}
            />
          </>
        )}

        {tab === 2 && (
          <DomainBuy
            domain={domain}
            setDomain={setDomain}
            setBuyDomain={setBuyDomain}
            buyDomain={buyDomain}
          />
        )}
      </Card>
    </Wrapper>
  );
};

export default Domain;
