import { createPagination } from '@cloudez/cloudez-design-system';

export default (state, action) => {
  switch (action.type) {
    case 'GET_EMAILS':
      const emails = action.payload.results;
      const pagination = createPagination(action.payload, 10);

      return {
        ...state,
        ...pagination,
        emails,
      };

    case 'UPDATE_EMAIL': {
      return {
        ...state,
        emails: state?.emails?.map(email =>
          email.id === action.payload.id ? action.payload : email,
        ),
      };
    }

    case 'SET_LOADING': {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case 'SET_ERRORS': {
      return {
        ...state,
        errors: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
