import React, { Reducer } from 'react';
import { IActionPayload } from 'types';
import { CloudDetailState } from './state';

const reducer: Reducer<CloudDetailState, IActionPayload> = (
  state,
  action,
): CloudDetailState => {
  switch (action.type) {
    case 'GET_CLOUD':
    case 'PATCH_CLOUD':
      return {
        ...state,
        cloud: action.payload,
      };

    case 'SET_LOADING': {
      return {
        ...state,
        updateLoading: action.payload,
      };
    }

    case 'SET_ERRORS': {
      return {
        ...state,
        errors: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
