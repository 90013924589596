import React from 'react';

import gears from 'assets/json/gears.json';

import { Player } from '@lottiefiles/react-lottie-player';

import { Modal } from '@cloudez/cloudez-design-system';

import { Content } from './styles';

interface ValidatingConnectionModalProps {
  show: boolean;
  setShow: (value: boolean) => void;
}

const ValidatingConnectionModal = ({
  show,
  setShow,
}: ValidatingConnectionModalProps): JSX.Element => {
  return (
    <Modal noExit={show} width="374px" show={show} setShow={setShow}>
      <Content>
        <Player
          autoplay
          loop
          src={gears}
          style={{ height: '145px', width: '145px' }}
        />
        <span>Validando os dados de conexão...</span>
      </Content>
    </Modal>
  );
};

export default ValidatingConnectionModal;
