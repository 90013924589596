import React, { useState } from 'react';
import { withTheme } from 'styled-components';
import { Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';

import unlink from 'assets/img/svg/unlink.svg';

import { Modal, Input, Button } from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from 'components/ListingModal/styles';

import { ModalProps } from 'types';
import { useLayout } from 'hooks/layout';
import { HttpsText } from './styles';

const RemoveModal: React.FC<ModalProps> = ({ action, show, setShow }) => {
  const [remove, setRemove] = useState('');
  const [loading, setLoading] = useState(false);
  const theme = useLayout();

  return (
    <Modal width="455px" show={show} setShow={setShow} noExit={loading}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faTrashAlt} />
        </Icon>
        <Text>
          <Title>Excluir registro</Title>
          <Subtitle>Deseja excluir este registro?</Subtitle>
        </Text>
      </Header>

      <Content>
        <HttpsText>
          Por favor, escreva{' '}
          <span
            style={{
              textTransform: 'uppercase',
              color: theme.error,
              fontWeight: 'bold',
            }}
          >
            REMOVER
          </span>{' '}
          para confirmar essa ação.
        </HttpsText>

        <Input
          block
          placeholder="REMOVER"
          value={remove}
          onChange={e => {
            setRemove(e.target.value);
          }}
        />
      </Content>
      <Footer>
        <Button
          width="149px"
          icon
          disabled={remove !== 'REMOVER' || loading}
          error
          onClick={() => {
            setLoading(true);
            action();
          }}
        >
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Remover'}
        </Button>
      </Footer>
    </Modal>
  );
};

export default RemoveModal;
