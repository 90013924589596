import React, { useContext, useState, useEffect } from 'react';
import { Row, Col } from 'react-bootstrap';
import {
  FormInput,
  Field,
  Label,
  Pagination,
  Error,
  Radio,
  createPagination,
} from '@cloudez/cloudez-design-system';

import ApplicationIcon from 'components/ApplicationIcon';
import SearchInput from 'components/SearchInput';
import { getWebsitesService } from 'services/website';
import { getCloudsService } from 'services/cloud';

import { faPlusSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AddCard, AddButton } from 'components/AddCard';
import history from 'services/history';
import WebsitePlaceholder from './WebsitePlaceholder';
import CloudPlaceholder from './CloudPlaceholder';
import WebsiteTypes from './WebsiteTypes';
import { CloudWrapper, Card, Dest as DestStyle } from '../styles';
import { MigrationCreateContext } from '../../_context/state';

const Dest = ({
  disabled,
  selectedResource,
  setSelectedResource,
  websiteType,
  setWebsiteType,
  vldPayload,
  isExternal,
}) => {
  const { errors } = useContext(MigrationCreateContext);

  const [type, setType] = useState(1);

  const [website, setWebsite] = useState(null);
  const [websiteLoading, setWebsiteLoading] = useState(false);
  const [cloud, setCloud] = useState(null);
  const [cloudLoading, setCloudLoading] = useState(false);
  const [search, setSearch] = useState('');
  const [cloudSearch, setCloudSearch] = useState('');

  const getWebsitesCallback = async (page, search) => {
    setWebsiteLoading(true);
    try {
      const { data } = await getWebsitesService({
        page_size: 6,
        nomigration: 1,
        page,
        search,
      });

      const pagination = createPagination(data, 6);
      setWebsite({ websites: data.results, ...pagination });
      setWebsiteLoading(false);
    } catch (e) {
      setWebsiteLoading(false);
    }
  };

  const searchValue = searchValue => {
    getWebsitesCallback(1, searchValue);
  };

  const getCloudsCallback = async (page, search) => {
    setCloudLoading(true);
    try {
      const { data } = await getCloudsService({
        page_size: 6,
        nomigration: 1,
        page,
        search,
        noemail: true,
      });

      const pagination = createPagination(data, 6);
      setCloud({ clouds: data.results, ...pagination });
      setCloudLoading(false);
    } catch (e) {
      setCloudLoading(false);
    }
  };

  const searchCloudValue = searchValue => {
    getCloudsCallback(1, searchValue);
  };

  useEffect(() => {
    getWebsitesCallback();
    getCloudsCallback();
  }, []);

  return (
    <DestStyle disabled={disabled}>
      <h3>Destino</h3>
      <Field
        style={{
          marginBottom: '30px',
        }}
      >
        <Radio
          id="cloud"
          name="type"
          value="1"
          onChange={e => setType(Number(e.target.value))}
          checked={type === 1}
        />
        <Label onClick={() => setType(1)}>Cloud</Label>
        <Radio
          style={{
            marginLeft: '20px',
          }}
          id="website"
          name="type"
          value="2"
          onChange={e => setType(Number(e.target.value))}
          checked={type === 2}
        />
        <Label onClick={() => setType(2)}>Website</Label>
      </Field>

      {type === 1 && (
        <>
          <Label>Selecione o cloud de destino</Label>
          <CloudWrapper>
            <SearchInput
              style={{
                width: '100%',
              }}
              value={cloudSearch}
              setValue={setCloudSearch}
              callback={searchCloudValue}
              noResults={cloud?.clouds?.length === 0}
            />
            <>
              <Row
                style={{
                  width: '100%',
                  marginLeft: 0,
                  marginTop: 20,
                }}
              >
                {cloud?.clouds?.length === 0 && (
                  <Col
                    lg="4"
                    sm="6"
                    xs="12"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <AddCard
                      width="100%"
                      height="110px"
                      onClick={() => history.push('/clouds/create')}
                    >
                      <AddButton row>
                        <FontAwesomeIcon icon={faPlusSquare} />
                        <span>adicionar</span>
                      </AddButton>
                    </AddCard>
                  </Col>
                )}
                {cloudLoading ? (
                  <CloudPlaceholder />
                ) : (
                  cloud?.clouds?.map(c => {
                    return (
                      <Col
                        key={c.id}
                        lg="4"
                        sm="6"
                        xs="12"
                        style={{
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Card
                          key={c.id}
                          cloud={c}
                          selected={c.id === selectedResource.id}
                          onClick={() =>
                            setSelectedResource({
                              id: c.id,
                              type: 'node',
                            })
                          }
                        >
                          <ApplicationIcon
                            icon={`${
                              c.cloud_provider.slug === 'partner' ||
                              c.cloud_provider.slug === 'partner-g2' ||
                              c.cloud_provider.slug.includes('partner-g3') ||
                              c.cloud_provider.slug.includes('partner-g4')
                                ? 'cloudez-footer'
                                : c.cloud_provider.slug === 'configr-g3' ||
                                  c.cloud_provider.slug === 'configr-g4' ||
                                  c.cloud_provider.slug === 'configr-g5' ||
                                  c.cloud_provider.slug.includes('dpg-custom')
                                ? 'configr'
                                : c.cloud_provider.slug
                            }`}
                            width="45px"
                            height="45px"
                          />

                          <div
                            style={{
                              textAlign: 'center',
                              width: '100%',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                            }}
                          >
                            <span>{c.name}</span>
                          </div>
                          <div
                            style={{
                              textAlign: 'center',
                              width: '100%',
                            }}
                          >
                            <p>{c.nickname}</p>
                          </div>
                        </Card>
                      </Col>
                    );
                  })
                )}
              </Row>
              <Row
                style={{
                  width: '100%',
                  marginTop: '-30px',
                  marginBottom: '20px',
                  marginLeft: 0,
                }}
              >
                <Pagination
                  onChangePage={getCloudsCallback}
                  count={cloud?.count}
                  previous={cloud?.previous}
                  next={cloud?.next}
                  current={cloud?.current}
                  search={cloudSearch}
                  pageSize="6"
                />
              </Row>
              <FormInput
                name="resource_type"
                style={{
                  display: 'none',
                }}
                value={selectedResource.type}
                onChange={() => {}}
                error={!!errors?.node}
              />
              <FormInput
                name="resource"
                style={{
                  display: 'none',
                }}
                value={selectedResource.id}
                onChange={() => {}}
                error={!!errors?.node}
              />
              {!!errors?.node && (
                <Error
                  style={{
                    marginBottom: '15px',
                  }}
                >
                  {errors?.node}
                </Error>
              )}
            </>
            {errors &&
              Object.keys(errors).map(
                (e, i) =>
                  e !== 'domain' &&
                  e !== 'host' &&
                  e !== 'auth' &&
                  e !== 'username' &&
                  e !== 'password' &&
                  e !== 'path' &&
                  e !== 'port' && (
                    <Error
                      key={i}
                      style={{
                        fontSize: '14px',
                        marginBottom: '10px',
                      }}
                    >
                      {errors[e][0]?.length > 1 ? errors[e][0] : errors[e]}
                    </Error>
                  ),
              )}
          </CloudWrapper>
          {vldPayload && (!vldPayload?.websites || isExternal) && (
            <>
              <Label>Selecione o tipo da aplicação de destino</Label>
              <WebsiteTypes
                websiteType={websiteType}
                setWebsiteType={setWebsiteType}
              />
              <FormInput
                name="website_type"
                style={{
                  display: 'none',
                }}
                value={websiteType}
                onChange={() => {}}
                error={!!errors?.website_type}
              />
              {!!errors?.website_type && (
                <Error
                  style={{
                    marginBottom: '15px',
                  }}
                >
                  {errors?.website_type}
                </Error>
              )}
            </>
          )}
        </>
      )}

      {type === 2 && (
        <>
          <Label>Selecione o website de destino</Label>
          <CloudWrapper>
            <SearchInput
              style={{
                width: '100%',
              }}
              value={search}
              setValue={setSearch}
              callback={searchValue}
              noResults={website?.websites?.length === 0}
            />

            <>
              <Row
                style={{
                  width: '100%',
                  marginLeft: 0,
                  marginTop: 20,
                }}
              >
                {website?.websites?.length === 0 && (
                  <Col
                    lg="4"
                    sm="6"
                    xs="12"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <AddCard
                      width="100%"
                      height="110px"
                      onClick={() => history.push('/websites/create')}
                    >
                      <AddButton row>
                        <FontAwesomeIcon icon={faPlusSquare} />
                        <span>adicionar</span>
                      </AddButton>
                    </AddCard>
                  </Col>
                )}
                {websiteLoading ? (
                  <WebsitePlaceholder />
                ) : (
                  website?.websites?.map(c => {
                    return (
                      <Col
                        key={c.id}
                        lg="4"
                        sm="6"
                        xs="12"
                        style={{
                          width: '100%',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        <Card
                          key={c.id}
                          cloud={c}
                          selected={c.id === selectedResource.id}
                          onClick={() =>
                            setSelectedResource({
                              id: c.id,
                              type: 'nodeapp',
                            })
                          }
                        >
                          <ApplicationIcon
                            icon={c.type.slug}
                            width="45px"
                            height="45px"
                          />
                          <div
                            style={{
                              textAlign: 'center',
                              width: '100%',
                              whiteSpace: 'nowrap',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                            }}
                          >
                            <span>{c.domain || c.nickname || c.name}</span>
                          </div>
                          <div
                            style={{
                              textAlign: 'center',
                              width: '100%',
                            }}
                          >
                            <p>{c.cloud.display}</p>
                          </div>
                        </Card>
                      </Col>
                    );
                  })
                )}
              </Row>
              <Row
                style={{
                  width: '100%',
                  marginTop: '-30px',
                  marginBottom: '20px',
                  marginLeft: 0,
                }}
              >
                <Pagination
                  onChangePage={getWebsitesCallback}
                  count={website?.count}
                  previous={website?.previous}
                  next={website?.next}
                  current={website?.current}
                  search={search}
                  pageSize="6"
                />
              </Row>
              <FormInput
                name="resource"
                style={{
                  display: 'none',
                }}
                value={selectedResource.id}
                onChange={() => {}}
                error={!!errors?.website}
              />
              {!!errors?.website && (
                <Error
                  style={{
                    marginBottom: '15px',
                  }}
                >
                  {errors?.website}
                </Error>
              )}
            </>
            {errors &&
              Object.keys(errors).map(
                (e, i) =>
                  e !== 'domain' &&
                  e !== 'host' &&
                  e !== 'auth' &&
                  e !== 'connection' &&
                  e !== 'username' &&
                  e !== 'password' &&
                  e !== 'path' &&
                  e !== 'port' && (
                    <Error
                      key={i}
                      style={{
                        fontSize: '14px',
                        marginBottom: '10px',
                      }}
                    >
                      {errors[e][0]?.length > 1 ? errors[e][0] : errors[e]}
                    </Error>
                  ),
              )}
          </CloudWrapper>
        </>
      )}
    </DestStyle>
  );
};

export default Dest;
