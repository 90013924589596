import React, { createContext, useReducer } from 'react';
import { PaginationType } from '@cloudez/cloudez-design-system';
import { Ticket } from 'types/ticket';
import reducer from './reducer';
import { setLoading, getTickets, updateTicket, setRedAlert } from './actions';

export interface TicketListingState extends PaginationType {
  tickets: Ticket[];
  loading: boolean;
  errors: any;
  setLoading(value: boolean): void;
  getTickets(
    page: string | number,
    search: any,
    team: any,
    status: any,
    filter: any,
    priority: any,
    user: any,
    order: any,
    ctrl: AbortController,
  ): Promise<void>;
  updateTicket(
    field: string,
    value: any,
    id?: number | string,
    ticket?: any,
  ): Promise<void>;
  setRedAlert(id: number | string): Promise<void>;
}

// initial state
const initialState = {
  tickets: null,
  count: null,
  previous: null,
  current: null,
  next: null,
  loading: true,
  errors: null,
};

// context
export const TicketListingContext = createContext<TicketListingState>(
  initialState as TicketListingState,
);

// provider
export const TicketListingProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    reducer,
    initialState as TicketListingState,
  );

  return (
    <TicketListingContext.Provider
      value={{
        // state
        tickets: state.tickets,
        count: state.count,
        previous: state.previous,
        current: state.current,
        next: state.next,
        loading: state.loading,
        errors: state.errors,

        // actions
        setLoading: value => setLoading(dispatch, value),
        getTickets: async (
          page,
          search,
          team,
          status,
          filter,
          priority,
          user,
          order,
          ctrl,
        ) =>
          await getTickets(
            dispatch,
            page,
            search,
            team,
            status,
            filter,
            priority,
            user,
            order,
            ctrl,
          ),
        updateTicket: async (field, value, id, ticket) =>
          await updateTicket(dispatch, field, value, id, ticket),
        setRedAlert: async id => await setRedAlert(dispatch, id),
      }}
    >
      {children}
    </TicketListingContext.Provider>
  );
};
