import React, { useEffect, memo, useRef, useContext } from 'react';

import moment from 'moment/min/moment-with-locales';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPaperclip,
  faFilePdf,
  faFile,
} from '@fortawesome/free-solid-svg-icons';
import md5 from 'md5';
import { ThemeContext } from 'styled-components';
import { useSpring, animated } from 'react-spring';
import { useLayout } from 'hooks/layout';
import {
  Message as MessageStyle,
  MessageHeader,
  MessageText,
  Hour,
  File,
} from '../styles';

const Message = ({ msg, user, ticket }) => {
  const { support_email } = useLayout();

  moment.locale('pt-BR');

  const _createdAt = moment(msg.created_at).endOf('day');
  const _yesterday = moment().endOf('day');

  const isOld = !moment(_yesterday).isSame(moment(_createdAt), 'd');

  const createdAt = moment(msg.created_at).format(isOld ? 'LLL' : 'HH:mm');

  const messageRef = useRef(null);

  const theme = useContext(ThemeContext);

  const isDomo = msg => {
    return (
      msg.author === 'domo@configr.com' ||
      msg.author === support_email ||
      msg.author_external === support_email
    );
  };

  useEffect(() => {
    const url = new RegExp(
      // eslint-disable-next-line
      /(\b(https?|ftp):\/\/[A-Z0-9+&@#\/%?=~_|!:,.;-]*[-A-Z0-9+&@#\/%=~_|])/gim,
    );

    if (messageRef.current) {
      messageRef.current.innerHTML = messageRef.current.innerHTML.replace(
        url,
        '<a href="$1" target="_blank">$1</a>',
      );
    }
  }, [messageRef]);

  const getDomoIcon = id => {
    const req = require.context('assets/img', true, /\.png$/);
    let icon;

    try {
      const random = (id % 4) + 1;
      const domo = 'domo-'.concat(random.toString());

      icon = req(`./${domo}.png`).default;
    } catch (e) {
      icon = null;
    }

    return icon;
  };

  const getDomoName = msg => {
    return `Domo #${Math.floor(Math.random() * msg.id)}`;
  };

  const props1 = useSpring({
    from: {
      border: `2px solid ${theme.favoriteNew}`,
      borderRadius:
        msg.author === user.email ? '15px 15px 0px 15px' : '15px 15px 15px 0px',
    },
    to: {
      border: `2px solid ${'rgba(255,255,255,0)'}`,
      borderRadius:
        msg.author === user.email ? '15px 15px 0px 15px' : '15px 15px 15px 0px',
    },
    config: { duration: 2000 },
  });

  return (
    <MessageStyle
      key={msg.id}
      domo={isDomo(msg)}
      isStaff={msg.is_ticket_staff && user.is_staff}
      isAuthor={msg.author === user.email}
    >
      <animated.div style={msg.isNew && props1}>
        <MessageHeader domo={isDomo(msg)}>
          <p>
            <img
              src={
                msg.author === 'domo@configr.com'
                  ? getDomoIcon(msg.id)
                  : `https://www.gravatar.com/avatar/${md5(
                      msg.author || msg.author_external,
                    )}?d=mp`
              }
              alt="avatar"
            />
            {msg.author === 'domo@configr.com'
              ? getDomoName(msg)
              : msg.display_name}
          </p>
          {msg.text && msg.file && (
            <span onClick={() => window.open(msg.file, '_blank')}>
              <span>Anexo</span>
              <FontAwesomeIcon icon={faPaperclip} />
            </span>
          )}
        </MessageHeader>
        <MessageText domo={isDomo(msg)}>
          {!msg.text && msg.file && (
            <File
              onClick={() => {
                window.open(msg.file, '_blank');
              }}
            >
              <span>
                {msg.file.includes('.png') ||
                msg.file.includes('.jpg') ||
                msg.file.includes('.jpe') ||
                msg.file.includes('.jpeg') ? (
                  <img
                    src={msg.file}
                    alt={msg.file}
                    style={{
                      maxHeight: '120px',
                      width: '100%',
                    }}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={msg.file.includes('.pdf') ? faFilePdf : faFile}
                  />
                )}
              </span>
              <p>{msg.file.split('uploads/')[1]}</p>
            </File>
          )}
          <pre ref={messageRef}>{msg.text}</pre>
        </MessageText>
        <Hour
          isAuthor={msg.author === user.email}
          domo={isDomo(msg)}
          staff={msg.is_ticket_staff && msg.author !== ticket.owner_email}
          isStaff={user.is_staff}
          isOld={isOld}
        >
          {createdAt}
        </Hour>
      </animated.div>
    </MessageStyle>
  );
};

export default memo(Message);
