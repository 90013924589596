import React from 'react';
import { Switch } from 'react-router-dom';
import * as p from 'pages';
import { useLayout } from 'hooks/layout';
import { useAuth } from 'hooks/auth';
import { P } from 'pages/Dashboard/styles';
import Route from './Route';

const Routes: React.FC = () => {
  const theme = useLayout();
  const { user } = useAuth();

  return (
    <Switch>
      {/* Non private routes */}
      <Route
        path={['/', '/login']}
        exact
        component={p.Auth.SignIn}
        loginLayout
      />
      {/* <Route path="/login" exact component={p.Auth.SignIn} loginLayout /> */}
      <Route
        path={['/register', '/signup']}
        exact
        component={p.Auth.SignUp}
        disabled={theme.has_disabled_register}
      />

      <Route
        loginLayout
        exact
        path="/login/2fa"
        component={p.Auth.TwoFactorAuthentication}
      />

      <Route
        loginLayout
        exact
        path="/password/reset"
        component={p.Auth.ResetPassword}
      />
      <Route
        loginLayout
        exact
        path="/password/reset/:uid/:token"
        component={p.Auth.ResetPasswordForm}
      />
      <Route
        exact
        path="/password/email-account/reset"
        component={p.ResetEmailPassword}
        defaultLayout
      />

      <Route
        exact
        path="/invoices"
        component={p.Invoices}
        isPrivate
        roles={['financial_role', 'customer_success_role']}
      />
      <Route
        exact
        path="/invoices/external/:hash"
        component={p.NewInvoiceExternal}
        defaultLayout
        roles={['financial_role', 'customer_success_role']}
      />
      <Route
        exact
        path="/invoices/bank-slip/:hash"
        component={p.InvoiceBankslip}
        defaultLayout
        roles={['financial_role', 'customer_success_role']}
      />
      <Route
        exact
        path="/invoices/success"
        component={p.InvoiceSuccess}
        defaultLayout
        roles={['financial_role', 'customer_success_role']}
      />
      <Route
        exact
        path="/invoices/resend"
        component={p.ResendInvoices}
        defaultLayout
        roles={['financial_role', 'customer_success_role']}
      />
      <Route
        exact
        path="/invoices/:id"
        component={p.InvoiceDetail}
        isPrivate
        roles={['financial_role', 'customer_success_role']}
      />

      <Route
        exact
        path={['/suporte/rating/:hash', '/tickets/rating/:hash']}
        component={p.TicketRating}
        defaultLayout
        disabled={theme.is_provider}
      />
      <Route exact path="/terms-of-use" component={p.Terms} defaultLayout />

      {/* Private routes */}
      <Route exact path="/onboard" component={p.Onboard} isPrivate authLayout />
      <Route exact path="/account" component={p.Account} isPrivate />
      <Route
        exact
        path="/phone/validate"
        component={p.Auth.ValidatePhone}
        isPrivate
        authLayout
      />
      <Route
        exact
        path="/update/billing"
        component={p.Auth.UpdateBilling}
        isPrivate
      />
      <Route exact path="/clouds" component={p.Clouds} isPrivate />
      <Route
        exact
        path="/clouds/create"
        component={user?.is_new_acquisition ? p.CloudCreateNew : p.CloudCreate}
        isPrivate
      />

      <Route
        exact
        path="/cloud/create/success"
        component={p.CloudCreateSuccess}
        isPrivate
      />
      <Route
        exact
        path="/clouds/:id"
        component={user?.is_new_acquisition ? p.CloudDetailNew : p.CloudDetail}
        isPrivate
      />

      <Route path="/dashboard" component={p.Dashboard} isPrivate />
      <Route
        path="/performance"
        component={p.Performance}
        isPrivate
        roles={['technical_role', 'customer_success_role']}
        disabled={!theme.has_performance}
      />

      <Route
        exact
        path="/databases"
        component={p.Databases}
        isPrivate
        roles={['technical_role', 'customer_success_role']}
      />
      <Route
        exact
        path="/databases/create"
        component={p.DatabaseCreate}
        isPrivate
        roles={['technical_role', 'customer_success_role']}
      />
      <Route
        exact
        path="/databases/:id"
        component={p.DatabaseDetail}
        isPrivate
        roles={['technical_role', 'customer_success_role']}
      />

      <Route
        exact
        path="/domains"
        component={p.Domains}
        isPrivate
        roles={['technical_role', 'customer_success_role']}
      />
      <Route
        exact
        path="/domains/create"
        component={p.DomainCreate}
        isPrivate
        roles={['technical_role', 'customer_success_role']}
      />
      <Route
        exact
        path="/domains/:id"
        component={p.DomainDetail}
        isPrivate
        roles={['technical_role', 'customer_success_role']}
      />

      <Route
        exact
        path="/emails"
        component={p.Emails}
        isPrivate
        roles={['technical_role', 'customer_success_role']}
      />
      <Route
        exact
        path="/emails/create"
        component={p.EmailCreate}
        isPrivate
        roles={['technical_role', 'customer_success_role']}
      />
      <Route
        exact
        path="/emails/:id"
        component={p.EmailDetail}
        isPrivate
        roles={['technical_role', 'customer_success_role']}
      />

      <Route
        path="/migrations"
        exact
        component={
          theme.has_new_migration ? p.MigrationListingNew : p.Migrations
        }
        isPrivate
        roles={['technical_role', 'customer_success_role']}
      />
      <Route
        path="/migrations/create"
        exact
        component={
          theme.has_new_migration ? p.MigrationCreateNew : p.MigrationCreate
        }
        isPrivate
        roles={['technical_role', 'customer_success_role']}
      />
      <Route
        path="/migrations/create/website"
        exact
        component={
          theme.has_new_migration
            ? p.MigrationCreateWebsiteNew
            : p.MigrationCreateWebsite
        }
        isPrivate
        roles={['technical_role', 'customer_success_role']}
      />
      <Route
        path="/migrations/create/email"
        exact
        component={
          theme.has_new_migration
            ? p.MigrationCreateEmailNew
            : p.MigrationCreateEmail
        }
        isPrivate
        roles={['technical_role', 'customer_success_role']}
      />
      <Route
        path="/migrations/create/assisted"
        exact
        component={
          theme.has_new_migration
            ? p.MigrationCreateAssistedNew
            : p.MigrationCreateAssisted
        }
        isPrivate
        roles={['technical_role', 'customer_success_role']}
      />
      <Route
        path="/migrations/:id"
        exact
        component={
          theme.has_new_migration ? p.MigrationDetailNew : p.MigrationDetail
        }
        isPrivate
        roles={['technical_role', 'customer_success_role']}
      />

      <Route path="/performance" component={p.Performance} isPrivate />

      <Route
        exact
        path={['/suporte', '/tickets']}
        disabled={theme.is_provider}
        component={p.Tickets}
        isPrivate
      />
      <Route
        exact
        disabled={theme.is_provider}
        path={['/suporte/create', '/tickets/create']}
        component={p.TicketCreate}
        isPrivate
      />
      <Route
        exact
        path={['/suporte/:id', '/tickets/:id']}
        disabled={theme.is_provider}
        component={p.TicketDetail}
        isPrivate
      />

      <Route
        exact
        path="/websites"
        component={p.Websites}
        isPrivate
        roles={['technical_role', 'customer_success_role']}
      />
      <Route
        exact
        path="/websites/create"
        component={p.WebsiteCreate}
        isPrivate
        roles={['technical_role', 'customer_success_role']}
      />
      <Route
        exact
        path="/websites/:id"
        component={p.WebsiteDetail}
        isPrivate
        roles={['technical_role', 'customer_success_role']}
      />

      {theme.slug === 'configr' && (
        <Route exact path="/referral" component={p.Referral} isPrivate />
      )}

      {/* 404 Route */}
      <Route path="/404" exact component={p.NotFound} isPrivate />
      <Route component={p.NotFound} />
    </Switch>
  );
};

export default Routes;
