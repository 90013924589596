import React from 'react';

import moment from 'moment';
import 'moment/locale/pt-br';

import { faLaptop, faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Icon } from 'components/ListingModal/styles';

import { Card } from './styles';

interface CardsSessionProps {
  device: {
    created_at: string;
    id: number;
    ip: string;
    location: string;
    current_device: boolean;
    system: { os: string; browser: string; is_mobile: boolean };
    user_agent: string;
  };
  setShowFinishSectionModal: (value: boolean) => void;
  setShowFinishActiveSectionModal: (value: boolean) => void;
  onClick: () => void;
}

const CardsSession: React.FC<CardsSessionProps> = ({
  device,
  setShowFinishSectionModal,
  setShowFinishActiveSectionModal,
  onClick,
}) => {
  return (
    <Card onClick={onClick}>
      <div className="title">
        <Icon style={{ borderRadius: '50%' }}>
          <FontAwesomeIcon
            style={{ fontSize: '20px' }}
            size="sm"
            icon={device.system?.is_mobile ? faMobileAlt : faLaptop}
          />
        </Icon>
        <span className="typeAccess">{device.system?.os}</span>
        {device?.current_device && (
          <div className="activeSession">Ativo agora</div>
        )}
      </div>
      <div className="info">
        Navegador: <span>{device.system?.browser}</span>
      </div>
      <div className="info">
        Localização: <span>{device?.location}</span>
      </div>
      <div className="info">
        Data de Criação: <span>{moment(device?.created_at).format('LLL')}</span>
      </div>

      <button
        onClick={() =>
          device?.current_device
            ? setShowFinishActiveSectionModal(true)
            : setShowFinishSectionModal(true)
        }
        type="button"
      >
        Encerrar dispositivo
      </button>
    </Card>
  );
};

export default CardsSession;
