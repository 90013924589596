import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  background: ${props => props.theme.interface1};

  box-shadow: 0px 2px 15px rgba(100, 114, 140, 0.25);
  border-radius: 5px;

  padding: 32px;

  gap: 24px;

  .overflowText {
    text-overflow: ellipsis;
    overflow: hidden;

    width: 105px;
    height: 16px;
  }

  .password {
    display: flex;
    flex-direction: row;

    align-items: center;

    gap: 10px;

    svg {
      cursor: pointer;

      width: 1.25em;

      color: ${props => props.theme.interface5};

      transition: filter 0.4s;

      &:hover {
        filter: brightness(0.5);
      }
    }
  }

  .title {
    font-weight: bold;
    font-size: 16px;

    line-height: 25px;

    color: ${props => props.theme.brand_primary_color};

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 9px;
  }

  .subtitle {
    font-weight: bold;
    font-size: 16px;

    color: ${props => props.theme.interface5};
  }

  .dataMig {
    display: flex;
    flex-direction: column;
    gap: 15px;

    display: flex;
    flex-direction: column;

    gap: 24px;

    p {
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: ${props => props.theme.brand_primary_color};
    }

    label,
    span {
      display: block;
      font-weight: bold;
      font-size: 16px;

      color: ${props => props.theme.interface5};
    }

    span {
      font-weight: normal;
    }
  }

  .users {
    display: flex;
    flex-direction: column;
    gap: 13px;

    p {
      font-weight: bold;
      font-size: 16px;
      line-height: 19px;
      color: ${props => props.theme.brand_primary_color};
    }
  }

  .visualizeAll {
    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: normal;
    font-size: 16px;

    text-decoration: underline;

    color: ${props => props.theme.brand_primary_color};
  }
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

interface FlexboxProps {
  flexDir?: 'column' | 'row';
  maxWidth?:
    | '100%'
    | '90%'
    | '80%'
    | '70%'
    | '50%'
    | '40%'
    | '30%'
    | '20%'
    | '10%';
  alignItems?: 'stretch' | 'center' | 'flex-start' | 'flex-end' | 'baseline';
  w?:
    | '100%'
    | '90%'
    | '80%'
    | '70%'
    | '60%'
    | '50%'
    | '40%'
    | '30%'
    | '20%'
    | '10%';
  justify?:
    | 'flex-start'
    | 'flex-end'
    | 'center'
    | 'space-between'
    | 'space-around'
    | 'space-evenly';
  mt?: string;
  spacing?: string;
}

export const Flexbox = styled.div<FlexboxProps>`
  display: flex;
  flex-direction: ${props => (props.flexDir ? props.flexDir : 'row')};
  justify-content: ${props => (props.justify ? props.justify : 'flex-start')}
  align-items: ${props => (props.alignItems ? props.alignItems : 'stretch')}
  max-width: ${props => (props.maxWidth ? props.maxWidth : '100%')}
  width: ${props => (props.w ? props.w : 'auto')}
  margin-top: ${props => (props.mt ? props.mt : '0px')}
  gap: ${props => (props.spacing ? props.spacing : '20px')};


   @media (max-width: 768px) {
     max-width: 100%;
     flex-direction: column;

     button {
       margin-top: 0px !important;
     }

     #radioCheckbox {
       margin-top: 0px !important;
     }
   }

 
`;
