import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useCallback, useMemo, useState } from 'react';

import cloudez_icon from 'assets/img/svg/cloud_cloudez.svg';

import { faCrown } from '@fortawesome/free-solid-svg-icons';
import { useLayout } from 'hooks/layout';
import * as Styles from './styles';

export default function CloudCard({
  cloud,
  selected,
  onClick,
  recommended = false,
}) {
  const theme = useLayout();
  const [openInfo, setOpenInfo] = useState(false);

  const mem = useMemo(() => {
    if (cloud.status_timeline && cloud.status_timeline.length > 0) {
      return Math.round(
        cloud.status_timeline
          .map(s => s.mem)
          .reduce((a, b) => {
            return Number(a) + Number(b);
          }, 0) / cloud.status_timeline.length,
      );
    }

    return null;
  }, [cloud.status_timeline]);

  const disk = useMemo(() => {
    if (cloud.status_timeline && cloud.status_timeline.length > 0) {
      return Math.round(
        cloud.status_timeline[cloud.status_timeline.length - 1].disk,
      );
    }

    return null;
  }, [cloud.status_timeline]);

  const getColor = useCallback(value => {
    if (!value) return theme.interface4;

    if (value <= 50) {
      return theme.successNew;
    }
    if (value <= 65) {
      return theme.favoriteNew;
    }
    if (value < 80) {
      return theme.alertNew;
    }
    if (value >= 80) {
      return theme.errorNew;
    }
  }, []);

  const memColor = useMemo(() => {
    return getColor(mem);
  }, [mem]);

  const diskColor = useMemo(() => {
    return getColor(disk);
  }, [disk]);

  return (
    <Styles.Wrapper
      selected={selected}
      onClick={onClick}
      recommended={recommended}
    >
      {recommended && (
        <Styles.Recommended>
          <FontAwesomeIcon icon={faCrown} />
          <span>Recomendado</span>
        </Styles.Recommended>
      )}
      <Styles.InfoWrapper
        onMouseEnter={() => setOpenInfo(true)}
        onMouseLeave={() => setOpenInfo(false)}
      >
        <Styles.Info />
        {openInfo && (
          <Styles.InfoPopover>
            <h4>Cloud {cloud.cloud_size.name}</h4>
            <span>Memória</span>
            <p>{cloud.cloud_size.memory}</p>
            <span>CPU</span>
            <p>
              {cloud.cloud_size.cores === 1
                ? '1 Core'
                : `${cloud.cloud_size.cores} Cores`}
            </p>
            <span>Disco</span>
            <p>{cloud.cloud_size.disk}</p>
            <Styles.HorizontalSeparator style={{ width: '100%' }} />
            <span>Aplicações instaladas</span>
            <p>{cloud.websites}</p>
          </Styles.InfoPopover>
        )}
      </Styles.InfoWrapper>
      <Styles.Section>
        <Styles.Icon>
          <img src={cloudez_icon} width="80px" alt="logo" />
        </Styles.Icon>
        <Styles.Title>{cloud.nickname || cloud.name}</Styles.Title>
        <Styles.Subtitle>
          {cloud.cloud_size.name} {cloud.cloud_size.disk}
        </Styles.Subtitle>
      </Styles.Section>
      <Styles.VerticalSeparator />
      <Styles.Section values>
        <Styles.CloudValue memory color={memColor}>
          <span>Memória</span>
          <div className="value" data-title="Média das últimas 5 análises">
            {`${mem || '--'}%`}
          </div>
        </Styles.CloudValue>
        <Styles.HorizontalSeparator />
        <Styles.CloudValue color={diskColor}>
          <span>Disco</span>
          <div className="value" data-title="Espaço ocupado em disco">
            {`${disk || '--'}%`}
          </div>
        </Styles.CloudValue>
      </Styles.Section>
    </Styles.Wrapper>
  );
}
