import { Reducer } from 'react';
import { IActionPayload } from 'types';
import { createPagination } from '@cloudez/cloudez-design-system';

const reducer: Reducer<any, IActionPayload> = (state, action): any => {
  switch (action.type) {
    case 'GET_MIGRATIONS':
      const migrations = action.payload.results;

      const pagination = createPagination(action.payload, 10);

      return {
        ...state,
        ...pagination,
        migrations,
      };

    case 'RATING_MIGRATION': {
      return {
        ...state,
        migrations: state?.migrations?.map(migration =>
          migration.id === action.payload.id
            ? {
                ...migration,
                rating: action.payload.rating,
              }
            : migration,
        ),
      };
    }

    case 'SET_LOADING': {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case 'SET_ERRORS': {
      return {
        ...state,
        errors: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
