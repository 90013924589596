import api from 'services/api';
import axios from 'axios';

export const getCepService = async cep => {
  try {
    return await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
  } catch (e) {
    throw e;
  }
};

export const getCreditcardService = async () => {
  try {
    return await api.get(`/v3/creditcard/`);
  } catch (e) {
    throw e;
  }
};

export const createCreditcardService = async data => {
  try {
    return await api.post('/v3/creditcard/', data);
  } catch (e) {
    throw e;
  }
};

export const deleteCreditcardService = async id => {
  try {
    return await api.delete(`/v3/creditcard/${id}/`);
  } catch (e) {
    throw e;
  }
};

export const getPlanTypesService = async params => {
  try {
    return await api.get('/v3/plan/type/', { params });
  } catch (e) {
    throw e;
  }
};

export const getPlanTypesServiceV3 = async params => {
  try {
    return await api.get('/v3/plan/type/', { params });
  } catch (e) {
    throw e;
  }
};

export const getDashboardService = async id => {
  try {
    return await api.get(`/v3/dashboard-summary/${id}/`);
  } catch (e) {
    throw e;
  }
};

export const getCompanyBillingService = async company => {
  try {
    return await api.get(`/v3/company/billing/${company}/`);
  } catch (e) {
    throw e;
  }
};

export const shareResourceService = async (type, id, email) => {
  try {
    return await api.post(`/v3/${type}/${id}/share/`, {
      email,
    });
  } catch (e) {
    throw e;
  }
};

export const unshareResourceService = async (type, id, email) => {
  try {
    return await api.post(`/v3/${type}/${id}/unshare/`, {
      email,
    });
  } catch (e) {
    throw e;
  }
};

export const getResourceService = async (type, id) => {
  try {
    return await api.get(`/v3/${type}/${id}/`);
  } catch (e) {
    throw e;
  }
};
