import styled, { css } from 'styled-components';

interface ContainerProps {
  disabled?: boolean;
}

export const Container = styled.div<ContainerProps>`
  input {
    cursor: pointer;
    position: relative;
    appearance: none;
    outline: none;
    width: 28px;
    height: 13px;
    border: 1px solid
      ${props =>
        props.theme.darkMode ? props.theme.interface6 : props.theme.interface4};
    border-radius: 50px;
    box-shadow: inset -20px 0 0 0 ${props => props.theme.interface2};

    &:after {
      content: '';
      position: absolute;
      top: 1px;
      left: 1px;
      width: 9px;
      height: 9px;
      background-color: ${props => props.theme.interface5};
      box-shadow: 0px 1px 4px ${props => props.theme.interface2};
      border-radius: 50%;
      transition: all 0.2s ease-in-out;
    }

    &:checked {
      border-color: ${props => props.theme.brand_primary_color};
      box-shadow: inset 28px 0 0 0 ${props => props.theme.brand_secondary_color};
    }

    &:checked:after {
      left: 16px;
      background-color: ${props => props.theme.brand_primary_color};
    }

    ${props =>
      props.disabled &&
      css`
        opacity: 0.4;
      `}
  }

  .error {
    display: block;
    font-size: 10px;
    color: ${props => props.theme.error};
    position: relative;
    white-space: nowrap;
  }
`;
