import React, { useEffect, useState } from 'react';

import { createPagination, Pagination } from '@cloudez/cloudez-design-system';
import { ListingHeader } from 'components/Listing/styles';
import { AnimatedList } from 'react-animated-list';
import { Col, Row } from 'react-bootstrap';
import { getWebsitesService } from 'services/website';
import { getEmailsService } from 'services/email';
import { getInvoicesService } from 'services/invoice';
import WebsitePlaceholder from './Placeholder';
import WebsiteCard from './WebsiteCard';

export const Websites = ({ cloud, cloudInfo }) => {
  const [loading, setLoading] = useState(false);

  const [websites, setWebsites] = useState([] as any);
  // const [emails, setEmails] = useState([] as any);
  // const [invoices, setInvoices] = useState([] as any);

  const getWebsitesCallback = async page => {
    setLoading(true);
    try {
      const { data } = await getWebsitesService({
        cloud: cloud.id,
        page,
        page_size: 5,
      });

      const pagination = createPagination(data, 5);

      setWebsites({ results: data.results, ...pagination });
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  // const getInvoicesCallback = async page => {
  //   setLoading(true);
  //   try {
  //     const { data } = await getInvoicesService({
  //       cloud: cloud.id,
  //       status: 0,
  //       page,
  //       page_size: 5,
  //     });

  //     const pagination = createPagination(data, 5);

  //     setInvoices({ results: data.results, ...pagination });
  //     setLoading(false);
  //   } catch (e) {
  //     setLoading(false);
  //   }
  // };

  // const getEmailsCallback = async page => {
  //   setLoading(true);
  //   try {
  //     const { data } = await getEmailsService({
  //       clouds: cloud.id,
  //       page,
  //       page_size: 5,
  //     });

  //     const pagination = createPagination(data, 5);

  //     setEmails({ results: data.results, ...pagination });
  //     setLoading(false);
  //   } catch (e) {
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    getWebsitesCallback(1);
    // getEmailsCallback(1);
    // getInvoicesCallback(1);
  }, []);
  return (
    <>
      {loading ? (
        <WebsitePlaceholder />
      ) : websites?.results?.length ? (
        <>
          <ListingHeader>
            <Row>
              <Col style={{ maxWidth: '3.333%' }} xs="10" md="6" xl="1">
                <p>APP</p>
              </Col>
              <Col xs="10">
                <p>DOMÍNIO</p>
              </Col>
              {(Number(cloudInfo?.mem) > 80 ||
                Number(cloudInfo?.disk) > 80 ||
                Number(cloudInfo?.cpu) > 80) && (
                <Col xs="1">
                  <p>AÇÕES</p>
                </Col>
              )}
            </Row>
          </ListingHeader>

          <AnimatedList initialAnimationDuration={800} animation="grow">
            {websites?.results?.map(website => (
              <WebsiteCard
                cloudInfo={cloudInfo}
                key={website.id}
                website={website}
              />
            ))}
          </AnimatedList>

          <Pagination
            onChangePage={getWebsitesCallback}
            count={websites.count}
            previous={websites.previous}
            next={websites.next}
            current={websites.current}
            pageSize={10}
          />
        </>
      ) : null}

      {!loading && websites?.results?.length === 0 && (
        <p>Nenhum website encontrado.</p>
      )}
    </>
  );
};
