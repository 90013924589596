import { Reducer } from 'react';
import { IActionPayload } from 'types';

const reducer: Reducer<any, IActionPayload> = (state, action): any => {
  switch (action.type) {
    case 'GET_INVOICE':
      return {
        ...state,
        invoice: action.payload,
      };

    case 'GET_USER_BILLING':
      return {
        ...state,
        userBilling: action.payload,
      };

    case 'GET_COMPANY_BILLING':
      return {
        ...state,
        companyBilling: action.payload,
      };

    case 'GET_USER':
      return {
        ...state,
        user: action.payload,
      };

    case 'GET_CREDIT_CARD':
      return {
        ...state,
        creditCard: action.payload,
      };

    case 'SET_LOADING': {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case 'SET_ERRORS': {
      return {
        ...state,
        errors: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
