import React, { useEffect, useContext, useState } from 'react';

import cloudez_icon from 'assets/img/svg/cloud_cloudez.svg';

import { Row, Col } from 'react-bootstrap';

import SearchBar from 'components/SearchBar';
import {
  Pagination,
  Loading,
  createPagination,
} from '@cloudez/cloudez-design-system';
import { getCloudsService } from 'services/cloud';
import { EmailCreateContext } from '../_context/state';
import { Wrapper, CloudCard, Content, Logo } from './styles';

const Cloud: React.FC = () => {
  const { updateField, newEmail } = useContext(EmailCreateContext);

  const [cloud, setCloud] = useState(null);
  const [search, setSearch] = useState(null);
  const [cloudLoading, setCloudLoading] = useState(false);

  const getCloudsCallback = async (page, search) => {
    setCloudLoading(true);
    try {
      const { data } = await getCloudsService({
        page_size: 12,
        page,
        search,
      });

      const pagination = createPagination(data, 12);
      setCloud({ clouds: data.results, ...pagination });
      setCloudLoading(false);
    } catch (e) {
      setCloudLoading(false);
    }
  };

  useEffect(() => {
    getCloudsCallback(1, ''); // eslint-disable-next-line
  }, []);

  const searchValue = searchValue => {
    getCloudsCallback(1, searchValue);
  };

  return (
    <Wrapper>
      <Row>
        <SearchBar
          callback={searchValue}
          noResults={cloud?.clouds?.length === 0}
          value={search}
        />
      </Row>
      {cloudLoading ? (
        <Loading />
      ) : (
        <>
          <Row
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {cloud?.clouds?.map(c => (
              <Col
                key={c.id}
                lg="2"
                md="3"
                sm="4"
                xs="6"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CloudCard
                  key={c.id}
                  selected={c.id === newEmail.cloud}
                  onClick={() => updateField('cloud', c.id)}
                >
                  <Content>
                    <Logo>
                      <img src={cloudez_icon} width="45px" alt="logo" />
                    </Logo>
                  </Content>
                  <span>{c.nickname || c.name}</span>
                  <p>
                    {c.cloud_size.name} {c.cloud_size.disk}
                  </p>
                </CloudCard>
              </Col>
            ))}
          </Row>
          <Row>
            <Pagination
              onChangePage={getCloudsCallback}
              pageSize={12}
              search={search}
              count={cloud?.count}
              previous={cloud?.previous}
              next={cloud?.next}
              current={cloud?.current}
            />
          </Row>
        </>
      )}
    </Wrapper>
  );
};

export default Cloud;
