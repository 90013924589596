import React, {
  useEffect,
  useContext,
  useState,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { AnimatedList } from 'react-animated-list';
import queryString from 'query-string';

import { Col, Row } from 'react-bootstrap';

import { ListingHeader, Ordering } from 'components/Listing/styles';
import { Wrapper } from 'components/ApplicationIcon/styles';
import {
  Button,
  Pagination,
  AdvancedSearch,
} from '@cloudez/cloudez-design-system';
import { Title, Header } from 'components/Common';

import SearchBar from 'components/SearchBar';
import history from 'services/history';
import EmptyListing from 'components/EmptyListing';
import {
  FilterHover,
  Filter,
  FilterSeparator,
  FilterWrapper,
} from 'components/SearchBar/styles';
import { Value } from 'components/Acessos';
import { ThemeContext } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faStar,
  faCloud,
  faAngleUp,
  faAngleDown,
} from '@fortawesome/free-solid-svg-icons';
import FilterDropdown from 'components/CloudFilter';

import { useDocTitle } from 'hooks/docTitle';
import { useLayout } from 'hooks/layout';
import InvoiceWarning from 'pages/Dashboard/InvoiceWarning';
import {
  DatabaseListingProvider,
  DatabaseListingContext,
} from './_context/state';
import DatabaseCard from './DatabaseCard';
import DatabasePlaceholder from './Placeholder';

const Databases: React.FC = () => {
  const theme = useLayout();

  useDocTitle(`Databases — ${theme.name}`);

  const { loading, databases, count, next, previous, current, getDatabases } =
    useContext(DatabaseListingContext);

  const {
    page = 1,
    search: _search = '',
    filter = 'all',
  } = queryString.parse(history.location.search);

  const [filterValue, setFilterValue] = useState(filter);
  const [cloudFilter, setCloudFilter] = useState(false);
  const [clouds, setClouds] = useState(null);
  const [orderValue, setOrderValue] = useState({ column: '', value: 0 }); // Inicialização do orderValue com valor zero e não ter chance de rolar undefined

  const [search, setSearch] = useState(
    queryString.parse(history.location.search),
  );

  const abortControllerRef = useRef<AbortController>(new AbortController());

  const getDatabasesCallback = useCallback(
    (page, search, filter, clouds, order) => {
      abortControllerRef.current.abort();
      abortControllerRef.current = new AbortController();
      getDatabases(
        page,
        search,
        filter,
        clouds,
        order,
        abortControllerRef.current,
      );

      const _query = {
        ...search,
        page,
        filter,
        clouds,
      };

      if (!search) delete _query.search;
      if (!clouds) delete _query.clouds;
      if (filter === 'all') delete _query.filter;
      if (page === 1 || page === '1') delete _query.page;

      const query = queryString.stringify(_query);
      history.replace(`/databases?${query}`);
      // eslint-disable-next-line
    },
    [],
  );

  useEffect(() => {
    getDatabasesCallback(page, search, filter, clouds, orderValue);
    // eslint-disable-next-line
  }, []);

  const handleSetClouds = useCallback(
    clouds => {
      const _clouds = clouds.join(',');
      setClouds(_clouds);
      setCloudFilter(false);
      getDatabasesCallback(1, search, filter, _clouds, orderValue);
    },
    // eslint-disable-next-line
    [search, filter, getDatabasesCallback],
  );

  const handleSetFilter = useCallback(
    filter => {
      setFilterValue(filter);
      getDatabasesCallback(1, search, filter, clouds, orderValue);
    },
    // eslint-disable-next-line
    [search, clouds, getDatabasesCallback],
  );

  const searchValue = useCallback(
    searchValue => {
      getDatabasesCallback(1, searchValue, filterValue, clouds, orderValue);
    },
    // eslint-disable-next-line
    [filterValue, clouds],
  );

  const cloudColor = useMemo(
    () =>
      cloudFilter || clouds
        ? theme.brand_primary_color
        : theme.darkMode
        ? theme.interface5
        : theme.interface4,
    [cloudFilter, clouds, theme],
  );

  const favoriteColor = useMemo(
    () =>
      filterValue === 'favorite'
        ? theme.favoriteNew
        : theme.darkMode
        ? theme.interface5
        : theme.interface4,
    [filterValue, theme],
  );

  const order = column => {
    // Ajuste na lógica de ordenação
    const newOrderValue =
      orderValue.column === column
        ? {
            column,
            value: orderValue.value === 1 ? 2 : 1,
          }
        : {
            column,
            value: 1,
          };
    setOrderValue(newOrderValue);
    getDatabasesCallback(1, search, filter, clouds, newOrderValue);
  };

  return (
    <>
      <Header>
        <Title>Databases</Title>
        <Button onClick={() => history.push('/databases/create')}>
          <span>Novo database</span>
        </Button>
      </Header>
      <InvoiceWarning />

      <SearchBar noInput>
        <AdvancedSearch
          style={{ width: '400px' }}
          callback={searchValue}
          value={search}
          setValue={setSearch}
          noResults={false}
          fields={[
            { label: 'Qualquer campo', value: 'search' },
            { label: 'Nome do banco', value: 'database_name' },
            { label: 'Nome do cloud', value: 'cloud_name' },
            { label: 'Fqdn do cloud', value: 'cloud_fqdn' },
            { label: 'Email', value: 'email' },
          ]}
        />
        <FilterWrapper>
          <Filter>
            <FontAwesomeIcon
              icon={faCloud}
              color={cloudColor}
              onClick={() => setCloudFilter(!cloudFilter)}
            />
            <FilterHover>
              <Value>
                <span>Cloud</span>
              </Value>
            </FilterHover>
            <FilterDropdown
              show={cloudFilter}
              setShow={setCloudFilter}
              callback={handleSetClouds}
            />
          </Filter>
          <FilterSeparator />
          <Filter>
            <FontAwesomeIcon
              icon={faStar}
              color={favoriteColor}
              onClick={() =>
                filterValue === 'favorite'
                  ? handleSetFilter('all')
                  : handleSetFilter('favorite')
              }
            />
            <FilterHover>
              <Value>
                <span>Favoritos</span>
              </Value>
            </FilterHover>
          </Filter>
        </FilterWrapper>
      </SearchBar>

      {loading ? (
        <DatabasePlaceholder />
      ) : databases?.length ? (
        <>
          <ListingHeader>
            <Row>
              <Col xs="10" sm="10" md="5">
                <Wrapper>
                  <p onClick={() => order('database_name')}>
                    Aplicação
                    <Ordering
                      status={
                        orderValue?.column === 'database_name'
                          ? orderValue?.value
                          : 0
                      }
                    >
                      <FontAwesomeIcon
                        icon={orderValue.value === 2 ? faAngleUp : faAngleDown}
                        style={{ marginTop: '2px' }}
                      />
                    </Ordering>
                  </p>
                </Wrapper>
              </Col>

              <Col className="d-none d-xl-flex">
                <p onClick={() => order('cloud')}>
                  Cloud
                  <Ordering
                    status={
                      orderValue?.column === 'cloud' ? orderValue?.value : 0
                    }
                  >
                    <FontAwesomeIcon
                      icon={orderValue.value === 2 ? faAngleUp : faAngleDown}
                      style={{ marginTop: '2px' }}
                    />
                  </Ordering>
                </p>
              </Col>

              <Col className="d-none d-md-flex">
                <p onClick={() => order('disk')}>
                  Disco
                  <Ordering
                    status={
                      orderValue?.column === 'disk' ? orderValue?.value : 0
                    }
                  >
                    <FontAwesomeIcon
                      icon={orderValue.value === 2 ? faAngleUp : faAngleDown}
                      style={{ marginTop: '2px' }}
                    />
                  </Ordering>
                </p>
              </Col>

              <Col xl="2" className="d-none d-xl-flex" />
            </Row>
          </ListingHeader>

          <AnimatedList initialAnimationDuration={800} animation="grow">
            {databases.map(database => (
              <DatabaseCard database={database} key={database.id} />
            ))}
          </AnimatedList>

          <Pagination
            onChangePage={getDatabasesCallback}
            pageSize={10}
            count={count}
            previous={previous}
            next={next}
            current={current}
            filter={filterValue}
            clouds={clouds}
            search={search}
            order={orderValue}
          />
        </>
      ) : null}

      {!loading && databases?.length === 0 && (
        <EmptyListing>
          <p>Nenhum database encontrado.</p>
          <Button onClick={() => history.push('/databases/create')} size="lg">
            Criar novo database
          </Button>
        </EmptyListing>
      )}
    </>
  );
};

export default props => (
  <DatabaseListingProvider>
    <Databases {...props} />
  </DatabaseListingProvider>
);
