import styled, { css } from 'styled-components';

interface DotProps {
  done?: boolean;
}

export const Dot = styled.div<DotProps>`
  position: relative;

  width: 28px;
  height: 28px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;

  color: ${props => props.theme.interface1};

  font-weight: bold;
  font-size: 13px;

  svg {
    color: ${props => props.theme.interface1};
  }

  border: 2px solid ${props => props.theme.successNew};

  ${props =>
    !props.done &&
    css`
      color: ${props => props.theme.successNew};
    `}

  background-color: ${props => props.done && props.theme.successNew};
`;

export const Line = styled.div<DotProps>`
  height: 2px;
  width: calc((100% / 3) - 40px);
  background-color: ${props => props.theme.successNew};
`;

export const ProgressBar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const ProgressContainer = styled.div`
  min-width: 50%;
  width: auto;
  @media (max-width: 768px) {
    width: 80%;
  }
`;

export const StatusLabel = styled.span`
  position: absolute;

  width: 200px;
  text-align: center;

  color: ${props => props.theme.interface5};

  bottom: -20px;
`;
