import React, { useEffect, useState } from 'react';

import cloudezIcon from 'assets/img/svg/cloud_cloudez.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useLayout } from 'hooks/layout';
import { Col, Row } from 'react-bootstrap';
import { ContentWrapper, StageButton, Subtitle, Title } from '../styles';
import Application from './Application';
import FTP from './FTP';
import Finish from './Finish';
import { Destination, FreeTrialCloud, Origin, Section } from './styles';

const Migration = ({
  newMigration,
  setNewMigration,
  createMigration,
  migrationErrors,
  loading,
  nextStage: _nextStage,
  cloud,
  validDomain,
  isValidating,
  checkIsHosted,
}) => {
  const { name } = useLayout();

  return (
    <>
      <ContentWrapper>
        <Title>Criar cópia</Title>

        <Subtitle style={{ width: '50%', margin: 'auto' }}>
          A melhor forma de migrar projetos de um servidor para outro, é criando
          uma cópia deles. Aqui na {name} você não perde tempo com processos
          repetitivos.
        </Subtitle>

        <div
          className="my-5 wrapper"
          // style={{ display: 'flex', justifyContent: 'space-between' }}
        >
          {/* <Origin></Origin> */}

          <Row>
            <Col md={6}>
              <Section>ORIGEM</Section>

              <Subtitle style={{ textAlign: 'left' }}>
                Informe o seu domínio, e nós identificamos onde seu site está
                hospedado atualmente. Caso ele não esteja na nossa base de
                dados, basta você fornecer os dados de FTP
              </Subtitle>
            </Col>

            <Col md={6}>
              <Section>DESTINO</Section>

              <Subtitle style={{ textAlign: 'left' }}>
                A cópia do seu projeto ficará salva no cloud abaixo. Esse é o
                cloud que ficará liberado para você durante o período de testes.
              </Subtitle>
            </Col>

            {/* <Destination>
              
            </Destination> */}
          </Row>

          <Row>
            <Col md={6}>
              <Application
                _nextStage={_nextStage}
                newMigration={newMigration}
                migrationErrors={migrationErrors}
                setNewMigration={setNewMigration}
              />

              {validDomain && (
                <FTP
                  _nextStage={_nextStage}
                  migrationErrors={migrationErrors}
                  newMigration={newMigration}
                  setNewMigration={setNewMigration}
                  validDomain={validDomain}
                />
              )}
            </Col>
            <Col xs={6}>
              <FreeTrialCloud>
                <img src={cloudezIcon} alt="cloudez" />
                <p>{cloud?.name}</p>
                <span>Trial Cloud</span>
              </FreeTrialCloud>

              <div style={{ marginTop: '20px' }} className="d-flex">
                <StageButton
                  onClick={_nextStage}
                  outline
                  disabled={isValidating || loading}
                  className="mr-4"
                >
                  Pular
                </StageButton>
                <StageButton
                  disabled={
                    loading ||
                    isValidating ||
                    (validDomain
                      ? !newMigration.host ||
                        !newMigration.domain ||
                        !newMigration.user ||
                        !newMigration.password
                      : !newMigration.domain)
                  }
                  onClick={
                    validDomain
                      ? () => createMigration()
                      : () => checkIsHosted()
                  }
                >
                  {isValidating || loading ? (
                    <FontAwesomeIcon
                      style={{ margin: 0 }}
                      icon={faSpinner}
                      spin
                    />
                  ) : validDomain ? (
                    'Criar cópia'
                  ) : (
                    'Validar domínio'
                  )}
                </StageButton>
              </div>
            </Col>
          </Row>
        </div>
      </ContentWrapper>
    </>
  );
};

export default Migration;
