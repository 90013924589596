import history from 'services/history';
import {
  getMigrationsService,
  migrationChangePinnedService,
} from 'services/migration';

export const setLoading = (dispatch, value) => {
  dispatch({
    type: 'SET_LOADING',
    payload: value,
  });
};

export const getMigrations = async (dispatch, page, search, filter, ctrl) => {
  setLoading(dispatch, true);
  try {
    const { data } = await getMigrationsService(
      { ...search, page, filter },
      ctrl,
    );

    dispatch({
      type: 'GET_MIGRATIONS',
      payload: data,
    });

    setLoading(dispatch, false);
  } catch (e) {
    if (e.message !== 'canceled') setLoading(dispatch, false);
    if (e?.response?.status === 404) {
      history.push('/404');
    }
  }
};

export const changePinned = async (dispatch, id) => {
  try {
    const { data } = await migrationChangePinnedService(id);

    dispatch({
      type: 'CHANGE_PINNED',
      payload: { id, data },
    });
  } catch (e) {
    dispatch({
      type: 'UPDATE_MIGRATION_ERROR',
      payload: e?.response?.data,
    });
  }
};
