import { Dispatch } from 'react';
import { toast } from 'react-toastify';
import history from 'services/history';
import {
  getEmailService,
  deleteEmailService,
  updateEmailService,
} from 'services/email';
import nProgress from 'nprogress';
import {
  createTicketMessageService,
  getMessagesService,
  getTicketsService,
} from 'services/ticket';
import { IActionPayload } from 'types';
import { EmailDetailState } from './state';

export const setLoading = (
  dispatch: Dispatch<IActionPayload>,
  value: any,
): void => {
  dispatch({
    type: 'SET_LOADING',
    payload: value,
  });
};

export const getEmail = async (
  dispatch: Dispatch<IActionPayload>,
  id: string | number,
): Promise<void> => {
  nProgress.start();
  try {
    const { data } = await getEmailService(id);

    dispatch({
      type: 'GET_EMAIL',
      payload: data,
    });

    // dispatch({
    //   type: 'SET_ORIGINAL_EMAIL',
    //   payload: data,
    // });

    getTicket(dispatch, data.id);

    nProgress.done();
  } catch (e) {
    nProgress.done();
    if (e?.response?.status === 404) {
      toast.error('Email não encontrado');
      history.push('/404');
    }
  }
};

export const getTicket = async (
  dispatch: Dispatch<IActionPayload>,
  id: string | number,
): Promise<void> => {
  try {
    const {
      data: { results },
    } = await getTicketsService({
      target: `3,${id}`,
    });

    if (results.length > 0) {
      const [ticket] = results;

      dispatch({
        type: 'GET_TICKET',
        payload: ticket,
      });

      getMessages(dispatch, ticket.id);
    }
  } catch (e) {
    nProgress.done();
  }
};

export const getMessages = async (
  dispatch: Dispatch<IActionPayload>,
  id: string | number,
): Promise<void> => {
  try {
    const { data } = await getMessagesService({
      ticket: id,
    });

    getFiles(dispatch, data);

    dispatch({
      type: 'GET_MESSAGES',
      payload: data,
    });
    nProgress.done();
  } catch (e) {
    nProgress.done();
  }
};

export const getFiles = (
  dispatch: Dispatch<IActionPayload>,
  data: any,
): void => {
  const _files = data.filter(d => d.file).map(d => d.file);

  dispatch({
    type: 'GET_FILES',
    payload: _files,
  });
};

export const addMessage = async (
  dispatch: Dispatch<IActionPayload>,
  _data: any,
): Promise<void> => {
  try {
    const { data } = await createTicketMessageService(_data);

    getMessages(dispatch, data.ticket);
  } catch (e) {
    toast.error(e?.response?.data[Object.keys(e?.response?.data)[0]][0]);
  }
};

export const deleteEmail = async (
  dispatch: Dispatch<IActionPayload>,
  id: string | number,
): Promise<void> => {
  try {
    const { data } = await deleteEmailService(id);

    dispatch({
      type: 'DELETE_EMAIL',
      payload: data,
    });
    toast.success('Email removido com sucesso');
    history.push('/emails');
  } catch (e) {
    if (e?.response?.status === 404) {
      toast.error('Email não encontrado');
      history.push('/404');
    }
  }
};

export const patchEmail = async (
  dispatch: Dispatch<IActionPayload>,
  field: string,
  value: any,
  id: string | number,
): Promise<void> => {
  setLoading(dispatch, true);
  try {
    const { data } = await updateEmailService(id, {
      [field]: value,
    });

    dispatch({
      type: 'PATCH_EMAIL',
      payload: data,
    });

    setLoading(dispatch, false);
    toast.success('Suas alterações estão sendo aplicadas.');
  } catch (error) {
    dispatch({
      type: 'SET_ERRORS',
      payload: error?.response?.data,
    });

    setLoading(dispatch, false);
    toast.error('Algo não está certo.');
  }
};

export const updateValue = (
  state: EmailDetailState,
  dispatch: Dispatch<IActionPayload>,
  e: any,
): void => {
  const values = state.email.values.map(_value => {
    if (_value.slug === e.target.name) {
      _value.value =
        e.target.type === 'checkbox'
          ? e.target.checked.toString()
          : e.target.value;
      return _value;
    }

    return _value;
  });

  if (e.target.name === 'domain') {
    dispatch({
      type: 'UPDATE_VALUE',
      payload: {
        ...state.email,
        values,
        domain: e.target.value,
      },
    });
  } else {
    dispatch({
      type: 'UPDATE_VALUE',
      payload: {
        ...state.email,
        values,
      },
    });
  }
};

export const updateField = (
  state: EmailDetailState,
  dispatch: Dispatch<IActionPayload>,
  field: string,
  value: any,
): void => {
  dispatch({
    type: 'UPDATE_FIELD',
    payload: {
      ...state.email,
      [field]: value,
    },
  });
};
