import styled, { css } from 'styled-components';

interface CardProps {
  selected?: boolean;
}
export const Card = styled.div<CardProps>`
  cursor: pointer;
  background: ${props => props.theme.interface2};
  width: 100%px;
  height: 50px;
  border-radius: 5px;
  margin-bottom: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border: 2px solid transparent;

  &:hover {
    border: 2px solid
      ${props =>
        props.theme.darkMode
          ? props.theme.interface5
          : props.theme.brand_primary_color};
  }

  ${props =>
    props.selected &&
    css`
      border: 2px solid
        ${props =>
          props.theme.darkMode
            ? props.theme.interface5
            : props.theme.brand_primary_color};
    `}
`;
