import React, { useEffect, useState } from 'react';

import { createPagination, Pagination } from '@cloudez/cloudez-design-system';
import { ListingHeader } from 'components/Listing/styles';
import { AnimatedList } from 'react-animated-list';
import { Col, Row } from 'react-bootstrap';
import { getEmailsService } from 'services/email';
import WebsitePlaceholder from './Placeholder';
import EmailCard from './EmailCard';

export const Emails = ({ cloud, cloudInfo }) => {
  const [loading, setLoading] = useState(false);

  const [emails, setEmails] = useState([] as any);

  const getEmailsCallback = async page => {
    setLoading(true);
    try {
      const { data } = await getEmailsService({
        clouds: cloud.id,
        page,
        page_size: 5,
      });

      const pagination = createPagination(data, 5);

      setEmails({ results: data.results, ...pagination });
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getEmailsCallback(1);
  }, []);
  return (
    <>
      {loading ? (
        <WebsitePlaceholder />
      ) : emails?.results?.length ? (
        <>
          <ListingHeader>
            <Row>
              <Col style={{ maxWidth: '3.333%' }} xs="10" md="6" xl="1">
                <p>APP</p>
              </Col>
              <Col xs="10">
                <p>DOMÍNIO</p>
              </Col>
              {Number(cloudInfo?.mem) > 80 && (
                <Col xs="1">
                  <p>AÇÕES</p>
                </Col>
              )}
            </Row>
          </ListingHeader>

          <AnimatedList initialAnimationDuration={800} animation="grow">
            {emails?.results?.map(email => (
              <EmailCard cloudInfo={cloudInfo} key={email.id} email={email} />
            ))}
          </AnimatedList>

          <Pagination
            onChangePage={getEmailsCallback}
            count={emails.count}
            previous={emails.previous}
            next={emails.next}
            current={emails.current}
            pageSize={10}
          />
        </>
      ) : null}

      {!loading && emails?.results?.length === 0 && (
        <p>Nenhum email encontrado.</p>
      )}
    </>
  );
};
