import { Reducer } from 'react';
import { IActionPayload } from 'types';

const reducer: Reducer<any, IActionPayload> = (state, action): any => {
  switch (action.type) {
    case 'GET_MIGRATION':
    case 'PATCH_MIGRATION':
    case 'UPDATE_FIELD':
      return {
        ...state,
        migration: action.payload,
      };

    case 'GET_SYNCS':
      return {
        ...state,
        syncs: action.payload,
      };

    case 'SET_LOADING': {
      return {
        ...state,
        updateLoading: action.payload,
      };
    }

    case 'SET_ERRORS': {
      return {
        ...state,
        errors: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
