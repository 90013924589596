import React, { InputHTMLAttributes, ReactComponentElement } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { Container } from './styles';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  register?: UseFormRegister<any>;
  required?: boolean;
  error?: any;
  label?: any;
}

export const CustomRadio = ({
  name,
  register,
  required,
  error,
  label,
  style,
  ...rest
}: InputProps): JSX.Element => {
  return (
    <Container id="radioCheckbox" style={style}>
      <div
        style={{
          display: 'inline-flex',
          flexDirection: 'row',
          alignItems: 'center',
          gap: '5px',
        }}
      >
        {register ? (
          <input type="checkbox" {...register(name, { required })} {...rest} />
        ) : (
          <input type="checkbox" {...rest} />
        )}
        {label}
        {error && <span className="error">{error}</span>}
      </div>
    </Container>
  );
};
