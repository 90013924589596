import React, { useState, useContext } from 'react';
import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faSpinner } from '@fortawesome/free-solid-svg-icons';

import {
  Modal,
  Button,
  Input,
  Label,
  Field,
  Error,
  Value,
} from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Content,
  Footer,
} from 'components/ListingModal/styles';
import {} from 'components/Acessos';

import { refreshBankslipService } from 'services/invoice';
import { ModalProps } from 'types';
import { useLayout } from 'hooks/layout';
import { InvoiceListingContext } from '../_context/state';

interface DateModal extends ModalProps {
  invoice: any;
}
const DateModal: React.FC<DateModal> = ({ show, setShow, invoice }) => {
  const { updateInvoice } = useContext(InvoiceListingContext);
  const [loading, setLoading] = useState(false);

  const theme = useLayout();

  const createdAt = moment(invoice?.created_at).format('DD/MM/YYYY');
  const expiredAt = moment(invoice?.expired_at).format('DD/MM/YYYY');

  const startChargingAt = moment(invoice?.start_charging_at).format(
    'YYYY-MM-DD',
  );

  const maxDate = moment(invoice?.start_charging_at)
    .add(10, 'days')
    .format('YYYY-MM-DD');

  const [date, setDate] = useState(
    moment(invoice?.expired_at).format('YYYY-MM-DD'),
  );

  const [error, setError] = useState(null);

  const changeDate = async () => {
    setLoading(true);
    // setError(null);
    try {
      await updateInvoice('expired_at', date, invoice.id);
      await refreshBankslipService(invoice.id);

      setError(null);
      setShow();
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setError(e?.response?.data);
    }
  };

  return (
    <Modal show={show} setShow={setShow} noExit={loading}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faCalendar} />
        </Icon>
        <Text>
          <Title>Mudar data da fatura</Title>
        </Text>
      </Header>

      <Content>
        <Field>
          <Label>Data de criação</Label>
          <Value>{createdAt}</Value>
        </Field>
        <Field>
          <Label>Data de expiração</Label>
          <Value>{expiredAt}</Value>
        </Field>
        <Field>
          <Label>Nova data de expiração:</Label>
          <Input
            style={{
              paddingRight: '30px',
            }}
            type="date"
            min={startChargingAt}
            max={maxDate}
            value={date}
            onChange={e => setDate(e.target.value)}
            background
            block
            error={error?.[Object.keys(error)[0]]?.[0]}
          />
          {error?.[Object.keys(error)[0]]?.[0] && (
            <Error>{error?.[Object.keys(error)[0]]?.[0]}</Error>
          )}
          {/* <FontAwesomeIcon icon={faCalendar} /> */}
        </Field>
        <span
          style={{
            fontSize: '16px',
            color: theme.interface5,
          }}
        >
          <b
            style={{
              color: theme.alertNew,
            }}
          >
            Atenção:
          </b>{' '}
          A troca da data de expiração não desbloqueia automaticamente os
          recursos do cloud.
        </span>
      </Content>
      <Footer>
        <Button onClick={changeDate} icon disabled={loading}>
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Mudar'}
        </Button>
      </Footer>
    </Modal>
  );
};

export default DateModal;
