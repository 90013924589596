import styled from 'styled-components';

export const Container = styled.div`
  background: ${props => props.theme.errorNew};
  text-align: center;
  color: ${props => props.theme.interface1};

  &:hover {
    svg {
      color: ${props => props.theme.interface1} !important;
    }
  }

  svg {
    color: ${props => props.theme.interface1};
  }

  padding: 0px 1px;

  left: -24px;
  top: -1px;
  position: absolute;

  box-shadow: 0px 2px 15px rgba(100, 114, 140, 0.25);
  border-radius: 5px;
`;
