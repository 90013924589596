export default (state, action) => {
  switch (action.type) {
    case 'UPDATE_PLAN':
      return {
        ...state,
        plan: action.payload,
      };

    case 'SET_LOADING': {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case 'GET_PLANS': {
      return {
        ...state,
        plans: action.payload,
      };
    }
    case 'GET_BASE_PLANS': {
      return {
        ...state,
        basePlans: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};
