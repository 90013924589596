import { Reducer } from 'react';
import { IActionPayload } from 'types';
import { EmailDetailState } from './state';

const reducer: Reducer<EmailDetailState, IActionPayload> = (
  state,
  action,
): EmailDetailState => {
  switch (action.type) {
    case 'GET_EMAIL':
    case 'UPDATE_VALUE':
    case 'UPDATE_FIELD':
    case 'PATCH_EMAIL':
      return {
        ...state,
        email: action.payload,
      };

    case 'SET_LOADING': {
      return {
        ...state,
        updateLoading: action.payload,
      };
    }

    case 'GET_TICKET':
      return {
        ...state,
        ticket: action.payload,
      };

    case 'GET_MESSAGES':
      return {
        ...state,
        messages: action.payload,
      };

    case 'GET_FILES':
      return {
        ...state,
        files: action.payload,
      };

    case 'SET_ERRORS': {
      return {
        ...state,
        errors: action.payload,
      };
    }

    // case 'SET_ORIGINAL_EMAIL': {
    //   return {
    //     ...state,
    //     originalEmail: JSON.parse(JSON.stringify(action.payload)),
    //   };
    // }

    default: {
      return state;
    }
  }
};

export default reducer;
