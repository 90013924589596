import { Reducer } from 'react';
import { IActionPayload } from 'types';
import { createPagination } from '@cloudez/cloudez-design-system';
import { WebsiteListingState } from './state';

const reducer: Reducer<WebsiteListingState, IActionPayload> = (
  state,
  action,
): WebsiteListingState => {
  switch (action.type) {
    case 'GET_WEBSITES':
      const websites = action.payload.results;
      const pagination = createPagination(action.payload, 10);

      return {
        ...state,
        ...pagination,
        websites,
      };

    case 'UPDATE_WEBSITE': {
      return {
        ...state,
        websites: state?.websites?.map(website =>
          website.id === action.payload.id ? action.payload : website,
        ),
      };
    }

    case 'UPDATE_FIELD': {
      return {
        ...state,
        websites: state?.websites?.map(website =>
          website.id === action.payload.id
            ? {
                ...website,
                [action.payload.field]: action.payload.value,
              }
            : website,
        ),
      };
    }

    case 'SET_LOADING': {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case 'SET_ERRORS': {
      return {
        ...state,
        errors: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
