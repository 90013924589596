import React, { useCallback, useRef, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Input } from '@cloudez/cloudez-design-system';
import { Container } from './styles';

interface ISearchInputProps {
  value?: any;
  callback: any;
  setValue?: any;
  error?: any;
  loading?: boolean;
  style?: any;
  block?: boolean;
}

const SearchInput: React.FC<ISearchInputProps> = ({
  value: _value,
  setValue: _setValue,
  error,
  callback,
  loading,
  ...rest
}) => {
  const [value, setValue] = useState(_value || '');

  const callbackHandler = useCallback(() => {
    if (callback) {
      callback(value);
    }
  }, [callback, value]);

  const onChange = useCallback(e => {
    setValue(e.target.value);
    if (_setValue) {
      _setValue(e.target.value);
    }
  }, []);

  const keyDownHandler = useCallback(
    e => {
      if (e.keyCode === 13) {
        e.preventDefault();

        callbackHandler();
      }
    },
    [callbackHandler],
  );

  return (
    <Container {...rest}>
      <Input
        placeholder="Faça sua busca aqui"
        value={value}
        onChange={onChange}
        onKeyDown={keyDownHandler}
        error={error}
        {...rest}
      />
      <FontAwesomeIcon
        icon={loading ? faSpinner : faSearch}
        spin={loading}
        onClick={callbackHandler}
      />
      <span>{error && 'Sua busca não foi encontrada'}</span>
    </Container>
  );
};

export default SearchInput;
