import React, { useState, useEffect, useMemo } from 'react';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';

import { Title, Header } from 'components/Common';

import { Row, Col } from 'react-bootstrap';

import { ThemeContext } from 'styled-components';
import ApplicationIcon from 'components/ApplicationIcon';
import kbToMb from 'utils/kbToMb';

import { DomainIcon } from 'pages/Domains/DomainListing/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGlobeAmericas,
  faArrowDown,
  faRedo,
  faHourglassEnd,
} from '@fortawesome/free-solid-svg-icons';
import { getDashboardService } from 'services/common';
import nProgress from 'nprogress';
import { ReactComponent as Check } from 'assets/img/svg/check.svg';
import { useDocTitle } from 'hooks/docTitle';
import { useLayout } from 'hooks/layout';
import { useAuth } from 'hooks/auth';
import { Button } from '@cloudez/cloudez-design-system';
import FreeTrialTime from 'pages/Onboard/FreeTrialTime';
import Placeholder from './Placeholder';
import DashboardChart from './DashboardChart';
import {
  Card,
  CardHeader,
  CardBody,
  Clouds,
  Total,
  Individual,
  Statistics,
  Top,
  Value,
  Domains,
  HTTPS,
  P,
  TrialDashboard,
  TrialButtons,
  TrialButton,
  Done,
} from './styles';
import InvoiceWarning from './InvoiceWarning';

const Dashboard: React.FC = () => {
  const theme = useLayout();
  const { user, getUser } = useAuth();
  const history = useHistory();
  useDocTitle(`Dashboard — ${theme.name}`);

  const [dash, setDash] = useState(null);

  useEffect(() => {
    const getData = async () => {
      nProgress.start();
      try {
        await getUser();
        const { data } = await getDashboardService(user.id);
        setDash(data);
        nProgress.done();
      } catch (e) {
        nProgress.done();
        console.log(e);
      }
    };

    getData();
    // eslint-disable-next-line
  }, []);

  const domains = {
    total: dash?.domains.length,
    active: dash?.domains.filter(d => d.is_active).length,
  };

  const cloudStatusList = {} as any;

  // eslint-disable-next-line
  const a = dash?.clouds?.forEach(c => {
    if (c.status === 0) {
      c.status = 'carregando';
      cloudStatusList.carregando = c.total;
    } else if (c.status === 1) {
      c.status = 'reiniciando';
      cloudStatusList.reiniciando = c.total;
    } else if (c.status === 2) {
      c.status = 'parado';
      cloudStatusList.parado = c.total;
    } else if (c.status === 3) {
      c.status = 'rodando';
      cloudStatusList.rodando = c.total;
    }
  });

  const cloudsData = dash?.clouds?.map(w => w.total);
  const total =
    dash?.clouds.length > 0 &&
    cloudsData?.reduce((t, v) => {
      return t ? t + v : t + v;
    });

  const pending = dash?.status?.update;
  const restart = dash?.status?.restart;

  const freeTrialEnded = useMemo(() => {
    const today = moment();
    const endsAt = moment(user?.onboarding_data?.free_trial_ends);

    const isBefore = endsAt.isBefore(today);
    if (
      !isBefore &&
      theme.has_free_trial &&
      !user.onboarding_data?.free_trial_done &&
      !user.customer_at
    ) {
      theme.setNotification(
        'Você ainda não completou o onboarding para conseguir os 7 dias de trial.',
        'warning',
        {
          text: 'CONTRATAR',
          callback: () => {
            history.push(
              user?.onboarding_data?.invoice
                ? `/invoices/${user?.onboarding_data?.invoice}`
                : '/clouds/create',
            );
          },
          onboarding: true,
        },
      );
    }

    return isBefore;
  }, [user]);

  return dash ? (
    <>
      {theme.has_free_trial &&
        !freeTrialEnded &&
        !user.onboarding_data?.free_trial_done &&
        !user.customer_at && (
          <TrialDashboard>
            <FreeTrialTime />

            <TrialButtons>
              <div>
                {user?.onboarding_data?.nodeapp ? (
                  <Done>
                    CRIAR APLICAÇÃO <Check width="18px" height="18px" />
                  </Done>
                ) : (
                  <TrialButton
                    onClick={() => history.push('/onboard')}
                    done={!!user?.onboarding_data?.nodeapp}
                    disabled={user?.onboarding_data?.nodeapp}
                    outline={!user?.onboarding_data?.nodeapp}
                  >
                    CRIAR APLICAÇÃO
                  </TrialButton>
                )}
              </div>
              <div>
                {user?.onboarding_data?.zone_active ? (
                  <Done>
                    ASSOCIAR DOMÍNIO <Check width="18px" height="18px" />
                  </Done>
                ) : user?.onboarding_data?.zone ? (
                  <Done verifying>
                    ASSOCIAR DOMÍNIO <FontAwesomeIcon icon={faHourglassEnd} />{' '}
                  </Done>
                ) : (
                  <TrialButton
                    onClick={() => history.push('/onboard')}
                    done={!!user?.onboarding_data?.zone_active}
                    disabled={user?.onboarding_data?.zone_active}
                    outline={!user?.onboarding_data?.zone}
                  >
                    ASSOCIAR DOMÍNIO
                  </TrialButton>
                )}
              </div>
              <div>
                {user?.onboarding_data?.migration ? (
                  <Done>
                    CRIAR CÓPIA <Check width="18px" height="18px" />
                  </Done>
                ) : (
                  <TrialButton
                    onClick={() => history.push('/onboard')}
                    done={!!user?.onboarding_data?.migration}
                    disabled={user?.onboarding_data?.migration}
                    outline={!user?.onboarding_data?.migration}
                  >
                    CRIAR CÓPIA
                  </TrialButton>
                )}
              </div>

              <div>
                <Button
                  onClick={() =>
                    history.push(
                      user?.onboarding_data?.invoice
                        ? `/invoices/${user?.onboarding_data?.invoice}`
                        : '/clouds/create',
                    )
                  }
                >
                  Contratar
                </Button>
              </div>
            </TrialButtons>
          </TrialDashboard>
        )}

      <Header>
        <Title>Bem vindo, {user.full_name}</Title>
      </Header>

      <InvoiceWarning />

      <Row>
        <Col style={{ marginBottom: '14px' }} xl="6">
          <Card>
            <CardHeader>Clouds</CardHeader>
            <CardBody>
              <Clouds>
                <Total>{total}</Total>
                <Individual>
                  <span
                    style={{
                      color: theme.label,
                    }}
                  >
                    {cloudStatusList.carregando || 0} carregando
                  </span>
                  <span
                    style={{
                      color: theme.alert,
                    }}
                  >
                    {cloudStatusList.reiniciando || 0} reiniciando
                  </span>
                  <span
                    style={{
                      color: theme.success,
                    }}
                  >
                    {cloudStatusList.rodando || 0} rodando
                  </span>
                  <span
                    style={{
                      color: theme.error,
                    }}
                  >
                    {cloudStatusList.parado || 0} parados
                  </span>
                </Individual>
              </Clouds>
            </CardBody>
          </Card>
        </Col>
        <Col style={{ marginBottom: '14px' }} xl="6">
          <Card>
            <CardHeader>Avisos</CardHeader>
            <CardBody>
              <Statistics>
                <li> {dash.status.cpu} com sobrecarga de CPU</li>
                <li> {dash.status.mem} com sobrecarga de memória</li>
                <li> {dash.status.disk} com sobrecarga de disco</li>
              </Statistics>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col style={{ marginBottom: '14px' }} xl="6">
          <DashboardChart data={dash.websites} name="Tipos de Websites" />
        </Col>
        <Col style={{ marginBottom: '14px' }} xl="6">
          <Card>
            <CardHeader>Top 5 Websites - Uso de disco</CardHeader>
            <CardBody>
              {dash.top_websites.length ? (
                <>
                  <Top>
                    {dash.top_websites.map(w => (
                      <li
                        key={w.id}
                        onClick={() => history.push(`/websites/${w.id}`)}
                      >
                        <Row>
                          <Col style={{ marginBottom: '14px' }} xs="2">
                            <ApplicationIcon
                              width="30px"
                              height="30px"
                              icon={w.type}
                            />
                          </Col>
                          <Col style={{ marginBottom: '14px' }} xs="7">
                            <Value>
                              <p>{w.domain}</p>
                              <span>{w.cloud}</span>
                            </Value>
                          </Col>
                          <Col style={{ marginBottom: '14px' }} xs="3">
                            <span
                              style={{
                                fontWeight: 700,
                                color: theme.interface5,
                                fontSize: '13px',
                                marginTop: '9px',
                              }}
                            >
                              {kbToMb(w.disk_usage) || 0}
                            </span>
                          </Col>
                        </Row>
                      </li>
                    ))}
                  </Top>
                  <Link
                    style={{
                      textAlign: 'center',
                      width: '100%',
                      fontSize: '10px',
                      marginTop: '25px',
                      color: theme.interface5,
                    }}
                    to="/websites"
                  >
                    Mostrar tudo
                  </Link>
                </>
              ) : (
                <Link
                  style={{
                    textAlign: 'center',
                    fontSize: '16px',
                    marginTop: '25px',
                    color: theme.brand_primary_color,
                  }}
                  to="/websites"
                >
                  Ir para websites
                </Link>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col style={{ marginBottom: '14px' }} xl="6">
          <DashboardChart data={dash.emails} name="Tipos de Emails" />
        </Col>
        <Col style={{ marginBottom: '14px' }} xl="6">
          <Card>
            <CardHeader>Top 5 Emails - Uso de disco</CardHeader>
            <CardBody>
              {dash.top_emails.length ? (
                <>
                  <Top>
                    {dash.top_emails.map(e => (
                      <li
                        key={e.id}
                        onClick={() => history.push(`/emails/${e.id}`)}
                      >
                        <Row>
                          <Col style={{ marginBottom: '14px' }} xs="2">
                            <ApplicationIcon
                              width="30px"
                              height="30px"
                              icon={
                                e.type === 'selfhosted-mail'
                                  ? 'cloudez-footer'
                                  : `${e.type}`
                              }
                            />
                          </Col>
                          <Col style={{ marginBottom: '14px' }} xs="7">
                            <Value>
                              <p>{e.domain}</p>
                              <span>{e.cloud}</span>
                            </Value>
                          </Col>
                          <Col style={{ marginBottom: '14px' }} xs="3">
                            <span
                              style={{
                                fontWeight: 700,
                                color: theme.interface5,
                                fontSize: '13px',
                                marginTop: '9px',
                              }}
                            >
                              {kbToMb(e.disk_usage) || 0}
                            </span>
                          </Col>
                        </Row>
                      </li>
                    ))}
                  </Top>
                  <Link
                    style={{
                      textAlign: 'center',
                      fontSize: '10px',
                      marginTop: '25px',
                      color: theme.interface5,
                    }}
                    to="/emails"
                  >
                    Mostrar tudo
                  </Link>
                </>
              ) : (
                <Link
                  style={{
                    textAlign: 'center',
                    fontSize: '16px',
                    marginTop: '25px',
                    color: theme.brand_primary_color,
                  }}
                  to="/emails"
                >
                  Ir para emails
                </Link>
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col style={{ marginBottom: '14px' }}>
          <Card>
            <CardHeader>Domínios</CardHeader>
            <CardBody>
              <Domains>
                <p>
                  <span>{domains.active}</span> ativos
                </p>
                <div />
                <span>
                  <span>{domains.total}</span> total
                </span>
              </Domains>
            </CardBody>
          </Card>
        </Col>

        <Col style={{ marginBottom: '14px' }} xl="6">
          <Card>
            <CardHeader>Lista dos domínios</CardHeader>
            <CardBody>
              {dash.top_domains.length ? (
                <>
                  <Top>
                    {dash.top_domains.map(d => (
                      <li
                        key={d.id}
                        onClick={() => history.push(`/domains/${d.id}`)}
                      >
                        <Row>
                          <Col style={{ marginBottom: '14px' }} xs="2">
                            <DomainIcon disable={!d.is_active}>
                              <FontAwesomeIcon icon={faGlobeAmericas} />
                            </DomainIcon>
                          </Col>
                          <Col style={{ marginBottom: '14px' }} xs="7">
                            <Value>
                              <p>{d.domain}</p>
                            </Value>
                          </Col>
                        </Row>
                      </li>
                    ))}
                  </Top>
                  <Link
                    style={{
                      textAlign: 'center',
                      fontSize: '10px',
                      marginTop: '25px',
                      color: theme.interface5,
                    }}
                    to="/domains"
                  >
                    Mostrar tudo
                  </Link>
                </>
              ) : (
                <Link
                  style={{
                    textAlign: 'center',
                    fontSize: '16px',
                    marginTop: '25px',
                    color: theme.brand_primary_color,
                  }}
                  to="/domains"
                >
                  Ir para domínios
                </Link>
              )}
            </CardBody>
          </Card>
        </Col>

        <Col style={{ marginBottom: '14px' }}>
          <Card>
            <CardHeader>HTTPS</CardHeader>
            <CardBody>
              <HTTPS>
                <p>{dash.certificates.total_active || 0}</p>
                <div>
                  <p>
                    {dash.certificates.total_letsencrypt || 0}
                    <span>Let's Encrypt</span>
                  </p>
                  <p>
                    {dash.certificates.total_namecheap ||
                      0 + dash.certificates.total_custom ||
                      0}
                    <span>Paga</span>
                  </p>
                </div>
              </HTTPS>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  ) : (
    <Placeholder />
  );
};

export default Dashboard;
