import React from 'react';
import { withTheme } from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faFolder } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-regular-svg-icons';

import { Button } from '@cloudez/cloudez-design-system';

import { CustomInput } from 'components/NewComponents';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from '../styles';

const SMTPModal = ({ email, theme, setShow }) => {
  return (
    <>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faFolder} />
        </Icon>
        <Text>
          <Title>Acessos SMTP</Title>
          <Subtitle>Informações de acesso de Email</Subtitle>
        </Text>
      </Header>
      <Content>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
          <CustomInput
            block
            name="server"
            label="SERVIDOR"
            readOnly
            value={email.cloud_fqdn}
            style={{ color: theme.brand_primary_color }}
            background
          />
          <CustomInput
            block
            name="webmail"
            label="WEBMAIL"
            icon={{
              svg: faExternalLinkAlt,
              isRight: true,
              color: theme.brand_primary_color,
              action: () => window.open(email.webmail_url),
            }}
            readOnly
            value={email.webmail_url}
            style={{ color: theme.brand_primary_color }}
            background
          />
          <div style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
            <CustomInput
              width="70%"
              name="smtp"
              icon={{
                color: theme.interface4,
                svg: faCopy,
                isRight: true,
                action: () =>
                  navigator.clipboard.writeText(`smtp.${email.domain}`),
              }}
              label="SMTP"
              readOnly
              background
              block
              value={`smtp.${email.domain}`}
            />
            <CustomInput
              name="port"
              label="Porta"
              readOnly
              background
              width="77px"
              block
              value={587}
            />
            <CustomInput
              name="ssl_port"
              label="Porta SSL"
              readOnly
              background
              width="77px"
              block
              value={465}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
            <CustomInput
              width="70%"
              name="imap"
              label="IMAP"
              icon={{
                color: theme.interface4,
                svg: faCopy,
                isRight: true,
                action: () =>
                  navigator.clipboard.writeText(`imap.${email.domain}`),
              }}
              readOnly
              background
              block
              value={`imap.${email.domain}`}
            />
            <CustomInput
              name="port"
              label="Porta"
              readOnly
              background
              width="77px"
              block
              value={143}
            />
            <CustomInput
              name="ssl_port"
              label="Porta SSL"
              readOnly
              background
              width="77px"
              block
              value={993}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'row', gap: '12px' }}>
            <CustomInput
              width="70%"
              name="pop"
              label="POP"
              icon={{
                color: theme.interface4,
                svg: faCopy,
                isRight: true,
                action: () =>
                  navigator.clipboard.writeText(`pop.${email.domain}`),
              }}
              readOnly
              background
              block
              value={`pop.${email.domain}`}
            />
            <CustomInput
              name="port"
              label="Porta"
              readOnly
              background
              width="77px"
              block
              value={110}
            />
            <CustomInput
              name="ssl_port"
              label="Porta SSL"
              readOnly
              background
              width="77px"
              block
              value={995}
            />
          </div>
        </div>
      </Content>
      <Footer>
        <Button width="149px" onClick={() => setShow(false)}>
          Fechar
        </Button>
      </Footer>
    </>
  );
};

export default withTheme(SMTPModal);
