import React, { useState } from 'react';

import ApplicationIcon from 'components/ApplicationIcon';

import { Card } from './styles';

import EditAddonModal from '../EditAddonModal';

const AddonCard = ({ addon, addons, setAddons }) => {
  const [show, setShow] = useState(false);

  return (
    <>
      {show && (
        <EditAddonModal
          addon={addon}
          show={show}
          setShow={setShow}
          addons={addons}
          setAddons={setAddons}
        />
      )}

      <Card onClick={() => setShow(true)}>
        <ApplicationIcon width="40px" height="40px" icon={addon.type.slug} />
        <p>{addon.type.name}</p>
      </Card>
    </>
  );
};

export default AddonCard;
