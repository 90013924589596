import React, { createContext, useReducer } from 'react';
import { useAuth } from 'hooks/auth';
import { Website } from 'types/website';
import reducer from './reducer';
import {
  setLoading,
  getWebsite,
  updateField,
  getWebsiteV3,
  updateValue,
  patchWebsiteV3,
  patchWebsite,
  deleteWebsite,
} from './actions';

export interface WebsiteDetailState {
  website: Website;
  updateLoading: boolean;
  errors: any;

  setLoading(value: boolean): void;
  getWebsite(id: string | number): Promise<void>;
  deleteWebsite(id: string | number, motive: string): Promise<void>;
  updateField(field: string, value: string): void;
  updateValue(e: Event): void;
  patchWebsite(field: string, value: any, id: number | string): Promise<void>;
}

// initial state
const initialState: WebsiteDetailState = {
  website: null,
  // originalWebsite: null,
  updateLoading: false,
  errors: null,
  setLoading: (value: boolean) => {},
  getWebsite: async (id: string | number) => {},
  deleteWebsite: async (id: string | number, motive: string) => {},
  updateField: (field: string, value: string) => {},
  updateValue: (e: Event) => {},
  patchWebsite: async (field: string, value: any, id: number | string) => {},
};

// context
export const WebsiteDetailContext =
  createContext<WebsiteDetailState>(initialState);

// provider
export const WebsiteDetailProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { user } = useAuth();

  return (
    <WebsiteDetailContext.Provider
      value={{
        // state
        website: state.website,
        // originalWebsite: state.originalWebsite,
        updateLoading: state.updateLoading,
        errors: state.errors,

        // actions
        setLoading: value => setLoading(dispatch, value),
        getWebsite: async id =>
          user.is_new_acquisition
            ? await getWebsiteV3(dispatch, id)
            : await getWebsite(dispatch, id),
        updateField: (field, value) =>
          updateField(state, dispatch, field, value),
        updateValue: e => updateValue(state, dispatch, e),
        patchWebsite: async (field, value, id) =>
          user.is_new_acquisition
            ? await patchWebsiteV3(dispatch, field, value, id)
            : await patchWebsite(dispatch, field, value, id),
        deleteWebsite: async (id, motive) =>
          await deleteWebsite(dispatch, id, motive),
      }}
    >
      {children}
    </WebsiteDetailContext.Provider>
  );
};
