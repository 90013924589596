import history from 'services/history';
import { getDomainsService, updateDomainService } from 'services/domain';

export const setLoading = (dispatch, value) => {
  dispatch({
    type: 'SET_LOADING',
    payload: value,
  });
};

const mapPriorityStatus = (priority: number) => {
  switch (priority) {
    case 0:
      return undefined;
    case 1:
      return false;
    case 2:
      return true;
    default:
      true;
  }
};

export const getDomains = async (
  dispatch,
  page,
  search,
  filter,
  order,
  ctrl,
) => {
  setLoading(dispatch, true);
  try {
    const { data } = await getDomainsService(
      {
        ...search,
        page,
        filter,
        [`${order?.column}_order`]: mapPriorityStatus(order?.value),
      },
      ctrl,
    );

    dispatch({
      type: 'GET_DOMAINS',
      payload: data,
    });
    setLoading(dispatch, false);
  } catch (e) {
    if (e.message !== 'canceled') setLoading(dispatch, false);
    if (e?.response?.status === 404) {
      history.push('/404');
    }
  }
};

export const updateDomain = async (dispatch, field, value, id) => {
  try {
    const { data } = await updateDomainService(id, {
      [field]: value,
    });

    dispatch({
      type: 'UPDATE_DOMAIN',
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: 'UPDATE_DOMAIN_ERROR',
      payload: e?.response?.data,
    });
  }
};
