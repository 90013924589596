import React, { useCallback, useState } from 'react';

import * as Yup from 'yup';
import { toast } from 'react-toastify';

import api from 'services/api';

import { Button } from '@cloudez/cloudez-design-system';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faEye,
  faEyeSlash,
  faKey,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { useLayout } from 'hooks/layout';
import { useHistory, useParams } from 'react-router-dom';
import { CustomInput } from 'components/NewComponents';
import generatePassword from 'utils/generatePassword';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Content, Wrapper } from '../styles';

const schema = Yup.object().shape({
  new_password1: Yup.string().required('A senha é obrigatória.'),
  new_password2: Yup.string()
    .min(8, 'A senha deve conter no mínimo 8 caracteres.')
    .oneOf([Yup.ref('new_password1'), null], 'As senhas são diferentes.')
    .required('Senha de confirmação é obrigatória.'),
});

const ResetPasswordForm: React.FC = () => {
  const theme = useLayout();
  document.title = `Redefinir minha senha — ${theme.name}`;

  const history = useHistory();
  const { uid, token } = useParams();

  const [showPw, setShowPw] = useState(false);
  const [showPw2, setShowPw2] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const [done, setDone] = useState(false);

  const onSubmit = useCallback(
    async data => {
      const payload = {
        ...data,
        uid,
        token,
      };

      try {
        setLoading(true);
        await api.post('/auth/password/reset/confirm/', payload);

        setLoading(false);
        setDone(true);
        toast.success('Senha alterada com sucesso');
        history.push('/');
      } catch (e) {
        setLoading(false);
        if (
          e?.response?.data[Object.keys(e?.response?.data)[0]][0] ===
          'Invalid value'
        ) {
          toast.error(
            'Token inválido ou expirado, solicite a recuperação de senha novamente.',
          );
        } else {
          toast.error(e?.response?.data[Object.keys(e?.response?.data)[0]][0]);
        }
      }
    },
    [uid, token],
  );

  return (
    <Wrapper>
      <Content>
        <img src={theme.logo_primary} alt="logo" />
        <form onSubmit={handleSubmit(onSubmit)}>
          <h3>Redefinir senha</h3>
          <div
            style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}
          >
            <CustomInput
              label="Nova senha"
              id="newPassword"
              type={showPw ? 'text' : 'password'}
              block
              register={register}
              error={errors?.new_password1?.message}
              icon={[
                {
                  svg: faKey,
                  isRight: true,
                  action: () => {
                    const pass = generatePassword();

                    setValue('new_password1', pass);
                  },
                },
                {
                  svg: !showPw ? faEye : faEyeSlash,
                  action: () => setShowPw(!showPw),
                  isRight: true,
                },
              ]}
              name="new_password1"
              placeholder="Nova senha"
            />

            <CustomInput
              label="Repetir nova senha"
              register={register}
              error={errors?.new_password2?.message}
              block
              icon={{
                svg: !showPw2 ? faEye : faEyeSlash,
                action: () => setShowPw2(!showPw2),
                isRight: true,
              }}
              type={showPw2 ? 'text' : 'password'}
              name="new_password2"
              placeholder="Repetir nova senha"
            />
          </div>
          <Button
            icon
            width="100%"
            type="submit"
            height="50px"
            disabled={loading || done}
          >
            {loading ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : done ? (
              <FontAwesomeIcon icon={faCheck} />
            ) : (
              'Redefinir'
            )}
          </Button>
        </form>
      </Content>
    </Wrapper>
  );
};

export default ResetPasswordForm;
