import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { withTheme } from 'styled-components';
import { Row, Col } from 'react-bootstrap';
import * as moment from 'moment';

import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSpinner } from '@fortawesome/free-solid-svg-icons';

import CurrencyFormat from 'react-currency-format';

import {
  Modal,
  Input,
  Field,
  Label,
  Error,
  Button,
} from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Content,
  Footer,
} from 'components/ListingModal/styles';
import { createCloudChangeService } from 'services/cloud';
import { getPlanTypesService } from 'services/common';
import { SquareCheckbox } from 'components/NewComponents';
import { Tab, Tabs } from 'components/Tabs';
import { useAuth } from 'hooks/auth';
import { useLayout } from 'hooks/layout';
import Plan from './Plan';
import { Comparision, Divisor, Help, Infos } from './styles';
import { PlanCard } from './Plan/styles';

const ChangePlanModal = ({ theme, show, setShow, cloud }) => {
  const bottomRef = useRef(null);
  const { user } = useAuth();
  const layout = useLayout();

  const [selectedPlan, setSelectedPlan] = useState(null);
  const [immediately, setImmediately] = useState(true);
  const [date, setDate] = useState(null);
  const [hour, setHour] = useState(null);
  const [minute, setMinute] = useState(null);
  const [loading, setLoading] = useState(false);
  const [plansLoading, setPlansLoading] = useState(false);
  const [plans, setPlans] = useState(null);
  const [errors, setErrors] = useState();
  const [tab, setTab] = useState(1);

  const isEmail = useMemo(() => {
    return cloud.cloud_size.category === 3;
  }, [cloud]);

  const isVolume = useMemo(() => {
    return cloud.cloud_size.category === 4;
  }, [cloud]);

  const getAnnual = useCallback(
    value => (value ? value * 12 - value * 12 * 0.1 : '0'),
    [],
  );

  const newDescription = (() => {
    if (selectedPlan?.description && selectedPlan?.description !== 'False') {
      return selectedPlan?.description;
    }

    if (
      selectedPlan?.cloud_size?.info &&
      selectedPlan?.cloud_size?.info['tip_pt-br']
    ) {
      return selectedPlan?.cloud_size?.info['tip_pt-br'];
    }

    return '';
  })();

  const actualDescription = (() => {
    if (cloud?.description && cloud?.description !== 'False') {
      return cloud?.description;
    }

    if (cloud?.cloud_size?.info && cloud?.cloud_size?.info['tip_pt-br']) {
      return cloud?.cloud_size?.info['tip_pt-br'];
    }

    return '';
  })();

  const getPlans = useCallback(
    async (filter = 'basic') => {
      try {
        setPlansLoading(true);
        const { data } = await getPlanTypesService(
          user.is_staff
            ? {
                page_size: 1000,
                category: 1,
                company__code: layout.code,
                size_category: isVolume
                  ? 'volume'
                  : isEmail
                  ? 'email'
                  : 'default,ram,cpu',
              }
            : {
                page_size: 1000,
                category: 1,
                size_category: isVolume
                  ? 'volume'
                  : isEmail
                  ? 'email'
                  : 'default,ram,cpu',
              },
        );

        setPlans(data.results);

        setPlansLoading(false);
      } catch (e) {
        setPlansLoading(false);
        console.log(e);
      }
    },
    [isEmail],
  );

  const upgradePlan = async (plan_type, immediately, date, hour, minute) => {
    setErrors({});
    setLoading(true);
    try {
      let payload = {
        plan_type,
        node: cloud.id,
        is_turbo: tab === 2,
      };

      if (immediately) {
        payload = {
          ...payload,
          immediately,
        };
      } else {
        payload = {
          ...payload,
          resize_date: date,
          resize_hour: `${hour}:${minute}:00`,
        };
      }

      await createCloudChangeService(payload);

      setShow(false);
      if (immediately) {
        toast.success('O upgrade será realizado assim que a fatura for paga.');
      } else {
        toast.success('O upgrade será realizado na data marcada.');
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      setErrors(e?.response?.data);
      if (e?.response?.data?.non_field_errors) {
        toast.error(e?.response?.data?.non_field_errors[0]);
      }
    }
  };

  const changeFilter = useCallback(
    _tab => {
      if (loading) return;
      getPlans(_tab === 1 ? 'basic' : 'turbo');
      setTab(_tab);
    },
    [loading],
  );

  useEffect(() => {
    getPlans();
  }, []);

  useEffect(() => {
    if (selectedPlan) {
      bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
  }, [selectedPlan]);

  return (
    <Modal
      style={{ overflowY: 'auto', height: '844px' }}
      show={show}
      setShow={setShow}
      noExit={loading}
      width="714px"
    >
      <Header
        style={{
          justifyContent: 'space-between',
          padding: '10px 28px',
          height: 'auto',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', width: '50%' }}>
          <Icon>
            <FontAwesomeIcon icon={faEdit} />
          </Icon>
          <Text>
            <Title>Aumentar ou diminuir recursos</Title>
          </Text>
        </div>
      </Header>

      <Content>
        <Tabs>
          {isEmail ? (
            <Tab active={tab === 1} onClick={() => changeFilter(1)}>
              Email
            </Tab>
          ) : (
            <>
              <Tab active={tab === 1} onClick={() => changeFilter(1)}>
                Padrão
              </Tab>
            </>
          )}
        </Tabs>
        {plans?.length > 0 ? (
          <>
            <Plan
              plans={plans}
              selectedPlan={selectedPlan}
              getAnnual={getAnnual}
              setSelectedPlan={setSelectedPlan}
              lifespanMonths={cloud.plan?.lifespan_months}
              tab={tab}
              loading={plansLoading}
            />
            {errors?.plan_type && (
              <Error
                style={{
                  margin: '10px 0',
                  color: '#d92947',
                }}
              >
                {errors?.plan_type[0]}
              </Error>
            )}
            {selectedPlan && (
              <Comparision>
                <div>
                  <p>Atual</p>
                  <PlanCard
                    style={{ border: '0', cursor: 'auto', margin: '0' }}
                  >
                    <div className="top">
                      <div className="plan-title">
                        {cloud?.plan?.name}
                        <span>{cloud?.cloud_size?.info?.memory} RAM</span>
                      </div>

                      <span className="tip">{actualDescription}</span>

                      <div className="price">
                        <span>
                          {cloud.plan?.lifespan_months === 1
                            ? 'Mensal'
                            : 'Anual'}
                        </span>
                        <CurrencyFormat
                          style={{ fontSize: '30px' }}
                          value={
                            cloud.plan?.lifespan_months === 1
                              ? tab === 2
                                ? cloud?.plan?.price?.amount
                                : cloud?.plan?.price?.amount
                              : getAnnual(
                                  tab === 2
                                    ? cloud?.plan?.price?.amount
                                    : cloud?.plan?.price?.amount,
                                )
                          }
                          displayType="text"
                          fixedDecimalScale
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          prefix={
                            tab === 2
                              ? cloud?.cloud_size?.turbo_price?.currency ===
                                'BRL'
                                ? 'R$ '
                                : '$'
                              : cloud?.cloud_size?.price?.currency === 'BRL'
                              ? 'R$ '
                              : '$'
                          }
                        />
                      </div>
                    </div>

                    <div className="separator" />
                    <div className="bottom">
                      <ul>
                        <li>DISCO</li>
                        <li className="value">{cloud?.cloud_size?.disk}</li>
                        <li>PROCESSADOR</li>
                        <li className="value">
                          {cloud?.cloud_size?.cores} CPU core
                        </li>
                      </ul>
                    </div>
                  </PlanCard>
                </div>
                <Divisor />
                <div>
                  <p>Novo</p>
                  <PlanCard
                    style={{ border: '0', cursor: 'auto', margin: '0' }}
                  >
                    <div className="top">
                      <div className="plan-title">
                        {selectedPlan?.name}
                        <span>
                          {selectedPlan?.cloud_size?.info?.memory} RAM
                        </span>
                      </div>

                      <span className="tip">{newDescription}</span>

                      <div className="price">
                        <span>
                          {cloud.plan?.lifespan_months === 1
                            ? 'Mensal'
                            : 'Anual'}
                        </span>
                        <CurrencyFormat
                          style={{ fontSize: '30px' }}
                          value={
                            cloud.plan?.lifespan_months === 1
                              ? tab === 2
                                ? selectedPlan?.turbo_price_total?.amount
                                : selectedPlan?.price_total?.amount
                              : getAnnual(
                                  tab === 2
                                    ? selectedPlan?.turbo_price_total?.amount
                                    : selectedPlan?.price_total?.amount,
                                )
                          }
                          displayType="text"
                          fixedDecimalScale
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          prefix={
                            tab === 2
                              ? selectedPlan?.turbo_price_total?.currency ===
                                'BRL'
                                ? 'R$ '
                                : '$'
                              : selectedPlan?.price_total?.currency === 'BRL'
                              ? 'R$ '
                              : '$'
                          }
                        />
                      </div>
                    </div>

                    <div className="separator" />
                    <div className="bottom">
                      <ul>
                        <li>DISCO</li>
                        <li className="value">
                          {selectedPlan?.cloud_size?.disk}
                        </li>
                        <li>PROCESSADOR</li>
                        <li className="value">
                          {selectedPlan?.cloud_size?.cores} CPU core
                        </li>
                      </ul>
                    </div>
                  </PlanCard>
                </div>
              </Comparision>
            )}

            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
              }}
            >
              <SquareCheckbox
                checked={immediately}
                value={immediately}
                onClick={() => setImmediately(!immediately)}
              />
              <Label
                onClick={() => setImmediately(!immediately)}
                style={{ marginBottom: '0' }}
              >
                Atualizar imediatamente após confirmação de pagamento.
              </Label>
            </div>

            {!immediately && (
              <>
                <p
                  style={{
                    color: theme.interface5,
                    fontSize: '14px',
                    marginBottom: '10px',
                  }}
                >
                  ou
                </p>
                <Row
                  style={{
                    marginTop: '10px',
                    marginRight: '0px',
                  }}
                >
                  <Col sm="5">
                    <Field>
                      <Label>DATA</Label>
                      <Input
                        type="date"
                        block="true"
                        min={moment().format('YYYY/MM/DD')}
                        value={date}
                        onChange={e => setDate(e.target.value)}
                      />
                      {errors?.resize_date && (
                        <Error>{errors?.resize_date[0]}</Error>
                      )}
                    </Field>
                  </Col>
                  <Col sm="3">
                    <Field>
                      <Label>HORA</Label>
                      <Input
                        block="true"
                        value={hour}
                        onChange={e => setHour(e.target.value)}
                      />
                      {errors?.resize_hour && (
                        <Error>{errors?.resize_hour[0]}</Error>
                      )}
                    </Field>
                  </Col>
                  <Col sm="3">
                    <Field>
                      <Label>Minuto</Label>
                      <Input
                        block="true"
                        value={minute}
                        onChange={e => setMinute(e.target.value)}
                      />
                    </Field>
                  </Col>
                </Row>
              </>
            )}
            <Infos>
              <p className="alert">IMPORTANTE: </p>
              <p>Seu cloud será atualizado após confirmação de pagamento.</p>
              <p>
                Clouds de até 4G podem levar de 10 a 15 minutos para atualizar.
              </p>
              <p>
                Clouds maiores que 4G podem levar de 30 a 50 minutos para
                atualizar.
              </p>
              <p>
                Seu cloud e aplicações ficarão offline pelo período da
                atualização.
              </p>
              {isEmail && (
                <p>Não é possível realizar downgrade em clouds de e-mail.</p>
              )}
            </Infos>
          </>
        ) : (
          <p
            style={{
              textAlign: 'center',
              fontSize: '20px',
              color: theme.interface5,
              fontWeight: 'bold',
              margin: '20px 0px',
            }}
          >
            Não há nenhum plano para upgrade
          </p>
        )}
        <div ref={bottomRef} />
      </Content>
      <Footer style={{ justifyContent: 'space-between' }}>
        <Help>
          <h5>PRECISA DE AJUDA?</h5>
          <a href="#">Clique aqui</a>
        </Help>
        <Button
          disabled={loading || plans?.length === 0}
          onClick={async () => {
            setLoading(true);
            await upgradePlan(selectedPlan.id, immediately, date, hour, minute);
            setLoading(false);
          }}
          icon
        >
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Alterar'}
        </Button>
      </Footer>
    </Modal>
  );
};

export default withTheme(ChangePlanModal);
