import {
  faExclamationTriangle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Modal } from '@cloudez/cloudez-design-system';
import { Content, Footer } from 'components/ListingModal/styles';
import Cloud from 'pages/Databases/DatabaseCreate/Cloud';
import React, { useContext, useMemo } from 'react';
import { WebsiteCreateContext } from '../_context/state';
import { NotRecommendedContent, Buttons } from './styles';

export default function NotRecommendedModal({
  setShow,
  onConfirm,
  notRecommended,
  ...props
}) {
  const { setNotRecommended } = useContext(WebsiteCreateContext);

  return (
    <Modal setShow={setShow} {...props}>
      <Content>
        <NotRecommendedContent>
          <FontAwesomeIcon
            className="close"
            icon={faTimes}
            onClick={() => setShow(false)}
          />

          <FontAwesomeIcon icon={faExclamationTriangle} />

          <p className="not-recommended">Cloud não recomendado</p>
          <p className="dynamic-text">{notRecommended.message}</p>
          <p className="recommendation">
            Recomendamos que selecione outro Cloud para entregar a melhor
            performance para a sua aplicação
          </p>

          <p className="question">Deseja continuar mesmo assim?</p>
        </NotRecommendedContent>
      </Content>

      <Footer>
        <Buttons>
          <Button onClick={() => setShow(false)}>NÃO</Button>
          <Button
            outline
            onClick={() => {
              onConfirm();
              setShow(false);
              setNotRecommended({
                open: false,
                message: '',
              });
            }}
          >
            Sim
          </Button>
        </Buttons>
      </Footer>
    </Modal>
  );
}
