import styled, { css } from 'styled-components';

interface IWrapperProps {
  defaultLayout?: boolean;
}

export const Wrapper = styled.div<IWrapperProps>`
  min-height: 100vh;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: flex-start;

  background: ${props => props.theme.interface2};

  ${props =>
    props.defaultLayout &&
    css`
      background: ${props.theme.interface2} !important;
    `}

  @media (max-width: 1000px) {
    display: block;
  }
`;

interface IColumnProps {
  right?: boolean;
  defaultLayout?: boolean;
  left?: boolean;
  sideMenuCollapsed?: boolean;
}

export const Column = styled.div<IColumnProps>`
  ${props =>
    props.right
      ? css`
          height: 100%;
          width: 100%;
          padding: 0 20px;
          margin-left: 210px;

          ${props.defaultLayout &&
          css`
            max-width: 1200px;

            padding: 30px;
            padding-top: 50px;
          `}
        `
      : css`
          position: fixed;

          top: 0;
          left: 0;
          background: ${props.theme.brand_primary_color} !important;
          width: 210px;
          height: 100%;

          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .img {
            width: ${props =>
              props.theme.sideMenuCollapsed ? '100px' : '210px'};
            height: 98px;
            padding: 38px 20px 0;
            cursor: pointer;

            img {
              width: 100%;
              max-height: 60px;
            }
          }
        `}

  @media (max-width: 1000px) {
    width: 100%;

    ${props =>
      props.left &&
      css`
        display: flex;
        justify-content: center;
        align-items: center;
        position: static;
        width: 100%;
        height: 98px;

        .img {
          padding: 0 20px 0;
          display: flex;

          align-items: center;
          justify-content: center;
        }
      `}

    ${props =>
      props.right &&
      css`
        margin-left: 0;
      `}
  }
`;

export const Logo = styled.div`
  width: 210px;
  display: flex;
  height: 98px;
  padding: 38px 20px 0;
  cursor: pointer;

  justify-content: center;
`;

export const Quote = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-bottom: 12px;
  padding: 12px;

  img {
    height: 100px !important;
    width: 100px !important;
    max-height: unset;
    margin: 0 auto;
  }

  span {
    margin: 6px 0;
    font-style: italic;
  }

  color: white;
  font-size: 12px;
  font-weight: 300;

  @media (max-width: 768px) {
    display: none;
  }
`;
