import styled from 'styled-components';

interface ILogoProps {
  sideMenuCollapsed?: boolean;
}

export const LogoWrapper = styled.div<ILogoProps>`
  width: ${props => (props.sideMenuCollapsed ? '100px' : '210px')};
  height: 98px;
  padding: 38px 20px 0;
  cursor: pointer;
`;

export const LogoImage = styled.img`
  width: 100%;
  max-height: 60px;
`;
