import React, { useEffect, useContext, useState, useRef } from 'react';
import { AnimatedList } from 'react-animated-list';
import { Col, Row } from 'react-bootstrap';

import queryString from 'query-string';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faStar,
  faDownload,
} from '@fortawesome/free-solid-svg-icons';

import history from 'services/history';

import { ListingHeader } from 'components/Listing/styles';
import {
  Pagination,
  Button,
  AdvancedSearch,
} from '@cloudez/cloudez-design-system';
import SearchBar from 'components/SearchBar';
import { Title, Header } from 'components/Common';

import EmptyListing from 'components/EmptyListing';

import {
  FilterHover,
  Filter,
  FilterSeparator,
  FilterWrapper,
} from 'components/SearchBar/styles';
import { Value } from 'components/Acessos';

import { useDocTitle } from 'hooks/docTitle';
import { useLayout } from 'hooks/layout';
import InvoiceWarning from 'pages/Dashboard/InvoiceWarning';
import {
  MigrationListingContext,
  MigrationListingProvider,
} from './_context/state';
import MigrationCard from './MigrationCard';
import MigrationPlaceholder from './Placeholder';

const Migrations = () => {
  const theme = useLayout();

  useDocTitle(`Migrações — ${theme.name}`);

  const { loading, migrations, count, previous, next, current, getMigrations } =
    useContext(MigrationListingContext);

  const {
    page = 1,
    search: _search = '',
    filter = 'all',
  } = queryString.parse(history.location.search);
  const [filterValue, setFilterValue] = useState(filter);

  const [search, setSearch] = useState(
    queryString.parse(history.location.search),
  );

  const abortControllerRef = useRef<AbortController>(new AbortController());

  const getMigrationsCallback = (page, search, filter) => {
    abortControllerRef.current.abort();
    abortControllerRef.current = new AbortController();

    getMigrations(page, search, filter, abortControllerRef.current);

    const _query = {
      ...search,
      page,
      filter,
    };

    if (!search) delete _query.search;
    if (filter === 'all') delete _query.filter;
    if (page === 1 || page === '1') delete _query.page;
    const query = queryString.stringify(_query);
    history.replace(`/migrations?${query}`);
  };

  useEffect(() => {
    getMigrationsCallback(page, search, filter);
    // eslint-disable-next-line
  }, []);

  const handleSetFilter = filter => {
    setFilterValue(filter);
    getMigrationsCallback(1, search, filter);
  };

  const searchValue = searchValue => {
    getMigrationsCallback(1, searchValue, filterValue);
  };

  return (
    <>
      <Header>
        <Title>Migrações</Title>
        <Button onClick={() => history.push('/migrations/create')}>
          <span>Nova migração</span>
        </Button>
      </Header>
      <InvoiceWarning />

      <SearchBar noInput>
        <AdvancedSearch
          style={{
            width: '400px',
          }}
          callback={searchValue}
          value={search}
          noResults={false}
          setValue={setSearch}
          fields={[
            {
              label: 'Qualquer campo',
              value: 'search',
            },
            {
              label: 'Domínio da aplicação',
              value: 'domain',
            },
          ]}
        />
        <FilterWrapper>
          <Filter>
            <FontAwesomeIcon
              icon={faDownload}
              color={
                filterValue === 'ongoing'
                  ? theme.brand_primary_color
                  : theme.darkMode
                  ? theme.interface5
                  : theme.interface4
              }
              onClick={() =>
                filterValue === 'ongoing'
                  ? handleSetFilter(null)
                  : handleSetFilter('ongoing')
              }
            />
            <FilterHover>
              <Value>
                <span>Em andamento</span>
              </Value>
            </FilterHover>
          </Filter>

          <FilterSeparator />

          <Filter>
            <FontAwesomeIcon
              icon={faCheckCircle}
              color={
                filterValue === 'finished'
                  ? theme.brand_primary_color
                  : theme.darkMode
                  ? theme.interface5
                  : theme.interface4
              }
              onClick={() =>
                filterValue === 'finished'
                  ? handleSetFilter(null)
                  : handleSetFilter('finished')
              }
            />
            <FilterHover>
              <Value>
                <span>Finalizadas</span>
              </Value>
            </FilterHover>
          </Filter>

          <FilterSeparator />

          <Filter>
            <FontAwesomeIcon
              icon={faStar}
              color={
                filterValue === 'favorite'
                  ? theme.favoriteNew
                  : theme.darkMode
                  ? theme.interface5
                  : theme.interface4
              }
              onClick={() =>
                filterValue === 'favorite'
                  ? handleSetFilter('all')
                  : handleSetFilter('favorite')
              }
            />
            <FilterHover>
              <Value>
                <span>Favoritos</span>
              </Value>
            </FilterHover>
          </Filter>
        </FilterWrapper>
      </SearchBar>

      {loading ? (
        <MigrationPlaceholder />
      ) : (
        <>
          {migrations?.length ? (
            <>
              <ListingHeader>
                <Row>
                  <Col xs sm md="5" lg="5" xl="4">
                    <p>Aplicação</p>
                  </Col>

                  <Col
                    xl="3"
                    lg="5"
                    md="5"
                    className="d-none d-sm-none d-md-flex"
                  >
                    Status
                  </Col>
                  <Col className="d-none d-xl-flex">
                    <p>CRIADO EM</p>
                  </Col>
                  <Col className="d-none d-xl-flex">
                    <p>ATUALIZADO EM</p>
                  </Col>
                  <Col xl="1" className="d-none d-xl-flex" />
                </Row>
              </ListingHeader>

              <AnimatedList initialAnimationDuration={800} animation="grow">
                {migrations.map(migration => (
                  <MigrationCard migration={migration} key={migration.id} />
                ))}
              </AnimatedList>

              <Pagination
                onChangePage={getMigrationsCallback}
                count={count}
                pageSize={10}
                previous={previous}
                next={next}
                current={current}
                search={search}
                filter={filterValue}
              />
            </>
          ) : null}
          {!loading && migrations?.length === 0 && (
            <EmptyListing>
              <p>Nenhuma migração encontrada.</p>
              <Button
                onClick={() => history.push('/migrations/create')}
                size="lg"
              >
                Criar nova migração
              </Button>
            </EmptyListing>
          )}
        </>
      )}
    </>
  );
};

export default props => (
  <MigrationListingProvider>
    <Migrations {...props} />
  </MigrationListingProvider>
);
