import React from 'react';
import empty from 'assets/img/svg/empty.svg';
import { Wrapper } from './styles';

const EmptyListing = ({ children }) => {
  return (
    <Wrapper>
      <div>
        <img src={empty} alt="empty" />
      </div>
      {children}
    </Wrapper>
  );
};

export default EmptyListing;
