import React from 'react';

import Placeholder from 'components/Placeholder';

import { Row, Col } from 'react-bootstrap';
import {
  Card,
  CardContent,
  CardHeader,
  CloudPerf,
  CloudActions,
} from '../CloudCard/styles';

const CloudPlaceholder = () => {
  return (
    <Card>
      <CardHeader>
        <Placeholder width={200} height={25} />
      </CardHeader>
      <CardContent>
        <CloudPerf>
          <Row
            style={{
              width: '100%',
              margin: '0px',
            }}
          >
            <Col>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  margin: 'auto',
                  position: 'relative',
                }}
              >
                <Placeholder width={135} height={120} />
              </div>
            </Col>
            <Col>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  margin: 'auto',
                  position: 'relative',
                }}
              >
                <Placeholder width={135} height={120} />
              </div>
            </Col>
          </Row>
          <Row
            style={{
              width: '100%',
              marginBottom: '10px',
            }}
          >
            <Col>
              <div
                style={{
                  width: '100%',
                  height: '100%',
                  margin: 'auto',
                  position: 'relative',
                }}
              >
                <Placeholder width="100%" height={30} />
              </div>
            </Col>
          </Row>
        </CloudPerf>
        <CloudActions>
          <Placeholder width={260} height={50} />
        </CloudActions>
      </CardContent>
    </Card>
  );
};

export default CloudPlaceholder;
