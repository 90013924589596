import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

import { Field } from 'components/Details';
import {
  Input,
  Checkbox,
  Range,
  TextArea,
  Select,
} from '@cloudez/cloudez-design-system';
import ReactTooltip from 'react-tooltip';
import { NodeAppField } from 'types';
import { Label, ValueText } from './styles';

interface INodeappFieldProps {
  field: NodeAppField;
  value: string | number;
  onChange: any;
  center?: boolean;
  nodeapp?: boolean;
  block?: boolean;
  error?: boolean;
  disabled?: boolean;
  cloudDistribution?: string;
}

const NodeappField = ({
  field,
  value,
  onChange,
  center,
  cloudDistribution,
  ...rest
}: INodeappFieldProps): JSX.Element => {
  const tooltip = {
    pagespeed: {
      text: (
        <span>
          Melhore o desempenho do site e garanta mais resultados de ranqueamento
          e SEO.
        </span>
      ),
    },
    php_filemanager: {
      text: (
        <span>
          Habilite o gerenciador de arquivos na sua aplicação, para que possa
          enviar e baixar arquivos.
        </span>
      ),
    },
    memory: {
      text: (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <span className="title">MEMORY_LIMIT (Mínimo: 128M)</span>
          Define a quantidade máxima de memória que a aplicação pode alocar.
          Isto ajuda a prevenir que scripts mal escritos consumam toda a memória
          disponível no cloud.
        </div>
      ),
    },
  }[field?.slug];

  return (
    <Field center={center}>
      <Label
        style={{
          textAlign: center ? 'center' : 'start',
        }}
      >
        {field?.name_br}
        {field?.slug === 'memory' ||
        field?.slug === 'php_filemanager' ||
        field?.slug === 'pagespeed' ? (
          <>
            <ReactTooltip
              offset={{ top: 6, right: 160, bottom: 10 }}
              arrowColor="transparent"
              className="customTooltip"
              id="showTooltip"
            >
              {tooltip.text}
            </ReactTooltip>
            <FontAwesomeIcon
              data-tip
              data-for="showTooltip"
              style={{ marginLeft: '4px' }}
              icon={faQuestionCircle}
            />
          </>
        ) : null}
      </Label>
      {(() => {
        switch (field?.widget) {
          case 't':
            return (
              <TextArea
                {...rest}
                name={field?.slug}
                value={value}
                onChange={onChange}
              />
            );
          case 's':
            const getValueText = value => {
              switch (value) {
                case '0':
                  return 'Desligado';
                case '1':
                  return '1 hora';
                case '2':
                  return '1 dia';
                case '3':
                  return '1 semana';
                case '4':
                  return '1 mês';
                case '5':
                  return 'Até o fim dos tempos';
                default:
                  return 'Desligado';
              }
            };
            return (
              <>
                <Range
                  min="0"
                  max="5"
                  name={field?.slug}
                  value={value}
                  onChange={onChange}
                  {...rest}
                />
                <ValueText>
                  {field?.slug === 'profile' ? value : getValueText(value)}
                </ValueText>
              </>
            );
          case 'l':
            return (
              <Select
                height="40px"
                name={field?.slug}
                {...rest}
                value={value}
                onChange={onChange}
              >
                {field?.choices.map(c => {
                  if (c === '5.6') {
                    return (
                      <option key={c} value={c}>
                        {`${c} (obsoleto)`}
                      </option>
                    );
                  }
                  if (
                    cloudDistribution &&
                    !cloudDistribution.includes('Ubuntu 20.04') &&
                    (c === '18.11.0' || c === '20.12.0' || c === '21.6.2')
                  ) {
                    return null;
                  }
                  return (
                    <option key={c} value={c}>
                      {c}
                    </option>
                  );
                })}
              </Select>
            );
          case 'c':
            return (
              <Checkbox
                {...rest}
                name={field?.slug}
                checked={value === 'true'}
                value={value === 'true'}
                onChange={onChange}
              />
            );
          default:
            return (
              <>
                <Input
                  {...rest}
                  name={field?.slug}
                  value={value}
                  onChange={onChange}
                />
              </>
            );
        }
      })()}
    </Field>
  );
};

export default NodeappField;
