import React, { Reducer } from 'react';
import { IActionPayload } from 'types';
import { DatabaseCreateState } from './state';

const reducer: Reducer<DatabaseCreateState, IActionPayload> = (
  state,
  action,
): DatabaseCreateState => {
  switch (action.type) {
    case 'UPDATE_FIELD':
    case 'UPDATE_NEW_DATABASE':
      return {
        ...state,
        newDatabase: action.payload,
      };

    case 'GET_DATABASE_USERS':
      return {
        ...state,
        databaseUsers: action.payload,
      };

    case 'SET_LOADING': {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case 'SET_ERRORS': {
      return {
        ...state,
        errors: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
