import history from 'services/history';
import {
  changeStatusMigrationService,
  getMigrationsService,
} from 'services/friday';

const mapPriorityStatus = (priority: number) => {
  switch (priority) {
    case 0:
      return undefined;
    case 1:
      return false;
    case 2:
      return true;
    default:
      true;
  }
};

export const setLoading = (dispatch, value) => {
  dispatch({
    type: 'SET_LOADING',
    payload: value,
  });
};

export const getMigrations = async (dispatch, page, search, filter, order) => {
  setLoading(dispatch, true);
  try {
    const { data } = await getMigrationsService({
      page,
      ...search,
      ...filter,
      [`${order?.column}_sort`]: mapPriorityStatus(order?.value),
    });

    dispatch({
      type: 'GET_MIGRATIONS',
      payload: data,
    });
    setLoading(dispatch, false);
  } catch (e) {
    setLoading(dispatch, false);
    if (e?.response?.status === 404) {
      history.push('/404');
    }
  }
};

export const ratingMigration = async (dispatch, field, value, id) => {
  try {
    const { data } = await changeStatusMigrationService(id, {
      [field]: value,
    });

    dispatch({
      type: 'RATING_MIGRATION',
      payload: data,
    });
  } catch (e) {
    dispatch({
      type: 'UPDATE_STATUS_MIGRATION_ERROR',
      payload: e?.response?.data,
    });
  }
};
