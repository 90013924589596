import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { Button } from '@cloudez/cloudez-design-system';

import Progress from 'components/Progress';
import { CreateWrapper, CreateHeader, CreateButtons } from 'components/Common';
import { getValue } from 'utils/getValue';
import Cloud from './Cloud';
import DatabaseTypes from './DatabaseTypes';
import Users from './Users';
import {
  DatabaseCreateProvider,
  DatabaseCreateContext,
} from './_context/state';

const DatabaseCreate = () => {
  const { newDatabase, createDatabase, loading, errors } = useContext(
    DatabaseCreateContext,
  );
  const [stage, setStage] = useState(1);

  useEffect(() => {
    if (errors?.users) {
      setStage(3);
    } else if (errors?.values?.database_name) {
      setStage(2);
    }
  }, [errors]);

  const _createDatabase = useCallback(async () => {
    await createDatabase(newDatabase);
    // eslint-disable-next-line
  }, [newDatabase]);

  const buttonDisabled = useMemo(
    () =>
      loading ||
      (stage === 1 && !newDatabase.cloud) ||
      (stage === 2 &&
        (!newDatabase.type || getValue(newDatabase, 'database_name') === '')),

    [loading, stage, newDatabase],
  );

  return (
    <CreateWrapper>
      <CreateHeader>
        <h1>
          {stage === 1
            ? 'Escolha um de seus clouds.'
            : stage === 2
            ? 'Selecione o tipo do banco de dados.'
            : stage === 3
            ? 'Você quer criar um novo usuário?'
            : ''}
        </h1>
        <Progress status={stage} n={3} />
      </CreateHeader>

      {stage === 1 && <Cloud />}

      {stage === 2 && <DatabaseTypes />}

      {stage === 3 && <Users />}

      <CreateButtons>
        {stage >= 2 && (
          <Button
            outline
            onClick={() => setStage(stage - 1)}
            style={{
              marginTop: '20px',
            }}
          >
            Voltar
          </Button>
        )}

        <Button
          disabled={buttonDisabled}
          style={{
            marginTop: '20px',
          }}
          icon
          onClick={() => (stage < 3 ? setStage(stage + 1) : _createDatabase())}
        >
          {loading ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : stage < 3 ? (
            'Próximo passo'
          ) : (
            'Finalizar'
          )}
        </Button>
      </CreateButtons>
    </CreateWrapper>
  );
};

export default props => (
  <DatabaseCreateProvider>
    <DatabaseCreate {...props} />
  </DatabaseCreateProvider>
);
