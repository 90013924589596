import React, { createContext, useReducer } from 'react';
import { Migration } from 'types/migration';
import reducer from './reducer';
import {
  setLoading,
  getMigration,
  updateField,
  getSyncs,
  patchMigration,
} from './actions';

export interface MigrationDetailState {
  migration: Migration;
  updateLoading: boolean;
  errors: any;
  syncs: any[];
  setLoading(value: boolean): void;
  getMigration(id: string | number): Promise<void>;
  patchMigration(field: string, value: any, id: string | number): Promise<void>;
  getSyncs(id: string | number): Promise<void>;
  updateField(field: string, value: any): void;
}

// initial state
const initialState = {
  migration: null,
  updateLoading: false,
  errors: null,
  syncs: null,
};

// context
export const MigrationDetailContext = createContext<MigrationDetailState>(
  initialState as MigrationDetailState,
);

// provider
export const MigrationDetailProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(
    reducer,
    initialState as MigrationDetailState,
  );

  return (
    <MigrationDetailContext.Provider
      value={{
        // state
        migration: state.migration,
        syncs: state.syncs,
        updateLoading: state.updateLoading,
        errors: state.errors,

        // actions
        setLoading: value => setLoading(dispatch, value),
        getMigration: async id => await getMigration(dispatch, id),
        patchMigration: async (field, value, id) =>
          await patchMigration(dispatch, field, value, id),
        getSyncs: async id => await getSyncs(dispatch, id),
        updateField: (field, value) =>
          updateField(state, dispatch, field, value),
      }}
    >
      {children}
    </MigrationDetailContext.Provider>
  );
};
