import { toast } from 'react-toastify';
import history from 'services/history';
import { getInvoiceService } from 'services/invoice';
import { getUserBillingService, getUserService } from 'services/user';
import {
  getCreditcardService,
  getCompanyBillingService,
} from 'services/common';
import nProgress from 'nprogress';

export const setLoading = (dispatch, value) => {
  dispatch({
    type: 'SET_LOADING',
    payload: value,
  });
};

export const getInvoice = async (dispatch, id) => {
  nProgress.start();

  try {
    const { data } = await getInvoiceService(id);

    dispatch({
      type: 'GET_INVOICE',
      payload: data,
    });
    nProgress.done();
  } catch (e) {
    nProgress.done();
    if (e.response.status === 404) {
      toast.error('Invoice não encontrada');
      history.push('/404');
    }
  }
};

export const getUserBilling = async (dispatch, user) => {
  try {
    const { data } = await getUserBillingService(user);

    dispatch({
      type: 'GET_USER_BILLING',
      payload: data,
    });
  } catch (e) {}
};

export const getCompanyBilling = async (dispatch, company) => {
  try {
    const { data } = await getCompanyBillingService(company);

    dispatch({
      type: 'GET_COMPANY_BILLING',
      payload: data,
    });
  } catch (e) {}
};

export const getUser = async (dispatch, user) => {
  try {
    const { data } = await getUserService(user);

    dispatch({
      type: 'GET_USER',
      payload: data,
    });
  } catch (e) {}
};

export const getCreditCard = async dispatch => {
  try {
    const { data } = await getCreditcardService();

    dispatch({
      type: 'GET_CREDIT_CARD',
      payload: data[0],
    });
  } catch (e) {}
};
