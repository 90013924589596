import styled, { css } from 'styled-components';

interface CardProps {
  selected?: boolean;
}

export const Card = styled.div<CardProps>`
  cursor: pointer;
  background: ${props => props.theme.interface2};
  width: 100%;
  height: 50px;
  border-radius: 5px;
  margin-bottom: 10px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border: 2px solid transparent;

  span.domain {
    font-size: 14px;
    color: ${props => props.theme.interface5};

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  p {
    font-size: 12px;
    color: ${props => props.theme.error};
    font-style: italic;
  }

  &:hover {
    border: 2px solid
      ${props =>
        props.theme.darkMode
          ? props.theme.interface5
          : props.theme.brand_primary_color};
  }

  ${props =>
    props.selected &&
    css`
      border: 2px solid
        ${props =>
          props.theme.darkMode
            ? props.theme.interface5
            : props.theme.brand_primary_color};
    `}
`;

export const Price = styled.span`
  font-weight: bold;
  font-style: italic;
  font-size: 14px;
  & > span {
    color: ${props => props.theme.success} !important;
  }
`;
