import React, { useState, useContext } from 'react';
import * as Yup from 'yup';
import InputMask from 'react-input-mask';

import { Row, Col } from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faSearch } from '@fortawesome/free-solid-svg-icons';
import {
  Field,
  FormInput,
  Label,
  Form,
  Error,
  Input,
  Checkbox,
  Button,
  FormSelect,
} from '@cloudez/cloudez-design-system';

import clearMask from 'utils/clearMask';
import { toast } from 'react-toastify';
import { ThemeContext } from 'styled-components';
import { getCepService } from 'services/common';
import { updateUserBillingService } from 'services/user';
import { useAuth } from 'hooks/auth';
import CreditCardModal from './CreditCardModal';
import { Wrapper } from './styles';

const billingSchema = Yup.object().shape({
  name: Yup.string().required('Este campo é obrigatório'),
  email: Yup.string()
    .email('Por favor, insira um email válido.')
    .required('Este campo é obrigatório'),
  document_id: Yup.string().required('Este campo é obrigatório'),
  phone_number: Yup.string().required('Este campo é obrigatório'),
  address_number: Yup.string().required('Este campo é obrigatório'),
  country: Yup.string().required('Este campo é obrigatório'),
  address1: Yup.string().required('Este campo é obrigatório'),
  address2: Yup.string().required('Este campo é obrigatório'),
  state: Yup.string().required('Este campo é obrigatório'),
  city: Yup.string().required('Este campo é obrigatório'),
});

const billingSchemaCNPJ = Yup.object().shape({
  name: Yup.string().required('Este campo é obrigatório'),
  email: Yup.string()
    .email('Por favor, insira um email válido.')
    .required('Este campo é obrigatório'),
  document_id: Yup.string().required('Este campo é obrigatório'),
  address_number: Yup.string().required('Este campo é obrigatório'),
  phone_number: Yup.string().required('Este campo é obrigatório'),
  country: Yup.string().required('Este campo é obrigatório'),
  address1: Yup.string().required('Este campo é obrigatório'),
  address2: Yup.string().required('Este campo é obrigatório'),
  state: Yup.string().required('Este campo é obrigatório'),
  city: Yup.string().required('Este campo é obrigatório'),
  inscricao_estadual: Yup.string().required('Este campo é obrigatório'),
});

const Payment = ({ billing, setBilling, setCreditCard, creditCard, tab }) => {
  const [creditCardModal, setCreditCardModal] = useState(false);
  const [billingErrors, setBillingErrors] = useState({} as any);
  const [cep, setCEP] = useState(billing?.zipcode);
  const [loading, setLoading] = useState(false);
  const [document, setDocument] = useState(
    billing?.document_id.length >= 12 ? 1 : 0,
  );
  const theme = useContext(ThemeContext);
  const [notHasIe, setNotHasIe] = useState(
    billing?.inscricao_estadual === 'ISENTO',
  );

  const [address, setAddress] = useState({
    state: billing?.state,
    address1: billing?.address1,
    address2: billing?.address2,
    city: billing?.city,
    zipcode: billing?.zipcode,
  });

  const { user, getUser } = useAuth();

  const getCEP = async _cep => {
    setBillingErrors(_data => {
      delete _data.zipcode;

      return _data;
    });
    try {
      const { data } = await getCepService(_cep);

      if (data.erro) {
        setBillingErrors(_data => ({
          ..._data,
          zipcode: ['CEP inválido'],
        }));
        return;
      }

      setAddress({
        state: data.uf,
        address1: data.logradouro,
        address2: data.bairro,
        city: data.localidade,
        zipcode: _cep,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmit = async d => {
    setLoading(true);

    const number = clearMask(d.phone_number);

    const billingPayload = {
      ...d,
      ...address,
      document_id: clearMask(d.document_id),
      user: user.id,
      phone_number: `+55${number}`,
    };

    try {
      setBillingErrors([]);
      await updateUserBillingService(user.id, billingPayload);
      await getUser();
      setLoading(false);
      toast.success('Informações de pagamento atualizadas!');
    } catch (e) {
      setLoading(false);
      setBillingErrors(e?.response?.data);
    }
  };

  return (
    billing && (
      <>
        {creditCardModal && (
          <CreditCardModal
            creditCard={creditCard}
            setCreditCard={setCreditCard}
            show={creditCardModal}
            setShow={setCreditCardModal}
          />
        )}
        <Form
          schema={document === 0 ? billingSchema : billingSchemaCNPJ}
          onSubmit={handleSubmit}
          id="accountForm"
          style={{
            width: '100%',
          }}
        >
          <Row>
            <Col>
              <Wrapper isFocused>
                <Row
                  style={{
                    margin: '0',
                  }}
                >
                  <Col>
                    <Field>
                      <Label required>Nome *</Label>
                      <FormInput
                        block="true"
                        name="name"
                        error={billingErrors?.name}
                        value={billing?.name}
                        onChange={e =>
                          setBilling({ ...billing, name: e.target.value })
                        }
                      />
                      {billingErrors?.name && (
                        <Error>{billingErrors?.name[0]}</Error>
                      )}
                    </Field>
                    <Row>
                      <Col>
                        <Field>
                          <Label required>Email financeiro *</Label>
                          <FormInput
                            block="true"
                            placeholder="cliente@company.com"
                            type="email"
                            name="email"
                            value={billing?.email}
                            onChange={e =>
                              setBilling({
                                ...billing,
                                email: e.target.value,
                              })
                            }
                            error={billingErrors?.email}
                          />
                          {billingErrors?.email && (
                            <Error>{billingErrors?.email[0]}</Error>
                          )}
                        </Field>
                      </Col>
                      <Col>
                        <Field>
                          <Label>
                            <span
                              style={{
                                display: 'inline',
                                cursor: 'pointer',
                                color:
                                  document === 0
                                    ? theme.brand_primary_color
                                    : theme.interface4,
                              }}
                              onClick={() => setDocument(0)}
                            >
                              CPF
                            </span>
                            /
                            <span
                              style={{
                                display: 'inline',
                                cursor: 'pointer',
                                color:
                                  document === 1
                                    ? theme.brand_primary_color
                                    : theme.interface4,
                              }}
                              onClick={() => setDocument(1)}
                            >
                              CNPJ
                            </span>
                          </Label>

                          <InputMask
                            mask={
                              document === 0
                                ? '999.999.999-999'
                                : '99.999.999/9999-99'
                            }
                            maskChar={null}
                            block="true"
                            name="document_id"
                            error={billingErrors?.document_id}
                            value={billing?.document_id}
                            onChange={e => {
                              if (e.target.value.length >= 15) {
                                setDocument(1);
                              }

                              setBilling({
                                ...billing,
                                document_id: clearMask(e.target.value),
                              });
                            }}
                          >
                            {inputProps => <FormInput {...inputProps} />}
                          </InputMask>
                          {billingErrors?.document_id && (
                            <Error>{billingErrors?.document_id[0]}</Error>
                          )}
                        </Field>
                      </Col>
                      {document === 1 && (
                        <Col xl="3">
                          <Field>
                            <Label required>Inscrição estadual</Label>
                            <FormInput
                              name="inscricao_estadual"
                              block
                              value={billing?.inscricao_estadual}
                              onChange={e => {
                                setBilling({
                                  ...billing,
                                  inscricao_estadual: e.target.value,
                                });
                              }}
                              disabled={notHasIe}
                              error={billingErrors?.inscricao_estadual}
                            />
                            <Field
                              style={{
                                marginTop: '8px',
                                marginBottom: '0',
                              }}
                            >
                              <Label
                                style={{
                                  fontSize: '11px',
                                }}
                              >
                                Não possuo inscrição estadual *
                              </Label>
                              <Checkbox
                                checked={notHasIe}
                                value={notHasIe}
                                onChange={e => {
                                  setNotHasIe(e.target.checked);

                                  if (e.target.checked === true) {
                                    setBilling({
                                      ...billing,
                                      inscricao_estadual: 'ISENTO',
                                    });
                                  } else {
                                    setBilling({
                                      ...billing,
                                      inscricao_estadual: '',
                                    });
                                  }
                                }}
                              />
                            </Field>
                            {billingErrors?.inscricao_estadual && (
                              <Error>
                                {billingErrors?.inscricao_estadual[0]}
                              </Error>
                            )}
                          </Field>
                        </Col>
                      )}
                    </Row>
                    <Row>
                      <Col xl="5">
                        <Field>
                          <Label required>TELEFONE *</Label>
                          <InputMask
                            name="phone_number"
                            mask="(99) 99999-9999"
                            maskChar={null}
                            value={billing?.phone_number}
                            autoFocus
                            onChange={e => {
                              setBilling({
                                ...billing,
                                phone_number: e.target.value,
                              });
                            }}
                            error={billingErrors?.phone_number}
                            block="true"
                          >
                            {inputProps => {
                              return <FormInput {...inputProps} />;
                            }}
                          </InputMask>
                          {billingErrors?.phone_number && (
                            <Error>{billingErrors?.phone_number[0]}</Error>
                          )}
                        </Field>
                      </Col>
                      <Col xl="4">
                        <Field>
                          <Label required>CEP *</Label>
                          <InputMask
                            mask="99999-999"
                            value={cep}
                            maskChar={null}
                            autoFocus
                            onChange={e => {
                              const cep = e.target.value;
                              setCEP(cep);
                              setAddress({
                                ...address,
                                zipcode: e.target.value,
                              });
                              if (cep.length >= 9) {
                                getCEP(cep.replace(/\.|-|\//g, ''));
                              }
                            }}
                            error={billingErrors?.zipcode}
                            block="true"
                          >
                            {inputProps => {
                              return <Input {...inputProps} />;
                            }}
                          </InputMask>
                          {billingErrors?.zipcode && (
                            <Error>{billingErrors?.zipcode[0]}</Error>
                          )}
                        </Field>
                      </Col>

                      <Col xl="3">
                        <Field>
                          <Label required>Número *</Label>
                          <FormInput
                            block="true"
                            name="address_number"
                            error={billingErrors?.address_number}
                            value={billing?.address_number}
                            onChange={e =>
                              setBilling({
                                ...billing,
                                address_number: e.target.value,
                              })
                            }
                          />
                          {billingErrors?.address_number && (
                            <Error>{billingErrors?.address_number[0]}</Error>
                          )}
                        </Field>
                      </Col>
                    </Row>
                    <Row>
                      <Col xl="6">
                        <Field>
                          <Label required>País *</Label>
                          <FormInput
                            block="true"
                            name="country"
                            value={billing?.country}
                            onChange={e =>
                              setBilling({
                                ...billing,
                                country: e.target.value,
                              })
                            }
                            error={billingErrors?.country}
                          />
                          {billingErrors?.country && (
                            <Error>{billingErrors?.country[0]}</Error>
                          )}
                        </Field>
                      </Col>
                      <Col>
                        <Field>
                          <Label>Bairro</Label>
                          <FormInput
                            disabled={!address?.zipcode}
                            background={address?.zipcode ? true : undefined}
                            onChange={e =>
                              setAddress({
                                ...address,
                                address2: e.target.value,
                              })
                            }
                            block="true"
                            value={address.address2}
                            name="address2"
                          />
                        </Field>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Field>
                          <Label>Endereço</Label>
                          <FormInput
                            disabled={!address?.zipcode}
                            background={address?.zipcode ? true : undefined}
                            onChange={e =>
                              setAddress({
                                ...address,
                                address1: e.target.value,
                              })
                            }
                            block="true"
                            value={address.address1}
                            name="address1"
                          />
                        </Field>
                      </Col>
                      <Col xl="2">
                        <Field>
                          <Label>Estado</Label>
                          <FormSelect
                            options={[
                              { title: 'Acre', id: 'AC' },
                              { title: 'Alagoas', id: 'AL' },
                              { title: 'Amapá', id: 'AP' },
                              { title: 'Amazonas', id: 'AM' },
                              { title: 'Bahia', id: 'BA' },
                              { title: 'Ceará', id: 'CE' },
                              { title: 'Distrito Federal', id: 'DF' },
                              { title: 'Espírito Santo', id: 'ES' },
                              { title: 'Goias', id: 'GO' },
                              { title: 'Maranhão', id: 'MA' },
                              { title: 'Mato Grosso', id: 'MT' },
                              { title: 'Mato Grosso do Sul', id: 'MS' },
                              { title: 'Minas Gerais', id: 'MG' },
                              { title: 'Pará', id: 'PA' },
                              { title: 'Paraíba', id: 'PB' },
                              { title: 'Paraná', id: 'PR' },
                              { title: 'Pernambuco', id: 'PE' },
                              { title: 'Piauí', id: 'PI' },
                              { title: 'Rio de Janeiro', id: 'RJ' },
                              { title: 'Rio Grande do Norte', id: 'RN' },
                              { title: 'Rio Grande do Sul', id: 'RS' },
                              { title: 'Rondônia', id: 'RO' },
                              { title: 'Roraima', id: 'RR' },
                              { title: 'Santa Catarina', id: 'SC' },
                              { title: 'São Paulo', id: 'SP' },
                              { title: 'Sergipe', id: 'SE' },
                              { title: 'Tocantins', id: 'TO' },
                            ]}
                            disabled={!address?.zipcode}
                            onChange={e =>
                              setAddress({
                                ...address,
                                state: e.target.value,
                              })
                            }
                            block
                            value={address.state}
                            name="state"
                          />
                        </Field>
                      </Col>
                      <Col>
                        <Field>
                          <Label>Cidade</Label>
                          <FormInput
                            disabled={!address?.zipcode}
                            background={address?.zipcode ? true : undefined}
                            onChange={e =>
                              setAddress({
                                ...address,
                                city: e.target.value,
                              })
                            }
                            block="true"
                            value={address.city}
                            name="city"
                          />
                        </Field>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Wrapper>
            </Col>
          </Row>
        </Form>
        <Row
          style={{
            width: '100%',
            marginLeft: 0,
          }}
        >
          <Col md="6">
            <Button
              width="300px"
              outline
              onClick={() => setCreditCardModal(true)}
            >
              Mudar cartão de crédito
            </Button>
          </Col>
          <Col md="6">
            <Button
              icon
              type="submit"
              form="accountForm"
              style={{
                marginLeft: 'auto',
              }}
            >
              {loading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : tab ? (
                'Confirmar'
              ) : (
                'Atualizar'
              )}
            </Button>
          </Col>
        </Row>
      </>
    )
  );
};

export default Payment;
