import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  background: ${props => props.theme.interface2};
  margin-bottom: 10px;
  margin-top: 30px;
  width: 100%;
  height: auto;
  padding: 10px 4px;
  border-radius: 5px;

  h3 {
    color: ${props => props.theme.interface5};
    padding: 8px 15px 20px 15px;

    p {
      font-size: 12px;
      font-weight: normal;
    }
  }

  opacity: ${props => (props.isFocused ? '1' : '0.4')};
`;
export const Total = styled.div`
  height: 100%;
  margin-right: 10px;

  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  & > span {
    color: ${props => props.theme.successNew};
    font-size: 28px;
    font-weight: 700;
  }

  & > p {
    color: ${props => props.theme.interface5};
    font-size: 16px;
    margin-bottom: 5px;
    margin-right: 7px;
  }
`;
