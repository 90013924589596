import styled from 'styled-components';
import { transparentize } from 'polished';
import { Input } from '@cloudez/cloudez-design-system';

export const Wrapper = styled.div`
  width: 100%;
  height: 65px;
  background: ${props =>
    props.theme.darkMode
      ? transparentize(0.84, props.theme.interface5)
      : transparentize(0.4, props.theme.interface3)};
  border-radius: 5px;
  margin-bottom: 40px;
  padding: 0px 15px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;

  ${Input} {
    width: 330px;
  }

  @media (max-width: 768px) {
    button {
      display: none;
    }

    ${Input} {
      width: 100%;
    }
  }
`;

export const Filters = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 5px 5px 1px 5px;
  overflow: auto;

  button {
    margin-bottom: 4px;
    width: 46%;
    height: 20px;
    font-size: 9px;
    padding: 4px 6px;
    font-weight: normal;
  }
`;

export const FilterHover = styled.div`
  cursor: default;
  line-height: 1.5;
  font-size: 10px;
  padding: 6px;
  position: absolute;
  top: 30px;
  left: -7px;
  display: none;
  border-radius: 5px;
  background: ${props =>
    props.theme.darkMode ? props.theme.interface4 : props.theme.interface7};
  color: ${props => props.theme.interface1};
  border: 1px solid
    ${props =>
      props.theme.darkMode ? props.theme.interface4 : props.theme.interface7};
  text-transform: uppercase;

  &::before {
    content: '';
    position: absolute;
    top: -8px;
    width: 8px;
    height: 8px;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
    border-bottom: 8px solid
      ${props =>
        props.theme.darkMode ? props.theme.interface4 : props.theme.interface7};
  }
`;

export const Filter = styled.div`
  position: relative;

  &:hover {
    ${FilterHover} {
      display: block;
    }
  }

  svg {
    font-size: 14px;
    cursor: pointer;
  }
`;

export const FilterWrapper = styled.div`
  height: 100%;
  margin-right: 10px;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    margin-right: 5px;
    margin-left: 12px;
  }
`;

export const FilterSeparator = styled.div`
  height: 24px;
  width: 1px;
  margin: 0px 12px;
  background: ${props =>
    props.theme.darkMode ? props.theme.interface2 : props.theme.interface3};

  @media (max-width: 768px) {
    margin: 0px 8px;
  }
`;
