import { toast } from 'react-toastify';
import history from 'services/history';
import {
  createDatabaseService,
  getDatabaseUsersService,
} from 'services/database';

export const setLoading = (dispatch, value) => {
  dispatch({
    type: 'SET_LOADING',
    payload: value,
  });
};

export const updateField = (state, dispatch, field, value) => {
  dispatch({
    type: 'UPDATE_FIELD',
    payload: {
      ...state.newDatabase,
      [field]: value,
    },
  });
};

export const updateNewDatabase = (dispatch, database) => {
  dispatch({
    type: 'UPDATE_NEW_DATABASE',
    payload: database,
  });
};

export const getDatabaseUsers = async (state, dispatch) => {
  try {
    const {
      data: { results },
    } = await getDatabaseUsersService({
      cloud: state.newDatabase.cloud,
      page_size: 100,
    });

    dispatch({
      type: 'GET_DATABASE_USERS',
      payload: results,
    });
  } catch (e) {
    console.log(e);
  }
};

export const createDatabase = async (dispatch, database) => {
  setLoading(dispatch, true);
  try {
    const { data } = await createDatabaseService(database);

    toast.success('Database criado com sucesso.');
    history.push(`/databases/${data.id}`);
    setLoading(dispatch, false);
  } catch (e) {
    setLoading(dispatch, false);

    dispatch({
      type: 'SET_ERRORS',
      payload: e?.response?.data,
    });
  }
};
