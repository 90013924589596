import React, { useState } from 'react';

import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';
import * as yup from 'yup';

import {
  Footer,
  Icon,
  Text,
  Title,
  Header,
} from 'components/ListingModal/styles';
import { Countdown } from 'components/Countdown';

import { resendCodeService, verifyDeviceService } from 'services/user';
import { useAuth } from 'hooks/auth';

import {
  Modal,
  Button,
  Form,
  Error,
  FormInput,
} from '@cloudez/cloudez-design-system';

import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelopeOpenText,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';

import { Content } from './styles';

interface CodeEmailModalProps {
  show: boolean;
  setShow: (value: boolean) => void;
  setIsEmailActive: (value: boolean) => void;
}

const CodeEmailModal: React.FC<CodeEmailModalProps> = ({
  show,
  setShow,
  setIsEmailActive,
}) => {
  const { user } = useAuth();

  const [loadingResend, setLoadingResend] = useState(false);
  const [emailCodeError, setEmailCodeError] = useState('');
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState('00:00');
  const [reset, setReset] = useState(false);
  const [message, setMessage] = useState('');

  const resendCode = async () => {
    setLoadingResend(true);
    try {
      setMessage('');
      await resendCodeService();
      setReset(true);
      setReset(false);
      setMessage('Código reenviado com sucesso!');
      setLoadingResend(false);
    } catch {
      setMessage('');
      setLoadingResend(false);
    }
  };

  const schema = yup.object().shape({
    code: yup.string().required('O código é obrigatório.'),
  });

  const submitEmailCode = async data => {
    schema
      .validate(data, { abortEarly: false })
      .then(async result => {
        setLoading(true);
        await verifyDeviceService({
          code: result.code.replace(/[^0-9]/g, ''),
          device_type: 'email',
        });
        toast.success('Autenticação de dois fatores ativada com sucesso.');
        setIsEmailActive(true);
        setShow(false);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        if (error.response.data.reason === 'N_FAILED_ATTEMPTS') {
          setEmailCodeError(
            `Você excedeu o número de tentativas, aguarde até ${moment(
              error.response.data.locked_until,
            ).format('LT')}.`,
          );
        } else if (
          error.message &&
          error.response?.data.message !== 'Code not valid'
        ) {
          setEmailCodeError(error.message);
        } else {
          setEmailCodeError('Código inválido!');
        }
      });
  };

  return (
    <Modal width="497px" show={show} setShow={setShow}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faEnvelopeOpenText} />
        </Icon>
        <Text>
          <Title>Insira sua senha para iniciar a configuração</Title>
        </Text>
      </Header>

      <Form onSubmit={submitEmailCode}>
        <Content>
          <span>
            Verifique seu email! o seu código foi enviado com sucesso para o
            email <b>{user.email}</b>
          </span>
          <div>
            <InputMask
              width="368px"
              mask="999 999"
              maskChar={null}
              error={emailCodeError !== ''}
              name="code"
              placeholder="Digite aqui o código"
            >
              {inputProps => <FormInput {...inputProps} />}
            </InputMask>
            {emailCodeError && <Error>{emailCodeError}</Error>}
          </div>
          {show && (
            <Countdown
              setTimer={setTimer}
              timeDef="05:00"
              count={300}
              reset={reset}
            >
              {timer === '00:00' ? (
                <span
                  className="sendCode"
                  style={{ cursor: 'pointer' }}
                  onClick={resendCode}
                >
                  {loadingResend ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    'Reenviar código'
                  )}
                </span>
              ) : (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: '5px',
                  }}
                >
                  <span className="sendCode">Reenviar código em {timer}</span>
                  {message && <span className="successMessage">{message}</span>}
                </div>
              )}
            </Countdown>
          )}
        </Content>
        <Footer hasLink>
          <Button disabled={loading} type="submit">
            {loading ? (
              <FontAwesomeIcon
                style={{ marginLeft: '0' }}
                icon={faSpinner}
                spin
              />
            ) : (
              'Ativar'
            )}
          </Button>
        </Footer>
      </Form>
    </Modal>
  );
};

export default CodeEmailModal;
