import styled from 'styled-components';

export const DebugText = styled.div`
  font-size: 16px;
  color: ${props => props.theme.interface5};
  line-height: 24px;

  display: flex;
  flex-direction: column;
  gap: 20px;
`;
