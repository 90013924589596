import styled, { css } from 'styled-components';

interface ICardProps {
  selected?: boolean;
  spam?: boolean;
}

export const Card = styled.div<ICardProps>`
  cursor: pointer;
  width: 100%;
  height: 50px;
  background: ${props => props.theme.interface2};
  padding: 13px 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
  border: 1px solid
    ${props =>
      props.selected
        ? props.theme.darkMode
          ? props.theme.interface5
          : props.theme.brand_primary_color
        : 'transparent'};
  border-radius: 5px;

  svg {
    color: ${props => props.theme.brand_primary_color};
    font-size: 24px;
  }

  &:hover {
    border: 1px solid ${props => props.theme.brand_primary_color};
  }

  ${props =>
    props.spam &&
    css`
      border: 1px solid ${props => props.theme.alert};
      opacity: 0.7;

      &:hover {
        opacity: 1;
        border: 1px solid ${props => props.theme.alert};
      }
    `}
`;

export const Spam = styled.div`
  height: 14px;
  border-radius: 0px 0px 5px 5px;
  display: flex;
  justify-content: center;
  position: absolute;
  font-size: 9px;
  color: #fff;
  text-transform: uppercase;
  width: 64px;
  background: ${props => props.theme.alert};
  top: 0;
  left: calc(50% - 32px);
`;

export const Username = styled.span`
  margin-left: 8px;
  font-size: 12px;
  color: ${props => props.theme.interface5};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 90px;
`;

export const Host = styled.div`
  font-size: 10px;
  font-weight: bold;
  color: ${props => props.theme.interface5};
  margin-right: 12px;

  span {
    display: block;
    font-weight: normal;
    font-style: italic;
  }
`;
