import React, { useEffect, useState, useContext, useMemo } from 'react';

import CurrencyFormat from 'react-currency-format';
import { Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { ThemeContext } from 'styled-components';

import moment from 'moment';

import { Button } from '@cloudez/cloudez-design-system';

import history from 'services/history';
import api from 'services/api';

import { Title, Header, Breadcrumbs, Breadcrumb } from 'components/Common';

import cloudez from 'assets/img/svg/cloudez_footer.svg';

import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { useDocTitle } from 'hooks/docTitle';
import { useAuth } from 'hooks/auth';
import useDevice from 'hooks/device';

import DateModal from '../InvoiceListing/DateModal';
import ReactivateModal from './ReactivateModal';
import Placeholder from './Placeholder';
import {
  Separator,
  Card,
  CompanyBillingInfo,
  UserBillingInfo,
  OrderStatus,
  OrderBadge,
  InvoiceItems,
  InvoiceItemsHeader,
  InvoiceItem,
} from './styles';
import { InvoiceDetailProvider, InvoiceDetailContext } from './_context/state';
import ExistentCreditCardModal from './ExistentCardModal';

const InvoiceDetail = ({ match }) => {
  const {
    user: { company },
  } = useAuth();

  const [showReactivateModal, setShowReactivateModal] = useState(false);
  const [showExistentCreditCardModal, setShowExistentCreditCardModal] =
    useState(false);
  const [dateModal, setDateModal] = useState(false);
  const [proofSent, setProofSent] = useState(false);

  const { isMobile } = useDevice();

  const theme = useContext(ThemeContext);
  const {
    invoice,
    user,
    userBilling,
    companyBilling,
    creditCard,
    getInvoice,
    getCompanyBilling,
    getUserBilling,
    getCreditCard,
    getUser,
  } = useContext(InvoiceDetailContext);

  useEffect(() => {
    const { id } = match.params;

    if (!invoice) {
      getInvoice(id);
    } else {
      getCompanyBilling(company);
      getUserBilling(invoice.user);
      getUser(invoice.user);
      getCreditCard();
    }

    // eslint-disable-next-line
  }, [invoice]);

  const getInvoicePDF = async () => {
    // setLoadingChurn(true);
    try {
      const { data } = await api.get(`/v3/invoice/${invoice.id}/pdf/`, {
        responseType: 'blob',
      });

      const a = document.createElement('a');
      a.href = URL.createObjectURL(data);
      a.download = `invoice-${invoice.id}.pdf`;
      a.click();
      // setLoadingChurn(false);
    } catch (e) {
      // setLoadingChurn(false);
      console.log(e);
    }
  };

  const today = moment();
  const expiredAt = moment(invoice?.expired_at);
  const isExpired = expiredAt?.isBefore(today, 'days');

  const startChargingAt = moment(invoice?.start_charging_at).format(
    'YYYY-MM-DD',
  );

  const maxDate = moment(invoice?.start_charging_at)
    .add(10, 'days')
    .format('YYYY-MM-DD');

  const canChangeDate =
    isExpired &&
    invoice?.has_gateway &&
    !invoice?.is_paid &&
    moment().isBetween(startChargingAt, maxDate, null, '[]');

  useDocTitle(invoice ? `#${invoice?.id} — Fatura` : 'Fatura — Cloudez');

  const hasNF = useMemo(
    () => !!invoice?.nf || user?.payment_gateway === 'PagBrasil',
    [invoice, user],
  );

  return invoice && userBilling && user ? (
    <>
      {canChangeDate && dateModal && (
        <DateModal
          show={dateModal}
          setShow={() => setDateModal(false)}
          invoice={invoice}
        />
      )}
      {showExistentCreditCardModal && setShowExistentCreditCardModal && (
        <ExistentCreditCardModal
          invoiceLink={`${window.location.origin}/invoices/bank-slip/${invoice.public_id}`}
          show={showExistentCreditCardModal}
          setShow={setShowExistentCreditCardModal}
        />
      )}
      {showReactivateModal && (
        <ReactivateModal
          show={showReactivateModal}
          setShow={setShowReactivateModal}
          id={invoice.id}
          proofSent={() => setProofSent(true)}
        />
      )}
      <Header>
        <Title>
          <Breadcrumbs>
            <Breadcrumb>
              <Link to="/dashboard">Home</Link>
            </Breadcrumb>
            <FontAwesomeIcon icon={faChevronRight} />
            <Breadcrumb>
              <Link to="/invoices">Faturas</Link>
            </Breadcrumb>
          </Breadcrumbs>
          Fatura # {invoice.id}
        </Title>
        {hasNF &&
          (user.payment_gateway === 'PagBrasil' ? (
            <Button
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                getInvoicePDF();
              }}
            >
              Baixar PDF
            </Button>
          ) : (
            <Button
              onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                window.open(invoice.nf);
              }}
            >
              Baixar NF-e
            </Button>
          ))}
      </Header>

      <Card>
        <Row
          style={{
            height: '150px',
          }}
        >
          <Col
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            {companyBilling && (
              <CompanyBillingInfo>
                <h1>{companyBilling.fantasy_name}</h1>
                <div
                  style={{
                    marginTop: '8px',
                  }}
                >
                  <p>
                    {companyBilling.address1 && `${companyBilling.address1}, `}{' '}
                    {companyBilling.address_number &&
                      `${companyBilling.address_number}, `}{' '}
                    {companyBilling.address2}
                  </p>
                  <p>
                    {companyBilling.city && `${companyBilling.city}, `}{' '}
                    {companyBilling.state && `${companyBilling.state} - `}{' '}
                    {companyBilling.zipcode}
                  </p>
                  {companyBilling.document_id && (
                    <p>
                      CNPJ:
                      {companyBilling.document_id}
                    </p>
                  )}
                </div>
              </CompanyBillingInfo>
            )}
          </Col>
          <Col
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            {/* <img
              style={{
                height: '90px',
              }}
              alt="logo"
              src={cloudez}
            /> */}
          </Col>
        </Row>
        <Separator />

        <Row
          style={{
            // height: '100px',
            flexDirection: isMobile ? 'column' : 'row',
          }}
        >
          <Col
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <UserBillingInfo>
              <div
                style={{
                  marginTop: '8px',
                }}
              >
                {user.full_name && <span>Fatura para</span>}
                <p>{user.full_name}</p>
                <p>
                  {userBilling.address1}{' '}
                  {userBilling.address2 && `, ${userBilling.address2}`}
                  {userBilling.address_number &&
                    `, ${userBilling.address_number}`}
                </p>
                <p>
                  {userBilling.city}{' '}
                  {userBilling.state && `, ${userBilling.state}`}
                  {userBilling.zipcode && `- ${userBilling.zipcode}`}
                </p>
              </div>
            </UserBillingInfo>
          </Col>
          <Col
            style={{
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: isMobile ? 'flex-start' : 'flex-end',
              alignItems: 'center',
            }}
          >
            <OrderStatus>
              <span>
                Data do pedido:{' '}
                <p>{moment(invoice.created_at).format('DD/MM/YYYY')}</p>
              </span>
              <span>
                Status do pedido:{' '}
                <OrderBadge expired={isExpired} paid={invoice.is_paid}>
                  {invoice.is_paid
                    ? 'Fatura Paga'
                    : isExpired
                    ? 'Fatura Expirada'
                    : 'Fatura Pendente'}
                </OrderBadge>
              </span>
            </OrderStatus>
          </Col>
        </Row>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginTop: '20px',
          }}
        >
          <Button
            onClick={() => setShowReactivateModal(true)}
            alert
            width="280px"
          >
            Enviar Comprovante
          </Button>
          {!invoice.is_paid && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
              }}
            >
              <Button
                style={{ marginLeft: '20px' }}
                onClick={() =>
                  history.push(`/invoices/external/${invoice.public_id}`)
                }
              >
                Pagar agora
              </Button>
            </div>
          )}
        </div>

        <InvoiceItems>
          <InvoiceItemsHeader>
            <Row>
              <Col>Plano</Col>
              <Col>Preço</Col>
              <Col className="d-none d-md-flex ">Período de referência</Col>
              <Col className="d-none d-md-flex ">Data de expiração</Col>
            </Row>
          </InvoiceItemsHeader>
          {invoice.items.map(i => (
            <InvoiceItem key={i.id}>
              <Row
                style={{
                  color: theme.interface5,
                  fontSize: '1rem',
                }}
              >
                <Col>{i.description}</Col>
                <Col
                  style={{
                    fontWeight: 'bolder',
                  }}
                >
                  <CurrencyFormat
                    value={i.price.amount}
                    displayType="text"
                    fixedDecimalScale
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    prefix={i.price.currency === 'BRL' ? 'R$ ' : '$ '}
                  />
                </Col>
                <Col className="d-none d-md-flex ">
                  {i.reference_date &&
                    `${moment(i.reference_date)
                      .subtract(i.plan_lifespan_months, 'month')
                      .format('DD/MM/YYYY')} - ${moment(
                      i.reference_date,
                    ).format('DD/MM/YYYY')}`}
                </Col>
                <Col className="d-none d-md-flex ">
                  {moment(invoice.expired_at).format('DD/MM/YYYY')}
                </Col>
              </Row>
            </InvoiceItem>
          ))}
        </InvoiceItems>
      </Card>
    </>
  ) : (
    <Placeholder />
  );
};

export default props => (
  <InvoiceDetailProvider>
    <InvoiceDetail {...props} />
  </InvoiceDetailProvider>
);
