import styled from 'styled-components';
import { addAlpha } from 'utils/addAlpha';

export const SharedUser = styled.p`
  font-size: 14px;
  color: ${props => props.theme.interface5};
  line-height: 1.5;
`;

export const EmailBox = styled.div`
  overflow: auto;

  gap: 13px;

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  .emails {
    display: flex;
    flex-direction: row;
    align-items: center;

    height: 32px;

    width: fit-content;

    padding: 8px 16px;

    border-radius: 50px;

    font-weight: bold;

    p,
    svg {
      color: ${props => `${props.theme.brand_primary_color}`};
    }

    background-color: ${props =>
      addAlpha(props.theme.brand_primary_color, 0.15)};

    transition: opacity 0.2s;

    &:hover {
      opacity: 0.9;
    }
  }
`;
