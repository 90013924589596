import styled, { css } from 'styled-components';

export const Selection = styled.div`
  height: 160px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

interface SelectProps {
  selected?: boolean;
}

export const Select = styled.div<SelectProps>`
  height: 100%;
  width: 100%;
  border-radius: 5px;
  border: 1px solid transparent;
  margin: 0px 8px;

  &:hover {
    border: 1px solid
      ${props =>
        props.theme.darkMode
          ? props.theme.interface5
          : props.theme.brand_primary_color};
  }

  img {
    height: 100%;
    width: 100%;
  }
  display: flex;
  justify-content: center;
  padding: 10px;
  align-items: center;

  cursor: pointer;

  ${props =>
    props.selected &&
    css`
      border: 1px solid
        ${props =>
          props.theme.darkMode
            ? props.theme.interface5
            : props.theme.brand_primary_color};
    `}
`;

export const Card = styled.div`
  height: auto;
  padding: 30px 60px;
  background: ${props => props.theme.interface1};
  border-radius: 5px;
  margin-top: 40px;
  margin-bottom: 10px;

  h1 {
    font-size: 30px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 30px;
  }
`;
