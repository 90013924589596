import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  gap: 20px;
`;

interface ContentProps {
  success?: boolean;
}

export const Content = styled.div<ContentProps>`
  width: 50%;

  padding: 32px;

  box-shadow: 0px 2px 15px rgba(100, 114, 140, 0.25);
  border-radius: 5px;

  background-color: ${props =>
    props.success ? props.theme.successNew : props.theme.interface1};

  h1 {
    font-size: 44px;
    line-height: 120%;

    color: ${props => props.theme.interface1};

    margin-bottom: 43px;
  }

  .attention {
    display: flex;
    flex-direction: column;

    margin-bottom: 32px;

    color: ${props => props.theme.interface1};

    b {
      font-weight: bold;
      font-size: 16px;
      line-height: 140%;
    }
  }

  .title {
    gap: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;

    color: ${props => props.theme.brand_primary_color};

    span {
      font-weight: bold;
      font-size: 16px;
      line-height: 25px;
    }

    svg {
      width: 23px;
      height: 23px;
    }
  }

  p {
    color: ${props => props.theme.brand_primary_color};
    font-weight: bold;
    font-size: 14px;
  }

  .requestData {
    display: flex;
    flex-direction: column;
    gap: 15px;

    margin-top: 24px;

    color: ${props => props.theme.interface5};
  }

  .migrationDetail {
    display: flex;
    flex-direction: column;

    margin-top: 24px;

    gap: 24px;

    big {
      color: ${props => props.theme.interface5};
      font-size: 16px;
    }
  }

  .value {
    display: flex;
    flex-direction: column;

    gap: 8px;

    b {
      font-weight: bold;
      font-size: 28px;
      color: ${props => props.theme.successNew};
    }
  }

  .accessInvoiceButton {
    border-radius: 5px;
    border: 2px solid ${props => props.theme.interface1};

    background-color: transparent;

    margin-bottom: 32px;

    font-weight: bold;
    font-size: 16px;
    line-height: 100%;
    color: ${props => props.theme.interface1};

    gap: 12px;

    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 12px;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(95%);
    }
  }

  .infoInvoice {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    font-size: 18px;
    line-height: 200%;

    color: ${props => props.theme.interface1};
  }
`;
