import React from 'react';
import FreeTrialTime from '../FreeTrialTime';
import {
  ContentWrapper,
  Title,
  BadgeFlare,
  BadgeWrapper,
  Description,
} from '../styles';

export default function BadgeReceived({
  children,
  title,
  badge,
  description,
  description2,
}) {
  return (
    <ContentWrapper>
      <Title>{title}</Title>

      <BadgeWrapper>
        <BadgeFlare src={badge} alt="badge" />
      </BadgeWrapper>

      <Description>{description}</Description>

      {description2 && <Description>{description2}</Description>}

      {children}
    </ContentWrapper>
  );
}
