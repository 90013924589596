import React from 'react';

import { Button } from '@cloudez/cloudez-design-system';

import cloudDedicado from 'assets/img/svg/cloud_dedicado.svg';
import shareWebsite from 'assets/img/svg/website_shared.svg';
import { Wrapper, Select } from './styles';

const CloudSharingPlans = ({ setStage, stage, setIsSharing }) => {
  return (
    <Wrapper>
      <Select>
        <img alt="cloud" src={cloudDedicado} />
        <p>Cria a aplicação em um de seus clouds dedicados</p>
        <Button
          onClick={() => {
            setIsSharing(false);
          }}
        >
          Cloud dedicado
        </Button>
      </Select>
      <Select>
        <img alt="share" src={shareWebsite} />
        <p>Contrate um plano de website compartilhado</p>
        <Button
          onClick={() => {
            setStage(6);
          }}
        >
          Website compartilhado
        </Button>
      </Select>
    </Wrapper>
  );
};

export default CloudSharingPlans;
