import { useMediaQuery } from '@react-hook/media-query';

interface UseDeviceProps {
  isMobile: boolean;
  isTablet: boolean;
}

export default function useDevice(): UseDeviceProps {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const isTablet = useMediaQuery('(max-width: 1000px)');

  return { isMobile, isTablet };
}
