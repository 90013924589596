import ErrorBoundary from 'components/ErrorBoundary';
import React from 'react';
import { LastLocationProvider } from 'react-router-last-location';

import { AuthProvider } from './auth';
import { HelpBaseProvider } from './help';
import { LayoutProvider } from './layout';
import { NotificationsProvider } from './notifications';

const AppProvider: React.FC = ({ children }) => (
  <LastLocationProvider>
    <LayoutProvider>
      <ErrorBoundary>
        <AuthProvider>
          <NotificationsProvider>
            <HelpBaseProvider>{children}</HelpBaseProvider>
          </NotificationsProvider>
        </AuthProvider>
      </ErrorBoundary>
    </LayoutProvider>
  </LastLocationProvider>
);

export default AppProvider;
