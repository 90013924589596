import { toast } from 'react-toastify';
import history from 'services/history';
import { getWebsitesService, updateWebsiteService } from 'services/website';

export const setLoading = (dispatch, value) => {
  dispatch({
    type: 'SET_LOADING',
    payload: value,
  });
};

export const setUpdateLoading = (dispatch, value) => {
  dispatch({
    type: 'SET_UPDATE_LOADING',
    payload: value,
  });
};

const mapPriorityStatus = (priority: number) => {
  switch (priority) {
    case 0:
      return undefined;
    case 1:
      return false;
    case 2:
      return true;
    default:
      true;
  }
};

export const getWebsites = async (
  dispatch,
  page,
  search,
  filter,
  clouds,
  order,
  ctrl,
) => {
  setLoading(dispatch, true);
  try {
    const { data } = await getWebsitesService(
      {
        ...search,
        page,
        filter,
        clouds,
        [`${order?.column}_order`]: mapPriorityStatus(order?.value),
      },
      ctrl,
    );

    dispatch({
      type: 'GET_WEBSITES',
      payload: data,
    });
    setLoading(dispatch, false);
  } catch (e) {
    if (e.message !== 'canceled') setLoading(dispatch, false);
    if (e?.response?.status === 404) {
      history.push('/404');
    }
  }
};

export const updateWebsite = async (
  dispatch,
  field,
  value,
  id,
  website = null,
) => {
  try {
    setUpdateLoading(dispatch, true);

    const { data } = await updateWebsiteService(
      website.id || id,
      website || {
        [field]: value,
      },
    );

    toast.success('Suas alterações estão sendo aplicadas');

    dispatch({
      type: 'UPDATE_WEBSITE',
      payload: data,
    });
    setUpdateLoading(dispatch, false);
  } catch (e) {
    dispatch({
      type: 'UPDATE_WEBSITE_ERROR',
      payload: e?.response?.data,
    });
    setUpdateLoading(dispatch, false);
  }
};

export const updateField = (dispatch, field, value, website) => {
  dispatch({
    type: 'UPDATE_WEBSITE_FIELD',
    payload: {
      website,
      field: {
        slug: field,
        value: value.toString(),
      },
    },
  });
};
