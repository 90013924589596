import React from 'react';
import Placeholder from 'components/Placeholder';
import { Col, Row } from 'react-bootstrap';
import { CloudCard, Content, Logo, Disk } from '../styles';

const CloudCardSkeleton = () => (
  <Col
    lg="2"
    md="3"
    sm="4"
    xs="6"
    style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <CloudCard>
      <Content>
        <Logo>
          <Placeholder circle width={45} height={45} />
        </Logo>
      </Content>
      <Placeholder width={100} height={14} />
      <Placeholder width={60} height={10} />
    </CloudCard>
  </Col>
);

export default function CloudPlaceholder() {
  return (
    <Row>
      <CloudCardSkeleton />
      <CloudCardSkeleton />
      <CloudCardSkeleton />
      <CloudCardSkeleton />
      <CloudCardSkeleton />
      <CloudCardSkeleton />
    </Row>
  );
}
