import React, { useContext, useEffect, useMemo, useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { Button } from '@cloudez/cloudez-design-system';
import Progress from 'components/Progress';
import { CreateWrapper, CreateHeader, CreateButtons } from 'components/Common';

import useDevice from 'hooks/device';
import { CloudCreateContext, CloudCreateProvider } from './_context/state';

import Plan from './Plan';
import Payment from './Payment';
import Details from './Details';
import PlanTypes from './PlanTypes';
import Suggestion from './Suggestion';

const CloudCreate = () => {
  const [stage, setStage] = useState(1);

  const [loading, setLoading] = useState(false);

  const { planType, setPlanType } = useContext(CloudCreateContext);

  const stageTitle = useMemo(() => {
    switch (stage) {
      case 1:
        return 'Do que você precisa?';
      case 2:
        return 'Escolha o seu plano';
      case 3:
        return 'Detalhes do Pedido';
      case 4:
        return 'Pagamento';
      default:
        return '';
    }
  }, [stage]);

  const stageSubtitle = 'Vamos te indicar a melhor solução para o seu caso';

  const { isMobile } = useDevice();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const [tab, setTab] = useState(1);

  const recommendation = urlParams.get('recommendation')?.split(',');

  const [selectedPlan, setSelectedPlan] = useState(null);

  useEffect(() => {
    if (!recommendation) {
      setStage(1);
    } else if (recommendation && !selectedPlan) {
      setStage(2);
    } else if (recommendation && selectedPlan && !planType) {
      setStage(3);
    } else if (stage === 4 && planType) {
      setStage(4);
    }
  }, [recommendation, selectedPlan, planType]);

  return (
    <CreateWrapper>
      <CreateHeader>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            marginBottom: '24px',
          }}
        >
          <h1>{stageTitle}</h1>
          {stage === 1 && <span>{stageSubtitle}</span>}
        </div>
        <Progress status={stage} n={3} labels={['', '', '']} />
      </CreateHeader>

      {stage === 1 && <Suggestion setStage={setStage} />}

      {stage === 2 && (
        <Plan
          tab={tab}
          setTab={setTab}
          setSelectedPlan={setSelectedPlan}
          setStage={setStage}
        />
      )}

      {stage === 3 && <Details stage={stage} setStage={setStage} />}

      {stage === 4 && (
        <Payment
          setStage={setStage}
          loading={loading}
          setLoading={setLoading}
        />
      )}

      {stage === 2 && (
        <>
          <CreateHeader>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '8px',
              }}
            >
              <h1>Conheça os tipos de plano</h1>
            </div>
          </CreateHeader>
          <PlanTypes tab={tab} />
        </>
      )}

      <CreateButtons align="left" reverseColumn>
        {stage >= 3 && (
          <Button
            outline
            onClick={() => {
              setStage(stage - 1);
              stage === 4 && setPlanType('');
              stage === 3 && setSelectedPlan(null);
            }}
            style={{
              marginTop: '20px',
            }}
          >
            Voltar
          </Button>
        )}

        {stage === 3 && (
          <Button
            style={{ marginTop: '20px' }}
            onClick={() => {
              setStage(4);
              const { fbq } = window as any;
              const { gtag } = window as any;

              if (fbq) {
                fbq('track', 'InitiateCheckout');
              }

              if (gtag) {
                gtag('event', 'track', {
                  event_category: 'InitiateCheckout',
                });
              }
            }}
          >
            Avançar
          </Button>
        )}

        {isMobile && stage === 4 && (
          <Button
            style={{ marginTop: '20px' }}
            type="submit"
            form="infoFormPayment"
            disabled={loading}
            icon
            block
          >
            {loading ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              'Finalizar pedido'
            )}
          </Button>
        )}
      </CreateButtons>
    </CreateWrapper>
  );
};

export default props => (
  <CloudCreateProvider>
    <CloudCreate {...props} />
  </CloudCreateProvider>
);
