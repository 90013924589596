import React from 'react';

import { useHistory } from 'react-router-dom';

import moment from 'moment';

import {
  faFileInvoiceDollar,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Container, Content } from './styles';
import { useMigCreateAssisted } from '../../MigrationCreateNew/contexts/MigAssistedContext';

type Migration = {
  domain: string;
  assisted_targets: string;
  assisted_info: string;
  assisted_value: number;
  invoice_id: number;
  invoice_valid_until: string;
};

interface SuccessProps {
  migration: Migration;
}

const Success = ({ migration }: SuccessProps): JSX.Element => {
  const { cacheData } = useMigCreateAssisted();

  const history = useHistory();

  const item = cacheData || migration;

  return (
    <Container>
      <Content>
        <div className="title">
          <FontAwesomeIcon icon={faInfoCircle} />
          <span>Detalhes da Migração Assistida</span>
        </div>
        <div className="requestData">
          <p>Dados da Solicitação</p>
          <span>
            <b>Domínio:</b> {item.domain}
          </span>
          <span>
            <b>Tipo de Migração:</b> {item.assisted_targets}
          </span>
          <div className="migrationDetail">
            <p>Detalhes da Migração:</p>
            <big>{item.assisted_info}</big>
          </div>
          <div className="value">
            <span>Valor</span>
            <b>
              {' '}
              {new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(item.assisted_value)}
            </b>
          </div>
        </div>
      </Content>
      <Content success>
        <div style={{ maxWidth: '472px' }}>
          <h1>Seu pedido de migração assistida foi realizado com sucesso!</h1>
          <div className="attention">
            <b>ATENÇÃO</b>
            <span>
              Para darmos início ao seu pedido, é necessário a realização prévio
              do pagamento da fatura. Assim que nosso sistema reconhecer o
              pagamento, daremos início a sua migração que poderá ser
              acompanhada pelo ticket gerado.
            </span>
          </div>

          <button
            onClick={() => history.push(`/invoices/${item.invoice_id}`)}
            className="accessInvoiceButton"
            type="button"
          >
            Acesse sua Fatura <FontAwesomeIcon icon={faFileInvoiceDollar} />
          </button>

          <div className="infoInvoice">
            <span>
              <b>Valor:</b>{' '}
              {new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',
              }).format(item.assisted_value)}
            </span>
            <span>
              <b>Vencimento:</b>{' '}
              {moment(item.invoice_valid_until).format('DD/MM/YYYY')}
            </span>
          </div>
        </div>
      </Content>
    </Container>
  );
};

export default Success;
