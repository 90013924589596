import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;

  margin-top: 56px;
`;

interface CardProps {
  selected: boolean;
}

export const Card = styled.div<CardProps>`
  min-height: 160px;
  width: 270px;

  cursor: pointer;

  text-align: center;

  ${props =>
    props.selected &&
    css`
      border: 2px solid ${props => props.theme.brand_primary_color};
    `}

  justify-content: center;
  gap: 16px;

  display: flex;
  flex-direction: column;
  align-items: center;

  background-color: ${props => props.theme.interface1};

  padding: 16px;

  box-shadow: 0px 2px 15px rgba(100, 114, 140, 0.25);
  border-radius: 5px;

  .title {
    font-weight: 700;
    font-size: 16px;
  }

  .subtitle {
    font-weight: 400;
    font-size: 12px;
    color: ${props => props.theme.interface5};
  }

  .types {
    display: flex;
    align-items: center;
    gap: 15px;
  }
`;

interface PlanTypeProps {
  bubbleColor?: boolean;
}

export const PlanType = styled.div<PlanTypeProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;

  font-weight: 400;
  font-size: 12px;

  color: ${props => props.theme.interface5};

  .bubble {
    width: 8px;
    height: 8px;
    border-radius: 50%;

    background-color: ${props =>
      props.bubbleColor ? props.theme.successNew : props.theme.interface4};
  }
`;
