import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;

  background: ${props => props.theme.interface1};
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 30px 50px;
`;

export const Select = styled.div`
  width: 50%;
  height: 100%;
  padding: 30px 90px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;

  & > img {
    background: ${props => props.theme.brand_primary_color};
    width: 110px;
  }

  & > p {
    text-align: center;
    color: ${props => props.theme.interface5};
    font-size: 13px;
    margin: 20px 0;
  }

  @media (max-width: 768px) {
    border-left: 0px solid ${props => props.theme.interface3};
    width: 100%;
    height: 200px;
    padding: 0px;
    margin-bottom: 20px;
  }
`;

interface ICloudCardProps {
  selected?: boolean;
}

export const CloudCard = styled.div<ICloudCardProps>`
  cursor: pointer;
  background: ${props => props.theme.interface1};
  width: 230px;
  height: 110px;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 15px 0px;
  border: 2px solid transparent;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  ${props =>
    props.selected &&
    css`
      border: 2px solid
        ${props =>
          props.theme.darkMode
            ? props.theme.interface5
            : props.theme.brand_primary_color};
    `}

  &:hover {
    transition: all 0.2s ease-in;
    border: 2px solid
      ${props =>
        props.theme.darkMode
          ? props.theme.interface5
          : props.theme.brand_primary_color};
  }

  span {
    margin-top: 10px;
    font-size: 14px;
    color: ${props => props.theme.interface5};
  }

  p {
    font-size: 10px;
    color: ${props => props.theme.interface5};
  }
`;

export const Content = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 10px;
`;

export const Logo = styled.div`
  padding: 0px 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Disk = styled.div`
  padding: 0px 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
`;
