import React, { useEffect, useState } from 'react';

import {
  faBell,
  faChevronDown,
  faMoon,
  faSun,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import {
  faBell as fasBell,
  faMoon as fasMoon,
  faSun as fasSun,
  faQuestionCircle as fasQuestionCircle,
} from '@fortawesome/free-regular-svg-icons';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLayout } from 'hooks/layout';
import { useAuth } from 'hooks/auth';
import { useNotifications } from 'hooks/notifications';
import { Wrapper, Container, IconList, IconItem, User, Count } from './styles';
import CollapseButton from './CollapseButton';
import UserDropdown from './UserDropdown';
import NavbarElipse from './NavbarElipse';
import NotificationsDropdown from './NotificationsDropdown';

interface INavbarProps {
  hasNotification?: boolean;
}

const Navbar: React.FC<INavbarProps> = ({ hasNotification }) => {
  const { toggleDarkMode, slug, is_provider } = useLayout();
  const theme = useLayout();
  const { user } = useAuth();

  const isAuthenticated = !!user;
  const { notifications } = useNotifications();

  const [dropdown, setDropdown] = useState(false);
  const [notificationsDropdown, setNotificationsDropdown] = useState(false);

  const count = notifications?.filter(
    n => n && n.status === 0 && n.type !== 'chat',
  ).length;

  return (
    <Wrapper hasNotification={hasNotification}>
      <CollapseButton />

      <Container>
        <IconList>
          {isAuthenticated && (
            <>
              {!is_provider && (
                <>
                  <IconItem
                    onClick={() => {
                      window.open('https://help.cloudez.io', '_blank');
                    }}
                    hideOnMobile
                  >
                    <NavbarElipse
                      icon={faQuestionCircle}
                      hover={fasQuestionCircle}
                    />
                  </IconItem>
                </>
              )}
              <IconItem
                onClick={() => {
                  setDropdown(false);
                  setNotificationsDropdown(!notificationsDropdown);
                }}
              >
                <NavbarElipse icon={faBell} hover={fasBell} />
                {count > 0 && <Count>{count}</Count>}
                {notificationsDropdown && (
                  <NotificationsDropdown
                    setDropdown={setNotificationsDropdown}
                  />
                )}
              </IconItem>
              <IconItem
                onClick={() => {
                  toggleDarkMode();
                }}
                hideOnMobile
              >
                {theme.darkMode ? (
                  <NavbarElipse icon={faSun} hover={fasSun} />
                ) : (
                  <NavbarElipse icon={faMoon} hover={fasMoon} />
                )}
              </IconItem>
              <IconItem
                onClick={() => {
                  setNotificationsDropdown(false);
                  setDropdown(!dropdown);
                }}
              >
                <User active={dropdown}>
                  <div>
                    <img
                      alt="avatar"
                      style={{
                        width: '30px',
                        height: '30px',
                        borderRadius: '50%',
                      }}
                      src={user.avatar}
                    />
                    <span>{user.full_name}</span>
                  </div>
                  <FontAwesomeIcon icon={faChevronDown} />
                </User>
                {dropdown && (
                  <UserDropdown setDropdown={setDropdown} dropdown={dropdown} />
                )}
              </IconItem>
            </>
          )}
        </IconList>
      </Container>
    </Wrapper>
  );
};

export default Navbar;
