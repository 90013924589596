import React, { useState, useEffect, useMemo, useContext } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { Button, Stepper } from '@cloudez/cloudez-design-system';

import { CreateWrapper, CreateHeader, CreateButtons } from 'components/Common';
import { useLayout } from 'hooks/layout';
import { useAuth } from 'hooks/auth';

import toastError from 'utils/toastError';

import SharingWebsitePlans from './SharingWebsitePlans';
import NotRecommendedModal from './NotRecommendedModal';
import CloudSharingPlans from './CloudSharingPlans';
import AdvancedOptions from './AdvancedOptions';
import WebsiteTypes from './WebsiteTypes';
import Payment from './Payment';
import Success from './Success';
import Domain from './Domain';
import Cloud from './Cloud';

import { WebsiteCreateProvider, WebsiteCreateContext } from './_context/state';

const WebsiteCreate = () => {
  const { has_sharing, is_provider } = useLayout();
  const {
    website,
    database,
    createWebsite,
    errors,
    loading,
    notRecommended,
    setNotRecommended,
  } = useContext(WebsiteCreateContext);

  const {
    user: { has_pending_plan },
  } = useAuth();

  const [openNotRecommended, setOpenNotRecommended] = useState(false);
  const [advancedOptions, setAdvancedOptions] = useState(false);
  const [stage, setStage] = useState<any>(1);
  const [buy, setBuy] = useState<any>(false);
  const [noDb, setNoDb] = useState(false);
  const [isSharing, setIsSharing] = useState(has_sharing);

  useEffect(() => {
    if (errors) {
      if (errors?.values?.domain) {
        setStage(2);
      } else {
        toastError(errors);
      }
    }
  }, [errors]);

  const _createWebsite = async (buyDomain = false, isProvider = false) => {
    if (advancedOptions) {
      createWebsite(website, database, noDb, buyDomain);
    } else {
      createWebsite(website, null, null, buyDomain, isProvider);
    }
  };

  const isAdvancedSettings = useMemo(
    () => advancedOptions || website.type === 110,
    [website, advancedOptions],
  );

  const stageLabel = useMemo(() => {
    switch (stage) {
      case 1:
        return 'Selecione a aplicação';
      case 2:
        return 'Você possui um domínio?';
      case 3:
        return 'Escolha um destino';
      case 4:
        if (isAdvancedSettings) return 'Opções avançadas.';
        if (buy && !isAdvancedSettings) return 'Pagamento.';
        break;
      case 5:
        if (buy && isAdvancedSettings) return 'Pagamento.';
        break;
      case 6:
        return 'Escolha um plano';
      default:
        return '';
    }
  }, [stage, isAdvancedSettings, buy]);

  return (
    <>
      {openNotRecommended && (
        <NotRecommendedModal
          show={openNotRecommended}
          setShow={setOpenNotRecommended}
          notRecommended={notRecommended}
          setNotRecommended={setNotRecommended}
          onConfirm={
            buy || isAdvancedSettings
              ? () => setStage(state => state + 1)
              : _createWebsite
          }
        />
      )}
      {stage !== 'success' && (
        <CreateWrapper>
          <CreateHeader>
            <h1>{stageLabel}</h1>

            <div style={{ minWidth: '50%', marginTop: '1rem' }}>
              <Stepper
                isMobile={false}
                steps={
                  buy && isAdvancedSettings
                    ? 5
                    : isAdvancedSettings || buy
                    ? 4
                    : 3
                }
                activeStep={stage}
              />
            </div>
          </CreateHeader>

          {stage === 1 && (
            <WebsiteTypes
              advancedOptions={isAdvancedSettings}
              setAdvancedOptions={setAdvancedOptions}
            />
          )}

          {stage === 2 && <Domain errors={errors} buy={buy} setBuy={setBuy} />}

          {stage === 3 &&
            !has_pending_plan &&
            !is_provider &&
            (has_sharing && isSharing ? (
              <CloudSharingPlans
                setIsSharing={setIsSharing}
                setStage={setStage}
                stage={stage}
              />
            ) : (
              <Cloud />
            ))}

          {stage === 4 && isAdvancedSettings && !buy && (
            <AdvancedOptions errors={errors} setNoDb={setNoDb} />
          )}

          {stage === 4 && buy && !isAdvancedSettings && (
            <Payment
              createWebsite={_createWebsite}
              domain={{
                ...buy,
                domain_do_hire: true,
              }}
              setStage={setStage}
            />
          )}

          {stage === 4 && isAdvancedSettings && buy && (
            <AdvancedOptions errors={errors} setNoDb={setNoDb} />
          )}

          {stage === 5 && buy && isAdvancedSettings && (
            <Payment
              createWebsite={_createWebsite}
              domain={{
                ...buy,
                domain_do_hire: true,
              }}
              setStage={setStage}
            />
          )}

          {stage === 6 && (
            <SharingWebsitePlans
              setStage={setStage}
              setIsSharing={setIsSharing}
            />
          )}

          {!(has_sharing && ((stage === 3 && isSharing) || stage === 6)) && (
            <CreateButtons>
              {stage >= 2 && (
                <Button
                  outline
                  onClick={() => {
                    if (has_sharing && stage === 3 && !isSharing) {
                      setIsSharing(true);
                    } else setStage(stage - 1);
                  }}
                  style={{
                    marginTop: '20px',
                  }}
                >
                  Voltar
                </Button>
              )}

              {(stage === 4 && buy && !isAdvancedSettings) ||
              (stage === 5 && buy && isAdvancedSettings) ? (
                <Button
                  disable={loading}
                  style={{
                    marginTop: '20px',
                  }}
                  icon
                  type="submit"
                  form="domainWebsiteForm"
                  data-id="websites-finalbtn"
                >
                  {loading ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    'Finalizar'
                  )}
                </Button>
              ) : (
                <Button
                  disabled={
                    loading ||
                    (stage === 1 && !website.type) ||
                    (stage === 3 && !website.cloud)
                  }
                  style={{
                    marginTop: '20px',
                  }}
                  onClick={() => {
                    if (isAdvancedSettings) {
                      if (buy || stage < 4) {
                        if (stage === 3 && notRecommended.open) {
                          setOpenNotRecommended(true);
                        } else {
                          setStage(stage + 1);
                        }
                      } else {
                        _createWebsite();
                      }
                    } else if (
                      stage === 2 &&
                      (has_pending_plan || is_provider)
                    ) {
                      _createWebsite(false, has_pending_plan || is_provider);
                    } else if (buy || stage < 3) {
                      setStage(stage + 1);
                    } else if (stage === 3 && notRecommended.open) {
                      setOpenNotRecommended(true);
                    } else {
                      _createWebsite();
                    }
                  }}
                  icon
                  data-id={
                    stage === 1
                      ? 'websites-next-apps'
                      : stage === 2
                      ? 'websites-next-domain'
                      : !(isAdvancedSettings && stage < 4) &&
                        !(buy && stage < 4) &&
                        !(buy && isAdvancedSettings && stage < 5) &&
                        !(stage < 3)
                      ? 'websites-finalbtn'
                      : ''
                  }
                >
                  {loading ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : isAdvancedSettings && stage < 4 ? (
                    'Próximo passo'
                  ) : buy && stage < 4 ? (
                    'Próximo passo'
                  ) : buy && isAdvancedSettings && stage < 5 ? (
                    'Próximo passo'
                  ) : stage === 2 && (has_pending_plan || is_provider) ? (
                    'Finalizar'
                  ) : stage < 3 ? (
                    'Próximo passo'
                  ) : (
                    'Finalizar'
                  )}
                </Button>
              )}
            </CreateButtons>
          )}
        </CreateWrapper>
      )}

      {stage === 'success' && <Success />}
    </>
  );
};

export default props => (
  <WebsiteCreateProvider>
    <WebsiteCreate {...props} />
  </WebsiteCreateProvider>
);
