import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase, faSpinner } from '@fortawesome/free-solid-svg-icons';

import { Modal, Button } from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Footer,
} from 'components/ListingModal/styles';
import { ModalProps } from 'types';

interface IAdminModalProps extends ModalProps {
  type?: any;
}

const AdminModal: React.FC<IAdminModalProps> = ({
  action,
  show,
  setShow,
  type,
}) => {
  const [loading, setLoading] = useState(false);

  return (
    <Modal show={show} setShow={setShow} noExit={loading}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faDatabase} />
        </Icon>
        <Text>
          <Title>{type === 'mysql' ? 'PhpMyAdmin' : 'PhpPgAdmin'}</Title>
          <Subtitle>
            Deseja ativar o {type === 'mysql' ? 'PhpMyAdmin' : 'PhpPgAdmin'}?
          </Subtitle>
        </Text>
      </Header>

      <Footer>
        <Button
          icon
          disabled={loading}
          onClick={async () => {
            setLoading(true);
            await action();
            setLoading(false);
            setShow();
          }}
        >
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Ativar'}
        </Button>
      </Footer>
    </Modal>
  );
};

export default AdminModal;
