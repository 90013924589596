import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 16px;
  gap: 10px;

  background: ${props => props.theme.interface1};};
  border: 2px solid ${props => props.theme.interface3};
  border-radius: 5px;

  .title {
    width: 100%;

    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;
  }

  .typeAccess {
    
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;

    color: ${props => props.theme.interface5};
  }

  .activeSession {
    margin-left: auto;

    
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;

    background-color: ${props => props.theme.successNew};
    color: ${props => props.theme.interface1};
    padding: 1px 5px;
    border-radius: 23px;
  }

  .info {
    
    font-weight: bold;
    font-size: 12px;
    line-height: 140%;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 293px;

    color: ${props => props.theme.interface5};

    span {
      font-weight: normal;
    }
  }

  button {
    
    font-weight: bold;
    font-size: 14px;
    line-height: 140%;

    border: 0;
    background: transparent;

    color: ${props => props.theme.errorNew};
  }
`;
