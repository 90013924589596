import React, { useState } from 'react';
import * as Yup from 'yup';

import {
  Button,
  FormInput,
  Form,
  Field,
  Label,
  Error,
} from '@cloudez/cloudez-design-system';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { createEmailAccountPasswordService } from 'services/email';
import { Wrapper } from './styles';

const schema = Yup.object().shape({
  email: Yup.string()
    .email('Insira um email válido.')
    .required('O email é obrigatório.'),
  old_password: Yup.string().required('A senha antiga é obrigatória.'),
  new_password: Yup.string().required('A nova senha obrigatória.'),
});

const ResetEmailPassword: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState(null);
  const [done, setDone] = useState(null);

  const handleSubmit = async e => {
    try {
      setLoading(true);
      const { data } = await createEmailAccountPasswordService(e);
      setLoading(false);
      setDone(data);
    } catch (e) {
      setLoading(false);
      setErrors(e?.response?.data);
    }
  };

  return (
    <Wrapper>
      <h1
        style={{
          fontSize: '2em',
          fontWeight: 'bolder',
          textAlign: 'center',
          marginBottom: '30px',
        }}
      >
        {done || 'Email account password reset'}
      </h1>
      {!done && (
        <Form schema={schema} onSubmit={handleSubmit}>
          <Field>
            <Label>Email</Label>
            <FormInput
              type="email"
              block
              name="email"
              error={!!errors?.email}
            />
            {errors?.email && <Error>{errors?.email[0]}</Error>}
          </Field>
          <Field>
            <Label>Senha antiga</Label>
            <FormInput
              type="password"
              block
              name="old_password"
              error={!!errors?.old_password}
            />
            {errors?.old_password && <Error>{errors?.old_password[0]}</Error>}
          </Field>
          <Field>
            <Label>Nova senha</Label>
            <FormInput
              type="password"
              block
              name="new_password"
              error={!!errors?.new_password}
            />
            {errors?.new_password && <Error>{errors?.new_password[0]}</Error>}
          </Field>
          <Button
            type="submit"
            size="lg"
            style={{
              width: '100%',
            }}
            icon
            disabled={loading}
          >
            {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Enviar'}
          </Button>
        </Form>
      )}
    </Wrapper>
  );
};

export default ResetEmailPassword;
