import api from 'services/api';

export const getSurveySlugService = async (slug, params?) => {
  try {
    return await api.get(`/v3/survey/${slug}/`, { params });
  } catch (e) {
    throw e;
  }
};

export const answerSurveyService = async data => {
  try {
    return await api.post(`/v3/answer-template/`, data);
  } catch (e) {
    throw e;
  }
};

export const getAnswersPerSurveyService = async survey => {
  try {
    return await api.get(`/v3/answer/`, {
      params: {
        survey,
      },
    });
  } catch (e) {
    throw e;
  }
};
