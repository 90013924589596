import styled from 'styled-components';

export const TitleText = styled.span`
  font-weight: 700;
  font-size: 14px;

  color: #a2a9bf;
`;

export const InfoCard = styled.div`
  height: auto;
  padding: 15px;
  background: ${props => props.theme.interface1};
  border-radius: 5px;

  .info {
    height: 100%;
    width: 100%;
    font-size: 12px;
    color: ${props => props.theme.interface5};

    display: flex;
    flex-direction: column;
    gap: 20px;

    margin-top: 14px;

    p {
      span {
        color: ${props => props.theme.interface4};
      }
    }
  }
`;

export const CardHeader = styled.h3`
  font-size: 16px;
  color: ${props => props.theme.interface5};
`;
