import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  gap: 10px;

  font-weight: 400;
  font-size: 16px;

  padding: 20px;

  color: ${props => props.theme.interface5};

  .box {
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .successBox {
    display: flex;
    flex-direction: column;
    gap: 14px;

    .success {
      font-size: 16px;

      color: ${props => props.theme.successNew};
      font-weight: bold;
    }
  }
`;
