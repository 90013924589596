import styled from 'styled-components';

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 19px 21px;
  gap: 22px;

  color: ${props =>
    props.theme.darkMode ? props.theme.interface3 : props.theme.interface7};

  font-size: 16px;
  line-height: 140%;

  .sendCode {
    color: ${props => props.theme.brand_primary_color};
    font-weight: 600;
    cursor: pointer;
    transition: 0.2s;

    &:hover {
      opacity: 0.9;
    }
  }
`;
