import styled from 'styled-components';

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px 40px 0px;
  color: ${props => props.theme.interface7};
`;

export const MigrationWrapper = styled.div`
  width: 100%;
  height: 100%;

  background: ${props => props.theme.interface1};
  border-radius: 5px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 30px 50px;
`;

export const MigrationSelect = styled.div`
  width: 50%;
  height: 100%;
  padding: 30px 90px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;

  & > img {
    background: ${props => props.theme.brand_primary_color};
    width: 110px;
  }

  & > p {
    text-align: center;
    color: ${props => props.theme.interface5};
    font-size: 13px;
    margin: 20px 0;
  }

  @media (max-width: 768px) {
    border-left: 0px solid ${props => props.theme.interface3};
    width: 100%;
    height: 200px;
    padding: 0px;
    margin-bottom: 20px;
  }
`;

export const AssistedMigration = styled.div`
  width: 100%;
  height: 100%;
  padding: 30px 90px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;

  & > div {
    background: ${props => props.theme.brand_primary_color};
    width: 99px;

    img {
      margin-bottom: -4px;
      width: 100px;
    }
  }

  & > p {
    max-width: 300px;
    text-align: center;
    color: ${props => props.theme.interface5};
    font-size: 13px;
    margin: 20px 0;
  }

  @media (max-width: 1210px) {
    border-left: 0px solid ${props => props.theme.interface3};
    width: 100%;
    padding: 30px 20px;
  }
`;

export const MigrationInfo = styled.div`
  padding-right: 50px;
  width: 50%;
  height: 100%;

  p {
    color: ${props => props.theme.interface5} !important;
  }

  p + p {
    margin-top: 6px;
  }

  div + div {
    margin-top: 30px;

    ul {
      list-style: none;
      flex-wrap: wrap;

      li {
        padding: 12px 13px;
        cursor: pointer;
        border: 1px solid transparent;

        ul {
          display: flex;
          list-style: initial;
          width: 100%;
          justify-content: space-around;
          padding: 12px 13px;

          li {
            padding: 0;
            color: ${props => props.theme.brand_primary_color} !important;
          }
        }

        &:nth-child(odd) {
          background-color: ${props => props.theme.interface2};
        }

        &:hover {
          color: ${props => props.theme.brand_primary_color};
          border: 1px solid ${props => props.theme.brand_primary_color};
        }
      }
    }
  }

  h1 {
    color: ${props => props.theme.brand_primary_color};
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  h3 {
    color: ${props => props.theme.interface5};
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 18px;
  }

  p {
    color: ${props => props.theme.interface4};
    text-align: left;
    line-height: 1.74;
  }

  @media (max-width: 1210px) {
    padding-right: 0px;
    width: 100%;
  }
`;
