import { Button } from '@cloudez/cloudez-design-system';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;

  gap: 20px;

  a {
    color: ${props => props.theme.interface5};
    text-decoration: underline;
  }

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const handleStatusType = props => {
  switch (props.status) {
    case 4:
      return '#29D975';
    case 2:
    case 3:
    case 6:
      return '#FFBF2D';
    case 7:
      return '#2253F5';
    case 1:
      return '#333333';
    case 5:
      return '#CFD3DE';
    default:
      return null;
  }
};

interface StatusBoxProps {
  success?: boolean;
  status?: number;
}

export const StatusBox = styled.div<StatusBoxProps>`
  background: ${props => handleStatusType(props)};
  border-radius: 20px;

  font-weight: 400;
  font-size: 12px;

  padding: 6px 10px;

  color: ${props => props.theme.interface1};
`;

const handleTypeContent = props => {
  switch (props.type) {
    case 'canceled':
      return props.theme.interface5;
    case 'success':
      return props.theme.successNew;
    default:
      return props.theme.interface1;
  }
};

interface ContentProps {
  type?: 'success' | 'canceled';
}

export const Content = styled.div<ContentProps>`
  width: 50%;

  @media (max-width: 900px) {
    width: 100%;
  }

  padding: 32px;

  box-shadow: 0px 2px 15px rgba(100, 114, 140, 0.25);
  border-radius: 5px;

  background-color: ${props => handleTypeContent(props)};

  h1 {
    font-size: 36px;
    line-height: 120%;

    color: ${props => props.theme.interface1};

    margin-bottom: 10px;
  }

  a {
    transition: opacity 0.2s;

    &:hover {
      opacity: 0.8;
    }
  }

  .cancelledText {
    color: ${props => props.theme.interface1};
  }

  .attention {
    display: flex;
    flex-direction: column;

    margin-bottom: 32px;

    color: ${props => props.theme.interface1};

    b {
      font-weight: bold;
      font-size: 16px;
      line-height: 140%;
    }
  }

  .progress {
    margin-top: 24px;

    font-size: 16px;
    line-height: 25px;

    color: ${props => props.theme.successNew};
  }

  .title {
    gap: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;

    color: ${props => props.theme.brand_primary_color};

    font-size: 16px;
    line-height: 25px;

    span {
      font-weight: bold;
    }

    svg {
      width: 23px;
      height: 23px;
    }
  }

  p {
    color: ${props => props.theme.brand_primary_color};
    font-weight: bold;
    font-size: 14px;
  }

  .destData,
  .tempMail,
  .relatedTickets,
  .originData {
    display: flex;
    flex-direction: column;
    gap: 15px;

    margin-top: 24px;

    color: ${props => props.theme.interface5};

    .tickets {
      div {
        display: flex;
        flex-direction: row;
        gap: 5px;

        .ticketId {
          color: ${props => props.theme.interface5};
          text-decoration: underline;
          cursor: pointer;
        }

        .ticketStatus {
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }

  .infoAppointment {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 26px;

    font-size: 16px;
    line-height: 140%;

    color: ${props => props.theme.interface2};
  }

  .reviewProgress {
    color: ${props => props.theme.brand_primary_color};

    margin-top: 24px;

    width: fit-content;

    text-decoration: underline;
    transition: opacity 0.2s;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }

    font-weight: bold;
    font-size: 14px;
  }

  .tempMail {
    div {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  .status {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 24px;

    gap: 8px;
  }

  .webmail {
    display: flex;
    flex-direction: column;

    font-weight: normal;
    font-size: 16px;
    line-height: 25px;

    color: ${props => props.theme.interface1};

    a {
      color: ${props => props.theme.interface1};
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .domainsContent {
    display: flex;
    flex-direction: column;

    margin-top: 24px;

    b {
      font-weight: bold;
      font-size: 18px;
      line-height: 140%;

      color: ${props => props.theme.interface1};
    }

    .domains {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      margin-top: 24px;

      gap: 20px;

      color: ${props => props.theme.interface1};
    }
  }

  .migrationDetail {
    display: flex;
    flex-direction: column;

    margin-top: 24px;

    gap: 24px;

    big {
      color: ${props => props.theme.interface5};
      font-size: 16px;
    }
  }

  .confirmButton {
    border-radius: 5px;
    border: 2px solid ${props => props.theme.interface1};

    background-color: transparent;

    align-items: center;
    justify-content: center;

    margin-bottom: 32px;

    width: 164px;
    height: 35px;

    font-weight: bold;
    font-size: 12px;
    color: ${props => props.theme.interface1};

    gap: 12px;

    display: flex;
    flex-direction: row;

    transition: filter 0.2s;

    &:hover {
      filter: brightness(95%);
    }
  }

  .infoInvoice {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    font-size: 18px;
    line-height: 200%;

    color: ${props => props.theme.interface1};
  }

  .confirmedMigration {
    margin-top: 24px;

    display: flex;
    flex-direction: column;

    color: ${props => props.theme.interface1};

    gap: 24px;

    b {
      font-size: 18px;
    }

    .confirmedDate {
      font-style: italic;
      font-size: 14px;
    }

    .comment {
      display: flex;
      flex-direction: column;
      gap: 5px;

      font-weight: bold;
      font-size: 14px;

      button {
        text-transform: none;
        font-weight: normal;
        border: 1px solid ${props => props.theme.interface3};
        background-color: rgba(245, 248, 255, 0.2);

        margin-left: auto;

        margin-top: 19px;
      }

      input {
        padding: 13px 22px 14px;

        background-color: rgba(245, 248, 255, 0.2);

        border: 1px solid ${props => props.theme.interface3};
        border-radius: 5px;

        color: ${props => props.theme.interface1};

        ::placeholder {
          font-weight: normal;
          font-size: 14px;

          color: ${props => props.theme.interface1};
        }
      }
    }
  }
`;

export const RatingButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 26px;
  align-items: center;
`;

interface RatingButtonProps {
  active: boolean;
}

export const RatingButton = styled(Button)<RatingButtonProps>`
  width: auto;
  font-size: 14px;
  border: 1px solid #ffffff;
  font-weight: 400;
  text-transform: none;
  padding: 10px 8px;
  gap: 9px;

  opacity: 1;

  color: ${props => (props.active ? props.theme.successNew : '#ffffff')};

  background-color: ${props =>
    props.active === false || props.active === null
      ? props.theme.successNew
      : '#ffffff'};

  &:hover {
    background-color: ${props => props.active && '#ffffff'} !important;
  }

  ${({ active }) =>
    active &&
    css`
      box-shadow: 0px 2px 15px rgba(100, 114, 140, 0.25);
    `}
`;

export const SupportBox = styled.div`
  background-color: ${props => props.theme.interface1};

  margin-top: 40px;

  padding: 31px 0 37px 58px;

  width: 100%;
`;
