import styled, { css } from 'styled-components';

export const Card = styled.div`
  height: 45px;
  width: 100%;
  background: ${props => props.theme.alertNew};
  border: 1px solid ${props => props.theme.alertNew};
  border-radius: 5px;
  padding: 0 20px;
  margin-bottom: 20px;

  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: ${props => props.theme.interface1};

  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    background: transparent;
    color: ${props => props.theme.interface1};

    font-weight: bold;
    font-size: 12px;
    line-height: 12px;
    padding: 5.5px 23.5px;

    border: 2px solid ${props => props.theme.interface1};
    border-radius: 5px;
  }
`;
