import React, { useState, useEffect } from 'react';
import { withTheme } from 'styled-components';
import { Row, Col } from 'react-bootstrap';

import { Label, Input, Select, Field } from '@cloudez/cloudez-design-system';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKey } from '@fortawesome/free-solid-svg-icons';
import generatePassword from 'utils/generatePassword';
import { getDatabaseUsersService } from 'services/database';
import { Existent } from './styles';

const Users = ({ newDatabase, setNewDatabase }) => {
  const [users, setUsers] = useState([]);
  const [user, setUser] = useState({
    username: '',
    password: '',
    host: '127.0.0.1',
    id: null,
  } as any);
  const [choose, setChoose] = useState(false);

  useEffect(() => {
    async function getDatabaseUsers() {
      try {
        const {
          data: { results },
        } = await getDatabaseUsersService({
          cloud: newDatabase.cloud.id,
          page_size: 100,
        });

        setUsers(results);
      } catch (e) {
        console.log(e);
      }
    }

    getDatabaseUsers();
    // eslint-disable-next-line
  }, []);

  return (
    <Row
      style={{
        marginLeft: 0,
        width: '100%',
      }}
    >
      {!choose ? (
        <>
          <Col xs="12">
            <Field>
              <Label>Usuário</Label>
              <Input
                block
                placeholder="Usuário"
                name="username"
                value={user.username}
                onChange={e => {
                  setUser({
                    ...user,
                    username: e.target.value,
                  });
                  setNewDatabase({
                    ...newDatabase,
                    users: [
                      {
                        ...user,
                        username: e.target.value,
                      },
                    ],
                  });
                }}
              />
            </Field>
            {/* {errors?.user?.username && (
                          <Error>{errors?.user?.username[0]}</Error>
                        )} */}
          </Col>
          <Col xs="12">
            <Field>
              <Label>Senha</Label>
              <Existent onClick={() => setChoose(true)}>
                Escolher existente
              </Existent>
              <Input
                block
                value={user.password}
                onChange={e => {
                  setUser({
                    ...user,
                    password: e.target.value,
                  });
                  setNewDatabase({
                    ...newDatabase,
                    users: [
                      {
                        ...user,
                        password: e.target.value,
                      },
                    ],
                  });
                }}
                placeholder="Senha"
                name="password"
              />
              <FontAwesomeIcon
                icon={faKey}
                onClick={() => {
                  const pass = generatePassword();

                  setUser({
                    ...user,
                    password: pass,
                  });
                  setNewDatabase({
                    ...newDatabase,
                    users: [
                      {
                        ...user,
                        password: pass,
                      },
                    ],
                  });
                }}
              />
            </Field>
          </Col>
        </>
      ) : (
        <Col xs="12">
          <Label>Selecionando um usuário</Label>
          <Existent onClick={() => setChoose(false)}>Criar novo</Existent>
          <Select
            height="40px"
            value={user}
            onChange={e => {
              setUser(e.target.value);
              setNewDatabase({
                ...newDatabase,
                users: [JSON.parse(e.target.value)],
              });
            }}
          >
            <option value="">Selecione um usuário</option>
            {!!users.length &&
              users.map(u => (
                <option key={u.id} value={JSON.stringify(u)}>
                  {u.username}
                </option>
              ))}
          </Select>
        </Col>
      )}
    </Row>
  );
};

export default Users;
