import React from 'react';

import {
  faCheck,
  faCloud,
  faExclamationCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Modal, Button } from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Footer,
} from 'components/ListingModal/styles';

import { Alert } from 'components/Alert';
import { Content } from './styles';

interface SuccessModalProps {
  show: boolean;
  setShow: (value: boolean) => void;
  migration: any;
}

const SuccessModal = ({
  show,
  setShow,
  migration,
}: SuccessModalProps): JSX.Element => {
  const isEmail = migration.migration_target === 'email';
  return (
    <Modal width="455px" show={show} setShow={setShow}>
      <Header>
        <Icon color={isEmail ? 'rgba(41, 217, 117, 1)' : undefined}>
          <FontAwesomeIcon
            style={{ fontSize: '20px' }}
            icon={isEmail ? faCheck : faCloud}
          />
        </Icon>
        <Text>
          <Title>
            {isEmail
              ? 'Migração de email criada com sucesso'
              : 'Migração iniciada'}
          </Title>
          {!isEmail && (
            <Subtitle>Sua migração foi iniciada com sucesso!</Subtitle>
          )}
        </Text>
      </Header>

      <Content>
        {isEmail ? (
          <>
            <Alert info>
              <FontAwesomeIcon
                style={{ width: '20px', height: '20px', marginRight: '15px' }}
                icon={faExclamationCircle}
              />
              Preste atenção as informações a seguir pois elas são importantes e
              podem resolver dúvidas futuras
            </Alert>
            <span>
              Caso você use Webmail, é muito <b>IMPORTANTE</b> que se lembre de
              exportar os contatos.
            </span>
            <span>
              Qualquer dúvida é só abrir um ticket que estaremos aqui para
              auxiliar no que for necessário
            </span>
          </>
        ) : (
          <>
            <span className="green">
              Parabéns, a sua migração foi iniciada com sucesso!
            </span>
            <span>
              Você poderá acompanhar o processo de sua migração através da
              página inicial ou em seu e-mail.
            </span>
          </>
        )}
      </Content>

      <Footer>
        <Button width="149px" onClick={() => setShow(false)}>
          {isEmail ? 'OK' : 'Fechar'}
        </Button>
      </Footer>
    </Modal>
  );
};

export default SuccessModal;
