import styled from 'styled-components';

interface WrapperProps {
  isFocused?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  background: ${props => props.theme.interface1};
  margin-bottom: 10px;
  margin-top: 30px;
  width: 100%;
  height: auto;
  padding: 10px 4px;

  h3 {
    color: ${props => props.theme.interface5};
    padding: 8px 15px 20px 15px;

    p {
      font-size: 12px;
      font-weight: normal;
    }
  }

  opacity: ${props => (props.isFocused ? '1' : '0.4')};

  table {
    width: 100%;
    font-size: 18px;
    line-height: 1.1em;
    border-collapse: collapse;
    border-spacing: 2px;

    tbody {
      display: table-row-group;
      vertical-align: middle;
      border-color: inherit;
    }

    tr {
      display: table-row;
      vertical-align: inherit;
      border-color: inherit;

      &:last-child {
        border-top: 1px solid #680eb5;

        td {
          padding: 0;

          p,
          span {
            margin-block-start: 0px;
            padding: 11px 20px;

            font-size: 1em;
            border-top: 5px solid #680eb5;
          }
        }
      }
    }

    td {
      font-size: 1em;
      padding: 11px 20px;

      display: table-cell;
      vertical-align: inherit;

      p,
      span {
        display: block;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
        font-size: 1em;

        small {
          font-size: 0.7em;
        }
      }
    }
  }
`;

export const Helper = styled.div`
  font-size: 12px;
  color: ${props => props.theme.interface5};

  display: flex;
  height: 100%;
`;
