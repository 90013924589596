import React, { useCallback, useEffect, useState, useMemo } from 'react';

import queryString from 'query-string';

import { Button } from '@cloudez/cloudez-design-system';

import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { answerSurveyService, getSurveySlugService } from 'services/survey';
import { deleteUserService, getUserBillingService } from 'services/user';
import { getCreditcardService } from 'services/common';
import history from 'services/history';

import { useDocTitle } from 'hooks/docTitle';
import { useLayout } from 'hooks/layout';
import { useAuth } from 'hooks/auth';

import InvoiceWarning from 'pages/Dashboard/InvoiceWarning';

import { Tab, Tabs, TabContent } from 'components/Tabs';
import { CustomInput } from 'components/NewComponents';
import { Title, Header } from 'components/Common';
import PasswordModal from './PasswordModal';
import RemoveModal from './RemoveModal';
import ChurnModal from './ChurnModal';
import Security from './Security';
import Payment from './Payment';

import { Content, FooterButtons } from './styles';

const validateNameSchema = yup.object().shape({
  full_name: yup.string().required('Este campo é obrigatório'),
});

const Account: React.FC = () => {
  const theme = useLayout();
  const { user, updateUser, signOut } = useAuth();

  const [loading, setLoading] = useState(false);

  useDocTitle(`Conta — ${theme.name}`);

  const [passwordModal, setPasswordModal] = useState(false);
  const [churnModal, setChurnModal] = useState(false);
  const [creditCard, setCreditCard] = useState(null);
  const [billing, setBilling] = useState(null);
  const [survey, setSurvey] = useState(null);
  const [modal, setModal] = useState(false);

  const { tab } = queryString.parse(history.location.search);
  const [_tab, setTab] = useState(Number(tab) || 1);
  const [partnerCanChurn] = useState(
    user.is_company_owner && user.owned_companies.includes(theme.code),
  );
  const configrCanChurn = useMemo(
    () => user.company_slug === 'configr' && theme.slug === 'configr',
    [user, theme],
  );

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validateNameSchema),
  });

  useEffect(() => {
    async function getSurvey() {
      let surveySlug;
      if (configrCanChurn) surveySlug = 'churn-reason';
      else if (partnerCanChurn) surveySlug = 'partners-churn';

      try {
        const { data } = await getSurveySlugService(surveySlug);
        setSurvey(data);
      } catch (e) {
        console.log(e);
      }
    }

    if (configrCanChurn || partnerCanChurn) getSurvey();

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function getBilling() {
      try {
        const { data } = await getUserBillingService(user.id);
        setBilling({
          ...data,
          phone_number: data?.phone_number?.substring('+55'.length),
        });
      } catch (e) {
        console.log(e);
      }
    }

    async function getCreditCard() {
      try {
        const { data } = await getCreditcardService();
        setCreditCard(data[0]);
      } catch (e) {
        console.log(e);
      }
    }

    if (!billing) getBilling();
    if (!creditCard) getCreditCard();

    const eventMethod = window.addEventListener
      ? 'addEventListener'
      : 'attachEvent';
    const eventer = window[eventMethod];
    const messageEvent =
      eventMethod === 'attachEvent' ? 'onmessage' : 'message';
    eventer(
      messageEvent,
      e => {
        if (e.data === 'credit_created') {
          getCreditCard();
        }
      },
      false,
    );
    // eslint-disable-next-line
  }, []);

  const onSubmit = async data => {
    try {
      setLoading(true);
      await updateUser('full_name', data.full_name);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      console.log('err', err);
    }
  };
  const deleteUser = useCallback(async () => {
    try {
      await deleteUserService(user.id);
      signOut();
    } catch (e) {
      console.log(e);
    }
    // eslint-disable-next-line
  }, [user]);

  const answerSurvey = useCallback(
    async payload => {
      const data = {
        slug: survey.slug,
        answers: [],
      };

      for (const item in payload) {
        data.answers = [
          ...data.answers,
          { question_id: item, answer: payload[item] },
        ];
      }

      try {
        await answerSurveyService(data);
        setChurnModal(false);

        if (configrCanChurn) deleteUser();
      } catch (e) {
        console.log(e);
      }
    },
    [survey],
  );

  return (
    <>
      {modal && (
        <RemoveModal show={modal} setShow={setModal} action={deleteUser} />
      )}

      {passwordModal && (
        <PasswordModal show={passwordModal} setShow={setPasswordModal} />
      )}

      {churnModal && (
        <ChurnModal
          show={churnModal}
          setShow={setChurnModal}
          survey={survey}
          action={answerSurvey}
          partnerCanChurn={partnerCanChurn}
        />
      )}

      <Header>
        <Title>Conta</Title>
      </Header>

      <InvoiceWarning />

      <Tabs>
        <Tab onClick={() => setTab(1)} active={_tab === 1}>
          Perfil
        </Tab>
        <Tab onClick={() => setTab(2)} active={_tab === 2}>
          Informações de pagamento
        </Tab>
        <Tab onClick={() => setTab(3)} active={_tab === 3}>
          Segurança
        </Tab>
      </Tabs>

      <TabContent>
        {(() => {
          switch (_tab) {
            case 1:
              return (
                <Content>
                  <img
                    src={user.avatar}
                    width="100px"
                    alt="avatar"
                    height="100px"
                  />
                  <form onSubmit={handleSubmit(onSubmit)} className="inputBox">
                    <CustomInput
                      name="full_name"
                      block
                      defaultValue={user?.full_name}
                      placeholder="Nome da conta"
                      label="Nome da conta"
                      register={register}
                      error={errors?.full_name?.message}
                    />
                    <Button icon disabled={loading}>
                      {loading ? (
                        <FontAwesomeIcon icon={faSpinner} spin />
                      ) : (
                        'Alterar nome'
                      )}{' '}
                    </Button>
                  </form>
                </Content>
              );
            case 2:
              return (
                <Payment
                  billing={billing}
                  creditCard={creditCard}
                  setCreditCard={setCreditCard}
                  setBilling={setBilling}
                  tab={tab}
                />
              );
            case 3:
              return <Security />;
            default:
              return null;
          }
        })()}
      </TabContent>
      {_tab !== 3 && (
        <FooterButtons>
          <Button
            error
            outline
            onClick={() => {
              if (configrCanChurn || partnerCanChurn) {
                setChurnModal(true);
              } else {
                setModal(true);
              }
            }}
          >
            Deletar conta
          </Button>
          <Button
            outline
            onClick={() => {
              setPasswordModal(true);
            }}
          >
            Trocar senha
          </Button>
        </FooterButtons>
      )}
    </>
  );
};

export default Account;
