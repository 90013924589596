import queryString from 'query-string';
import { Reducer } from 'react';
import { IActionPayload } from 'types';
import { createPagination } from '@cloudez/cloudez-design-system';
import { MigrationListingState } from './state';

const reducer: Reducer<MigrationListingState, IActionPayload> = (
  state,
  action,
): MigrationListingState => {
  switch (action.type) {
    case 'GET_MIGRATIONS':
      const migrations = action.payload.results;
      const pagination = createPagination(action.payload, 10);

      return {
        ...state,
        ...pagination,
        migrations,
      };

    case 'CHANGE_PINNED': {
      return {
        ...state,
        migrations: state?.migrations?.map(migration =>
          migration.id === action.payload.id
            ? { ...migration, is_pinned: action.payload.data }
            : migration,
        ),
      };
    }

    case 'SET_LOADING': {
      return {
        ...state,
        loading: action.payload,
      };
    }

    case 'SET_ERRORS': {
      return {
        ...state,
        errors: action.payload,
      };
    }

    default: {
      return state;
    }
  }
};

export default reducer;
