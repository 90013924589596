import styled from 'styled-components';

export const FinancialInfo = styled.div`
  width: 100%;
  height: auto;

  display: flex;
  flex-direction: column;
  gap: 24px;

  font-size: 16px;

  h5 {
    color: ${props => props.theme.brand_primary_color};
    font-size: 16px;
  }

  font-size: 14px;
  color: ${props => props.theme.interface5};
  font-weight: 700;

  div.infos,
  div.info {
    display: flex;
    justify-content: space-between;
  }

  div.info {
    flex-direction: column;
  }

  div.info + div.info {
    margin-left: 10px;
  }

  span.label {
    font-weight: 400;
    font-size: 12px;
  }
`;
