import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { withTheme } from 'styled-components';
import { Row, Col } from 'react-bootstrap';

import ApplicationIcon from 'components/ApplicationIcon';

import {
  Field,
  Input,
  InputSufix,
  Loading,
  Arrows,
} from '@cloudez/cloudez-design-system';

import { getWebsiteTypesService } from 'services/website';
import { useAuth } from 'hooks/auth';
import { Tab, Tabs } from 'components/Details';
import Swipe from 'react-easy-swipe';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import useDevice from 'hooks/device';
import { Wrapper, Type } from './styles';

const typesFilter = {
  eCommerce: ['magento', 'magento2', 'woocommerce', 'opencart', 'prestashop'],
  cms: ['wordpress', 'joomla', 'ghost', 'drupal'],
  frameworks: [
    'laravel',
    'nodejs',
    'php',
    'python',
    'rails',
    'react',
    'angular',
    'symfony',
    'cakephp',
  ],
};

const WebsiteTypes = ({ newWebsite, setNewWebsite, theme }) => {
  const [types, setTypes] = useState(null);
  const [filter, setFilter] = useState('all');
  const [loading, setLoading] = useState(false);
  const [tab, setTab] = useState(1);
  const { user } = useAuth();
  const { isMobile } = useDevice();

  useEffect(() => {
    async function getWebsiteTypes() {
      setLoading(true);
      const { data } = await getWebsiteTypesService({
        page_size: 500,
        company: user.company,
      });

      const alphabetical = (a, b) => {
        if (a.slug > b.slug) {
          return 1;
        }

        if (a.slug < b.slug) {
          return -1;
        }

        return 0;
      };

      setTypes({
        all: data.results.sort(alphabetical),
        eCommerce: data.results
          .filter(type => typesFilter.eCommerce.includes(type.slug))
          .sort(alphabetical),
        cms: data.results
          .filter(type => typesFilter.cms.includes(type.slug))
          .sort(alphabetical),
        frameworks: data.results
          .filter(type => typesFilter.frameworks.includes(type.slug))
          .sort(alphabetical),
      });
      setLoading(false);
    }

    if (!types) getWebsiteTypes();
    // eslint-disable-next-line
  }, [types]);

  const getFilter = useCallback(_tab => {
    switch (_tab) {
      case 1:
        return 'all';
      case 2:
        return 'eCommerce';
      case 3:
        return 'cms';
      case 4:
        return 'frameworks';
      default:
        return 'all';
    }
  }, []);

  const changeTab = (_tab, _filter) => {
    setTab(_tab);
    setFilter(_filter);
  };

  const apps = useMemo(() => {
    if (!types) return [];

    return types[filter];
  }, [filter, types]);

  return (
    <Wrapper>
      <Field
        style={{
          position: 'relative',
          margin: '0 auto',
          marginBottom: '30px',
          maxWidth: '300px',
        }}
      >
        <p
          style={{
            fontWeight: 'normal',
            textAlign: 'center',
            fontSize: '16px',
            color: '#64728C !important',
          }}
        >
          Dê um nome pra sua aplicação
        </p>
        <Input
          placeholder="Dê um nome pra sua aplicação..."
          style={{
            marginTop: '10px',
          }}
          block
          onChange={e => {
            setNewWebsite({
              ...newWebsite,
              name: e.target.value,
            });
          }}
        />
        {/* <InputSufix
          style={{
            top: '29px',
            right: 0,
          }}
        /> */}
      </Field>

      {loading ? (
        <Loading />
      ) : (
        <>
          <Tabs
            className="mb-3"
            style={{
              maxWidth: '900px',
              margin: '0 auto',
            }}
          >
            <Tab active={tab === 1} onClick={() => changeTab(1, 'all')}>
              Todos
            </Tab>
            <Tab active={tab === 2} onClick={() => changeTab(2, 'eCommerce')}>
              E-commerce
            </Tab>
            <Tab active={tab === 3} onClick={() => changeTab(3, 'cms')}>
              CMS
            </Tab>
            <Tab active={tab === 4} onClick={() => changeTab(4, 'frameworks')}>
              Frameworks
            </Tab>
          </Tabs>

          {isMobile && (
            <Arrows
              disabled={tab === 1}
              icon={faChevronLeft}
              left="true"
              onClick={() => tab > 1 && changeTab(tab - 1, getFilter(tab - 1))}
              onboard
            />
          )}
          <Swipe
            onSwipeRight={() =>
              tab > 1 && changeTab(tab - 1, getFilter(tab - 1))
            }
            onSwipeLeft={() =>
              tab < 4 && changeTab(tab + 1, getFilter(tab + 1))
            }
            tolerance={100}
          >
            <Row
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '0 auto',
                maxWidth: '900px',
              }}
            >
              {apps?.map((t, i) => (
                <Col
                  key={t.id}
                  lg="3"
                  md="3"
                  sm="4"
                  xs="6"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <Type
                    type={t}
                    onClick={() =>
                      setNewWebsite({
                        ...newWebsite,
                        type: t.id,
                        values: t.values.map(t => ({
                          slug: t.slug,
                          value: t.default,
                        })),
                      })
                    }
                    selected={newWebsite.type === t.id}
                    title={t.description}
                  >
                    <ApplicationIcon
                      width="40px"
                      height="40px"
                      icon={`${t.slug}`}
                    />
                    <span>{t.name}</span>
                  </Type>
                </Col>
              ))}
            </Row>
          </Swipe>
          {isMobile && (
            <Arrows
              disabled={tab === 4}
              icon={faChevronRight}
              right="true"
              onClick={() => tab < 4 && changeTab(tab + 1, getFilter(tab + 1))}
              onboard
            />
          )}
        </>
      )}
    </Wrapper>
  );
};

export default withTheme(WebsiteTypes);
