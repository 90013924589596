import React from 'react';

import { faCheck, faHourglassHalf } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLayout } from 'hooks/layout';
import { Table } from './styles';

type Syncs = {
  type: string;
  status: number;
  email: string;
};

type Migration = {
  migration_status: number;
  migration_target: string;
  syncs: Syncs[];
};

interface SyncsTableProps {
  migration: Migration;
  isRemoveBackup: boolean;
}

const SyncsTable = ({
  migration,
  isRemoveBackup,
}: SyncsTableProps): JSX.Element => {
  const theme = useLayout();

  const getSyncIcon = status => {
    switch (status) {
      case 1:
      case 2:
      case 4:
      case 5:
      case 6:
        return faHourglassHalf;
      case 3:
        return faCheck;
      default:
        return faHourglassHalf;
    }
  };

  const getSyncColor = status => {
    switch (status) {
      case 3:
        return theme.successNew;
      default:
        return theme.interface4;
    }
  };

  const getIcon = status => {
    return migration.migration_status === status ||
      migration.migration_status === 1
      ? faHourglassHalf
      : faCheck;
  };

  const getColor = status => {
    return migration.migration_status === status ||
      migration.migration_status === 1
      ? theme.interface4
      : theme.success;
  };

  return (
    <Table>
      <tr>
        <th className="withBorder">Sincronizando</th>
        <th className="withBorder">
          <FontAwesomeIcon
            color={getColor(isRemoveBackup ? 1 : 2)}
            spin={migration.migration_status === 2 && !isRemoveBackup}
            icon={getIcon(isRemoveBackup ? 1 : 2)}
          />
        </th>
      </tr>
      {migration?.syncs?.map(item => {
        return (
          <>
            {item.type === 'rsync' ? (
              <tr>
                <td>Baixando os arquivos</td>
                <td>
                  <FontAwesomeIcon
                    style={{ color: getSyncColor(item.status) }}
                    spin={item.status === 2}
                    icon={getSyncIcon(item.status)}
                  />
                </td>
              </tr>
            ) : null}
            {item.type === 'imapsync' && (
              <tr>
                <td>Baixando conta {item?.email}</td>
                <td>
                  <FontAwesomeIcon
                    style={{ color: getSyncColor(item.status) }}
                    spin={item.status === 2}
                    icon={getSyncIcon(item.status)}
                  />
                </td>
              </tr>
            )}
            {item.type === 'emailrsync' && (
              <tr>
                <td>Baixando as contas</td>
                <td>
                  <FontAwesomeIcon
                    style={{ color: getSyncColor(item.status) }}
                    spin={item.status === 2}
                    icon={getSyncIcon(item.status)}
                  />
                </td>
              </tr>
            )}
            {item.type === 'internal' && (
              <tr>
                <td>Importando chave SSH</td>
                <td>
                  <FontAwesomeIcon
                    style={{ color: getSyncColor(item.status) }}
                    spin={item.status === 2}
                    icon={getSyncIcon(item.status)}
                  />
                </td>
              </tr>
            )}
            {item.type === 'autodump' && (
              <tr>
                <td>Baixando o banco de dados</td>
                <td>
                  <FontAwesomeIcon
                    style={{ color: getSyncColor(item.status) }}
                    spin={item.status === 2}
                    icon={getSyncIcon(item.status)}
                  />
                </td>
              </tr>
            )}
            {item.type === 'finish' && (
              <>
                <tr>
                  <td>Importando o banco de dados</td>
                  <td>
                    <FontAwesomeIcon
                      style={{ color: getSyncColor(item.status) }}
                      spin={item.status === 2}
                      icon={getSyncIcon(item.status)}
                    />
                  </td>
                </tr>
              </>
            )}
          </>
        );
      })}
      {migration?.migration_target === 'website' && (
        <>
          <tr>
            <th className="withBorder">Configuração</th>
            <th className="withBorder">
              <FontAwesomeIcon
                color={getColor(isRemoveBackup ? 1 : 2)}
                icon={getIcon(isRemoveBackup ? 1 : 2)}
              />
            </th>
          </tr>
          <tr>
            <td>Configurando os últimos detalhes</td>
            <td>
              <FontAwesomeIcon
                color={getColor(isRemoveBackup ? 1 : 2)}
                icon={getIcon(isRemoveBackup ? 1 : 2)}
              />
            </td>
          </tr>
        </>
      )}
      <tr>
        <th className="withBorder">Validação</th>
        <th className="withBorder">
          <FontAwesomeIcon
            icon={getSyncIcon(migration?.migration_status === 4 ? 3 : 1)}
            color={getSyncColor(migration?.migration_status === 4 ? 3 : 1)}
            spin={migration?.migration_status === 7}
          />
        </th>
      </tr>
      <tr>
        <td>Aguardando validação</td>
        <td>
          <FontAwesomeIcon
            icon={getSyncIcon(migration?.migration_status === 4 ? 3 : 1)}
            color={getSyncColor(migration?.migration_status === 4 ? 3 : 1)}
            spin={migration?.migration_status === 7}
          />
        </td>
      </tr>
    </Table>
  );
};

export default SyncsTable;
