import React, { useCallback, useEffect, useMemo, useState } from 'react';

import history from 'services/history';

import { createDomainService } from 'services/domain';
import { getCloudsService, createCloudSetupService } from 'services/cloud';
import {
  getWebsitesService,
  createWebsiteService,
  updateWebsiteService,
  getWebsiteService,
} from 'services/website';
import {
  createWebsiteMigrationService,
  vldValidateWebsiteService,
} from 'services/migration';
import { useLayout } from 'hooks/layout';
import { useAuth } from 'hooks/auth';
import hourFlare from 'assets/img/badge_hour_flare.png';
import appFlare from 'assets/img/svg/badge_app_flare.svg';
import migFlare from 'assets/img/svg/badge_mig_flare.svg';
import Axios from 'axios';
import Payment from './Domain/Payment';
import Domain from './Domain';
import Migration from './Migration';
import Application from './Application';
import { StageButton } from './styles';
import BadgeReceived from './BadgeReceived';
import FreeTrial from './FreeTrial';
import DomainActivation from './DomainActivation';

import FreeTrialTime from './FreeTrialTime';

const Onboard = () => {
  const { user, getUser } = useAuth();
  const theme = useLayout();

  const [loading, setLoading] = useState(false);
  const [stage, setStage] = useState(0);
  const [cloud, setCloud] = useState(user?.onboarding_data?.node);
  const [nodeapp, setNodeapp] = useState(user?.onboarding_data?.nodeapp);

  const [validDomain, setValidDomain] = useState(false);
  const [isValidating, setIsValidating] = useState(false);

  const [showBadge, setShowBadge] = useState(false);
  const [payDomain, setPayDomain] = useState(false);

  const [newWebsite, setNewWebsite] = useState({
    cloud: cloud?.id || user?.onboarding_data?.node,
    values: [],
    type: null,
    user: null,
    disk_usage: null,
    certificate: null,
    use_cdn: 'false',
    name: '',
  });

  const [errors, setErrors] = useState(null);

  const [newDomain, setNewDomain] = useState({
    domain: null,
    domain_do_hire: false,
  });

  const [newMigration, setNewMigration] = useState({
    host: '',
    domain: '',
    password: '',
    user: '',
    nodeapp: nodeapp?.id || nodeapp,
    sftp: false,
    user_id: user?.id,
    mig_type: 1,
    path: '',
    port: 21,
  });
  const [migrationErrors, setMigrationErrors] = useState(null);

  useEffect(async () => {
    await getUser();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function getNodeapp() {
      try {
        const { data } = await getWebsiteService(
          user?.onboarding_data?.nodeapp || nodeapp?.id,
        );

        setNodeapp(data);
      } catch (e) {
        console.log(e);
      }
    }

    if (nodeapp || user?.onboarding_data?.nodeapp) getNodeapp();
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    const { onboarding_data } = user;

    if (!showBadge) {
      if (onboarding_data?.node) {
        setStage(1);
      }

      if (onboarding_data?.nodeapp) {
        setStage(2);
      }

      if (onboarding_data?.zone && !onboarding_data?.zone_active) {
        setShowBadge(true);
      }

      if (onboarding_data?.zone_active) {
        setStage(3);
        setShowBadge(false);
      }

      if (onboarding_data?.finished_onboarding) {
        history.push('/dashboard');
      }
    }
    // eslint-disable-next-line
  }, [user]);

  const nextStage = useCallback(
    () => (stage < 3 ? setStage(stage + 1) : history.push('/dashboard')),
    [stage],
  );

  const badge = useMemo(() => {
    switch (stage) {
      case 0:
        return {
          badge: hourFlare,
          title: 'Teste grátis ativado com sucesso',
          subtitle:
            'Agora você tem 24h para utilizar nossa plataforma de Cloud com tranquilidade, facilidade e segurança.',
        };
      case 1:
        return {
          badge: appFlare,
          title: 'Aplicação criada com sucesso',
          subtitle:
            'Parabéns! Você ganhou mais 1 dia para usufruir do seu teste grátis, e conhecer as facilidades da nossa plataforma.',
        };
      case 3:
        return {
          badge: migFlare,
          title: 'Copia feita com sucesso',
          subtitle:
            'Parabéns! Você completou todos os passos e ganhou mais 2 dias no seu free trial (já são 7 no total). A configuração do seu site em um dos nossos Cloud Server está completa e todas as funcionalidades do nosso painel já estão liberadas.',
        };
      default:
        return null;
    }
  }, [stage]);

  const createApp = async () => {
    setLoading(true);
    try {
      const { data } = await createWebsiteService(newWebsite);
      setNodeapp(data.id);
      setNewMigration({
        ...newMigration,
        nodeapp: data.id,
      });
      await getUser();
      setLoading(false);
      setShowBadge(true);

      const { fbq } = window;
      const { gtag } = window;

      if (fbq) {
        fbq('track', 'SubmitApplication');
      }

      if (gtag) {
        gtag('event', 'track', { event_category: 'SubmitApplication' });
      }
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };

  const createDomain = async () => {
    if (newDomain.domain_do_hire) {
      setPayDomain(true);
      setLoading(false);
      setShowBadge(true);
    } else {
      setLoading(true);
      try {
        await createDomainService(newDomain);
        await updateWebsiteService(nodeapp.id, {
          ...nodeapp,
          values: [
            ...nodeapp.values,
            {
              slug: 'domain',
              value: newDomain.domain,
            },
          ],
        });

        await getUser();
        setLoading(false);
        setShowBadge(true);
      } catch (e) {
        setLoading(false);
        setErrors(e?.response?.data);
        console.log(e);
      }
    }
  };

  const createMigration = async () => {
    setLoading(true);
    setMigrationErrors(null);
    try {
      const {
        data: { authenticated, files, type, website },
      } = await vldValidateWebsiteService(newMigration);

      let migration;

      if (authenticated) {
        migration = {
          src_host: newMigration.host,
          src_port: newMigration.port,
          src_paths: files || ['public_html'],
          src_username: newMigration.user,
          src_password: newMigration.password,
          nodeapp_src: website,
          nodeapp: newMigration.nodeapp,
          type,
          domain: newMigration.domain,
          auto_point_dns: type === 1,
        };

        try {
          await createWebsiteMigrationService(migration);

          await getUser();
          setLoading(false);
          setShowBadge(true);

          const { fbq } = window;
          const { gtag } = window;

          if (fbq) {
            fbq('track', 'Migracao');
          }

          if (gtag) {
            gtag('event', 'track', { event_category: 'Migracao' });
          }
        } catch (e) {
          setLoading(false);
          setMigrationErrors(e?.response?.data?.errors);
        }
      }
    } catch (e) {
      setLoading(false);
      setMigrationErrors(e?.response?.data?.errors);
    }
  };

  const checkIsHosted = async () => {
    setIsValidating(true);
    setMigrationErrors(null);
    try {
      const { data } = await Axios.post(
        `${process.env.REACT_APP_VLD_URL}domain`,
        {
          domain: newMigration.domain,
          host: newMigration.host,
          user_id: user.id,
        },
        {
          headers: {
            Authorization: 'Bearer api_token_validator',
          },
        },
      );
      setValidDomain(true);
      setIsValidating(false);
    } catch (e) {
      setIsValidating(false);
      setMigrationErrors(e?.response?.data?.errors);
    }
  };

  const startFreeTrial = async () => {
    setLoading(true);
    try {
      const payload = {
        plan_type: theme.trial_plan_id,
        lifespan_months: 1,
      };

      const { data } = await createCloudSetupService(payload);
      setCloud(data.cloud);
      setNewWebsite({
        ...newWebsite,
        cloud: data.cloud,
      });
      await getUser();
      setLoading(false);
      setShowBadge(true);

      const { fbq } = window;
      const { gtag } = window;

      if (fbq) {
        fbq('track', 'StartTrial');
      }

      if (gtag) {
        gtag('event', 'track', { event_category: 'StartTrial' });
      }
    } catch (e) {
      setLoading(false);
    }
  };

  const skipFreeTrial = () => {
    history.push('/clouds/create?from=onboard');
  };

  return (
    <>
      {(stage > 0 || (stage === 0 && showBadge)) && (
        <FreeTrialTime
          style={{
            margin: '40px auto',
          }}
        />
      )}

      {!showBadge ? (
        <>
          {stage === 0 && (
            <FreeTrial
              startFreeTrial={startFreeTrial}
              skipFreeTrial={skipFreeTrial}
              loading={loading}
            />
          )}

          {stage === 1 && (
            <Application
              loading={loading}
              newWebsite={newWebsite}
              setNewWebsite={setNewWebsite}
              nextStage={nextStage}
              createApp={createApp}
            />
          )}

          {stage === 2 && (
            <Domain
              nextStage={nextStage}
              loading={loading}
              newDomain={newDomain}
              setNewDomain={setNewDomain}
              createDomain={createDomain}
              errors={errors}
            />
          )}

          {stage === 3 && (
            <Migration
              checkIsHosted={checkIsHosted}
              migrationErrors={migrationErrors}
              loading={loading}
              newMigration={newMigration}
              setNewMigration={setNewMigration}
              nextStage={nextStage}
              createMigration={createMigration}
              cloud={cloud}
              validDomain={validDomain}
              isValidating={isValidating}
            />
          )}
        </>
      ) : stage === 2 && !payDomain ? (
        <DomainActivation
          domain={newDomain}
          nextStage={nextStage}
          setShowBadge={setShowBadge}
        />
      ) : stage === 2 && payDomain ? (
        <Payment
          buyDomain={newDomain}
          nodeapp={nodeapp}
          setPayDomain={setPayDomain}
        />
      ) : (
        <BadgeReceived
          title={badge.title}
          badge={badge.badge}
          description={badge.subtitle}
        >
          <StageButton
            onClick={() => {
              if (stage !== 3) {
                nextStage();
                setShowBadge(false);
              } else {
                history.push('/dashboard');
              }
            }}
          >
            {stage === 3 ? 'ACESSAR PAINEL' : 'Próximo Passo'}
          </StageButton>
        </BadgeReceived>
      )}
    </>
  );
};

export default Onboard;
