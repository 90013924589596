import React from 'react';
import { AnimatedList } from 'react-animated-list';
import { Col, Row } from 'react-bootstrap';

import Placeholder from 'components/Placeholder';

import {
  Card,
  CardContent,
  ListingHeader,
  Listing,
  ListingItem,
  ApplicationValue,
} from 'components/Listing/styles';

import { Acesso, AcessosList } from 'components/Acessos';

const InvoicePlaceholder = () => {
  const placeholders = [];

  for (let i = 1; i <= 4; i++) {
    placeholders.push(i);
  }

  return (
    <>
      <ListingHeader>
        <Row>
          <Col xs="2" lg="1">
            <p>Id</p>
          </Col>

          <Col xs="8" sm="6" md="4" lg="6" xl="5">
            <p>Email</p>
          </Col>

          <Col className="d-none d-sm-none d-md-flex">
            <p>Valor</p>
          </Col>

          <Col className="d-none d-sm-none d-md-flex">
            <p>Vencimento</p>
          </Col>

          <Col className="d-none d-sm-none d-md-flex">
            <p>Nota Fiscal</p>
          </Col>

          <Col xl="1" md="2" sm="3" xs="4" className="d-none d-md-flex" />
        </Row>
      </ListingHeader>

      <Listing>
        <AnimatedList initialAnimationDuration={800} animation="grow">
          {placeholders.map((p, i) => (
            <ListingItem key={p} fade={i > 0 && i}>
              <Card>
                <CardContent>
                  <Col xs="2" lg="1">
                    <ApplicationValue>
                      <Placeholder width={30} height={12} />
                    </ApplicationValue>
                  </Col>

                  <Col xs="8" sm="6" md="4" lg="6" xl="5">
                    <ApplicationValue>
                      <Placeholder width={200} height={12} />
                    </ApplicationValue>
                  </Col>

                  <Col className="d-none d-sm-none d-md-flex">
                    <ApplicationValue>
                      <Placeholder width={30} height={12} />
                    </ApplicationValue>
                  </Col>

                  <Col className="d-none d-sm-none d-md-flex">
                    <ApplicationValue>
                      <Placeholder width={30} height={12} />
                    </ApplicationValue>
                  </Col>

                  <Col className="d-none d-sm-none d-md-flex">
                    <ApplicationValue>
                      <Placeholder width={30} height={12} />
                    </ApplicationValue>
                  </Col>

                  <Col xl="1" md="2" sm="3" xs="4" className="d-none d-sm-flex">
                    <AcessosList>
                      <Acesso active hover>
                        <Placeholder width={16} height={18} />
                      </Acesso>

                      <Acesso active hover favorite>
                        <Placeholder width={16} height={18} />
                      </Acesso>

                      <Acesso active hover favorite>
                        <Placeholder width={16} height={18} />
                      </Acesso>
                    </AcessosList>
                  </Col>
                </CardContent>
              </Card>
            </ListingItem>
          ))}
        </AnimatedList>
      </Listing>
    </>
  );
};

export default InvoicePlaceholder;
