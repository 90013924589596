import React, {
  useEffect,
  useState,
  useContext,
  useCallback,
  useRef,
} from 'react';
import { AnimatedList } from 'react-animated-list';
import queryString from 'query-string';

import { Col, Row } from 'react-bootstrap';

import history from 'services/history';

import {
  ListingHeader,
  Notification,
  Ordering,
} from 'components/Listing/styles';
import {
  Button,
  Pagination,
  AdvancedSearch,
} from '@cloudez/cloudez-design-system';
import { Title, Header } from 'components/Common';
import SearchBar from 'components/SearchBar';

import {
  faStar,
  faCircle,
  faAngleUp,
  faAngleDown,
} from '@fortawesome/free-solid-svg-icons';

import EmptyListing from 'components/EmptyListing';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FilterHover,
  Filter,
  FilterSeparator,
  FilterWrapper,
} from 'components/SearchBar/styles';
import { Value } from 'components/Acessos';
import { useDocTitle } from 'hooks/docTitle';
import { useLayout } from 'hooks/layout';
import { useAuth } from 'hooks/auth';
import { useNotifications } from 'hooks/notifications';
import InvoiceWarning from 'pages/Dashboard/InvoiceWarning';
import { Filters } from './styles';
import TicketCard from './TicketCard';
import { TicketListingProvider, TicketListingContext } from './_context/state';
import TicketPlaceholder from './Placeholder';
import { CustomFilter } from './CustomFilter';

const Tickets = () => {
  const theme = useLayout();
  const { user } = useAuth();
  const { notifications } = useNotifications();

  useDocTitle(`Suporte — ${theme.name}`);

  const { loading, tickets, count, previous, next, current, getTickets } =
    useContext(TicketListingContext);

  const {
    page = 1,
    search: _search,
    team: _team = null,
    status = 1,
    filter: _filter = 'all',
    priority: _priority = '',
  } = queryString.parse(history.location.search);

  const [teamValue, setTeamValue] = useState<any>(_team as any);
  const [statusValue, setStatusValue] = useState(Number(status));
  const [filterValue, setFilterValue] = useState(_filter);
  const [priorityValue, setPriorityValue] = useState(_priority);
  const [orderValue, setOrderValue] = useState<any>();

  const [search, setSearch] = useState(
    queryString.parse(history.location.search),
  );

  const abortControllerRef = useRef<AbortController>(new AbortController());

  const getTicketsCallback = (
    page,
    search,
    team = teamValue,
    status = statusValue,
    filter = filterValue,
    priority = priorityValue,
    order = orderValue,
  ) => {
    abortControllerRef.current.abort();
    abortControllerRef.current = new AbortController();

    getTickets(
      page,
      search,
      team,
      status,
      filter,
      priority,
      user,
      order,
      abortControllerRef.current,
    );

    const _query = {
      ...search,
      team,
      status,
      filter,
      page,
      priority,
    };

    if (!search) delete _query.search;
    if (!team) delete _query.team;
    if (filter === 'all') delete _query.filter;
    if (priority === '') delete _query.priority;
    if (page === 1 || page === '1') delete _query.page;
    const query = queryString.stringify(_query);
    history.replace(`/suporte?${query}`);
  };

  useEffect(() => {
    getTicketsCallback(
      page,
      search,
      teamValue,
      statusValue,
      filterValue,
      orderValue,
    );
    // eslint-disable-next-line
  }, []);

  const searchValue = searchValue => {
    getTicketsCallback(
      1,
      searchValue,
      teamValue,
      statusValue,
      filterValue,
      orderValue,
    );
  };

  const handleSetTeamValue = team => {
    setTeamValue(team);
    getTicketsCallback(
      1,
      search,
      team,
      statusValue,
      filterValue,
      priorityValue,
      orderValue,
    );
  };

  const handleSetStatus = status => {
    setStatusValue(status);
    getTicketsCallback(
      1,
      search,
      teamValue,
      status,
      filterValue,
      priorityValue,
      orderValue,
    );
  };

  const handleSetFilter = filter => {
    setFilterValue(filter);
    getTicketsCallback(
      1,
      search,
      teamValue,
      statusValue,
      filter,
      priorityValue,
      orderValue,
    );
  };

  const order = column => {
    const order = {
      value: orderValue?.value === 2 ? 1 : orderValue?.value === 1 ? 0 : 2,
      column,
    };
    setOrderValue(order);
    getTicketsCallback(
      1,
      search,
      teamValue,
      statusValue,
      filterValue,
      priorityValue,
      order,
    );
  };

  const handleSetPriority = priority => {
    setPriorityValue(priority);
    getTicketsCallback(
      1,
      search,
      teamValue,
      statusValue,
      filterValue,
      priority,
      orderValue,
    );
  };

  const hasNotification = useCallback(
    team => {
      return notifications?.find(n => {
        if (n) {
          const isTeam =
            (n.team && n.team === team) ||
            (n.resource && n.resource.team === team);

          const isTicket = n.type === 'ticket' && n.status === 0;

          return isTeam && isTicket && n.description !== '';
        }
        return false;
      });
    },
    [notifications],
  );

  const fieldsToSearch = user.is_staff
    ? [
        {
          label: 'Qualquer campo',
          value: 'search',
        },
        {
          label: 'Título',
          value: 'summary',
        },
        {
          label: 'Email',
          value: 'email',
        },
        {
          label: 'Domínio',
          value: 'domain',
        },
        {
          label: 'Recurso',
          value: 'target_type',
        },
        {
          label: 'Responsável',
          value: 'responsible',
        },
      ]
    : [
        {
          label: 'Qualquer campo',
          value: 'search',
        },
        {
          label: 'Título',
          value: 'summary',
        },
        {
          label: 'Email',
          value: 'email',
        },
        {
          label: 'Domínio',
          value: 'domain',
        },
        {
          label: 'Recurso',
          value: 'target_type',
        },
      ];

  return (
    <>
      <Header>
        <Title>Suporte</Title>
        <Button onClick={() => history.push('/suporte/create')}>
          <span>Novo ticket</span>
        </Button>
      </Header>
      <InvoiceWarning />

      <SearchBar noInput>
        <AdvancedSearch
          style={{
            width: '400px',
          }}
          callback={searchValue}
          noResults={false}
          value={search}
          setValue={setSearch}
          fields={fieldsToSearch}
        />
        <FilterWrapper>
          <CustomFilter
            selected={statusValue === 1}
            onClick={() => handleSetStatus(1)}
            spacing
            type="open"
          />
          <CustomFilter
            selected={statusValue === 2}
            onClick={() => handleSetStatus(2)}
            type="closed"
          />
          <FilterSeparator />
          <Filter>
            <FontAwesomeIcon
              icon={faStar}
              color={
                filterValue === 'is_pinned'
                  ? theme.favoriteNew
                  : theme.darkMode
                  ? theme.interface5
                  : theme.interface4
              }
              onClick={() =>
                filterValue === 'is_pinned'
                  ? handleSetFilter('all')
                  : handleSetFilter('is_pinned')
              }
            />
            <FilterHover>
              <Value>
                <span>Favoritos</span>
              </Value>
            </FilterHover>
          </Filter>
        </FilterWrapper>
      </SearchBar>

      <Filters staff={user.is_staff}>
        {user.is_staff && (
          <>
            <div>
              <Button
                size="sm"
                outline={_team || teamValue}
                onClick={() => handleSetTeamValue(null)}
              >
                Todos
              </Button>
              <Button
                size="sm"
                outline={
                  teamValue !== Number(_team)
                    ? Number(_team) !== 1
                    : teamValue !== 1
                }
                onClick={() => handleSetTeamValue(1)}
              >
                Suporte
                {hasNotification(1) && (
                  <Notification
                    style={{
                      top: '-5px',
                      left: '94%',
                    }}
                  />
                )}
              </Button>
              <Button
                size="sm"
                outline={
                  teamValue !== Number(_team)
                    ? Number(_team) !== 2
                    : teamValue !== 2
                }
                onClick={() => handleSetTeamValue(2)}
              >
                Financeiro
                {hasNotification(2) && (
                  <Notification
                    style={{
                      top: '-5px',
                      left: '94%',
                    }}
                  />
                )}
              </Button>
              <Button
                size="sm"
                outline={
                  teamValue !== Number(_team)
                    ? Number(_team) !== 3
                    : teamValue !== 3
                }
                onClick={() => handleSetTeamValue(3)}
              >
                Comercial
                {hasNotification(3) && (
                  <Notification
                    style={{
                      top: '-5px',
                      left: '94%',
                    }}
                  />
                )}
              </Button>
              <Button
                size="sm"
                outline={
                  teamValue !== Number(_team)
                    ? Number(_team) !== 4
                    : teamValue !== 4
                }
                onClick={() => handleSetTeamValue(4)}
              >
                Migração
                {hasNotification(4) && (
                  <Notification
                    style={{
                      top: '-5px',
                      left: '94%',
                    }}
                  />
                )}
              </Button>
              <Button
                size="sm"
                outline={
                  teamValue !== Number(_team)
                    ? Number(_team) !== 5
                    : teamValue !== 5
                }
                onClick={() => handleSetTeamValue(5)}
              >
                Spam
                {hasNotification(5) && (
                  <Notification
                    style={{
                      top: '-5px',
                      left: '94%',
                    }}
                  />
                )}
              </Button>
              <Button
                size="sm"
                outline={
                  teamValue !== Number(_team)
                    ? Number(_team) !== 6
                    : teamValue !== 6
                }
                onClick={() => handleSetTeamValue(6)}
              >
                Alertas
                {hasNotification(6) && (
                  <Notification
                    style={{
                      top: '-5px',
                      left: '94%',
                    }}
                  />
                )}
              </Button>
              <Button
                size="sm"
                outline={
                  teamValue !== Number(_team)
                    ? Number(_team) !== 7
                    : teamValue !== 7
                }
                onClick={() => handleSetTeamValue(7)}
              >
                Painel
                {hasNotification(7) && (
                  <Notification
                    style={{
                      top: '-5px',
                      left: '94%',
                    }}
                  />
                )}
              </Button>
              <Button
                size="sm"
                outline={
                  _team !== null && teamValue !== Number(_team)
                    ? Number(_team) !== 0
                    : teamValue !== 0
                }
                onClick={() => handleSetTeamValue('0')}
              >
                Outros
                {hasNotification(0) && (
                  <Notification
                    style={{
                      top: '-5px',
                      left: '94%',
                    }}
                  />
                )}
              </Button>
            </div>
            <div>
              <Button
                size="sm"
                outline={
                  _filter !== filterValue
                    ? _filter !== 'all'
                    : filterValue !== 'all'
                }
                onClick={() => handleSetFilter('all')}
              >
                Todos
              </Button>
              <Button
                size="sm"
                outline={
                  _filter !== filterValue
                    ? _filter !== 'is_cloudez'
                    : filterValue !== 'is_cloudez'
                }
                onClick={() => handleSetFilter('is_cloudez')}
              >
                Cloudez
              </Button>
              <Button
                size="sm"
                outline={
                  _filter !== filterValue
                    ? _filter !== 'authorlast'
                    : filterValue !== 'authorlast'
                }
                onClick={() => handleSetFilter('authorlast')}
              >
                Author last
              </Button>
              <Button
                size="sm"
                outline={
                  _filter !== filterValue
                    ? _filter !== 'noresponse'
                    : filterValue !== 'noresponse'
                }
                onClick={() => handleSetFilter('noresponse')}
              >
                No answer
              </Button>
              <Button
                size="sm"
                outline={
                  _filter !== filterValue
                    ? _filter !== 'redalert'
                    : filterValue !== 'redalert'
                }
                onClick={() => handleSetFilter('redalert')}
              >
                Red alert
              </Button>
            </div>
            <div>
              <Button
                size="sm"
                outline={
                  _priority !== priorityValue
                    ? _priority !== ''
                    : priorityValue !== ''
                }
                onClick={() => handleSetPriority('')}
              >
                Todos
              </Button>
              <Button
                size="sm"
                outline={
                  _priority !== priorityValue
                    ? _priority !== '1'
                    : priorityValue !== '1'
                }
                onClick={() => handleSetPriority('1')}
              >
                <FontAwesomeIcon
                  icon={faCircle}
                  style={{
                    margin: 0,
                  }}
                  color="green"
                />
              </Button>
              <Button
                size="sm"
                outline={
                  _priority !== priorityValue
                    ? _priority !== '2'
                    : priorityValue !== '2'
                }
                onClick={() => handleSetPriority('2')}
              >
                <FontAwesomeIcon
                  icon={faCircle}
                  style={{
                    margin: 0,
                  }}
                  color="blue"
                />
              </Button>
              <Button
                size="sm"
                outline={
                  _priority !== priorityValue
                    ? _priority !== '3'
                    : priorityValue !== '3'
                }
                onClick={() => handleSetPriority('3')}
                color="yellow"
              >
                <FontAwesomeIcon
                  icon={faCircle}
                  style={{
                    margin: 0,
                  }}
                  color="yellow"
                />
              </Button>
              <Button
                size="sm"
                outline={
                  _priority !== priorityValue
                    ? _priority !== '4'
                    : priorityValue !== '4'
                }
                onClick={() => handleSetPriority('4')}
              >
                <FontAwesomeIcon
                  icon={faCircle}
                  style={{
                    margin: 0,
                  }}
                  color="orange"
                />
              </Button>
              <Button
                size="sm"
                outline={
                  _priority !== priorityValue
                    ? _priority !== '5'
                    : priorityValue !== '5'
                }
                onClick={() => handleSetPriority('5')}
              >
                <FontAwesomeIcon
                  icon={faCircle}
                  style={{
                    margin: 0,
                  }}
                  color="red"
                />
              </Button>
            </div>
          </>
        )}
        {/* <div>
          <Button
            size="sm"
            outline={
              statusValue !== Number(status)
                ? Number(status) !== 1
                : statusValue !== 1
            }
            onClick={() => handleSetStatus(1)}
          >
            Abertos
          </Button>
          <Button
            size="sm"
            outline={
              statusValue !== Number(status)
                ? Number(status) !== 2
                : statusValue !== 2
            }
            onClick={() => handleSetStatus(2)}
          >
            Fechados
          </Button>
        </div> */}
      </Filters>

      {loading ? (
        <TicketPlaceholder />
      ) : (
        <>
          {tickets?.length ? (
            <>
              <ListingHeader>
                <Row>
                  <Col xs="10" sm="10" md="4" lg="1">
                    <p>Status</p>
                  </Col>
                  <Col xs="10" sm="10" md="4" lg="4">
                    <p
                      onClick={() => {
                        order('id');
                      }}
                    >
                      Ticket
                      <Ordering
                        status={
                          orderValue?.column === 'id' ? orderValue?.value : 0
                        }
                      >
                        <FontAwesomeIcon
                          icon={
                            orderValue?.value === 2 ? faAngleUp : faAngleDown
                          }
                        />
                      </Ordering>
                    </p>
                  </Col>
                  <Col className="d-none d-md-flex">
                    <p
                      onClick={() => {
                        order('team');
                      }}
                    >
                      Área
                      <Ordering
                        status={
                          orderValue?.column === 'team' ? orderValue?.value : 0
                        }
                      >
                        <FontAwesomeIcon
                          icon={
                            orderValue?.value === 2 ? faAngleUp : faAngleDown
                          }
                        />
                      </Ordering>
                    </p>
                  </Col>
                  <Col className="d-none d-md-flex">
                    <p
                      onClick={() => {
                        order('created_at');
                      }}
                    >
                      Criado em
                      <Ordering
                        status={
                          orderValue?.column === 'created_at'
                            ? orderValue?.value
                            : 0
                        }
                      >
                        <FontAwesomeIcon
                          icon={
                            orderValue?.value === 1 ? faAngleUp : faAngleDown
                          }
                        />
                      </Ordering>
                    </p>{' '}
                  </Col>
                  <Col className="d-none d-md-flex">
                    <p
                      onClick={() => {
                        order('updated_at');
                      }}
                    >
                      {statusValue === 2 ? (
                        'Fechado em'
                      ) : (
                        <>
                          Atualizado em
                          <Ordering
                            status={
                              orderValue?.column === 'updated_at'
                                ? orderValue?.value
                                : 0
                            }
                          >
                            <FontAwesomeIcon
                              icon={
                                orderValue?.value === 1
                                  ? faAngleUp
                                  : faAngleDown
                              }
                            />
                          </Ordering>
                        </>
                      )}
                    </p>{' '}
                  </Col>
                  <Col className="d-none d-md-flex" />
                </Row>
              </ListingHeader>

              <AnimatedList initialAnimationDuration={800} animation="grow">
                {tickets.map(ticket => (
                  <TicketCard ticket={ticket} key={ticket.id} />
                ))}
              </AnimatedList>

              <Pagination
                onChangePage={getTicketsCallback}
                count={count}
                previous={previous}
                pageSize={10}
                next={next}
                current={current}
                search={search}
                team={teamValue}
                status={statusValue}
                filter={filterValue}
                order={orderValue}
                ticket
              />
            </>
          ) : null}
          {!loading && tickets?.length === 0 && (
            <EmptyListing>
              <p>Nenhum ticket encontrado.</p>
              <Button onClick={() => history.push('/suporte/create')} size="lg">
                Criar novo ticket
              </Button>
            </EmptyListing>
          )}
        </>
      )}
    </>
  );
};

export default props => (
  <TicketListingProvider>
    <Tickets {...props} />
  </TicketListingProvider>
);
