import React, { useContext, useEffect, useRef } from 'react';

import moment from 'moment';
import { Col, Row } from 'react-bootstrap';

import Chart from 'chart.js';

import { ThemeContext } from 'styled-components';
import { darken, transparentize } from 'polished';

import { Card, CardHeader } from './styles';

export const Graphics = ({ cloud, cloudInfo }) => {
  const theme = useContext(ThemeContext);

  const memRef = useRef<HTMLCanvasElement>();
  const cpuRef = useRef<HTMLCanvasElement>();
  const diskRef = useRef<HTMLCanvasElement>();

  useEffect(() => {
    if (cloudInfo) {
      const memChartRef = memRef?.current?.getContext('2d');
      const cpuChartRef = cpuRef?.current?.getContext('2d');
      const diskChartRef = diskRef?.current?.getContext('2d');

      const dates = cloud.status_timeline.map(s => {
        const d = moment(s.datetime);
        return d.format('HH:MM:ss');
      });

      const memData = cloud.status_timeline.map((s, i) => ({
        x: (100 / cloud.status_timeline.length) * i,
        y: Number(s.mem),
      }));

      const cpuData = cloud.status_timeline.map((s, i) => ({
        x: (100 / cloud.status_timeline.length) * i,
        y: Number(s.cpu),
      }));

      const gradientFillMem = memChartRef.createLinearGradient(0, 0, 0, 125);

      Chart.pluginService.register({
        beforeDraw(chart) {
          if (chart.config.options.elements.center) {
            const { ctx } = chart.chart;
            const centerConfig = chart.config.options.elements.center;
            const txt = centerConfig.text;
            ctx.font = '30px Lato';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillStyle = theme.interface4;
            const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
            const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;

            ctx.fillText(txt, centerX, centerY);
          }
        },
      });

      gradientFillMem.addColorStop(
        0,
        memData[memData.length - 1] && memData[memData.length - 1].y <= 50
          ? theme.success
          : memData[memData.length - 1] &&
            memData[memData.length - 1].y > 50 &&
            memData[memData.length - 1] &&
            memData[memData.length - 1].y <= 65
          ? theme.favorite
          : memData[memData.length - 1] &&
            memData[memData.length - 1].y > 65 &&
            memData[memData.length - 1] &&
            memData[memData.length - 1].y <= 80
          ? theme.alert
          : memData[memData.length - 1] && memData[memData.length - 1].y > 80
          ? theme.error
          : '#fff',
      );

      gradientFillMem.addColorStop(
        0.5,
        memData[memData.length - 1] && memData[memData.length - 1].y <= 50
          ? transparentize(0.3, theme.success)
          : memData[memData.length - 1] &&
            memData[memData.length - 1].y > 50 &&
            memData[memData.length - 1] &&
            memData[memData.length - 1].y <= 65
          ? transparentize(0.3, theme.favorite)
          : memData[memData.length - 1] &&
            memData[memData.length - 1].y > 65 &&
            memData[memData.length - 1] &&
            memData[memData.length - 1].y <= 80
          ? transparentize(0.3, theme.alert)
          : memData[memData.length - 1] && memData[memData.length - 1].y > 80
          ? transparentize(0.3, theme.error)
          : '#fff',
      );

      gradientFillMem.addColorStop(
        1,
        memData[memData.length - 1] && memData[memData.length - 1].y <= 50
          ? transparentize(0.9, theme.success)
          : memData[memData.length - 1] &&
            memData[memData.length - 1].y > 50 &&
            memData[memData.length - 1] &&
            memData[memData.length - 1].y <= 65
          ? transparentize(0.9, theme.favorite)
          : memData[memData.length - 1] &&
            memData[memData.length - 1].y > 65 &&
            memData[memData.length - 1] &&
            memData[memData.length - 1].y <= 80
          ? transparentize(0.9, theme.alert)
          : memData[memData.length - 1] && memData[memData.length - 1].y > 80
          ? transparentize(0.9, theme.error)
          : '#fff',
      );

      const gradientFillCpu = cpuChartRef.createLinearGradient(0, 0, 0, 125);

      gradientFillCpu.addColorStop(
        0,
        cpuData[cpuData.length - 1] && cpuData[cpuData.length - 1].y <= 50
          ? theme.success
          : cpuData[cpuData.length - 1] &&
            cpuData[cpuData.length - 1].y > 50 &&
            cpuData[cpuData.length - 1] &&
            cpuData[cpuData.length - 1].y <= 65
          ? theme.favorite
          : cpuData[cpuData.length - 1] &&
            cpuData[cpuData.length - 1].y > 65 &&
            cpuData[cpuData.length - 1] &&
            cpuData[cpuData.length - 1].y <= 80
          ? theme.alert
          : cpuData[cpuData.length - 1] && cpuData[cpuData.length - 1].y > 80
          ? theme.error
          : '#fff',
      );

      gradientFillCpu.addColorStop(
        0.5,
        cpuData[cpuData.length - 1] && cpuData[cpuData.length - 1].y <= 50
          ? transparentize(0.3, theme.success)
          : cpuData[cpuData.length - 1] &&
            cpuData[cpuData.length - 1].y > 50 &&
            cpuData[cpuData.length - 1] &&
            cpuData[cpuData.length - 1].y <= 65
          ? transparentize(0.3, theme.favorite)
          : cpuData[cpuData.length - 1] &&
            cpuData[cpuData.length - 1].y > 65 &&
            cpuData[cpuData.length - 1] &&
            cpuData[cpuData.length - 1].y <= 80
          ? transparentize(0.3, theme.alert)
          : cpuData[cpuData.length - 1] && cpuData[cpuData.length - 1].y > 80
          ? transparentize(0.3, theme.error)
          : '#fff',
      );

      gradientFillCpu.addColorStop(
        1,
        cpuData[cpuData.length - 1] && cpuData[cpuData.length - 1].y <= 50
          ? transparentize(0.9, theme.success)
          : cpuData[cpuData.length - 1] &&
            cpuData[cpuData.length - 1].y > 50 &&
            cpuData[cpuData.length - 1] &&
            cpuData[cpuData.length - 1].y <= 65
          ? transparentize(0.9, theme.favorite)
          : cpuData[cpuData.length - 1] &&
            cpuData[cpuData.length - 1].y > 65 &&
            cpuData[cpuData.length - 1] &&
            cpuData[cpuData.length - 1].y <= 80
          ? transparentize(0.9, theme.alert)
          : cpuData[cpuData.length - 1] && cpuData[cpuData.length - 1].y > 80
          ? transparentize(0.9, theme.error)
          : '#fff',
      );

      new Chart(cpuChartRef, {
        type: 'line',
        data: {
          labels: dates,
          datasets: [
            {
              label: 'CPU',
              data: cpuData,
              fill: 'origin',
              backgroundColor: gradientFillCpu,
              borderColor:
                cpuData[cpuData.length - 1] &&
                cpuData[cpuData.length - 1].y <= 50
                  ? darken(0.2, theme.success)
                  : cpuData[cpuData.length - 1] &&
                    cpuData[cpuData.length - 1].y > 50 &&
                    cpuData[cpuData.length - 1] &&
                    cpuData[cpuData.length - 1].y <= 65
                  ? darken(0.2, theme.favorite)
                  : cpuData[cpuData.length - 1] &&
                    cpuData[cpuData.length - 1].y > 65 &&
                    cpuData[cpuData.length - 1] &&
                    cpuData[cpuData.length - 1].y <= 80
                  ? darken(0.2, theme.alert)
                  : cpuData[cpuData.length - 1] &&
                    cpuData[cpuData.length - 1].y > 80 &&
                    cpuData[cpuData.length - 1] &&
                    cpuData[cpuData.length - 1].y <= 100
                  ? darken(0.2, theme.error)
                  : null,
              borderWidth: 2.5,
              lineTension: 0.3,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                display: false,
              },
            ],
            yAxes: [
              {
                display: false,
                ticks: {
                  min: 0,
                  max: 100,
                  stepSize: 10,
                },
              },
            ],
          },
          elements: {
            point: {
              radius: 0,
            },
          },
          legend: {
            display: false,
          },
        },
      });

      new Chart(memChartRef, {
        type: 'line',
        data: {
          labels: dates,
          datasets: [
            {
              label: 'Memória',
              data: memData,
              backgroundColor: gradientFillMem,
              borderColor:
                memData[memData.length - 1] &&
                memData[memData.length - 1].y <= 50
                  ? darken(0.2, theme.success)
                  : memData[memData.length - 1] &&
                    memData[memData.length - 1].y > 50 &&
                    memData[memData.length - 1] &&
                    memData[memData.length - 1].y <= 80
                  ? darken(0.2, theme.favorite)
                  : memData[memData.length - 1] &&
                    memData[memData.length - 1].y > 65 &&
                    memData[memData.length - 1] &&
                    memData[memData.length - 1].y <= 80
                  ? darken(0.2, theme.alert)
                  : memData[memData.length - 1] &&
                    memData[memData.length - 1].y > 80 &&
                    memData[memData.length - 1] &&
                    memData[memData.length - 1].y <= 100
                  ? darken(0.2, theme.error)
                  : null,
              borderWidth: 2,
              lineTension: 0.3,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                display: false,
              },
            ],
            yAxes: [
              {
                display: false,
                ticks: {
                  min: 0,
                  max: 100,
                  stepSize: 10,
                },
              },
            ],
          },
          elements: {
            point: {
              radius: 0,
            },
          },
          legend: {
            display: false,
          },
        },
      });

      new Chart(diskChartRef, {
        type: 'doughnut',
        data: {
          labels: ['Disco'],
          datasets: [
            {
              label: 'Disco',
              data: [
                cloudInfo && cloudInfo.disk,
                cloudInfo && 100 - cloudInfo.disk,
              ],
              backgroundColor: [
                cloudInfo && cloudInfo.disk <= 50
                  ? theme.success
                  : cloudInfo && cloudInfo.disk > 50 && cloudInfo.disk <= 65
                  ? theme.favorite
                  : cloudInfo && cloudInfo.disk > 65 && cloudInfo.disk <= 80
                  ? theme.alert
                  : cloudInfo && cloudInfo.disk > 80 && cloudInfo.disk <= 100
                  ? theme.error
                  : null,
                theme.interface3,
              ],
              borderWidth: [0, 0],
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          elements: {
            center: {
              text: `${cloudInfo && Math.floor(cloudInfo.disk)}%`,
            },
          },
          cutoutPercentage: 90,
          legend: {
            display: false,
          },
        },
      });
    }
    // eslint-disable-next-line
  }, [cloud, cloudInfo]);

  return (
    <Row>
      <Col xl="4">
        <Card>
          <CardHeader>CPU</CardHeader>
          {cloudInfo ? (
            <div
              style={{
                width: '100%',
                height: '100%',
                margin: 'auto',
                position: 'relative',
              }}
            >
              <canvas ref={cpuRef} />
              <span
                style={{
                  position: 'absolute',
                  width: '100%',
                  color: theme.interface5,
                  textAlign: 'center',
                  top: 'calc(50% - 25px)',
                  fontSize: '20px',
                }}
              >
                {cloudInfo.cpu}%
              </span>
            </div>
          ) : (
            <p
              style={{
                width: '100%',
                textAlign: 'center',
                color: theme.interface4,
                fontSize: '12px',
                marginTop: '12px',
              }}
            >
              O cloud está em sincronização
            </p>
          )}
        </Card>
      </Col>
      <Col xl="4">
        <Card>
          <CardHeader>Memória</CardHeader>
          {cloudInfo ? (
            <div
              style={{
                width: '100%',
                height: '100%',
                margin: 'auto',
                position: 'relative',
              }}
            >
              <canvas ref={memRef} />
              <span
                style={{
                  position: 'absolute',
                  width: '100%',
                  color: theme.interface5,
                  textAlign: 'center',
                  top: 'calc(50% - 25px)',
                  fontSize: '20px',
                }}
              >
                {cloudInfo.mem}%
              </span>
            </div>
          ) : (
            <p
              style={{
                width: '100%',
                textAlign: 'center',
                color: theme.interface4,
                fontSize: '12px',
                marginTop: '12px',
              }}
            >
              O cloud está em sincronização
            </p>
          )}
        </Card>
      </Col>
      <Col xl="4">
        <Card>
          <CardHeader>Disco</CardHeader>
          {cloudInfo ? (
            <div
              style={{
                width: '100%',
                height: '100%',
                margin: 'auto',
                position: 'relative',
              }}
            >
              <canvas ref={diskRef} />
            </div>
          ) : (
            <p
              style={{
                width: '100%',
                textAlign: 'center',
                color: theme.interface4,
                fontSize: '12px',
                marginTop: '12px',
              }}
            >
              O cloud está em sincronização
            </p>
          )}
        </Card>
      </Col>
    </Row>
  );
};
