import axios from 'axios';

const api = axios.create({
  baseURL:
    window.location.hostname === 'demo.cloudez.io'
      ? 'https://dev-api.cloudez.io/'
      : process.env.REACT_APP_API_URL,
});

export default api;
