import React from 'react';
import Placeholder from 'components/Placeholder';
import { Col } from 'react-bootstrap';
import { Card } from '../AddonCard/styles';

const AddonPlaceholder = () => {
  const placeholders = [];

  for (let i = 1; i <= 10; i++) {
    placeholders.push(i);
  }

  return placeholders.map((p, i) => (
    <Col key={i} xs="6" sm="6" md="4" lg="3" xl="2">
      <Card>
        <Placeholder width={40} height={40} />
        <p>
          <Placeholder width={100} height={12} />
        </p>
      </Card>
    </Col>
  ));
};

export default AddonPlaceholder;
