import React, {
  useMemo,
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
  Dispatch,
  SetStateAction,
} from 'react';
import Slider from 'react-slick';

import { useAuth } from 'hooks/auth';

import { Row, Col } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import Placeholder from 'components/Placeholder';
import { faUserSecret } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import CurrencyFormat from 'react-currency-format';
import EmptyListing from 'components/EmptyListing';
import { Tab, Tabs } from 'components/Tabs';
import useDevice from 'hooks/device';
import { Button } from '@cloudez/cloudez-design-system';
import { useLayout } from 'hooks/layout';
import { CloudCreateContext } from '../_context/state';
import { Wrapper, PlanCard, Private, RecurrencySelect } from './styles';

interface IPlansProps {
  setStage: Dispatch<SetStateAction<number>>;
  setSelectedPlan: any;
  tab: number;
  setTab: (value: number) => void;
}

const Plan: React.FC<IPlansProps> = ({
  tab,
  setTab,
  setStage,
  setSelectedPlan,
}) => {
  const { updatePlan, plans, getPlans, loading, recurrency, setRecurrency } =
    useContext(CloudCreateContext);

  const { slug, stamp } = useLayout();
  const { user } = useAuth();

  const _stamp = stamp.toLowerCase();

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);

  const recommendation = urlParams.get('recommendation')?.split(',');

  const slider = useRef(null);

  useEffect(() => {
    const slickListDiv = document.getElementsByClassName('slick-list')[0];
    slickListDiv.addEventListener('wheel', (event: WheelEvent) => {
      event.preventDefault();
      event.deltaY > 0
        ? slider?.current?.slickNext()
        : slider?.current?.slickPrev();
    });
  }, []);

  const settings = useMemo(
    () => ({
      // centerMode: true,
      infinite: false,
      // focusOnSelect: true,
      speed: 300,
      slidesToShow: 3.5,
      slidesToScroll: 4,
      dots: true,
      // nextArrow: <NextArrow />,
      // prevArrow: <PrevArrow />,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2.3,
            slidesToScroll: 2,
            initialSlide: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1.2,
            slidesToScroll: 1,
          },
        },
      ],
    }),
    [],
  );

  const placeholders = [];

  for (let i = 1; i <= 4; i++) {
    placeholders.push(i);
  }

  const { isMobile } = useDevice();

  const onTabChange = useCallback(_tab => {
    let _size_category;
    switch (_tab) {
      case 1:
        _size_category = 'default';
        break;
      case 2:
        _size_category = 'ram';
        break;
      case 3:
        _size_category = 'cpu';
        break;
      case 4:
        _size_category = 'email';
        break;
      case 5:
        _size_category = 'volume';
        break;
      default:
        break;
    }

    setTab(_tab);
    getPlans(_size_category, true);
  }, []);

  useEffect(() => {
    if (recommendation?.includes('volume')) {
      onTabChange(5);
    } else if (recommendation?.includes('email')) {
      onTabChange(4);
    } else {
      getPlans('default', true);
    }
  }, []);

  return (
    <Wrapper>
      <RecurrencySelect>
        <div
          className={recurrency === 'monthly' ? 'selected' : ''}
          onClick={() => setRecurrency('monthly')}
        >
          Mensal
        </div>
        <div
          className={recurrency === 'annual' ? 'selected' : ''}
          onClick={() => setRecurrency('annual')}
        >
          Anual
        </div>
      </RecurrencySelect>

      <p>Pague anualmente e economize até 10%</p>

      {!isMobile && (
        <Tabs className="my-4" style={{ justifyContent: 'center' }}>
          <Tab
            style={{ width: 'auto' }}
            active={tab === 1}
            onClick={() => onTabChange(1)}
          >
            Clouds padrão
          </Tab>
          <Tab
            style={{ width: 'auto' }}
            active={tab === 2}
            onClick={() => onTabChange(2)}
          >
            Clouds focados em Memória
          </Tab>
          <Tab
            style={{ width: 'auto' }}
            active={tab === 3}
            onClick={() => onTabChange(3)}
          >
            Clouds focados em Processamento
          </Tab>
          <Tab
            style={{ width: 'auto' }}
            active={tab === 4}
            onClick={() => onTabChange(4)}
          >
            Cloud para E-mails
          </Tab>
          {slug !== 'configr' && user.is_company_owner && (
            <Tab
              style={{ width: 'auto' }}
              active={tab === 5}
              onClick={() => onTabChange(5)}
            >
              Volume
            </Tab>
          )}
        </Tabs>
      )}

      <Row>
        <Col
          xl="12"
          style={{
            marginBottom: '25px',
          }}
        >
          <Slider {...settings} ref={slider}>
            {!loading ? (
              plans?.results?.length > 0 ? (
                plans?.results?.map(p => {
                  const getAnnual = value =>
                    value ? value * 12 - value * 12 * 0.1 : '0';

                  const annualPrice = getAnnual(p?.price_total?.amount);
                  const annualTurboPrice = getAnnual(
                    p?.turbo_price_total?.amount,
                  );

                  const description = (() => {
                    if (p?.description && p?.description !== 'False') {
                      return p?.description;
                    }

                    if (
                      p?.cloud_size?.info &&
                      p?.cloud_size?.info['tip_pt-br']
                    ) {
                      return p?.cloud_size?.info['tip_pt-br'];
                    }

                    return '';
                  })();

                  const isDedicated =
                    recommendation?.includes('dedicated') && p?.is_individual;

                  const isShared =
                    recommendation?.includes('shared') && !p?.is_individual;

                  const isBasic = recommendation?.includes('basic');
                  const isTurbo = recommendation?.includes('turbo');

                  const isVolume = recommendation?.includes('volume');

                  const isEmail = (() => {
                    return p?.cloud_size?.category === 3;
                  })();

                  return (
                    <div key={p?.id}>
                      <PlanCard private={p?.is_private}>
                        {p?.is_private && (
                          <Private>
                            <FontAwesomeIcon icon={faUserSecret} />
                          </Private>
                        )}
                        <div className="top">
                          <div className="plan-title">
                            {p?.name}
                            <span>{p?.cloud_size?.memory} RAM</span>
                          </div>

                          <span className="tip">{description}</span>

                          <div className="prices">
                            {(p?.has_basic || isEmail) && (
                              <div
                                className={
                                  (isBasic && isDedicated && tab === 1) ||
                                  (isBasic && isShared && tab === 1) ||
                                  (isBasic && isVolume)
                                    ? 'recommended'
                                    : ''
                                }
                              >
                                {((isBasic && isDedicated && tab === 1) ||
                                  (isBasic && isShared && tab === 1) ||
                                  (isBasic && isVolume)) && (
                                  <span className="idealPlan">Plano ideal</span>
                                )}
                                <div className="price ">
                                  <span>{isEmail ? 'Preço' : 'Preço'}</span>
                                  <CurrencyFormat
                                    value={
                                      recurrency === 'monthly'
                                        ? p?.price_total?.amount
                                        : annualPrice
                                    }
                                    displayType="text"
                                    fixedDecimalScale
                                    decimalScale={2}
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    prefix={
                                      p?.price_total?.currency === 'BRL'
                                        ? 'R$ '
                                        : '$'
                                    }
                                  />
                                </div>
                              </div>
                            )}
                            {p?.has_turbo && !isEmail && (
                              <div
                                className={
                                  (isTurbo && isDedicated && tab === 1) ||
                                  (isTurbo && isShared && tab === 1) ||
                                  (isTurbo && isVolume)
                                    ? 'recommended'
                                    : ''
                                }
                              >
                                {((isTurbo && isDedicated && tab === 1) ||
                                  (isTurbo && isShared && tab === 1) ||
                                  (isTurbo && isVolume)) && (
                                  <span className="idealPlan">Plano ideal</span>
                                )}
                                <div className="price">
                                  <span>Preço</span>
                                  <CurrencyFormat
                                    value={
                                      recurrency === 'monthly'
                                        ? p?.turbo_price_total?.amount
                                        : annualTurboPrice
                                    }
                                    displayType="text"
                                    fixedDecimalScale
                                    decimalScale={2}
                                    decimalSeparator=","
                                    thousandSeparator="."
                                    prefix={
                                      p?.price_total?.currency === 'BRL'
                                        ? 'R$ '
                                        : '$'
                                    }
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>

                        <div className="separator" />
                        <div className="bottom">
                          {p?.cloud_size && (
                            <ul>
                              <li>DISCO</li>
                              <li className="value">{p?.cloud_size.disk}</li>
                              <li>PROCESSADOR</li>
                              <li className="value">
                                {p?.cloud_size.cores} CPU core
                              </li>
                            </ul>
                          )}
                        </div>
                        <Button
                          style={{ width: '100%', maxWidth: '200px' }}
                          onClick={() => {
                            updatePlan(p);
                            setSelectedPlan(true);
                            setStage(3);
                          }}
                        >
                          Selecionar
                        </Button>
                      </PlanCard>
                    </div>
                  );
                })
              ) : (
                <EmptyListing>
                  <p>Nenhum plano encontrado.</p>
                </EmptyListing>
              )
            ) : (
              placeholders.map((p, i) => (
                <div key={i}>
                  <PlanCard>
                    <div className="top">
                      <div className="plan-title">
                        <Placeholder width={48} height={14} />
                        <Placeholder width={80} height={14} />
                      </div>
                      <div className="tip">
                        <Placeholder width="100%" height={40} />
                      </div>
                      <div className="price">
                        <span>
                          <Placeholder width={40} height={14} />
                        </span>
                        <span>
                          <Placeholder width={80} height={28} />
                        </span>
                      </div>
                    </div>
                    <div className="separator" />
                    <div className="bottom">
                      <span>
                        <Placeholder width={40} height={12} />
                      </span>
                      <span>
                        <Placeholder width={55} height={14} />
                      </span>
                      <span>
                        <Placeholder width={40} height={12} />
                      </span>
                      <span>
                        <Placeholder width={55} height={14} />
                      </span>
                    </div>
                    <div className="d-flex justify-content-center">
                      <Placeholder width={130} height={30} />
                    </div>
                  </PlanCard>
                </div>
              ))
            )}
          </Slider>
        </Col>
      </Row>
    </Wrapper>
  );
};

export default Plan;
