import React, { createContext, useReducer } from 'react';
import reducer from './reducer';
import { setLoading, getCloud, patchCloud, deleteCloud } from './actions';

// initial state
const initialState = {
  cloud: null,
  updateLoading: false,
  errors: null,
};

// context
export const CloudDetailContext = createContext(initialState);

// provider
export const CloudDetailProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <CloudDetailContext.Provider
      value={{
        // state
        cloud: state.cloud,
        updateLoading: state.updateLoading,
        errors: state.errors,

        // actions
        setLoading: value => setLoading(dispatch, value),
        getCloud: async id => await getCloud(dispatch, id),
        deleteCloud: async (id, motive) =>
          await deleteCloud(dispatch, id, motive),
        patchCloud: async (field, value, id) =>
          await patchCloud(dispatch, field, value, id),
      }}
    >
      {children}
    </CloudDetailContext.Provider>
  );
};
