import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { AnimatedList } from 'react-animated-list';

import Placeholder from 'components/Placeholder';

import { Listing } from 'components/Listing/styles';
import { Card, CardContent } from '../styles';

const DomainRecordPlaceholder = () => {
  const placeholders = [];

  for (let i = 1; i <= 4; i++) {
    placeholders.push(i);
  }

  return (
    <Listing>
      <AnimatedList initialAnimationDuration={800} animation="grow">
        {placeholders.map((p, i) => (
          <Card key={i}>
            <CardContent>
              <Row
                style={{
                  display: 'flex',
                  height: '100%',
                  width: '100%',
                }}
              >
                <Col sm="2">
                  <span style={{ marginTop: 12 }}>
                    <Placeholder height={12} />
                  </span>
                </Col>

                <Col sm="3">
                  <span style={{ marginTop: 12 }}>
                    <Placeholder height={12} />
                  </span>
                </Col>

                <Col sm="6">
                  <span style={{ marginTop: 12 }}>
                    <Placeholder height={12} />
                  </span>
                </Col>

                <Col sm="1">
                  <span style={{ marginTop: 12 }}>
                    <Placeholder height={12} />
                  </span>
                </Col>
              </Row>
            </CardContent>
          </Card>
        ))}
      </AnimatedList>
    </Listing>
  );
};

export default DomainRecordPlaceholder;
