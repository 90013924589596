import React from 'react';
import Placeholder from 'components/Placeholder';
import { Col } from 'react-bootstrap';

import { Type } from '../styles';

const WebsitePlaceholder: React.FC = () => {
  const placeholders = [];

  for (let i = 1; i <= 12; i++) {
    placeholders.push(i);
  }

  return (
    <>
      {placeholders.map((p, i) => (
        <Col key={i} lg="2" md="3" sm="4" xs="6">
          <Type>
            <Placeholder width={40} height={40} />
            <span>
              <Placeholder width={70} height={12} />
            </span>
          </Type>
        </Col>
      ))}
    </>
  );
};

export default WebsitePlaceholder;
