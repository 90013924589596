import React from 'react';

import cloudezFooter from 'assets/img/svg/logo_cloudez.svg';
import { Wrapper, FooterStyle, Logo, Text } from './styles';

const Footer: React.FC = () => {
  return (
    <Wrapper>
      <FooterStyle>
        <Logo src={cloudezFooter} />
        <Text>
          Cloud Easy, Corp © 2017.
          <br />
          Cloudez™ - All rights reserved.
        </Text>
      </FooterStyle>
    </Wrapper>
  );
};

export default Footer;
