import { useAuth } from 'hooks/auth';
import { useLayout } from 'hooks/layout';
import { Layouts } from 'pages';
import React, { useEffect, useMemo, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import Can from './Can';

const RouteWrapper: React.FC<any> = ({
  component: Component,
  isPrivate,
  defaultLayout,
  authLayout,
  loginLayout,
  roles,
  ...rest
}) => {
  const { user, skipBilling } = useAuth();
  const { has_free_trial } = useLayout();
  const isAuthenticated = !!user;
  const lastLocation = useLastLocation();

  const [locate, setLocate] = useState();

  useEffect(() => {
    if (lastLocation?.pathname !== '/') {
      setLocate(lastLocation?.pathname);
    }
  }, [lastLocation]);

  const Layout = useMemo<any>(() => {
    if (loginLayout) {
      return Layouts.LoginLayout;
    }

    if (isAuthenticated === (isPrivate || defaultLayout) && !authLayout) {
      return Layouts.DefaultLayout;
    }

    return Layouts.AuthLayout;
  }, [isPrivate, defaultLayout, isAuthenticated, loginLayout, authLayout]);

  if (!isAuthenticated && isPrivate) {
    /*
    Redirect to '/' if user is unauthenticated and route is private
    */
    return <Redirect to="/" />;
  }

  if (!rest.path || rest.disabled) return <Redirect to="/404" />;

  if (rest.location.pathname.includes('tickets')) {
    return (
      <Redirect to={rest.location.pathname.replace('tickets', 'suporte')} />
    );
  }
  /*
    Special routes logic
  */
  if (user) {
    if (
      !user.is_staff &&
      !user.is_verified &&
      rest.path !== '/phone/validate'
    ) {
      return <Redirect to="/phone/validate" />;
    }

    if (user.is_verified && rest.path === '/phone/validate') {
      return <Redirect to="/dashboard" />;
    }

    if (
      !skipBilling &&
      isAuthenticated &&
      !user.is_staff &&
      user.billing_outdated &&
      user.company_slug === 'configr' &&
      rest.path !== '/update/billing'
    ) {
      return <Redirect to="/update/billing" />;
    }

    if (isAuthenticated && rest.path === '/login/2fa') {
      return <Redirect to="/dashboard" />;
    }

    if (!has_free_trial && rest.path === '/onboard') {
      return <Redirect to="/dashboard" />;
    }
  }

  if (isAuthenticated && !isPrivate && !defaultLayout) {
    if (lastLocation?.pathname !== '/') return <Redirect to={lastLocation} />;

    return <Redirect to="/dashboard" />;
  }

  return (
    <Can roles={roles}>
      <Route
        {...rest}
        render={props => (
          <Layout defaultLayout={defaultLayout}>
            <Component {...props} />
          </Layout>
        )}
      />
    </Can>
  );
};

export default RouteWrapper;
