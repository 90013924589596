import React, { useEffect, useState } from 'react';
import { withTheme } from 'styled-components';
import { Row, Col } from 'react-bootstrap';

import ApplicationIcon from 'components/ApplicationIcon';

import { Input, Field, Label, Error } from '@cloudez/cloudez-design-system';

import { getDatabaseTypesService } from 'services/database';
import { useAuth } from 'hooks/auth';
import { Wrapper, Type } from './styles';

import DatabaseTypePlaceholder from './Placeholder';

const DatabaseTypes = ({ newDatabase, setNewDatabase, databaseNameError }) => {
  const { user } = useAuth();

  const [types, setTypes] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getDatabaseTypes() {
      setLoading(true);
      const { data } = await getDatabaseTypesService({
        company: user.company,
      });

      setTypes(data);
      setLoading(false);
    }

    if (!types.length) getDatabaseTypes();
    // eslint-disable-next-line
  }, [types]);

  return (
    <Wrapper>
      <Field>
        <Label>Tipo do database</Label>
        <Row
          style={{
            marginLeft: 0,
            width: '100%',
          }}
        >
          {loading ? (
            <DatabaseTypePlaceholder />
          ) : (
            types?.map(t => (
              <Col
                key={t.id}
                xs="12"
                sm="6"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Type
                  onClick={() =>
                    setNewDatabase({
                      ...newDatabase,
                      type: t.id,
                      values: t.values.map(t => ({
                        slug: t.slug,
                        value: t.default,
                      })),
                    })
                  }
                  selected={newDatabase.type === t.id}
                >
                  <ApplicationIcon
                    width="40px"
                    height="40px"
                    icon={
                      t.slug === 'selfhosted-mail'
                        ? 'cloudez-footer'
                        : `${t.slug}`
                    }
                  />
                  <span>{t.name}</span>
                  <p>{t.description}</p>
                </Type>
              </Col>
            ))
          )}
        </Row>
      </Field>

      <Field>
        <Label>Nome do database</Label>
        <Input
          placeholder="Dê um nome pra seu banco de dados..."
          block
          onChange={e => {
            setNewDatabase({
              ...newDatabase,
              values: newDatabase.values.map(v =>
                v.slug === 'database_name'
                  ? { ...v, value: e.target.value }
                  : v,
              ),
            });
          }}
          error={databaseNameError}
        />
        {databaseNameError && <Error>{databaseNameError}</Error>}
      </Field>
    </Wrapper>
  );
};

export default DatabaseTypes;
