import styled from 'styled-components';

interface OffBadgeProps {
  isActive?: boolean;
}

export const OffBadge = styled.div<OffBadgeProps>`
  margin-left: 10px;
  background: ${props =>
    props.isActive ? props.theme.success : props.theme.interface2};
  color: ${props =>
    props.isActive ? props.theme.interface1 : props.theme.interface4};

  width: 49px;
  height: 19px;
  padding: 4px 14px;
  border-radius: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
`;

export const CardContent = styled.div`
  padding: 20px 0px;
  font-size: 14px;
  color: ${props => props.theme.interface5};

  ul {
    margin-top: 20px;
    column-count: 3;

    li {
      color: ${props =>
        props.theme.darkMode ? props.theme.interface4 : props.theme.interface6};
    }
  }
`;

export const StatusCard = styled.div`
  height: 100%;
  padding: 15px;
  background: ${props => props.theme.interface1};
  border-radius: 5px;
`;

export const AddRecordButton = styled.div`
  height: 30px;
  width: 30px;
  background-color: ${props => props.theme.brand_secondary_color};
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: width 0.3s ease-in-out;

  svg {
    margin-right: -105px;
    transition: all 0.05s ease-out;
  }

  &:hover {
    width: auto;
    padding: 0px 8px;
    transition: all 0.3s ease-out;

    svg {
      margin-right: 0;
      transition: all 0.3s ease-out;
      transform: rotateZ(-180deg);
    }

    span {
      transition: opacity 0.3s ease-in-out;
      transition-delay: 0.1s;
      opacity: 1;
    }
  }

  span {
    opacity: 0;
    margin-left: 8px;
    font-size: 12px;
    color: ${props => props.theme.brand_primary_color};
  }
`;

export const Rtypes = styled.div`
  max-width: 100%;
  padding: 10px;
  margin-top: -28px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  div {
    width: auto;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 8px;
  }

  button {
    width: 70px;
    margin-right: 8px;
  }
`;
