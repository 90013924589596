import styled, { css } from 'styled-components';
import { Button } from '@cloudez/cloudez-design-system';
import { darken } from 'polished';

export const Tabs = styled.ul`
  list-style: none;
  display: flex;
`;

interface ITabProps {
  active?: boolean;
  confirm?: boolean;
}

export const Tab = styled.li<ITabProps>`
  font-size: 14px;
  color: ${props => props.theme.interface5};
  padding: 6px 0px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  border-bottom: 3px solid transparent;
  transition: border 200ms ease-in-out;

  ${props =>
    props.active &&
    css`
      border-bottom: 3px solid
        ${props.confirm ? props.theme.success : props.theme.brand_primary_color};
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      color: ${props.confirm
        ? props.theme.interface5
        : props.theme.brand_primary_color};
    `}

  ${props =>
    props.confirm &&
    css`
      svg {
        margin-right: 10px;
        color: ${props.active ? props.theme.success : props.theme.interface5};
      }
    `}


  @media (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;

    ${props =>
      !props.active &&
      css`
        display: none;
      `}
  }
`;

export const Loading = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    font-size: 50px;
    color: ${props => props.theme.brand_primary_color};
  }
`;

interface IContentProps {
  loading?: boolean;
}

export const Content = styled.div<IContentProps>`
  ${props =>
    props.loading &&
    css`
      opacity: 0.5;
    `}
`;

export const TabContent = styled.div<{ noPadding?: boolean }>`
  box-shadow: 0px 2px 15px rgba(100, 114, 140, 0.25);
  position: relative;
  height: auto;
  width: 100%;
  background: ${props => props.theme.interface1};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  border-radius: 5px;
  padding: ${props => (props.noPadding ? '' : '18px 12px')};
`;

export const TabFooter = styled.div`
  border-top: 1px solid ${props => props.theme.interface3};
  height: auto;
  width: 100%;
  padding: 15px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  background: ${props => props.theme.interface2};

  div {
    cursor: default;
    svg {
      color: ${props => props.theme.interface4};
    }
    color: ${props => props.theme.interface5};

    &:hover {
      color: ${props => props.theme.brand_primary_color};
      svg {
        color: ${props => props.theme.brand_primary_color};
      }
    }

    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
  }
`;

interface IFieldProps {
  center?: boolean;
}

export const Field = styled.div<IFieldProps>`
  width: 100%;
  margin-bottom: 16px;

  ${props =>
    props.center &&
    css`
      padding: 0px 14px;
      width: auto;
      max-width: 270px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    `}
`;

export const Label = styled.span`
  display: block;
  font-size: 12px;
  color: ${props => darken(0.2, props.theme.interface4)};
  svg {
    font-size: 14px;
  }
`;

interface IValueProps {
  link?: boolean;
}

export const Value = styled.span<IValueProps>`
  a {
    color: ${props => props.theme.interface6};
    text-decoration: underline;
  }

  margin-top: 2px;
  display: block;
  font-size: 14px !important;
  color: ${props =>
    props.link
      ? props.theme.brand_primary_color
      : props.theme.interface5} !important;
  ${props =>
    props.link &&
    css`
      cursor: pointer;
    `}

  svg {
    margin-left: 8px;
  }

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Separator = styled.div`
  width: 1px;
  background: ${props => props.theme.interface3};
`;

export const Header = styled.div`
  width: 100%;
  margin-bottom: 40px;

  @media (max-width: 768px) {
    margin-bottom: 30px;

    ${Button} {
      margin-top: 10px;
      width: 100%;
    }
  }
`;

export const Title = styled.div`
  margin-left: 20px;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props =>
    props.theme.darkMode
      ? props.theme.interface4
      : props.theme.interface7} !important;

  h3 {
    font-size: 18px;
    font-weight: bold;

    a {
      text-decoration: none;
      color: ${props =>
        props.theme.darkMode
          ? props.theme.interface4
          : props.theme.interface7} !important;
    }
  }

  p {
    font-size: 12px;
    color: ${props => props.theme.interface5};
  }

  svg {
    cursor: pointer;
    margin-left: 10px;
    color: ${props => props.theme.brand_primary_color};
  }
`;

interface IOfflineButtonProps {
  offline?: boolean;
}

export const OfflineButton = styled.button<IOfflineButtonProps>`
  padding: 11px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease-in;
  font-size: 16px;
  font-weight: 700;
  font-family: 'Nunito', sans-serif;
  height: 40px;
  span {
    margin-left: 8px;
  }
  ${props =>
    props.offline
      ? css`
          background: ${props => props.theme.errorNew};
          border: 1px solid ${props => props.theme.errorNew};
          color: white;
          &:hover {
            border: 1px solid transparent;
            background: ${props => darken(0.1, props.theme.errorNew)};
          }
        `
      : css`
          background: ${props => props.theme.brand_primary_color};
          border: 1px solid ${props => props.theme.brand_primary_color};
          color: white;
          &:hover {
            border: 1px solid transparent;
            background: ${props =>
              darken(0.1, props.theme.brand_primary_color)};
          }
        `}
`;

export const Card = styled.div`
  height: auto;
  padding: 15px;
  background: ${props => props.theme.interface1};
  border-radius: 5px;
`;

export const CardHeader = styled.h3`
  font-size: 16px;
  color: ${props => props.theme.interface5};
`;

export const Informations = styled.div`
  padding: 30px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: 768px) {
    padding: 10px 0px;
  }
`;

export const Information = styled.div`
  svg {
    color: ${props => props.theme.interface5};
    font-size: 18px;
  }
  color: ${props =>
    props.theme.darkMode ? props.theme.interface4 : props.theme.interface6};

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;
`;

export const TicketCard = styled.div`
  height: 100%;
  padding: 15px;
  background: ${props => props.theme.interface1};
  border-radius: 5px;
`;
