import styled from 'styled-components';

export const HttpsText = styled.p`
  font-size: 14px;
  line-height: 100%;
  color: ${props => props.theme.interface5};

  .error {
    text-transform: uppercase;
    color: ${props => props.theme.errorNew};
    font-weight: bold;
  }
`;
