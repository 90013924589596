import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  ${props =>
    !props.theme.darkMode &&
    css`
      background: linear-gradient(
        180deg,
        ${props.theme.interface2},
        ${props.theme.interface3}
      );
    `}
  padding: 0px 30px;
  grid-area: footer;
`;

export const FooterStyle = styled.div`
  padding: 20px 0;
  display: flex;
  height: 100%;
  border-top: 1px solid
    ${props =>
      props.theme.darkMode ? props.theme.interface1 : props.theme.interface3};
  justify-content: flex-start;
  align-items: center;
`;

export const Logo = styled.img`
  margin: 0px 17px;
  transition: transform 0.5s ease-in;
  height: 30px;
`;

export const Text = styled.p`
  font-size: 10px;
  color: #aaa;
`;
