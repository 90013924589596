import React, { useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { Button, Error } from '@cloudez/cloudez-design-system';

import Progress from 'components/Progress';
import { CreateWrapper, CreateHeader, CreateButtons } from 'components/Common';
import Domain from './Domain';
import Cloud from './Cloud';
import Payment from './Payment';
import Success from './Success';
import EmailTypes from './EmailTypes';
import { EmailCreateProvider, EmailCreateContext } from './_context/state';

const EmailCreate = () => {
  const { newEmail, createEmail, loading, errors } =
    useContext(EmailCreateContext);

  const [stage, setStage] = useState<any>(1 as any);
  const [buy, setBuy] = useState(false as any);

  const _createEmail = async (buyDomain = false) => {
    try {
      await createEmail(newEmail, buyDomain);
    } catch (e) {
      throw e;
    }
  };

  return (
    <>
      {stage !== 'success' && (
        <CreateWrapper>
          <CreateHeader>
            <h1>
              {stage === 1
                ? 'Você possui um domínio?'
                : stage === 2
                ? 'Selecione o tipo do email'
                : stage === 3
                ? 'Escolha um de seus clouds'
                : stage === 4
                ? 'Pagamento'
                : ''}
            </h1>
            <Progress status={stage} n={buy ? 4 : 3} />
          </CreateHeader>

          {stage === 1 && <Domain setBuy={setBuy} />}

          {stage === 2 && <EmailTypes />}

          {stage === 3 && <Cloud />}

          {stage === 4 && (
            <Payment
              createEmail={_createEmail}
              domain={{
                ...buy,
                domain_do_hire: true,
              }}
              setStage={setStage}
            />
          )}

          {errors && <Error>{errors[Object.keys(errors)[0]][0]}</Error>}

          <CreateButtons>
            {stage >= 2 && (
              <Button
                outline
                onClick={() => setStage(stage - 1)}
                style={{
                  marginTop: '20px',
                }}
              >
                Voltar
              </Button>
            )}

            {buy && stage === 4 ? (
              <Button
                disable={loading}
                style={{
                  marginTop: '20px',
                }}
                icon
                type="submit"
                form="domainWebsiteForm"
              >
                {loading ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  'Finalizar'
                )}
              </Button>
            ) : (
              <Button
                disabled={
                  loading ||
                  (stage === 2 && !newEmail.type) ||
                  (stage === 3 && !newEmail.cloud) ||
                  (stage === 2 &&
                    newEmail.type > 2 &&
                    newEmail.values.filter(
                      v => v.slug === 'verification_code',
                    )[0].value === '') ||
                  newEmail.domain === ''
                }
                style={{
                  marginTop: '20px',
                }}
                onClick={() =>
                  stage === 1 ||
                  (stage === 2 && newEmail.type === 1) ||
                  (buy && stage !== 4)
                    ? setStage(stage + 1)
                    : _createEmail()
                }
                icon
              >
                {loading ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : stage === 1 ||
                  (stage === 2 && newEmail.type === 1) ||
                  (buy && stage !== 4) ? (
                  'Próximo passo'
                ) : (
                  'Finalizar'
                )}
              </Button>
            )}
          </CreateButtons>
        </CreateWrapper>
      )}

      {stage === 'success' && <Success />}
    </>
  );
};

export default props => (
  <EmailCreateProvider>
    <EmailCreate {...props} />
  </EmailCreateProvider>
);
