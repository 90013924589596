import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import App from './App';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://d2922baaadca46cbbf2e574948013864@sentry.cloudez.io/4',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
    release: 'cloudez-app-release-3',
  });
}

ReactDOM.render(<App />, document.getElementById('root'));
