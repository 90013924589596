import React, { useMemo, useEffect, useState, useContext } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

import { Button } from '@cloudez/cloudez-design-system';
import Progress from 'components/Progress';
import { CreateWrapper, CreateHeader, CreateButtons } from 'components/Common';

import { CloudCreateContext, CloudCreateProvider } from './_context/state';

import Plan from './Plan';
import Payment from './Payment';

const CloudCreate = () => {
  const { plan, getPlans, getBasePlans } = useContext(CloudCreateContext);
  const [stage, setStage] = useState(1);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getPlans();
    getBasePlans();
    // eslint-disable-next-line
  }, []);

  const stageTitle = useMemo(() => {
    return stage === 1 ? 'Selecione um plano' : stage === 2 ? 'Pagamento' : '';
  }, [stage]);

  return (
    <CreateWrapper>
      <CreateHeader>
        <h1>{stageTitle}</h1>
        <Progress status={stage} n={2} />
      </CreateHeader>

      {stage === 1 && <Plan />}

      {stage === 2 && <Payment loading={loading} setLoading={setLoading} />}

      <CreateButtons>
        {stage >= 2 && (
          <Button
            outline
            onClick={() => setStage(stage - 1)}
            style={{
              marginTop: '20px',
            }}
          >
            Voltar
          </Button>
        )}

        {stage === 2 ? (
          <Button
            type="submit"
            form="infoFormPayment"
            disabled={loading}
            style={{
              marginTop: '20px',
            }}
            icon
          >
            {loading ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : stage < 3 ? (
              'Pagar'
            ) : (
              'Finalizar'
            )}
          </Button>
        ) : (
          <Button
            disabled={loading || !plan}
            style={{
              marginTop: '20px',
            }}
            onClick={() => {
              if (stage < 2) {
                setStage(stage + 1);
                const { fbq } = window;
                const { gtag } = window;

                if (fbq) {
                  fbq('track', 'InitiateCheckout');
                }

                if (gtag) {
                  gtag('event', 'track', {
                    event_category: 'InitiateCheckout',
                  });
                }
              }
            }}
            icon
          >
            {loading ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : stage < 3 ? (
              'Próximo Passo'
            ) : (
              'Finalizar'
            )}
          </Button>
        )}
      </CreateButtons>
    </CreateWrapper>
  );
};

export default props => (
  <CloudCreateProvider>
    <CloudCreate {...props} />
  </CloudCreateProvider>
);
