import styled, { css } from 'styled-components';
import { darken } from 'polished';

export const TicketHeader = styled.div`
  width: 100%;
  height: 61px;
  border-bottom: 1px solid ${props => props.theme.interface4};
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 768px) {
    height: auto;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 100%;

  padding: 15px;
`;

export const Target = styled.div`
  height: 100%;
  width: 10%;

  padding: 4px 20px;
  border-right: 1px solid ${props => props.theme.interface4};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  color: ${props => props.theme.interface1};
  font-size: 10px;

  a {
    text-decoration: none;
    text-transform: uppercase;
    transition: all 0.2s ease-in;
    color: ${props => props.theme.interface1};

    &:hover {
      background: ${props => darken(0.1, props.theme.brand_primary_color)};
    }

    cursor: pointer;
    padding: 3px 10px;
    border-radius: 5px;
    background: ${props => props.theme.brand_primary_color};
  }
`;

interface RedAlertProps {
  active?: boolean;
}
export const RedAlert = styled.div<RedAlertProps>`
  border-top-right-radius: 5px;
  width: 2%;
  height: 100%;
  background: ${props =>
    props.active ? props.theme.error : props.theme.interface1};
  border: 1px solid
    ${props => (props.active ? 'transparent' : props.theme.error)};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease-in-out;

  svg {
    transition: all 0.2s ease-in-out;
    font-size: 8px !important;

    color: ${props =>
      props.active ? props.theme.interface1 : props.theme.error} !important;
  }

  cursor: pointer;

  &:hover {
    background: ${props => props.theme.error};
    transition: all 0.2s ease-in-out;
    border: 1px solid ${props => props.theme.error};
    svg {
      color: ${props => props.theme.interface1} !important;
    }
  }
`;

interface WrapperProps {
  showInfo?: boolean;
}

export const Wrapper = styled.div<WrapperProps>`
  position: fixed;
  z-index: 999;
  bottom: 45px;
  transition: left 0.25s ease;
  left: ${props => (props.theme.sideMenuCollapsed ? '120px' : '230px')};

  background: ${props =>
    props.showInfo ? props.theme.interface1 : props.theme.brand_primary_color};
  width: ${props => (props.showInfo ? '700px' : '80px')};
  height: ${props => (props.showInfo ? 'auto' : '80px')};
  border-radius: ${props => (props.showInfo ? '5px' : '50%')};

  ${props =>
    props.showInfo
      ? css`
          border: 1px solid ${props => props.theme.brand_primary_color};
        `
      : css`
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
        `};

  svg {
    font-size: 40px;
    color: ${props => props.theme.interface1};
  }

  @media (max-width: 840px) {
    display: none;
  }
`;

export const Info = styled.div`
  height: 100%;
  width: 53%;

  padding: 11px 24px;
  border-right: 1px solid ${props => props.theme.interface4};

  display: flex;
  align-items: center;
  text-align: center;

  color: ${props => props.theme.interface5};

  svg {
    color: ${props => props.theme.interface5};
    font-size: 24px;
    cursor: pointer;
    margin-right: 10px;
    margin-top: 2px;
    align-self: center;
  }
`;

export const CHS = styled.div`
  height: 100%;
  width: 35%;
  padding: 11px 24px;

  display: flex;
  align-items: center;
  font-size: 14px;
  color: ${props => props.theme.interface5};
  font-weight: bold;
`;

export const Points = styled.div`
  height: 100%;
  width: 100%;
  padding: 11px;
  margin-left: 10px;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  font-size: 8px;
  color: ${props => props.theme.interface5};
  font-weight: bold;
  line-height: 2;
`;

export const Point = styled.span`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    display: flex;
    align-items: center;
    justify-content: space-between;

    div {
      margin-right: 5px;
    }
  }
`;

interface CHSColorProps {
  point: number;
  finance?: boolean;
}

export const CHSColor = styled.div<CHSColorProps>`
  height: 5px;
  width: 5px;

  border-radius: 50%;
  background: ${props =>
    props.finance
      ? props.point >= 4
        ? props.theme.success
        : props.point >= 2
        ? props.theme.alert
        : props.theme.error
      : props.point >= 2
      ? props.theme.success
      : props.point >= 1
      ? props.theme.alert
      : props.theme.error};
`;
