import {
  faAngleDown,
  faAngleUp,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Input, Button } from '@cloudez/cloudez-design-system';
import useDevice from 'hooks/device';
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import Swipe from 'react-easy-swipe';
import CurrencyFormat from 'react-currency-format';
import { validateCoupon } from 'services/cloud';
import { toast } from 'react-toastify';
import { CloudCreateContext } from '../_context/state';
import * as Styles from './styles';

interface IDetailsCardProps {
  setStage: Dispatch<SetStateAction<number>>;
  onToggle(open: boolean): Dispatch<SetStateAction<boolean>> | void;
}

const DetailsCard: React.FC<IDetailsCardProps> = ({ setStage, onToggle }) => {
  const {
    planType,
    plan,
    recurrency,
    coupon,
    setCoupon,
    discount,
    discountType,
    setCouponDiscount,
  } = useContext(CloudCreateContext);

  const headerRef = useRef(null);

  const getAnnual = useCallback<(number: number) => number>(
    value => (value ? value * 12 - value * 12 * 0.1 : 0),
    [],
  );

  const annualPrice = useMemo<number>(
    () =>
      getAnnual(
        planType === 'turbo'
          ? plan.turbo_price_total?.amount
          : plan.price_total?.amount,
      ),
    [plan, planType],
  );

  const { isMobile } = useDevice();

  const [mobileOpen, setMobileOpen] = useState(false);
  const [couponLoading, setCouponLoading] = useState(false);

  const [discountValue, setDiscountValue] = useState<number>();

  const totalPrice = useMemo(() => {
    let price =
      planType === 'turbo'
        ? plan.turbo_price_total?.amount
        : plan.price_total?.amount;
    let annualPrice;
    if (recurrency === 'annual') {
      price = getAnnual(price);
      annualPrice = price;
    }

    if (discount) {
      switch (discountType) {
        case 'rate':
          price *= 1 - discount;
          break;
        case 'credit':
          price -= discount;
          break;
        default:
          break;
      }
    }

    setDiscountValue(
      (annualPrice ||
        (planType === 'turbo'
          ? plan.turbo_price_total?.amount
          : plan.price_total?.amount)) - price,
    );

    return price;
  }, [planType, plan, discount, discountType]);

  const validateAndApplyCoupon = useCallback(async () => {
    if (!coupon) return;
    setCouponLoading(true);
    try {
      const {
        data: { coupon: _coupon },
      } = await validateCoupon(coupon);

      setCouponDiscount(
        _coupon.discount_rate > 0
          ? _coupon.discount_rate
          : _coupon.discount_credit.amount,
        _coupon.discount_rate > 0 ? 'rate' : 'credit',
      );

      setCouponLoading(false);
    } catch (e) {
      toast.error(e.response?.data?.error);
      setCouponLoading(false);
    }
  }, [coupon]);

  const typeName = {
    turbo: 'TURBO',
    email: 'EMAIL',
    basic: 'BASIC',
  }[planType];

  const onMobileOpen = useCallback(() => {
    if (!mobileOpen) {
      const { top } = headerRef.current.getBoundingClientRect();

      window.scrollTo({
        top: top + window.scrollY,
        left: 0,
        behavior: 'smooth',
      });
    }

    setTimeout(
      () => {
        setMobileOpen(!mobileOpen);
        if (isMobile && onToggle) {
          onToggle(!mobileOpen);
        }

        if (mobileOpen) {
          window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        }
      },
      mobileOpen ? 1 : 300,
    );
  }, [onToggle, mobileOpen, headerRef]);

  const handleSwipeUp = (position, event) => {
    event.preventDefault();
    onMobileOpen();
  };

  return (
    <>
      {!isMobile && <Styles.Title>Detalhes</Styles.Title>}
      <Styles.Card mobileOpen={mobileOpen}>
        {isMobile && (
          <div
            ref={headerRef}
            className="mobile-header"
            data-testid="mobile-header"
            onClick={() => onMobileOpen()}
          >
            <span>Detalhes da compra</span>
            <div>
              <span className="total-label">Total: </span>
              <CurrencyFormat
                value={
                  planType === 'turbo'
                    ? recurrency === 'annual'
                      ? getAnnual(plan.turbo_price_total?.amount)
                      : plan.turbo_price_total?.amount
                    : recurrency === 'annual'
                    ? getAnnual(plan.price_total?.amount)
                    : plan.price_total?.amount
                }
                displayType="text"
                className="total-value"
                fixedDecimalScale
                decimalScale={2}
                decimalSeparator=","
                thousandSeparator="."
                prefix={
                  (planType === 'turbo'
                    ? plan.turbo_price_total?.currency
                    : plan.price_total?.currency) === 'BRL'
                    ? 'R$ '
                    : '$'
                }
              />
              <FontAwesomeIcon icon={mobileOpen ? faAngleUp : faAngleDown} />
            </div>
          </div>
        )}
        {((isMobile && mobileOpen) || !isMobile) && (
          <>
            {' '}
            <div className="section">
              <h3>Plano</h3>
              <div className="name-mem">
                <span>{plan.name}</span>
                <span>{plan.cloud_size.memory} RAM</span>
              </div>
              <p className="tip">{plan.description}</p>
              <div className="stats">
                <div>
                  <h4>DISCO</h4>
                  <span>{plan.cloud_size.disk}</span>
                </div>
                <div>
                  <h4>PROCESSADOR</h4>
                  <span>
                    {plan.cloud_size.cores} CPU core
                    {plan.cloud_size.cores > 1 && 's'}
                  </span>
                </div>
              </div>
            </div>
            <div className="section">
              <h3>RECORRÊNCIA</h3>
              <div className="recurrency">
                <span data-testid="recurrency">
                  {recurrency === 'monthly' ? 'mensal' : 'anual'}
                </span>
                <a onClick={() => setStage(2)}>Alterar</a>
              </div>
            </div>
            <div className="section">
              <h3>TIPO</h3>
              <div className="type">
                <span data-testid="type">{typeName}</span>
                <CurrencyFormat
                  data-testid="totalValue"
                  value={
                    planType === 'turbo'
                      ? recurrency === 'annual'
                        ? getAnnual(plan.turbo_price_total?.amount)
                        : plan.turbo_price_total?.amount
                      : recurrency === 'annual'
                      ? getAnnual(plan.price_total?.amount)
                      : plan.price_total?.amount
                  }
                  displayType="text"
                  fixedDecimalScale
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  prefix={
                    (planType === 'turbo'
                      ? plan.turbo_price_total?.currency
                      : plan.price_total?.currency) === 'BRL'
                      ? 'R$ '
                      : '$'
                  }
                />
              </div>
            </div>
            <div className="section">
              <h3>CUPOM DESCONTO</h3>
              <div className="d-flex">
                <Input
                  onChange={e => setCoupon(e.target.value)}
                  value={coupon}
                  placeholder="Digite seu cupom aqui"
                  block
                />
                <Button
                  data-testid="validateCouponButton"
                  width="130px"
                  disabled={couponLoading}
                  onClick={validateAndApplyCoupon}
                  className="ml-1"
                >
                  {couponLoading ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    'Aplicar'
                  )}
                </Button>
              </div>
            </div>
            <div className="section">
              <h3>TOTAL DO PEDIDO</h3>
              <div className="total">
                <CurrencyFormat
                  data-testid="totalPrice"
                  value={totalPrice}
                  displayType="text"
                  fixedDecimalScale
                  decimalScale={2}
                  decimalSeparator=","
                  thousandSeparator="."
                  prefix={
                    (planType === 'turbo'
                      ? plan.turbo_price_total?.currency
                      : plan.price_total?.currency) === 'BRL'
                      ? 'R$ '
                      : '$'
                  }
                />
              </div>
              {recurrency === 'annual' && (
                <>
                  <div className="nodiscount">
                    <CurrencyFormat
                      data-testid="nodiscount"
                      value={
                        planType === 'turbo'
                          ? plan.turbo_price_total?.amount * 12
                          : plan.price_total?.amount * 12
                      }
                      displayType="text"
                      fixedDecimalScale
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      prefix={
                        (planType === 'turbo'
                          ? plan.turbo_price_total?.currency
                          : plan.price_total?.currency) === 'BRL'
                          ? 'R$ '
                          : '$'
                      }
                    />
                  </div>
                  <div className="discount">
                    Economize (Anual){' '}
                    <CurrencyFormat
                      data-testid="discount"
                      value={
                        planType === 'turbo'
                          ? plan.turbo_price_total?.amount * 12 - annualPrice
                          : plan.price_total?.amount * 12 - annualPrice
                      }
                      displayType="text"
                      fixedDecimalScale
                      decimalScale={2}
                      decimalSeparator=","
                      thousandSeparator="."
                      prefix={
                        (planType === 'turbo'
                          ? plan.turbo_price_total?.currency
                          : plan.price_total?.currency) === 'BRL'
                          ? 'R$ '
                          : '$'
                      }
                    />{' '}
                  </div>
                </>
              )}
              {discount && (
                <div className="discount">
                  Desconto Cupom{' '}
                  <CurrencyFormat
                    data-testid="coupon-discount"
                    value={discountValue}
                    displayType="text"
                    fixedDecimalScale
                    decimalScale={2}
                    decimalSeparator=","
                    thousandSeparator="."
                    prefix={
                      (planType === 'turbo'
                        ? plan.turbo_price_total?.currency
                        : plan.price_total?.currency) === 'BRL'
                        ? 'R$ '
                        : '$'
                    }
                  />{' '}
                </div>
              )}
            </div>
            {isMobile && (
              <Swipe onSwipeUp={handleSwipeUp}>
                <div className="swipe" data-testid="mobileSwipe">
                  <div className="line" />
                </div>
              </Swipe>
            )}
          </>
        )}
      </Styles.Card>
    </>
  );
};

export default DetailsCard;
