import React, { createContext, useReducer } from 'react';
import { NodeAppType, NodeAppValue } from 'types';
import { Website } from 'types/website';
import reducer from './reducer';
import {
  setLoading,
  updateWebsiteField,
  updateWebsiteValue,
  updateWebsite,
  createWebsite,
  updateDatabase,
  updateDatabaseField,
  updateDatabaseValue,
  setNotRecommended,
} from './actions';

export interface WebsiteCreateState {
  website:
    | {
        cloud: number | string;
        values: NodeAppValue[];
        type: number | string;
        user: number | string;
        disk_usage: number;
        certificate: any;
        use_cdn: string | boolean;
        name: string;
        domain: string;
        id: string | number;
      }
    | Website;
  database: {
    id: number | string;
    cloud: number | string;
    values: NodeAppValue[];
    websites: number[];
    type: NodeAppType;
    users: any[];
    admin: number;
  };
  loading: boolean;
  errors: any;
  notRecommended: {
    open: boolean;
    message: string;
  };

  setNotRecommended(value: any): void;
  updateWebsiteField(field: string, value: any): void;
  updateWebsiteValue(field: any): void;
  updateWebsite(website: any): void;
  updateDatabase(database: any): void;
  updateDatabaseValue(database: any): void;
  updateDatabaseField(field: string, value: any): void;
  createWebsite(
    website: any,
    db: any,
    noDb: boolean,
    buyDomain: boolean,
    isProvider?: boolean,
  ): Promise<void>;
  setLoading(value: boolean): void;
}

// initial state
const initialState = {
  website: {
    cloud: null,
    values: [],
    type: null,
    user: null,
    disk_usage: null,
    certificate: null,
    use_cdn: 'false',
    name: '',
  },
  database: {
    id: null,
    cloud: null,
    values: [],
    websites: [],
    type: null,
    users: [],
    admin: null,
  },
  loading: false,
  errors: null,
  notRecommended: {
    open: false,
    message: '',
  },
};

// context
export const WebsiteCreateContext = createContext<WebsiteCreateState>(
  initialState as WebsiteCreateState,
);

// provider
export const WebsiteCreateProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(
    reducer,
    initialState as WebsiteCreateState,
  );

  return (
    <WebsiteCreateContext.Provider
      value={{
        // state
        website: state.website,
        database: state.database,
        errors: state.errors,
        loading: state.loading,
        notRecommended: state.notRecommended,

        // actions
        setNotRecommended: value => setNotRecommended(dispatch, value),
        updateWebsiteField: (field, value) =>
          updateWebsiteField(state, dispatch, field, value),
        updateWebsiteValue: field => updateWebsiteValue(state, dispatch, field),
        updateWebsite: website => updateWebsite(dispatch, website),
        updateDatabase: database => updateDatabase(dispatch, database),
        updateDatabaseValue: database =>
          updateDatabaseValue(state, dispatch, database),
        updateDatabaseField: (field, value) =>
          updateDatabaseField(state, dispatch, field, value),
        createWebsite: async (website, db, noDb, buyDomain, isProvider) =>
          createWebsite(dispatch, website, db, noDb, buyDomain, isProvider),

        setLoading: value => setLoading(dispatch, value),
      }}
    >
      {children}
    </WebsiteCreateContext.Provider>
  );
};
