import React from 'react';
import { withTheme } from 'styled-components';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';

const Placeholder = props => {
  return (
    <SkeletonTheme
      {...props}
      color={props.theme.interface3}
      highlightColor={props.theme.interface2}
    >
      <Skeleton {...props} />
    </SkeletonTheme>
  );
};

export default withTheme(Placeholder);
