import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 25px;
  margin-bottom: 25px;
  width: 100%;
  height: auto;
`;

export const Card = styled.div`
  background: ${props => props.theme.interface1};

  width: 100%;
  min-height: 120px;

  border-radius: 5px;
  padding: 30px;
`;
