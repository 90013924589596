import styled from 'styled-components';

export const CloudInfo = styled.div`
  height: 100%;
  width: 100%;
  margin-top: -15px;
  line-height: 2.5;
  font-size: 12px;
  color: ${props => props.theme.interface5};

  p {
    span {
      color: ${props => props.theme.interface4};
    }
  }
`;
