import React from 'react';
import Slider from 'react-slick';

import { Arrows } from '@cloudez/cloudez-design-system';
import Placeholder from 'components/Placeholder';
import {
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import CurrencyFormat from 'react-currency-format';
import { Wrapper, PlanCard, Price } from './styles';

const Plan = ({ selectedPlan, setSelectedPlan, plans, basePlans }) => {
  function NextArrow(props) {
    const { onClick } = props;
    return <Arrows right="true" onClick={onClick} icon={faChevronRight} />;
  }

  function PrevArrow(props) {
    const { onClick } = props;

    return <Arrows left="true" onClick={onClick} icon={faChevronLeft} />;
  }

  const settings = {
    className: 'center',
    centerMode: true,
    infinite: false,
    focusOnSelect: true,
    centerPadding: '60px',
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const placeholders = [];

  for (let i = 1; i <= 5; i++) {
    placeholders.push(i);
  }

  return (
    <Wrapper>
      <Slider {...settings}>
        {basePlans
          ? plans?.map(p => {
              const [basePlan] = basePlans.filter(b => p.cloud_size === b.id);

              return (
                <div key={p.id}>
                  <PlanCard
                    selected={p.id === selectedPlan}
                    onClick={() => setSelectedPlan(p.id)}
                  >
                    <Price>
                      <h3>{p.name}</h3>
                      <span>
                        <CurrencyFormat
                          style={{ fontSize: '30px' }}
                          value={p.price_total?.amount}
                          displayType="text"
                          fixedDecimalScale
                          decimalScale={2}
                          decimalSeparator=","
                          thousandSeparator="."
                          prefix={
                            p.price_total?.currency === 'BRL' ? 'R$ ' : '$'
                          }
                        />
                        <p>mensal</p>
                      </span>
                    </Price>
                    {basePlan && (
                      <>
                        <span>{basePlan?.info['tip_pt-br']}</span>
                        <ul>
                          <li>{basePlan?.info.memory} RAM</li>
                          <li>{basePlan?.info.cores} CPU core</li>
                          <li>{basePlan?.info.disk}</li>
                        </ul>
                      </>
                    )}
                  </PlanCard>
                </div>
              );
            })
          : placeholders.map((p, i) => (
              <div key={i}>
                <PlanCard>
                  <Placeholder width={28} height={12} />
                  <Placeholder width={80} height={60} />
                  <div>
                    <Placeholder width={100} height={12} />
                    <Placeholder width={100} height={12} />
                    <Placeholder width={100} height={12} />
                  </div>
                  <div>
                    <span>
                      <Placeholder width={40} height={12} />
                    </span>
                    <span>
                      <Placeholder width={40} height={12} />
                    </span>
                    <span>
                      <Placeholder width={40} height={12} />
                    </span>
                  </div>
                </PlanCard>
              </div>
            ))}
      </Slider>
    </Wrapper>
  );
};

export default Plan;
