import React, { useState } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faSpinner } from '@fortawesome/free-solid-svg-icons';

import { Modal, Button } from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Footer,
} from 'components/ListingModal/styles';
import { ModalProps } from 'types';

const WebmailModal: React.FC<ModalProps> = ({ action, show, setShow }) => {
  const [loading, setLoading] = useState(false);

  return (
    <Modal show={show} setShow={setShow} noExit={loading}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faEnvelope} />
        </Icon>
        <Text>
          <Title>Webmail</Title>
          <Subtitle>Deseja ativar o webmail?</Subtitle>
        </Text>
      </Header>

      <Footer>
        <Button
          icon
          disabled={loading}
          onClick={async () => {
            setLoading(true);
            await action();
            setLoading(false);
            setShow();
          }}
        >
          {loading ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Ativar'}
        </Button>
      </Footer>
    </Modal>
  );
};

export default WebmailModal;
