import React, { useState } from 'react';
import { withTheme } from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faSpinner } from '@fortawesome/free-solid-svg-icons';

import { CustomInput } from 'components/NewComponents';
import { Button } from '@cloudez/cloudez-design-system';

import { toast } from 'react-toastify';
import {
  deleteCertificateService,
  createCertificateService,
  requeueCertificateService,
} from 'services/website';
import toastError from 'utils/toastError';
import {
  Header,
  Icon,
  Text,
  Title,
  Subtitle,
  Content,
  Footer,
} from '../styles';
import { HttpsText, HttpsButtonContainer } from './styles';

const HttpsModal = ({
  website,
  theme,
  setShow,
  action,
  type,
  loading,
  setLoading,
}) => {
  const [remove, setRemove] = useState('');
  // const [loading, setLoading] = useState(false);

  const removeCertificate = async () => {
    try {
      setLoading(true);
      await deleteCertificateService(website.certificate.id);
      await action('certificate', null, website.id);
      setLoading(false);
      setShow(false);
    } catch (e) {
      toastError(e);
    }
  };

  const requeueCertificate = async () => {
    try {
      setLoading(true);
      await requeueCertificateService(website.certificate.id);
      await action('certificate', null, website.id);
      setLoading(false);
      setShow(false);
    } catch (e) {
      toastError(e);
    }
  };

  const createCertificate = async () => {
    try {
      setLoading(true);

      const { data } = await createCertificateService({
        provider: 4,
        website: website.id,
      });

      await action(
        'certificate',
        { id: data.id, status: data.status, is_active: data.is_active },
        website.id,
      );

      setLoading(false);
      setShow(false);
    } catch (e) {
      toast.error(e?.response?.data[Object.keys(e?.response?.data)[0]]);
    }
  };

  return (
    <>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faLock} />
        </Icon>
        <Text>
          <Title>Certificado</Title>
          <Subtitle>
            {(() => {
              switch (type) {
                case 'no-https':
                  return 'O seu site não está hospedado conosco.';
                case 'https-loading':
                  return 'Aguarde o processamento';
                case 'https-remove':
                  return 'Você gostaria de remover o certificado deste website? ';
                default:
                  return 'Deixe seu website mais seguro!';
              }
            })()}
          </Subtitle>
        </Text>
      </Header>

      <Content>
        {(() => {
          switch (type) {
            case 'no-https':
              return (
                <HttpsText>
                  Só é possível pedir um certificado se o seu site estiver
                  hospedado conosco.
                </HttpsText>
              );
            case 'https-loading':
              return (
                <HttpsText>
                  Seu certificado ainda está sendo processado...
                  <br />
                  Gostaria de solicitar novamente?
                </HttpsText>
              );
            case 'https-remove':
              return (
                <>
                  <HttpsText style={{ marginBottom: '20px' }}>
                    Você gostaria de remover o certificado SSL (https) deste
                    website?
                  </HttpsText>

                  <HttpsText style={{ marginBottom: '20px' }}>
                    Por favor, escreva{' '}
                    <span
                      style={{
                        textTransform: 'uppercase',
                        fontWeight: 'bold',
                        color: theme.error,
                      }}
                    >
                      REMOVER
                    </span>
                    , para confirmar a desativação do certificado SSL.
                  </HttpsText>
                  <CustomInput
                    name="remove"
                    width="100%"
                    placeholder="REMOVER"
                    value={remove}
                    onChange={e => {
                      setRemove(e.target.value);
                    }}
                  />
                </>
              );
            default:
              return (
                <HttpsText>
                  O certificado gratuito <i>Let's Encrypt</i> será gerado e
                  configurado automáticamente em seu website. <br />
                  Ao final do processo, você visualizará o ícone ativado.
                </HttpsText>
              );
          }
        })()}
      </Content>
      <Footer
        style={
          type === 'https-loading'
            ? { justifyContent: 'center' }
            : { justifyContent: 'flex-end' }
        }
      >
        {(() => {
          switch (type) {
            case 'no-https':
              return (
                <Button width="149px" icon onClick={() => setShow(false)}>
                  {loading ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    'Fechar'
                  )}
                </Button>
              );
            case 'https-loading':
              return (
                <HttpsButtonContainer>
                  <Button width="180px" icon onClick={() => setShow(false)}>
                    {loading ? (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                      'Não'
                    )}
                  </Button>
                  <Button width="180px" icon onClick={requeueCertificate}>
                    {loading ? (
                      <FontAwesomeIcon icon={faSpinner} spin />
                    ) : (
                      'Sim'
                    )}
                  </Button>
                </HttpsButtonContainer>
              );
            case 'https-remove':
              return (
                <Button
                  width="149px"
                  icon
                  disabled={remove !== 'REMOVER'}
                  error
                  onClick={removeCertificate}
                >
                  {loading ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    'Remover'
                  )}
                </Button>
              );
            default:
              return (
                <Button width="149px" icon onClick={createCertificate}>
                  {loading ? (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  ) : (
                    'Ativar'
                  )}
                </Button>
              );
          }
        })()}
      </Footer>
    </>
  );
};

export default withTheme(HttpsModal);
