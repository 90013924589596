import React, { useContext, useState } from 'react';

import {
  Footer,
  Icon,
  Text,
  Title,
  Header,
} from 'components/ListingModal/styles';

import { Modal, Button } from '@cloudez/cloudez-design-system';

import { toast } from 'react-toastify';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faExclamationTriangle,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { Content } from './styles';
import { SecurityCompanyContext } from '../_context/state';

interface FinishSectionModal {
  show: boolean;
  setShow: (value: boolean) => void;
  id: number | string;
  token2FA: string;
  getStatus: any;
}

const FinishSectionModal: React.FC<FinishSectionModal> = ({
  show,
  setShow,
  id,
  token2FA,
  getStatus,
}) => {
  const [loading, setLoading] = useState(false);

  const { deleteDevice, deleteAllDevices } = useContext(SecurityCompanyContext);

  const finishAllDevices = async () => {
    try {
      setLoading(true);
      await deleteAllDevices({ 'X-token2fa': token2FA });
      setShow(false);
      getStatus();
      setLoading(false);
      toast.success('Dispositivos encerrados com sucesso!');
    } catch (err) {
      toast.error('Erro ao encerrar dispositivos!');
      console.log(err);
      setLoading(false);
    }
  };

  const finishDeviceSelected = async () => {
    try {
      setLoading(true);
      await deleteDevice(id);
      setShow(false);
      getStatus();
      setLoading(false);
      toast.success('Dispositivo encerrado com sucesso!');
    } catch (err) {
      console.log(err);
      toast.error('Erro ao encerrar dispositivo!');
      setLoading(false);
    }
  };

  return (
    <Modal width="497px" show={show} setShow={setShow}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faExclamationTriangle} />
        </Icon>
        <Text>
          <Title>{id ? 'Encerrar Dispositivo' : 'Encerrar Dispositivos'}</Title>
        </Text>
      </Header>

      <Content>
        {id ? (
          <span>
            <b>Atenção!</b> Ao encerrar esse dispositivo, ao realizar o login
            novamente deverá informar o código de 6 dígitos do seu aplicativo de
            segurança.
          </span>
        ) : (
          <span>
            <b>Atenção!</b> Ao encerrar todos os dispositivos, os dispostivos
            serão removidos e todos ao acessarem novamente, deverão inserir o
            código de 6 dígitos do seu aplicativo de segurança. <br />
            <br /> O seu <b>dispositivo ativo</b> não será afetado.
          </span>
        )}
      </Content>
      <Footer hasLink>
        <Button onClick={() => setShow(false)} outline>
          Cancelar
        </Button>
        <Button
          onClick={id ? finishDeviceSelected : finishAllDevices}
          type="button"
          disabled={loading}
        >
          {loading ? (
            <FontAwesomeIcon
              style={{ marginLeft: '0' }}
              icon={faSpinner}
              spin
            />
          ) : (
            'Encerrar'
          )}
        </Button>
      </Footer>
    </Modal>
  );
};

export default FinishSectionModal;
