import history from 'services/history';
import {
  deleteAllDeviceService,
  deleteDeviceService,
  getOTPStatusService,
} from 'services/user';

export const setLoading = (dispatch, value) => {
  dispatch({
    type: 'SET_LOADING',
    payload: value,
  });
};

export const getOtpStatus = async (dispatch, headers, page?) => {
  setLoading(dispatch, true);
  try {
    const { data } = await getOTPStatusService({ page }, headers);

    dispatch({
      type: 'GET_OTP_STATUS',
      payload: data,
    });
    setLoading(dispatch, false);
  } catch (e) {
    setLoading(dispatch, false);
    if (e?.response?.status === 404) {
      history.push('/404');
    }
  }
};

export const deleteDevice = async (dispatch, id) => {
  try {
    const { data } = await deleteDeviceService(id);

    dispatch({
      type: 'DELETE_DEVICE',
      payload: data,
    });
  } catch (e) {
    if (e?.response?.status === 404) {
      history.push('/404');
    }
  }
};

export const deleteAllDevices = async (dispatch, token) => {
  try {
    const { data } = await deleteAllDeviceService(token);

    dispatch({
      type: 'DELETE_ALL_DEVICE',
      payload: data,
    });
    history.push('/account');
  } catch (e) {
    if (e?.response?.status === 404) {
      history.push('/404');
    }
  }
};

export const setCode = (dispatch, value) => {
  dispatch({
    type: 'SET_CODE',
    payload: value,
  });
};
