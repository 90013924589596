import styled from 'styled-components';
import { Input } from '@cloudez/cloudez-design-system';

export const SquareInput = styled(Input)`
  width: 50px;
  height: 50px;

  font-size: 20px;
  font-weight: bold;
  text-align: center;
`;

export const Inputs = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Timer = styled.div`
  margin: auto;
  text-align: center;

  margin-bottom: 50px;

  p.label {
    text-decoration: underline;
    display: inline;
  }

  p.time-left {
    text-decoration: underline;
    display: inline;
    color: ${props => props.theme.brand_primary_color};
  }
`;

export const Content = styled.div`
  height: 100%;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  margin: auto;

  form {
    width: 100%;
    padding: 15px 0px;

    span {
      color: ${props => props.theme.error};
      font-size: 12px;
    }

    p {
      font-size: 12px;
      color: #979797;

      a {
        color: ${props => props.theme.label};
        margin-top: 15px;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  @media (max-width: 1000px) {
    padding: 0;
  }
`;

export const Wrapper = styled.div`
  /* margin-top: 30px; */
  height: 100%;
  width: 100%;

  h1 {
    font-weight: 900;
    font-size: 35px;
    color: ${props =>
      props.theme.darkMode ? props.theme.interface4 : '#333333'};
    text-align: center;
  }

  h4 {
    font-size: 16px;
    margin-top: 12px;
    margin-bottom: 20px;
    font-weight: 400;

    color: #64728c;

    text-align: center;
  }
`;
