import React, { useEffect, useState, useContext, useRef, useMemo } from 'react';
import Swipe from 'react-easy-swipe';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMicrochip,
  faHdd,
  faMemory,
  faNetworkWired,
  faChevronRight,
  faChevronLeft,
  faEdit,
  faCheck,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import * as moment from 'moment';
import history from 'services/history';

import { Row, Col, Alert } from 'react-bootstrap';

import ApplicationIcon from 'components/ApplicationIcon';
import { Button, Select, Arrows } from '@cloudez/cloudez-design-system';
import {
  Header,
  Title,
  Tabs,
  Tab,
  TabContent,
  Card,
  CardHeader,
  Informations,
  Information,
} from 'components/Details';

import Ticket from 'components/Ticket';

import usePersistedState from 'hooks/usePersistedState';
import { toast } from 'react-toastify';
import { Breadcrumbs, Breadcrumb } from 'components/Common';
import { Link } from 'react-router-dom';
import { ThemeContext } from 'styled-components';
import { darken, transparentize } from 'polished';
import Chart from 'chart.js';
import {
  getCloudsService,
  getAddonsService,
  getCloudUsersService,
  createCloudChangeService,
  putCloudActionService,
} from 'services/cloud';
import { answerSurveyService, getSurveySlugService } from 'services/survey';

import { useDocTitle } from 'hooks/docTitle';
import { useAuth } from 'hooks/auth';

import ChangePlanModal from './ChangePlanModal';
import ChurnModal from './ChurnModal';
import { CloudDetailProvider, CloudDetailContext } from './_context/state';
import ChangeNickname from './ChangeNickname';
import Users from './Users';
import Addons from './Addons';
import Placeholder from './Placeholder';
import { CloudInfo } from './styles';
import RemoveModal from './RemoveModal';

const CloudDetail = ({ match }) => {
  const { user } = useAuth();
  const { cloud, getCloud, deleteCloud, ticket, clouds } =
    useContext(CloudDetailContext);
  const theme = useContext(ThemeContext);
  const memRef = useRef();
  const cpuRef = useRef();
  const diskRef = useRef();

  const [addons, setAddons] = useState(null);
  const [users, setUsers] = useState(null);
  const [tab, setTab] = usePersistedState('@cloudez-app-v2/cloudTab', 1);
  const [action, setAction] = useState(0);
  const [removeModal, setRemoveModal] = useState(false);
  const [canAction, setCanAction] = useState(true);
  const [changePlanModal, setChangePlanModal] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [churnModal, setChurnModal] = useState(false);
  const [survey, setSurvey] = useState(null);
  const [addonLoading, setAddonLoading] = useState(true);
  const [userLoading, setUserLoading] = useState(true);
  const [partnerCanChurn] = useState(
    user.is_company_owner && user.owned_companies.includes(theme.code),
  );
  const [configrCanChurn] = useState(
    user.company_slug === 'configr' && theme.slug === 'configr',
  );

  const [changeNicknameModal, setChangeNicknameModal] = useState(false);
  console.log('CloudDetail');
  const cloudInfo = cloud?.status_timeline[cloud.status_timeline.length - 1];
  switch (cloud?.status_listing) {
    case 'id':
      console.log('Pagamento Pendente');
      break;
    case 'ia':
      console.log('Pagamento Atrasado');
      break;
    case 'ub':
      console.log('Usuário Bloqueado');
      break;
    default:
      break;
  }
  useEffect(() => {
    if (cloudInfo) {
      const memChartRef = memRef.current.getContext('2d');
      const cpuChartRef = cpuRef.current.getContext('2d');
      const diskChartRef = diskRef.current.getContext('2d');

      const dates = cloud.status_timeline.map(s => {
        const d = moment(s.datetime);
        return d.format('HH:MM:ss');
      });

      const memData = cloud.status_timeline.map((s, i) => ({
        x: (100 / cloud.status_timeline.length) * i,
        y: Number(s.mem),
      }));

      const cpuData = cloud.status_timeline.map((s, i) => ({
        x: (100 / cloud.status_timeline.length) * i,
        y: Number(s.cpu),
      }));

      const gradientFillMem = memChartRef.createLinearGradient(0, 0, 0, 125);

      Chart.pluginService.register({
        beforeDraw(chart) {
          if (chart.config.options.elements.center) {
            const { ctx } = chart.chart;
            const centerConfig = chart.config.options.elements.center;
            const txt = centerConfig.text;
            ctx.font = '30px Lato';
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillStyle = theme.interface4;
            const centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
            const centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;

            ctx.fillText(txt, centerX, centerY);
          }
        },
      });

      gradientFillMem.addColorStop(
        0,
        memData[memData.length - 1] && memData[memData.length - 1].y <= 50
          ? theme.success
          : memData[memData.length - 1] &&
            memData[memData.length - 1].y > 50 &&
            memData[memData.length - 1] &&
            memData[memData.length - 1].y <= 65
          ? theme.favorite
          : memData[memData.length - 1] &&
            memData[memData.length - 1].y > 65 &&
            memData[memData.length - 1] &&
            memData[memData.length - 1].y <= 80
          ? theme.alert
          : memData[memData.length - 1] && memData[memData.length - 1].y > 80
          ? theme.error
          : '#fff',
      );

      gradientFillMem.addColorStop(
        0.5,
        memData[memData.length - 1] && memData[memData.length - 1].y <= 50
          ? transparentize(0.3, theme.success)
          : memData[memData.length - 1] &&
            memData[memData.length - 1].y > 50 &&
            memData[memData.length - 1] &&
            memData[memData.length - 1].y <= 65
          ? transparentize(0.3, theme.favorite)
          : memData[memData.length - 1] &&
            memData[memData.length - 1].y > 65 &&
            memData[memData.length - 1] &&
            memData[memData.length - 1].y <= 80
          ? transparentize(0.3, theme.alert)
          : memData[memData.length - 1] && memData[memData.length - 1].y > 80
          ? transparentize(0.3, theme.error)
          : '#fff',
      );

      gradientFillMem.addColorStop(
        1,
        memData[memData.length - 1] && memData[memData.length - 1].y <= 50
          ? transparentize(0.9, theme.success)
          : memData[memData.length - 1] &&
            memData[memData.length - 1].y > 50 &&
            memData[memData.length - 1] &&
            memData[memData.length - 1].y <= 65
          ? transparentize(0.9, theme.favorite)
          : memData[memData.length - 1] &&
            memData[memData.length - 1].y > 65 &&
            memData[memData.length - 1] &&
            memData[memData.length - 1].y <= 80
          ? transparentize(0.9, theme.alert)
          : memData[memData.length - 1] && memData[memData.length - 1].y > 80
          ? transparentize(0.9, theme.error)
          : '#fff',
      );

      const gradientFillCpu = cpuChartRef.createLinearGradient(0, 0, 0, 125);

      gradientFillCpu.addColorStop(
        0,
        cpuData[cpuData.length - 1] && cpuData[cpuData.length - 1].y <= 50
          ? theme.success
          : cpuData[cpuData.length - 1] &&
            cpuData[cpuData.length - 1].y > 50 &&
            cpuData[cpuData.length - 1] &&
            cpuData[cpuData.length - 1].y <= 65
          ? theme.favorite
          : cpuData[cpuData.length - 1] &&
            cpuData[cpuData.length - 1].y > 65 &&
            cpuData[cpuData.length - 1] &&
            cpuData[cpuData.length - 1].y <= 80
          ? theme.alert
          : cpuData[cpuData.length - 1] && cpuData[cpuData.length - 1].y > 80
          ? theme.error
          : '#fff',
      );

      gradientFillCpu.addColorStop(
        0.5,
        cpuData[cpuData.length - 1] && cpuData[cpuData.length - 1].y <= 50
          ? transparentize(0.3, theme.success)
          : cpuData[cpuData.length - 1] &&
            cpuData[cpuData.length - 1].y > 50 &&
            cpuData[cpuData.length - 1] &&
            cpuData[cpuData.length - 1].y <= 65
          ? transparentize(0.3, theme.favorite)
          : cpuData[cpuData.length - 1] &&
            cpuData[cpuData.length - 1].y > 65 &&
            cpuData[cpuData.length - 1] &&
            cpuData[cpuData.length - 1].y <= 80
          ? transparentize(0.3, theme.alert)
          : cpuData[cpuData.length - 1] && cpuData[cpuData.length - 1].y > 80
          ? transparentize(0.3, theme.error)
          : '#fff',
      );

      gradientFillCpu.addColorStop(
        1,
        cpuData[cpuData.length - 1] && cpuData[cpuData.length - 1].y <= 50
          ? transparentize(0.9, theme.success)
          : cpuData[cpuData.length - 1] &&
            cpuData[cpuData.length - 1].y > 50 &&
            cpuData[cpuData.length - 1] &&
            cpuData[cpuData.length - 1].y <= 65
          ? transparentize(0.9, theme.favorite)
          : cpuData[cpuData.length - 1] &&
            cpuData[cpuData.length - 1].y > 65 &&
            cpuData[cpuData.length - 1] &&
            cpuData[cpuData.length - 1].y <= 80
          ? transparentize(0.9, theme.alert)
          : cpuData[cpuData.length - 1] && cpuData[cpuData.length - 1].y > 80
          ? transparentize(0.9, theme.error)
          : '#fff',
      );

      new Chart(cpuChartRef, {
        type: 'line',
        data: {
          labels: dates,
          datasets: [
            {
              label: 'CPU',
              data: cpuData,
              fill: 'origin',
              backgroundColor: gradientFillCpu,
              borderColor:
                cpuData[cpuData.length - 1] &&
                cpuData[cpuData.length - 1].y <= 50
                  ? darken(0.2, theme.success)
                  : cpuData[cpuData.length - 1] &&
                    cpuData[cpuData.length - 1].y > 50 &&
                    cpuData[cpuData.length - 1] &&
                    cpuData[cpuData.length - 1].y <= 65
                  ? darken(0.2, theme.favorite)
                  : cpuData[cpuData.length - 1] &&
                    cpuData[cpuData.length - 1].y > 65 &&
                    cpuData[cpuData.length - 1] &&
                    cpuData[cpuData.length - 1].y <= 80
                  ? darken(0.2, theme.alert)
                  : cpuData[cpuData.length - 1] &&
                    cpuData[cpuData.length - 1].y > 80 &&
                    cpuData[cpuData.length - 1] &&
                    cpuData[cpuData.length - 1].y <= 100
                  ? darken(0.2, theme.error)
                  : null,
              borderWidth: 2.5,
              lineTension: 0.3,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                display: false,
              },
            ],
            yAxes: [
              {
                display: false,
                ticks: {
                  min: 0,
                  max: 100,
                  stepSize: 10,
                },
              },
            ],
          },
          elements: {
            point: {
              radius: 0,
            },
          },
          legend: {
            display: false,
          },
        },
      });

      new Chart(memChartRef, {
        type: 'line',
        data: {
          labels: dates,
          datasets: [
            {
              label: 'Memória',
              data: memData,
              backgroundColor: gradientFillMem,
              borderColor:
                memData[memData.length - 1] &&
                memData[memData.length - 1].y <= 50
                  ? darken(0.2, theme.success)
                  : memData[memData.length - 1] &&
                    memData[memData.length - 1].y > 50 &&
                    memData[memData.length - 1] &&
                    memData[memData.length - 1].y <= 80
                  ? darken(0.2, theme.favorite)
                  : memData[memData.length - 1] &&
                    memData[memData.length - 1].y > 65 &&
                    memData[memData.length - 1] &&
                    memData[memData.length - 1].y <= 80
                  ? darken(0.2, theme.alert)
                  : memData[memData.length - 1] &&
                    memData[memData.length - 1].y > 80 &&
                    memData[memData.length - 1] &&
                    memData[memData.length - 1].y <= 100
                  ? darken(0.2, theme.error)
                  : null,
              borderWidth: 2,
              lineTension: 0.3,
            },
          ],
        },
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                display: false,
              },
            ],
            yAxes: [
              {
                display: false,
                ticks: {
                  min: 0,
                  max: 100,
                  stepSize: 10,
                },
              },
            ],
          },
          elements: {
            point: {
              radius: 0,
            },
          },
          legend: {
            display: false,
          },
        },
      });

      new Chart(diskChartRef, {
        type: 'doughnut',
        data: {
          labels: ['Disco'],
          datasets: [
            {
              label: 'Disco',
              data: [
                cloudInfo && cloudInfo.disk,
                cloudInfo && 100 - cloudInfo.disk,
              ],
              backgroundColor: [
                cloudInfo && cloudInfo.disk <= 50
                  ? theme.success
                  : cloudInfo && cloudInfo.disk > 50 && cloudInfo.disk <= 65
                  ? theme.favorite
                  : cloudInfo && cloudInfo.disk > 65 && cloudInfo.disk <= 80
                  ? theme.alert
                  : cloudInfo && cloudInfo.disk > 80 && cloudInfo.disk <= 100
                  ? theme.error
                  : null,
                theme.interface3,
              ],
              borderWidth: [0, 0],
            },
          ],
        },
        options: {
          maintainAspectRatio: false,
          elements: {
            center: {
              text: `${cloudInfo && Math.floor(cloudInfo.disk)}%`,
            },
          },
          cutoutPercentage: 90,
          legend: {
            display: false,
          },
        },
      });
    }
    // eslint-disable-next-line
  }, [cloud, cloudInfo]);

  useEffect(() => {
    const { id } = match.params;
    getCloud(id);

    async function getSurvey() {
      let surveySlug;
      if (configrCanChurn) surveySlug = 'churn-reason';
      else if (partnerCanChurn) surveySlug = 'partners-churn';

      try {
        const { data } = await getSurveySlugService(surveySlug);
        setSurvey(data);
      } catch (e) {
        console.log(e);
      }
    }

    if (configrCanChurn || partnerCanChurn) getSurvey();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function getAddons() {
      setAddonLoading(true);
      try {
        const { data } = await getAddonsService({
          cloud: cloud.id,
          name: '',
        });

        setAddonLoading(false);
        setAddons(data);
      } catch (e) {
        setAddonLoading(false);
        console.log(e);
      }
    }

    async function getUsers() {
      try {
        setUserLoading(true);
        const { data } = await getCloudUsersService({
          cloud: cloud.id,
          name: '',
          username: '',
        });
        setUsers(data);
        setUserLoading(false);
      } catch (e) {
        setUserLoading(false);
        console.log(e);
      }
    }

    if (cloud && !users && !addons) {
      getAddons();
      getUsers();
    }
    // eslint-disable-next-line
  }, [cloud]);

  const _deleteCloud = async () => {
    await deleteCloud(cloud.id);
  };

  const deleteCloudHandle = async () => {
    try {
      setRemoveLoading(true);
      const { data } = await getCloudsService();
      if ((configrCanChurn || partnerCanChurn) && data.count <= 1) {
        setRemoveLoading(false);
        setChurnModal(true);
      } else {
        setRemoveLoading(false);
        setRemoveModal(true);
      }
    } catch (e) {
      setRemoveLoading(false);
    }
  };

  const cloudAction = async () => {
    try {
      const payload = {
        action,
      };

      await putCloudActionService(cloud.id, payload);

      toast.success('A ação está sendo aplicada.');
      setCanAction(false);
    } catch (e) {}
  };

  const answerSurvey = async payload => {
    const data = {
      slug: survey.slug,
      answers: [],
    };

    for (const item in payload) {
      data.answers = [
        ...data.answers,
        { question_id: item, answer: payload[item] },
      ];
    }

    try {
      await answerSurveyService(data);

      if (partnerCanChurn) {
        history.push('/suporte');
      } else if (configrCanChurn) {
        _deleteCloud();
      }
    } catch (e) {
      console.log(e);
    }
  };

  useDocTitle(
    cloud ? `${cloud?.nickname || cloud?.name} — Cloud` : 'Clouds — Cloudez',
  );

  const icon = useMemo(() => {
    if (cloud) {
      if (
        cloud.cloud_provider.slug === 'partner' ||
        cloud.cloud_provider.slug === 'partner-g2' ||
        cloud.cloud_provider.slug.includes('partner-g3') ||
        cloud.cloud_provider.slug.includes('partner-g4')
      ) {
        return 'cloudez-footer';
      }

      if (
        cloud.cloud_provider.slug === 'configr-g3' ||
        cloud.cloud_provider.slug === 'configr-g4' ||
        cloud.cloud_provider.slug === 'configr-g5' ||
        cloud.cloud_provider.slug.includes('dpg-custom')
      ) {
        return 'configr';
      }

      return cloud.cloud_provider.slug;
    }
  }, [cloud]);

  return cloud ? (
    <>
      {churnModal && (
        <ChurnModal
          survey={survey}
          action={answerSurvey}
          show={churnModal}
          setShow={() => setChurnModal(false)}
          partnerCanChurn={partnerCanChurn}
          configrCanChurn={configrCanChurn}
        />
      )}
      {removeModal && (
        <RemoveModal
          action={_deleteCloud}
          show={removeModal}
          setShow={() => setRemoveModal(false)}
        />
      )}
      {changeNicknameModal && (
        <ChangeNickname
          show={changeNicknameModal}
          setShow={() => setChangeNicknameModal(false)}
        />
      )}
      {changePlanModal && (
        <ChangePlanModal
          show={changePlanModal}
          setShow={setChangePlanModal}
          cloud={cloud}
        />
      )}
      <Header>
        <Row>
          <Col md="10" sm="9">
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <ApplicationIcon width="64px" height="64px" icon={icon} />
              <Title>
                <Breadcrumbs>
                  <Breadcrumb>
                    <Link to="/dashboard">Home</Link>
                  </Breadcrumb>
                  <FontAwesomeIcon icon={faChevronRight} />
                  <Breadcrumb>
                    <Link to="/clouds">Clouds</Link>
                  </Breadcrumb>
                </Breadcrumbs>
                <h3>
                  {cloud.nickname || cloud.name}
                  <FontAwesomeIcon
                    style={{
                      marginLeft: '10px',
                      cursor: 'pointer',
                      color: theme.brand_primary_color,
                    }}
                    icon={faEdit}
                    onClick={() => setChangeNicknameModal(true)}
                  />
                </h3>
                {cloud.nickname && <p>Cloud: {cloud.name}</p>}
              </Title>
            </div>
          </Col>
          <Col
            className="d-none d-sm-flex d-md-flex d-xl-flex"
            sm="3"
            md="2"
            style={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Button
              icon={removeLoading}
              disabled={removeLoading}
              block
              outline
              error
              remove
              onClick={deleteCloudHandle}
            >
              {removeLoading ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                <>Excluir cloud</>
              )}
            </Button>
          </Col>
        </Row>
      </Header>

      <Row
        style={{
          marginBottom: `${15}px`,
        }}
      >
        <Col xl="7">
          <Row style={{ marginBottom: `${30}px` }}>
            <Col xl="12">
              <Card>
                <CardHeader>Informações</CardHeader>
                <Row>
                  <Col xl="7">
                    <Informations>
                      <Information>
                        <FontAwesomeIcon icon={faMicrochip} />
                        <span
                          style={{
                            fontSize: `${12}px`,
                            marginTop: `${10}px`,
                          }}
                        >
                          CPU
                        </span>
                        <span
                          style={{
                            fontSize: `${16}px`,
                            fontWeight: 'bold',
                          }}
                        >
                          {cloud.cloud_size.cores}
                          vCPU
                        </span>
                      </Information>
                      <Information>
                        <FontAwesomeIcon icon={faHdd} />
                        <span
                          style={{
                            fontSize: `${12}px`,
                            marginTop: `${10}px`,
                          }}
                        >
                          Disco
                        </span>
                        <span
                          style={{
                            fontSize: `${16}px`,
                            fontWeight: 'bold',
                          }}
                        >
                          {cloud.cloud_size.disk}
                        </span>
                      </Information>
                      <Information>
                        <FontAwesomeIcon icon={faMemory} />
                        <span
                          style={{
                            fontSize: `${12}px`,
                            marginTop: `${10}px`,
                          }}
                        >
                          Memória
                        </span>
                        <span
                          style={{
                            fontSize: `${16}px`,
                            fontWeight: 'bold',
                          }}
                        >
                          {cloud.cloud_size.memory}
                        </span>
                      </Information>
                      <Information>
                        <FontAwesomeIcon icon={faNetworkWired} />
                        <span
                          style={{
                            fontSize: `${12}px`,
                            marginTop: `${10}px`,
                          }}
                        >
                          Banda
                        </span>
                        <span
                          style={{
                            fontSize: `${16}px`,
                            fontWeight: 'bold',
                          }}
                        >
                          {cloud.cloud_size.transfer}
                        </span>
                      </Information>
                    </Informations>
                  </Col>
                  <Col>
                    <CloudInfo>
                      <p
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <span
                          style={{
                            marginRight: '2px',
                          }}
                        >
                          Tipo:
                        </span>
                        {cloud.cloud_size.name}
                        {cloud.is_paying && (
                          <Button
                            size="mn"
                            onClick={() => setChangePlanModal(true)}
                            outline
                            style={{
                              marginLeft: '6px',
                            }}
                          >
                            Alterar
                          </Button>
                        )}
                      </p>
                      <p>
                        <span>Sistema:</span> {cloud.cloud_distribution.name}
                      </p>
                      <p>
                        <span>IPv4:</span> {cloud.ipaddr}
                      </p>
                      {cloud.ipaddrv6 && (
                        <p>
                          <span>IPv6:</span> {cloud.ipaddrv6}
                        </p>
                      )}
                      <p>
                        <span>Hostname:</span> {`${cloud.name}.${cloud.domain}`}
                      </p>
                      <p>
                        <span>Ação: </span>
                        <Select
                          style={{
                            fontSize: '12px',
                            marginLeft: '2px',
                            marginRight: '6px',
                          }}
                          height="26px"
                          width="140px"
                          value={action}
                          onChange={e => setAction(e.target.value)}
                        >
                          <option disabled value="0">
                            Escolha uma ação:
                          </option>
                          <option value="restart">Reiniciar cloud</option>
                          <option value="apply">Aplicar configurações</option>
                          <option value="update">Atualizar pacotes</option>
                        </Select>
                        <FontAwesomeIcon
                          onClick={canAction && cloudAction}
                          icon={faCheck}
                          style={{
                            cursor: 'pointer',
                          }}
                          size="1x"
                          color={theme.brand_primary_color}
                        />
                      </p>
                    </CloudInfo>
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Row>
            <Col xl="4">
              <Card>
                <CardHeader>CPU</CardHeader>
                {cloudInfo ? (
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      margin: 'auto',
                      position: 'relative',
                    }}
                  >
                    <canvas ref={cpuRef} />
                    <span
                      style={{
                        position: 'absolute',
                        width: '100%',
                        color: theme.interface5,
                        textAlign: 'center',
                        top: 'calc(50% - 25px)',
                        fontSize: '20px',
                      }}
                    >
                      {cloudInfo.cpu}%
                    </span>
                  </div>
                ) : (
                  <p
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      color: theme.interface4,
                      fontSize: '12px',
                      marginTop: '12px',
                    }}
                  >
                    O cloud está em sincronização
                  </p>
                )}
              </Card>
            </Col>
            <Col xl="4">
              <Card>
                <CardHeader>Memória</CardHeader>
                {cloudInfo ? (
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      margin: 'auto',
                      position: 'relative',
                    }}
                  >
                    <canvas ref={memRef} />
                    <span
                      style={{
                        position: 'absolute',
                        width: '100%',
                        color: theme.interface5,
                        textAlign: 'center',
                        top: 'calc(50% - 25px)',
                        fontSize: '20px',
                      }}
                    >
                      {cloudInfo.mem}%
                    </span>
                  </div>
                ) : (
                  <p
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      color: theme.interface4,
                      fontSize: '12px',
                      marginTop: '12px',
                    }}
                  >
                    O cloud está em sincronização
                  </p>
                )}
              </Card>
            </Col>
            <Col xl="4">
              <Card>
                <CardHeader>Disco</CardHeader>
                {cloudInfo ? (
                  <div
                    style={{
                      width: '100%',
                      height: '100%',
                      margin: 'auto',
                      position: 'relative',
                    }}
                  >
                    <canvas ref={diskRef} />
                  </div>
                ) : (
                  <p
                    style={{
                      width: '100%',
                      textAlign: 'center',
                      color: theme.interface4,
                      fontSize: '12px',
                      marginTop: '12px',
                    }}
                  >
                    O cloud está em sincronização
                  </p>
                )}
              </Card>
            </Col>
          </Row>
        </Col>
        <Col xl="5">
          <Card>
            <CardHeader>Tickets</CardHeader>
            <Row>
              <Col xs>
                <Ticket type="1" id={cloud.id} />
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <Row>
        <Col>
          <Tabs>
            <Tab active={tab === 1} onClick={() => setTab(1)}>
              Addons
            </Tab>
            <Tab active={tab === 2} onClick={() => setTab(2)}>
              Usuários
            </Tab>
          </Tabs>

          <Arrows
            icon={faChevronLeft}
            left="true"
            onClick={() => tab === 2 && setTab(tab - 1)}
            actions="true"
            disabled={tab === 1}
          />
          <Swipe
            onSwipeRight={() => tab === 2 && setTab(tab - 1)}
            onSwipeLeft={() => tab === 1 && setTab(tab + 1)}
            tolerance={100}
          >
            <TabContent>
              {(() => {
                switch (tab) {
                  case 1:
                    return (
                      <Addons
                        addonLoading={addonLoading}
                        addons={addons}
                        setAddons={setAddons}
                        cloud={cloud.id}
                      />
                    );
                  case 2:
                    return (
                      <Users
                        users={users}
                        setUsers={setUsers}
                        cloud={cloud.id}
                        userLoading={userLoading}
                      />
                    );
                  default:
                    return null;
                }
              })()}
            </TabContent>
          </Swipe>
          <Arrows
            icon={faChevronRight}
            right="true"
            onClick={() => tab === 1 && setTab(tab + 1)}
            actions="true"
            disabled={tab === 2}
          />
          <Row
            style={{
              marginTop: '20px',
            }}
          >
            <Col xs="12" className="d-flex d-sm-none">
              <Button
                icon={removeLoading}
                disabled={removeLoading}
                width="100%"
                outline
                error
                remove
                onClick={deleteCloudHandle}
              >
                {removeLoading ? (
                  <FontAwesomeIcon icon={faSpinner} spin />
                ) : (
                  <>Excluir cloud</>
                )}
              </Button>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  ) : (
    <Placeholder />
  );
};

export default props => (
  <CloudDetailProvider>
    <CloudDetail {...props} />
  </CloudDetailProvider>
);
