import React, { useContext } from 'react';
import CSVReader from 'react-csv-reader';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile } from '@fortawesome/free-solid-svg-icons';

import { Modal, Button } from '@cloudez/cloudez-design-system';
import {
  Header,
  Icon,
  Text,
  Title,
  Content,
  Footer,
} from 'components/ListingModal/styles';

import { ThemeContext } from 'styled-components';

const papaparseOptions = {
  header: false,
  dynamicTyping: true,
  skipEmptyLines: true,
};

const CSVModal = ({ show, setShow, action }) => {
  const theme = useContext(ThemeContext);

  return (
    <Modal show={show} setShow={setShow}>
      <Header>
        <Icon>
          <FontAwesomeIcon icon={faFile} />
        </Icon>
        <Text>
          <Title>Importação de contas</Title>
        </Text>
      </Header>

      <Content>
        <p
          style={{
            color: theme.interface5,
            fontSize: '12px',
          }}
        >
          Pra adicionar múltiplas contas com maior facilidade você pode utilizar
          um arquivo CSV com o conteúdo seguindo o padrão abaixo:
        </p>
        <div
          style={{
            width: '60%',
            margin: '30px auto',
            display: 'flex',
            alignItems: 'center',
            color: theme.interface5,
            fontSize: '12px',
            border: `1px solid ${theme.interface4}`,
            background: theme.interface3,
            padding: '15px',
          }}
        >
          username1,senha1
          <br /> username2,senha2 <br />
          username3,senha3
          <br /> .<br /> .<br /> . <br />
          usernameN,senhaN
        </div>

        <p
          style={{
            color: theme.interface5,
            fontSize: '12px',
          }}
        >
          <b>Obs.:</b> O arquivo não deve ter cabeçalho.
        </p>
        <CSVReader
          parserOptions={papaparseOptions}
          onFileLoaded={(data, fileInfo) => {
            action(data, fileInfo);
            setShow();
          }}
          inputStyle={{ marginTop: '10px' }}
        />
      </Content>

      <Footer>
        <Button onClick={() => setShow(false)}>Fechar</Button>
      </Footer>
    </Modal>
  );
};

export default CSVModal;
